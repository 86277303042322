<template>
    <ui-dropdown-item :disabled="!canPasteCard" @click.prevent="pasteCard">
        <template #dropdown-menu-item-label="{ instance }">
            <fa-icon class="icon" icon="fa-regular fa-clipboard" />
            {{ $t('Paste') }}
        </template>
    </ui-dropdown-item>
</template>

<script>
import { useStore } from 'vuex';
import { defineComponent, computed, inject } from 'vue';
import UiIcon from '../../../../../../components/UiIcon.vue';
import UiDropdownItem from '../../../../../../components/UiDropdownItem.vue';
import { useSequence } from '../../../../../composables/useSequence';
import { useHistory } from '../../../../../composables/useHistory';

export default defineComponent({
    name: 'UiBackgroundPasteSelector',
    components: {
        UiIcon,
        UiDropdownItem
    },
    props: {
        id: String,
        seqId: String
    },
    setup(props) {
        const store = useStore();
        const { saveHistoryStep } = useHistory();
        const { sequenceStoreModulePath } = useSequence(props.seqId);
        const $videoStudio = inject('$videoStudio');

        const canPasteCard = computed(() => {
            return store.getters['ui/canPasteCard'](props.id);
        });

        const pasteCard = () => {
            if (canPasteCard.value) {
                $videoStudio.value.studio.$stage.pauseTimeline();
                saveHistoryStep(() => {
                    store.dispatch(sequenceStoreModulePath.value + '/pasteCard', props.id);
                });
            }
        };

        return {
            canPasteCard,
            pasteCard
        };
    }
});
</script>
