import { useStore } from 'vuex';
import { computed } from 'vue';
import { useVideo } from './useVideo';

export function useHistory(store = null) {
    if (!store) {
        store = useStore();
    }
    const { saveVideo } = useVideo(store);

    const startHistoryStep = () => {
        store.dispatch('ui/history/startStep');
    };

    const ignoreHistoryStep = () => {
        store.dispatch('ui/history/ignoreStep');
    };

    const undo = () => {
        store.dispatch('ui/history/undoStep');
    };

    const redo = () => {
        store.dispatch('ui/history/redoStep');
    };

    const canUndo = computed(() => store.getters['ui/history/canUndo']);

    const canRedo = computed(() => store.getters['ui/history/canRedo']);

    const saveHistoryStep = (action) => {
        startHistoryStep();
        action();
        saveVideo();
    };

    return { startHistoryStep, ignoreHistoryStep, undo, redo, saveHistoryStep, canUndo, canRedo };
}
