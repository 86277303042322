<template>
    <button class="ui-caption-button ui-caption-button-icon-only" :disabled="enableDropDown" :style="toggleStyle" @click="openQuickCutTimeline">
        <svg-icon icon="scissors-icon" />
    </button>
</template>

<script>
import { useStore } from 'vuex';
import { defineComponent, computed } from 'vue';
import UiIcon from '../../../../../../components/UiIcon.vue';
import { useSequence } from '../../../../../composables/useSequence';

export default defineComponent({
    name: 'UiBackgroundVideoTimeRangeSelector',
    components: {
        UiIcon,
     },
    props: {
        seqId: String,
        cardElementId: String,
        readOnly: Boolean,
        iconOnly: Boolean
    },
    setup(props) {
        const store = useStore();
        const {
            hasPreviousBackground,
            hasVideoBackground,
            hasRecordingBackground,
            maxDuration,
            backgroundVideo,
            videoTimerangeSegments
        } = useSequence(props.seqId);

        const toggleStyle = computed(() => {
            return isActiveTimerange.value ? { color: 'var(--edit-mode-color)' } : {};
        });

        const enableDropDown = computed(() => {
            return props.readOnly || !backgroundVideo.value.src || !maxDuration.value;
        });

        // Computed property to determine if the timerange selector should be active
        const isActiveTimerange = computed(() => {  
            if (videoTimerangeSegments.value.length > 0) {
                return (
                    videoTimerangeSegments.value[0].start > 0 ||
                    videoTimerangeSegments.value[0].end != maxDuration.value
                );
            }
            return false;
        });

        //openQuickCutTimeline
        function openQuickCutTimeline() {
            if (hasRecordingBackground.value) {
                store.dispatch('ui/quickcut/openQuickCutForRecording', props.seqId);
            } else {
                store.dispatch('ui/quickcut/openQuickCutForBackground', props.seqId);
            }
        }


        return {
            isActiveTimerange,
            hasVideoBackground,
            hasPreviousBackground,
            toggleStyle,
            maxDuration,
            enableDropDown,
            openQuickCutTimeline,
        };
    }
});
</script>
