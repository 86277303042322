/**
 * Video Studio
 * Production constants
 */

const FULL_CAPTURE_MODE = 'full';
const AUDIO_CAPTURE_MODE = 'audio';
const FRAME_CAPTURE_MODE = 'frame';

const LOADING_SEQUENCE_ID = '__seqLoadingProduction';
const LOADING_SEQUENCE_NAME = 'Loading Production Sequence';
const LOADING_SEQUENCE_DURATION = 2;

const Production = {
    ORIGIN_URL: import.meta.env.VITE_PRODUCTION_URL,

    FULL_CAPTURE_MODE,
    AUDIO_CAPTURE_MODE,
    FRAME_CAPTURE_MODE,
    CAPTURE_MODES: [FULL_CAPTURE_MODE, AUDIO_CAPTURE_MODE, FRAME_CAPTURE_MODE],

    LOADING_SEQUENCE_ID,
    LOADING_SEQUENCE_NAME,
    LOADING_SEQUENCE_DURATION
};

export default Production;
