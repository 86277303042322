<!--
  -- Component name: AGR1
  -- Type: Transition
  -- Uses: color.start,color.second,color.third
  -- Tags: agrica
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        viewBox="0 0 1920 1080"
        preserveAspectRatio="xMidYMid slice"
    >
        <rect class="polygon0" :fill="state.color.start" :width="width" :height="height" x="50%" y="50%" />
        <g class="polygons-left">
            <polygon
                class="polygon1"
                :fill="state.color.start"
                fill-opacity="0.7"
                points="0,0 3030,0.5 2599.06,1080 0,1080"
            />
            <rect class="polygon3 polygon-skew" :fill="state.color.second" x="3000" y="-20" width="160" height="1120" />
            <rect class="polygon4 polygon-skew" :fill="state.color.third" x="3100" y="-20" width="200" height="1120" />
            <rect class="polygon5 polygon-skew" :fill="state.color.start" x="3250" y="-20" width="220" height="1120" />
        </g>
        <polygon
            v-if="this.format.width > this.format.height"
            class="polygon2"
            :fill="state.color.start"
            fill-opacity="0.7"
            points="1615,1080 1920,1080 1920,300"
        />
        <polygon
            v-if="this.format.width === this.format.height"
            class="polygon2"
            :fill="state.color.start"
            fill-opacity="0.7"
            points="1195,1080 1500,1080 1500,300 "
        />
        <polygon
            v-if="this.format.width < this.format.height"
            class="polygon2"
            :fill="state.color.start"
            fill-opacity="0.7"
            points="990,1080 1295,1080 1295,300 "
        />
    </svg>
</template>

<style lang="scss" scoped>
.polygon-skew {
    transform: skewX(-21.75deg);
}
</style>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: true, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true // The transition component is placed above the in and out elements
            // swapElements: true, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {
        width() {
            return this.format.width > this.format.height ? 1920 : 1080;
        },

        height() {
            return this.format.height > this.format.width ? 1920 : 1080;
        }
    },

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();

            t.fromTo(
                '#' + this.containerID + ' .polygon0',
                { scale: 0, attr: { 'fill-opacity': 1 } },
                {
                    scale: 1,
                    transformOrigin: '50% 50%',
                    attr: { 'fill-opacity': 0.7 },
                    duration: 0.5,
                    ease: 'power1.in'
                }
            );
            t.set('#' + this.containerID + ' .polygon0', { autoAlpha: 0 });

            t.fromTo(
                '#' + this.containerID + ' .polygon1',
                { attr: { 'fill-opacity': 0 } },
                { attr: { 'fill-opacity': 0 }, duration: 0.499 },
                0
            );
            t.set('#' + this.containerID + ' .polygon1', { attr: { 'fill-opacity': 0.7 } }, 0.5);

            t.fromTo(
                '#' + this.containerID + ' .polygons-left',
                { xPercent: 0 },
                { xPercent: -70, duration: 1.2, ease: 'power2.out' },
                0.5
            );
            t.fromTo(
                '#' + this.containerID + ' .polygon-skew',
                { filter: 'blur(20px)' },
                { attr: { width: 0, x: 3030 }, filter: 'blur(0px)', duration: 1, ease: 'power2.out' },
                0.75
            );
            t.fromTo(
                '#' + this.containerID + ' .polygon2',
                { xPercent: 100 },
                { xPercent: 30, duration: 0.5, ease: 'power2.out' },
                1.1
            );

            t.fromTo(
                '#' + this.containerID + ' .polygon1',
                { xPercent: 0 },
                { xPercent: -3, duration: 2.5, ease: 'power1.out' },
                1.6
            );
            t.to('#' + this.containerID + ' .polygon2', { xPercent: 0, duration: 2.5, ease: 'power1.out' }, 1.6);

            return t;
        }
    }
};
</script>
