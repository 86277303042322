import { ref } from 'vue';

export function useClickThreshold(threshold = 5) {
    const clickStartX = ref(0);
    const clickStartY = ref(0);
    const isThresholdExceeded = ref(false);

    const startTracking = (event) => {
        clickStartX.value = event.clientX;
        clickStartY.value = event.clientY;
        isThresholdExceeded.value = false;
    };

    const checkThreshold = (event) => {
        if (isThresholdExceeded.value) return true;

        const deltaX = Math.abs(event.clientX - clickStartX.value);
        const deltaY = Math.abs(event.clientY - clickStartY.value);   
        
        if (deltaX > threshold || deltaY > threshold) {
            isThresholdExceeded.value = true;
            return true;
        }

        return false;
    };

    const resetTracking = () => {
        isThresholdExceeded.value = false;
    };

    return {
        startTracking,
        checkThreshold,
        resetTracking,
        isThresholdExceeded
    };
}
