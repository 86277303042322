<template>
  <div class="segment-container">
    <template v-if="isSmallSegment">
      <div
        class="small-segment-handle"
        @mousedown.stop="startDrag('both', index)"
        :style="{ left: getCenteredHandlePos() + 'px' }"
        :class="{ selected: isSelected, 'no-cursor': isDragging && dragSegmentIndex === index }"
      >
        <div class="handle-indicator vertical-bar"></div>
      </div>
      <div
        class="small-segment-highlight"
        :class="{ active: isSelected }"
        :style="{
          left: getCenteredHandlePos() + 'px',
          width: SMALL_SEGMENT_WIDTH + 'px'
        }"
        @click.stop="selectSegment"
      ></div>
    </template>
    <template v-else>
      <div
        class="handle start-handle"
        @mousedown.stop="startDrag('start', index)"
        :style="{ 
          left: getHandlePos(segment.start) + 'px',
          borderTopLeftRadius: isDraggingStart ? '0px' : '10px',
          borderBottomLeftRadius: isDraggingStart ? '0px' : '10px'
        }"
        ref="startHandle"
        :class="{ selected: isSelected, 'no-cursor': isDragging && dragSegmentIndex === index }"
      >
        <div class="handle-indicator vertical-bar" :style="{ opacity: isDraggingStart ? 0 : 1 }"></div>
        <div class="handle-indicator chevron-left" :style="{ opacity: isDraggingStart ? 1 : 0 }"></div>
      </div>
      <div
        class="handle end-handle"
        @mousedown.stop="startDrag('end', index)"
        :style="{ 
          left: getHandlePos(segment.end, true) + 'px',
          borderTopRightRadius: isDraggingEnd ? '0px' : '10px',
          borderBottomRightRadius: isDraggingEnd ? '0px' : '10px'
        }"
        ref="endHandle"
        :class="{ selected: isSelected, 'no-cursor': isDragging && dragSegmentIndex === index }"
      > 
        <div class="handle-indicator vertical-bar" :style="{ opacity: isDraggingEnd ? 0 : 1 }"></div>
        <div class="handle-indicator chevron-right" :style="{ opacity: isDraggingEnd ? 1 : 0 }"></div>
      </div>
      <div
        class="segment-highlight"
        :class="{ active: isSelected }"
        :style="{
          left: getHandlePos(segment.start) + 'px',
          width: getHandlePos(segment.end) - getHandlePos(segment.start) + 'px'
        }"
        @click.stop="selectSegment"
      ></div>
    </template>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch } from 'vue';

export default defineComponent({
    name: 'SegmentHandler',
    props: {
        segment: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        getHandlePos: {
            type: Function,
            required: true
        },
        isDragging: {
            type: Boolean,
            required: true
        },
        dragSegmentIndex: {
            type: Number,
            required: true
        },
        selectedSegmentIndex: {
            type: Number,
            required: true
        },
        dragHandle: {
            type: String,
            default: ''
        }
    },
    emits: ['startDrag', 'selectSegment'],
    setup(props, { emit }) {
    const SMALL_SEGMENT_THRESHOLD = 28; // pixels
    const SMALL_SEGMENT_WIDTH = 14; // pixels

    const isSelected = computed(() => 
      props.selectedSegmentIndex === props.index
    );

    const isSmallSegment = computed(() => {
      const startPos = props.getHandlePos(props.segment.start);
      const endPos = props.getHandlePos(props.segment.end);
      return endPos - startPos < SMALL_SEGMENT_THRESHOLD;
    });

    const isDraggingStart = computed(() => 
      props.isDragging && props.dragSegmentIndex === props.index && props.dragHandle === 'start'
    );

    const isDraggingEnd = computed(() => 
      props.isDragging && props.dragSegmentIndex === props.index && props.dragHandle === 'end'
    );

    const getCenteredHandlePos = () => {
      const startPos = props.getHandlePos(props.segment.start);
      const endPos = props.getHandlePos(props.segment.end);
      return startPos + (endPos - startPos) / 2 - SMALL_SEGMENT_WIDTH / 2;
    };

    const selectSegment = (event) => {
      emit('selectSegment', props.index, event);
    };

    const startDrag = (handle, index) => {
      emit('startDrag', handle, index);
    };
    
    return {
      isSelected,
      isSmallSegment,
      isDraggingStart,
      isDraggingEnd,
      selectSegment,
      startDrag,
      getCenteredHandlePos,
      SMALL_SEGMENT_WIDTH,
    };
  }
});
</script>

<style scoped>
.segment-container {
  position: relative;
}

.handle, .small-segment-handle {
  position: absolute;
  top: -2px;
  height: 62px;
  background-color: var(--edit-mode-color);
  cursor: ew-resize;
  z-index: 10;
  transition: background-color 0.2s ease, border-radius 0.2s ease, opacity 0.2s ease;
}

.handle {
  width: 14px;
  opacity: 0.5;
  z-index: 50;
}

.small-segment-handle {
  width: 14px;
  opacity: 0.5;
  cursor: move;
  border-radius: 10px;
  z-index: 50;

}

.handle.selected, .small-segment-handle.selected {
  opacity: 1;
}

.handle.no-cursor, .small-segment-handle.no-cursor {
  cursor: none;
}

.handle-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s ease;
}

.vertical-bar {
  width: 2px;
  height: 30px;
  background-color: white;
}

.chevron-right, .chevron-left {
  width: 10px;
  height: 10px;
  border-style: solid;
  border-color: white;
  border-width: 0 2px 2px 0;
}

.chevron-right {
  transform: translate(-75%, -50%) rotate(-45deg);
}

.chevron-left {
  transform: translate(-25%, -50%) rotate(135deg);
}

.segment-highlight, .small-segment-highlight {
  top: -2px;
  position: absolute;
  height: 62px;
  pointer-events: auto;
  cursor: pointer;
  z-index: 5;
  transition: background-color 0.2s ease;
  border-radius: 10px;
}

.segment-highlight.active, .small-segment-highlight.active {
  border: 2px solid var(--edit-mode-color);
}

.small-segment-highlight {
  z-index: 9;
}
</style>