/**
 * Video Studio
 * Branding logo data Vuex store module
 */

import { Align, Dimension, Logo } from '@/js/video-studio/constants';
import { conversions, types } from '@/js/video-studio/utils';

const originalState = () => ({
    src: '',
    src__ref: '',
    src__id: '',
    overrideEnabled: true,
    animation: {
        src: '',
        src__ref: '',
        src__id: '',
        type: ''
    },
    size: {
        width: {
            value: Dimension.AUTO,
            custom: null
        },
        height: {
            value: Dimension.AUTO,
            custom: null
        }
    },
    position: {
        alignH: Align.RIGHT,
        alignV: Align.TOP,
        alignX: Dimension.ZERO,
        alignY: Dimension.ZERO
    }
});

const state = {
    ...originalState()
};

// Getters
const getters = {
    logoAsset: (state) => state.src,

    logo: (state) => state.animation.src,

    logoAnimation: (state) => state.animation.type,

    logoSize: (state) => ({
        width: conversions.optionValue(state.size.width, Dimension.AUTO),
        height: conversions.optionValue(state.size.height, Dimension.AUTO)
    })
};

// Mutations
const mutations = {
    setLogoAsset(state, src) {
        state.src = src;
    },

    setLogo(state, src) {
        state.animation.src = src;
    },

    setLogoAnimation(state, type) {
        state.animation.type = types.isLogoAnimation(type) ? type : Logo.DEFAULT;
    },

    setLogoWidth(state, width) {
        if (types.isDimension(width.value, true)) {
            state.size.width.value = width.value;
            state.size.width.custom = null;
        } else if (types.isDimension(width.custom, true)) {
            state.size.width.value = Dimension.CUSTOM;
            state.size.width.custom = width.custom;
        } else {
            state.size.width.value = Dimension.AUTO;
            state.size.width.custom = null;
        }
    },

    setLogoHeight(state, height) {
        if (types.isDimension(height.value, true)) {
            state.size.height.value = height.value;
            state.size.height.custom = null;
        } else if (types.isDimension(height.custom, true)) {
            state.size.height.value = Dimension.CUSTOM;
            state.size.height.custom = height.custom;
        } else {
            state.size.height.value = Dimension.AUTO;
            state.size.height.custom = null;
        }
    },

    setLogoPosition(state, position) {
        state.position.alignH = types.isAlign(position.alignH) ? position.alignH : Align.RIGHT;
        state.position.alignV = types.isAlign(position.alignV) ? position.alignV : Align.TOP;
        state.position.alignX =
            state.position.alignH == Align.CUSTOM && types.isDimension(position.alignX)
                ? position.alignX
                : Dimension.ZERO;
        state.position.alignY =
            state.position.alignV == Align.CUSTOM && types.isDimension(position.alignY)
                ? position.alignY
                : Dimension.ZERO;
    },

    setLogoAssetReference(state, ref) {
        state.src__ref = ref;
    },

    setLogoAssetId(state, id) {
        state.src__id = id;
    },

    setLogoReference(state, ref) {
        state.animation.src__ref = ref;
    },

    setLogoId(state, id) {
        state.animation.src__id = id;
    },

    enableLogoOverride(state, enable) {
        state.overrideEnabled = enable;
    }
};

// Actions
const actions = {
    initLogo({ commit }, data) {
        commit('setLogoAsset', data.src);
        commit('setLogo', data.animation.src);
        commit('setLogoAnimation', data.animation.type);
        commit('setLogoWidth', data.size.width);
        commit('setLogoHeight', data.size.height);
        commit('setLogoPosition', data.position);
        commit('setLogoAssetId', data.src__id);
        commit('setLogoAssetReference', data.src__ref);
        commit('setLogoId', data.animation.src__id);
        commit('setLogoReference', data.animation.src__ref);
        commit('enableLogoOverride', data.overrideEnabled ?? true);
    },

    clearLogoState({ state }) {
        Object.assign(state, originalState());
    },

    updateLogoAsset({ commit }, data) {
        commit('setLogoAssetId', data.src__id);
        commit('setLogoAsset', data.src);
    },

    updateLogo({ commit }, data) {
        commit('setLogoId', data.src__id);
        commit('setLogo', data.src);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
