<template>
    <router-link
        v-if="!loading"
        v-for="video in videos"
        :key="video.id"
        :to="{ name: 'EditVideo', params: { id: video.id } }"
    >
        <div class="video-item" @mouseover="setHoveredVideo(video.id)" @mouseleave="resetHoveredVideo">
            <div v-if="!video.thumbnails.staticSrc" class="video-item-draft">
                <div class="video-item-draft-button">{{ $t('draft') }}</div>
            </div>
            <div v-else :class="['video-item-preview', formatNameService.handle(video.format)]">
                <img
                    :src="hoveredVideo === video.id ? video.thumbnails.previewSrc : video.thumbnails.staticSrc"
                    :alt="$t('home.video-preview-image-alt')"
                />
            </div>

            <div class="video-item-information">
                <div class="video-item-name">{{ video.name }}</div>
                <div class="video-item-last-update">{{ getTimeSince(video.updatedAt * 1000) }}</div>
            </div>
        </div>
    </router-link>
    <div v-else v-for="idx in 3" class="video-item skeleton">
        <div class="video-item-draft"></div>

        <div class="video-item-information">
            <div class="video-item-name">
                <span>{{ $t('home.video-name-skeleton') }}</span>
            </div>
            <div class="video-item-last-update">
                <span>{{ $t('home.video-last-update-skeleton') }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useDates } from '@/js/composables/useDates.js';
import { ref } from 'vue';
import GetFormatNameService from '@/js/application/services/video/GetFormatNameService.js';

const { getTimeSince } = useDates();
const props = defineProps({
    videos: {
        type: Array,
        required: true
    },
    loading: {
        type: Boolean,
        default: false
    }
});

const hoveredVideo = ref(null);
const setHoveredVideo = (videoId) => (hoveredVideo.value = videoId);
const resetHoveredVideo = () => (hoveredVideo.value = null);

const formatNameService = new GetFormatNameService();
</script>
