<template>
    <ui-card
        :id="cardElementId"
        :class="'ui-card-' + id"
        :title="$t('Voice-over')"
        :added-card-id="addedCardId"
        :hide-footer="isNoneCategory"
    >
        <template #header-icon>
            <svg-icon icon="voice-ai-icon" />
        </template>
        <template #menu-content>
            <ui-dropdown-item :disabled="!canCopyCard" @click.prevent="copyCard">
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-copy" />
                    {{ $t('Copy') }}
                </template>
            </ui-dropdown-item>

            <ui-dropdown-item :disabled="!canPasteCard" @click.prevent="pasteCard">
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-clipboard" />
                    {{ $t('Paste') }}
                </template>
            </ui-dropdown-item>

            <ui-dropdown-item
                v-if="isEmotionMode"
                classes="ui-remove-item"
                :disabled="!canRemoveCard"
                @click.prevent="removeCard"
            >
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-trash-can" />
                    {{ $t('Remove') }}
                </template>
            </ui-dropdown-item>
        </template>

        <div class="voice-over-card-content ui-card-row">
            <ui-media-selector
                v-if="isAudioCategory"
                :id="cardElementId + '-audio'"
                key="media-selector-tts-audio"
                v-model:media="voiceOverMediaAudioModel"
                :type="mediaTypeAudio"
                :label="$t('studio.tts_categories.audio')"
                :showLabel="false"
                :disabled="readOnly"
                icon="fa-solid fa-volume"
                :trigger-opening="triggerSelection && isAudioCategory && !libraryId"
                @[mediaSelectorShowEvent]="openMediaLibrary"
                @[mediaSelectorChangeEvent]="resetVoiceOverCaptions"
            />

            <ui-voice-over-selector
                v-else-if="isVoiceCategory"
                :id="cardElementId + '-tts'"
                :disabled="readOnly"
                :seqId="seqId"
                :useInSequence="true"
                :trigger-opening="triggerSelection && isVoiceCategory && !libraryId"
            />

            <ui-recording-selector
                v-else-if="isRecordingCategory"
                :id="cardElementId + '-recording'"
                v-model:recording="voiceOverRecordingModel"
                :type="RECORDING_TYPE_MICROPHONE"
                :label="$t('studio.tts_categories.recording')"
                :showLabel="false"
                :disabled="readOnly"
                :trigger-opening="triggerSelection && isRecordingCategory && !libraryId"
                @[recordingSelectorChangeEvent]="resetVoiceOverCaptions"
            />

            <ui-dropdown
                :id="cardElementId + '-tts-category'"
                :select="true"
                v-model="voiceOverCategory"
                :disabled="readOnly"
                :default-label="$t('Add voice-over')"
                menu-strategy="fixed"
                @[dropdownCategorySelectEvent]="triggerCategoryDropdown"
            >
                <template #dropdown-toggle="{ instance }">
                    <svg-icon v-if="isVoiceCategory && isProcessing" icon="dots-loader" />
                    <span v-else>{{ instance.toggleLabel }}</span>
                </template>
                <ui-dropdown-item
                    v-for="category in ttsCategories"
                    :key="category"
                    :value="category"
                    :label="$t('studio.tts_categories.' + category)"
                    :disabled="recordingDisabled(category)"
                    :show-icon-on-disabled="true"
                    v-tooltip="recordingDisabled(category) && $t('studio.features.recording-disabled')"
                >
                    <template #dropdown-menu-item-label="{ instance }">
                        <slot :name="instance.value.toLowerCase() + '-voice-over-icon'"></slot>
                        {{ instance.label || instance.value }}
                    </template>
                </ui-dropdown-item>
            </ui-dropdown>

            <div class="sequence-tools-selector-container" v-if="!isNoneCategory">
                <UiVoiceOverCaptionSelector
                    :useInSequence="true"
                    :seqId="seqId"
                    :readOnly="readOnly"
                    :iconOnly="true"
                />
                <UiVoiceOverTimeRangeSelector
                    :useInSequence="true"
                    :seqId="seqId"
                    :readOnly="readOnly"
                    :iconOnly="true"
                />
                <UiVoiceOverSpeedSelector :useInSequence="true" :seqId="seqId" :readOnly="readOnly" :iconOnly="true" />
                <UiVoiceOverVolumeSelector
                    :useInSequence="true"
                    :seqId="seqId"
                    :readOnly="readOnly"
                    :iconOnly="true"
                    menu-placement="bottom"
                />
            </div>
        </div>

        <template #footer>
            <UiVoiceOverDurationSelector :use-in-sequence="true" :seqId="seqId" :readOnly="readOnly" :iconOnly="true" />
        </template>
    </ui-card>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { TTS } from 'cte-video-studio';
import { RECORDING_ELEMENT_ID, TTS_ELEMENT_ID } from '../../../../../constants';
import { RECORDING_TYPE_MICROPHONE } from '@/js/constants';
import { useSequence } from '../../../../composables/useSequence';
import { useVoiceOver } from '../../../../composables/useVoiceOver';
import { useHistory } from '../../../../composables/useHistory';
import { useCard } from '../../../../composables/useCard';
import { useVideo } from '../../../../composables/useVideo';
import UiIcon from '../../../../../components/UiIcon.vue';
import { UI_MEDIA_TYPE_AUDIO } from '../../../../../components/UiMediaSelector.vue';
import UiVoiceOverSelector from '../../../../../components/UiVoiceOverSelector.vue';
import UiVoiceOverVolumeSelector from '../selectors/volume/UiVoiceOverVolumeSelector.vue';
import UiVoiceOverSpeedSelector from '../selectors/speed/UiVoiceOverSpeedSelector.vue';
import UiVoiceOverDurationSelector from '../selectors/duration/UiVoiceOverDurationSelector.vue';
import { UiCard, UiDropdown, UiDropdownItem, UiMediaSelector } from '../../../../../components';
import { UI_MEDIA_SELECTOR_SHOW, UI_MEDIA_SELECTOR_CHANGE } from '../../../../../components/UiMediaSelector.vue';
import UiRecordingSelector, { UI_RECORDING_SELECTOR_CHANGE } from '../../../../../components/UiRecordingSelector.vue';
import UiVoiceOverCaptionSelector from '../selectors/caption/UiVoiceOverCaptionSelector.vue';
import UiVoiceOverTimeRangeSelector from '../selectors/time-range/UiVoiceOverTimeRangeSelector.vue';
import { UI_DROPDOWN_SELECT_CHANGE } from '../../../../../components/UiDropdown.vue';
import { UsesTooltip } from '@/js/mixins/index.js';

export default defineComponent({
    components: {
        UiVoiceOverTimeRangeSelector,
        UiVoiceOverCaptionSelector,
        UiVoiceOverDurationSelector,
        UiVoiceOverSpeedSelector,
        UiIcon,
        UiCard,
        UiDropdownItem,
        UiMediaSelector,
        UiRecordingSelector,
        UiDropdown,
        UiVoiceOverSelector,
        UiVoiceOverVolumeSelector
    },

    props: {
        id: {
            type: String,
            default: TTS_ELEMENT_ID
        },
        seqId: {
            type: String,
            default: ''
        },
        addedCardId: {
            type: String,
            default: ''
        }
    },

    mixins: [UsesTooltip],

    setup(props) {
        const store = useStore();

        const useInSequence = ref(true);
        const sequenceId = ref(props.seqId);
        const triggerSelection = ref(false);

        const mediaTypeAudio = UI_MEDIA_TYPE_AUDIO;
        const ttsCategories = TTS.CATEGORIES;

        const cardElementId = computed(() => props.seqId + '-' + props.id);
        const readOnly = computed(() => store.state.ui.readOnly);
        const libraryId = computed(() => store.state.ui.currentLibrary.id);

        const { isEmotionMode } = useVideo();
        const { saveHistoryStep } = useHistory();
        const { sequenceStoreModulePath } = useSequence(props.seqId);
        const { canCopyCard, copyCard, canPasteCard, pasteCard, canRemoveCard } = useCard(props.seqId, props.id);

        const {
            voiceOverCategory,
            isNoneCategory,
            isAudioCategory,
            isVoiceCategory,
            isRecordingCategory,
            voiceOverMediaAudioModel,
            voiceOverRecordingModel,
            isProcessing,
            voiceOverCaptions
        } = useVoiceOver(useInSequence, sequenceId);

        const removeCard = () => {
            saveHistoryStep(() => {
                store.commit(sequenceStoreModulePath.value + '/tts/showCard', false);
                store.commit(sequenceStoreModulePath.value + '/resetTtsState');
            });
        };

        const openMediaLibrary = (selector) => {
            store.commit('ui/setCurrentMediaLibrary', {
                mediaType: selector.type,
                selectedMediaId: selector.media.id,
                selector
            });
        };

        const triggerCategoryDropdown = () => {
            triggerSelection.value = true;
        };

        const resetVoiceOverCaptions = () => {
            voiceOverCaptions.value = [];
        };

        const recordingDisabled = (category) =>
            category === RECORDING_ELEMENT_ID && !store.state.auth.user.client.enableRecording;

        return {
            isEmotionMode,
            mediaTypeAudio,
            cardElementId,
            readOnly,
            canCopyCard,
            copyCard,
            canPasteCard,
            pasteCard,
            canRemoveCard,
            removeCard,
            voiceOverCategory,
            voiceOverMediaAudioModel,
            voiceOverRecordingModel,
            ttsCategories,
            isAudioCategory,
            isVoiceCategory,
            isRecordingCategory,
            isNoneCategory,
            isProcessing,
            openMediaLibrary,
            triggerCategoryDropdown,
            triggerSelection,
            libraryId,
            resetVoiceOverCaptions,
            recordingDisabled,
            mediaSelectorShowEvent: UI_MEDIA_SELECTOR_SHOW,
            mediaSelectorChangeEvent: UI_MEDIA_SELECTOR_CHANGE,
            recordingSelectorChangeEvent: UI_RECORDING_SELECTOR_CHANGE,
            dropdownCategorySelectEvent: UI_DROPDOWN_SELECT_CHANGE,
            RECORDING_TYPE_MICROPHONE
        };
    }
});
</script>
