<template>
    <ui-card :id="id" :class="'ui-card-' + id" :title="$t('Brand voice')">
        <template #header-icon>
            <svg-icon icon="voice-ai-icon" />
        </template>

        <div class="brand-voice-over-selection ui-card-row" :class="{ disabled: readOnly }">
            <label>{{ $t('Default voice') }}</label>
            <ui-dropdown
                id="settings-brand-default-voice"
                class="voices-dropdown"
                :select="true"
                v-model="defaultVoice"
                :disabled="readOnly"
            >
                <ui-dropdown-item
                    v-for="voice in sortedVoicesLibrary"
                    :key="voice.id"
                    :value="voice.name"
                    :label="voice.label"
                    :style="getVoiceStyles(voice.name)"
                />
            </ui-dropdown>
        </div>
    </ui-card>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import UiCard from '@resources/js/components/UiCard.vue';
import UiIcon from '@resources/js/components/UiIcon.vue';
import { UiDropdown, UiDropdownItem } from '@resources/js/components/index.js';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    mixins: [],

    components: {
        UiCard,
        UiDropdown,
        UiDropdownItem,
        UiIcon
    },

    props: {
        id: {
            type: String,
            default: 'settings-brand-voice-over'
        }
    },

    setup() {
        const store = useStore();

        const { t } = useI18n();

        const readOnly = computed(() => store.state.ui.readOnly);

        const shortLanguage = computed(() => store.state.ui.shortLanguage);

        const defaultVoice = computed({
            get() {
                return store.state.branding.defaultVoiceId;
            },
            set(voiceId) {
                store.commit('branding/setDefaultVoiceId', voiceId);
            }
        });

        const voicesLibrary = computed(() => store.state.branding.libraries.voices);

        const voicesTranslatedLibrary = computed(() => {
            return voicesLibrary.value.map((voice) => {
                return {
                    ...voice,
                    label: getVoiceName(voice)
                };
            });
        });

        const sortedVoicesLibrary = computed(() => {
            return voicesTranslatedLibrary.value
                .slice()
                .sort((voiceA, voiceB) =>
                    getVoiceName(voiceA).localeCompare(getVoiceName(voiceB), shortLanguage.value)
                );
        });

        const getVoiceStyles = (voice) => {
            return {
                '--animation-thumbnail': `url(/assets/thumbnails/voices/${voice}.gif)`
            };
        };

        const getVoiceName = (voice) => {
            return t('studio.tags.' + voice.tags[0]) + ' ' + voice.id;
        };

        return {
            readOnly,
            defaultVoice,
            sortedVoicesLibrary,
            getVoiceStyles
        };
    }
});
</script>
