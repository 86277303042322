<template>
    <div :class="['current-time', positionClass]" :style="style">
        {{ formattedTime }}
    </div>
</template>

<script>
import { defineComponent, computed } from 'vue';

export default defineComponent({
    name: 'CurrentTimeDisplay',
    props: {
        currentTime: {
            type: Number,
            required: true
        },
        position: {
            type: String,
            required: true,
            validator: (value) => ['left', 'right', 'center'].includes(value)
        },
        x: {
            type: Number,
            required: true
        },
        y: {
            type: Number,
            required: true
        },
        rate: {
            type: Number,
            default: 30
        }
    },
    setup(props) {
        
        const formattedTime = computed(() => {
            let time = props.currentTime;
            let rate = props.rate;

            let minutes = Math.floor((time % 3600) / 60);
            let seconds = Math.floor(time % 60);
            let frames = Math.floor((time % 1) * rate);

            // Formatage des secondes
            seconds = seconds < 10 ? `0${seconds}` : seconds;

            // Formatage des frames
            frames = frames < 10 ? `0${frames}` : frames;
            if (rate > 100) {
                frames = frames < 100 ? `0${frames}` : frames;
            }

            if (isNaN(minutes)) {
                minutes = 0;
            }
            if (isNaN(seconds)) {
                seconds = 0;
            }
            if (isNaN(frames)) {
                frames = 0;
            }

            return `${minutes}:${seconds}:${frames}`;
        });

        const positionClass = computed(() => {
            return `current-time-${props.position}`;
        });

        const style = computed(() => {
            return {
                position: 'fixed',
                left: `${props.x}px`,
                top: `${props.y}px`
            };
        });

        return {
            formattedTime,
            positionClass,
            style
        };
    }
});
</script>

<style scoped>
.current-time {
    color: white;
    font-size: 12px;
    white-space: nowrap;
    background-color: var(--edit-mode-color);
    padding: 2px 4px;
    border-radius: 4px;
    user-select: none;
    pointer-events: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    z-index: 9999;
}

.current-time::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid var(--edit-mode-color);
}

.current-time-right {
    transform: translateX(-50%);
}

.current-time-left {
    transform: translateX(-50%);
}

.current-time-center {
    transform: translateX(-50%);
}
</style>
