<template>
    <div id="companion-ui" v-if="!loading">
        <div class="ui-layout">
            <ui-sidebar
                class="ui-sidebar"
                :prompts="prompts"
                :current-prompt-id="currentPrompt.id"
                @add-prompt="setCompanionBarFocus"
            >
                <ui-wrapper :tag="false" class="ui-sidebar-header">
                    <a class="ui-sidebar-back" href="#" @click="router.go(-1)">
                        <span class="visually-hidden">{{ $t('Back to home') }}</span>
                        <svg-icon icon="chevron-left-icon" class="back" />
                    </a>
                    <a class="ui-sidebar-logo" href="#" @click="router.go(-1)">
                        <img class="companion-logo" :src="logoCteCompanion" alt="" />
                    </a>
                    <div class="ui-sidebar-titles">
                        <h1>{{ title }}</h1>
                        <h2>Video GenAI {{ version }}</h2>
                    </div>
                </ui-wrapper>
            </ui-sidebar>
            <ui-body
                ref="$body"
                class="ui-body"
                :current-prompt="currentPrompt"
                :focus-companion-bar="companionBarFocus"
                v-cloak
            >
                <header>
                    <img class="companion-logo" :src="logoCteCompanion" />
                    <p>{{ versionName }}</p>
                </header>
                <h2>{{ $t('What video would you like to create?') }}</h2>
            </ui-body>
            <ui-modal-container v-cloak></ui-modal-container>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { UiModalContainer, UiWrapper } from '@/js/components';
import { useStore } from 'vuex';
import { subscribeToChannel } from '@/js/utils.js';
import { AI_PROMPT_CREATED, AI_PROMPT_UPDATED, USER_CHANNEL } from '@/js/constants/index.js';
import logoCteCompanion from '@/assets/images/logo-cte-companion.png';
import FetchCompanionDataService from '@/js/application/services/companion/FetchCompanionDataService.js';
import { UiBody, UiSidebar } from '@/js/companion/components/ui/index.js';

const route = useRoute();
const store = useStore();
const router = useRouter();
const loading = ref(true);

const title = ref('');
const version = ref('');
const versionName = ref('');

const companionBarFocus = ref(false);
const userId = computed(() => store.state.ui.userId);
const prompts = computed(() => store.state.prompts.all);
const currentPrompt = computed(() => store.state.currentPrompt);

const initStore = (config, promptId, promptUrl) => {
    store.dispatch('ui/init', config);
    if (!!promptId && !!promptUrl && window.location.hash.substring(1) != 'new') {
        window.history.replaceState({ id: promptId, url: promptUrl }, '', window.location.href);
        store.dispatch('setPromptToLoad', { id: promptId, url: promptUrl });
    }
};

const initUrlNavigation = () => {
    window.addEventListener('popstate', updateBodyFromUrl);
};

const updateBodyFromUrl = (event) => {
    unsetCompanionBarFocus();
    if (!!event.state?.url) {
        store.dispatch('setPromptToLoad', { id: event.state.id, url: event.state.url });
        store.dispatch('loadPrompt', event.state.url);
    } else {
        store.dispatch('unloadPrompt');
        if (event.state?.new) setCompanionBarFocus();
    }
};

const initWebSockets = () => {
    subscribeToChannel(USER_CHANNEL, { id: userId.value }, 'private', {
        [AI_PROMPT_CREATED]: (prompt) => {
            store.dispatch('setPrompt', prompt);
            if (currentPrompt.value.id === prompt.id) {
                store.dispatch('setCurrentPrompt', prompt);
            }
        },
        [AI_PROMPT_UPDATED]: (prompt) => {
            store.dispatch('setPrompt', prompt);
            if (currentPrompt.value.id === prompt.id) {
                store.dispatch('setCurrentPrompt', prompt);
            }
        }
    });
};

const initFocusFromUrl = () => {
    if (window.location.hash.substring(1) == 'new') {
        window.history.replaceState({ new: true }, '', store.state.ui.urls.base);
        store.dispatch('unloadPrompt', prompt);
        setCompanionBarFocus();
    }
};

const setCompanionBarFocus = () => {
    companionBarFocus.value = true;
    /*this.$nextTick(() => {
        companionBarFocus.value = null;
    });*/
};

const unsetCompanionBarFocus = () => {
    companionBarFocus.value = false;
    /*this.$nextTick(() => {
        companionBarFocus.value = null;
    });*/
};

const fetchCompanionResources = () => {
    new FetchCompanionDataService(store).fetchCompanionData().then((data) => {
        if (data?.config) {
            title.value = data.title;
            version.value = data.version;
            versionName.value = data.versionName;
            initStore(data.config, data.promptId, data.promptUrl);
            initUrlNavigation();
            initWebSockets();
            initFocusFromUrl();
            loading.value = false;
        }
    });
};

onMounted(() => {
    fetchCompanionResources();
});
</script>
