<template>
    <div class="ui-range-interval-input" :class="containerClasses">
        <label :id="labelId" class="ui-range-label" :class="labelClasses" :aria-hidden="!showLabel || null">
            <slot>{{ label }}</slot>
        </label>
        <div class="ui-range-interval-input-group" :style="intervalStyles">
            <input
                type="range"
                :id="intervalStartId"
                v-model.number="startValue"
                :step="step"
                :min="min"
                :max="max"
                :disabled="disabled"
                @change="emitChangeEvent"
                @input="handleMinInput"
            />
            <input
                type="range"
                :id="intervalEndId"
                v-model.number="endValue"
                :step="step"
                :min="min"
                :max="max"
                :disabled="disabled"
                @change="emitChangeEvent"
                @input="handleMaxInput"
            />
        </div>

        <div class="ui-range-interval-input-group ui-range-interval-controls">
            <ui-number-input
                :id="intervalStartInputId"
                :min="min"
                :max="endValue - minRangeLength"
                :step="0.1"
                :decimals="rangeDecimals"
                :disabled="disabled"
                :show-label="false"
                v-model="startValue"
                @[numberChangeEvent]="emitChangeEvent"
            />

            <div class="ui-range-interval-input-length-label">
                {{ `${lengthLabel} ${unit}` }}
            </div>

            <ui-number-input
                :id="intervalEndInputId"
                :min="startValue + minRangeLength"
                :max="max"
                :step="0.1"
                :decimals="rangeDecimals"
                :disabled="disabled"
                :show-label="false"
                v-model="endValue"
                @[numberChangeEvent]="emitChangeEvent"
            />
        </div>
    </div>
</template>

<script>
import { Dimension } from 'cte-video-studio';
import UiNumberInput, { UI_NUMBER_INPUT_CHANGE } from './UiNumberInput.vue';

export default {
    components: { UiNumberInput },
    emits: ['update:values'],

    props: {
        id: {
            type: String
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        },
        step: {
            type: Number,
            default: 1
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        rangeDecimals: {
            type: Number,
            default: 0
        },
        minRangeLength: {
            type: Number,
            default: 1
        },
        unit: {
            type: String,
            default: ''
        },
        values: {
            type: Object,
            default: () => ({
                start: 0,
                end: -1
            })
        }
    },

    data() {
        return {
            startValue: this.values.start,
            endValue: this.values.end === -1 ? Math.floor(this.max * 100) / 100 : this.values.end,

            numberChangeEvent: UI_NUMBER_INPUT_CHANGE
        };
    },

    computed: {
        containerClasses() {
            return {
                disabled: this.disabled
            };
        },

        intervalStartId() {
            return this.id + '-start-interval';
        },

        intervalEndId() {
            return this.id + '-end-interval';
        },

        intervalStartInputId() {
            return this.id + '-input-start-interval';
        },

        intervalEndInputId() {
            return this.id + '-input-end-interval';
        },

        labelId() {
            return this.id + '-label';
        },

        labelClasses() {
            return {
                'visually-hidden': !this.showLabel
            };
        },

        lengthLabel() {
            return (this.endValue - this.startValue).toFixed(this.rangeDecimals);
        },

        intervalStyles() {
            return {
                '--progress-left': this.getPercent(this.startValue, this.min, this.max) + Dimension.PERCENT_UNIT,
                '--progress-right': this.getPercent(this.endValue, this.min, this.max) + Dimension.PERCENT_UNIT
            };
        }
    },

    watch: {
        max(newValue) {
            // change endValue to maximum value in case "max" has changed since component build
            if (this.values.end <= 0) {
                this.$nextTick(() => {
                    this.endValue = Math.floor(newValue * 100) / 100;
                });
            }
        },
        'values.start': {
            handler(newValue) {
                this.startValue = !isNaN(newValue) ? newValue : this.min;
            }
        },
        'values.end': {
            handler(newValue) {
                this.endValue = !isNaN(newValue) ? newValue : Math.floor(this.max * 100) / 100;
            }
        }
    },

    methods: {
        getPercent(value, min, max) {
            return ((value - min) / (max - min)) * 100;
        },

        // prevent user from dragging further than maximum value
        handleMinInput(event) {
            const value = parseFloat(event.target.value);
            if (value >= this.endValue - this.minRangeLength) {
                this.startValue = this.endValue - this.minRangeLength;
            } else {
                this.startValue = value;
            }
        },

        // prevent user from dragging further than minimum value
        handleMaxInput(event) {
            const value = parseFloat(event.target.value);
            if (value <= this.startValue + this.minRangeLength) {
                this.endValue = this.startValue + this.minRangeLength;
            } else {
                this.endValue = value;
            }
        },

        emitChangeEvent() {
            this.$emit('update:values', { start: this.startValue, end: this.endValue });
        }
    },

    mounted() {
        //
    }
};
</script>
