<template>
    <ui-modal
        v-bind="{ ...$props, ...$attrs }"
        ref="$modal"
        class="duplicate-edit-modal ui-modal-lg"
        :title="$t('Edit a 2Emotion template')"
        :action-label="duplicateLabel"
        :action-class="duplicateClasses"
    >
        <p>
            {{
                $t(
                    'To edit and adapt a 2Emotion template, you have to duplicate it, then make your changes on the duplicated version.'
                )
            }}
        </p>
        <p>{{ $t('You will then be able to disable the original 2Emotion template.') }}</p>
        <p v-if="unsavedChanges">
            <b>{{ $t('Your changes will not be saved, and you will be redirected to the new template.') }}</b>
        </p>
    </ui-modal>
</template>

<script>
import { Modal } from '../../../../mixins';

export default {
    mixins: [Modal],

    components: {
        //
    },

    props: {
        unsavedChanges: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            //
        };
    },

    computed: {
        duplicateLabel() {
            return this.unsavedChanges ? this.$t('Duplicate without saving') : this.$t('Duplicate and edit');
        },

        duplicateClasses() {
            return this.unsavedChanges ? 'ui-simple-button-cancel' : undefined;
        }
    },

    watch: {
        //
    },

    methods: {
        //
    },

    mounted() {
        //
    }
};
</script>
