/**
  -- Component name: TranslationRTL
  -- Type: Background animation
  -- Uses: 
  -- Categories: background
  -- Tags: translation
 */

import { Background } from '../../constants';
import gsap from 'gsap';

class TranslationRTL {
    static animate(background, data) {
        let t = gsap.timeline();
        t.set(background, { scale: Background.ANIMATION_ZOOM_SCALE_DEFAULT, transformOrigin: '0 50%' });
        t.fromTo(
            background,
            { xPercent: 0 },
            { duration: 1, xPercent: (1 - Background.ANIMATION_ZOOM_SCALE_DEFAULT) * 100 }
        );
        return t;
    }
}

export default TranslationRTL;
