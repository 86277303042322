<script setup>
import { ref, computed, watchEffect, onUnmounted } from 'vue';

const props = defineProps({
    stream: {
        type: [MediaStream, null],
        required: true
    },
    disabled: {
        type: Boolean,
        default: false
    }
});

const barSize = 12;
const barPosX = ['50%', '25%', '75%'];
const barMaxHeight = [60, 48, 48];
const barHeight = ref([barSize, barSize, barSize]);

const visualizerClass = computed(() => ({ disabled: props.disabled }));

let audioContext = null,
    analyser = null,
    streamSource = null,
    frameCallbackId = null;

const startAnalyser = (stream) => {
    audioContext = new AudioContext();

    analyser = audioContext.createAnalyser();
    analyser.smoothingTimeConstant = 0.7;
    analyser.fftSize = 32;

    streamSource = audioContext.createMediaStreamSource(stream);
    streamSource.connect(analyser);

    frameCallbackId = window.requestAnimationFrame(analyseStream);
};

const stopAnalyser = () => {
    window.cancelAnimationFrame(frameCallbackId);
    audioContext?.close();
    streamSource?.disconnect(analyser);
    audioContext = null;
    analyser = null;
    streamSource = null;
    barHeight.value = [barSize, barSize, barSize];
};

const analyseStream = () => {
    const frequencyData = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(frequencyData);

    const frequencyLevels = [[], [], []];
    frequencyData.slice(frequencyData.length % 3).forEach((value, index) => {
        frequencyLevels[index % 3].push(value);
    });

    frequencyLevels.forEach((levels, index) => {
        barHeight.value[index] = Math.max(
            barSize,
            barMaxHeight[index] * Math.min(levels.reduce((a, value) => a + value, 0) / levels.length / 128, 1)
        );
    });

    frameCallbackId = window.requestAnimationFrame(analyseStream);
};

watchEffect(() => {
    stopAnalyser();
    if (props.stream && !props.disabled) startAnalyser(props.stream);
});

onUnmounted(() => stopAnalyser());
</script>

<template>
    <svg class="audio-visualizer" :class="visualizerClass" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
        <circle cx="40" cy="40" r="40"></circle>
        <rect
            v-for="i in barPosX.length"
            :width="barSize"
            :height="barHeight[i - 1]"
            :rx="barSize / 2"
            :x="barPosX[i - 1]"
            y="50%"
            class="audio-visualizer-level-bar"
        ></rect>
    </svg>
</template>
