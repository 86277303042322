const ADMIN_ROLE_ID = 1;
const SUPERVISOR_ROLE_ID = 2;
const EDITOR_ROLE_ID = 4;
const CONTENT_EDITOR_ROLE_ID = 9;

const ADMIN_ROLE_NAME = 'admin';
const SUPERVISOR_ROLE_NAME = 'client_admin';
const EDITOR_ROLE_NAME = 'client_editor';
const CONTENT_EDITOR_ROLE_NAME = 'client_content_editor';

const Roles = {
    ADMIN: ADMIN_ROLE_NAME,
    SUPERVISOR: SUPERVISOR_ROLE_NAME,
    EDITOR: EDITOR_ROLE_NAME,
    CONTENT_EDITOR: CONTENT_EDITOR_ROLE_NAME,

    ADMIN_ROLE_ID: ADMIN_ROLE_ID,
    SUPERVISOR_ROLE_ID: SUPERVISOR_ROLE_ID,
    EDITOR_ROLE_ID: EDITOR_ROLE_ID,
    CONTENT_EDITOR_ROLE_ID: CONTENT_EDITOR_ROLE_ID,

    [ADMIN_ROLE_ID]: ADMIN_ROLE_NAME,
    [SUPERVISOR_ROLE_ID]: SUPERVISOR_ROLE_NAME,
    [EDITOR_ROLE_ID]: EDITOR_ROLE_NAME,
    [CONTENT_EDITOR_ROLE_ID]: CONTENT_EDITOR_ROLE_NAME
};

export default Roles;
