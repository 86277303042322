import { computed } from 'vue';
import { useStore } from 'vuex';

export function usePreview() {
    const store = useStore();

    const playing = computed(() => store.state.preview.playing);
    const currentTimelineId = computed(() => store.state.preview.currentTimelineId);
    const isMainTimeline = computed(() => store.state.preview.isMainTimeline);
    const currentTimelineLabels = computed(() => store.state.preview.currentTimelineLabels);

    return {
        playing,
        currentTimelineId,
        isMainTimeline,
        currentTimelineLabels
    };
}
