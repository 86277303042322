/**
 * Edit video Settings borders Vuex store extension
 */

import { Color, Dimension, types } from 'cte-video-studio';

const state = {
    enabled: false,
    color: Color.BORDER_DEFAULT,
    color__ref: Color.BORDER_DEFAULT,
    size: Dimension.ZERO,
    customized: false,
    top: {
        enabled: false,
        color: Color.NONE,
        color__ref: Color.NONE,
        size: Dimension.ZERO
    },
    right: {
        enabled: false,
        color: Color.NONE,
        color__ref: Color.NONE,
        size: Dimension.ZERO
    },
    bottom: {
        enabled: false,
        color: Color.NONE,
        color__ref: Color.NONE,
        size: Dimension.ZERO
    },
    left: {
        enabled: false,
        color: Color.NONE,
        color__ref: Color.NONE,
        size: Dimension.ZERO
    }
};

// Getters
const getters = {
    borders(state) {
        if (!state.enabled) return undefined;
        let b = { top: {}, right: {}, bottom: {}, left: {} };
        b.top.color = state.customized && state.top.enabled && !!state.top.color ? state.top.color : state.color;
        b.top.size = state.customized && state.top.enabled ? state.top.size || Dimension.ZERO : state.size;
        b.right.color =
            state.customized && state.right.enabled && !!state.right.color ? state.right.color : state.color;
        b.right.size = state.customized && state.right.enabled ? state.right.size || Dimension.ZERO : state.size;
        b.bottom.color =
            state.customized && state.bottom.enabled && !!state.bottom.color ? state.bottom.color : state.color;
        b.bottom.size = state.customized && state.bottom.enabled ? state.bottom.size || Dimension.ZERO : state.size;
        b.left.color = state.customized && state.left.enabled && !!state.left.color ? state.left.color : state.color;
        b.left.size = state.customized && state.left.enabled ? state.left.size || Dimension.ZERO : state.size;
        return b;
    }
};

// Mutations
const mutations = {
    enableBorders(state, enabled) {
        state.enabled = enabled;
    },

    setBorderColor(state, color) {
        state.color = types.isColor(color) ? color : Color.BORDER_DEFAULT;
    },

    setBorderSize(state, size) {
        state.size = parseFloat(size) || Dimension.ZERO;
    },

    customizeBorders(state, customized) {
        state.customized = customized;
    },

    /*
    DOUBLON DANS LES HOOKS

    setBorderSide(state, data) {
        if (Dimension.SIDES.indexOf(data.side) != -1) {
            state[data.side].enabled = data.enabled;
            state[data.side].color =
                state[data.side].enabled && types.isColor(data.color) ? data.color : Color.NONE;
            state[data.side].size = state[data.side].enabled && parseFloat(data.size) ? data.size : Dimension.ZERO;
        }
    },*/

    setBorderColorReference(state, ref) {
        state.color__ref = ref || state.color;
    },

    setBorderSide(state, data) {
        if (Dimension.SIDES.indexOf(data.side) != -1) {
            state[data.side].enabled = data.enabled;
            state[data.side].color = state[data.side].enabled && types.isColor(data.color) ? data.color : Color.NONE;
            state[data.side].color__ref = state[data.side].enabled
                ? data.color__ref || state[data.side].color
                : Color.NONE;
            state[data.side].size = state[data.side].enabled && parseFloat(data.size) ? data.size : Dimension.ZERO;
        }
    }
};

// Actions
const actions = {
    initBorders({ commit }, data) {
        commit('enableBorders', data.enabled);
        commit('setBorderColor', data.color);
        commit('setBorderSize', data.size);
        commit('customizeBorders', data.customized);
        commit('setBorderSide', { side: Dimension.TOP, ...data.top });
        commit('setBorderSide', { side: Dimension.RIGHT, ...data.right });
        commit('setBorderSide', { side: Dimension.BOTTOM, ...data.bottom });
        commit('setBorderSide', { side: Dimension.LEFT, ...data.left });
        commit('setBorderColorReference', data.color__ref);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
