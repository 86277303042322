import { ref } from 'vue';
import { gsap } from 'gsap';

export function useScrolling(container, currentTimePos, elementsAreDragging) {
    const scrollAnimation = ref(null);
    const isScrolling = ref(false);

    const startContinuousScroll = () => {
        if (scrollAnimation.value) return;
        const animate = () => {
            if (container.value && !elementsAreDragging.value) {
                const containerWidth = container.value.clientWidth;
                const targetScrollLeft = currentTimePos.value - containerWidth / 2;

                gsap.to(container.value, {
                    scrollLeft: targetScrollLeft,
                    duration: 0.01,
                    ease: 'none',
                    onStart: () => {
                        isScrolling.value = true;
                    },
                    onComplete: () => {
                        isScrolling.value = false;
                    }
                });
            }

            scrollAnimation.value = requestAnimationFrame(animate);
        };

        scrollAnimation.value = requestAnimationFrame(animate);
    };

    const stopContinuousScroll = () => {
        if (scrollAnimation.value) {
            cancelAnimationFrame(scrollAnimation.value);
            scrollAnimation.value = null;
        }
        isScrolling.value = false;
    };

    const scrollToCurrentTime = () => {
        if (container.value) {
            const containerWidth = container.value.clientWidth;
            const targetScrollLeft = currentTimePos.value - containerWidth / 2;

            gsap.to(container.value, {
                scrollLeft: targetScrollLeft,
                duration: 0.3,
                ease: 'power2.out'
            });
        }
    };

    return {
        startContinuousScroll,
        stopContinuousScroll,
        scrollToCurrentTime,
        isScrolling
    };
}
