/**
 * Edit video UI clipboard Vuex store
 */

import _escapeRegExp from 'lodash/escapeRegExp';
import _get from 'lodash/get';
import _isPlainObject from 'lodash/isPlainObject';
import _merge from 'lodash/merge';
import _transform from 'lodash/transform';
import { REFERENCE_KEY_SUFFIX } from '@/js/constants';

const LOCAL_STORAGE_KEY = 'cte-clipboard';
const LOCAL_STORAGE_LIFETIME = 2 * 60 * 60 * 1000; // 2 hours

const resolveData = (data, brandState, prefixes) => {
    let keyRegExp = RegExp(_escapeRegExp(REFERENCE_KEY_SUFFIX) + '$'),
        valueRegExp = RegExp('^' + _escapeRegExp(prefixes.settingsReference));

    return _transform(data, (resolved, value, key, object) => {
        if (keyRegExp.test(key) && valueRegExp.test(value)) {
            let resolvedKey = key.replace(keyRegExp, '');
            resolved[resolvedKey] = _get(brandState, value.replace(valueRegExp, ''));
            resolved[key] = value;
        } else {
            resolved[key] = _isPlainObject(value) ? resolveData(value, brandState, prefixes) : value;
        }
    });
};

const state = {
    type: '',
    timestamp: null,
    brandId: '',
    data: null
};

const getters = {
    hasPastableData: (state) => state.timestamp + LOCAL_STORAGE_LIFETIME >= Date.now(),

    hasPastableDataOfType: (state, getters) => (type) => getters.hasPastableData && type == state.type
};

const mutations = {
    setType(state, type) {
        state.type = type;
    },

    setTimestamp(state, timestamp) {
        state.timestamp = timestamp;
    },

    setBrandId(state, brandId) {
        state.brandId = brandId;
    },

    setData(state, data) {
        state.data = _merge({}, data);
    }
};

const actions = {
    init({ dispatch }) {
        dispatch('load');

        window.addEventListener('storage', (event) => {
            if (event.key == LOCAL_STORAGE_KEY) {
                dispatch('load');
            }
        });
    },

    load({ commit, dispatch, state, rootState, rootGetters }) {
        let clipboardState = window.localStorage.getItem(LOCAL_STORAGE_KEY);

        if (!!clipboardState) {
            clipboardState = JSON.parse(clipboardState);

            if (clipboardState.timestamp + LOCAL_STORAGE_LIFETIME < Date.now()) {
                dispatch('clear');
            }

            commit('setType', clipboardState.type);
            commit('setTimestamp', clipboardState.timestamp);
            commit('setBrandId', clipboardState.brandId);
            commit(
                'setData',
                state.brandId == rootState.branding.id
                    ? clipboardState.data
                    : resolveData(clipboardState.data, rootState.branding, rootState.ui.prefixes)
            );
        }
    },

    clear({ commit }) {
        commit('setType', '');
        commit('setTimestamp', null);
        commit('setBrandId', '');
        commit('setData', null);
        window.localStorage.removeItem(LOCAL_STORAGE_KEY);
    },

    copy({ commit, state, rootState }, { type, data }) {
        commit('setType', type);
        commit('setTimestamp', Date.now());
        commit('setBrandId', rootState.branding.id);
        commit('setData', data);
        window.localStorage.setItem(
            LOCAL_STORAGE_KEY,
            JSON.stringify({
                type: state.type,
                timestamp: state.timestamp,
                brandId: state.brandId,
                data: _merge({}, data)
            })
        );
    }
};

export default {
    namespaced: true,

    modules: {
        //
    },

    state,
    getters,
    mutations,
    actions
};
