import AuthApiService from '@/js/infrastructure/api/auth/AuthApiService.js';

const LOGIN_URL = import.meta.env.VITE_IDENTITY_URL;

export default class AuthDataService {
    constructor(store) {
        this.store = store;
        this.authApiService = new AuthApiService(store);
    }

    async logout() {
        this.authApiService.postLogout().then(() => {
            window.location.assign(LOGIN_URL);
        });
    }
}
