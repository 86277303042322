<template>
    <div class="gen-image-ai">
        <div class="gen-image-ai-configuration">
            <ui-text-input
                id="gen-image-ai"
                v-model="prompt"
                :placeholder="$t('image-generation.text-placeholder')"
                :horizontal="false"
                :show-label="false"
                :disabled="generating"
            />

            <ui-image-generation-options
                :disabled="generating"
                v-model:style="selectedStyle"
                v-model:format="selectedFormat"
            />
        </div>

        <button
            class="gen-image-ai-generate-button"
            :disabled="unauthorized || !enoughCredits || generationDisabled || forbiddenWordUsed || generating"
            v-tooltip="!!buttonData.tooltipKey && $t(buttonData.tooltipKey)"
            @click="generateImages"
        >
            <span v-if="!generating" class="action-label">{{ $t('image-generation.generate') }}</span>
            <svg-icon v-else class="loader gen-image-ai-generating" icon="dots-loader" />
            <fa-icon v-if="!!buttonData.icon" :icon="buttonData.icon" class="gen-image-ai-generate-button-icon" />
        </button>

        <span v-if="hasGenerationError" class="gen-image-ai-generation-error">{{ $t('image-generation.error') }}</span>

        <transition name="suggestions">
            <div class="gen-image-ai-suggestions" v-if="!hasGenerated">
                <ui-image-generation-suggestion
                    v-for="suggestion in suggestions"
                    :suggestion="suggestion"
                    @use-prompt="handlePromptUse"
                />
            </div>
        </transition>

        <div :class="resultsClasses">
            <transition-group name="results">
                <ui-image-generation-result
                    v-for="image in results"
                    :key="image.uuid"
                    :image="image"
                    :importing-uuid="importingUuid"
                    @use-image="handleImageUse"
                />
            </transition-group>
        </div>
    </div>
</template>

<script>
import { ref, defineComponent, onMounted, computed } from 'vue';

import UiTextInput from '../UiTextInput.vue';
import UiImageGenerationOptions from './UiImageGenerationOptions.vue';
import UiImageGenerationResult from './UiImageGenerationResult.vue';
import UiImageGenerationSuggestion from './UiImageGenerationSuggestion.vue';

import {
    FORBIDDEN_WORDS,
    PORTRAIT_FORMAT,
    PROMPT_SUGGESTIONS,
    WIDESCREEN_FORMAT,
    SQUARE_FORMAT,
    STYLE_PHOTO
} from '../../constants/image-generation.js';
import GenerateImageAIService from '../../videos/application/services/GenerateImageAIService.js';
import UsesTooltip from '../../mixins/UsesTooltip.js';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'UiImageGeneration',
    components: {
        UiImageGenerationResult,
        UiImageGenerationOptions,
        UiTextInput,
        UiImageGenerationSuggestion
    },
    mixins: [UsesTooltip],

    data() {
        return {
            suggestions: PROMPT_SUGGESTIONS
        };
    },

    setup(_, { emit }) {
        const store = useStore();
        const generationService = new GenerateImageAIService();

        const formatRatio = store.getters['display/formatRatio'];
        const defaultFormat = formatRatio > 1 ? WIDESCREEN_FORMAT : formatRatio < 1 ? PORTRAIT_FORMAT : SQUARE_FORMAT;

        const prompt = ref('');
        const selectedStyle = ref(STYLE_PHOTO);
        const selectedFormat = ref(defaultFormat);

        const remainingCredits = ref(0);
        const enoughCredits = computed(() => remainingCredits.value > 0);
        const unauthorized = !store.state.ui.permissions.genImageAi;
        const generationDisabled = computed(() => prompt.value.length < 3);
        const generating = ref(false);
        const importingUuid = ref(null);
        const hasGenerated = ref(false);
        const hasGenerationError = ref(false);

        const forbiddenWordUsed = computed(() =>
            FORBIDDEN_WORDS.some((word) => prompt.value.toLowerCase().split(' ').includes(word))
        );

        const buttonData = computed(() =>
            generationService.getGenerateButtonData(
                unauthorized,
                store.state.ui.isSupervisor,
                enoughCredits.value,
                forbiddenWordUsed.value
            )
        );

        const results = ref([]);
        const resultsClasses = computed(() => [
            'gen-image-ai-results',
            { 'gen-image-ai-results-importing': !!importingUuid.value }
        ]);

        const handlePromptUse = ({ text, style }) => {
            prompt.value = text;
            selectedStyle.value = style;
        };

        const generateImages = async () => {
            hasGenerationError.value = false;
            generating.value = true;
            hasGenerated.value = true;

            const response = await generationService.generateImages(
                prompt.value,
                selectedFormat.value,
                selectedStyle.value
            );
            if (response.error) hasGenerationError.value = true;
            else {
                results.value.unshift(...response.images);
                remainingCredits.value = response.remaining;
            }

            generating.value = false;
        };

        const handleImageUse = async (imageUuid) => {
            importingUuid.value = imageUuid;

            const response = await generationService.useImage(imageUuid);
            if (response.error) hasGenerationError.value = true;
            else emit('select-media', response.uuid);

            importingUuid.value = null;
        };

        const handleClearHistory = async () => {
            await generationService.clearUserHistory();
            results.value = []; // reset
        };

        onMounted(async () => {
            const data = await generationService.fetchUserHistory();
            if (!data.error) {
                results.value = data.history;
                remainingCredits.value = data.remaining;
            }
        });

        return {
            prompt,
            selectedStyle,
            selectedFormat,
            hasGenerated,
            hasGenerationError,
            forbiddenWordUsed,
            enoughCredits,
            importingUuid,
            unauthorized,
            generationDisabled,
            buttonData,
            results,
            resultsClasses,
            generating,
            generateImages,
            handlePromptUse,
            handleImageUse,
            handleClearHistory
        };
    }
});
</script>

<style scoped>
.suggestions-enter-active,
.suggestions-leave-active {
    transition: opacity 0.5s ease;
}

.suggestions-enter-from,
.suggestions-leave-to {
    opacity: 0;
}

.results-enter-active,
.results-leave-active {
    transition: all 0.5s ease;
}
.results-enter-from,
.results-leave-to {
    opacity: 0;
    transform: translateY(30px);
}
</style>
