<template>
    <ui-card :id="id" :class="'ui-card-' + id" :title="$t('AI Settings')">
        <template #header-icon>
            <fa-icon class="icon" icon="fa-solid fa-microchip-ai" />
        </template>

        <ui-switch-button
            id="settings-enable-ai"
            class="ui-card-row"
            :label="$t('Enable AI usage')"
            v-model="aiEnabled"
            :disabled="readOnly"
        />

        <ui-text-input
            v-if="aiEnabled"
            id="settings-ai-name"
            class="settings-ai-name ui-card-row"
            :label="$t('Name')"
            :model-value="aiName"
            :default-value="sequenceName"
            :disabled="readOnly"
            @[textInputChangeEvent]="handleNameInput"
        />

        <div class="settings-ai-landscape ui-card-row" v-if="aiEnabled">
            <label>
                <svg-icon icon="landscape-format-icon" class="video-format-icon" />
                <span class="visually-hidden">{{ $t('Landscape format') }}</span>
            </label>
            <ui-dropdown
                id="settings-ai-landscape"
                :select="true"
                :default-label="$t('None')"
                :items="filteredAiLandscapeSequences"
                v-model="aiLandscapeFormat"
                :disabled="readOnly"
                :show-menu-bottom="!loadedAiSequences"
                @[dropdownShowEvent]="loadAiSequences"
            >
                <template #dropdown-menu-top>
                    <ui-text-input
                        id="settings-ai-landscape-sequence-filter"
                        type="search"
                        :placeholder="$t('Search')"
                        :show-label="false"
                        icon="search-icon"
                        v-model="aiLandscapeSequencesFilter"
                    />
                </template>
                <template #dropdown-menu-bottom>
                    <svg-icon icon="dots-loader" />
                </template>
            </ui-dropdown>
            <a
                :href="aiLandscapeFormatUrl"
                target="_blank"
                class="settings-ai-format-link"
                :class="{ disabled: !aiLandscapeFormatUrl }"
            >
                <fa-icon class="icon" icon="fa-regular fa-arrow-up-right-from-square" />
                <span class="visually-hidden">{{ $t('Edit this format') }}</span>
            </a>
        </div>

        <div class="settings-ai-square ui-card-row" v-if="aiEnabled">
            <label>
                <svg-icon icon="square-format-icon" class="video-format-icon" />
                <span class="visually-hidden">{{ $t('Square format') }}</span>
            </label>
            <ui-dropdown
                id="settings-ai-square"
                :select="true"
                :default-label="$t('None')"
                :items="filteredAiSquareSequences"
                v-model="aiSquareFormat"
                :disabled="readOnly"
                :show-menu-bottom="!loadedAiSequences"
                @[dropdownShowEvent]="loadAiSequences"
            >
                <template #dropdown-menu-top>
                    <ui-text-input
                        id="settings-ai-square-sequence-filter"
                        type="search"
                        :placeholder="$t('Search')"
                        :show-label="false"
                        icon="search-icon"
                        v-model="aiSquareSequencesFilter"
                    />
                </template>
                <template #dropdown-menu-bottom>
                    <svg-icon icon="dots-loader" />
                </template>
            </ui-dropdown>
            <a
                :href="aiSquareFormatUrl"
                target="_blank"
                class="settings-ai-format-link"
                :class="{ disabled: !aiSquareFormatUrl }"
            >
                <fa-icon class="icon" icon="fa-regular fa-arrow-up-right-from-square" />
                <span class="visually-hidden">{{ $t('Edit this format') }}</span>
            </a>
        </div>

        <div class="settings-ai-portrait ui-card-row" v-if="aiEnabled">
            <label>
                <svg-icon icon="portrait-format-icon" class="video-format-icon" />
                <span class="visually-hidden">{{ $t('Portrait format') }}</span>
            </label>
            <ui-dropdown
                id="settings-ai-portrait"
                :select="true"
                :default-label="$t('None')"
                :items="filteredAiPortraitSequences"
                v-model="aiPortraitFormat"
                :disabled="readOnly"
                :show-menu-bottom="!loadedAiSequences"
                @[dropdownShowEvent]="loadAiSequences"
            >
                <template #dropdown-menu-top>
                    <ui-text-input
                        id="settings-ai-portrait-sequence-filter"
                        type="search"
                        :placeholder="$t('Search')"
                        :show-label="false"
                        icon="search-icon"
                        v-model="aiPortraitSequencesFilter"
                    />
                </template>
                <template #dropdown-menu-bottom>
                    <svg-icon icon="dots-loader" />
                </template>
            </ui-dropdown>
            <a
                :href="aiPortraitFormatUrl"
                target="_blank"
                class="settings-ai-format-link"
                :class="{ disabled: !aiPortraitFormatUrl }"
            >
                <fa-icon class="icon" icon="fa-regular fa-arrow-up-right-from-square" />
                <span class="visually-hidden">{{ $t('Edit this format') }}</span>
            </a>
        </div>

        <ui-number-input
            v-if="aiEnabled"
            id="settings-ai-duration"
            class="ui-card-row"
            :label="$t('Duration (in s.)')"
            v-model="aiDuration"
            :min="0"
            :disabled="readOnly"
        />

        <div class="settings-ai-metadata" v-if="aiEnabled">
            <ui-text-input
                id="settings-ai-metadata-usage"
                class="ui-card-row"
                :textarea="true"
                :label="$t('Usage')"
                :horizontal="false"
                :model-value="aiMetadataUsage"
                :disabled="readOnly"
                @[textInputInputEvent]="_debouncedHandleMetadataInput('usage', $event)"
            />

            <ui-text-input
                id="settings-ai-metadata-description"
                class="ui-card-row"
                :textarea="true"
                :label="$t('Description')"
                :horizontal="false"
                :model-value="aiMetadataDescription"
                :disabled="readOnly"
                @[textInputInputEvent]="_debouncedHandleMetadataInput('description', $event)"
            />

            <ui-text-input
                id="settings-ai-metadata-custom-elements"
                class="ui-card-row"
                :textarea="true"
                :label="$t('Custom elements')"
                :horizontal="false"
                :model-value="aiMetadataCustomElements"
                :disabled="readOnly"
                @[textInputInputEvent]="_debouncedHandleMetadataInput('custom_elements', $event)"
            />

            <ui-text-input
                id="settings-ai-metadata-style"
                class="ui-card-row"
                :textarea="true"
                :label="$t('Style')"
                :horizontal="false"
                :model-value="aiMetadataStyle"
                :disabled="readOnly"
                @[textInputInputEvent]="_debouncedHandleMetadataInput('style', $event)"
            />

            <ui-text-input
                id="settings-ai-metadata-message-styles"
                class="ui-card-row"
                :textarea="true"
                :label="$t('Message styles')"
                :horizontal="false"
                :model-value="aiMetadataMessageStyles"
                :disabled="readOnly"
                @[textInputInputEvent]="_debouncedHandleMetadataInput('message_styles', $event)"
            />
        </div>
    </ui-card>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import _debounce from 'lodash/debounce';
import { Duration } from 'cte-video-studio';
import * as UiComponents from '../../../../../components';
import { UI_DROPDOWN_MENU_SHOW } from '../../../../../components/UiDropdown.vue';
import { UI_TEXT_INPUT_INPUT, UI_TEXT_INPUT_CHANGE } from '../../../../../components/UiTextInput.vue';
import { ModifiesHistory, SavesVideo } from '../../../../mixins';

const UPDATE_INPUT_DELAY = 1500;

export default {
    mixins: [ModifiesHistory, SavesVideo],

    components: {
        ...UiComponents
    },

    props: {
        id: {
            type: String,
            default: 'settings-ai'
        }
    },

    data() {
        return {
            dropdownShowEvent: UI_DROPDOWN_MENU_SHOW,
            textInputInputEvent: UI_TEXT_INPUT_INPUT,
            textInputChangeEvent: UI_TEXT_INPUT_CHANGE,

            aiLandscapeSequencesFilter: '',
            aiSquareSequencesFilter: '',
            aiPortraitSequencesFilter: ''
        };
    },

    computed: {
        ...mapState({
            readOnly: (state) => state.ui.readOnly,
            loadedAiSequences: (state) => state.ui.aiSequences,
            sequenceIdPrefix: (state) => state.ui.prefixes.sequenceId,
            state: (state) => state.settings.ai,
            sequenceDuration: (state) => state.sequences[state.sequences.order[0]]?.options.duration
        }),

        ...mapGetters({
            aiSequenceEditUrl: 'ui/aiSequenceEditUrl'
        }),

        sequenceId() {
            return this.$store.state.sequences.order[0].replace(this.sequenceIdPrefix, '');
        },

        sequenceName() {
            return this.$store.state.settings.name;
        },

        aiEnabled: {
            get() {
                return this.state.enabled;
            },
            set(value) {
                this.startHistoryStep();
                this.$store.commit('settings/enableAi', value);
                if (!!value && !this.aiName) this.updateAiSettings('name', this.sequenceName);
                this.saveVideo();
            }
        },

        sortedAiSequences() {
            return (
                this.loadedAiSequences?.sort((sequenceA, sequenceB) => sequenceA.name.localeCompare(sequenceB.name)) ||
                []
            );
        },

        aiName() {
            return this.state.name || '';
        },

        aiSequences() {
            let sequences = [];

            if (!!this.sortedAiSequences.length) {
                sequences = this.sortedAiSequences;
            } else {
                Object.values(this.state.formats).forEach((format) => {
                    if (!!format.id && !sequences.find((sequence) => sequence.id == format.id)) {
                        sequences.push({
                            id: format.id,
                            name: format.name,
                            used: true,
                            permissions: format.permissions
                        });
                    }
                });
            }

            return [
                { value: null, label: this.$t('None'), tag: 'span' },
                { value: this.sequenceId, label: this.$t('This sequence'), tag: 'span' },
                ...sequences
                    .filter((sequence) => sequence.id != this.sequenceId)
                    .map((sequence) => ({
                        value: sequence.id,
                        label: sequence.name,
                        tag: 'span',
                        classes: sequence.used ? 'used' : '',
                        tooltip: true,
                        metadata: {
                            editUrl: sequence.permissions.edit ? this.aiSequenceEditUrl(sequence.id) : ''
                        }
                    }))
            ];
        },

        filteredAiLandscapeSequences() {
            if (!/\S/.test(this.aiLandscapeSequencesFilter)) return this.aiSequences;

            let filters = this.aiLandscapeSequencesFilter.split(/\s+/).filter((f) => f.length > 1);
            return this.aiSequences.filter((sequence) =>
                filters.every((filter) => sequence.label.toLowerCase().includes(filter.toLowerCase()))
            );
        },

        aiLandscapeFormat: {
            get() {
                return this.state.formats.format_16x9?.id;
            },
            set(value) {
                if (value !== this.state.formats.format_16x9?.id) {
                    this.startHistoryStep();
                    this.updateAiFormats('format_16x9', { id: value, name: '' });
                    this.saveVideo();
                }
            }
        },

        aiLandscapeFormatUrl() {
            let format = this.aiSequences.find((sequence) => sequence.value === this.aiLandscapeFormat);
            return format?.metadata?.editUrl;
        },

        filteredAiSquareSequences() {
            if (!/\S/.test(this.aiSquareSequencesFilter)) return this.aiSequences;

            let filters = this.aiSquareSequencesFilter.split(/\s+/).filter((f) => f.length > 1);
            return this.aiSequences.filter((sequence) =>
                filters.every((filter) => sequence.label.toLowerCase().includes(filter.toLowerCase()))
            );
        },

        aiSquareFormat: {
            get() {
                return this.state.formats.format_1x1?.id;
            },
            set(value) {
                if (value !== this.state.formats.format_1x1?.id) {
                    this.startHistoryStep();
                    this.updateAiFormats('format_1x1', { id: value, name: '' });
                    this.saveVideo();
                }
            }
        },

        aiSquareFormatUrl() {
            let format = this.aiSequences.find((sequence) => sequence.value === this.aiSquareFormat);
            return format?.metadata?.editUrl;
        },

        filteredAiPortraitSequences() {
            if (!/\S/.test(this.aiPortraitSequencesFilter)) return this.aiSequences;

            let filters = this.aiPortraitSequencesFilter.split(/\s+/).filter((f) => f.length > 1);
            return this.aiSequences.filter((sequence) =>
                filters.every((filter) => sequence.label.toLowerCase().includes(filter.toLowerCase()))
            );
        },

        aiPortraitFormat: {
            get() {
                return this.state.formats.format_9x16?.id;
            },
            set(value) {
                if (value !== this.state.formats.format_9x16?.id) {
                    this.startHistoryStep();
                    this.updateAiFormats('format_9x16', { id: value, name: '' });
                    this.saveVideo();
                }
            }
        },

        aiPortraitFormatUrl() {
            let format = this.aiSequences.find((sequence) => sequence.value === this.aiPortraitFormat);
            return format?.metadata?.editUrl;
        },

        aiDuration: {
            get() {
                return this.state.duration || this.sequenceDuration || 0;
            },
            set(value) {
                if (value == 0) value = Duration.NONE;
                this.startHistoryStep();
                this.updateAiSettings('duration', value);
                this.saveVideo();
            }
        },

        aiMetadataUsage() {
            return this.state.metadata.usage || '';
        },

        aiMetadataDescription() {
            return this.state.metadata.description || '';
        },

        aiMetadataCustomElements() {
            return this.state.metadata.custom_elements || '';
        },

        aiMetadataStyle() {
            return this.state.metadata.style || '';
        },

        aiMetadataMessageStyles() {
            return this.state.metadata.message_styles || '';
        }
    },

    watch: {
        //
    },

    methods: {
        ...mapMutations({
            setAiSettings: 'settings/setAiSettings',
            setAiFormats: 'settings/setAiFormats'
        }),

        ...mapActions({
            loadAiSequences: 'ui/loadAiSequences',
            applyAiSettings: 'ui/applyAiSettings'
        }),

        updateAiSettings(key, value) {
            let { enabled, url, currentUrl, formats, ...settings } = this.state;
            this.setAiSettings({ ...settings, [key]: value });
        },

        updateAiMetadata(key, value) {
            let { enabled, url, currentUrl, metadata, formats, ...settings } = this.state;
            this.setAiSettings({ ...settings, metadata: { ...metadata, [key]: value } });
        },

        handleNameInput(value) {
            if (value !== this.state.name) {
                this.startHistoryStep();
                this.updateAiSettings('name', value);
                this.saveVideo();
            }
        },

        handleMetadataInput(key, value) {
            if (value !== this.state.metadata[key]) {
                this.startHistoryStep();
                this.updateAiMetadata(key, value);
                this.saveVideo();
            }
        },

        updateAiFormats(key, value) {
            this.setAiFormats({ ...this.state.formats, [key]: value });
        }
    },

    created() {
        this._debouncedHandleMetadataInput = _debounce(this.handleMetadataInput, UPDATE_INPUT_DELAY);
    },

    mounted() {
        //
    }
};
</script>
