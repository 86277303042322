/**
 * Video Studio
 * Background animations index file
 * Source: https://stackoverflow.com/questions/42199872/is-it-possible-to-import-vue-files-in-a-folder
 */

const modules = import.meta.glob('./*.js', { eager: true });
const animations = {};
for (const module in modules) {
    const name = module
        .split('/')
        .pop()
        .replace(/(\.\/|\.js)/g, '');

    if (name === 'index') continue;
    animations[name] = modules[module].default;
}

export default animations;
