<template>
    <ui-color-selector
        v-if="backgroundType !== COLOR_TYPE && enableOverlay"
        :id="'card-seq' + seqId + '-bg-overlay-color'"
        class="ui-content-row"
        v-model:color="overlayColor"
        default-color="color1"
        :palette="brandPalette"
        :enable-alpha="true"
        :default-alpha="overlayDefaultOpacity"
        :enable-other-colors="brandEnableOtherColors"
        :label="$t('Color')"
        :disabled="readOnly || hasPreviousBackground"
    />
    <ui-library-selector
        v-if="backgroundType !== COLOR_TYPE && enableOverlay && !isBrandLibraryEmpty('overlays') && isEmotionMode"
        :id="'card-seq' + seqId + '-bg-overlay-type'"
        class="ui-content-row"
        v-model="overlayType"
        :default-value="overlayDefaultType"
        library="overlays"
        preview-path="overlays/"
        :label="$t('Effect')"
        :disabled="readOnly || hasPreviousBackground"
        @[librarySelectorShowEvent]="openLibrary"
        @[librarySelectorPreviewChangeEvent]="previewOverlayType"
    />
    <ui-switch-button
        v-if="
            backgroundType !== COLOR_TYPE &&
            enableOverlay &&
            !isBrandLibraryEmpty('overlays') &&
            overlayType !== overlayDefaultType &&
            isEmotionMode
        "
        :id="'card-seq' + seqId + '-bg-overlay-enable-animation'"
        class="ui-content-row"
        :label="$t('Animated')"
        v-model="enableOverlayAnimation"
        :disabled="readOnly || hasPreviousBackground"
    />
</template>

<script>
import { Background, Color, Overlay, STAGE_LOADING_ENDED_EVENT } from 'cte-video-studio';
import { mapGetters, mapState } from 'vuex';
import {
    UI_LIBRARY_SELECTOR_SHOW,
    UI_LIBRARY_SELECTOR_PREVIEW_CHANGE
} from '../../../../components/UiLibrarySelector.vue';
import UiColorSelector from '../../../../components/UiColorSelector.vue';
import UiLibrarySelector from '../../../../components/UiLibrarySelector.vue';
import UiSwitchButton from '../../../../components/UiSwitchButton.vue';
import ModifiesHistory from '../../../mixins/ModifiesHistory';
import SavesVideo from '../../../mixins/SavesVideo';

export default {
    mixins: [ModifiesHistory, SavesVideo],
    name: 'UiBackgroundOverlaySelector',
    components: { UiSwitchButton, UiLibrarySelector, UiColorSelector },
    inject: ['$videoStudio'],
    props: {
        seqId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            COLOR_TYPE: Background.COLOR_TYPE,
            overlayDefaultOpacity: Color.BACKGROUND_OPACITY_DEFAULT,
            overlayDefaultType: Overlay.DEFAULT,
            librarySelectorShowEvent: UI_LIBRARY_SELECTOR_SHOW,
            librarySelectorPreviewChangeEvent: UI_LIBRARY_SELECTOR_PREVIEW_CHANGE
        };
    },
    computed: {
        ...mapState({
            readOnly: (state) => state.ui.readOnly,
            brandPalette: (state) => state.branding.palette,
            brandEnableOtherColors: (state) => state.branding.enableOtherColors,
            libraryTagOrders: (state) => state.branding.libraries.tagOrders,
            sequenceState(state) {
                return state.sequences[this.seqId];
            }
        }),

        ...mapGetters({
            getBrandLibraryItems: 'branding/libraries/libraryItems',
            isEmotionMode: 'ui/isEmotionMode'
        }),

        sequenceStoreModulePath() {
            return 'sequences/' + this.seqId;
        },

        resolvedId() {
            return this.$store.getters[this.sequenceStoreModulePath + '/resolvedId'];
        },

        resolvedBackgroundState() {
            return this.$store.state.sequences[this.resolvedId].background;
        },

        hasPreviousBackground() {
            return this.$store.getters[this.sequenceStoreModulePath + '/hasPreviousBackground'];
        },

        enableOverlay() {
            return this.resolvedBackgroundState.overlay.enabled;
        },

        backgroundType() {
            return this.sequenceState.background.type;
        },

        isBrandLibraryEmpty() {
            return (library, category) => !this.getBrandLibraryItems(library, category).length;
        },

        overlayColor: {
            get() {
                let color = { ref: Color.NONE, value: Color.NONE, alpha: Color.BACKGROUND_OPACITY_DEFAULT };

                if (this.enableOverlay) {
                    color.ref = this.resolvedBackgroundState.overlay.color.start__ref;
                    color.value = this.resolvedBackgroundState.overlay.color.start;
                    color.alpha = this.resolvedBackgroundState.overlay.opacity;
                }

                return color;
            },
            set({ ref, value, alpha }) {
                if (!this.hasPreviousBackground) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(this.sequenceStoreModulePath + '/setBackgroundOverlayColor', {
                            start__ref: ref,
                            start: value
                        });
                        this.$store.commit(this.sequenceStoreModulePath + '/setBackgroundOverlayOpacity', alpha);
                    });
                }
            }
        },

        overlayType: {
            get() {
                return this.resolvedBackgroundState.overlay.type;
            },
            set(value) {
                if (!this.hasPreviousBackground) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(this.sequenceStoreModulePath + '/setBackgroundOverlay', value);
                    });
                }
            }
        },

        enableOverlayAnimation: {
            get() {
                return this.resolvedBackgroundState.overlay.animation.enabled;
            },
            set(value) {
                if (!this.hasPreviousBackground) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(this.sequenceStoreModulePath + '/enableBackgroundOverlayAnimation', value);
                    });
                }
            }
        }
    },

    methods: {
        saveHistoryStep(actions) {
            this.startHistoryStep();
            actions();
            this.saveVideo();
        },

        playPreview(time) {
            this.$store.commit('preview/setCurrentTimelineId', this.seqId);
            this.$videoStudio.studio.$stage.seekSequenceTimeline(this.seqId, time || 0.001);
            this.$videoStudio.studio.$stage.playTimeline();
        },

        // Note: This method looks the same as playBackgroundAnimationTypePreview(), but we keep it different
        // in case we'd want the preview to start at a different timing.
        playOverlayTypePreview() {
            this.playPreview(0.001);
        },

        previewOverlayType(type, cancel) {
            this.ignoreHistoryStep(() => {
                this.$store.commit(this.sequenceStoreModulePath + '/setBackgroundOverlay', type);
                if (!cancel && this.enableOverlayAnimation)
                    this.$videoStudio.studio.$stage.$el.addEventListener(
                        STAGE_LOADING_ENDED_EVENT,
                        this.playOverlayTypePreview,
                        {
                            once: true
                        }
                    );
            });
        },

        openLibrary(selector) {
            const isMusicLibrary = selector.library === 'musics';

            this.$store.commit('ui/setCurrentLibrary', {
                id: selector.library,
                category: selector.category,
                tagOrder: selector.tagOrder || this.libraryTagOrders[selector.library] || null,
                selectedItemId: isMusicLibrary ? selector.modelValue.id : selector.modelValue,
                selector
            });
        }
    }
};
</script>
