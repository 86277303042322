<!--
  -- Component name: Number2
  -- Type: Transition
  -- Uses: color.start
  -- Tags: number
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <defs>
            <mask :id="svgMaskID">
                <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
                <path
                    class="color-number"
                    d="M609.3,464.6c1.3,0,2.7,0,4.1,0c37.9,0,75.7,0.1,113.6-0.1c4,0,5,1.1,4.9,5c-0.3,8.2-0.2,16.3,0,24.5
				c0.1,3.1-1.1,4-4.1,4c-56.9-0.1-113.8-0.1-170.8,0c-3.1,0-4.4-0.9-4.3-4.2c0.2-7.5,0.2-15,0-22.5c-0.1-3.3,0.9-5.5,3.2-7.8
				c28-27.9,56.2-55.6,83.8-84c11.1-11.5,21.2-24.1,30.8-36.9c8.9-11.9,13.5-26.1,13.6-41c0.2-17.6-8.3-33.6-27.6-38.9
				c-14.3-3.9-28.9-4.4-43.5-2.7c-16,1.8-31.9,4.3-48.6,6.6c-0.4-2.6-1.1-5.9-1.5-9.3c-0.8-5.7-1.5-11.5-2.3-17.3
				c-0.7-4.9-0.3-5.6,4.5-6.7c13.8-3.3,27.9-5.4,42.1-5.8c11.5-0.3,23-1,34.5-0.7c17.9,0.6,35.5,3.2,51.8,11.4
				c20.7,10.4,31.5,27.4,34.2,50.2c3.9,32.6-7.5,59.9-28.2,84.1c-21.9,25.5-46.7,48.2-70.7,71.6
				C622.3,450.6,615.9,457.7,609.3,464.6z"
                />
            </mask>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" :fill="state.color.start" :mask="svgMask" />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {
        svgMaskID() {
            return this.containerID + '-number2-mask';
        },

        svgMask() {
            return 'url(#' + this.svgMaskID + ')';
        }
    },

    watch: {
        svgMaskRadius: {
            handler() {
                this.updateTimeline();
            }
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            t.fromTo(
                '#' + this.svgMaskID + ' .color-number',
                { scale: 1 },
                { scale: 1.5, transformOrigin: '50% 50%', duration: 1 },
                0
            );
            t.fromTo(
                '#' + this.svgMaskID + ' .color-number',
                { scale: 1.5 },
                { scale: 50, translateX: '-100%', transformOrigin: '50% 50%', duration: 0.2 },
                1
            );
            return t;
        }
    }
};
</script>
