/**
 * Video Studio
 * Transitions index file
 * Source: https://stackoverflow.com/questions/42199872/is-it-possible-to-import-vue-files-in-a-folder
 */

const modules = import.meta.glob('./*.vue', { eager: true });
const transitions = {};
for (const module in modules) {
    const name = module
        .split('/')
        .pop()
        .replace(/(\.\/|\.vue)/g, '');

    transitions[name] = modules[module].default;
}

export default transitions;
