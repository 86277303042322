<template>
    <ui-card :id="id" :class="'ui-card-' + id" :title="$t('Video branding')">
        <template #header-icon>
            <fa-icon class="icon" icon="fa-solid fa-palette" />
        </template>

        <template #footer>
            <ui-dropdown
                id="settings-logo"
                class="centered logo-dropdown"
                :menu="false"
                :caret="false"
                :scrollable="false"
                menu-placement="bottom-start"
                menu-strategy="fixed"
            >
                <template #dropdown-toggle>
                    <svg-icon icon="logo-icon" />
                    <span>{{ $t('Logo') }}</span>
                </template>

                <ui-switch-button
                    id="settings-enable-brand-logo"
                    class="ui-content-row"
                    :label="$t('Display brand logo')"
                    v-model="brandLogoEnabled"
                    :disabled="readOnly"
                />

                <ui-media-selector
                    v-if="brandLogoEnabled"
                    id="settings-brand-logo-image"
                    class="ui-content-row"
                    key="media-selector-brand-logo-image"
                    v-model:media="brandLogoImage"
                    :type="mediaTypeImage"
                    :label="$t('Brand logo')"
                    :disabled="readOnly"
                    icon="fa-solid fa-image"
                    @[mediaSelectorShowEvent]="openMediaLibrary"
                />
                <ui-library-selector
                    v-if="brandLogoEnabled"
                    id="settings-brand-logo-animation"
                    class="ui-content-row"
                    key="library-selector-brand-logo-animation"
                    v-model="brandLogoAnimationType"
                    :default-value="brandLogoDefaultAnimationType"
                    library="logoAnimations"
                    preview-path="logos/"
                    :label="$t('Animation')"
                    :disabled="readOnly"
                    icon="fa-solid fa-sparkles"
                    @[librarySelectorShowEvent]="openLibrary"
                />

                <ui-switch-button
                    v-if="brandLogoEnabled"
                    id="settings-override-brand-logo-size"
                    class="ui-content-row"
                    :label="$t('Change size and position')"
                    v-model="brandLogoOverridePosition"
                    :disabled="readOnly"
                />
                <div v-if="brandLogoOverridePosition" class="brand-logo-position">
                    <ui-number-input
                        id="settings-brand-logo-width"
                        class="ui-content-row"
                        v-model="brandLogoWidth"
                        :default-value="''"
                        :min="1"
                        :decimals="1"
                        :label="$t('Width')"
                        :disabled="readOnly"
                    />
                    <ui-number-input
                        id="settings-brand-logo-height"
                        class="ui-content-row"
                        v-model="brandLogoHeight"
                        :default-value="''"
                        :min="1"
                        :decimals="1"
                        :label="$t('Height')"
                        :disabled="readOnly"
                    />
                    <div class="ui-content-field ui-content-row">
                        <label>{{ $t('Horizontal position') }}</label>
                        <ui-dropdown
                            id="settings-brand-logo-hor-position"
                            :select="true"
                            :editable="true"
                            :editable-reg-exp="/^\-?\d+(?:(?:\.|,)\d+)?$/"
                            v-model="brandLogoHorizontalPosition"
                            :disabled="readOnly"
                        >
                            <ui-dropdown-item
                                v-for="position in brandLogoHorizontalPositions"
                                :key="position"
                                :value="position"
                                :label="$t('studio.alignments.' + position)"
                            />
                        </ui-dropdown>
                    </div>
                    <div class="ui-content-field ui-content-row">
                        <label>{{ $t('Vertical position') }}</label>
                        <ui-dropdown
                            id="settings-brand-logo-ver-position"
                            :select="true"
                            :editable="true"
                            :editable-reg-exp="/^\-?\d+(?:(?:\.|,)\d+)?$/"
                            v-model="brandLogoVerticalPosition"
                            :disabled="readOnly"
                        >
                            <ui-dropdown-item
                                v-for="position in brandLogoVerticalPositions"
                                :key="position"
                                :value="position"
                                :label="$t('studio.alignments.' + position)"
                            />
                        </ui-dropdown>
                    </div>
                </div>
            </ui-dropdown>

            <ui-dropdown
                id="settings-borders"
                class="centered borders-dropdown"
                ref="$bordersDropdown"
                :menu="false"
                :caret="false"
                :scrollable="false"
                menu-placement="bottom"
                menu-strategy="fixed"
                @[dropdownShowEvent]="enableBorders"
            >
                <template #dropdown-toggle>
                    <svg-icon icon="borders-icon" />
                    <span>{{ $t('Borders') }}</span>
                </template>

                <ui-switch-button
                    id="settings-enable-borders"
                    class="ui-content-row"
                    :label="$t('Add borders')"
                    v-model="bordersEnabled"
                    :disabled="readOnly"
                />

                <div v-if="bordersEnabled" class="borders-settings">
                    <ui-color-selector
                        id="settings-borders-color"
                        class="ui-content-row"
                        key="color-selector-borders"
                        v-model:color="bordersColor"
                        :default-color="bordersDefaultColor"
                        :palette="brandPalette"
                        :enable-other-colors="brandEnableOtherColors"
                        :label="$t('Color')"
                        :disabled="readOnly"
                    />
                    <ui-number-input
                        id="settings-borders-size"
                        class="ui-content-row"
                        v-model="bordersSize"
                        :default-value="bordersDefaultSize"
                        :min="0"
                        :label="$t('Size (in pixels)')"
                        :disabled="readOnly"
                    />
                    <div class="border-sides-settings ui-content-row" :class="{ disabled: readOnly }">
                        <label>{{ $t('Position') }}</label>
                        <div class="border-side-inputs">
                            <input type="checkbox" class="border-side-top" v-model="borderTop" :disabled="readOnly" />
                            <input
                                type="checkbox"
                                class="border-side-bottom"
                                v-model="borderBottom"
                                :disabled="readOnly"
                            />
                            <input type="checkbox" class="border-side-left" v-model="borderLeft" :disabled="readOnly" />
                            <input
                                type="checkbox"
                                class="border-side-right"
                                v-model="borderRight"
                                :disabled="readOnly"
                            />
                        </div>
                    </div>
                </div>
            </ui-dropdown>

            <ui-dropdown
                id="settings-captions"
                class="centered captions-dropdown"
                :menu="false"
                :caret="false"
                :scrollable="false"
                menu-placement="top-end"
                menu-strategy="fixed"
            >
                <template #dropdown-toggle>
                    <fa-icon class="icon" icon="fa-regular fa-comment-dots" />
                    <span>{{ $t('Captions') }}</span>
                </template>

                <div class="captions-font-selection ui-content-field ui-content-row" :class="{ disabled: readOnly }">
                    <label>{{ $t('Font') }}</label>
                    <ui-dropdown
                        id="settings-captions-font"
                        :select="true"
                        v-model="captionsFont"
                        :disabled="readOnly"
                        :toggle-style="getCaptionsFontStyles(captionsFont)"
                    >
                        <ui-dropdown-item
                            v-for="font in parsedFontLibrary"
                            :key="font.name"
                            :value="font.name"
                            :style="getCaptionsFontStyles(font.name)"
                        />
                    </ui-dropdown>
                </div>
                <div class="ui-content-field ui-content-row" :class="{ disabled: readOnly }">
                    <label>{{ $t('Alignment') }}</label>
                    <ui-dropdown
                        id="settings-captions-alignment"
                        :select="true"
                        v-model="captionsAlign"
                        :disabled="readOnly"
                    >
                        <ui-dropdown-item
                            v-for="(alignLabel, alignValue) in availableAlignmentTypes"
                            :value="alignValue"
                            :label="alignLabel"
                        />
                    </ui-dropdown>
                </div>
                <div class="ui-content-field ui-content-row" :class="{ disabled: readOnly }">
                    <label>{{ $t('Style') }}</label>
                    <ui-dropdown
                        id="settings-captions-style"
                        :select="true"
                        v-model="captionsStyle"
                        :disabled="readOnly"
                    >
                        <ui-dropdown-item
                            v-for="(styleLabel, styleValue) in availableFontStyles"
                            :key="styleValue"
                            :value="styleValue"
                            :label="styleLabel"
                        />
                    </ui-dropdown>
                </div>
                <div class="ui-content-field ui-content-row" :class="{ disabled: readOnly }">
                    <label>{{ $t('Size') }}</label>
                    <ui-dropdown id="settings-captions-size" :select="true" v-model="captionsSize" :disabled="readOnly">
                        <ui-dropdown-item
                            v-for="scale in fontScaleValues"
                            :key="'scale-' + scale"
                            :value="scale * fontSizeReference"
                        />
                    </ui-dropdown>
                </div>
                <ui-color-selector
                    id="settings-captions-color"
                    class="ui-content-row"
                    key="color-selector-captions-color"
                    v-model:color="captionsColor"
                    :default-color="captionsDefaultColor"
                    :palette="brandPalette"
                    :enable-other-colors="brandEnableOtherColors"
                    :label="$t('Text color')"
                    :disabled="readOnly"
                />
                <ui-color-selector
                    id="settings-captions-bg-color"
                    class="ui-content-row"
                    key="color-selector-captions-bg-color"
                    v-model:color="captionsBackgroundColor"
                    :default-color="captionsDefaultBackgroundColor"
                    :palette="brandPalette"
                    :enable-empty="true"
                    :enable-alpha="true"
                    :enable-other-colors="brandEnableOtherColors"
                    :label="$t('Text background color')"
                    :disabled="readOnly"
                />
                <ui-switch-button
                    id="settings-enable-captions-shadow"
                    class="ui-content-row"
                    :label="$t('Add shadow')"
                    v-model="captionsShadowEnabled"
                    :disabled="readOnly"
                />
                <div class="ui-content-field ui-content-row" :class="{ disabled: readOnly }">
                    <label>{{ $t('Horizontal position') }}</label>
                    <ui-dropdown
                        id="settings-captions-hor-position"
                        :select="true"
                        :editable="true"
                        :editable-reg-exp="/^\-?\d+(?:(?:\.|,)\d+)?$/"
                        v-model="captionsHorizontalPosition"
                        :disabled="readOnly"
                    >
                        <ui-dropdown-item
                            v-for="position in captionsHorizontalPositions"
                            :key="position"
                            :value="position"
                            :label="$t('studio.alignments.' + position)"
                        />
                    </ui-dropdown>
                </div>
                <div class="ui-content-field ui-content-row" :class="{ disabled: readOnly }">
                    <label>{{ $t('Vertical position') }}</label>
                    <ui-dropdown
                        id="settings-captions-ver-position"
                        :select="true"
                        :editable="true"
                        :editable-reg-exp="/^\-?\d+(?:(?:\.|,)\d+)?$/"
                        v-model="captionsVerticalPosition"
                        :disabled="readOnly"
                    >
                        <ui-dropdown-item
                            v-for="position in captionsVerticalPositions"
                            :key="position"
                            :value="position"
                            :label="$t('studio.alignments.' + position)"
                        />
                    </ui-dropdown>
                </div>
            </ui-dropdown>
        </template>
    </ui-card>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import _omit from 'lodash/omit';
import { Align, Color, Dimension, Font, Logo, Message } from 'cte-video-studio';
import i18n from '@/libs/i18n';
import {
    LOGO_VERTICAL_POSITIONS,
    LOGO_HORIZONTAL_POSITIONS,
    CAPTIONS_VERTICAL_POSITIONS,
    CAPTIONS_HORIZONTAL_POSITIONS
} from '@/js/constants/index.js';
import * as UiComponents from '../../../../../components';
import { UI_DROPDOWN_MENU_SHOW } from '@/js/components/UiDropdown.vue';
import {
    UI_MEDIA_TYPE_IMAGE,
    UI_MEDIA_SELECTOR_SHOW,
    UI_MEDIA_SELECTOR_CHANGE
} from '../../../../../components/UiMediaSelector.vue';
import {
    UI_LIBRARY_SELECTOR_SHOW,
    UI_LIBRARY_SELECTOR_PREVIEW_CHANGE
} from '../../../../../components/UiLibrarySelector.vue';
import { getPrefixedUuid } from '@/js/utils.js';
import { ModifiesHistory, SavesVideo } from '../../../../mixins';

const BORDER_DEFAULT_SIZE = 20;

const TEXT_EDITOR_DEFAULT_FONT_SCALES = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2];

export default {
    mixins: [ModifiesHistory, SavesVideo],

    components: {
        ...UiComponents
    },

    props: {
        id: {
            type: String,
            default: 'settings-branding'
        }
    },

    data() {
        return {
            dropdownShowEvent: UI_DROPDOWN_MENU_SHOW,
            mediaTypeImage: UI_MEDIA_TYPE_IMAGE,
            mediaSelectorShowEvent: UI_MEDIA_SELECTOR_SHOW,
            mediaSelectorChangeEvent: UI_MEDIA_SELECTOR_CHANGE,
            librarySelectorShowEvent: UI_LIBRARY_SELECTOR_SHOW,
            librarySelectorPreviewChangeEvent: UI_LIBRARY_SELECTOR_PREVIEW_CHANGE,
            brandLogoDefaultAnimationType: Logo.DEFAULT,
            brandLogoHorizontalPositions: LOGO_HORIZONTAL_POSITIONS,
            brandLogoVerticalPositions: LOGO_VERTICAL_POSITIONS,
            bordersDefaultColor: Color.BORDER_DEFAULT,
            bordersDefaultSize: BORDER_DEFAULT_SIZE,
            alignmentTypes: Align.TEXT_ALIGNMENTS,
            styleTypes: Font.STYLES,
            captionsDefaultColor: Color.CAPTIONS_DEFAULT,
            captionsDefaultBackgroundColor: Color.NONE,
            captionsHorizontalPositions: CAPTIONS_HORIZONTAL_POSITIONS,
            captionsVerticalPositions: CAPTIONS_VERTICAL_POSITIONS,
            fontScaleValues: TEXT_EDITOR_DEFAULT_FONT_SCALES,
            fontSizeReference: Message.FONT_SIZE_DEFAULT
        };
    },

    computed: {
        ...mapState({
            readOnly: (state) => state.ui.readOnly,
            prefixes: (state) => state.ui.prefixes,
            brandLogo: (state) => state.branding.logo,
            brandPalette: (state) => state.branding.palette,
            brandEnableOtherColors: (state) => state.branding.enableOtherColors,
            fontLibrary: (state) => state.branding.libraries.fonts,
            libraryTagOrders: (state) => state.branding.libraries.tagOrders,
            state: (state) => state.settings,
            shortLanguage: (state) => state.ui.shortLanguage
        }),

        ...mapGetters({
            brandLogoSize: 'settings/logoSize',
            brandLogoPosition: 'settings/logoPosition',
            borders: 'settings/borders'
        }),

        parsedFontLibrary() {
            return this.fontLibrary.sort((fontA, fontB) => fontA.name.localeCompare(fontB.name, this.shortLanguage));
        },

        brandLogoEnabled: {
            get() {
                return this.state.logo.enabled;
            },
            set(value) {
                this.startHistoryStep();
                this.$store.commit('settings/enableLogo', value);
                this.saveVideo();
            }
        },

        brandLogoImage: {
            get() {
                let ref = { src: '', id: '' };

                if (this.brandLogoEnabled) {
                    ref.src = this.$store.getters['settings/logo'];
                    ref.id = this.$store.getters['settings/logoId'];
                }

                return ref;
            },
            set({ src, id }) {
                let override =
                    (!!src && src != this.brandLogo.animation.src) ||
                    this.brandLogoAnimationType != this.brandLogo.animation.type;

                this.startHistoryStep();
                this.$store.commit('settings/overrideLogo', override);
                if (!this.state.logo.animation.src__ref) {
                    this.$store.commit('settings/setLogoReference', getPrefixedUuid(this.prefixes.mediaReference));
                }
                this.$store.dispatch('settings/updateLogo', { src, src__id: id });
                this.saveVideo();
            }
        },

        brandLogoAnimationType: {
            get() {
                return this.$store.getters['settings/logoAnimation'];
            },
            set(type) {
                let override =
                    (!!type && type != this.brandLogo.animation.type) ||
                    this.brandLogoImage.src != this.brandLogo.animation.src;

                this.startHistoryStep();
                this.$store.commit('settings/overrideLogo', override);
                this.$store.commit('settings/setLogoAnimation', type);
                this.saveVideo();
            }
        },

        // brandLogoAnimationMetadata() {
        //     return this.findLibraryItem('logoAnimations', '', this.brandLogoAnimationType) || null;
        // },

        // brandLogoAnimationUses() {
        //     return this.brandLogoAnimationMetadata && this.brandLogoAnimationMetadata.uses || [];
        // },

        brandLogoOverridePosition: {
            get() {
                return this.state.logo.position.overrideBranding;
            },
            set(value) {
                this.startHistoryStep();
                this.$store.commit('settings/overrideLogoPosition', value);
                this.saveVideo();
            }
        },

        brandLogoWidth: {
            get() {
                return this.brandLogoSize.width != Dimension.AUTO ? parseFloat(this.brandLogoSize.width) : '';
            },
            set(value) {
                if (value != this.brandLogoWidth) {
                    this.startHistoryStep();
                    this.$store.commit('settings/setLogoWidth', {
                        value: Dimension.CUSTOM,
                        custom: value !== '' ? value + Dimension.PERCENT_UNIT : value
                    });
                    this.saveVideo();
                }
            }
        },

        brandLogoHeight: {
            get() {
                return this.brandLogoSize.height != Dimension.AUTO ? parseFloat(this.brandLogoSize.height) : '';
            },
            set(value) {
                if (value != this.brandLogoHeight) {
                    this.startHistoryStep();
                    this.$store.commit('settings/setLogoHeight', {
                        value: Dimension.CUSTOM,
                        custom: value !== '' ? value + Dimension.PERCENT_UNIT : value
                    });
                    this.saveVideo();
                }
            }
        },

        brandLogoHorizontalPosition: {
            get() {
                return this.brandLogoPosition.alignH != Align.CUSTOM
                    ? this.brandLogoPosition.alignH
                    : parseFloat(this.brandLogoPosition.alignX);
            },
            set(value) {
                let parsedValue = value.replace(',', '.'),
                    position = {
                        alignH: !isNaN(parsedValue) ? Align.CUSTOM : value,
                        alignX: !isNaN(parsedValue) ? parsedValue : null
                    };
                if (
                    position.alignH != this.state.logo.position.alignH ||
                    position.alignX != this.state.logo.position.alignX
                ) {
                    this.startHistoryStep();
                    this.$store.commit(
                        'settings/setLogoPosition',
                        Object.assign({}, this.state.logo.position, position)
                    );
                    this.saveVideo();
                }
            }
        },

        brandLogoVerticalPosition: {
            get() {
                return this.brandLogoPosition.alignV != Align.CUSTOM
                    ? this.brandLogoPosition.alignV
                    : parseFloat(this.brandLogoPosition.alignY);
            },
            set(value) {
                let parsedValue = value.replace(',', '.'),
                    position = {
                        alignV: !isNaN(parsedValue) ? Align.CUSTOM : value,
                        alignY: !isNaN(parsedValue) ? parsedValue : Dimension.ZERO
                    };
                if (
                    position.alignV != this.state.logo.position.alignV ||
                    position.alignY != this.state.logo.position.alignY
                ) {
                    this.startHistoryStep();
                    this.$store.commit(
                        'settings/setLogoPosition',
                        Object.assign({}, this.state.logo.position, position)
                    );
                    this.saveVideo();
                }
            }
        },

        bordersEnabled: {
            get() {
                return this.state.borders.enabled;
            },
            set(value) {
                if (value ^ this.bordersEnabled) {
                    this.startHistoryStep();
                    if (!!value && !this.bordersEnabled && this.state.borders.size == Dimension.ZERO) {
                        this.$store.commit('settings/setBorderSize', BORDER_DEFAULT_SIZE);
                    }
                    this.$store.commit('settings/enableBorders', value);
                    this.$store.commit('settings/customizeBorders', value);
                    this.saveVideo();

                    if (!value) this.$refs.$bordersDropdown.hide();
                }
            }
        },

        bordersColor: {
            get() {
                let color = { ref: Color.BORDER_DEFAULT, value: Color.BORDER_DEFAULT };

                if (this.bordersEnabled) {
                    color.ref = this.state.borders.color__ref;
                    color.value = this.state.borders.color;
                }

                return color;
            },
            set({ ref, value }) {
                this.startHistoryStep();
                this.$store.commit('settings/setBorderColor', value);
                this.$store.commit('settings/setBorderColorReference', ref);
                this.saveVideo();
            }
        },

        bordersSize: {
            get() {
                return this.state.borders.size;
            },
            set(value) {
                if (value != this.bordersSize) {
                    this.startHistoryStep();
                    this.$store.commit('settings/setBorderSize', value);
                    this.saveVideo();
                }
            }
        },

        borderTop: {
            get() {
                return this.borders && this.borders.top.size != Dimension.ZERO;
            },
            set(value) {
                if (value != this.borderTop) {
                    this.startHistoryStep();
                    this.setBorderSide({ side: Dimension.TOP, enabled: !value, size: !value ? Dimension.ZERO : null });
                    this.saveVideo();
                }
            }
        },

        borderBottom: {
            get() {
                return this.borders && this.borders.bottom.size != Dimension.ZERO;
            },
            set(value) {
                if (value != this.borderBottom) {
                    this.startHistoryStep();
                    this.setBorderSide({
                        side: Dimension.BOTTOM,
                        enabled: !value,
                        size: !value ? Dimension.ZERO : null
                    });
                    this.saveVideo();
                }
            }
        },

        borderLeft: {
            get() {
                return this.borders && this.borders.left.size != Dimension.ZERO;
            },
            set(value) {
                if (value != this.borderLeft) {
                    this.startHistoryStep();
                    this.setBorderSide({ side: Dimension.LEFT, enabled: !value, size: !value ? Dimension.ZERO : null });
                    this.saveVideo();
                }
            }
        },

        borderRight: {
            get() {
                return this.borders && this.borders.right.size != Dimension.ZERO;
            },
            set(value) {
                if (value != this.borderRight) {
                    this.startHistoryStep();
                    this.setBorderSide({
                        side: Dimension.RIGHT,
                        enabled: !value,
                        size: !value ? Dimension.ZERO : null
                    });
                    this.saveVideo();
                }
            }
        },

        captionsFont: {
            get() {
                return this.$store.getters['settings/captionsFont'];
            },
            set(font) {
                this.startHistoryStep();
                this.$store.commit('settings/setFont', { value: font, custom: null });
                this.saveVideo();
            }
        },

        captionsAlign: {
            get() {
                return this.state.captions.align || Align.TEXT_CENTER;
            },
            set(value) {
                this.startHistoryStep();
                this.$store.commit('settings/setAlign', value);
                this.saveVideo();
            }
        },

        captionsStyle: {
            get() {
                return this.state.captions.style || Font.STYLE_BOLD;
            },
            set(value) {
                this.startHistoryStep();
                this.$store.commit('settings/setStyle', value);
                this.saveVideo();
            }
        },

        captionsColor: {
            get() {
                return {
                    ref: this.state.captions.color.start__ref || Color.CAPTIONS_DEFAULT,
                    value: this.state.captions.color.start || Color.CAPTIONS_DEFAULT
                };
            },
            set({ ref, value }) {
                this.startHistoryStep();
                this.$store.commit('settings/setColor', { start__ref: ref, start: value });
                this.saveVideo();
            }
        },

        captionsBackgroundColor: {
            get() {
                return {
                    ref: this.state.captions.background.color.start__ref || Color.NONE,
                    value: this.state.captions.background.color.start || Color.NONE,
                    alpha: this.state.captions.background.opacity
                };
            },
            set({ ref, value, alpha }) {
                this.startHistoryStep();
                this.$store.commit('settings/setBackground', { color: { start__ref: ref, start: value } });
                this.$store.commit('settings/setBackgroundOpacity', alpha);
                this.saveVideo();
            }
        },

        captionsShadowEnabled: {
            get() {
                return this.state.captions.shadow;
            },
            set(value) {
                this.startHistoryStep();
                this.$store.commit('settings/enableShadow', value);
                this.saveVideo();
            }
        },

        captionsHorizontalPosition: {
            get() {
                return this.state.captions.position.alignH != Align.CUSTOM
                    ? this.state.captions.position.alignH
                    : parseFloat(this.state.captions.position.alignX);
            },
            set(value) {
                let parsedValue = value.replace(',', '.'),
                    position = {
                        alignH: !isNaN(parsedValue) ? Align.CUSTOM : value,
                        alignX: !isNaN(parsedValue) ? parsedValue : Dimension.ZERO
                    };
                if (
                    position.alignH != this.state.captions.position.alignH ||
                    position.alignX != this.state.captions.position.alignX
                ) {
                    this.startHistoryStep();
                    this.$store.commit(
                        'settings/setPosition',
                        Object.assign({}, this.state.captions.position, position)
                    );
                    this.saveVideo();
                }
            }
        },

        captionsVerticalPosition: {
            get() {
                return this.state.captions.position.alignV != Align.CUSTOM
                    ? this.state.captions.position.alignV
                    : parseFloat(this.state.captions.position.alignY);
            },
            set(value) {
                let parsedValue = value.replace(',', '.'),
                    position = {
                        alignV: !isNaN(parsedValue) ? Align.CUSTOM : value,
                        alignY: !isNaN(parsedValue) ? parsedValue : Dimension.ZERO
                    };
                if (
                    position.alignV != this.state.captions.position.alignV ||
                    position.alignY != this.state.captions.position.alignY
                ) {
                    this.startHistoryStep();
                    this.$store.commit(
                        'settings/setPosition',
                        Object.assign({}, this.state.captions.position, position)
                    );
                    this.saveVideo();
                }
            }
        },

        captionsSize: {
            get() {
                return this.state.captions.fontScale.value * Message.FONT_SIZE_DEFAULT;
            },
            set(value) {
                let fontScale = value / Message.FONT_SIZE_DEFAULT;

                if (fontScale != this.state.captions.fontScale.value) {
                    this.startHistoryStep();
                    this.$store.commit('settings/setFontScale', { value: fontScale });
                    this.saveVideo();
                }
            }
        },

        availableAlignmentTypes() {
            return Object.fromEntries(
                this.alignmentTypes
                    .filter((alignValue) => alignValue !== Align.TEXT_JUSTIFY)
                    .map((alignValue) => [alignValue, this.$t('studio.text_alignments.' + alignValue)])
            );
        },

        availableFontStyles() {
            return Object.fromEntries(this.styleTypes.map((style) => [style, this.$t('studio.font_styles.' + style)]));
        }
    },

    watch: {
        //
    },

    methods: {
        ...mapMutations({
            setCurrentLibrary: 'ui/setCurrentLibrary',
            setCurrentMediaLibrary: 'ui/setCurrentMediaLibrary',
            setBorderSide: 'settings/setBorderSide'
        }),

        ...mapActions({
            //
        }),

        openLibrary(selector) {
            this.setCurrentLibrary({
                id: selector.library,
                category: selector.category,
                tagOrder: selector.tagOrder || this.libraryTagOrders[selector.library] || null,
                selectedItemId: selector.modelValue,
                selector
            });
        },

        openMediaLibrary(selector) {
            this.setCurrentMediaLibrary({
                mediaType: selector.type,
                selectedMediaId: selector.media.id,
                selector
            });
        },

        // previewBrandLogoAnimationType(type, cancel) {
        //     this.$studio.$stage.$off(STAGE_LOADING_ENDED_EVENT, this.playBrandLogoAnimationTypePreview);
        //     this.ignoreHistoryStep(() => {
        //         this.$store.commit('settings/setLogoAnimation', type);
        //         if (!cancel) this.$studio.$stage.$once(STAGE_LOADING_ENDED_EVENT, this.playBrandLogoAnimationTypePreview);
        //     });
        // },

        // playBrandLogoAnimationTypePreview() {
        //     this.playPreview(this.$store.getters[this.elementStoreModulePath+'/start'] || 0.001);
        // },

        enableBorders() {
            if (!this.readOnly) this.bordersEnabled = true;
        },

        getCaptionsFontStyles(font) {
            return {
                '--font-thumbnail': `url(/assets/thumbnails/fonts/${font}.gif)`
            };
        }
    },

    mounted() {
        //
    }
};
</script>
