class LocalStorageManager {
    static getItem(key) {
        return localStorage.getItem(key);
    }

    static setItem(key, value) {
        localStorage.setItem(key, value);
    }

    static load(key) {
        const storedItem = localStorage.getItem(key);
        return JSON.parse(storedItem);
    }

    static save(key, value) {
        const jsonValue = JSON.stringify(value);
        localStorage.setItem(key, jsonValue);
    }

    static clearLocalStorage() {
        localStorage.clear();
    }
}

export default LocalStorageManager;
