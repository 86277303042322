export const VIDEO_CONTEXT = 'video';
export const BRAND_CONTEXT = 'brand';

import i18n from '@/libs/i18n';

export const VIDEO_ONBOARDING_EMOTION_MODE_STEPS = [
    {
        target: '#video-onboarding-step-1',
        header: {
            title: i18n.global.t('Your video\n')
        },
        content: i18n.global.t('Define the format, music, logo and overall style of your video.\n'),
        icon: 'fa-regular fa-square-sliders',
        params: {
            placement: 'right',
            enableScrolling: false
        }
    },
    {
        target: '.video-onboarding-step-2',
        header: {
            title: i18n.global.t('Create a sequence\n')
        },
        content: i18n.global.t(
            'Build your video sequence by sequence, using creative templates or creating empty sequences.\n'
        ),
        icon: 'fa-solid fa-square-dashed-circle-plus',
        params: {
            placement: 'right',
            enableScrolling: false
        }
    },
    {
        target: '.video-onboarding-step-3-emotion',
        header: {
            title: i18n.global.t('Add elements\n')
        },
        content: i18n.global.t(
            'Integrate creative elements: messages, images, icons, videos, audio, panels, transitions…\n'
        ),
        icon: 'fa-solid fa-icons',
        params: {
            placement: 'left',
            enableScrolling: false
        }
    },
    {
        target: '#video-onboarding-step-4',
        header: {
            title: i18n.global.t('Preview and sharing\n')
        },
        content: i18n.global.t(
            'Watch your video or sequence in real time, and share a viewing link with your teams.\n'
        ),
        icon: 'fa-solid fa-eye',
        params: {
            placement: 'top',
            enableScrolling: false
        }
    },
    {
        target: '.video-onboarding-step-5',
        header: {
            title: i18n.global.t('Working modes\n')
        },
        content: i18n.global.t(
            "Switch from Emotion mode to Content mode to simplify your creative interface and focus on the content of your video. Handy when you're working from a template!\n"
        ),
        icon: 'fa-solid fa-toggle-off',
        params: {
            placement: 'bottom',
            enableScrolling: false
        }
    },
    {
        target: '#video-onboarding-step-6',
        header: {
            title: i18n.global.t('Download your video\n')
        },
        content: i18n.global.t(
            'Your changes are saved automatically, so return to the page where your videos are listed to start production, then download your creation.\n'
        ),
        icon: 'fa-solid fa-cloud-arrow-down',
        params: {
            placement: 'right',
            enableScrolling: false
        }
    }
];

export const VIDEO_ONBOARDING_CONTENT_MODE_STEPS = [
    {
        target: '#video-onboarding-step-1',
        header: {
            title: i18n.global.t('Your video\n')
        },
        content: i18n.global.t('Define the format and music of your video.\n'),
        icon: 'fa-solid fa-sliders',
        params: {
            placement: 'right',
            enableScrolling: false
        }
    },
    {
        target: '.video-onboarding-step-2',
        header: {
            title: i18n.global.t('Create a sequence\n')
        },
        content: i18n.global.t('Build your video sequence by sequence using creative templates.\n'),
        icon: 'fa-solid fa-square-dashed-circle-plus',
        params: {
            placement: 'right',
            enableScrolling: false
        }
    },
    {
        target: '.video-onboarding-step-3-content',
        header: {
            title: i18n.global.t('Customize elements\n')
        },
        content: i18n.global.t(
            'Customize creative elements: messages, images, icons, videos, audio, panels, transitions…\n'
        ),
        icon: 'fa-solid fa-icons',
        params: {
            placement: 'left',
            enableScrolling: false
        }
    },
    {
        target: '#video-onboarding-step-4',
        header: {
            title: i18n.global.t('Preview and sharing\n')
        },
        content: i18n.global.t(
            'Watch your video or sequence in real time, and share a viewing link with your teams.\n'
        ),
        icon: 'fa-solid fa-eye',
        params: {
            placement: 'top',
            enableScrolling: false
        }
    },
    {
        target: '.video-onboarding-step-5',
        header: {
            title: i18n.global.t('Working modes\n')
        },
        content: i18n.global.t(
            'Switch from Content mode to Emotion mode to unlock the full functionality of your creative interface. Indispensable for unleashing your creativity!\n'
        ),
        icon: 'fa-solid fa-toggle-off',
        params: {
            placement: 'bottom',
            enableScrolling: false
        }
    },
    {
        target: '#video-onboarding-step-6',
        header: {
            title: i18n.global.t('Download your video\n')
        },
        content: i18n.global.t(
            'Your changes are saved automatically, so return to the page where your videos are listed to start production, then download your creation.\n'
        ),
        icon: 'fa-solid fa-cloud-arrow-down',
        params: {
            placement: 'right',
            enableScrolling: false
        }
    }
];

export const BRAND_ONBOARDING_STEPS = [];
