<template>
    <div class="ui-card-selector" role="none">
        <ul class="selector-items disabled-hide" :style="selectorItemsStyles" role="menu">
            <li
                class="selector-item"
                v-for="(itemLabel, itemId) in _selectorItems"
                :key="'selector-item-' + itemId"
                role="menuitem"
            >
                <button
                    class="selector-item-button"
                    :disabled="selectorItemDisabled[itemId] || recordingDisabled(itemId)"
                    v-tooltip="recordingDisabled(itemId) && $t('studio.features.recording-disabled')"
                    @click="handleItemClick($event, itemId)"
                >
                    <fa-icon v-if="recordingDisabled(itemId)" icon="fa-regular fa-lock" class="icon" />
                    <svg-icon v-else :icon="getCardIcon(itemId)" :class="itemId" />
                    <span>{{ itemLabel }}</span>
                </button>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { Message, TTS, Visual } from '../../../../video-studio/constants/';
import { v4 as uuidv4 } from 'uuid';
import {
    MESSAGE_ELEMENT_ID,
    IMAGE_ELEMENT_ID,
    PANEL_ELEMENT_ID,
    AUDIO_ELEMENT_ID,
    TRANSITION_ELEMENT_ID,
    FOOTER_ELEMENT_ID,
    TTS_ELEMENT_ID,
    VIDEO_ELEMENT_ID,
    CARTOON_ELEMENT_ID,
    STICKER_ELEMENT_ID,
    ICON_ELEMENT_ID,
    MOSAIC_ELEMENT_ID,
    RECORDING_ELEMENT_ID,
    MAX_NB_OF_MESSAGES_PER_SEQUENCES,
    MAX_NB_OF_VISUALS_PER_SEQUENCES
} from '../../../../constants';
import { UiIcon } from '../../../../components';
import { useRecording } from '@/js/videos/composables/useRecording';
import i18n from '@/libs/i18n.js';
import { UsesTooltip } from '@/js/mixins/index.js';

export const UI_CARD_SELECTOR_ADDED_CARD = 'ui-card-selector-added-card';

export default {
    components: {
        UiIcon
    },

    props: {
        seqId: {
            type: String,
            default: ''
        }
    },

    mixins: [UsesTooltip],

    setup() {
        const { openRecordDeviceModal } = useRecording();

        return {
            openRecordDeviceModal
        };
    },

    data() {
        return {
            hoverOpacity: 0,
            hoverPosX: null,
            hoverPosY: null,
            hoverDuration: '0s'
        };
    },

    computed: {
        ...mapState({
            readOnly: (state) => state.ui.readOnly,
            restrictions: (state) => state.ui.restrictions,
            state(state) {
                return state.sequences[this.seqId];
            }
        }),

        _selectorItems() {
            return {
                [MESSAGE_ELEMENT_ID]: this.$t('Message'),
                [IMAGE_ELEMENT_ID]: this.$t('Image'),
                [ICON_ELEMENT_ID]: this.$t('Icon'),
                [VIDEO_ELEMENT_ID]: this.$t('Video'),
                [STICKER_ELEMENT_ID]: this.$t('Sticker'),
                [CARTOON_ELEMENT_ID]: this.$t('Cartoon'),
                [MOSAIC_ELEMENT_ID]: this.$t('Mosaic'),
                [PANEL_ELEMENT_ID]: this.$t('Panel'),
                [AUDIO_ELEMENT_ID]: this.$t('Audio'),
                [TTS_ELEMENT_ID]: this.$t('Voice-over'),
                [RECORDING_ELEMENT_ID]: this.$t('Record'),
                [FOOTER_ELEMENT_ID]: this.$t('Footer'),
                [TRANSITION_ELEMENT_ID]: this.$t('Transition')
            };
        },

        ...mapGetters({
            //
        }),

        messages() {
            return this.$store.getters['sequences/' + this.seqId + '/messages'];
        },
        visuals() {
            return this.$store.getters['sequences/' + this.seqId + '/visuals'];
        },

        hasPanel() {
            return this.$store.getters['sequences/' + this.seqId + '/hasPanel'];
        },
        hasAudio() {
            return this.$store.getters['sequences/' + this.seqId + '/hasAudio'];
        },
        hasTransition() {
            return this.$store.getters['sequences/' + this.seqId + '/hasTransition'];
        },
        hasFooter() {
            return this.$store.getters['sequences/' + this.seqId + '/hasFooter'];
        },
        hasTts() {
            return this.$store.getters['sequences/' + this.seqId + '/hasTts'];
        },
        hasReachedMessageLimit() {
            return (
                this.messages.length >= MAX_NB_OF_MESSAGES_PER_SEQUENCES ||
                (this.restrictions.maxSequenceMessageCards &&
                    this.messages.length >= this.restrictions.maxSequenceMessageCards)
            ); // Note: Later, condition will be on number of messages < max messages
        },
        hasReachedVisualLimit() {
            return (
                this.visuals.length >= MAX_NB_OF_VISUALS_PER_SEQUENCES ||
                (this.restrictions.maxSequenceVisualCards &&
                    this.visuals.length >= this.restrictions.maxSequenceVisualCards)
            ); // Note: Later, condition will be on number of visuals < max visuals // Note: Later, condition will be on number of visuals < max visuals
        },

        selectorItemsStyles() {
            return {
                '--selector-item-hover-opacity': this.hoverOpacity,
                '--selector-item-hover-x': this.hoverPosX,
                '--selector-item-hover-y': this.hoverPosY,
                '--selector-item-hover-duration': this.hoverDuration
            };
        },

        selectorItemDisabled() {
            return {
                [MESSAGE_ELEMENT_ID]: this.hasReachedMessageLimit,
                [IMAGE_ELEMENT_ID]: this.hasReachedVisualLimit,
                [PANEL_ELEMENT_ID]: this.hasPanel,
                [AUDIO_ELEMENT_ID]: this.hasAudio,
                [TRANSITION_ELEMENT_ID]: this.hasTransition,
                [FOOTER_ELEMENT_ID]: this.hasFooter,
                [TTS_ELEMENT_ID]: this.hasTts,
                [VIDEO_ELEMENT_ID]: this.hasReachedVisualLimit,
                [CARTOON_ELEMENT_ID]: this.hasReachedVisualLimit,
                [STICKER_ELEMENT_ID]: this.hasReachedVisualLimit,
                [ICON_ELEMENT_ID]: this.hasReachedVisualLimit,
                [MOSAIC_ELEMENT_ID]: this.hasReachedVisualLimit,
                [RECORDING_ELEMENT_ID]: this.hasReachedVisualLimit
            };
        }
    },

    watch: {
        //
    },

    methods: {
        ...mapActions({
            startHistoryStep: 'ui/history/startStep',
            saveVideo: 'ui/saveVideo'
        }),

        handleItemClick(event, itemId) {
            this.startHistoryStep();
            let id = itemId;
            let visualCategory = null;
            let dispatchAction = null;

            switch (itemId) {
                case MESSAGE_ELEMENT_ID:
                    id = Message.PREFIX_ID + uuidv4();
                    dispatchAction = 'addNewMessage';
                    break;
                case PANEL_ELEMENT_ID:
                    dispatchAction = 'showPanelCard';
                    break;
                case AUDIO_ELEMENT_ID:
                    dispatchAction = 'showAudioCard';
                    break;
                case TRANSITION_ELEMENT_ID:
                    dispatchAction = 'showTransitionCard';
                    break;
                case FOOTER_ELEMENT_ID:
                    dispatchAction = 'showFooterCard';
                    break;
                case TTS_ELEMENT_ID:
                    this.addVoiceOver();
                    break;
                case RECORDING_ELEMENT_ID:
                    this.openRecordDeviceModal({ sequenceId: this.seqId });
                    break;
                case IMAGE_ELEMENT_ID:
                case VIDEO_ELEMENT_ID:
                case CARTOON_ELEMENT_ID:
                case STICKER_ELEMENT_ID:
                case ICON_ELEMENT_ID:
                case MOSAIC_ELEMENT_ID:
                    if (itemId === IMAGE_ELEMENT_ID) {
                        visualCategory = Visual.IMAGE_CATEGORY;
                    } else if (itemId === VIDEO_ELEMENT_ID) {
                        visualCategory = Visual.VIDEO_CATEGORY;
                    } else if (itemId === CARTOON_ELEMENT_ID) {
                        visualCategory = Visual.LOTTIE_CATEGORY;
                    } else if (itemId === STICKER_ELEMENT_ID) {
                        visualCategory = Visual.ANIMATED_CATEGORY;
                    } else if (itemId === ICON_ELEMENT_ID) {
                        visualCategory = Visual.ICON_CATEGORY;
                    } else if (itemId === MOSAIC_ELEMENT_ID) {
                        visualCategory = Visual.COLLAGE_CATEGORY;
                    }
                    id = Visual.PREFIX_ID + uuidv4();
                    dispatchAction = 'addEmptyVisual';
                    break;
            }

            if (dispatchAction) {
                this.$store.dispatch(`sequences/${this.seqId}/${dispatchAction}`, { id });
            }

            this.saveVideo();
            event.currentTarget.dispatchEvent(new MouseEvent('mouseleave'));
            this.$nextTick(() => this.$emit(UI_CARD_SELECTOR_ADDED_CARD, { id, category: visualCategory }, this));
        },

        addVoiceOver() {
            this.$store.dispatch('sequences/' + this.seqId + '/tts/setDefaultVoiceId');
            this.$store.commit('sequences/' + this.seqId + '/tts/setCategory', TTS.VOICE_CATEGORY);
            this.$store.commit('sequences/' + this.seqId + '/tts/showCard', true);
        },

        getCardIcon(itemId) {
            switch (itemId) {
                case MESSAGE_ELEMENT_ID:
                    return 'message-icon';
                case IMAGE_ELEMENT_ID:
                    return 'image-regular-icon';
                case PANEL_ELEMENT_ID:
                    return 'panel-icon';
                case AUDIO_ELEMENT_ID:
                    return 'audio-icon';
                case TRANSITION_ELEMENT_ID:
                    return 'transition-icon';
                case FOOTER_ELEMENT_ID:
                    return 'footer-icon';
                case TTS_ELEMENT_ID:
                    return 'voice-ai-icon';
                case VIDEO_ELEMENT_ID:
                    return 'circle-play-regular-icon';
                case CARTOON_ELEMENT_ID:
                    return 'person-running-solid-icon';
                case STICKER_ELEMENT_ID:
                    return 'bolt-regular-icon';
                case ICON_ELEMENT_ID:
                    return 'shapes-regular-icon';
                case MOSAIC_ELEMENT_ID:
                    return 'mosaic-icon';
                case RECORDING_ELEMENT_ID:
                    return 'recording-icon';
            }
        },

        recordingDisabled(type) {
            return type === RECORDING_ELEMENT_ID && !this.$store.state.auth.user.client.enableRecording;
        }
    },

    mounted() {
        //
    }
};
</script>
