export default class VideoSaveHistoryStepService {
    constructor(store) {
        this.store = store;
    }

    handle(actions) {
        this.store.dispatch('ui/history/startStep');
        actions();
        this.store.dispatch('ui/saveVideo');
    }
}