<template>
    <div v-if="isActive" class="ui-tts-editor" :class="editorClasses">
        <ui-tts-editor-header :cancel-method="close"></ui-tts-editor-header>
        <div class="ui-tts-editor-content">
            <div class="ui-tts-voice-selection">
                <ui-library-selector
                    v-model="voiceId"
                    :default-value="defaultVoiceId"
                    library="voices"
                    :preview-path="'voices/'"
                    :show-label="true"
                    :label="$t('Select a voice')"
                    :update-label="$t('Change voice')"
                    :show-error="hasVoiceIdStatusError"
                    :disabled="readOnly"
                    icon="fa-solid fa-person"
                    @[librarySelectorShowEvent]="openLibrary"
                />
            </div>
            <ui-text-input
                id="ui-tts-editor-text-input"
                :textarea="true"
                :label="$t('Text to speech')"
                :show-label="false"
                :model-value="text"
                :disabled="readOnly"
                :placeholder="$t('Enter text to convert into speech...')"
                :maxlength="maxlength"
                :show-error="hasTextStatusError"
                @[textInputInputEvent]="debouncedHandleTextInput"
            />
            <div class="ui-tts-length-counter" v-if="text.length >= maxlength">
                {{ sprintf($t('%1$d characters left'), maxlength - text.length) }}
            </div>
            <div v-if="hasError && !hasTextStatusError && !hasVoiceIdStatusError" class="ui-tts-status-error">
                {{ errorStatusText }}
            </div>
            <ui-tts-editor-main-button
                :is-processing="isProcessing"
                :status="status"
                :up-to-date="upToDate"
                :read-only="readOnly"
                :disabled="readOnly"
                @startTTS="startTTS"
            />
        </div>
    </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import { printf as sprintf } from 'fast-printf';
import UiTextInput from '../../../../../../../components/UiTextInput.vue';
import _debounce from 'lodash/debounce';
import UiTtsEditorHeader from './UiTtsEditorHeader.vue';
import UiLibrarySelector from '../../../../../../../components/UiLibrarySelector.vue';
import {
    TTS_STATUS_COMPLETED,
    TTS_STATUS_ERROR,
    TTS_STATUS_TEXT_ERROR,
    TTS_STATUS_VOICE_ID_ERROR
} from '@/js/constants/index.js';
import { UI_TEXT_INPUT_INPUT } from '@/js/components/UiTextInput.vue';
import { UI_LIBRARY_SELECTOR_SHOW } from '@/js/components/UiLibrarySelector.vue';
import VideoTtsService from '../../../../../../application/services/VideoTtsService';
import UiRangeInput from '../../../../../../../components/UiRangeInput.vue';
import { useVoiceOver } from '@/js/videos/composables/useVoiceOver.js';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import { ProvidesIcons } from '../../../../../../../mixins';
import UiTtsEditorMainButton from './UiTtsEditorMainButton.vue';
import { TTS } from '../../../../../../../video-studio/constants/index.js';
import { useI18n } from 'vue-i18n';

const UPDATE_INPUT_DELAY = 300;
const INPUT_MAX_LENGTH = 2500;

export default defineComponent({
    mixins: [ProvidesIcons],
    name: 'TtsEditor',
    components: {
        UiTtsEditorMainButton,
        UiRangeInput,
        UiLibrarySelector,
        UiTtsEditorHeader,
        UiTextInput
    },
    setup() {
        const store = useStore();
        const { t } = useI18n();
        const { saveHistoryStep } = useHistory();
        const videoTtsService = new VideoTtsService(store);

        const textInputInputEvent = UI_TEXT_INPUT_INPUT;
        const librarySelectorShowEvent = UI_LIBRARY_SELECTOR_SHOW;
        const maxlength = INPUT_MAX_LENGTH;

        const videoId = computed(() => store.state.settings.id);
        const readOnly = computed(() => store.state.ui.readOnly);
        const libraryTagOrders = computed(() => store.state.branding.libraries.tagOrders);
        const showTTSEditor = computed(() => store.state.ui.showTTSEditor);
        const seqId = computed(() => store.state.ui.ttsEditorConfig.seqId);
        const useInSequence = computed(() => store.state.ui.ttsEditorConfig.useInSequence);

        const { storeModulePath, voiceOverState, isProcessing, voiceOverCaptions } = useVoiceOver(useInSequence, seqId);

        const editorClasses = computed(() => {
            return { show: showTTSEditor.value };
        });

        const status = computed(() => {
            return voiceOverState.value?.voice.status;
        });

        const hasError = computed(() => {
            return !!status.value && status.value !== TTS_STATUS_COMPLETED && !isProcessing.value;
        });

        const hasTextStatusError = computed(() => {
            return status.value === TTS_STATUS_TEXT_ERROR;
        });

        const hasVoiceIdStatusError = computed(() => {
            return status.value === TTS_STATUS_VOICE_ID_ERROR;
        });

        const errorStatusText = computed(() => {
            switch (status.value) {
                case TTS_STATUS_ERROR:
                    return t('errors.tts.default');
                default:
                    return t('errors.tts.' + status.value);
            }
        });

        const statusClasses = computed(() => {
            return {
                'ui-tts-status-success': status.value === TTS_STATUS_COMPLETED,
                'ui-tts-status-error': hasError.value
            };
        });

        const upToDate = computed(() => {
            return voiceOverState.value?.voice.upToDate;
        });

        const text = computed({
            get() {
                return voiceOverState.value?.voice.text;
            },
            set(value) {
                saveHistoryStep(() => {
                    store.commit(`${storeModulePath.value}/voice/setText`, value);
                    store.commit(`${storeModulePath.value}/voice/setUpToDate`, false);
                });
            }
        });

        const defaultVoiceId = computed(() => store.state.branding.defaultVoiceId);

        const voiceId = computed({
            get() {
                return voiceOverState.value?.voice.voiceId;
            },
            set(value) {
                saveHistoryStep(() => {
                    store.commit(`${storeModulePath.value}/voice/setVoiceId`, value || defaultVoiceId.value);
                    store.commit(`${storeModulePath.value}/voice/setUpToDate`, false);
                });
            }
        });

        const exaggeration = computed({
            get() {
                return voiceOverState.value?.voice.exaggeration;
            }
        });

        const handleTextInput = (value) => {
            text.value = value;
        };

        const close = () => {
            store.commit('ui/hideTTSEditor');
        };

        const openLibrary = (selector) => {
            store.commit('ui/setCurrentLibrary', {
                id: selector.library,
                category: selector.category,
                tagOrder: selector.tagOrder || libraryTagOrders.value[selector.library] || null,
                selectedItemId: selector.modelValue,
                selector
            });
        };

        const startTTS = () => {
            if (!isProcessing.value) {
                videoTtsService.startTask(
                    storeModulePath.value,
                    voiceId.value,
                    text.value,
                    videoId.value,
                    exaggeration.value
                );
            }
            resetTtsCaptions();
        };

        const debouncedHandleTextInput = _debounce(handleTextInput, UPDATE_INPUT_DELAY);

        const isActive = computed(() => {
            return !!voiceOverState.value && voiceOverState.value.category !== TTS.NONE_CATEGORY;
        });

        const resetTtsCaptions = () => {
            voiceOverCaptions.value = [];
        };

        return {
            isActive,
            defaultVoiceId,
            seqId,
            useInSequence,
            textInputInputEvent,
            librarySelectorShowEvent,
            maxlength,
            readOnly,
            editorClasses,
            status,
            upToDate,
            isProcessing,
            hasError,
            hasTextStatusError,
            hasVoiceIdStatusError,
            errorStatusText,
            statusClasses,
            text,
            voiceId,
            exaggeration,
            close,
            openLibrary,
            startTTS,
            debouncedHandleTextInput,
            sprintf
        };
    }
});
</script>
