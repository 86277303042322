<template>
    <aside id="companion-bar" :class="{ 'active-dropdown': activeDropdownId != null }">
        <form id="companion-form" @submit.prevent="emitSubmitEvent">
            <ui-dropdown
                ref="$briefDropdown"
                id="companion-bar-brief"
                class="ui-companion-bar-fieldset ui-companion-bar-brief"
                :class="briefDropdownClasses"
                :menu="false"
                :caret="false"
                :scrollable="false"
                menu-placement="bottom"
                menu-strategy="fixed"
                :menu-offset="[0, 25]"
                @[dropdownShowEvent]="setActiveDropdown"
                @[dropdownHideEvent]="unsetActiveDropdown"
            >
                <template #dropdown-toggle>
                    <span class="ui-companion-bar-fieldset-name">{{ $t('Brief') }}</span>
                    <span class="ui-companion-bar-fieldset-preview">{{ briefPreview || $t('What video?') }}</span>
                </template>

                <div class="ui-companion-bar-field">
                    <label>{{ $t('Brief') }}</label>
                    <textarea
                        ref="$briefFocusElement"
                        name="prompt"
                        :placeholder="$t('What video?')"
                        v-model="input"
                        @change="emitChangeEvent"
                    ></textarea>
                </div>
            </ui-dropdown>

            <ui-dropdown
                ref="$goalDropdown"
                id="companion-bar-goal"
                class="ui-companion-bar-fieldset ui-companion-bar-goal"
                :class="goalDropdownClasses"
                :menu="false"
                :caret="false"
                :scrollable="false"
                menu-placement="bottom"
                menu-strategy="fixed"
                :menu-offset="[0, 25]"
                @[dropdownShowEvent]="setActiveDropdown"
                @[dropdownHideEvent]="unsetActiveDropdown"
            >
                <template #dropdown-toggle>
                    <span class="ui-companion-bar-fieldset-name">{{ $t('Goal') }}</span>
                    <span class="ui-companion-bar-fieldset-preview">{{ goalPreview || $t('Why?') }}</span>
                </template>

                <div class="ui-companion-bar-field">
                    <label>{{ $t('Goal') }}</label>
                    <textarea
                        ref="$goalFocusElement"
                        name="context[goal]"
                        :placeholder="$t('Why?')"
                        v-model="context.goal"
                        @change="emitChangeEvent"
                    ></textarea>
                </div>
                <div class="ui-companion-bar-field">
                    <label>{{ $t('Key elements') }}</label>
                    <textarea
                        name="context[key_elements]"
                        :placeholder="$t('What?')"
                        v-model="context.key_elements"
                        @change="emitChangeEvent"
                    ></textarea>
                </div>
                <div class="ui-companion-bar-field">
                    <label>{{ $t('Target') }}</label>
                    <textarea
                        name="context[target]"
                        :placeholder="$t('For who?')"
                        v-model="context.target"
                        @change="emitChangeEvent"
                    ></textarea>
                </div>
                <div class="ui-companion-bar-field">
                    <label>{{ $t('Place of use') }}</label>
                    <input
                        type="text"
                        name="context[place]"
                        :placeholder="$t('Where?')"
                        v-model="context.place"
                        @change="emitChangeEvent"
                    />
                </div>
            </ui-dropdown>

            <ui-dropdown
                ref="$renderingDropdown"
                id="companion-bar-rendering"
                class="ui-companion-bar-fieldset ui-companion-bar-rendering"
                :class="renderingDropdownClasses"
                :menu="false"
                :caret="false"
                :scrollable="false"
                menu-placement="bottom"
                menu-strategy="fixed"
                :menu-offset="[0, 25]"
                @[dropdownShowEvent]="setActiveDropdown"
                @[dropdownHideEvent]="unsetActiveDropdown"
            >
                <template #dropdown-toggle>
                    <span class="ui-companion-bar-fieldset-name">{{ $t('Rendering') }}</span>
                    <span class="ui-companion-bar-fieldset-preview">{{ renderingPreview || $t('How?') }}</span>
                </template>

                <div class="ui-companion-bar-field identity">
                    <label>{{ $t('Identity') }}</label>
                    <ui-dropdown
                        id="companion-bar-identity"
                        :select="true"
                        :menu="false"
                        :items="brandItems"
                        v-model="brand_id"
                    />
                </div>
                <div class="ui-companion-bar-field">
                    <label>{{ $t('Tone') }}</label>
                    <textarea
                        name="context[tone]"
                        :placeholder="$t('How?')"
                        v-model="context.tone"
                        @change="emitChangeEvent"
                    ></textarea>
                </div>
                <div class="ui-companion-bar-field">
                    <label>{{ $t('Graphic style') }}</label>
                    <input
                        type="text"
                        name="context[style]"
                        :placeholder="$t('In what way?')"
                        v-model="context.style"
                        @change="emitChangeEvent"
                    />
                </div>
                <fieldset class="ui-companion-bar-field video-formats">
                    <legend>{{ $t('Format') }}</legend>
                    <div class="ui-format-selection">
                        <label
                            v-for="(format, index) in formats"
                            class="video-format"
                            :for="'video-format-' + format.ratioString"
                        >
                            <input
                                type="radio"
                                :id="'video-format-' + format.ratioString"
                                name="context[format]"
                                class="video-format-input"
                                v-model="context.format"
                                :value="format.ratioString"
                            />
                            <svg-icon
                                :icon="'video-format-' + format.ratioString + '-icon'"
                                class="video-format-icon"
                            />
                            <span class="visually-hidden">{{
                                $t('studio.template_formats.' + format.ratioString)
                            }}</span>
                            <div class="video-format-selected">
                                <fa-icon class="icon" icon="fa-solid fa-check" />
                            </div>
                        </label>
                    </div>
                </fieldset>
                <!--div class="ui-companion-bar-field">
                    <label>{{ $t('Duration') }}</label>
                    <input type="text" name="context[duration]" v-model="context.duration" @change="emitChangeEvent" />
                </div-->
                <div class="ui-companion-bar-field">
                    <label>{{ $t('Language') }}</label>
                    <input
                        type="text"
                        name="context[language]"
                        :placeholder="$t('In which language?')"
                        v-model="context.language"
                        @change="emitChangeEvent"
                    />
                </div>
            </ui-dropdown>

            <button type="submit" :class="{ loading: loading }" :disabled="loading || !isDirty">
                <svg-icon v-if="loading" icon="dots-loader" />
                <svg-id-randomized-icon icon="cte-icon" />
                <span>{{ $t('Create videos') }}</span>
            </button>
        </form>
    </aside>
</template>

<script>
import { printf as sprintf } from 'fast-printf';
import { UiDropdown, UiIcon } from '../../../../components';
import { UI_DROPDOWN_MENU_HIDE, UI_DROPDOWN_MENU_SHOW } from '../../../../components/UiDropdown.vue';

export const UI_COMPANION_BAR_CHANGE = 'ui-companion-bar-change';
export const UI_COMPANION_BAR_SUBMIT = 'ui-companion-bar-submit';

const DEFAULT_FORMAT = '16x9';
const DEFAULT_DURATION = 30;

export default {
    emits: [UI_COMPANION_BAR_CHANGE, UI_COMPANION_BAR_SUBMIT],

    components: {
        UiDropdown,
        UiIcon
    },

    props: {
        currentPrompt: {
            type: Object,
            default: null
        },
        currentPromptExists: {
            type: Boolean,
            default: false
        },
        brands: {
            type: Object,
            default: () => ({})
        },
        formats: {
            type: Array,
            default: () => []
        },
        focus: {
            type: Boolean,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            dropdownShowEvent: UI_DROPDOWN_MENU_SHOW,
            dropdownHideEvent: UI_DROPDOWN_MENU_HIDE,

            activeDropdownId: null,

            id: null,
            input: '',
            context: {
                goal: '',
                key_elements: '',
                target: '',
                place: '',
                tone: '',
                style: '',
                format: '',
                duration: '',
                language: ''
            },
            brand_id: null
        };
    },

    computed: {
        isDirty() {
            return !!this.briefPreview;
        },

        briefPreview() {
            return this.input;
        },

        goalPreview() {
            return this.context.goal || this.context.key_elements || this.context.target || this.context.place;
        },

        renderingPreview() {
            return [
                this.context.format ? this.$t('companion.formats.' + this.context.format) : null,
                this.context.duration ? sprintf($t('%ss'), this.context.duration) : null
            ]
                .filter((value) => value !== null)
                .join(' - ');
        },

        briefDropdownClasses() {
            return {
                active: this.activeDropdownId == 'companion-bar-brief',
                dirty: !!this.briefPreview
            };
        },

        goalDropdownClasses() {
            return {
                active: this.activeDropdownId == 'companion-bar-goal',
                dirty: !!this.goalPreview
            };
        },

        renderingDropdownClasses() {
            return {
                active: this.activeDropdownId == 'companion-bar-rendering',
                dirty: !!this.renderingPreview
            };
        },

        brandItems() {
            return Object.entries(this.brands).map(([value, label]) => ({ value, label }));
        }
    },

    watch: {
        currentPrompt: {
            deep: true,
            handler(newValue) {
                if (newValue.id != this.id && (newValue.loaded || !this.currentPromptExists)) {
                    this.id = newValue.id;
                    this.input = newValue.input;
                    this.context = Object.assign(
                        {
                            goal: '',
                            key_elements: '',
                            target: '',
                            place: '',
                            tone: '',
                            style: '',
                            format: '',
                            duration: '',
                            language: ''
                        },
                        newValue.context
                    );
                    this.brand_id = !!this.brands[newValue.brand.id] ? newValue.brand.id : this._defaultBrandId;
                }
            }
        },

        focus(newValue) {
            if (newValue !== null) {
                this.$refs.$briefDropdown.toggle(newValue);
                this.$refs.$goalDropdown.toggle(false);
                this.$refs.$renderingDropdown.toggle(false);
            }
        },

        activeDropdownId(newValue) {
            switch (newValue) {
                case 'companion-bar-brief':
                    this.$refs.$briefFocusElement.focus();
                    break;
                case 'companion-bar-goal':
                    this.$refs.$goalFocusElement.focus();
                    break;
            }
        }
    },

    methods: {
        sprintf,

        setActiveDropdown(dropdown) {
            this.activeDropdownId = dropdown.id;
        },

        unsetActiveDropdown(dropdown) {
            if (this.activeDropdownId === dropdown.id) {
                this.activeDropdownId = null;
            }
        },

        getPromptData() {
            return {
                prompt: this.input,
                context: {
                    ...this.context,
                    brand_id: this.brand_id
                }
            };
        },

        emitChangeEvent() {
            this.$emit(UI_COMPANION_BAR_CHANGE, this.getPromptData());
        },

        emitSubmitEvent() {
            this.$emit(UI_COMPANION_BAR_SUBMIT, this.getPromptData());
        }
    },

    created() {
        this._defaultBrandId = this.$store.state.ui.defaultBrandId;
    },

    mounted() {
        if (!this.brand_id) this.brand_id = this._defaultBrandId;
    }
};
</script>
