<template>
    <component :is="iconComponent" v-if="iconComponent" fill="currentColor" class="icon" />
    <div v-else class="icon-placeholder"></div>
</template>

<script setup>
import { markRaw, ref, watch } from 'vue';
import * as Sentry from '@sentry/browser';

const props = defineProps({
    icon: {
        type: String,
        required: true
    }
});

const iconComponent = ref(null);

const loadIcon = async () => {
    try {
        const module = await import(`@/assets/svg/${props.icon}.svg`);
        iconComponent.value = markRaw(module.default);
    } catch (error) {
        Sentry.captureException(error);
        console.warn(`Failed to load icon: ${props.icon}`, error);
        iconComponent.value = null;
    }
};

loadIcon();

watch(() => props.icon, loadIcon);
</script>
