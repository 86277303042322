// Vuex module to handle uploaded files
const FILE_REMOVED = null;

const state = () => ({
    templateThumbnail: ''
});

const getters = {
    templateThumbnailUrl: (state) =>
        state.templateThumbnail instanceof File ? URL.createObjectURL(state.templateThumbnail) : state.templateThumbnail
};

const mutations = {
    setFileToType(state, { type, data }) {
        state[type] = data;
    },

    removeFileOfType(state, type) {
        state[type] = FILE_REMOVED;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations
};
