import Echo from 'laravel-echo';
import pusherJs from 'pusher-js';
import BroadcastingDataService from '@/js/application/services/broadcasting/BroadcastingDataService.js';

window.Pusher = pusherJs;

const initializeEcho = () => {
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: import.meta.env.VITE_PUSHER_APP_KEY,
        cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
        forceTLS: true,
        authorizer: (channel, options) => {
            return {
                authorize: (socketId, callback) => {
                    new BroadcastingDataService()
                        .postBroadcastingAuth({
                            socket_id: socketId,
                            channel_name: channel.name
                        })
                        .then((data) => {
                            console.log(data);
                            callback(false, data);
                        })
                        .catch((error) => {
                            callback(error);
                        });
                }
            };
        }
    });
};

export { initializeEcho };
