<template>
    <ui-card :id="id" :class="'ui-card-' + id" :title="$t('Brand languages')">
        <template #header-icon>
            <fa-icon class="icon" icon="fa-regular fa-globe" />
        </template>

        <div class="template-languages-selection ui-card-row" :class="{ disabled: readOnly }">
            <label>{{ $t('Template languages') }}</label>
            <ui-dropdown
                id="settings-template-languages"
                :select="true"
                :multiple="true"
                v-model="templateLanguages"
                :disabled="readOnly"
            >
                <ui-dropdown-item
                    v-for="lang in availableLanguages"
                    :key="lang.value"
                    :value="lang.value"
                    :label="lang.label"
                />
            </ui-dropdown>
        </div>
    </ui-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import _isEqual from 'lodash/isEqual';
import { BrandCard } from '../../../../mixins';

export default {
    mixins: [BrandCard],

    components: {
        //
    },

    props: {
        id: {
            type: String,
            default: 'settings-template-languages'
        }
    },

    data() {
        return {
            //
        };
    },

    computed: {
        ...mapState({
            availableTemplateLanguages: (state) => state.ui.availableTemplateLanguages
        }),

        ...mapGetters({
            // canHideElement: 'ui/canHideElement',
        }),

        availableLanguages() {
            return this.availableTemplateLanguages
                .map((language) => ({ value: language, label: this.$t('studio.template_languages.' + language) }))
                .sort((languageA, languageB) => languageA.label.localeCompare(languageB.label, this.shortLanguage));
        },

        templateLanguages: {
            get() {
                return this.state.enabledTemplateLanguages;
            },
            set(languages) {
                languages = languages.filter((language) => this.availableTemplateLanguages.includes(language));
                if (!languages.length) languages = [this.shortLanguage];
                if (!_isEqual(languages, this.templateLanguages)) {
                    this.modifyBrand(() => {
                        this.$store.commit('branding/enableTemplateLanguages', languages);
                    });
                }
            }
        }
    },

    watch: {
        //
    },

    methods: {
        //
    },

    created() {
        //
    },

    mounted() {
        //
    }
};
</script>
