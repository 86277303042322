<template>
  <div class="audio-loading-container" :style="containerStyle">
    <canvas ref="canvasRef" :width="width" :height="height" class="audio-loading-canvas"></canvas>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch, computed } from 'vue';
import gsap from 'gsap';

const props = defineProps({
  isLoading: {
    type: Boolean,
    required: true
  },
  width: {
    type: Number,
    default: 200
  },
  height: {
    type: Number,
    default: 58
  }
});

const canvasRef = ref(null);
const animationRef = ref(null);

const containerStyle = computed(() => ({
  width: `${props.width}px`,
  height: `${props.height}px`,
  position: 'absolute',
  top: '0',
  left: '0'
}));

const textStyle = computed(() => ({
  lineHeight: `${props.height}px`
}));

const drawWave = (progress) => {
  const canvas = canvasRef.value;
  if (!canvas) return;

  const ctx = canvas.getContext('2d');
  const width = canvas.width;
  const height = canvas.height / 2;

  ctx.clearRect(0, 0, width, height);
  
  // Fond semi-transparent
  ctx.fillStyle = 'rgba(58, 68, 82, 0)';
  ctx.fillRect(0, 0, width, height);

  ctx.beginPath();

  const gradient = ctx.createLinearGradient(0, height * 0.4, 0, height * 0.6);
  gradient.addColorStop(0, 'rgba(255, 0, 127, 0.5)');
  gradient.addColorStop(0.5, 'rgba(255, 0, 95, 0.5)');
  gradient.addColorStop(1, 'rgba(255, 0, 73, 0.5)');
  ctx.fillStyle = gradient;

  const centerY = height / 2;
  const amplitude = height * 0.1; // 20% de la hauteur totale (10% au-dessus et 10% en dessous du centre)

  ctx.moveTo(0, centerY);

  for (let i = 0; i < width; i++) {
    const normalizedProgress = (i / width + progress) % 1;
    const y = centerY + Math.sin(normalizedProgress * Math.PI * 4) * amplitude / 2;
    ctx.lineTo(i, y);
  }

  ctx.lineTo(width, height);
  ctx.lineTo(0, height);
  ctx.closePath();
  ctx.fill();
};

const startAnimation = () => {
  if (animationRef.value) return;

  animationRef.value = gsap.to({}, {
    duration: 2,
    repeat: -1,
    onUpdate: () => drawWave(animationRef.value.progress()),
  });
};

const stopAnimation = () => {
  if (animationRef.value) {
    animationRef.value.kill();
    animationRef.value = null;
  }
};

watch(() => props.isLoading, (newValue) => {
  if (newValue) {
    startAnimation();
  } else {
    stopAnimation();
  }
});

onMounted(() => {
  if (props.isLoading) {
    startAnimation();
  }
});

onUnmounted(() => {
  stopAnimation();
});
</script>

<style scoped>
.audio-loading-container {
  overflow: hidden;
  pointer-events: none;
  z-index: 10;
}

.audio-loading-canvas {
  position: absolute;
  top: 50%;
  left: 0;
}

.audio-loading-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
}
</style>