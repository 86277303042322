<template>
    <section class="ui-body-panel" role="application" aria-describedby="ui-templates-title">
        <h2 id="ui-templates-title" class="visually-hidden">{{ $t('brands.panels.templates') }}</h2>

        <div class="ui-card-container">
            <card-templates id="templates" :title="$t('Templates')" library="templates" :panel="panelId" />
        </div>
    </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { TEMPLATES_BRAND_PANEL_ID } from '../../../../constants';
import { CardTemplates } from './cards';

export default {
    mixins: [
        //
    ],

    components: {
        CardTemplates
    },

    props: {
        //
    },

    data() {
        return {
            panelId: TEMPLATES_BRAND_PANEL_ID
        };
    },

    computed: {
        ...mapState({
            //
        }),

        ...mapGetters({
            //
        })
    },

    watch: {
        //
    },

    methods: {
        //
    },

    mounted() {
        //
    }
};
</script>
