<template>
    <ui-card :id="id" :class="'ui-card-' + id" :title="$t('Video format')">
        <template #header-icon>
            <svg-icon icon="format-icon" />
        </template>
        <fieldset class="video-formats ui-card-row">
            <legend class="visually-hidden">{{ $t('Video format selection') }}</legend>
            <div class="ui-format-selection">
                <label
                    v-for="(format, index) in standardFormats"
                    :id="formatElementIds[index]"
                    class="video-format"
                    :for="formatInputIds[index]"
                >
                    <input
                        type="radio"
                        :id="formatInputIds[index]"
                        name="video-format"
                        class="video-format-input"
                        v-model="selectedFormat"
                        :value="format.ratio"
                        :disabled="readOnly"
                    />
                    <svg-icon :icon="'video-format-' + format.ratioString + '-icon'" class="video-format-icon" />
                    <span class="visually-hidden">{{ format.label }}</span>
                    <div class="video-format-selected">
                        <fa-icon class="icon" icon="fa-solid fa-check" />
                    </div>
                </label>
            </div>
        </fieldset>
        <div v-if="!isTemplate" class="video-format-resolution-setting ui-card-row">
            <label
                class="video-format-resolution-label off"
                for="video-format-resolution-switch-toggle"
                :aria-hidden="!isSelectedFormatHd || null"
            >
                <a :href="greenVideoInfoUrl" target="_blank">
                    <fa-icon class="icon" icon="fa-solid fa-circle-info" />
                    <span>{{ $t('Green HD') }}</span>
                </a>
            </label>
            <ui-switch-button
                class="video-format-resolution"
                id="video-format-resolution"
                :show-label="false"
                v-model="isSelectedFormatHd"
                :disabled="readOnly"
            ></ui-switch-button>
            <label
                class="video-format-resolution-label on"
                for="video-format-resolution-switch-toggle"
                :aria-hidden="isSelectedFormatHd || null"
                @[selectedFormatHdEvent].prevent=""
            >
                {{ $t('Full HD') }}
            </label>
        </div>
    </ui-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import * as UiComponents from '../../../../../components';
import { ModifiesHistory, SavesVideo } from '../../../../mixins';
import UiIcon from '../../../../../components/UiIcon.vue';

export default {
    mixins: [ModifiesHistory, SavesVideo],

    components: {
        UiIcon,
        ...UiComponents
    },

    props: {
        id: {
            type: String,
            default: 'settings-format'
        }
    },

    data() {
        return {
            //
        };
    },

    computed: {
        ...mapState({
            readOnly: (state) => state.ui.readOnly,
            availableFormats: (state) => state.ui.availableFormats,
            greenVideoInfoUrl: (state) => state.ui.urls.greenVideoInfo,
            state: (state) => state.display.format,
            isTemplate: (state) => state.settings.isTemplate
        }),

        ...mapGetters({
            currentFormatString: 'display/formatString',
            currentFormatRatio: 'display/formatRatio',
            standardFormats: 'ui/availableStandardFormats'
        }),

        formatElementIds() {
            return this.standardFormats.map((format) => 'video-format-' + format.ratioString);
        },

        formatInputIds() {
            return this.standardFormats.map((format) => 'video-format-' + format.ratioString + '-input');
        },

        selectedFormat: {
            get() {
                return this.currentFormatRatio;
            },
            set(value) {
                let format = this.availableFormats.find(
                    (format) => format.ratio == value && format.hd == this.isSelectedFormatHd
                );
                if (format) {
                    this.startHistoryStep();
                    this.setVideoFormat(format);
                    this.saveVideo();
                }
            }
        },

        isSelectedFormatHd: {
            get() {
                return this.$store.getters['ui/isHdFormat'](this.currentFormatString);
            },
            set(value) {
                let format = this.availableFormats.find(
                    (format) => format.ratio == this.selectedFormat && format.hd == value
                );
                if (format) {
                    this.startHistoryStep();
                    this.setVideoFormat(format);
                    this.saveVideo();
                }
            }
        },

        selectedFormatHdEvent() {
            return this.isSelectedFormatHd ? 'click' : null;
        },

        selectedFormatNotHdEvent() {
            return !this.isSelectedFormatHd ? 'click' : null;
        }
    },

    watch: {
        selectedFormat() {
            // clear cache of sequence templates when video format is changed
            this.$store.commit('branding/libraries/unloadLibrary', 'sequenceTemplates');
        }
    },

    methods: {
        ...mapActions({
            setVideoFormat: 'display/setFormat'
        })
    },

    mounted() {
        //
    }
};
</script>
