<template>
    <div class="dropdown-list-item" @click="selectValue">
        <slot />
    </div>
</template>

<script setup>
import { inject } from 'vue';

const props = defineProps(['value']);

const model = inject('model');

const selectValue = () => {
    model.value = props.value;
};
</script>
