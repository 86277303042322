import VideoDTO from '@/js/dto/VideoDTO.js';
import UserDTO from '@/js/dto/UserDTO.js';
import Roles from '@/js/constants/roles.js';

const state = {
    user: {},
    latestVideos: []
};

const getters = {
    user(state) {
        return state.user;
    },

    getLatestVideos(state) {
        return state.latestVideos;
    },

    isCTE: (state) => state.user?.role?.id === Roles.ADMIN_ROLE_ID,
    isClientAdmin: (state) => state.user?.role?.id === Roles.SUPERVISOR_ROLE_ID,

    isLoggedIn: (state) => !!state.user?.id
};

const mutations = {
    setUser(state, user) {
        state.user = user;
    },

    resetLatestVideos(state) {
        state.latestVideos = [];
    },

    addLatestVideos(state, video) {
        state.latestVideos.push(video);
    }
};

const actions = {
    setUserData({ commit }, userData) {
        commit('setUser', new UserDTO(userData));
    },

    setUserLatestVideosData({ commit }, userLatestVideosData) {
        commit('resetLatestVideos');
        (userLatestVideosData ?? []).forEach((videoData) => {
            commit('addLatestVideos', new VideoDTO(videoData));
        });
    }
};

export default {
    namespaced: true,

    state,
    getters,
    mutations,
    actions
};
