<template>
    <ui-dropdown
        :id="'card-seq-' + cardElementId + '-duration'"
        class="style-dropdown centered"
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="bottom"
        menu-strategy="fixed"
        :toggle-style="toggleStyle"
        :icon-only="iconOnly"
    >
        <template #dropdown-toggle>
            <fa-icon class="icon" icon="fa-regular fa-clock" />
            <span>{{ $t('Length') }}</span>
        </template>
        <div class="sequence-duration ui-card-row">
            <ui-number-input
                :id="cardElementId + '-duration'"
                :class="{ auto: isSequenceDurationAuto }"
                v-model="sequenceDuration"
                :min="0"
                :label="$t('Sequence duration (in s.)')"
                :disabled="readOnly || sequenceState.options.hidden"
            />
            <label
                :for="cardElementId + '-duration-auto'"
                class="sequence-duration-auto"
                :class="{ disabled: readOnly || sequenceState.options.hidden }"
            >
                <input
                    type="checkbox"
                    :id="cardElementId + '-duration-auto'"
                    :checked="isSequenceDurationAuto"
                    :disabled="readOnly || sequenceState.options.hidden"
                    @change="handleSequenceDurationAutoChange"
                />
                <span>{{ $t('Auto') }}</span>
            </label>
        </div>
    </ui-dropdown>
</template>

<script>
import { useStore } from 'vuex';
import { defineComponent, computed } from 'vue';
import UiIcon from '../../../../../../components/UiIcon.vue';
import UiNumberInput from '../../../../../../components/UiNumberInput.vue';
import UiDropdown from '../../../../../../components/UiDropdown.vue';
import { useHistory } from '../../../../../composables/useHistory';
import { useSequence } from '../../../../../composables/useSequence';
import { useDisplay } from '../../../../../composables/useDisplay';
import { Duration } from 'cte-video-studio';

export default defineComponent({
    name: 'UiBackgroundDurationSelector',
    components: {
        UiIcon,
        UiNumberInput,
        UiDropdown,
        useDisplay
    },
    props: {
        seqId: String,
        cardElementId: String,
        readOnly: Boolean,
        iconOnly: Boolean
    },
    setup(props) {
        const store = useStore();
        const { saveHistoryStep } = useHistory();
        const {
            sequenceState,
            sequenceStoreModulePath,
            isLastVisibleSequence,
            isNextVisibleSequence,
            hasPreviousBackground,
            resolvedBackgroundState
        } = useSequence(props.seqId);
        const { labels } = useDisplay();

        const sequenceTotalTime = computed(() => {
            if (!sequenceState.value?.options?.hidden) {
                const endTime = labels.value[isLastVisibleSequence.value ? 'end' : isNextVisibleSequence.value.id];
                const startTime = labels.value[props.seqId];
                return Number((endTime - startTime).toFixed(2));
            }
            return '';
        });

        const isSequenceDurationAuto = computed(() => sequenceState.value.options.duration === Duration.NONE);

        const sequenceDuration = computed({
            get: () => (!isSequenceDurationAuto.value ? sequenceState.value.options.duration : sequenceTotalTime.value),
            set: (value) => handleDurationChange(value)
        });

        const toggleStyle = computed(() => {
            if (!isSequenceDurationAuto.value) return { color: 'var(--edit-mode-color)' };
            else return {};
        });

        function handleDurationChange(value) {
            if (value === 0) value = Duration.NONE;
            // Save history step before committing changes
            saveHistoryStep(() => {
                store.commit(`${sequenceStoreModulePath.value}/setDuration`, value);
            });
        }

        const handleSequenceDurationAutoChange = () => {
            sequenceDuration.value = isSequenceDurationAuto.value ? sequenceTotalTime.value : Duration.NONE;
        };

        return {
            sequenceDuration,
            isSequenceDurationAuto,
            sequenceState,
            hasPreviousBackground,
            toggleStyle,
            handleSequenceDurationAutoChange,
        };
    }
});
</script>
