<script>
import { h, inject } from 'vue';
import { v4 as uuidv4 } from 'uuid';

const UiIcon = (props, context) => {
    const iconSlots = inject('iconSlots');
    const render = iconSlots()[props.icon];
    let slot;

    if (render) {
        const content = render({ randomId: uuidv4() });
        slot = h(
            content[0].type,
            {
                ...content[0].props,
                ...context.attrs,
                class: [content[0].props.class, context.attrs.class].filter((c) => !!c).join(' ')
            },
            [...(content[0].children || content[0].text || [])]
        );
    }

    return slot || null;
};

UiIcon.props = {
    icon: String
};

export default UiIcon;
</script>
