<!--
  -- Component name: Number8
  -- Type: Transition
  -- Uses: color.start
  -- Tags: number
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <defs>
            <mask :id="svgMaskID">
                <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
                <path
                    class="color-number"
                    d="M597.9,352.5c-22.4-8.1-36.2-22.7-40.3-45.9c-6.8-38.8,8.8-69.3,48.5-82.4c19.6-6.5,39.8-6.7,60.2-5.2
				c15.2,1.1,29.6,4.8,42.9,12.2c18.9,10.5,30.6,26.1,33.2,48c1.9,15.9,0.6,31.3-6.9,45.6c-7.3,13.8-19.2,22.4-33.1,27.1
				c7.2,3.8,15.1,7.2,22.1,12c12.2,8.5,19.1,20.9,21.9,35.4c3.1,16.3,2.9,32.5-3.6,48c-9.7,23.2-28.8,35-51.9,41.7
				c-17.8,5.1-36,5.8-54.2,4.6c-22.3-1.4-43.7-6.6-61.5-21.3c-13.6-11.2-21-25.7-23.3-43.2c-1.6-12.6-0.8-24.8,2.8-36.9
				c5.3-17.8,17.3-29.5,34.1-36.6C591.6,354.6,594.5,353.7,597.9,352.5z M704.8,416.8c0-24.7-11.1-39.2-34.3-44.5
				c-11.8-2.7-23.7-3.1-35.7-1.2c-9,1.5-17.8,3.8-25.2,9.7c-13,10.3-16.1,24.5-15.3,39.9c1.1,19.6,11.4,32,30.3,37.2
				c12.9,3.6,26.1,4.2,39.4,2.3C687.9,456.9,705.1,445.4,704.8,416.8z M649.5,335.2c6.3-0.8,12.7-1.1,18.9-2.4
				c22.6-4.7,30.9-19.9,31.5-39.8c0.7-21.1-7.7-33.4-25.9-38.6c-16.1-4.6-32.5-4.6-48.6,0c-13.2,3.7-22.5,11.8-24.9,25.6
				c-1.2,6.6-1.1,13.7-0.3,20.4c1.5,12.2,7.1,22.1,18.5,28C628.4,333.3,638.7,335,649.5,335.2z"
                />
            </mask>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" :fill="state.color.start" :mask="svgMask" />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {
        svgMaskID() {
            return this.containerID + '-number8-mask';
        },

        svgMask() {
            return 'url(#' + this.svgMaskID + ')';
        }
    },

    watch: {
        svgMaskRadius: {
            handler() {
                this.updateTimeline();
            }
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            t.fromTo(
                '#' + this.svgMaskID + ' .color-number',
                { scale: 1 },
                { scale: 1.5, transformOrigin: '50% 50%', duration: 1 },
                0
            );
            t.fromTo(
                '#' + this.svgMaskID + ' .color-number',
                { scale: 1.5 },
                { scale: 50, translateX: '-100%', transformOrigin: '50% 50%', duration: 0.2 },
                1
            );
            return t;
        }
    }
};
</script>
