<template>
    <ui-card
        :id="cardElementId"
        :class="[{ 'ui-card-editing': editing }, 'ui-card-' + id]"
        :title="$t('Message {number}', { number })"
        :added-card-id="addedCardId"
        v-on:mousedown="editingElement"
    >
        <template #header-icon>
            <svg-icon icon="message-icon" />
        </template>
        <template #menu-content>
            <ui-dropdown-item :disabled="!canCopyCard" @click.prevent="copyCard">
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-copy" />
                    {{ $t('Copy') }}
                </template>
            </ui-dropdown-item>

            <ui-dropdown-item :disabled="!canPasteCard" @click.prevent="pasteCard">
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-clipboard" />
                    {{ $t('Paste') }}
                </template>
            </ui-dropdown-item>

            <ui-dropdown-item
                v-if="isEmotionMode"
                classes="ui-duplicate-item"
                :disabled="!canDuplicateMessage"
                @click.prevent="duplicateCard()"
            >
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-clone" />
                    {{ $t('Duplicate') }}
                </template>
            </ui-dropdown-item>

            <ui-dropdown-item
                v-if="isEmotionMode"
                classes="ui-remove-item"
                :disabled="!canRemoveMessage"
                @click.prevent="removeCard"
            >
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-trash-can" />
                    {{ $t('Remove') }}
                </template>
            </ui-dropdown-item>
        </template>

        <div v-if="isEmotionMode" class="sequence-message-type ui-card-row">
            <ui-library-selector
                ref="$messageSelector"
                :id="cardElementId + '-type'"
                v-model="messageType"
                :default-value="messageDefaultType"
                library="messages"
                preview-path="messages/"
                :label="$t('Choose an animation')"
                :disabled="readOnly"
                icon="fa-solid fa-sparkles"
                @[librarySelectorShowEvent]="openMessageLibrary"
                @[librarySelectorPreviewChangeEvent]="previewMessageType"
            />
        </div>

        <div class="sequence-message-text ui-card-row">
            <ui-text-editor
                :id="cardElementId + '-text'"
                v-model="messageText"
                :message-type="messageType"
                :previewing="isMessagePreview"
                :font-size="messageFontSize"
                :align="messageAlign"
                :palette="messageColors"
                :marks="messageMarks"
                :label="$t('Message text')"
                :show-label="false"
                :disabled="readOnly"
                :enableAlignJustify="false"
                @[textEditorFontSizeChangeEvent]="messageFontSize = $event"
                @[textEditorAlignChangeEvent]="messageAlign = $event"
                @[textEditorPreviewChangeEvent]="previewMessageText"
            />
        </div>

        <div v-if="messageUses.some((use) => /^palette\.color/.test(use))" class="sequence-message-colors ui-card-row">
            <ui-color-selector
                v-if="messageUses.includes('palette.color1')"
                :id="cardElementId + '-color1'"
                key="color-selector-message-color1"
                v-model:color="messageColor1"
                default-color="color1"
                :palette="brandPalette"
                :enable-other-colors="brandEnableOtherColors"
                :label="sprintf($t('Color %1$d'), 1)"
                :showLabel="false"
                :disabled="readOnly"
            />
            <ui-color-selector
                v-if="messageUses.includes('palette.color2')"
                :id="cardElementId + '-color2'"
                key="color-selector-message-color2"
                v-model:color="messageColor2"
                default-color="color2"
                :palette="brandPalette"
                :enable-other-colors="brandEnableOtherColors"
                :label="sprintf($t('Color %1$d'), 2)"
                :showLabel="false"
                :disabled="readOnly"
            />
            <ui-color-selector
                v-if="messageUses.includes('palette.color3')"
                :id="cardElementId + '-color3'"
                key="color-selector-message-color3"
                v-model:color="messageColor3"
                default-color="color2"
                :palette="brandPalette"
                :enable-other-colors="brandEnableOtherColors"
                :label="sprintf($t('Color %1$d'), 3)"
                :showLabel="false"
                :disabled="readOnly"
            />
            <ui-color-selector
                v-if="messageUses.includes('palette.color4')"
                :id="cardElementId + '-color4'"
                key="color-selector-message-color4"
                v-model:color="messageColor4"
                default-color="color2"
                :palette="brandPalette"
                :enable-other-colors="brandEnableOtherColors"
                :label="sprintf($t('Color %1$d'), 4)"
                :showLabel="false"
                :disabled="readOnly"
            />
            <ui-color-selector
                v-if="messageUses.includes('palette.color5')"
                :id="cardElementId + '-color5'"
                key="color-selector-message-color5"
                v-model:color="messageColor5"
                default-color="color2"
                :palette="brandPalette"
                :enable-other-colors="brandEnableOtherColors"
                :label="sprintf($t('Color %1$d'), 5)"
                :showLabel="false"
                :disabled="readOnly"
            />
        </div>
        <div v-if="messageUses.includes('palette.quotes')" class="sequence-message-quotes-color ui-card-row">
            <ui-color-selector
                :id="cardElementId + '-quotes-color'"
                key="color-selector-message-quotes-color"
                class="ui-card-row"
                v-model:color="messageQuotesColor"
                default-color="color1"
                :palette="brandPalette"
                :enable-other-colors="brandEnableOtherColors"
                :label="$t('Quotes color')"
                :showLabel="false"
                :disabled="readOnly"
            />
        </div>

        <div v-if="messageUses.includes('images')" class="sequence-message-images">
            <ul>
                <li
                    v-for="(messageImage, index) in messageImages"
                    :key="'message-image' + index"
                    class="sequence-message-image"
                >
                    <ui-media-selector
                        :id="cardElementId + '-image' + (index + 1)"
                        :key="'media-selector-message-image' + (index + 1)"
                        :media="messageImage"
                        :type="mediaTypeImage"
                        :label="sprintf($t('Image %1$d'), messageImagesMax > 1 ? index + 1 : '')"
                        :showLabel="false"
                        :disabled="readOnly"
                        @[mediaSelectorChangeEvent]="setMessageImage(index, $event)"
                        @[mediaSelectorShowEvent]="openMediaLibrary"
                    />
                    <button
                        v-if="messageImages.length > 1"
                        class="ui-simple-button ui-simple-button-small action-remove-image"
                        :title="$t('Remove image')"
                        :disabled="readOnly"
                        @click="removeMessageImage(index)"
                    >
                        <span class="visually-hidden" v-once>{{ $t('Remove image') }}</span>
                        <svg-icon icon="close-icon" />
                    </button>
                </li>
                <li class="add-sequence-message-image">
                    <button
                        v-if="messageImages.length < messageImagesMax"
                        class="ui-simple-button ui-simple-button-small action-add-image"
                        :title="$t('Add image')"
                        :disabled="readOnly"
                        @click="addMessageImage"
                    >
                        <span class="visually-hidden" v-once>{{ $t('Add image') }}</span>
                        <svg-icon icon="selector-add-icon" />
                    </button>
                </li>
            </ul>
        </div>

        <template #footer>
            <ui-dropdown
                v-if="isEmotionMode"
                :id="cardElementId + '-style'"
                class="centered style-dropdown"
                :menu="false"
                :caret="false"
                :scrollable="false"
                menu-placement="bottom-start"
                :toggle-style="toggleStyleMessageStyle"
            >
                <template #dropdown-toggle>
                    <fa-icon class="icon" icon="fa-regular fa-sparkles" />
                    <span>{{ $t('Style') }}</span>
                </template>
                <div class="message-font-selection ui-content-field ui-content-row" :class="{ disabled: readOnly }">
                    <label>{{ $t('Font') }}</label>
                    <ui-dropdown
                        :id="cardElementId + '-font'"
                        :select="true"
                        v-model="messageFont"
                        :disabled="readOnly"
                        :toggle-style="getMessageFontStyles(messageFont)"
                    >
                        <ui-dropdown-item
                            v-for="font in parsedFontLibrary"
                            :key="font.name"
                            :value="font.name"
                            :style="getMessageFontStyles(font.name)"
                        />
                    </ui-dropdown>
                </div>
                <ui-switch-button
                    :id="cardElementId + '-enable-shadow'"
                    class="ui-content-row"
                    :label="$t('Add shadow')"
                    v-model="shadowEnabled"
                    :disabled="readOnly"
                />
            </ui-dropdown>

            <ui-dropdown
                v-if="isEmotionMode"
                :id="cardElementId + '-position'"
                class="centered position-dropdown"
                :menu="false"
                :caret="false"
                :scrollable="false"
                menu-placement="bottom"
            >
                <template #dropdown-toggle>
                    <fa-icon class="icon" icon="fa-regular fa-arrows-up-down-left-right" />
                    <span>{{ $t('Position') }}</span>
                </template>
                <ui-number-input
                    :id="cardElementId + '-width'"
                    class="ui-content-row"
                    v-model="messageWidth"
                    :default-value="messageWidthDefault"
                    :min="1"
                    :decimals="1"
                    :label="$t('Width')"
                    :disabled="readOnly"
                />
                <ui-number-input
                    :id="cardElementId + '-height'"
                    class="ui-content-row"
                    v-model="messageHeight"
                    :default-value="messageHeightDefault"
                    :min="1"
                    :decimals="1"
                    :label="$t('Height')"
                    :disabled="readOnly"
                />
                <div class="ui-content-field ui-content-row" :class="{ disabled: readOnly }">
                    <label>{{ $t('Horizontal position') }}</label>
                    <ui-dropdown
                        :id="cardElementId + '-hor-position'"
                        :select="true"
                        :editable="true"
                        :editable-reg-exp="/^\-?\d+(?:(?:\.|,)\d+)?$/"
                        v-model="messageHorizontalPosition"
                        :disabled="readOnly"
                    >
                        <ui-dropdown-item
                            v-for="position in messageHorizontalPositions"
                            :key="position"
                            :value="position"
                            :label="$t('studio.alignments.' + position)"
                        />
                    </ui-dropdown>
                </div>
                <div class="ui-content-field ui-content-row" :class="{ disabled: readOnly }">
                    <label>{{ $t('Vertical position') }}</label>
                    <ui-dropdown
                        :id="cardElementId + '-ver-position'"
                        :select="true"
                        :editable="true"
                        :editable-reg-exp="/^\-?\d+(?:(?:\.|,)\d+)?$/"
                        v-model="messageVerticalPosition"
                        :disabled="readOnly"
                    >
                        <ui-dropdown-item
                            v-for="position in messageVerticalPositions"
                            :key="position"
                            :value="position"
                            :label="$t('studio.alignments.' + position)"
                        />
                    </ui-dropdown>
                </div>
            </ui-dropdown>

            <ui-dropdown
                :id="cardElementId + '-timing'"
                class="centered timing-dropdown"
                :menu="false"
                :caret="false"
                :scrollable="false"
                menu-placement="bottom-end"
                :toggle-style="toggleTimingMessageStyle"
            >
                <template #dropdown-toggle>
                    <fa-icon class="icon" icon="fa-regular fa-stopwatch" />
                    <span>{{ $t('Timing') }}</span>
                </template>
                <ui-number-input
                    :id="cardElementId + '-start'"
                    class="ui-content-row"
                    v-model="messageStart"
                    :default-value="0"
                    :min="0"
                    :label="$t('Starting time')"
                    :disabled="readOnly"
                />
                <ui-number-input
                    :id="cardElementId + '-end'"
                    class="ui-content-row"
                    v-model="messageEnd"
                    :default-value="messageEndDefault"
                    :min="messageStart"
                    :label="$t('Ending time')"
                    :disabled="readOnly"
                />
            </ui-dropdown>
        </template>
    </ui-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import _isEqual from 'lodash/isEqual';
import { printf as sprintf } from 'fast-printf';
import { Align, Color, Dimension, Duration, Message, STAGE_LOADING_ENDED_EVENT } from 'cte-video-studio';
import {
    MESSAGE_ELEMENT_ID,
    VERTICAL_POSITIONS,
    HORIZONTAL_POSITIONS,
    MAX_NB_OF_MESSAGES_PER_SEQUENCES
} from '../../../../../constants';
import {
    UI_TEXT_EDITOR_FONT_SIZE_CHANGE,
    UI_TEXT_EDITOR_ALIGN_CHANGE,
    UI_TEXT_EDITOR_PREVIEW_CHANGE
} from '../../../../../components/UiTextEditor.vue';
import { getPrefixedUuid } from '../../../../../utils';
import { SequenceCard } from '../../../../mixins';
import { v4 as uuidv4 } from 'uuid';

const MESSAGE_IMAGES_MAX = 5; // Note: Vuex store currently supports up to 5 images

export default {
    mixins: [SequenceCard],

    inject: ['$videoStudio'],

    components: {
        //
    },

    props: {
        id: {
            type: String,
            default: MESSAGE_ELEMENT_ID
        },

        elementId: {
            type: String,
            default: ''
        },

        editing: {
            type: Boolean,
            default: false
        },

        addedCardId: {
            type: String,
            default: ''
        },

        number: {
            type: Number,
            default: 1
        }
    },

    data() {
        return {
            textEditorFontSizeChangeEvent: UI_TEXT_EDITOR_FONT_SIZE_CHANGE,
            textEditorAlignChangeEvent: UI_TEXT_EDITOR_ALIGN_CHANGE,
            textEditorPreviewChangeEvent: UI_TEXT_EDITOR_PREVIEW_CHANGE,
            messageDefaultType: Message.DEFAULT,
            messageWidthDefault: Dimension.AUTO,
            messageHeightDefault: Dimension.AUTO,
            messageHorizontalPositions: HORIZONTAL_POSITIONS,
            messageVerticalPositions: VERTICAL_POSITIONS,
            messageEndDefault: Duration.END_DEFAULT,
            displayedMediaSelectors: 0,
            isMessageTypePreview: false
        };
    },

    computed: {
        ...mapState({
            shortLanguage: (state) => state.ui.shortLanguage,
            libraryId: (state) => state.ui.currentLibrary.id,
            fontLibrary: (state) => state.branding.libraries.fonts,
            restrictions: (state) => state.ui.restrictions,
            defaultFont: (state) => state.branding.font
        }),

        ...mapGetters({
            findLibraryItem: 'branding/libraries/findLibraryItem'
        }),

        cardElementId() {
            return this.seqId + '-' + this.elementId;
        },

        elementState() {
            return this.sequenceState[this.elementId];
        },

        elementStoreModulePath() {
            return this.sequenceStoreModulePath + '/' + this.elementId;
        },

        parsedFontLibrary() {
            return this.fontLibrary
                .slice()
                .sort((fontA, fontB) => fontA.name.localeCompare(fontB.name, this.shortLanguage));
        },

        canDuplicateMessage() {
            let messagesLength = this.$store.getters['sequences/' + this.seqId + '/messages'].length;
            return (
                this.canDuplicateCard &&
                messagesLength < MAX_NB_OF_MESSAGES_PER_SEQUENCES &&
                (this.restrictions.maxSequenceMessageCards === null ||
                    messagesLength < this.restrictions.maxSequenceMessageCards)
            );
        },

        isMessagePreview() {
            return this.isMessageTypePreview || this.libraryId == 'sequenceTemplates';
        },

        messageType: {
            get() {
                return this.elementState.animation.type;
            },
            set(type) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.elementStoreModulePath + '/setRotationDegrees', 0); // reset rotation when animation is changed
                    this.$store.commit(this.elementStoreModulePath + '/setAnimation', type);
                    // Save current message text state in case some markers were changed by changing message type
                    this.$store.commit(this.elementStoreModulePath + '/setText', this.messageText);
                    this.isMessageTypePreview = false;
                });
            }
        },

        messageMetadata() {
            return this.findLibraryItem('messages', '', this.messageType) || null;
        },

        messageUses() {
            return (this.messageMetadata && this.messageMetadata.uses) || [];
        },

        messageMarks() {
            return (this.messageMetadata ? this.messageMetadata.marks : []).reduce((marks, name) => {
                marks[name] = true;
                return marks;
            }, {});
        },

        messageText: {
            get() {
                return this.elementState.text;
            },
            set(value) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.elementStoreModulePath + '/setText', value);
                });
            }
        },

        messageFontSize: {
            get() {
                return this.elementState.size.autoSize
                    ? ''
                    : this.$store.getters[this.elementStoreModulePath + '/fontScale'] * Message.FONT_SIZE_DEFAULT;
            },
            set(value) {
                let fontScale =
                    value === ''
                        ? { value: Message.FONT_SCALE_DEFAULT, custom: null }
                        : { value: Message.FONT_SCALE_CUSTOM, custom: value / Message.FONT_SIZE_DEFAULT };

                if (
                    !_isEqual(fontScale, this.elementState.fontScale) ||
                    this.elementState.size.autoSize ^ (value === '')
                ) {
                    this.saveHistoryStep(() => {
                        if (this.elementState.size.autoSize ^ (value === '')) {
                            this.$store.commit(this.elementStoreModulePath + '/enableAutoSize', value === '');
                        }

                        if (!_isEqual(fontScale, this.elementState.fontScale)) {
                            this.$store.commit(this.elementStoreModulePath + '/setFontScale', fontScale);
                        }
                    });
                }
            }
        },

        messageAlign: {
            get() {
                return this.elementState.align || Align.TEXT_LEFT;
            },
            set(value) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.elementStoreModulePath + '/setAlign', value);
                });
            }
        },

        messageColor1: {
            get() {
                return this.getComputedColor('color1');
            },
            set(value) {
                this.setComputedColor('color1', value);
            }
        },

        messageColor2: {
            get() {
                return this.getComputedColor('color2');
            },
            set(value) {
                this.setComputedColor('color2', value);
            }
        },

        messageColor3: {
            get() {
                return this.getComputedColor('color3');
            },
            set(value) {
                this.setComputedColor('color3', value);
            }
        },

        messageColor4: {
            get() {
                return this.getComputedColor('color4');
            },
            set(value) {
                this.setComputedColor('color4', value);
            }
        },

        messageColor5: {
            get() {
                return this.getComputedColor('color5');
            },
            set(value) {
                this.setComputedColor('color5', value);
            }
        },

        messageQuotesColor: {
            get() {
                return this.getComputedColor('quotes');
            },
            set(value) {
                this.setComputedColor('quotes', value);
            }
        },

        messageColors() {
            return Object.keys(this.elementState.palette).reduce((palette, key) => {
                palette[key] = this.getComputedColor(key).value;
                return palette;
            }, {});
        },

        messageImagesMax() {
            if (!this.messageUses.includes('images')) return 0;

            return (
                this.messageUses.reduce((max, use) => max + Number(/^images\.image/.test(use)), 0) || MESSAGE_IMAGES_MAX
            );
        },

        messageImages: {
            get() {
                if (!this.messageImagesMax) return [];

                let index,
                    images = [];
                for (index = this.messageImagesMax; index > 0; --index) {
                    if (
                        !images.length &&
                        !this.$store.getters[this.elementStoreModulePath + '/image'](index) &&
                        index > Math.max(1, this.displayedMediaSelectors)
                    )
                        continue;

                    images[index - 1] = {
                        src: this.$store.getters[this.elementStoreModulePath + '/image'](index),
                        id: this.$store.getters[this.elementStoreModulePath + '/imageId'](index)
                    };
                }

                return images;
            },
            set(images) {
                let imagesStore = images.reduce((store, image, index) => {
                    if (image.id != this.$store.getters[this.elementStoreModulePath + '/imageId'](index + 1)) {
                        store['image' + (index + 1)] = image.src;
                        store['image' + (index + 1) + '__id'] = image.id;
                        if (!this.$store.getters[this.elementStoreModulePath + '/imageRef'](index + 1)) {
                            store['image' + (index + 1) + '__ref'] = getPrefixedUuid(this.prefixes.mediaReference);
                        }
                    }
                    return store;
                }, {});

                if (!!Object.keys(imagesStore).length) {
                    this.saveHistoryStep(() => {
                        if (this.displayedMediaSelectors != this.elementState.imageSelectors) {
                            this.$store.commit(
                                this.elementStoreModulePath + '/setImageSelectors',
                                this.displayedMediaSelectors
                            );
                        }
                        this.$store.commit(this.elementStoreModulePath + '/setImageReferences', imagesStore);
                        this.$store.dispatch(this.elementStoreModulePath + '/updateImages', imagesStore);
                    });
                }
            }
        },

        messageImageSelectors: {
            get() {
                return this.elementState.imageSelectors;
            },
            set(total) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.elementStoreModulePath + '/setImageSelectors', total);
                });
            }
        },

        messageFont: {
            get() {
                return this.$store.getters[this.elementStoreModulePath + '/font'];
            },
            set(font) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.elementStoreModulePath + '/setFont', { value: font, custom: null });
                });
            }
        },

        shadowEnabled: {
            get() {
                return this.elementState.shadow;
            },
            set(value) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.elementStoreModulePath + '/enableShadow', value);
                });
            }
        },

        messageWidth: {
            get() {
                return this.$store.getters[this.elementStoreModulePath + '/size'].width != Dimension.AUTO
                    ? parseFloat(this.$store.getters[this.elementStoreModulePath + '/size'].width)
                    : '';
            },
            set(value) {
                if (value != this.messageWidth) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(this.elementStoreModulePath + '/setWidth', {
                            value: Dimension.CUSTOM,
                            custom: value != Dimension.AUTO ? value + Dimension.PERCENT_UNIT : value
                        });
                    });
                }
            }
        },

        messageHeight: {
            get() {
                return this.$store.getters[this.elementStoreModulePath + '/size'].height != Dimension.AUTO
                    ? parseFloat(this.$store.getters[this.elementStoreModulePath + '/size'].height)
                    : '';
            },
            set(value) {
                if (value != this.messageHeight) {
                    this.saveHistoryStep(() => {
                        if ((value != Dimension.AUTO) ^ this.elementState.size.height.enabled) {
                            this.$store.commit(
                                this.elementStoreModulePath + '/enableHeight',
                                !this.elementState.size.height.enabled
                            );
                        }
                        this.$store.commit(this.elementStoreModulePath + '/setHeight', {
                            value: Dimension.CUSTOM,
                            custom: value != Dimension.AUTO ? value + Dimension.PERCENT_UNIT : value
                        });
                    });
                }
            }
        },

        messageHorizontalPosition: {
            get() {
                return this.elementState.position.alignH != Align.CUSTOM
                    ? this.elementState.position.alignH
                    : parseFloat(this.elementState.position.alignX);
            },
            set(value) {
                let parsedValue = value.replace(',', '.'),
                    position = {
                        alignH: !isNaN(parsedValue) ? Align.CUSTOM : value,
                        alignX: !isNaN(parsedValue) ? parsedValue : Dimension.ZERO
                    };
                if (
                    position.alignH != this.elementState.position.alignH ||
                    position.alignX != this.elementState.position.alignX
                ) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(
                            this.elementStoreModulePath + '/setPosition',
                            Object.assign({}, this.elementState.position, position)
                        );
                    });
                }
            }
        },

        messageVerticalPosition: {
            get() {
                return this.elementState.position.alignV != Align.CUSTOM
                    ? this.elementState.position.alignV
                    : parseFloat(this.elementState.position.alignY);
            },
            set(value) {
                let parsedValue = value.replace(',', '.'),
                    position = {
                        alignV: !isNaN(parsedValue) ? Align.CUSTOM : value,
                        alignY: !isNaN(parsedValue) ? parsedValue : Dimension.ZERO
                    };
                if (
                    position.alignV != this.elementState.position.alignV ||
                    position.alignY != this.elementState.position.alignY
                ) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(
                            this.elementStoreModulePath + '/setPosition',
                            Object.assign({}, this.elementState.position, position)
                        );
                    });
                }
            }
        },

        messageStart: {
            get() {
                return this.$store.getters[this.elementStoreModulePath + '/start'];
            },
            set(value) {
                if (value != this.messageStart) {
                    this.saveHistoryStep(() => {
                        if (this.messageEnd != '' && value > this.messageEnd) {
                            this.$store.commit(this.elementStoreModulePath + '/setEnd', {
                                value: value + (this.messageEnd - this.messageStart),
                                custom: Duration.NONE
                            });
                        }
                        this.$store.commit(this.elementStoreModulePath + '/setStart', { value, custom: Duration.NONE });
                    });
                }
            }
        },

        messageEnd: {
            get() {
                return this.$store.getters[this.elementStoreModulePath + '/end'] != Duration.END_DEFAULT
                    ? this.$store.getters[this.elementStoreModulePath + '/end']
                    : '';
            },
            set(value) {
                if (value != this.messageEnd) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(this.elementStoreModulePath + '/setEnd', { value, custom: Duration.NONE });
                    });
                }
            }
        },

        toggleStyleMessageStyle() {
            if (this.messageFont !== this.defaultFont.value || this.shadowEnabled === true) {
                return { color: 'var(--edit-mode-color)' };
            } else return {};
        },

        toggleTimingMessageStyle() {
            if (this.messageStart !== 0 || (this.messageEnd !== '' && this.messageEnd !== 0)) {
                return { color: 'var(--edit-mode-color)' };
            } else return {};
        }
    },

    watch: {
        'elementState.imageSelectors': {
            handler(newValue) {
                this.displayedMediaSelectors = newValue;
            }
        }
    },

    methods: {
        sprintf,

        copyCard() {
            if (this.canCopyCard) {
                this.$store.dispatch(this.elementStoreModulePath + '/copyToClipboard');
            }
        },

        pasteCard() {
            if (this.canPasteCard) {
                this.$videoStudio.studio.$stage.pauseTimeline();
                this.saveHistoryStep(() => {
                    this.$store.dispatch(this.elementStoreModulePath + '/pasteCard');
                });
            }
        },

        duplicateCard() {
            if (this.canDuplicateMessage) {
                this.$videoStudio.studio.$stage.pauseTimeline();
                this.startHistoryStep();
                this.$store.dispatch('sequences/' + this.seqId + '/duplicateMessage', {
                    newId: Message.PREFIX_ID + uuidv4(),
                    modelId: this.elementId
                });
                this.saveVideo();
            }
        },

        removeCard() {
            this.saveHistoryStep(() => {
                this.$store.dispatch(this.sequenceStoreModulePath + '/removeElement', this.elementId);
            });
        },

        openMessageLibrary(selector) {
            this.isMessageTypePreview = true;
            this.openLibrary(selector);
        },

        previewMessageType(type, cancel) {
            this.ignoreHistoryStep(() => {
                this.$store.commit(this.elementStoreModulePath + '/setRotationDegrees', 0);
                this.$store.commit(this.elementStoreModulePath + '/setAnimation', type);
                if (!cancel)
                    this.$videoStudio.studio.$stage.$el.addEventListener(
                        STAGE_LOADING_ENDED_EVENT,
                        this.playMessageTypePreview,
                        {
                            once: true
                        }
                    );
                else this.isMessageTypePreview = false;
            });
        },

        playMessageTypePreview() {
            this.playPreview(this.$store.getters[this.elementStoreModulePath + '/start'] || 0.001);
        },

        previewMessageText(text, cancel) {
            this.ignoreHistoryStep(() => {
                this.$store.commit(this.elementStoreModulePath + '/setText', text);
            });
        },

        getMessageFontStyles(font) {
            return {
                '--font-thumbnail': `url(/assets/thumbnails/fonts/${font}.gif)`
            };
        },

        getComputedColor(key) {
            let color = { ref: Color.NONE, value: Color.NONE };

            if (this.messageUses.includes('palette.' + key)) {
                color.ref = this.elementState.palette[key + '__ref'];
                color.value = this.elementState.palette[key];
            }

            return color;
        },

        setComputedColor(key, { ref, value }) {
            this.saveHistoryStep(() => {
                this.$store.commit(
                    this.elementStoreModulePath + '/setPalette',
                    Object.assign({}, this.elementState.palette, { [key + '__ref']: ref, [key]: value })
                );
            });
        },

        addMessageImage() {
            if (this.displayedMediaSelectors < this.messageImagesMax) {
                this.displayedMediaSelectors++;
                this.saveHistoryStep(() => {
                    this.$store.commit(
                        this.elementStoreModulePath + '/setImageSelectors',
                        this.displayedMediaSelectors
                    );
                });
            }
        },

        removeMessageImage(index) {
            if (this.messageImagesMax > 0 && this.displayedMediaSelectors > 1) {
                this.displayedMediaSelectors--;

                if (!!this.$store.getters[this.elementStoreModulePath + '/image'](index + 1)) {
                    let images = [...this.messageImages];
                    images.splice(index, 1);
                    images.push({ src: '', id: '' });
                    this.messageImages = [...images];
                } else {
                    this.saveHistoryStep(() => {
                        this.$store.commit(
                            this.elementStoreModulePath + '/setImageSelectors',
                            this.displayedMediaSelectors
                        );
                    });
                }
            }
        },

        setMessageImage(index, media) {
            let images = [...this.messageImages];
            images[index] = media;
            this.messageImages = [...images];
        },

        editingElement() {
            this.$videoStudio.studio.$stage.getSequenceElement(this.seqId).startEditingElement(null, this.elementId);
        }
    },

    created() {
        this.displayedMediaSelectors = Math.max(this.elementState.imageSelectors, this.messageImages.length);
    },

    mounted() {
        //
    }
};
</script>
