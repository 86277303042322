/**
 * Video Studio
 * Background constants
 */

const COLOR_TYPE = 'color';
const IMAGE_TYPE = 'image';
const VIDEO_TYPE = 'video';
const CARTOON_TYPE = 'cartoon';
const ANIMATED_TYPE = 'animated';
const COLLAGE_TYPE = 'collage';
const MAP_ZOOM_TYPE = 'mapZoom';
const RECORDING_TYPE = 'recording';
const PREVIOUS_TYPE = 'previous';

const LOADING_PRODUCTION_TYPE = 'loadingProduction';

const ANIMATION_NONE = 'None';

const MAP_ZOOM_WIDTH = 1280;
const MAP_ZOOM_HEIGHT = 1190;
const MAP_ZOOM_COORDS_PLACEHOLDER = '%%COORDS%%';
const MAP_ZOOM_LEVEL_PLACEHOLDER = '%%ZOOM_LEVEL%%';
const MAP_ZOOM_TYPE_PLACEHOLDER = '%%TYPE%%';
const MAP_ZOOM_API_KEY_PLACEHOLDER = '%%API_KEY%%';
const MAP_ZOOM_URL =
    'https://maps.googleapis.com/maps/api/staticmap?center=' +
    MAP_ZOOM_COORDS_PLACEHOLDER +
    '&scale=2&zoom=' +
    MAP_ZOOM_LEVEL_PLACEHOLDER +
    '&size=640x640&maptype=' +
    MAP_ZOOM_TYPE_PLACEHOLDER +
    '&sensor=false&format=jpg&key=' +
    MAP_ZOOM_API_KEY_PLACEHOLDER;
const MAP_ZOOM_LEVELS = [5, 7, 10, 12, 15];
const MAP_ZOOM_SATELLITE_TYPE = 'satellite';
const MAP_ZOOM_ROADMAP_TYPE = 'roadmap';
const DEFAULT_MAP_ZOOM_COORDS = '48.85842697334484,2.2944929259904505'; // Note: Eiffel Tower coordinates
const NO_MAP_ZOOM_COORDS = '';

const COLLAGE_HORIZONTAL_TYPE = 'CollageHorizontal';
const COLLAGE_INCLINED_TYPE = 'CollageInclined';
const COLLAGE_TABLE_TYPE = 'CollageTable';
const COLLAGE_VERTICAL_TYPE = 'CollageVertical';
const COLLAGE_MAX_MEDIA = 4;
const COLLAGE_DEFAULT_TYPE = COLLAGE_HORIZONTAL_TYPE;

const BLUR_FILTER_AMOUNT = 12;

const files = import.meta.glob('../animations/background/*.js');
const ANIMATION_TYPES = {};
for (let key in files) {
    key = key.split('/').pop();
    if (key === 'index.js') continue;

    key = key.replace(/(\.\/|\.js)/g, '');
    let parts = key.match(/([A-Z\d]+(?:[a-z]+|$))/g);
    ANIMATION_TYPES[parts.join('_').toUpperCase()] = key;
}

const Background = {
    PREFIX_CLASS: 'Background',

    DEFAULT: VIDEO_TYPE,

    COLOR_TYPE,
    IMAGE_TYPE,
    VIDEO_TYPE,
    CARTOON_TYPE,
    COLLAGE_TYPE,
    ANIMATED_TYPE,
    MAP_ZOOM_TYPE,
    RECORDING_TYPE,
    PREVIOUS_TYPE,
    LOADING_PRODUCTION_TYPE,
    TYPES: [
        COLOR_TYPE,
        IMAGE_TYPE,
        VIDEO_TYPE,
        CARTOON_TYPE,
        ANIMATED_TYPE,
        COLLAGE_TYPE,
        MAP_ZOOM_TYPE,
        RECORDING_TYPE,
        PREVIOUS_TYPE,
        LOADING_PRODUCTION_TYPE
    ],
    COLLAGE_TYPES: [COLLAGE_HORIZONTAL_TYPE, COLLAGE_INCLINED_TYPE, COLLAGE_TABLE_TYPE, COLLAGE_VERTICAL_TYPE],

    ANIMATION_TYPES,
    ANIMATION_DEFAULT: ANIMATION_NONE,
    ANIMATION_ZOOM_SCALE_DEFAULT: 1.3,

    MAP_ZOOM_WIDTH,
    MAP_ZOOM_HEIGHT,
    MAP_ZOOM_COORDS_PLACEHOLDER,
    MAP_ZOOM_LEVEL_PLACEHOLDER,
    MAP_ZOOM_TYPE_PLACEHOLDER,
    MAP_ZOOM_API_KEY_PLACEHOLDER,
    MAP_ZOOM_URL,
    MAP_ZOOM_LEVELS,
    MAP_ZOOM_SATELLITE_TYPE,
    MAP_ZOOM_ROADMAP_TYPE,
    DEFAULT_MAP_ZOOM_COORDS,
    NO_MAP_ZOOM_COORDS,

    COLLAGE_DEFAULT_TYPE,
    COLLAGE_INCLINED_TYPE,
    COLLAGE_MAX_MEDIA,

    BLUR_FILTER_AMOUNT
};

export default Background;
