<template>
    <div class="control-panel">
        <div class="control-panel-container">
            <div class="middle-container">
                <div class="split-button-container">
                    <button :disabled="!canSplit" @click="$emit('split')" class="split-button">
                        <svg-icon icon="split"></svg-icon>
                        <span>{{ $t('Split') }}</span>
                    </button>
                </div>
                <div class="controls">
                    <button :disabled="!canZoomOut" @click="$emit('zoomOut')" class="zoom-button">
                        <svg-icon icon="zoom-out-icon"></svg-icon>
                    </button>
                    <button :disabled="!canZoomIn" @click="$emit('zoomIn')" class="zoom-button">
                        <svg-icon icon="zoom-in-icon"></svg-icon>
                    </button>
                </div>
                <div class="controls">
                    <button class="history-button action-undo" :disabled="!canUndo" @click="undo">
                        <svg-icon icon="undo-icon" />
                    </button>
                    <button class="history-button action-redo" :disabled="!canRedo" @click="redo">
                        <svg-icon icon="redo-icon" />
                    </button>
                </div>
                <div class="close-button-container">
                    <button @click="$emit('close')" class="close-button">
                        {{ $t('confirm') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import zoomOutIcon from '@resources/assets/svg/zoom-out-icon.svg';
import zoomInIcon from '@resources/assets/svg/zoom-in-icon.svg';
import splitIcon from '@resources/assets/svg/split.svg';
import { useHistory } from '@video-composables/useHistory';

export default defineComponent({
    name: 'ControlButtons',
    emits: ['split', 'zoomIn', 'zoomOut', 'close'],
    props: {
        canZoomIn: {
            required: true
        },
        canZoomOut: {
            required: true
        },
        canSplit: {
            required: true
        }
    },
    setup() {
        const { undo, redo, canUndo, canRedo } = useHistory();
        return {
            zoomOutIcon,
            zoomInIcon,
            splitIcon,
            undo,
            redo,
            canUndo,
            canRedo
        };
    }
});
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/mixins';

.control-panel {
    position: relative;
    height: 24px;
}

.control-panel-container {
    position: absolute;
    top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.controls {
    display: flex;
    justify-content: space-between;
}

.icon {
    width: 16px;
    height: 16px;
    filter: brightness(0) invert(1);
}

.middle-container {
    display: flex;
}

button {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-right: 10px;
    padding: 8px 9px;
    font-size: rfs-value(14px);
    /*cte-light-dark*/
    background-color: #3a4452;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition:
        opacity 0.3s ease,
        background-color 0.3s ease;
    height: 34px;
}

button:hover {
    opacity: 0.8;
}

button:disabled {
    background-color: #2a3240;
    color: #ffffff;
    cursor: not-allowed;
    opacity: 0.6;
}

button:disabled:hover {
    opacity: 0.6;
}

.history-button,
.split-button,
.zoom-button,
.close-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-button {
    margin-right: 0;
    margin-left: 10px;
}
</style>
