<template>
    <div class="gen-image-ai-suggestion">
        <div class="gen-image-ai-suggestion-placeholder">
            <span class="prompt-text">{{ $t(suggestion.promptKey) }}</span>
            <button class="gen-image-ai-suggestion-button" @click="useSuggestion(suggestion)">
                {{ $t('image-generation.use-prompt') }}
            </button>
        </div>
        <div class="gen-image-ai-suggestion-overlay"></div>
        <img :src="suggestion.image" :alt="$t('image-generation.suggestion')" />
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    name: 'UiImageGenerationSuggestion',

    props: {
        suggestion: Object
    },

    emits: ['use-prompt'],

    setup(_, { emit }) {
        const { t } = useI18n();

        const useSuggestion = (suggestion) =>
            emit('use-prompt', { text: t(suggestion.promptKey), style: suggestion.style });

        return { useSuggestion };
    }
});
</script>
