import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';
import EmojiConvertor from 'emoji-js';

export const PLUGIN_NAME = 'emojiReplacer';
export const PLUGIN_KEY = new PluginKey(PLUGIN_NAME);

const findEmojis = (doc) => {
    const decorations = [];
    const emojiCss = new EmojiConvertor();
    const emojiWrapper = new EmojiConvertor();
    const emojiCssRE =
        /<span class="emoji-outer emoji-sizer"><span class="emoji-inner" style="([^"]+)" data-codepoints="([^"]+)"><\/span><\/span>/g;
    const emojiCssPositionRE = /background-position:[\d\.]+% [\d\.]+%/;
    const emojiWrapperRE = /<span class="emoji-native">([^<]+)<\/span>/g;

    emojiCss.replace_mode = 'css';
    emojiCss.use_sheet = true;
    emojiWrapper.replace_mode = 'unified';
    emojiWrapper.wrap_native = true;

    doc.descendants((node, position) => {
        if (!node.isText || !node.text) return;

        let replacedEmojis = emojiCss.replace_unified(node.text),
            wrappedEmojis = emojiWrapper.replace_unified(node.text),
            matchesCss = Array.from(replacedEmojis.matchAll(emojiCssRE)),
            matchesWrapper = Array.from(wrappedEmojis.matchAll(emojiWrapperRE)),
            length = 0;

        matchesCss.forEach((match, index) => {
            let [emojiBgPosition] = emojiCssPositionRE.exec(match[1]),
                startPos = position + match.index - length,
                endPos = startPos + matchesWrapper[index][1].length;

            decorations.push(
                Decoration.inline(startPos, endPos, {
                    class: 'emoji-icon',
                    style: emojiBgPosition
                })
            );
            length += match[0].length - matchesWrapper[index][1].length;
        });
    });

    return DecorationSet.create(doc, decorations);
};

export default Extension.create({
    name: PLUGIN_NAME,

    addProseMirrorPlugins() {
        return [
            /*new Plugin({
                key: PLUGIN_KEY,
                state: {
                    init(_, { doc }) {
                        return findEmojis(doc);
                    },
                    apply(transaction, value, oldState, newState) {
                        return transaction.docChanged ? findEmojis(transaction.doc) : value;
                    },
                },
                props: {
                    decorations(state) {
                        return this.getState(state);
                    },
                },
            }),*/
        ];
    }
});
