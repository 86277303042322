<template>
    <div v-if="!loading && !errorCode" class="companion-preview h-100">
        <div id="companion-video-preview" class="preview-layout">
            <div class="preview-studio-wrapper">
                <video-studio
                    v-if="studioReady"
                    ref="videoStudioRef"
                    id="studio"
                    :autoplay="true"
                    :companion-preview="true"
                />
                <canvas :width="formatWidth" :height="formatHeight" class="preview-studio-shape"></canvas>
            </div>
            <div id="studio-controls" class="preview-controls">
                <controls-timeline :reduced="true" />
            </div>
        </div>
    </div>
</template>

<script setup>
import VideoStudio from '@/js/video-studio/VideoStudio.vue';
import { nextTick, provide, ref } from 'vue';
import { useStore } from 'vuex';
import VideoStudioService from '@/js/infrastructure/api/video/VideoStudioService.js';
import StudioShowInitializer from '@/js/videos/application/StudioShowInitializer.js';
import { useRoute } from 'vue-router';
import { ControlsTimeline } from '@/js/videos/components/controls/index.js';

const store = useStore();
const route = useRoute();

const videoStudioRef = ref(null);
const studioReady = ref(false);
const videoName = ref('');
const formatWidth = ref('');
const formatHeight = ref('');

// component state with API call
const loading = ref(true);
const errorCode = ref(null);

const videoStudioService = new VideoStudioService(store);
const studioShowInitializer = new StudioShowInitializer(store, videoStudioService);

provide('$videoStudio', videoStudioRef);
const ready = () => {
    studioReady.value = true;

    videoName.value = store.state.settings.name;
    formatWidth.value = store.state.display.format.width;
    formatHeight.value = store.state.display.format.height;
};
studioShowInitializer.initVideoStudio(route.params.uuid, false, false).then((response) => {
    loading.value = false;

    if (response?.errorCode) errorCode.value = response.errorCode;
    else nextTick(ready);
});

// add class to body
document.body.classList.add('companion-video-preview');
</script>
