<template>
    <div id="error-screen" :class="`error-${errorCode}`">
        <div class="error-screen-overlay" />
        <div class="error-screen-container">
            <div class="error-screen-top">
                <img :src="logo" class="error-screen-logo" />
            </div>
            <div class="error-screen-middle">
                <div class="error-screen-title">{{ $t(`errors.${errorCode}.title`) }}</div>
                <div class="error-screen-description">{{ $t(`errors.${errorCode}.message`) }}</div>
                <div class="error-screen-button" @click="action">{{ $t(`errors.${errorCode}.action`) }}</div>
            </div>
            <div class="error-screen-bottom">
                <div class="error-screen-copyright">
                    {{ sprintf($t('copyrights'), new Date().getFullYear()) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { printf as sprintf } from 'fast-printf';
import logo from '@/assets/images/logo.png';
import { useRouter } from 'vue-router';

const props = defineProps({
    errorCode: {
        type: Number,
        default: 500
    }
});

const router = useRouter();

const action = () => {
    if ([403, 404].includes(props.errorCode)) {
        router.push('/');
    } else router.go(0); // reload
};
</script>
