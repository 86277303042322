<template>
    <section class="ui-body-panel">
        <div class="ui-card-container">
            <ui-card id="edit-client-team-summary-card" :hide-header="true">
                <div class="ui-card-row">
                    <edit-client-team-summary
                        :client="client"
                        :licenses-number="licensesNumber"
                        :licenses-limit-number="licensesLimitNumber"
                    />
                </div>
            </ui-card>

            <div class="edit-client-filter-container">
                <ui-text-input
                    id="search-user"
                    v-model="searchUserText"
                    :placeholder="$t('edit-client.team.search-user')"
                    icon="search-icon"
                    :show-label="false"
                />
                <div
                    class="button-wrapper"
                    v-tooltip="
                        !isCTE &&
                        licensesNumber >= licensesLimitNumber &&
                        $t('edit-client.team.maximum-licenses-reached')
                    "
                >
                    <button
                        class="ui-simple-button ui-simple-button-select ui-button-invite-user"
                        :disabled="!isCTE && licensesNumber >= licensesLimitNumber"
                        @click="openModal('InviteUserModal', { client, successCallback: userInvitedCallback })"
                    >
                        <fa-icon icon="fa-regular fa-user-plus" class="icon" />
                        <span>{{ $t('edit-client.team.invite-user') }}</span>
                    </button>
                </div>
            </div>

            <ui-card id="edit-client-users-card" :hide-header="true">
                <div class="ui-card-row">
                    <edit-client-users-list ref="clientUsersList" :search-text="searchUserText" :client="client" />
                </div>
            </ui-card>
        </div>
    </section>
</template>

<script setup>
import { computed, ref } from 'vue';
import UiCard from '@/js/components/UiCard.vue';
import EditClientTeamSummary from '@/views/EditClientView/components/EditClientTeamSummary.vue';
import ClientDTO from '@/js/dto/ClientDTO.js';
import EditClientUsersList from '@/views/EditClientView/components/EditClientUsersList.vue';
import UiTextInput from '@/js/components/UiTextInput.vue';
import { useModal } from '@/js/composables/useModal.js';
import UsesTooltip from '@/js/mixins/UsesTooltip.js';
import { useAuth } from '@/js/composables/useAuth.js';

const vTooltip = UsesTooltip.directives.tooltip;

const searchUserText = defineModel({ default: '' });
const props = defineProps({
    client: {
        type: ClientDTO,
        required: true
    }
});

const { isCTE } = useAuth();
const { openModal } = useModal();

const clientUsersList = ref(null);
const licensesNumber = computed(() => props.client.nbLicensesSupervisors + props.client.nbLicensesCreators);
const licensesLimitNumber = computed(() => props.client.emotionUsersLimit + props.client.contentUsersLimit);

// when user has been invited, refresh users list
const userInvitedCallback = () => {
    clientUsersList.value.resetDataForUsers();
    clientUsersList.value.fetchClientUsers();
};
</script>
