<template>
    <ui-modal
        v-bind="{ ...$props, ...$attrs }"
        ref="$modal"
        class="create-sequence-template-modal ui-modal-sm"
        :title="$t('Save as template')"
        :waiting-action="createSequenceTemplate"
    >
        <div class="sequence-categories ui-content-field ui-content-row">
            <label>{{ $t('Categories') }}</label>
            <ui-dropdown
                id="sequence-categories"
                :select="true"
                :multiple="true"
                v-model="selectedSequenceCategories"
                :disabled="readOnly"
                menu-strategy="fixed"
                :menu-max-size-enabled="true"
            >
                <template #dropdown-menu-top>
                    <ui-text-input
                        id="sequence-categories-filter"
                        type="search"
                        :placeholder="$t('Search')"
                        :show-label="false"
                        icon="search-icon"
                        v-model="sequenceCategoryFilter"
                    />
                </template>
                <ui-dropdown-item
                    v-for="category in filteredAvailableCategories"
                    :key="category.name"
                    :value="category.id"
                    :label="
                        getTemplateCategoryLabel(
                            $t,
                            'sequenceTemplates',
                            category.name,
                            $store.state.ui.organizationName
                        )
                    "
                />
            </ui-dropdown>
        </div>
        <div v-if="availableBrands.length > 1" class="sequence-brands ui-content-field ui-content-row">
            <label>{{ $t('Brands') }}</label>
            <ui-dropdown
                id="sequence-brands"
                :select="true"
                :multiple="true"
                v-model="sequenceBrands"
                :disabled="readOnly"
                menu-strategy="fixed"
                :menu-max-size-enabled="true"
            >
                <ui-dropdown-item
                    v-for="brand in availableBrands"
                    :key="brand.id"
                    :value="brand.id"
                    :label="brand.name"
                />
            </ui-dropdown>
        </div>
    </ui-modal>
</template>

<script>
import { mapState } from 'vuex';
import { Modal } from '../../../../mixins';
import { UiDropdown, UiDropdownItem } from '../../../../components';
import UiTextInput from '../../../../components/UiTextInput.vue';
import { getTemplateCategoryLabel } from '@/js/utils.js';

export default {
    mixins: [Modal],

    components: {
        UiDropdown,
        UiDropdownItem,
        UiTextInput
    },

    props: {
        sequenceId: String
    },

    data() {
        return {
            sequenceCategories: [],
            sequenceCategoryFilter: '',
            sequenceBrands: []
        };
    },

    computed: {
        ...mapState({
            shortLanguage: (state) => state.ui.shortLanguage,
            readOnly: (state) => state.ui.readOnly,
            availableBrands: (state) => state.ui.availableBrands,
            defaultSequenceCategory: (state) => state.ui.defaultSequenceCategory,
            availableSequenceCategories: (state) => state.ui.availableSequenceCategories,
            brandId: (state) => state.branding.id
        }),

        availableCategories() {
            // Note: This computed property is just an alias since
            // alphabetical sorting has been removed
            return [...this.availableSequenceCategories];
        },

        availableCategoryIds() {
            return this.availableSequenceCategories.map((category) => category.id);
        },

        filteredAvailableCategories() {
            if (!/\S/.test(this.sequenceCategoryFilter)) return this.availableCategories;

            let filters = this.sequenceCategoryFilter.split(/\s+/).filter((f) => f.length > 1);
            return this.availableCategories.filter((category) =>
                filters.every((filter) => category.label.toLowerCase().includes(filter.toLowerCase()))
            );
        },

        selectedSequenceCategories: {
            get() {
                return this.sequenceCategories;
            },
            set(categories) {
                categories = categories.filter((category) => this.availableCategoryIds.includes(category));
                if (!categories.length)
                    categories = [
                        this.availableSequenceCategories.find(
                            (category) => category.name == this.defaultSequenceCategory
                        )?.id
                    ];
                this.sequenceCategories = categories;
            }
        }
    },

    watch: {
        //
    },

    methods: {
        getTemplateCategoryLabel,
        async createSequenceTemplate() {
            try {
                await this.$store.dispatch('ui/createSequenceTemplate', {
                    sequenceId: this.sequenceId,
                    categoryIds: this.sequenceCategories,
                    activatedForBrandIds: this.sequenceBrands
                });
            } catch (error) {
                // TODO: Display an error notification?
            }
        }
    },

    mounted() {
        this.sequenceCategories = this.availableSequenceCategories
            .filter((category) => category.name == this.defaultSequenceCategory)
            .map((category) => category.id);
        this.sequenceBrands = !!this.availableBrands.length ? [this.brandId] : [];
    }
};
</script>
