<template>
    <div class="loading-marker">
        <span class="dots">
            <span v-for="i in 3" :key="i" :ref="(el) => (dots[i - 1] = el)" class="dot">.</span>
        </span>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import gsap from 'gsap';

const dots = ref([]);
let dotsTimeline = null;

const animateDots = () => {
    if (dots.value.length !== 3) return;

    dotsTimeline = gsap.timeline({ repeat: -1 });

    dotsTimeline.to(dots.value, {
        duration: 0.6,
        y: -10,
        stagger: {
            each: 0.2,
            repeat: -1,
            yoyo: true,
            ease: 'sine.inOut'
        },
        ease: 'sine.inOut'
    });
};

onMounted(() => {
    animateDots();
});

onBeforeUnmount(() => {
    if (dotsTimeline) {
        dotsTimeline.kill();
    }
});
</script>

<style scoped>
.loading-marker {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 16px;
    width: 100%;
}

.dots {
    display: inline-flex;
}

.dot {
    font-size: 24px;
    line-height: 1;
    opacity: 0.7;
    margin: 0 2px;
}
</style>
