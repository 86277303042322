import { ref, onMounted, onUnmounted } from 'vue';

const useAutoOffset = (initialOffset = 500) => {
    const offset = ref(initialOffset);

    const calculateOffset = () => {
        const viewportHeight = window.innerHeight;
        offset.value = Math.round(viewportHeight * 0.2);
    };

    onMounted(() => {
        calculateOffset();
        window.addEventListener('resize', calculateOffset);
    });

    onUnmounted(() => {
        window.removeEventListener('resize', calculateOffset);
    });

    return { offset };
};

export default useAutoOffset;
