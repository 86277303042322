<template>
    <ui-card :id="id" :class="'ui-card-' + id" :title="$t('Brand identity')" content-class="row">
        <template #header-icon>
            <fa-icon class="icon" icon="fa-regular fa-folder-image" />
        </template>

        <div class="settings-logos col-lg-6 col-xl-12 col-xxl-6">
            <ui-media-selector
                :id="id + '-logo-asset-image'"
                class="ui-card-row"
                key="media-selector-logo-asset-image"
                icon="fa-solid fa-plus"
                v-model:media="logoAssetImage"
                :type="mediaTypeImage"
                :label="$t('Colored logo')"
                :disabled="readOnly"
                @[mediaSelectorShowEvent]="openMediaLibrary"
            />
            <ui-media-selector
                :id="id + '-logo-image'"
                class="ui-card-row"
                key="media-selector-brand-logo-image"
                icon="fa-solid fa-plus"
                v-model:media="logoImage"
                :type="mediaTypeImage"
                :label="$t('White logo')"
                :disabled="readOnly"
                @[mediaSelectorShowEvent]="openMediaLibrary"
            />
            <ui-switch-button
                :id="id + '-enable-logo-override'"
                class="ui-card-row"
                :label="$t('Allow usage of another logo')"
                v-model="logoOverrideEnabled"
                :disabled="readOnly"
            />
        </div>

        <div v-if="false" class="settings-meta col-lg-6 col-xl-12 col-xxl-6">
            <ui-text-input
                :id="id + '-meta-brand'"
                class="ui-card-row"
                ref="$metaBrand"
                :horizontal="false"
                v-model="metaBrand"
                :label="$t('Brand name')"
                :disabled="readOnly"
            />
            <ui-text-input
                :id="id + '-meta-website'"
                class="ui-card-row"
                ref="$metaWebsite"
                :horizontal="false"
                v-model="metaWebsite"
                :label="$t('Website')"
                :disabled="readOnly"
            />
        </div>

        <template #footer>
            <ui-dropdown
                id="logo-animation"
                class="centered animation-dropdown"
                :select="true"
                v-model="logoAnimationType"
                :caret="false"
                :disabled="readOnly"
                menu-placement="bottom"
            >
                <template #dropdown-toggle>
                    <fa-icon class="icon" icon="fa-regular fa-sparkles" />
                    <span>{{ $t('Animation') }}</span>
                </template>

                <ui-dropdown-item
                    v-for="animation in activeLogoAnimationLibrary"
                    :key="animation.name"
                    :value="animation.name"
                    :label="animation.label"
                    :style="getLogoAnimationStyles(animation.name)"
                />
            </ui-dropdown>

            <ui-dropdown
                id="logo-position"
                class="centered position-dropdown"
                :menu="false"
                :caret="false"
                :scrollable="false"
                menu-placement="bottom"
            >
                <template #dropdown-toggle>
                    <fa-icon class="icon" icon="fa-regular fa-arrows-up-down-left-right" />
                    <span>{{ $t('Position') }}</span>
                </template>

                <ui-number-input
                    :id="id + '-logo-width'"
                    class="ui-content-row"
                    v-model="logoWidth"
                    :default-value="''"
                    :min="1"
                    :decimals="1"
                    :label="$t('Width')"
                    :disabled="readOnly"
                />
                <ui-number-input
                    :id="id + '-logo-height'"
                    class="ui-content-row"
                    v-model="logoHeight"
                    :default-value="''"
                    :min="1"
                    :decimals="1"
                    :label="$t('Height')"
                    :disabled="readOnly"
                />
                <div class="ui-content-field ui-content-row">
                    <label>{{ $t('Horizontal position') }}</label>
                    <ui-dropdown
                        :id="id + '-logo-hor-position'"
                        :select="true"
                        :editable="true"
                        :editable-reg-exp="/^\-?\d+(?:(?:\.|,)\d+)?$/"
                        v-model="logoHorizontalPosition"
                        :disabled="readOnly"
                    >
                        <ui-dropdown-item
                            v-for="position in logoHorizontalPositions"
                            :key="position"
                            :value="position"
                            :label="$t('studio.alignments.' + position)"
                        />
                    </ui-dropdown>
                </div>
                <div class="ui-content-field ui-content-row">
                    <label>{{ $t('Vertical position') }}</label>
                    <ui-dropdown
                        :id="id + '-logo-ver-position'"
                        :select="true"
                        :editable="true"
                        :editable-reg-exp="/^\-?\d+(?:(?:\.|,)\d+)?$/"
                        v-model="logoVerticalPosition"
                        :disabled="readOnly"
                    >
                        <ui-dropdown-item
                            v-for="position in logoVerticalPositions"
                            :key="position"
                            :value="position"
                            :label="$t('studio.alignments.' + position)"
                        />
                    </ui-dropdown>
                </div>
            </ui-dropdown>
        </template>
    </ui-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { Align, Dimension, Logo } from 'cte-video-studio';
import { LOGO_VERTICAL_POSITIONS, LOGO_HORIZONTAL_POSITIONS, LIBRARY_STATUS_ACTIVE } from '@/js/constants/index.js';
import { getPrefixedUuid } from '@/js/utils.js';
import { BrandCard } from '../../../../mixins';

export default {
    mixins: [BrandCard],

    components: {
        //
    },

    props: {
        id: {
            type: String,
            default: 'settings-identity'
        }
    },

    data() {
        return {
            logoDefaultAnimationType: Logo.DEFAULT,
            logoHorizontalPositions: LOGO_HORIZONTAL_POSITIONS,
            logoVerticalPositions: LOGO_VERTICAL_POSITIONS
        };
    },

    computed: {
        ...mapState({
            logoAnimationLibrary: (state) => state.branding.libraries.logoAnimations
        }),

        ...mapGetters({
            getLibraryTags: 'branding/libraries/libraryTags',
            logoSize: 'branding/logoSize'
        }),

        logoAssetImage: {
            get() {
                return { src: this.state.logo.src, id: this.state.logo.src__id };
            },
            set({ src, id }) {
                this.modifyBrand(() => {
                    if (!this.state.logo.src__ref) {
                        this.$store.commit(
                            'branding/setLogoAssetReference',
                            getPrefixedUuid(this.prefixes.mediaReference)
                        );
                    }
                    this.updateLogoAsset({ src, src__id: id });
                });
            }
        },

        logoImage: {
            get() {
                return { src: this.state.logo.animation.src, id: this.state.logo.animation.src__id };
            },
            set({ src, id }) {
                this.modifyBrand(() => {
                    if (!this.state.logo.animation.src__ref) {
                        this.$store.commit('branding/setLogoReference', getPrefixedUuid(this.prefixes.mediaReference));
                    }
                    this.updateLogo({ src, src__id: id });
                });
            }
        },

        logoOverrideEnabled: {
            get() {
                return this.state.logo.overrideEnabled;
            },
            set(enable) {
                this.modifyBrand(() => {
                    this.$store.commit('branding/enableLogoOverride', enable);
                });
            }
        },

        sortedLogoAnimationLibrary() {
            let tagOrder = this.libraryTagOrders['logoAnimations'],
                sortedTags = this.getLibraryTags('logoAnimations').sort(
                    (tagA, tagB) => tagOrder.indexOf(tagA) - tagOrder.indexOf(tagB)
                );

            return this.logoAnimationLibrary
                .map((animation) => {
                    return {
                        ...animation,
                        label:
                            this.$t('studio.tags.' + animation.tags[0]) +
                            ' ' +
                            (animation.tagIndexes[animation.tags[0]] + 1)
                    };
                })
                .sort((animationA, animationB) => {
                    let order = sortedTags.indexOf(animationA.tags[0]) - sortedTags.indexOf(animationB.tags[0]);

                    if (!!order) return order;

                    return animationA.tagIndexes[animationA.tags[0]] - animationB.tagIndexes[animationB.tags[0]];
                });
        },

        activeLogoAnimationLibrary() {
            return this.sortedLogoAnimationLibrary.filter((animation) => animation.status == LIBRARY_STATUS_ACTIVE);
        },

        logoAnimationType: {
            get() {
                return this.state.logo.animation.type;
            },
            set(type) {
                this.modifyBrand(() => {
                    this.$store.commit('branding/setLogoAnimation', type);
                });
            }
        },

        logoWidth: {
            get() {
                return this.logoSize.width != Dimension.AUTO ? parseFloat(this.logoSize.width) : '';
            },
            set(value) {
                if (value != this.logoWidth) {
                    this.modifyBrand(() => {
                        this.$store.commit('branding/setLogoWidth', {
                            value: Dimension.CUSTOM,
                            custom: value !== '' ? value + Dimension.PERCENT_UNIT : value
                        });
                    });
                }
            }
        },

        logoHeight: {
            get() {
                return this.logoSize.height != Dimension.AUTO ? parseFloat(this.logoSize.height) : '';
            },
            set(value) {
                if (value != this.logoHeight) {
                    this.modifyBrand(() => {
                        this.$store.commit('branding/setLogoHeight', {
                            value: Dimension.CUSTOM,
                            custom: value !== '' ? value + Dimension.PERCENT_UNIT : value
                        });
                    });
                }
            }
        },

        logoHorizontalPosition: {
            get() {
                return this.state.logo.position.alignH != Align.CUSTOM
                    ? this.state.logo.position.alignH
                    : parseFloat(this.state.logo.position.alignX);
            },
            set(value) {
                let parsedValue = value.replace(',', '.'),
                    position = {
                        alignH: !isNaN(parsedValue) ? Align.CUSTOM : value,
                        alignX: !isNaN(parsedValue) ? parsedValue : null
                    };
                if (
                    position.alignH != this.state.logo.position.alignH ||
                    position.alignX != this.state.logo.position.alignX
                ) {
                    this.modifyBrand(() => {
                        this.$store.commit(
                            'branding/setLogoPosition',
                            Object.assign({}, this.state.logo.position, position)
                        );
                    });
                }
            }
        },

        logoVerticalPosition: {
            get() {
                return this.state.logo.position.alignV != Align.CUSTOM
                    ? this.state.logo.position.alignV
                    : parseFloat(this.state.logo.position.alignY);
            },
            set(value) {
                let parsedValue = value.replace(',', '.'),
                    position = {
                        alignV: !isNaN(parsedValue) ? Align.CUSTOM : value,
                        alignY: !isNaN(parsedValue) ? parsedValue : Dimension.ZERO
                    };
                if (
                    position.alignV != this.state.logo.position.alignV ||
                    position.alignY != this.state.logo.position.alignY
                ) {
                    this.modifyBrand(() => {
                        this.$store.commit(
                            'branding/setLogoPosition',
                            Object.assign({}, this.state.logo.position, position)
                        );
                    });
                }
            }
        },

        metaBrand: {
            get() {
                return this.state.meta.brand;
            },
            set(brand) {
                this.modifyBrand(() => {
                    this.$store.commit('branding/setMeta', { brand });
                });
            }
        },

        metaWebsite: {
            get() {
                return this.state.meta.website;
            },
            set(brand) {
                this.modifyBrand(() => {
                    this.$store.commit('branding/setMeta', { website });
                });
            }
        }
    },

    watch: {
        //
    },

    methods: {
        ...mapActions({
            updateLogoAsset: 'branding/updateLogoAsset',
            updateLogo: 'branding/updateLogo'
        }),

        getLogoAnimationStyles(animation) {
            return {
                '--animation-thumbnail': `url(/assets/thumbnails/logos/${animation}.gif)`
            };
        }
    },

    mounted() {
        //
    }
};
</script>
