<template>
  <div class="timeline-slider-outer">
    <div class="timeline-slider-scroll" ref="sliderOuterContainer">
      <div
        class="timeline-slider-content"
        ref="sliderWrapper"
        @mousedown="handleMouseDown"
        @mousemove="handleMouseMove"
        @mouseleave="handleMouseLeave"
      >
        <div class="slider-container" ref="sliderContainer" :style="{ width: `${canvasWidth}px` }">
          <GapRenderer 
            :initial-gap-style="getInitialGapStyle"
            :final-gap-style="getFinalGapStyle"
            :segments="segments"
            :get-gap-style="getGapStyle"
          />
          <slot name="segments"></slot>
          <HoverBar
            v-show="showHoverBar"
            :position="hoverBarPosition"
            :hover-time="hoverTime"
            :x="hoverDisplayX"
            :y="hoverDisplayY"
            :rate="rate"
            :is-dragging="isDragging"
          />
          <CurrentTimeBar
            :position="currentTimePos"
            :canvas-width="canvasWidth"
            :is-dragging="currentTimeIsDragging"
            :is-scrolling="isScrolling"
            @start-drag="startCurrentTimeDrag"
          />
          <slot name="canvas"></slot>
          <slot name="audio-waveform"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, onBeforeUnmount, watch, computed } from 'vue';
import CurrentTimeBar from './CurrentTimeBar.vue';
import GapRenderer from './GapRenderer.vue';
import HoverBar from './HoverBar.vue';

import { useClickThreshold } from '../composables/useClickThreshold';
import { useDisplay } from '@video-composables/useDisplay';
import { useScrolling } from '../composables/useScrolling';
import { useHoverBar } from '../composables/useHoverBar';

export default defineComponent({
  name: 'TimelineSlider',
  components: {
    CurrentTimeBar,
    GapRenderer,
    HoverBar
  },
  props: {
    canvasWidth: {
      type: Number,
      required: true
    },
    segments: {
      type: Array,
      required: true
    },
    currentTimePos: {
      type: Number,
      required: true
    },
    getInitialGapStyle: {
      type: Object,
      required: true
    },
    getGapStyle: {
      type: Function,
      required: true
    },
    getFinalGapStyle: {
      type: Object,
      required: true
    },
    currentTimeIsDragging: {
      type: Boolean,
      required: true
    },
    isDragging: {
      type: Boolean,
      required: true
    },
    segmentIsDragging: {
      type: Boolean,
      required: true
    },
    endTime: {
      type: Number,
      required: true
    },
    rate: {
      type: Number,
      default: 30
    },
  },
  emits: ['startSliderDrag', 'startCurrentTimeDrag', 'elementsMount', 'startSegmentDrag'],
  setup(props, { emit }) {
    const sliderWrapper = ref(null);
    const sliderContainer = ref(null);
    const sliderOuterContainer = ref(null);
    const elementsAreDragging = ref(false);
    const { playing } = useDisplay();
    const { startTracking, checkThreshold, resetTracking } = useClickThreshold();

    const canvasWidthRef = computed(() => props.canvasWidth);
    const endTimeRef = computed(() => props.endTime);

    const { 
      startContinuousScroll, 
      stopContinuousScroll, 
      scrollToCurrentTime, 
      isScrolling 
    } = useScrolling(sliderOuterContainer, computed(() => props.currentTimePos), elementsAreDragging);

    const {
      showHoverBar,
      hoverBarPosition,
      hoverTime,
      hoverDisplayX,
      hoverDisplayY,
      handleMouseMove,
      handleMouseLeave
    } = useHoverBar(sliderWrapper, canvasWidthRef, endTimeRef);

    const handleMouseDown = (event) => {
      startTracking(event);
      const segmentIndex = getSegmentIndexAtPosition(event.clientX);

      const handleMouseMove = (moveEvent) => {
        if (checkThreshold(moveEvent)) {
          if (segmentIndex !== -1) {
            if (!props.currentTimeIsDragging) {
              emit('startSegmentDrag', moveEvent, segmentIndex);
            }
          } else {            
            emit('startSliderDrag', moveEvent, sliderWrapper.value);
          }
          cleanupMouseListeners();
        }
      };

      const handleMouseUp = () => {
        cleanupMouseListeners();
        resetTracking();
      };

      const cleanupMouseListeners = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    };

    watch(() => playing.value, (newValue) => {
      newValue ? startContinuousScroll() : stopContinuousScroll();
    });

    watch(() => props.currentTimePos, () => {
      if (!playing.value) {
        scrollToCurrentTime();
      }
    });

    watch(() => props.isDragging, (newValue) => {
      elementsAreDragging.value = newValue;
    });

    const getSegmentIndexAtPosition = (clientX) => {
      if (!sliderWrapper.value) return -1;

      const { left, width } = sliderWrapper.value.getBoundingClientRect();
      const position = (clientX - left) / width;
      const time = position * props.endTime;

      return props.segments.findIndex((segment) => time >= segment.start && time <= segment.end);
    };

    const startCurrentTimeDrag = (event) => {
      emit('startCurrentTimeDrag', event);
      elementsAreDragging.value = true;
    };

    onMounted(() => {
      emit('elementsMount', {
        sliderWrapper: sliderWrapper.value,
        sliderContainer: sliderContainer.value
      });
    });

    onBeforeUnmount(() => {
      stopContinuousScroll();
    });

    return {
      sliderWrapper,
      sliderContainer,
      sliderOuterContainer,
      startCurrentTimeDrag,
      showHoverBar,
      hoverBarPosition,
      handleMouseMove,
      handleMouseLeave,
      handleMouseDown,
      hoverTime,
      hoverDisplayX,
      hoverDisplayY,
      isScrolling
    };
  }
});
</script>


<style scoped>
.hover-bar-container {
    position: absolute;
    top: 0;
    height: 100%;
    pointer-events: none;
    z-index: 15;
}

.hover-timecode {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
}

.hover-bar {
    width: 1px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
}
.timeline-slider-outer {
    width: 100%;
    overflow: hidden;
}

.timeline-slider-scroll {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    /* scroll-behavior: smooth; */
}

.timeline-slider-content {
    display: inline-block;
    min-width: 100%;
    cursor: grab;
    padding-top: 36px;
    padding-left: 8px;
    /* padding-left: 24px;
    padding-right: 24px; */
    width: max-content;
}

.timeline-slider-content:active {
    cursor: grabbing;
}

.slider-container {
    position: relative;
    height: 62px;
    white-space: nowrap;
    border-top: 2px solid rgba(216, 0, 85, 0.5);
    border-bottom: 2px solid rgba(216, 0, 85, 0.5);
}

.timeline-slider-scroll::-webkit-scrollbar {
    height: 8px;
    margin-top: 8px;
}

.timeline-slider-scroll::-webkit-scrollbar-track {
    background: #2c343a;
}

.timeline-slider-scroll::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.timeline-slider-scroll::-webkit-scrollbar-thumb:hover {
    background: white;
}

.vertical-bar {
    margin-top: 16px;
    margin-left: 6px;
    position: absolute;
    border-left: 2px solid white;
    height: 39 px;
}
</style>
