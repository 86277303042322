<template>
    <ui-modal
        v-bind="{ ...$props, ...$attrs }"
        ref="$modal"
        class="create-without-saving-modal"
        :title="$t('Do you want to leave this page?')"
        action-class="ui-simple-button-cancel"
    >
        <p>{{ $t('Your changes will not be saved, and you will be redirected to the new template.') }}</p>
    </ui-modal>
</template>

<script>
import { Modal } from '../../../../mixins';

export default {
    mixins: [Modal],

    components: {
        //
    },

    props: {
        //
    },

    data() {
        return {
            //
        };
    },

    computed: {
        //
    },

    watch: {
        //
    },

    methods: {
        //
    },

    mounted() {
        //
    }
};
</script>
