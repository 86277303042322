import { computed } from 'vue';
import { useStore } from 'vuex';

export function useBrand() {
    const store = useStore();

    const getBrandLibraryItems = (library, category) => {
        return store.getters['brand/getBrandLibraryItems'](library, category);
    };

    const isBrandLibraryEmpty = () => {
        return (library, category) => !getBrandLibraryItems(library, category).length;
    };

    return {
        getBrandLibraryItems,
        isBrandLibraryEmpty
    };
}
