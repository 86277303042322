<template>
    <div class="ui-media-selector" :class="containerClasses" @[stopAudioPreviewEvent]="stopAudioPreview">
        <button :id="selectorId" class="selector" :class="selectorClasses" :disabled="disabled" @click="showLibrary">
            <checkboard v-if="!isEmpty && !isAudio" />
            <div class="selector-preview" :style="mediaPreviewStyles">
                <svg-icon v-if="!isEmpty && isAudio" icon="audio-icon" />
            </div>
            <span class="visually-hidden">{{ label || $t('Select a media') }}</span>
        </button>
        <div v-if="isEmpty" class="selector-empty">
            <svg class="border">
                <rect x="0" y="0" width="100%" height="100%" rx="10" fill="none" />
            </svg>
            <fa-icon class="icon" :icon="icon" />
        </div>
        <div v-else class="selector-actions">
            <button
                v-if="isAudio"
                class="action-preview"
                :class="previewActionClasses"
                :title="$t('Preview')"
                @click="toggleAudioPreview"
            >
                <span class="visually-hidden">{{ $t('Preview') }}</span>
                <svg-icon icon="play-icon" class="play" />
                <svg-icon icon="pause-icon" class="pause" />
            </button>
            <button class="action-change" :title="$t('Change media')" @click="showLibrary">
                <span class="visually-hidden">{{ $t('Change media') }}</span>
                <fa-icon class="icon" icon="fa-solid fa-rotate" />
            </button>
            <button class="action-remove" :title="$t('Remove media')" @click="handleRemove">
                <span class="visually-hidden">{{ $t('Remove media') }}</span>
                <svg-icon icon="close-icon" />
            </button>
        </div>
        <label :class="labelClasses" :for="selectorId" :aria-hidden="!showLabel || null">{{
            label || $t('Select a media')
        }}</label>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Checkboard } from '@lk77/vue3-color';
import UiIcon from './UiIcon.vue';
import loader from '@/js/video-studio/loader.js';

export const UI_MEDIA_TYPE_IMAGE = 'image';
export const UI_MEDIA_TYPE_ICON = 'icon';
export const UI_MEDIA_TYPE_GIF = 'gif';
export const UI_MEDIA_TYPE_VIDEO = 'video';
export const UI_MEDIA_TYPE_CARTOON = 'cartoon';
export const UI_MEDIA_TYPE_ANIMATED = 'animated';
export const UI_MEDIA_TYPE_AUDIO = 'audio';
export const UI_MEDIA_TYPE_MUSIC = 'music';

export const UI_MEDIA_IMAGE_TYPES = [UI_MEDIA_TYPE_IMAGE, UI_MEDIA_TYPE_ICON, UI_MEDIA_TYPE_GIF, UI_MEDIA_TYPE_CARTOON];
export const UI_MEDIA_VIDEO_TYPES = [UI_MEDIA_TYPE_VIDEO, UI_MEDIA_TYPE_ANIMATED];
export const UI_MEDIA_AUDIO_TYPES = [UI_MEDIA_TYPE_AUDIO, UI_MEDIA_TYPE_MUSIC];

export const UI_MEDIA_SELECTOR_SHOW = 'ui-media-selector-show';
export const UI_MEDIA_SELECTOR_HIDE = 'ui-media-selector-hide';
export const UI_MEDIA_SELECTOR_CHANGE = 'update:media';

export default {
    emits: [UI_MEDIA_SELECTOR_SHOW, UI_MEDIA_SELECTOR_CHANGE],

    inject: ['$videoStudio'],

    components: {
        Checkboard,
        UiIcon
    },

    props: {
        id: {
            type: String
        },
        type: {
            type: String,
            default: UI_MEDIA_TYPE_IMAGE
        },
        media: {
            type: Object,
            default: () => ({
                src: '',
                id: ''
            })
        },
        // library: {
        //     type: Object,
        //     default: () => ({}),
        // },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        icon: {
            type: String,
            default: 'selector-add-icon'
        },
        triggerOpening: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            currentValue: this.media.id || '',
            audioPreviewIsPlaying: false,
            audioPreview: null
        };
    },

    computed: {
        ...mapState({
            selectedMediaId: (state) => state.ui.currentMediaLibrary.selectedMediaId
        }),

        ...mapGetters({
            mediaThumbnailUrl: 'ui/mediaThumbnailUrl',
            mediaPreviewUrl: 'ui/mediaPreviewUrl'
        }),

        isEmpty() {
            return this.currentValue === '';
        },

        isImage() {
            return UI_MEDIA_IMAGE_TYPES.includes(this.type);
        },
        isVideo() {
            return UI_MEDIA_VIDEO_TYPES.includes(this.type);
        },
        isAudio() {
            return UI_MEDIA_AUDIO_TYPES.includes(this.type);
        },

        containerClasses() {
            return {
                audio: this.isAudio,
                disabled: this.disabled
            };
        },

        selectorId() {
            return this.id + '-selector';
        },

        selectorClasses() {
            return {
                empty: this.isEmpty
            };
        },

        mediaPreviewSrc() {
            return !!this.currentValue ? this.mediaPreviewUrl(this.currentValue) : '';
        },

        thumbnailSrc() {
            return !!this.currentValue ? this.mediaThumbnailUrl(this.currentValue) : '';
        },

        blobThumbnailSrc() {
            return !!this.thumbnailSrc ? this.$store.getters['loading/getBlob'](this.thumbnailSrc) || '' : '';
        },

        backgroundImage() {
            return this.$videoStudio ? this.blobThumbnailSrc : this.thumbnailSrc;
        },

        mediaPreviewStyles() {
            if (this.isEmpty) return {};

            if (!this.isAudio) {
                return {
                    backgroundImage: "url('" + this.backgroundImage + "')"
                };
            }
        },

        previewActionClasses() {
            return {
                playing: !this.isEmpty && this.isAudio && this.audioPreviewIsPlaying
            };
        },

        stopAudioPreviewEvent() {
            return !this.isEmpty && this.isAudio ? 'mouseleave' : null;
        },

        labelClasses() {
            return {
                'visually-hidden': !this.showLabel
            };
        }
    },

    watch: {
        media(newValue) {
            this.currentValue = (!!newValue && newValue.id) || '';
        },

        mediaPreviewSrc(newValue) {
            this.destroyAudioPreview();
            this.initAudioPreview();
        },

        thumbnailSrc(newValue, oldValue) {
            this.updateThumbnail(newValue, oldValue);
        }
    },

    methods: {
        showLibrary() {
            this.$emit(UI_MEDIA_SELECTOR_SHOW, this);
        },

        hideLibrary() {
            this.$el.dispatchEvent(new Event(UI_MEDIA_SELECTOR_HIDE));
        },

        select() {
            this.currentValue = this.selectedMediaId;
            this.emitChangeEvent();
            this.hideLibrary();
        },

        remove() {
            this.currentValue = '';
            this.emitChangeEvent();
        },

        cancel() {
            this.hideLibrary();
        },

        initAudioPreview() {
            if (this.isAudio && !!this.mediaPreviewSrc) {
                this.audioPreview = new Audio(this.mediaPreviewSrc);
                this.audioPreview.addEventListener('ended', this.stopAudioPreview);
            }
        },

        destroyAudioPreview() {
            if (!!this.audioPreview) {
                this.audioPreview.removeEventListener('ended', this.stopAudioPreview);
                this.audioPreview.pause();
                this.audioPreview = null;
            }
        },

        toggleAudioPreview() {
            if (!this.isEmpty && this.isAudio) {
                this.audioPreviewIsPlaying = !this.audioPreviewIsPlaying;
                if (!!this.audioPreview) {
                    if (this.audioPreviewIsPlaying) this.audioPreview.play();
                    else this.audioPreview.pause();
                }
            }
        },

        stopAudioPreview(event) {
            this.audioPreviewIsPlaying = false;
            if (!!this.audioPreview) {
                this.audioPreview.pause();
                this.audioPreview.currentTime = 0;
            }
        },

        handleRemove(event) {
            this.remove();
            this.cancel();
        },

        emitChangeEvent() {
            this.$emit(UI_MEDIA_SELECTOR_CHANGE, {
                id: this.currentValue,
                src: !!this.currentValue ? this.mediaPreviewUrl(this.currentValue) : ''
            });
        },

        updateThumbnail(newValue, oldValue) {
            if (this.$videoStudio && !this.isAudio) {
                if (newValue) loader.load(newValue, () => {});
                if (oldValue) loader.unload(oldValue, () => {});
            }
        }
    },

    mounted() {
        this.initAudioPreview();
        this.updateThumbnail(this.thumbnailSrc);
        if (this.triggerOpening) this.showLibrary();
    },

    beforeUnmount() {
        this.destroyAudioPreview();
    }
};
</script>
