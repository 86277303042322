<template>
    <div class="ui-dropdown" v-tooltip="tooltip">
        <select v-if="select" :id="selectId" ref="$select" :multiple="multiple" :disabled="disabled" aria-hidden="true">
            <template v-if="!!selectOptions">
                <option
                    v-if="select && !multiple && editable"
                    :key="id + '-editable-option'"
                    :value="editableValue"
                    :selected="selectedValue == editableValue"
                >
                    {{ editableValue }}
                </option>
                <option
                    v-for="(option, index) in selectOptions"
                    v-bind="option.attributes"
                    :key="id + '-option-' + index"
                    :value="option.value"
                    :disabled="option.disabled"
                    :selected="(multiple && selectedValue.includes(option.value)) || selectedValue == option.value"
                >
                    {{ option.label || option.value }}
                </option>
            </template>
        </select>
        <button
            :id="toggleId"
            class="ui-dropdown-toggle"
            :class="toggleClasses"
            :style="toggleStyle"
            ref="$toggleElement"
            data-bs-toggle="dropdown"
            :role="select ? 'combobox' : null"
            aria-haspopup="true"
            :aria-controls="dropdownMenuId"
            :aria-expanded="show || null"
            :disabled="disabled"
        >
            <span class="ui-dropdown-toggle-inner">
                <slot name="dropdown-toggle" :instance="instanceForSlots">
                    {{ toggleLabel }}
                </slot>
            </span>
            <span v-if="caret" class="ui-dropdown-toggle-caret">
                <fa-icon class="icon" icon="fa-solid fa-chevron-down" />
            </span>
        </button>
        <slot name="dropdown-toggle-after" :instance="instanceForSlots"></slot>
        <div
            :id="dropdownMenuId"
            ref="$dropdownMenu"
            class="ui-dropdown-menu dropdown-menu disabled-hide"
            :class="dropdownMenuClasses"
            :aria-labelledby="toggleId"
            role="none"
        >
            <div v-if="hasDropdownMenuTop" class="ui-dropdown-menu-top disabled-hide">
                <slot name="dropdown-menu-top" :instance="instanceForSlots"></slot>
            </div>
            <ui-wrapper
                :is="scrollable ? UiScrollable : null"
                ref="$dropdownMenuScrollable"
                role="none"
                @scroll="handleScrollEvent"
            >
                <ul
                    v-if="(menu && !select) || select || !!items.length"
                    class="ui-dropdown-menu-inner disabled-hide"
                    :role="select ? 'listbox' : 'menu'"
                >
                    <li v-if="select && !multiple && editable" class="ui-dropdown-editable-item" role="option">
                        <input
                            type="text"
                            v-model="editableValue"
                            :placeholder="editablePlaceholder || $t('Custom value')"
                            @keyup.enter="handleEditableItemChange"
                        />
                    </li>
                    <template v-if="!!items.length">
                        <ui-dropdown-item
                            v-for="(item, index) in items"
                            :key="id + '-item-' + item.value"
                            :classes="item.classes"
                            :href="item.href || '#'"
                            :value="item.value || ''"
                            :label="item.label || item.value"
                            :tooltip="!!item.tooltip"
                            :label-tag="item.tag || false"
                            :metadata="item.metadata || {}"
                            :disabled="item.disabled || false"
                            v-on="item.events || {}"
                        />
                    </template>
                    <template v-else>
                        <slot></slot>
                        <div ref="$loadingMarker"></div>
                    </template>
                </ul>
                <div v-else class="ui-dropdown-menu-inner" role="none">
                    <slot></slot>
                </div>
            </ui-wrapper>
            <div v-if="hasDropdownMenuBottom" class="ui-dropdown-menu-bottom disabled-hide">
                <slot name="dropdown-menu-bottom" :instance="instanceForSlots"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import Dropdown from 'bootstrap/js/dist/dropdown';
import maxSize from 'popper-max-size-modifier';
import UiDropdownItem from './UiDropdownItem.vue';
import UiScrollable from './UiScrollable.vue';
import UiWrapper from './UiWrapper.vue';
import UiIcon from './UiIcon.vue';
import { shallowRef } from 'vue';
import { UsesTooltip } from '../mixins';

export const UI_DROPDOWN_MENU_SHOW = 'ui-dropdown-menu-show';
export const UI_DROPDOWN_MENU_HIDE = 'ui-dropdown-menu-hide';
export const UI_DROPDOWN_SELECT_CHANGE = 'update:modelValue';
export const UI_DROPDOWN_SELECT_ADD = 'ui-dropdown-select-add';
export const UI_DROPDOWN_SELECT_REMOVE = 'ui-dropdown-select-remove';
const LOADING_OFFSET_PIXELS = 500;

export default {
    mixins: [UsesTooltip],

    emits: [
        UI_DROPDOWN_MENU_SHOW,
        UI_DROPDOWN_MENU_HIDE,
        UI_DROPDOWN_SELECT_CHANGE,
        UI_DROPDOWN_SELECT_ADD,
        UI_DROPDOWN_SELECT_REMOVE
    ],

    components: {
        UiIcon,
        UiDropdownItem,
        UiWrapper
    },

    provide() {
        return {
            dropdown: this
        };
    },

    props: {
        id: {
            type: String
        },
        caret: {
            type: Boolean,
            default: true
        },
        icon: {
            type: Boolean,
            default: false
        },
        iconOnly: {
            type: Boolean,
            default: false
        },
        select: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: false
        },
        editablePlaceholder: {
            type: String,
            default: ''
        },
        editableRegExp: {
            type: RegExp,
            default: () => /\S/
        },
        modelValue: '',
        defaultLabel: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        menu: {
            type: Boolean,
            default: true
        },
        menuPlacement: {
            type: String,
            default: 'bottom-start'
        },
        menuStrategy: {
            type: String,
            default: 'absolute'
        },
        menuOffset: {
            type: Array,
            default: () => [0, 2]
        },
        menuMaxSizeEnabled: {
            type: Boolean,
            default: false
        },
        scrollable: {
            type: Boolean,
            default: true
        },
        items: {
            type: Array,
            default: () => []
        },
        toggleClass: {
            type: [String, Array, Object],
            default: ''
        },
        toggleStyle: {
            type: Object,
            default: () => ({})
        },
        showMenuTop: {
            type: Boolean,
            default: true
        },
        showMenuBottom: {
            type: Boolean,
            default: true
        },
        tooltip: {
            type: String,
            default: ''
        },
        infiniteLoading: {
            type: Boolean,
            default: false
        },
        loadingOffsetPixels: {
            type: Number,
            default: LOADING_OFFSET_PIXELS
        },
        loadingItemsFunction: {
            type: Function,
            default: () => {}
        },
        startingPage: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {
            show: false,
            editableValue: '',
            UiScrollable: shallowRef(UiScrollable),
            page: 1,
            loading: false,
            hasMoreData: true
        };
    },

    computed: {
        selectId() {
            return this.id + '-dropdown-select';
        },

        selectOptions() {
            if (!this.select || (this.items.length === 0 && !this.$slots.default)) {
                return false;
            }

            // If items are available, return them directly.
            if (this.items.length > 0) {
                return this.items;
            }

            // Process slot default children.
            return this.$slots.default().flatMap((vnode) => this.processVNode(vnode));
        },

        selectedValue() {
            return !this.multiple ? this.modelValue : Array.isArray(this.modelValue) ? this.modelValue : [];
        },

        selectedLabel() {
            if (!this.selectOptions) return this.defaultLabelTrad;

            if (!this.multiple || this.selectedValue.length <= 1) {
                let selectedValue = !this.multiple ? this.selectedValue : this.selectedValue[0],
                    option = this.selectOptions.find((option) => option.value == selectedValue);
                return (option === undefined && this.defaultLabelTrad) || option.label || option.value || '';
            }

            return '';
        },

        hasValidEditableValue() {
            return this.select && !this.multiple && this.editable && this.editableRegExp.test(this.editableValue);
        },

        toggleLabel() {
            if (this.select && this.multiple && this.selectedValue.length !== 1) {
                return this.selectedValue.length > 1 ? this.$t('Several') : this.$t('None selected');
            }

            return (
                (this.select && this.editable && this.editableValue) ||
                this.selectedLabel ||
                (!this.multiple && this.selectedValue)
            );
        },

        toggleId() {
            return this.id + '-dropdown-toggle';
        },

        toggleClasses() {
            let classes = [],
                propClasses = typeof this.toggleClass == 'string' ? this.toggleClass.split(/\s+/) : this.toggleClass;

            if (this.iconOnly) classes.push('ui-dropdown-toggle-icon-only');

            return [
                propClasses,
                {
                    'ui-dropdown-toggle-icon': this.icon
                },
                classes
            ];
        },

        dropdownMenuId() {
            return this.id + '-dropdown-menu';
        },

        dropdownMenuClasses() {
            return {
                'ui-dropdown-content': !this.menu && !this.select,
                show: this.show
            };
        },

        hasDropdownMenuTop() {
            return !!this.$slots['dropdown-menu-top'] && this.showMenuTop;
        },

        hasDropdownMenuBottom() {
            return !!this.$slots['dropdown-menu-bottom'] && this.showMenuBottom;
        },

        instanceForSlots() {
            return this;
        },

        defaultLabelTrad() {
            return this.defaultLabel || this.$t('Select...');
        }
    },

    watch: {
        // it must be declared like that because modelValue can be an array (CardSettingsTemplateLanguages)
        modelValue: {
            handler(newValue) {
                if (this.editable) {
                    this.editableValue =
                        this.selectedLabel == this.defaultLabelTrad && !!newValue && this.editableRegExp.test(newValue)
                            ? newValue
                            : '';
                }
            },
            deep: true
        }
    },

    methods: {
        processVNode(vnode) {
            // Handling ui-dropdown-item type vnodes.
            if (vnode.type?.name === 'ui-dropdown-item') {
                return this.extractDropdownItemProps(vnode);
            }

            // Handling vnodes without children or with a single untyped child.
            if (vnode.children.length === 0 || (vnode.children.length === 1 && !vnode.children[0]?.type?.name)) {
                return this.createSeparator();
            }

            return vnode.children
                .filter((child) => child.type?.name === 'ui-dropdown-item')
                .map(this.extractDropdownItemProps.bind(this));
        },

        // Method to extract properties for a ui-dropdown-item.
        extractDropdownItemProps(vnode) {
            return {
                label: vnode.props.label,
                value: vnode.props.value,
                disabled: vnode.props.disabled,
                metadata: vnode.props.metadata,
                attributes: {}
            };
        },

        // Method to create a separator object.
        createSeparator() {
            return {
                label: '',
                value: '',
                disabled: true,
                metadata: {},
                attributes: { role: 'separator' }
            };
        },
        handleShowDropdown() {
            if (this.scrollable) {
                this.$refs.$dropdownMenuScrollable.$refs.$wrapperReference.refresh();
                this.$refs.$dropdownMenuScrollable.$refs.$wrapperReference.scrollTo('.ui-dropdown-item.selected');
            }
            if (this.select) {
                this.$nextTick(() => {
                    let selected = this.$refs.$dropdownMenu.querySelector('.ui-dropdown-item.selected');
                    if (selected) selected.focus();
                });
            }
            this.emitShowEvent();
        },

        emitShowEvent() {
            this.show = true;
            this.$emit(UI_DROPDOWN_MENU_SHOW, this);
        },

        emitHideEvent(event) {
            if (
                (this.menu || this.select) &&
                event.clickEvent &&
                event.clickEvent.target.closest('.ui-dropdown') == this.$el &&
                (this.multiple ||
                    !!event.clickEvent.target.querySelector('.disabled,[disabled]') ||
                    event.clickEvent.target.classList.contains('ui-scrollable-handle') ||
                    event.clickEvent.target.classList.contains('disabled-hide')) &&
                event.cancelable
            ) {
                event.preventDefault();
            } else {
                if (!this.multiple) {
                    if (this.hasValidEditableValue) {
                        if (this.selectedValue != this.editableValue)
                            this.$emit(UI_DROPDOWN_SELECT_CHANGE, this.editableValue);
                    } else {
                        this.editableValue = '';
                        if (this.selectedLabel == this.defaultLabelTrad) this.$emit(UI_DROPDOWN_SELECT_CHANGE, '');
                    }
                }
                this.$emit(UI_DROPDOWN_MENU_HIDE, this);
                this.show = false;
            }
        },

        handleItemClick(event, item) {
            if (this.select) {
                if (this.multiple) {
                    if (this.selectedValue.includes(item.value)) {
                        this.$emit(UI_DROPDOWN_SELECT_REMOVE, item.value);
                        this.$emit(
                            UI_DROPDOWN_SELECT_CHANGE,
                            this.selectedValue.filter((value) => value !== item.value)
                        );
                    } else {
                        this.$emit(UI_DROPDOWN_SELECT_ADD, item.value);
                        this.$emit(UI_DROPDOWN_SELECT_CHANGE, [...this.selectedValue, item.value]);
                    }
                } else {
                    if (this.selectedValue != item.value) {
                        this.$emit(UI_DROPDOWN_SELECT_CHANGE, item.value, this.selectedLabel, item.metadata);
                    }
                    if (this.editable) this.editableValue = '';
                }
            }
        },

        handleEditableItemChange(event) {
            if (this.hasValidEditableValue) {
                // Note: hide() function will take care of emitting the UI_DROPDOWN_SELECT_CHANGE event
                this.hide();
            }
        },

        hide() {
            this._dropdown.hide();
        },

        toggle(show = null) {
            if (show === true) {
                this._dropdown.show();
            } else if (show === false) {
                this._dropdown.hide();
            } else {
                this._dropdown.toggle();
            }
        },

        handleScrollEvent() {
            if (this.infiniteLoading && this.show) {
                this.checkMarkerPosition();
            }
        },

        checkMarkerPosition() {
            const marker = this.$refs.$loadingMarker;
            const container = this.$refs.$dropdownMenu;
            if (
                marker.getBoundingClientRect().top - container.getBoundingClientRect().bottom <
                this.loadingOffsetPixels
            ) {
                this.loadMoreItems();
            }
        },

        loadMoreItems() {
            if (this.loading || !this.hasMoreData) return;
            this.loading = true;

            this.loadingItemsFunction(this.startingPage + this.page)
                .then((paginatedResult) => {
                    if (paginatedResult.data.length > 0) {
                        this.$emit('addItems', paginatedResult.data);
                        this.page++;
                    } else {
                        this.hasMoreData = false;
                    }
                })
                .catch(() => {
                    this.hasMoreData = false;
                })
                .finally(() => {
                    this.loading = false;

                    if (this.hasMoreData) {
                        this.checkMarkerPosition();
                    }
                });
        }
    },

    mounted() {
        this.editableValue =
            this.editable &&
            !this.multiple &&
            this.selectedLabel == this.defaultLabelTrad &&
            !!this.modelValue &&
            this.editableRegExp.test(this.modelValue)
                ? this.modelValue
                : '';

        this._dropdown = new Dropdown(this.$refs.$toggleElement, {
            autoClose: this.menu || this.select || 'outside',
            offset: this.menuOffset,
            popperConfig: (config) => {
                config.placement = this.menuPlacement;
                config.strategy = this.menuStrategy;
                if (this.menuMaxSizeEnabled) {
                    config.modifiers = [
                        ...config.modifiers,
                        maxSize,
                        {
                            name: 'applyMaxSize',
                            enabled: true,
                            phase: 'beforeWrite',
                            requires: ['maxSize'],
                            fn: ({ state }) => {
                                let { height } = state.modifiersData.maxSize;
                                state.styles.popper.maxHeight = height + 'px';
                            }
                        }
                    ];
                }
                return config;
            }
        });
        this.$refs.$toggleElement.addEventListener('show.bs.dropdown', this.handleShowDropdown);
        this.$refs.$toggleElement.addEventListener('hide.bs.dropdown', this.emitHideEvent);
    },

    beforeUnmount() {
        this.$refs.$toggleElement.removeEventListener('show.bs.dropdown', this.handleShowDropdown);
        this.$refs.$toggleElement.removeEventListener('hide.bs.dropdown', this.emitHideEvent);
        this._dropdown.dispose();
    }
};
</script>

<style scoped>
.ui-dropdown-toggle-icon-only:not(.ui-action-button) {
    min-width: 0 !important;
    padding: bootstrap.rfs-value($ui-button-icon-only-padding);
}
</style>
