export default class StudioCompanionPreviewManager {
    constructor(store) {
        this.store = store;
    }

    initPreviewResize() {
        const previewContainer = document.querySelector('.preview-studio-wrapper');

        if (window.$studio && window.$studio.proxy) {
            let studioScale = previewContainer.offsetWidth / this.store.state.display.format.width;
            window.$studio.proxy.setContainerScale(studioScale, true);
            window.$studio.proxy.studio.$stage.scale = studioScale;
        }
    }
}
