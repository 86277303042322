/**
  -- Component name: FastZoomIn
  -- Type: Background animation
  -- Uses: animation.zoomTarget,animation.zoomTargetX,animation.zoomTargetY
  -- Categories: background
  -- Tags: zoom
 */

import gsap from 'gsap';
import { conversions } from '../../utils';

class FastZoomIn {
    static animate(background, data) {
        let t = gsap.timeline(),
            transformOrigin = conversions.pointAlignment(data.zoomTarget) || data.zoomTargetX + ' ' + data.zoomTargetY;
        t.fromTo(background, { scale: 1 }, { duration: 1, scale: 1.3, transformOrigin, ease: 'circ.out' });
        return t;
    }
}

export default FastZoomIn;
