<template>
    <div class="edit-client-summary">
        <div class="client-summary-data">
            <div class="client-summary-data-title">{{ $t('Organization name') }}</div>
            <div class="client-summary-data-value">{{ client.name }}</div>
        </div>
        <div class="client-summary-data">
            <div class="client-summary-data-title">{{ $t('Identifier') }}</div>
            <div class="client-summary-data-value">{{ client.shortname }}</div>
        </div>
        <div class="client-summary-data">
            <div class="client-summary-data-title">{{ $t('Active license') }}</div>
            <div class="client-summary-data-value">{{ licenceIsActive ? $t('yes') : $t('no') }}</div>
        </div>
        <div class="client-summary-data">
            <div class="client-summary-data-title">{{ $t('License start date') }}</div>
            <div class="client-summary-data-value">{{ new Date(client.licenceStartsAt).toLocaleDateString() }}</div>
        </div>
        <div class="client-summary-data">
            <div class="client-summary-data-title">{{ $t('License end date') }}</div>
            <div class="client-summary-data-value">{{ new Date(client.licenceExpiresAt).toLocaleDateString() }}</div>
        </div>
    </div>
</template>

<script setup>
import ClientDTO from '@/js/dto/ClientDTO.js';
import { computed } from 'vue';

const props = defineProps({
    client: {
        type: Object,
        required: ClientDTO
    }
});

const licenceIsActive = computed(() => {
    return new Date() <= new Date(props.client.licenceExpiresAt);
});
</script>
