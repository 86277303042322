<template>
    <li class="ui-companion-video ui-companion-video-skeleton" :class="'ui-video-format-' + format">
        <div class="ui-video-preview">
            <video :src="videoPreviewUrl" muted autoplay />
        </div>
        <div class="ui-video-details">
            <h3></h3>
            <p><span></span></p>
            <div class="ui-video-tools">
                <button class="ui-video-edit">{{ $t('Use') }}</button>
            </div>
        </div>
    </li>
</template>

<script>
import { UiIcon } from '../../../../components';

const DURATION_MIN = 10;
const DURATION_MAX = 300;

export default {
    components: {
        UiIcon
    },

    props: {
        format: {
            type: String,
            default: '16x9'
        },
        promptStartedAt: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {
            //
        };
    },

    computed: {
        videoPreviewUrl() {
            let diff = Math.min(Math.round((Date.now() - (this.promptStartedAt || Date.now())) / 1000), DURATION_MAX);
            return `/assets/videos/companion_waiting_${this.format}.mp4` + (diff < DURATION_MIN ? '' : '#t=' + diff);
        }
    },

    methods: {
        //
    },

    created() {
        //
    },

    mounted() {
        //
    }
};
</script>
