<template>
    <div class="ui-color-selector" :class="containerClasses">
        <button
            ref="$selector"
            :id="selectorId"
            class="selector"
            :class="selectorClasses"
            :style="colorPreviewStyles"
            :disabled="disabled"
            @click.prevent="togglePicker($event)"
        >
            <checkboard v-if="enableAlpha || enableEmpty" />
            <span class="visually-hidden">{{ label || $t('Select a color') }}</span>
            <slot name="selector-toggle" :instance="instanceForSlots"></slot>
        </button>
        <slot name="selector-toggle-after" :instance="instanceForSlots"></slot>
        <label :class="labelClasses" :for="selectorId" :aria-hidden="!showLabel || null">{{
            label || $t('Select a color')
        }}</label>

        <ui-color-picker
            ref="$picker"
            :id="pickerId"
            :class="pickerClasses"
            v-model="colorObject"
            :default-color="defaultColor"
            :palette="palette"
            :enable-empty="enableEmpty"
            :color-reference="colorReference"
            :enable-alpha="enableAlpha"
            :default-alpha="defaultAlpha"
            :enable-other-colors="enableOtherColors"
            :is-brand-palette="isBrandPalette"
            @[pickerExpandEvent]="handlePickerExpand"
            @mousedown.stop
        />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Checkboard } from '@lk77/vue3-color';
import tinycolor from 'tinycolor2';
import { createPopper } from '@popperjs/core';
import { Color, types } from 'cte-video-studio';
import UiColorPicker, { UI_COLOR_PICKER_EXPAND } from './UiColorPicker.vue';

export const UI_COLOR_SELECTOR_SHOW = 'ui-color-selector-show';
export const UI_COLOR_SELECTOR_HIDE = 'ui-color-selector-hide';
export const UI_COLOR_SELECTOR_CHANGE = 'update:color';

export default {
    emits: [UI_COLOR_SELECTOR_CHANGE, UI_COLOR_SELECTOR_SHOW, UI_COLOR_SELECTOR_HIDE],

    components: {
        Checkboard,
        UiColorPicker
    },

    props: {
        id: {
            type: String
        },
        color: {
            type: Object,
            default: () => ({
                ref: Color.NONE,
                value: Color.NONE,
                alpha: Color.OPACITY_DEFAULT
            })
        },
        defaultColor: {
            type: String,
            default: Color.BLACK
        },
        palette: {
            type: Object,
            default: () => ({})
        },
        enableEmpty: {
            type: Boolean,
            default: false
        },
        enableAlpha: {
            type: Boolean,
            default: false
        },
        defaultAlpha: {
            type: Number,
            default: Color.OPACITY_DEFAULT
        },
        enableOtherColors: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        selectorClass: {
            type: [String, Object],
            default: ''
        },
        isBrandPalette: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            shownPicker: false,
            colorPreview: {
                hex: this.color.value || this.getResolvedDefaultColor(this.defaultColor),
                a: this.color.alpha > 0 || this.color.alpha === 0 ? this.color.alpha : this.defaultAlpha
            }
        };
    },

    computed: {
        ...mapState({
            prefixes: (state) => state.ui.prefixes
        }),

        ...mapGetters({
            //
        }),

        resolvedDefaultColor() {
            return this.getResolvedDefaultColor(this.defaultColor);
        },

        isEmpty() {
            return this.enableEmpty && this.color.value == Color.NONE;
        },

        containerClasses() {
            return {
                disabled: this.disabled
            };
        },

        selectorId() {
            return this.id + '-selector';
        },

        selectorClasses() {
            return [
                this.selectorClass,
                {
                    show: this.shownPicker
                }
            ];
        },

        colorReference() {
            return !!this.color.value
                ? this.color.ref
                : this.palette[this.defaultColor]
                  ? this.getPaletteReference(this.defaultColor)
                  : this.defaultColor;
        },

        colorObject: {
            get() {
                return {
                    hex: this.color.value || this.resolvedDefaultColor,
                    a: this.color.alpha > 0 || this.color.alpha === 0 ? this.color.alpha : this.defaultAlpha
                };
            },
            set(newColor) {
                let newValue = {
                    ref: this.$refs.$picker.selectedPaletteColor || newColor.hex.toLowerCase(),
                    value: newColor.hex.toLowerCase()
                };
                if (this.enableAlpha) newValue.alpha = newColor.a;

                if (!this.isEqualColor(newValue, this.color)) {
                    this.$emit(UI_COLOR_SELECTOR_CHANGE, newValue);
                }
            }
        },

        colorPreviewStyles() {
            return {
                '--selected-color': this.isEmpty ? 'none' : this.colorPreview.hex,
                '--selected-alpha': this.enableAlpha ? this.colorPreview.a : Color.OPACITY_DEFAULT
            };
        },

        pickerId() {
            return this.id + '-picker';
        },

        pickerClasses() {
            return {
                show: this.shownPicker
            };
        },

        pickerExpandEvent() {
            return UI_COLOR_PICKER_EXPAND;
        },

        labelClasses() {
            return {
                'visually-hidden': !this.showLabel
            };
        },

        instanceForSlots() {
            return this;
        }
    },

    watch: {
        color(newValue) {
            this.colorPreview = {
                hex: this.color.value || this.resolvedDefaultColor,
                a: this.color.alpha > 0 || this.color.alpha === 0 ? this.color.alpha : this.defaultAlpha
            };
        }
    },

    methods: {
        isEqualColor({ ref: refA, value: colorA, alpha: alphaA }, { ref: refB, value: colorB, alpha: alphaB }) {
            return (
                tinycolor.equals(refA, refB) &&
                tinycolor.equals(colorA, colorB) &&
                (!this.enableAlpha || alphaA === alphaB)
            );
        },

        getResolvedDefaultColor(defaultColor) {
            return types.isColor(defaultColor)
                ? defaultColor
                : this.$store.state.branding.palette[defaultColor] || (this.enableEmpty ? Color.NONE : Color.BLACK);
        },

        getPaletteReference(key) {
            return this.prefixes.settingsReference + 'palette.' + key;
        },

        togglePicker(event) {
            if (!this.shownPicker) this.showPicker();
            else this.hidePicker();
        },

        showPicker(event) {
            this._popper.update();
            this.shownPicker = true;
            document.addEventListener('mousedown', this.hidePicker);
            this.$emit(UI_COLOR_SELECTOR_SHOW, this);
        },

        hidePicker(event) {
            if (!event || !this.$refs.$picker.$el.contains(event.target)) {
                this.shownPicker = false;
                document.removeEventListener('mousedown', this.hidePicker);
                this.$emit(UI_COLOR_SELECTOR_HIDE, this);
            }
        },

        handlePickerExpand() {
            this._popper.update();
        }
    },

    mounted() {
        this._popper = createPopper(this.$refs.$selector, this.$refs.$picker.$el, {
            placement: 'bottom-start',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 2]
                    }
                },
                {
                    name: 'preventOverflow',
                    options: {
                        boundary: document.querySelector('.ui-body-panel')
                    }
                },
                {
                    name: 'flip',
                    enabled: true
                }
            ]
        });
    },

    beforeUnmount() {
        document.removeEventListener('mousedown', this.hidePicker);
        this._popper.destroy();
    }
};
</script>
