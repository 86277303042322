<!--
  -- Component name: ColorMoveToTop
  -- Type: Transition
  -- Uses: color.start
  -- Tags: color
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        fill="state.color.start"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <path class="color-move-left-1" :fill="state.color.start" d="M0 0h1280v720H0z" />
        <path class="color-move-left-2" :fill="state.color.start" d="M0 0h1280v720H0z" />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            isAbove: true
        };
    },

    computed: {},

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 }, 0.001);
            t.set('#' + this.containerID + ' .color-move-left-2', { autoAlpha: 0.5 }, 0.2);
            t.fromTo(
                '#' + this.containerID + ' .color-move-left-1',
                { yPercent: 0 },
                { yPercent: -100, duration: 0.7, ease: 'power1.out' },
                0.2
            );
            t.fromTo(
                '#' + this.containerID + ' .color-move-left-2',
                { yPercent: 100 },
                { yPercent: 0, duration: 0.7, ease: 'power1.out' },
                0.2
            );
            t.fromTo(
                '#' + this.containerID + ' .color-move-left-2',
                { scaleY: 0 },
                { scaleY: 0.2, duration: 0.3, ease: 'power1.out' },
                0.2
            );
            t.fromTo(
                '#' + this.containerID + ' .color-move-left-2',
                { scaleY: 0.2 },
                { scaleY: 0, duration: 0.5, ease: 'power1.out' },
                0.7
            );
            return t;
        }
    }
};
</script>
