<!--
  -- Component name: Number7
  -- Type: Transition
  -- Uses: color.start
  -- Tags: number
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <defs>
            <mask :id="svgMaskID">
                <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
                <path
                    class="color-number"
                    d="M562.9,502.8c40.4-77.6,80.4-154.6,120.9-232.5c-2.8,0-4.6,0-6.5,0c-40.8,0-81.7,0-122.5,0
				c-6.6,0-6.6,0-6.6-6.6c0-7.4,0.2-14.7-0.1-22.1c-0.2-4.6,1.2-6,5.9-6c42.4,0.2,84.9,0.1,127.3,0.1c15.2,0,30.4,0.1,45.6-0.1
				c3.8,0,5.4,1.2,4.8,4.9c0,0.2,0,0.3,0,0.5c0.2,24.9-0.1,24.7-11.6,47.2c-22.9,44.8-45.4,89.9-68.1,134.8
				c-9,17.8-18.1,35.6-27,53.5c-3.9,7.7-7.4,15.6-11.4,23.2c-0.9,1.7-3.4,3.6-5.2,3.6c-13.6,0.3-27.2,0.2-40.8,0.1
				C566.5,503.5,565.4,503.2,562.9,502.8z"
                />
            </mask>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" :fill="state.color.start" :mask="svgMask" />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {
        svgMaskID() {
            return this.containerID + '-number7-mask';
        },

        svgMask() {
            return 'url(#' + this.svgMaskID + ')';
        }
    },

    watch: {
        svgMaskRadius: {
            handler() {
                this.updateTimeline();
            }
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            t.fromTo(
                '#' + this.svgMaskID + ' .color-number',
                { scale: 1 },
                { scale: 1.5, transformOrigin: '50% 50%', duration: 1 },
                0
            );
            t.fromTo(
                '#' + this.svgMaskID + ' .color-number',
                { scale: 1.5 },
                { scale: 50, translateX: '-100%', transformOrigin: '50% 50%', duration: 0.2 },
                1
            );
            return t;
        }
    }
};
</script>
