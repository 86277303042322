<template>
    <main class="ui-iframe-view">
        <iframe width="100%" height="100%" :src="src" allowfullscreen></iframe>
    </main>
</template>

<script setup>
const LEGACY_URL = import.meta.env.VITE_LEGACY_APP_URL;
const src = LEGACY_URL + '/media';
</script>
