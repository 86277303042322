<template>
    <div class="ui-caption-editor-actions">
        <ui-caption-editor-action-subtitles
            :enable="enable"
            :captioning="captioning"
            :current-caption-button="currentCaptionButton"
            :short-language="shortLanguage"
            @update:captioning="updateCaptioning"
        >
        </ui-caption-editor-action-subtitles>
        <ui-caption-editor-action-translation
            :enable="enable"
            :current-captions="currentCaptions"
            :current-caption-button="currentCaptionButton"
            :translating-status="translatingStatus"
            :short-language="shortLanguage"
            @update:translating-status="updateTranslatingStatus"
            @update:current-captions="updateCurrentCaptions"
        >
            <fa-icon class="icon" icon="fa-solid fa-language" />
        </ui-caption-editor-action-translation>
    </div>
</template>

<script>
import UiCaptionEditorActionSubtitles from './actions/UiCaptionEditorActionSubtitles.vue';
import UiCaptionEditorActionTranslation from './actions/UiCaptionEditorActionTranslation.vue';

export default {
    name: 'UiCaptionEditorActions',

    components: {
        UiCaptionEditorActionSubtitles,
        UiCaptionEditorActionTranslation
    },

    props: {
        enable: {
            type: Boolean,
            default: true
        },
        captioning: {
            type: Boolean
        },
        currentCaptions: {
            type: Array
        },
        translatingStatus: {
            type: Object
        },
        currentCaptionButton: {
            type: Object
        },
        shortLanguage: {
            type: String
        }
    },

    methods: {
        updateCaptioning(captioning) {
            this.$emit('update:captioning', captioning);
        },

        updateTranslatingStatus(translatingStatus) {
            this.$emit('update:translating-status', translatingStatus);
        },

        updateCurrentCaptions(currentCaptions) {
            this.$emit('update:current-captions', currentCaptions);
        }
    }
};
</script>

<style scoped></style>
