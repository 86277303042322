<!--
  -- Component name: MirrorBevel
  -- Type: Transition
  -- Uses: 
  -- Tags: mirror
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <defs>
            <clipPath :id="svgMaskID" clipPathUnits="objectBoundingBox" :style="clipPathTransform">
                <path class="bevel1" d="M46.4-96.1h-351.9L-826.2,806h351.9L46.4-96.1z" />
                <path class="bevel2" d="M-435.6-96.1h-147.8L-1104.1,806h147.8L-435.6-96.1z" />
                <path class="bevel3" d="M-143.2-96.1h-2328L-2992,806h2328L-143.2-96.1z" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            needsRefresh: true, // The transition needs Chrome and Safari to force a re-rendering during the animation
            keepActive: true
            // isAbove: false, // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {
        svgMaskID() {
            return this.containerID + '-mirror-bevel-mask';
        },

        svgMask() {
            return 'url(#' + this.svgMaskID + ')';
        },

        clipPathTransform() {
            return this.format && this.getClipPathTransform(1280, 720);
        }
    },

    watch: {
        svgMaskRadius: {
            handler() {
                this.updateTimeline();
            }
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            t.set(this, { inClipPathProxy: this.svgMask }, 0.0001);
            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 }, 1.1);
            t.fromTo('#' + this.svgMaskID + ' .bevel1', { x: 0 }, { duration: 0.9, x: '+=2560' }, 0);
            t.fromTo('#' + this.svgMaskID + ' .bevel2', { x: 0 }, { duration: 0.8, x: '+=2360' }, 0);
            t.fromTo('#' + this.svgMaskID + ' .bevel3', { x: 0 }, { duration: 0.8, x: '+=1980' }, 0.3);
            return t;
        }
    }
};
</script>
