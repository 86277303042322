import { mapActions } from 'vuex';

export default {
    methods: {
        ...mapActions('ui/history', {
            startHistoryStep: 'startStep',
            ignoreHistoryStep: 'ignoreStep',
            undo: 'undoStep',
            redo: 'redoStep'
        })
    }
};
