import BaseModal from './BaseModal';
import UiModal from '../components/modals/UiModal.vue';

export default {
    mixins: [
        //
    ],

    components: {
        UiModal
    },

    props: {
        ...BaseModal.props,
        ...UiModal.props
    },

    data() {
        return {
            //
        };
    },

    computed: {
        //
    },

    watch: {
        //
    },

    methods: {
        open() {
            this.$refs.$modal.open();
        },

        close() {
            this.$refs.$modal.close();
        }
    },

    mounted() {
        //
    }
};
