import { Editor } from '@tiptap/vue-3';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import HardBreak from '@tiptap/extension-hard-break';
import Text from '@tiptap/extension-text';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import TextStyle from '@tiptap/extension-text-style';
import { Color as TextColor } from '@tiptap/extension-color';
import StudioMarkers, { customNodes, toStudioMessage } from './StudioMarkers';

export const headlessEditor = new Editor({
    content: '',
    enableInputRules: false,
    enablePasteRules: false,
    parseOptions: {
        preserveWhitespace: true
    },
    extensions: [
        Document,
        Paragraph,
        StudioMarkers.configure({ allEnabled: true }),
        HardBreak,
        TextStyle,
        TextColor,
        Bold,
        Italic,
        Text
    ]
});

export const parseEditorMessage = (htmlContent, activeNodes = {}) => {
    headlessEditor.commands.setContent(htmlContent, false, { preserveWhitespace: true });

    // Parse and replace studio marks by tiptap nodes and marks
    customNodes.forEach((node) => {
        if (!!activeNodes[node.name]) node.parseEditorMessage(headlessEditor);
    });

    return toStudioMessage(headlessEditor.getHTML(), activeNodes);
};
