/**
 * Video Studio
 * Sound constants
 */

const VOLUME_MIN = 0;
const VOLUME_MAX = 1;
const VOLUME_EMPTY = null;

const FADE_NONE = 0;
const FADE_EMPTY = null;

const LOOP_NONE = 1;
const LOOP_INFINITE = -1;

const Sound = {
    VOLUME_MIN,
    VOLUME_MAX,
    VOLUME_EMPTY,

    FADE_NONE,
    FADE_EMPTY,

    LOOP_NONE,
    LOOP_INFINITE,

    VOLUME_CUSTOM: 'custom',
    FADE_CUSTOM: 'custom',

    VOLUME_DEFAULT: VOLUME_MAX,
    BACKGROUND_VOLUME_DEFAULT: VOLUME_MAX,
    FADE_DEFAULT: 2,
    BACKGROUND_FADE_DEFAULT: 0,
    END_FADE_DEFAULT: 3,
    LOOP_DEFAULT: LOOP_NONE
};

export default Sound;
