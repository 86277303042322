<template>
    <header class="ui-tts-editor-header">
        <h5 class="ui-tts-editor-title">{{ $t('AI Voice') }}</h5>
        <button class="ui-tts-editor-close ui-action-button" @click.prevent="cancelMethod">
            <span class="visually-hidden">{{ $t('Close') }}</span>
            <fa-icon class="icon" icon="fa-solid fa-xmark" />
        </button>
    </header>
</template>

<script>
export default {
    name: 'UiTtsEditorHeader',

    props: {
        cancelMethod: {
            type: Function,
            required: true
        }
    }
};
</script>

<style scoped></style>
