<template>
    <div class="ui-range-input" :class="containerClasses">
        <label
            :id="labelId"
            class="ui-range-label"
            :class="labelClasses"
            :for="inputId"
            :aria-hidden="!showLabel || null"
        >
            <slot>{{ label }}</slot>
        </label>
        <div class="ui-range-input-group">
            <input
                type="range"
                ref="$range"
                :id="rangeId"
                :style="rangeStyles"
                :aria-labelledby="labelId"
                v-model="inputValue"
                :step="step"
                :min="min"
                :max="max"
                :disabled="disabled"
                @change="handleChange"
            />
            <input
                type="number"
                ref="$input"
                :id="inputId"
                class="ui-range-number-input"
                v-model="inputValue"
                :step="step"
                :min="min"
                :max="max"
                :disabled="disabled"
                @change="handleChange"
            />
        </div>
    </div>
</template>

<script>
import { Dimension } from 'cte-video-studio';

export default {
    emits: ['update:modelValue'],

    props: {
        id: {
            type: String
        },
        modelValue: {
            type: Number,
            default: 0
        },
        defaultValue: {
            type: Number,
            default: 0
        },
        step: {
            type: Number,
            default: 1
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        },
        horizontal: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        showLabel: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            inputValue: !isNaN(this.modelValue) ? this.modelValue : this.defaultValue
        };
    },

    computed: {
        containerClasses() {
            return {
                horizontal: this.horizontal,
                disabled: this.disabled
            };
        },

        rangeId() {
            return this.id + '-range';
        },

        rangeStyles() {
            return {
                '--range-value': (100 * (this.inputValue - this.min)) / (this.max - this.min) + Dimension.PERCENT_UNIT
            };
        },

        inputId() {
            return this.rangeId + '-input';
        },

        labelId() {
            return this.rangeId + '-label';
        },

        labelClasses() {
            return {
                'visually-hidden': !this.showLabel
            };
        }
    },

    watch: {
        modelValue(newValue) {
            this.inputValue = !isNaN(newValue) ? this.modelValue : this.defaultValue;
        }
    },

    methods: {
        handleChange(event) {
            if (!/\S/.test(this.inputValue) || isNaN(this.inputValue)) {
                this.inputValue = this.defaultValue;
            }
            if (this.min != null) this.inputValue = Math.max(this.min, this.inputValue);
            if (this.max != null) this.inputValue = Math.min(this.inputValue, this.max);
            this.emitChangeEvent();
        },

        emitChangeEvent() {
            this.$emit('update:modelValue', this.inputValue);
        }
    },

    mounted() {
        //
    }
};
</script>
