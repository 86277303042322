<template>
    <ui-dropdown
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="bottom"
        menu-strategy="fixed"
        :toggle-style="toggleStyle"
        :icon-only="iconOnly"
    >
        <template #dropdown-toggle>
            <fa-icon class="icon" icon="fa-regular fa-stopwatch" />
            <span v-if="iconOnly">{{ $t('Timing') }}</span>
        </template>
        <div class="sequence-duration ui-card-row">
            <ui-number-input
                class="ui-content-row"
                v-model="voiceOverStart"
                :default-value="0"
                :min="0"
                :label="$t('Starting time')"
                :disabled="readOnly"
            />
        </div>
    </ui-dropdown>
</template>

<script>
import { useStore } from 'vuex';
import { defineComponent, computed, ref } from 'vue';
import UiIcon from '../../../../../../components/UiIcon.vue';
import UiNumberInput from '../../../../../../components/UiNumberInput.vue';
import UiDropdown from '../../../../../../components/UiDropdown.vue';
import { useHistory } from '../../../../../composables/useHistory';
import { useDisplay } from '../../../../../composables/useDisplay';
import { Duration } from 'cte-video-studio';
import { useVoiceOver } from '../../../../../composables/useVoiceOver';

export default defineComponent({
    name: 'UiVoiceOverDurationSelector',
    components: {
        UiIcon,
        UiNumberInput,
        UiDropdown,
        useDisplay
    },
    props: {
        useInSequence: Boolean,
        seqId: String,
        readOnly: Boolean,
        iconOnly: Boolean
    },
    setup(props) {
        const useInSequence = ref(props.useInSequence);
        const seqId = ref(props.seqId);

        const store = useStore();
        const { saveHistoryStep } = useHistory();
        const { storeModulePath, voiceOverMediaState } = useVoiceOver(useInSequence, seqId);

        const voiceOverStart = computed({
            get: () => {
                return store.getters[storeModulePath.value + '/start'];
            },
            set(value) {
                saveHistoryStep(() => {
                    store.dispatch(storeModulePath.value + '/setStart', value);
                });
            }
        });

        const toggleStyle = computed(() => {
            if (voiceOverStart.value !== Duration.START_DEFAULT) return { color: 'var(--edit-mode-color)' };
        });

        return {
            voiceOverStart,
            toggleStyle,
            visualEndDefault: Duration.END_DEFAULT
        };
    }
});
</script>
