<template>
    <section class="ui-companion-answer ui-answer">
        <header class="ui-answer-user">
            <div class="ui-answer-user-avatar">
                <img class="companion-avatar" src="@/assets/images/logo-cte-companion.png" alt="" />
            </div>
            <h2 class="ui-answer-user-name">2Emotion.ai</h2>
            <p class="ui-answer-user-title">{{ answerTitle }}</p>
        </header>
        <ul class="ui-answer-content">
            <template v-for="n in createdVideosTotal" :key="'video' + n">
                <ui-companion-video-created
                    :video="prompt.videos[n - 1]"
                    :format="prompt.context.format"
                    :selected="n === selectedVideoIndex"
                    :folders="folders"
                    @[companionVideoClickEvent]="selectVideo(n)"
                    @[companionVideoUseEvent]="useVideo"
                />
            </template>
            <template v-for="n in creatingVideosTotal">
                <ui-companion-video-skeleton :format="prompt.context.format" :prompt-started-at="prompt.startedAt" />
            </template>
        </ul>
    </section>
</template>

<script>
import { printf as sprintf } from 'fast-printf';
import { UiIcon } from '../../../../components';
import UiCompanionVideoCreated, {
    UI_COMPANION_VIDEO_CLICK,
    UI_COMPANION_VIDEO_USE
} from './UiCompanionVideoCreated.vue';
import UiCompanionVideoSkeleton from './UiCompanionVideoSkeleton.vue';
import i18n from '@/libs/i18n';

export default {
    emits: [UI_COMPANION_VIDEO_USE],

    components: {
        UiIcon,
        UiCompanionVideoCreated,
        UiCompanionVideoSkeleton
    },

    props: {
        prompt: {
            type: Object,
            default: null
        },
        folders: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            companionVideoClickEvent: UI_COMPANION_VIDEO_CLICK,
            companionVideoUseEvent: UI_COMPANION_VIDEO_USE,

            selectedVideoIndex: 0
        };
    },

    computed: {
        expectedVideosTotal() {
            if (this.prompt.isIgnored || this.prompt.isFailed) return 0;

            if (this.prompt.isEnded) return this.prompt.videos?.length;

            if (this.prompt.isOngoing) return this.prompt.videoCreationsTotal;
        },

        creatingVideosTotal() {
            return this.prompt.isEnded
                ? 0
                : Math.max(0, this.prompt.videoCreationsTotal - (this.prompt.videos?.length || 0));
        },

        createdVideosTotal() {
            return this.prompt.videos?.length;
        },

        answerTitle() {
            switch (true) {
                case this.expectedVideosTotal > 1:
                    return sprintf(
                        i18n.global.t('Here are %1$s video suggestions for your brief'),
                        this.expectedVideosTotal
                    );
                case this.expectedVideosTotal == 1:
                    return i18n.global.t('Here is a video suggestion for your brief');
                default:
                    return i18n.global.t(
                        'It seems a problem occured during the generation of your videos, please try again'
                    );
            }
        }
    },

    methods: {
        sprintf,

        selectVideo(videoIndex) {
            this.selectedVideoIndex = videoIndex;
        },

        useVideo(videoData) {
            this.$emit(UI_COMPANION_VIDEO_USE, videoData);
        }
    },

    mounted() {
        //
    }
};
</script>
