/**
 * Video Studio
 * Branding data Vuex store module
 */

import { Color, Font } from '@/js/video-studio/constants';
import { types } from '@/js/video-studio/utils';
import { DEFAULT_TEMPLATE_LANGUAGE } from '@/js/constants/index.js';
import logo from './logo';
import libraries from './libraries.js';

const originalState = () => ({
    id: '',
    name: '',
    isMaster: false,
    autoActivationEnabled: true,
    enableOtherColors: true,
    enabledTemplateLanguages: [DEFAULT_TEMPLATE_LANGUAGE],
    defaultVoiceId: '',
    cover: {
        src: '',
        src__ref: '',
        src__id: ''
    },
    font: {
        value: Font.DEFAULT,
        custom: null
    },
    palette: {
        color1: Color.NONE,
        color2: Color.NONE,
        color3: Color.NONE,
        color4: Color.NONE,
        color5: Color.NONE,
        color6: Color.NONE,
        color7: Color.NONE,
        color8: Color.NONE,
        color9: Color.NONE,
        color10: Color.NONE,
        color11: Color.NONE,
        color12: Color.NONE,
        color13: Color.NONE,
        color14: Color.NONE,
        color15: Color.NONE,
        color16: Color.NONE,
        color17: Color.NONE,
        color18: Color.NONE,
        color19: Color.NONE,
        color20: Color.NONE
    },
    tts: {
        voiceId: null
    },
    meta: {
        brand: '',
        website: ''
    }
});

// Initial state
const state = {
    ...originalState()
};

// Getters
const getters = {
    defaultFont: (state) => (state.font.value != Font.CUSTOM ? state.font.value : state.font.custom)
};

// Mutations
const mutations = {
    setCover(state, src) {
        state.cover.src = src;
    },

    setFont(state, font) {
        if (types.isFont(font.value)) {
            state.font.value = font.value;
            state.font.custom = null;
        } else if (types.isFont(font.custom)) {
            state.font.value = Font.CUSTOM;
            state.font.custom = font.custom;
        } else {
            state.font.value = Font.DEFAULT;
            state.font.custom = null;
        }
    },

    setPalette(state, palette) {
        let p;
        for (p in palette) {
            if (types.isColor(palette[p])) state.palette[p] = palette[p];
        }
    },

    setTTSSettings(state, tts) {
        if (tts.voiceId) state.tts.voiceId = tts.voiceId;
    },

    setMeta(state, meta) {
        let p;
        for (p in meta) {
            if (Object.hasOwn(state.meta, p)) state.meta[p] = meta[p];
        }
    },

    setId(state, id) {
        state.id = id;
    },

    setName(state, name) {
        state.name = name;
    },

    setIsMaster(state, isMaster) {
        state.isMaster = isMaster;
    },

    enableAutoActivation(state, enable) {
        state.autoActivationEnabled = enable;
    },

    setCoverReference(state, ref) {
        state.cover.src__ref = ref;
    },

    setCoverId(state, id) {
        state.cover.src__id = id;
    },

    setPaletteWithEmpty(state, palette) {
        let p;
        for (p in palette) {
            if (types.isColor(palette[p]) || palette[p] == Color.NONE) state.palette[p] = palette[p];
        }
    },

    enableOtherColors(state, enable = true) {
        state.enableOtherColors = enable;
    },

    enableTemplateLanguages(state, languages) {
        state.enabledTemplateLanguages = !!languages.length ? languages.sort() : [DEFAULT_TEMPLATE_LANGUAGE];
    },

    setDefaultVoiceId(state, voiceId) {
        state.defaultVoiceId = voiceId;
    }
};

// Actions
const actions = {
    init({ commit, dispatch }, data) {
        dispatch('initLogo', data.logo);
        commit('setCover', data.cover.src);
        commit('setFont', data.font);
        commit('setPalette', data.palette);
        if (data.tts) commit('setTTSSettings', data.tts);
        commit('setMeta', data.meta || {});
        commit('setId', data.id || '');
        commit('setName', data.name || '');
        commit('setIsMaster', !!data.isMaster);
        commit('enableAutoActivation', !!data.autoActivationEnabled);
        commit('setCoverId', data.cover.src__id);
        commit('setCoverReference', data.cover.src__ref);
        commit('enableOtherColors', data.enableOtherColors);
        commit('enableTemplateLanguages', data.enabledTemplateLanguages || []);
        if (data.defaultVoiceId) commit('setDefaultVoiceId', data.defaultVoiceId);
        dispatch('libraries/init', data.libraries || {});
        dispatch('libraries/setTagOrders', data.tags || {});
    },

    updateCover({ commit }, data) {
        commit('setCoverId', data.src__id);
        commit('setCover', data.src);
    },

    updateAutoActivation({ commit }, enable) {
        commit('enableAutoActivation', enable);
    },

    clearState({ dispatch, state }) {
        dispatch('clearLogoState');
        dispatch('libraries/clearState');
        Object.assign(state, originalState());
    }
};

export default {
    namespaced: true,

    modules: {
        logo: logo,
        libraries: libraries
    },

    state,
    getters,
    mutations,
    actions
};
