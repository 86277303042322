import FoldersDataService from '@/js/application/services/folders/FoldersDataService.js';
import { useStore } from 'vuex';

export async function showFolderGuard(to, from, next) {
    const store = useStore();
    try {
        to.meta.folder = await new FoldersDataService(store).fetchByShortname(to.params.shortname);
        next();
    } catch (error) {
        next({ name: '404' });
    }
}
