/**
 * Video Studio
 * TTS constants
 */

const NONE_CATEGORY = '';
const AUDIO_CATEGORY = 'audio';
const VOICE_CATEGORY = 'voice';
const RECORDING_CATEGORY = 'recording';
const DEFAULT_CATEGORY = NONE_CATEGORY;

const STATUS_COMPLETED = 'tts-completed';
const STATUS_ERROR = 'tts-error';
const STATUS_PROCESSING = 'tts-processing';

const TTS = {
    NONE_CATEGORY,
    AUDIO_CATEGORY,
    VOICE_CATEGORY,
    RECORDING_CATEGORY,
    DEFAULT_CATEGORY,

    CATEGORIES: [VOICE_CATEGORY, AUDIO_CATEGORY, RECORDING_CATEGORY],

    STATUS_COMPLETED,
    STATUS_ERROR,
    STATUS_PROCESSING
};

export default TTS;
