<template>
    <div class="ui-location-selector" :class="containerClasses">
        <ui-dropdown
            :id="selectorId"
            ref="$dropdown"
            :menu="false"
            :caret="false"
            :scrollable="false"
            :disabled="disabled"
            toggleClass="selector"
            :toggleStyle="mapZoomPreviewStyles"
            @[dropdownHideEvent]="handleHide"
        >
            <template #dropdown-toggle>
                <span class="visually-hidden">{{ label || $t('Select a location') }}</span>
            </template>
            <template #dropdown-toggle-after="{ instance }">
                <div class="selector-actions">
                    <button class="action-change" :title="$t('Change location')" @click.stop="instance.toggle()">
                        <span class="visually-hidden">{{ $t('Change location') }}</span>
                        <fa-icon class="icon" icon="fa-solid fa-rotate" />
                    </button>
                </div>
            </template>

            <ui-text-input
                :id="id + '-address'"
                class="ui-location-address ui-card-row"
                ref="$address"
                :horizontal="false"
                v-model="address"
                :label="$t('Enter a location')"
                @focus="activateSearch"
                @input="handleAddressInput"
            />
            <div
                ref="$addressDropdown"
                class="ui-dropdown-menu dropdown-menu ui-location-autocomplete"
                :class="addressResultsDropdownClasses"
            >
                <ui-dropdown-item
                    v-for="result in addressResults"
                    :key="result.place_id"
                    @click="selectAddress(result)"
                >
                    <template v-for="main_text_part in result.main_text_parts"
                        ><span :class="{ 'autocomplete-matched': main_text_part.matched }">{{
                            main_text_part.text
                        }}</span></template
                    >
                    <em>{{ result.structured_formatting.secondary_text }}</em>
                </ui-dropdown-item>
            </div>
            <ui-text-input
                :id="id + '-coordinates'"
                :horizontal="false"
                v-model="coordinates"
                :label="$t('Or enter GPS coordinates')"
                @change="handleCoordinatesChange"
            />
            <div class="google-attribution">
                <img
                    src="@/assets/images/powered_by_google.png"
                    class="powered-by-google-logo"
                    alt="Powered by Google"
                />
                <div ref="$attributions"></div>
            </div>
        </ui-dropdown>
        <label :class="labelClasses" :for="selectorId">{{ label || $t('Select a location') }}</label>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { createPopper } from '@popperjs/core';
import { Loader as GoogleMapsLoader } from '@googlemaps/js-api-loader';
import _debounce from 'lodash/debounce';
import { Background } from 'cte-video-studio';
import { UiDropdown, UiDropdownItem, UiIcon, UiTextInput } from '../../../../components';
import { UI_DROPDOWN_MENU_HIDE } from '../../../../components/UiDropdown.vue';
import { getMapZoomUrl } from '../../../utils';

const DEFAULT_MAP_ZOOM_ADDRESS = 'Tour Eiffel, Paris, France';

let googleMapsLoader = null;

export default {
    emits: ['update:location'],

    components: {
        UiDropdown,
        UiDropdownItem,
        UiIcon,
        UiTextInput
    },

    props: {
        id: {
            type: String
        },
        location: {
            type: Object,
            default: () => ({
                address: '',
                coords: Background.DEFAULT_MAP_ZOOM_COORDS
            })
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        showLabel: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            address: this.location.address || '',
            coordinates: this.location.coords || Background.DEFAULT_MAP_ZOOM_COORDS,
            searching: false,
            addressResults: [], //[ { place_id: "abc", description: "ABC" }, { place_id: "abc123", description: "ABC123" }, { place_id: "123", description: "123" }],
            sessionToken: null
        };
    },

    computed: {
        ...mapState({
            language: (state) => state.ui.shortLanguage
        }),

        ...mapGetters({
            //
        }),

        containerClasses() {
            return {
                disabled: this.disabled
            };
        },

        selectorId() {
            return this.id + '-selector';
        },

        mapZoomPreviewStyles() {
            return {
                backgroundImage:
                    "url('" + getMapZoomUrl(this.coordinates || Background.DEFAULT_MAP_ZOOM_COORDS, 3, false) + "')"
            };
        },

        labelClasses() {
            return {
                'visually-hidden': !this.showLabel
            };
        },

        dropdownHideEvent() {
            return UI_DROPDOWN_MENU_HIDE;
        },

        addressResultsDropdownClasses() {
            return {
                show: this.searching && !!this.addressResults.length
            };
        }
    },

    watch: {
        location(newValue) {
            if (newValue.coords != this.coordinates || newValue.address != this.address) {
                this.coordinates = newValue.coords || Background.DEFAULT_MAP_ZOOM_COORDS;
                if (!!newValue.address) {
                    this.address = newValue.address;
                } else if (!!newValue.coords) {
                    this.address = '';
                    this.geocodeCoordinates();
                } else {
                    this.address = DEFAULT_MAP_ZOOM_ADDRESS;
                }
            }
        },

        searching(value) {
            if (value) document.addEventListener('click', this.hideAddressResults);
            else document.removeEventListener('click', this.hideAddressResults);
        }
    },

    methods: {
        activateSearch(event) {
            this.searching = true;
        },

        handleCoordinatesChange(event) {
            let value = event.target.value;
            if (!!value) {
                this.geocodeCoordinates(this.emitChangeEvent);
            } else {
                this.coordinates = Background.DEFAULT_MAP_ZOOM_COORDS;
                this.address = DEFAULT_MAP_ZOOM_ADDRESS;
            }
            this.emitChangeEvent();
        },

        handleAddressInput(event) {
            if (event.target.value.length > 2) {
                if (!this.sessionToken) {
                    this.sessionToken = new this._google.maps.places.AutocompleteSessionToken();
                }
                this._debouncedSearchAddress();
            } else {
                this.addressResults = [];
            }
        },

        searchAddress() {
            this._autocompleteService.getPlacePredictions(
                {
                    input: this.address,
                    fields: ['geometry'],
                    types: ['geocode'],
                    sessionToken: this.sessionToken
                },
                this.showAddressResults
            );
        },

        showAddressResults(predictions, status) {
            if (status == this._google.maps.places.PlacesServiceStatus.OK) {
                this.addressResults = predictions.map((result) => {
                    let i = 0;
                    result.main_text_parts = [];

                    result.structured_formatting.main_text_matched_substrings.forEach((str) => {
                        if (str.offset > i) {
                            result.main_text_parts.push({
                                text: result.structured_formatting.main_text.substring(i, str.offset),
                                matched: false
                            });
                        }
                        i = str.offset + str.length;
                        result.main_text_parts.push({
                            text: result.structured_formatting.main_text.substring(str.offset, i),
                            matched: true
                        });
                    });

                    if (i < result.structured_formatting.main_text.length) {
                        result.main_text_parts.push({
                            text: result.structured_formatting.main_text.substring(i),
                            matched: false
                        });
                    }

                    return result;
                });
                this._popper.update();
            }
        },

        selectAddress(result) {
            this._placesService.getDetails(
                {
                    placeId: result.place_id,
                    fields: ['geometry.location'],
                    sessionToken: this.sessionToken
                },
                (details, status) => {
                    if (status == this._google.maps.places.PlacesServiceStatus.OK) {
                        this.coordinates = details.geometry.location.toUrlValue();
                        this.address = result.description;
                        this.searching = false;
                        this.addressResults = [];
                        this.sessionToken = null;
                        this.emitChangeEvent();
                    }
                }
            );
        },

        hideAddressResults(event) {
            if (!event.target.closest('.ui-location-autocomplete, .ui-location-address')) {
                this.searching = false;
            }
        },

        geocodeCoordinates(callback) {
            let [lat, lng] = this.coordinates.split(',');
            this._geocoder
                .geocode({ location: { lat: Number(lat), lng: Number(lng) } })
                .then((response) => {
                    this.address = response.results.reduce((address, result) => {
                        if (address || result.types.includes('plus_code')) return address;
                        return result.formatted_address;
                    }, '');
                    if (callback) callback();
                })
                .catch((error) => {
                    this.address = '';
                    if (callback) callback();
                });
        },

        emitChangeEvent() {
            this.$emit('update:location', { coords: this.coordinates, address: this.address });
        },

        handleHide() {
            this.searching = false;
        }
    },

    created() {
        if (!googleMapsLoader) {
            googleMapsLoader = new GoogleMapsLoader({
                apiKey: window.STUDIO_MAP_API_KEY,
                version: 'weekly', // Note: At time of writing: v3.48
                language: this.language,
                libraries: ['places']
            });
        }

        this._debouncedSearchAddress = _debounce(this.searchAddress, 500);
    },

    mounted() {
        googleMapsLoader.load().then((google) => {
            this._google = google;
            this._autocompleteService = new this._google.maps.places.AutocompleteService();
            this._placesService = new this._google.maps.places.PlacesService(this.$refs.$attributions);
            this._geocoder = new this._google.maps.Geocoder();

            if (!this.address && !!this.coordinates) {
                this.geocodeCoordinates();
            }
        });

        this._popper = createPopper(this.$refs.$address.$el, this.$refs.$addressDropdown, {
            placement: 'bottom-start',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 0]
                    }
                },
                {
                    name: 'flip',
                    enabled: true
                }
            ]
        });
    },

    beforeUnmount() {
        this._popper.destroy();
        this._autocompleteService = null;
        this._placesService = null;
        this._google = null;
    }
};
</script>
