import FollowFolderApiService from '@/js/infrastructure/api/folders/FollowFolderApiService.js';

export default class FollowFolderService {
    constructor(store) {
        this.followFolderApiService = new FollowFolderApiService(store);
    }

    async handle(params) {
        return (await this.followFolderApiService.handle(params)).data;
    }
}
