<template>
    <div class="gif-generation-result" v-if="gifName">
        <div class="gif-generation-preview">
            <img :src="gifUrl" alt="GIF généré" class="gif-generation-img" />
        </div>
        <div class="modal-body-actions-bottom">
            <button class="ui-simple-button ui-simple-button-purple download-button cte-input" @click="emitRemakeGif">
                <fa-icon :icon="['far', 'rocket-launch']" />
                {{ $t('Gif.gif-generate-again') }}
            </button>
            <a
                :href="gifDownloadUrl"
                target="_blank"
                download
                class="cte-input ui-simple-button ui-simple-button-purple download-button"
            >
                <svg-icon icon="download-icon" />
                {{ $t('Gif.gif-download') }}
            </a>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
const LEGACY_APP_URL = import.meta.env.VITE_LEGACY_APP_URL;

const props = defineProps({
    gifName: String
});

const gifDownloadUrl = computed(() => `${LEGACY_APP_URL}/front/gif-download?name=${props.gifName}`);

const gifUrl = computed(() => `${LEGACY_APP_URL}/production/gifs/${props.gifName}.gif`);

const emit = defineEmits(['remake-gif']);

const emitRemakeGif = () => {
    emit('remake-gif');
};
</script>
