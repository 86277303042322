<template>
    <div :id="'ui-modal-' + id" class="ui-modal" :class="modalClasses" tabindex="-1" @[transitionEndEvent]="destroy">
        <div class="ui-modal-backdrop" @click.prevent="maybeClose"></div>
        <div class="ui-modal-dialog" role="dialog">
            <header v-if="showTitle || showClose" class="ui-modal-header">
                <slot v-if="showTitle" name="modal-header">
                    <h5 class="ui-modal-title">{{ title }}</h5>
                </slot>
                <button
                    v-if="showClose"
                    class="ui-modal-close ui-action-button"
                    :disabled="disabled"
                    @click.prevent="close"
                >
                    <span class="visually-hidden">{{ $t('Close') }}</span>
                    <fa-icon class="icon" icon="fa-solid fa-xmark" />
                </button>
            </header>
            <div class="ui-modal-body">
                <slot>
                    <p>{{ message }}</p>
                </slot>
            </div>
            <footer v-if="showFooter" class="ui-modal-footer">
                <slot name="modal-footer">
                    <button
                        v-if="showFooterCancel"
                        class="ui-simple-button ui-simple-button-cancel"
                        :disabled="disabled"
                        @click.prevent="cancel"
                    >
                        <span>{{ cancelLabel || $t('Cancel') }}</span>
                    </button>
                    <button
                        v-if="showFooterAction"
                        class="ui-simple-button"
                        :class="actionButtonClasses"
                        :disabled="disabled"
                        @click.prevent="doAction"
                    >
                        <span>{{ actionLabel || $t('OK') }}</span>
                        <svg-icon class="loader" icon="dots-loader" />
                    </button>
                </slot>
            </footer>
        </div>
    </div>
</template>

<script>
import { BaseModal } from '../../mixins';
import UiIcon from '../UiIcon.vue';

export const UI_MODAL_ACTION_EVENT = 'ui-modal-action';
export const UI_MODAL_WAITING_ACTION_EVENT = 'ui-modal-waiting-action';
export const UI_MODAL_CANCEL_EVENT = 'ui-modal-cancel';

export default {
    mixins: [BaseModal],

    emits: [UI_MODAL_ACTION_EVENT, UI_MODAL_WAITING_ACTION_EVENT, UI_MODAL_CANCEL_EVENT],

    components: {
        UiIcon
    },

    props: {
        title: {
            type: String,
            default: ''
        },
        message: {
            type: String,
            default: ''
        },
        showTitle: {
            type: Boolean,
            default: true
        },
        showClose: {
            type: Boolean,
            default: true
        },
        showFooter: {
            type: Boolean,
            default: true
        },
        showFooterCancel: {
            type: Boolean,
            default: true
        },
        showFooterAction: {
            type: Boolean,
            default: true
        },
        waitingAction: {
            type: Function,
            default: null
        },
        afterDestroyAction: {
            type: Function,
            default: null
        },
        cancelLabel: {
            type: String,
            default: ''
        },
        actionLabel: {
            type: String,
            default: ''
        },
        actionClass: {
            type: [String, Object],
            default: 'ui-simple-button-select'
        },
        closeWithBackdrop: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            isWaitingAction: false
        };
    },

    computed: {
        disabled() {
            return !this.show || this.isWaitingAction;
        },

        actionButtonClasses() {
            return [
                {
                    'ui-loading-button': !!this.waitingAction,
                    loading: !!this.waitingAction && this.isWaitingAction
                },
                this.actionClass
            ];
        }
    },

    watch: {
        //
    },

    methods: {
        doAction() {
            if (!this.isWaitingAction) {
                if (!!this.waitingAction) {
                    let result = this.waitingAction();
                    if (result && result.constructor.name === 'Promise') {
                        this.isWaitingAction = true;
                        result
                            .then(() => {
                                this.isWaitingAction = false;
                                this.$emit(UI_MODAL_ACTION_EVENT);
                                this.close();
                                if (!!this.afterDestroyAction) {
                                    this.$store.commit('ui/modals/remove', this.id);
                                    this.afterDestroyAction();
                                }
                            })
                            .catch(() => {
                                this.isWaitingAction = false;
                                this.cancel();
                            });
                        this.$emit(UI_MODAL_WAITING_ACTION_EVENT, result);
                        return;
                    }
                }
                this.$emit(UI_MODAL_ACTION_EVENT);
                this.close();
            }
        },

        cancel(event) {
            if (!this.isWaitingAction) {
                this.$emit(UI_MODAL_CANCEL_EVENT);
                this.close();
            }
        },

        maybeClose() {
            if (this.closeWithBackdrop) this.close();
        }
    },

    mounted() {
        //
    }
};
</script>
