<template>
    <article
        :id="id"
        class="ui-card"
        ref="$cardArticle"
        :class="cardClass"
        @[accordionCloseEndEvent].self="endAccordionClosing"
    >
        <header class="ui-card-header" @[headerClickEvent]="toggleAccordion" v-if="!hideHeader">
            <div v-if="hasHeaderIcon" class="ui-card-icon">
                <slot name="header-icon"></slot>
            </div>
            <slot name="header-title">
                <h3 class="ui-card-title">
                    {{ title }}
                    <span class="ui-card-title-data" v-if="titleData">{{ titleData }}</span>
                </h3>
            </slot>
            <slot name="header-tools"></slot>
            <ui-dropdown
                v-if="showMenu && hasMenu && isEmotionMode"
                v-bind="$attrs"
                :id="'sc-' + id"
                :icon="true"
                :caret="false"
                menu-placement="bottom-end"
                class="ui-card-actions"
                :tooltip="$t('Options')"
            >
                <template #dropdown-toggle>
                    <span class="visually-hidden">{{ actionMenuLabel || $t('Actions...') }}</span>
                    <svg-icon icon="menu-icon" />
                </template>
                <slot name="menu-content"></slot>
            </ui-dropdown>
            <button v-if="accordionId" class="ui-action-button ui-card-toggling-accordion">
                <svg-icon icon="chevron-down-thin-icon" />
            </button>
        </header>

        <div class="ui-card-content" :class="contentClass">
            <slot :instance="instanceForSlots"></slot>
        </div>

        <footer v-if="hasFooter && !hideFooter" class="ui-card-footer">
            <slot name="footer"></slot>
        </footer>
    </article>
</template>

<script>
import UiDropdown from './UiDropdown.vue';
import UiIcon from './UiIcon.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        UiDropdown,
        UiIcon
    },

    props: {
        id: {
            type: String,
            default: ''
        },

        title: {
            type: String,
            default: ''
        },

        titleData: {
            type: String,
            default: ''
        },

        showMenu: {
            type: Boolean,
            default: true
        },

        actionMenuLabel: {
            type: String,
            default: ''
        },

        contentClass: {
            type: [String, Object],
            default: ''
        },

        accordionId: {
            type: String,
            default: ''
        },

        addedCardId: {
            type: String,
            default: ''
        },

        hideFooter: {
            type: Boolean,
            default: false
        },

        hideHeader: {
            type: Boolean,
            default: false
        },

        shadow: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isOpenAccordionItem: false
        };
    },

    computed: {
        ...mapGetters({
            isEmotionMode: 'ui/isEmotionMode'
        }),

        hasHeaderIcon() {
            return !!this.$slots['header-icon'];
        },

        hasMenu() {
            return !!this.$slots['menu-content'];
        },

        hasFooter() {
            return !!this.$slots['footer'];
        },

        headerClickEvent() {
            return !!this.accordion ? 'click' : null;
        },

        accordion() {
            return !!this.accordionId ? this.$store.getters['ui/accordions/find'](this.accordionId) : null;
        },

        isActiveAccordionItem() {
            return !!this.accordion && this.accordion.itemActive === this.$refs.$cardArticle;
        },

        accordionCloseEndEvent() {
            return !!this.accordion && !this.isActiveAccordionItem ? 'transitionend' : null;
        },

        cardClass() {
            return {
                'ui-card-accordion': !!this.accordion,
                'ui-card-accordion-active': this.isActiveAccordionItem,
                'ui-card-open': this.isOpenAccordionItem,
                'ui-card-flash': this.id === this.addedCardId,
                'ui-card-shadow': this.shadow
            };
        },

        instanceForSlots() {
            return this;
        }
    },

    watch: {
        //
    },

    methods: {
        toggleAccordion() {
            this.$store.commit('ui/accordions/setItemActive', {
                id: this.accordionId,
                itemActive: this.accordion.itemActive === this.$refs.$cardArticle ? null : this.$refs.$cardArticle
            });
            if (this.isActiveAccordionItem) this.isOpenAccordionItem = true;
        },

        endAccordionClosing(event) {
            if (event.target == this.$refs.$cardArticle && event.propertyName == 'max-height') {
                this.isOpenAccordionItem = false;
            }
        }
    },

    mounted() {
        this.isOpenAccordionItem = this.isActiveAccordionItem;
    }
};
</script>
