<template>
    <nav class="ui-sidebar">
        <ul class="ui-sidebar-items">
            <li v-for="itemId in authorizedItems">
                <a
                    class="ui-sidebar-item-handle"
                    :class="{ active: itemId == state.currentPanel }"
                    :href="'#p-' + itemId"
                    @click.prevent="handleClick(itemId)"
                >
                    <fa-icon
                        class="icon"
                        v-if="sidebarItemsIcons[itemId].type === 'fa'"
                        :icon="sidebarItemsIcons[itemId].icon"
                    />
                    <svg-icon v-if="sidebarItemsIcons[itemId].type === 'svg'" :icon="sidebarItemsIcons[itemId].icon" />
                    <span class="item-name">{{ $t('brands.panels.' + itemId) }}</span>
                    <span v-if="itemId == state.currentPanel && !!itemsCount[itemId]" class="item-count">{{
                        itemsCount[itemId]
                    }}</span>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import {
    SETTINGS_BRAND_PANEL_ID,
    COMPONENTS_BRAND_PANEL_ID,
    SEQUENCES_BRAND_PANEL_ID,
    TEMPLATES_BRAND_PANEL_ID,
    LIBRARY_STATUS_ACTIVE
} from '../../../../constants';

const SIDEBAR_ITEM_LIBRARIES = {
    [SETTINGS_BRAND_PANEL_ID]: [],
    [COMPONENTS_BRAND_PANEL_ID]: [
        'logoAnimations',
        'backgroundAnimations',
        'overlays',
        'panels',
        'transitions',
        'messages',
        'visuals',
        'musics'
    ],
    [SEQUENCES_BRAND_PANEL_ID]: ['sequenceTemplates'],
    [TEMPLATES_BRAND_PANEL_ID]: ['templates']
};
const SIDEBAR_ITEMS_ICONS = {
    [SETTINGS_BRAND_PANEL_ID]: {
        type: 'fa',
        icon: 'fa-solid fa-swatchbook'
    },
    [COMPONENTS_BRAND_PANEL_ID]: {
        type: 'fa',
        icon: 'fa-solid fa-wand-magic-sparkles'
    },
    [SEQUENCES_BRAND_PANEL_ID]: {
        type: 'svg',
        icon: 'sequence-template-icon'
    },
    [TEMPLATES_BRAND_PANEL_ID]: {
        type: 'fa',
        icon: 'fa-solid fa-clapperboard-play'
    }
};

export default {
    components: {
        //
    },

    props: {
        //
    },

    data() {
        return {
            items: Object.keys(SIDEBAR_ITEM_LIBRARIES),
            sidebarItemsIcons: SIDEBAR_ITEMS_ICONS
        };
    },

    computed: {
        ...mapState({
            state: (state) => state.ui,
            brand: (state) => state.branding
        }),

        ...mapGetters({
            canCreateTemplate: 'ui/canCreateTemplate',
            libraryItemsCount: 'branding/libraries/libraryItemsCount'
        }),

        authorizedItems() {
            return this.items.filter(
                (itemId) => itemId != SEQUENCES_BRAND_PANEL_ID || this.canCreateTemplate('sequenceTemplates')
            );
        },

        itemsCount() {
            return this.items.reduce((counts, itemId) => {
                counts[itemId] = this.libraryItemsCount(
                    SIDEBAR_ITEM_LIBRARIES[itemId],
                    LIBRARY_STATUS_ACTIVE,
                    this.brand.enabledTemplateLanguages
                );
                return counts;
            }, {});
        }
    },

    methods: {
        ...mapActions({
            updateCurrentPanel: 'ui/updateCurrentPanel'
        }),

        handleClick(itemId) {
            this.updateCurrentPanel(itemId);
        }
    }
};
</script>
