import { ref } from 'vue';
import { useStore } from 'vuex';
import MinimalClientDTO from '@/js/dto/MinimalClientDTO.js';
import ClientDataService from '@/js/application/services/client/ClientDataService.js';
import usePagination from '@/js/composables/usePagination.js';

export function useClients() {
    const store = useStore();

    const clients = ref([]);

    const { page, totalPages, isLoading, paginationRequest, resetData } = usePagination();

    const fetchClients = async (options) => {
        let paginatedResult = await paginationRequest(() => new ClientDataService(store).getAll(options));
        if (paginatedResult) addClients(paginatedResult.data);
    };

    const addClients = (items) => {
        clients.value = clients.value.concat(items.map((item) => new MinimalClientDTO(item)));
    };

    const resetDataForClients = () => {
        clients.value = [];
        resetData();
    };

    return {
        page,
        totalPages,
        isLoading,
        clients,
        fetchClients,
        resetDataForClients,
        addClients
    };
}
