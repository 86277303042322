// Position constants

import { Align } from 'cte-video-studio';

export const VERTICAL_POSITIONS = [
    Align.DISTRIBUTED_DEFAULT,
    Align.DISTRIBUTED_TOP,
    Align.TOP,
    Align.HALF_TOP,
    Align.CENTER,
    Align.HALF_BOTTOM,
    Align.BOTTOM,
    Align.DISTRIBUTED_BOTTOM
];

export const HORIZONTAL_POSITIONS = [
    Align.DISTRIBUTED_DEFAULT,
    Align.DISTRIBUTED_LEFT,
    Align.LEFT,
    Align.HALF_LEFT,
    Align.CENTER,
    Align.HALF_RIGHT,
    Align.RIGHT,
    Align.DISTRIBUTED_RIGHT
];

export const PANEL_VERTICAL_POSITIONS = [Align.TOP, Align.HALF_TOP, Align.CENTER, Align.HALF_BOTTOM, Align.BOTTOM];

export const PANEL_HORIZONTAL_POSITIONS = [Align.LEFT, Align.HALF_LEFT, Align.CENTER, Align.HALF_RIGHT, Align.RIGHT];

export const LOGO_VERTICAL_POSITIONS = [Align.TOP, Align.HALF_TOP, Align.CENTER, Align.HALF_BOTTOM, Align.BOTTOM];

export const LOGO_HORIZONTAL_POSITIONS = [Align.LEFT, Align.HALF_LEFT, Align.CENTER, Align.HALF_RIGHT, Align.RIGHT];

export const CAPTIONS_VERTICAL_POSITIONS = [Align.TOP, Align.HALF_TOP, Align.CENTER, Align.HALF_BOTTOM, Align.BOTTOM];

export const CAPTIONS_HORIZONTAL_POSITIONS = [Align.LEFT, Align.CENTER, Align.RIGHT];

export const ZOOM_TARGETS = [
    Align.TOP_LEFT,
    Align.TOP,
    Align.TOP_RIGHT,
    Align.HALF_TOP_LEFT,
    Align.HALF_TOP,
    Align.HALF_TOP_RIGHT,
    Align.LEFT,
    Align.HALF_LEFT,
    Align.CENTER,
    Align.HALF_RIGHT,
    Align.RIGHT,
    Align.HALF_BOTTOM_LEFT,
    Align.HALF_BOTTOM,
    Align.HALF_BOTTOM_RIGHT,
    Align.BOTTOM_LEFT,
    Align.BOTTOM,
    Align.BOTTOM_RIGHT
];
