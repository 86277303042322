<template>
    <div class="nav-child-items-list" :class="{ opened: opened }">
        <slot />
    </div>
</template>

<script setup>
import { computed, inject, provide } from 'vue';

const props = defineProps({
    parentName: ''
});

const { openedNavItem } = inject('openedNavItem');

provide('navSubListName', props.parentName);

const opened = computed(() => {
    return openedNavItem.value === props.parentName;
});
</script>
