<template>
    <dropdown v-model="clientShortname" class="basic-dropdown">
        <template #dropdown-selected-value>
            <span v-if="selectedClient">{{ selectedClient.name }}</span>
        </template>
        <UiTextInput
            id="client-selector-search"
            type="search"
            :placeholder="$t('Search')"
            :show-label="false"
            icon="search-icon"
            v-model="searchFilter"
        />
        <ui-infinite-scroll-paginator v-model="page" :max-page="totalPages" :loading="isLoading">
            <dropdown-item v-for="client in clients" :key="client.id" :value="client.shortname">
                <span class="dropdown-list-item-label">{{ client.name }}</span>
            </dropdown-item>
            <template v-slot:loading>
                <LoadingMarker v-if="isLoading" />
            </template>
        </ui-infinite-scroll-paginator>
    </dropdown>
</template>

<script setup>
import { computed, defineModel, onMounted, ref, watch } from 'vue';
import UiTextInput from '@/js/components/UiTextInput.vue';
import UiInfiniteScrollPaginator from '@/js/components/UiInfiniteScrollPaginator.vue';
import DropdownItem from '@/js/components/dropdown/DropdownItem.vue';
import Dropdown from '@/js/components/dropdown/Dropdown.vue';
import LoadingMarker from '@/js/components/LoadingMarker.vue';
import { debounce } from 'lodash';
import { useClients } from '@/js/composables/useClients.js';

const clientShortname = defineModel();

const { page, totalPages, isLoading, clients, fetchClients, resetDataForClients } = useClients();

const selectedClient = ref(null);
const searchFilter = ref('');

const options = computed(() => ({
    page: page.value,
    search: searchFilter.value
}));

const debouncedSearch = debounce(() => {
    resetDataForClients();
    fetchClients(options.value);
}, 500);

const selectClient = (shortname) => {
    selectedClient.value = clients.value.find((client) => client.shortname === shortname);
};

watch(searchFilter, () => {
    debouncedSearch();
});

watch(page, () => {
    fetchClients(options.value);
});

watch(clients, () => {
    if (clients.value.length) {
        if (!clientShortname.value) {
            clientShortname.value = clients.value[0].shortname;
        }
        if (!selectedClient.value && clientShortname.value) {
            selectClient(clientShortname.value);
        }
    }
});

watch(clientShortname, (newValue) => {
    selectClient(newValue);
});

onMounted(() => {
    fetchClients(options.value);
});
</script>
