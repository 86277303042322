import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { STATUS_UNSAVED } from '../../constants';
import * as UiComponents from '../../components';
import {
    UI_MEDIA_TYPE_IMAGE,
    UI_MEDIA_SELECTOR_SHOW,
    UI_MEDIA_SELECTOR_CHANGE
} from '../../components/UiMediaSelector.vue';

export default {
    components: {
        ...UiComponents
    },

    props: {
        id: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            mediaTypeImage: UI_MEDIA_TYPE_IMAGE,
            mediaSelectorShowEvent: UI_MEDIA_SELECTOR_SHOW,
            mediaSelectorChangeEvent: UI_MEDIA_SELECTOR_CHANGE
        };
    },

    computed: {
        ...mapState({
            language: (state) => state.ui.language,
            shortLanguage: (state) => state.ui.shortLanguage,
            readOnly: (state) => state.ui.readOnly,
            prefixes: (state) => state.ui.prefixes,
            libraryTagOrders: (state) => state.branding.libraries.tagOrders,
            state: (state) => state.branding
        }),

        ...mapGetters({
            needsSave: 'ui/needsSave',
            findLibraryItemById: 'branding/libraries/findLibraryItemById'
        })
    },

    methods: {
        ...mapMutations({
            setCurrentMediaLibrary: 'ui/setCurrentMediaLibrary',
            setSaveStatus: 'ui/setSaveStatus',
            setLibraryItemStatus: 'branding/libraries/setLibraryItemStatus'
        }),

        ...mapActions({
            saveBrand: 'ui/saveBrand'
        }),

        modifyBrand(actions) {
            // this.setSaveStatus(STATUS_UNSAVED);
            actions();
        },

        openMediaLibrary(selector) {
            this.setCurrentMediaLibrary({
                mediaType: selector.type,
                selectedMediaId: selector.media.id,
                selector
            });
        },

        closeMediaLibrary() {
            //
        }
    }
};
