<template>
    <ui-card :id="cardElementId" :class="'ui-card-' + id" :title="$t('Transition')" :added-card-id="addedCardId">
        <template #header-icon>
            <svg-icon icon="transition-icon" />
        </template>
        <template #menu-content>
            <ui-dropdown-item :disabled="!canCopyCard" @click.prevent="copyCard">
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-copy" />
                    {{ $t('Copy') }}
                </template>
            </ui-dropdown-item>

            <ui-dropdown-item :disabled="!canPasteCard" @click.prevent="pasteCard">
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-clipboard" />
                    {{ $t('Paste') }}
                </template>
            </ui-dropdown-item>

            <ui-dropdown-item
                v-if="isEmotionMode"
                classes="ui-remove-item"
                :disabled="!canRemoveTransition"
                @click.prevent="removeCard"
            >
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-trash-can" />
                    {{ $t('Remove') }}
                </template>
            </ui-dropdown-item>
        </template>

        <div v-if="isEmotionMode" class="sequence-transition-type ui-card-row">
            <ui-library-selector
                ref="$transitionSelector"
                :id="cardElementId + '-type'"
                v-model="transitionType"
                :default-value="transitionDefaultType"
                library="transitions"
                preview-path="transitions/"
                :label="$t('Choose a transition')"
                :disabled="readOnly"
                icon="fa-solid fa-sparkles"
                @[librarySelectorShowEvent]="openLibrary"
                @[librarySelectorPreviewChangeEvent]="previewTransitionType"
            />
        </div>

        <div v-if="transitionUses.includes('scene')" class="sequence-transition-scene ui-card-row">
            <ui-switch-button
                :id="cardElementId + '-scene'"
                :label="$t('Add scene background')"
                v-model="enableTransitionScene"
                :disabled="readOnly"
            />
        </div>

        <div v-if="transitionUses.some((use) => /^color\./.test(use))" class="sequence-transition-colors ui-card-row">
            <ui-color-selector
                v-if="transitionUses.includes('color.start')"
                :id="cardElementId + '-color-start'"
                key="color-selector-transition-color-start"
                class="ui-card-row"
                v-model:color="transitionColorStart"
                default-color="color1"
                :palette="brandPalette"
                :enable-other-colors="brandEnableOtherColors"
                :label="sprintf($t('Color %1$d'), 1)"
                :disabled="readOnly"
            />
            <ui-color-selector
                v-if="transitionUses.includes('color.second')"
                :id="cardElementId + '-color-second'"
                key="color-selector-transition-color-second"
                class="ui-card-row"
                v-model:color="transitionColorSecond"
                default-color="color2"
                :palette="brandPalette"
                :enable-other-colors="brandEnableOtherColors"
                :label="sprintf($t('Color %1$d'), 2)"
                :disabled="readOnly"
            />
            <ui-color-selector
                v-if="transitionUses.includes('color.third')"
                :id="cardElementId + '-color-third'"
                key="color-selector-transition-color-third"
                class="ui-card-row"
                v-model:color="transitionColorThird"
                default-color="color2"
                :palette="brandPalette"
                :enable-other-colors="brandEnableOtherColors"
                :label="sprintf($t('Color %1$d'), 3)"
                :disabled="readOnly"
            />
        </div>

        <div v-if="transitionUses.includes('image')" class="sequence-transition-image ui-card-row">
            <ui-media-selector
                :id="cardElementId + '-image'"
                key="media-selector-transition-image"
                v-model:media="transitionImage"
                :type="mediaTypeImage"
                :label="$t('Image')"
                :showLabel="true"
                :disabled="readOnly"
                icon="fa-solid fa-image"
                @[mediaSelectorShowEvent]="openMediaLibrary"
            />
        </div>

        <div v-if="transitionUses.includes('image.color')" class="sequence-transition-image-color ui-card-row">
            <ui-color-selector
                :id="cardElementId + '-image-color'"
                key="color-selector-transition-image-color"
                class="ui-card-row"
                v-model:color="transitionImageColor"
                :default-color="colorEmpty"
                :enable-empty="true"
                :palette="brandPalette"
                :enable-other-colors="brandEnableOtherColors"
                :label="$t('Image color')"
                :disabled="readOnly"
            />
        </div>
    </ui-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Color, Transition, STAGE_LOADING_ENDED_EVENT } from 'cte-video-studio';
import { TRANSITION_ELEMENT_ID } from '../../../../../constants';
import { SequenceCard } from '../../../../mixins';
import { getPrefixedUuid } from '../../../../../utils';
import { printf as sprintf } from 'fast-printf';

export default {
    mixins: [SequenceCard],

    inject: ['$videoStudio'],

    components: {
        //
    },

    props: {
        id: {
            type: String,
            default: TRANSITION_ELEMENT_ID
        },

        addedCardId: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            transitionDefaultType: Transition.DEFAULT,
            colorEmpty: Color.NONE
        };
    },

    computed: {
        ...mapState({
            //
        }),

        ...mapGetters({
            findLibraryItem: 'branding/libraries/findLibraryItem'
        }),

        transitionType: {
            get() {
                return this.sequenceState.transition.type;
            },
            set(type) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.sequenceStoreModulePath + '/setTransition', type);
                });
            }
        },

        transitionMetadata() {
            if (!this.transitionType || this.transitionType == this.transitionDefaultType) return null;
            return this.findLibraryItem('transitions', '', this.transitionType) || null;
        },

        transitionUses() {
            return (this.transitionMetadata && this.transitionMetadata.uses) || [];
        },

        enableTransitionScene: {
            get() {
                return this.sequenceState.transition.scene;
            },
            set(value) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.sequenceStoreModulePath + '/enableTransitionScene', value);
                });
            }
        },

        transitionColorStart: {
            get() {
                let color = { ref: Color.NONE, value: Color.NONE };

                if (this.transitionUses.includes('color.start')) {
                    color.ref = this.sequenceState.transition.color.start__ref;
                    color.value = this.sequenceState.transition.color.start;
                }

                return color;
            },
            set({ ref, value }) {
                this.saveHistoryStep(() => {
                    this.$store.commit(
                        this.sequenceStoreModulePath + '/setTransitionColor',
                        Object.assign({}, this.sequenceState.transition.color, { start__ref: ref, start: value })
                    );
                });
            }
        },

        transitionColorSecond: {
            get() {
                let color = { ref: Color.NONE, value: Color.NONE };

                if (this.transitionUses.includes('color.second')) {
                    color.ref = this.sequenceState.transition.color.second__ref;
                    color.value = this.sequenceState.transition.color.second;
                }

                return color;
            },
            set({ ref, value }) {
                this.saveHistoryStep(() => {
                    this.$store.commit(
                        this.sequenceStoreModulePath + '/setTransitionColor',
                        Object.assign({}, this.sequenceState.transition.color, { second__ref: ref, second: value })
                    );
                });
            }
        },

        transitionColorThird: {
            get() {
                let color = { ref: Color.NONE, value: Color.NONE };

                if (this.transitionUses.includes('color.third')) {
                    color.ref = this.sequenceState.transition.color.third__ref;
                    color.value = this.sequenceState.transition.color.third;
                }

                return color;
            },
            set({ ref, value }) {
                this.saveHistoryStep(() => {
                    this.$store.commit(
                        this.sequenceStoreModulePath + '/setTransitionColor',
                        Object.assign({}, this.sequenceState.transition.color, { third__ref: ref, third: value })
                    );
                });
            }
        },

        transitionImage: {
            get() {
                let ref = { src: '', id: '' };

                if (this.transitionUses.includes('image')) {
                    ref.src = this.sequenceState.transition.image.src;
                    ref.id = this.sequenceState.transition.image.src__id;
                }

                return ref;
            },
            set({ src, id }) {
                this.saveHistoryStep(() => {
                    if (!this.sequenceState.transition.image.src__ref) {
                        this.$store.commit(
                            this.sequenceStoreModulePath + '/setTransitionImageReference',
                            getPrefixedUuid(this.prefixes.mediaReference)
                        );
                    }
                    this.$store.dispatch(this.sequenceStoreModulePath + '/updateTransitionImage', { src, src__id: id });
                });
            }
        },

        transitionImageColor: {
            get() {
                let color = { ref: Color.NONE, value: Color.NONE };

                if (this.transitionUses.includes('image.color')) {
                    color.ref = this.sequenceState.transition.image.color__ref;
                    color.value = this.sequenceState.transition.image.color;
                }

                return color;
            },
            set({ ref, value }) {
                this.saveHistoryStep(() => {
                    this.$store.commit(
                        this.sequenceStoreModulePath + '/setTransitionImageColor',
                        Object.assign({}, this.sequenceState.transition.image, { color__ref: ref, color: value })
                    );
                });
            }
        }
    },

    watch: {
        addedCardId(val) {
            if (this.cardElementId === val) this.openLibrary(this.$refs.$transitionSelector);
        }
    },

    methods: {
        sprintf,

        removeCard() {
            this.saveHistoryStep(() => {
                this.$store.dispatch(this.sequenceStoreModulePath + '/showTransitionCard', false);
            });
        },

        previewTransitionType(type, cancel) {
            this.ignoreHistoryStep(() => {
                this.$store.commit(this.sequenceStoreModulePath + '/setTransition', type);
                if (!cancel)
                    this.$videoStudio.studio.$stage.$el.addEventListener(
                        STAGE_LOADING_ENDED_EVENT,
                        this.playTransitionTypePreview,
                        { once: true }
                    );
            });
        },

        playTransitionTypePreview() {
            this.playPreview(0.001);
        }
    },

    mounted() {
        if (!this.sequenceState.transition.cardShown) {
            this.$store.dispatch(this.sequenceStoreModulePath + '/showTransitionCard', true);
        }
    }
};
</script>
