<template>
    <li role="option" :aria-disabled="disabled || null">
        <component
            :is="itemTag"
            v-bind="itemAttributes"
            :class="itemClasses"
            role="none"
            @click.prevent="emitItemChangeEvent"
            @keydown.space="emitItemChangeEvent"
        >
            <slot>
                {{ item.name }}
            </slot>
            <span v-show="itemIsActive" class="selected-icon">
                <fa-icon class="icon" icon="fa-solid fa-check" />
            </span>
        </component>
        <button
            v-if="hideable"
            class="hidden-toggle"
            :disabled="disabled"
            :aria-pressed="!itemIsHidden || null"
            @click.prevent="handleHideToggle"
        >
            <span class="visually-hidden">
                {{
                    sprintf(
                        $t('Set %1$s activation status to %2$s'),
                        item.name,
                        $t(
                            'brands.status.' + (!itemIsHidden ? libraryStatusHidden : libraryStatusActive) + '.label'
                        ).toLowerCase()
                    )
                }}
            </span>
            <fa-icon v-if="!itemIsHidden" icon="fa-solid fa-eye" class="icon visible-icon" />
            <fa-icon v-else icon="fa-solid fa-eye-slash" class="icon hidden-icon" />
        </button>
    </li>
</template>

<script>
import { LIBRARY_STATUS_HIDDEN, LIBRARY_STATUS_ACTIVABLE, LIBRARY_STATUS_ACTIVE } from '../../../../constants';
import { UiIcon } from '../../../../components';
import { printf as sprintf } from 'fast-printf';

export const UI_DROPDOWN_LIBRARY_ITEM_CHANGE = 'ui-dropdown-library-item-change';

export default {
    components: {
        UiIcon
    },

    inject: ['dropdown'],

    emits: [UI_DROPDOWN_LIBRARY_ITEM_CHANGE],

    props: {
        item: {
            type: Object,
            default: () => ({})
        },
        hideable: {
            type: Boolean,
            default: false
        },
        activable: {
            type: Boolean,
            default: true
        },
        classes: {
            type: [String, Array, Object],
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            libraryStatusHidden: LIBRARY_STATUS_HIDDEN,
            libraryStatusActivable: LIBRARY_STATUS_ACTIVABLE,
            libraryStatusActive: LIBRARY_STATUS_ACTIVE

            // selected: false,
        };
    },

    computed: {
        itemTag() {
            return this.disabled || (this.activable && this.itemIsHidden) ? 'span' : 'a';
        },

        itemClasses() {
            return [
                'ui-dropdown-item',
                'ui-dropdown-library-item',
                'dropdown-item',
                {
                    disabled: this.disabled || this.itemIsHidden,
                    selected: this.itemIsActive
                },
                this.classes
            ];
        },

        itemAttributes() {
            let attrs = { ...this.$attrs };

            if (!this.disabled) attrs.href = attrs.href || '#';
            else delete attrs.href;

            return attrs;
        },

        allowedItemStatus() {
            let status = [];
            if (this.hideable) status.push(LIBRARY_STATUS_HIDDEN);
            if (this.activable) status.push(LIBRARY_STATUS_ACTIVABLE);
            status.push(LIBRARY_STATUS_ACTIVE);

            return status;
        },

        nextItemStatus() {
            return this.allowedItemStatus.reduce((next, status, index) => {
                next[status] =
                    this.allowedItemStatus[(index + this.allowedItemStatus.length - 1) % this.allowedItemStatus.length];
                return next;
            }, {});
        },

        itemIsHidden() {
            return this.item.status == LIBRARY_STATUS_HIDDEN;
        },

        itemIsActivable() {
            return this.item.status == LIBRARY_STATUS_ACTIVABLE;
        },

        itemIsActive() {
            return this.item.status == LIBRARY_STATUS_ACTIVE;
        }
    },

    watch: {
        //
    },

    methods: {
        sprintf,

        emitItemChangeEvent(event) {
            event.preventDefault();

            if (this.disabled || (this.activable && this.itemIsHidden)) return;

            let newStatus = !this.itemIsActive
                ? LIBRARY_STATUS_ACTIVE
                : this.activable
                  ? LIBRARY_STATUS_ACTIVABLE
                  : LIBRARY_STATUS_HIDDEN;

            this.$emit(UI_DROPDOWN_LIBRARY_ITEM_CHANGE, newStatus, this.item);
            if (newStatus == LIBRARY_STATUS_ACTIVABLE && event.type != 'keydown') event.currentTarget.blur();
        },

        handleHideToggle(event) {
            if (!this.disabled && this.hideable) {
                let newStatus = this.itemIsHidden ? LIBRARY_STATUS_ACTIVE : LIBRARY_STATUS_HIDDEN;
                this.$emit(UI_DROPDOWN_LIBRARY_ITEM_CHANGE, newStatus, this.item);
            }
        }
    },

    mounted() {
        //
    }
};
</script>
