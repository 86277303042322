import { mapState, mapGetters } from 'vuex';
import { STATUS_AUTH_ERROR } from '../constants';

export default {
    data() {
        return {
            postMessageTarget: null,
            postMessageOrigin: ''
        };
    },

    computed: {
        ...mapState({
            postMessageAllowedOrigins: (state) => state.ui.postMessageAllowedOrigins
        }),

        ...mapGetters({
            isPostMessageAllowedOrigin: 'ui/isPostMessageAllowedOrigin'
        })
    },

    methods: {
        startPostMessageListening() {
            window.addEventListener('message', this.processReceivedMessageEvent);
        },

        endPostMessageListening() {
            window.removeEventListener('message', this.processReceivedMessageEvent);
            this.postMessageTarget = null;
        },

        parsePostMessageOrigin(url) {
            return !!url ? new URL(url).origin : '';
        },

        postMessageEvent(type, message, data, target = null, origin = '') {
            let messageData = data || {};
            messageData.type = type;
            messageData.message = message;

            let targetWindow = target || this.postMessageTarget.contentWindow;

            if (!!targetWindow) {
                targetWindow.postMessage(messageData, origin || this.postMessageOrigin);
            }
        },

        processReceivedMessageEvent(event) {
            if (!this.isPostMessageAllowedOrigin(event.origin) || event.source != this.postMessageTarget.contentWindow)
                return;

            let { type, message, ...data } = event.data;

            if (type == 'error') {
                if (message == 'expired_session' && !!data.token) {
                    this.$store.dispatch('ui/modals/openRefreshLoginModal', { token: data.token });
                } else if (!!data.redirect) {
                    this.$store.commit('ui/setAuthError', { error: message, ...data });
                    window.location.assign(data.redirect);
                } else {
                    this.$store.commit('ui/setSaveError', { error: message, ...data });
                }
            }
            this.receiveMessageEvent(event.data, event);
        },

        receiveMessageEvent(data, event) {
            //
        }
    }
};
