import { getPrefixedUuid } from '@/js/utils';
import { Align, Dimension, TTS, Visual } from '@/js/video-studio/constants';

export default class SetRecordingElementService {
    constructor(store) {
        this.store = store;
        this.mediaReferencePrefix = this.store.state.ui.prefixes.mediaReference;
    }

    createScreenRecordingElement(sequenceId, variationUrl, variationId) {
        const visualId = getPrefixedUuid(Visual.PREFIX_ID);
        this.store.dispatch(`sequences/${sequenceId}/addEmptyVisual`, { id: visualId });
        this.setScreenRecordingVisual(sequenceId, visualId, variationUrl, variationId);
    }

    setScreenRecordingVisual(sequenceId, visualId, variationUrl, variationId) {
        const elementStorePath = `sequences/${sequenceId}/${visualId}`;

        this.setRecordingVisual(sequenceId, visualId, Visual.RECORDING_CATEGORY, variationUrl, variationId);

        this.store.commit(`${elementStorePath}/setWidth`, {
            value: Dimension.CUSTOM,
            custom: `100${Dimension.PERCENT_UNIT}`
        });
        this.store.commit(`${elementStorePath}/enableHeight`, true);
        this.store.commit(`${elementStorePath}/setHeight`, {
            value: Dimension.CUSTOM,
            custom: `100${Dimension.PERCENT_UNIT}`
        });
        this.store.commit(`${elementStorePath}/setPosition`, {
            alignH: Align.CENTER,
            alignV: Align.CENTER,
            alignX: Dimension.ZERO,
            alignY: Dimension.ZERO
        });
    }

    createCameraRecordingElement(sequenceId, variationUrl, variationId) {
        const visualId = getPrefixedUuid(Visual.PREFIX_ID);
        this.store.dispatch(`sequences/${sequenceId}/addEmptyVisual`, { id: visualId });
        this.setCameraRecordingVisual(sequenceId, visualId, variationUrl, variationId);
    }

    setCameraRecordingVisual(sequenceId, visualId, variationUrl, variationId) {
        const elementStorePath = `sequences/${sequenceId}/${visualId}`;

        this.setRecordingVisual(sequenceId, visualId, Visual.RECORDING_CATEGORY, variationUrl, variationId);

        this.store.commit(`${elementStorePath}/setWidth`, {
            value: Dimension.CUSTOM,
            custom: `30${Dimension.PERCENT_UNIT}`
        });
        this.store.commit(`${elementStorePath}/enableHeight`, false);
        this.store.commit(`${elementStorePath}/setHeight`, { value: Dimension.AUTO, custom: null });
        this.store.commit(`${elementStorePath}/setPosition`, {
            alignH: Align.HALF_RIGHT,
            alignV: Align.HALF_BOTTOM,
            alignX: Dimension.ZERO,
            alignY: Dimension.ZERO
        });
    }

    setRecordingVisual(sequenceId, visualId, category, variationUrl, variationId) {
        const sequenceStorePath = `sequences/${sequenceId}`;
        const elementStorePath = `${sequenceStorePath}/${visualId}`;

        // TODO: Create a generic application service to deal with this?
        this.store.commit(`${elementStorePath}/setAnimationCategory`, category);
        if (!this.store.state.sequences[sequenceId][visualId].recording.src__ref) {
            this.store.commit(`${elementStorePath}/recording/setSourceRef`, getPrefixedUuid(this.mediaReferencePrefix));
        }
        this.store.dispatch(`${elementStorePath}/updateRecording`, { src: variationUrl, src__id: variationId });
    }

    setMicrophoneRecordingAudio(sequenceId, variationUrl, variationId) {
        const sequenceStorePath = `sequences/${sequenceId}`;

        this.store.commit(`${sequenceStorePath}/tts/setCategory`, TTS.RECORDING_CATEGORY);
        if (!this.store.state.sequences[sequenceId].tts.recording.src__ref) {
            this.store.commit(
                `${sequenceStorePath}/tts/recording/setSourceRef`,
                getPrefixedUuid(this.mediaReferencePrefix)
            );
        }
        this.store.commit(`${sequenceStorePath}/tts/recording/setSource`, variationUrl);
        this.store.commit(`${sequenceStorePath}/tts/recording/setSourceId`, variationId);
    }
}
