/**
 * UI accordions Vuex store
 */

const state = {
    list: []
};

const getters = {
    indexOf: (state) => (id) => state.list.findIndex((accordion) => accordion.id == id),

    find: (state, getters) => (id) => {
        let index = getters.indexOf(id);
        return index !== -1 ? state.list[index] : null;
    }
};

const mutations = {
    add(state, accordion) {
        state.list.push(accordion);
    },

    remove(state, id) {
        let index = state.list.findIndex((accordion) => accordion.id === id);
        if (index !== -1) state.list.splice(index, 1);
    },

    setItemActive(state, data) {
        let index = state.list.findIndex((accordion) => accordion.id === data.id);
        if (index !== -1) state.list[index].itemActive = data.itemActive;
    }
};

const actions = {
    //
};

export default {
    namespaced: true,

    modules: {
        //
    },

    state,
    getters,
    mutations,
    actions
};
