import { subscribeToChannel } from '@/js/utils.js';
import { VIDEO_CHANNEL, VIDEO_PRODUCTION_UPDATED, VIDEO_VALIDATION_UPDATED } from '@/js/constants/websockets.js';
import UpdateCurrentVideoDataService from '@/js/application/services/video/UpdateCurrentVideoDataService.js';

export default class SubscribeVideoChannelService {
    constructor(store) {
        this.store = store;
    }

    handle(videoId) {
        const store = this.store;
        subscribeToChannel(VIDEO_CHANNEL, { id: videoId }, 'private', {
            [VIDEO_PRODUCTION_UPDATED]: (event) => {
               new UpdateCurrentVideoDataService(store).handle(videoId);
            },
            [VIDEO_VALIDATION_UPDATED]: function (event) {
                // TODO: validation workflow
            }
        });
    }
}
