<script setup>
import { defineAsyncComponent, h } from 'vue';
import { nanoid } from 'nanoid';

const props = defineProps({
    icon: {
        type: String,
        required: true
    }
});

const randomizeIds = (svgContent) => {
    let uniqueId = nanoid();
    return svgContent
        .replace(/id="([^"]+)"/g, (match, id) => {
            return `id="${id}-${uniqueId}"`;
        })
        .replace(/url\(#([^"]+)\)/g, (match, id) => {
            return `url(#${id}-${uniqueId})`;
        });
};

const iconComponent = defineAsyncComponent({
    loader: () =>
        import(`@/assets/svg/${props.icon}.svg?raw`)
            .then((svgContent) => {
                return {
                    render() {
                        return h('div', { innerHTML: randomizeIds(svgContent.default) });
                    }
                };
            })
            .catch((error) => {
                console.warn(`Failed to load icon: ${props.icon}`, error);
                return {
                    render() {
                        return h('div', { class: 'icon-placeholder' }, '');
                    }
                };
            })
});
</script>

<template>
    <component :is="iconComponent" fill="currentColor" class="icon icon-id-randomized" />
</template>

<style scoped>
.icon-id-randomized {
    display: inline-block;
}
</style>
