<!--
  -- Component name: Folding1
  -- Type: Transition
  -- Uses: color.start
  -- Tags: folding
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        v-if="landscapeFormat"
        :class="classes"
        :id="this.containerID"
        viewBox="0 0 1920 1080"
        preserveAspectRatio="xMidYMid slice"
    >
        <defs>
            <clipPath :id="svgMaskID" clipPathUnits="objectBoundingBox" :style="landscapeClipPathTransform">
                <polygon class="polygon" fill="#fff" points="2566.67,0 0,4405.7 0,0" />
            </clipPath>
        </defs>
        <polygon class="polygon" :fill="state.color.start" points="2566.67,0 3808.62,2202.85 0,4405.7" />
    </svg>
    <svg
        class="studio-sequence-transition studio-container"
        v-else-if="portraitFormat"
        :class="classes"
        :id="this.containerID"
        viewBox="0 0 1080 1920"
        preserveAspectRatio="xMidYMid slice"
    >
        <defs>
            <clipPath :id="svgMaskID" clipPathUnits="objectBoundingBox" :style="portraitClipPathTransform">
                <polygon class="polygon" fill="#fff" points="0,2597.98 4418,0 0,0" />
            </clipPath>
        </defs>
        <polygon class="polygon" :fill="state.color.start" points="0,2597.98 2209,3802.99 4418,0" />
    </svg>
    <svg
        class="studio-sequence-transition studio-container"
        v-else-if="squareFormat"
        :class="classes"
        :id="this.containerID"
        viewBox="0 0 1000 1000"
        preserveAspectRatio="xMidYMid slice"
    >
        <defs>
            <clipPath :id="svgMaskID" clipPathUnits="objectBoundingBox" :style="squareClipPathTransform">
                <polygon class="polygon" fill="#fff" points="0,1619.47 2754,0 0,0" />
            </clipPath>
        </defs>
        <polygon class="polygon" :fill="state.color.start" points="0,1619.47 1377,2370.63 2754,0" />
    </svg>
</template>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            needsRefresh: true, // The transition needs Chrome and Safari to force a re-rendering during the animation
            keepActive: true
            // isAbove: false, // The transition component is placed above the in and out elements
            // swapElements: true, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {
        svgMaskID() {
            return this.containerID + '-folding1-mask';
        },

        svgMask() {
            return 'url(#' + this.svgMaskID + ')';
        },

        landscapeFormat() {
            return this.format.width > this.format.height;
        },

        portraitFormat() {
            return this.format.width < this.format.height;
        },

        squareFormat() {
            return this.format.width === this.format.height;
        },

        landscapeClipPathTransform() {
            return this.format && this.getClipPathTransform(1920, 1080);
        },

        portraitClipPathTransform() {
            return this.format && this.getClipPathTransform(1080, 1920);
        },

        squareClipPathTransform() {
            return this.format && this.getClipPathTransform(1000, 1000);
        }
    },

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            t.set(this, { inClipPathProxy: this.svgMask }, 0.0001);
            t.fromTo(
                '#' + this.containerID + ' .polygon',
                { scale: 0.01 },
                { scale: 1, duration: 1, ease: 'power2.in' }
            );
            return t;
        }
    }
};
</script>
