import VideoTtsApiService from '@/js/infrastructure/api/tts/VideoTtsApiService';
import VideoSaveHistoryStepService from './VideoSaveHistoryStepService';
import { TTS_STATUS_ERROR, TTS_STATUS_COMPLETED } from '../../../constants';

const STATUS_REQUEST_DELAY = 5000;
const STATUS_DISPLAY_DURATION = 5000;

export default class {
    constructor(store) {
        this.store = store;
        this.apiService = new VideoTtsApiService(store);
        this.videoSaveHistoryStepService = new VideoSaveHistoryStepService(store);
    }

    startTask(storeModulePath, voiceId, text, videoId, exaggeration) {
        this.reset(storeModulePath);
        this.startProcessing(storeModulePath);

        this.apiService
            .createTask(voiceId, text, videoId, String(exaggeration / 200))
            .then((response) => {
                this.checkTaskResult(storeModulePath, response.data.id);
            })
            .catch((error) => {
                this.handleError(storeModulePath, error.response?.data.error);
            });
    }

    checkTaskResult(storeModulePath, taskId) {
        setTimeout(() => {
            this.apiService
                .getTaskResult(taskId)
                .then((response) => {
                    if (response.data.completed) this.handleSuccess(storeModulePath, response.data);
                    else if (response.data.error) this.handleError(storeModulePath);
                    else this.checkTaskResult(storeModulePath, taskId);
                })
                .catch((error) => {
                    this.handleError(storeModulePath, error.response?.data.error);
                });
        }, STATUS_REQUEST_DELAY);
    }

    reset(storeModulePath) {
        this.store.dispatch(storeModulePath + '/voice/resetVoice');
    }

    startProcessing(storeModulePath) {
        this.store.dispatch(storeModulePath + '/voice/setVoiceProcessing');
    }

    stopProcessing(storeModulePath) {
        this.store.dispatch(storeModulePath + '/voice/stopVoiceProcessing');
    }

    handleSuccess(storeModulePath, result) {
        this.stopProcessing(storeModulePath);
        this.videoSaveHistoryStepService.handle(() => {
            this.store.dispatch(storeModulePath + '/handleVoiceResult', result);
        });
        this.setTemporaryStatus(storeModulePath, TTS_STATUS_COMPLETED);
        this.store.dispatch('ui/setTtsSuccessStatus', true);
    }

    handleError(storeModulePath, error = TTS_STATUS_ERROR) {
        this.stopProcessing(storeModulePath);
        this.videoSaveHistoryStepService.handle(() => {
            this.store.dispatch(storeModulePath + '/voice/handleVoiceError', error);
        });
        this.store.dispatch('ui/setTtsSuccessStatus', error);
    }

    setTemporaryStatus(storeModulePath, status) {
        this.store.dispatch(storeModulePath + '/setStatus', status);
        setTimeout(() => {
            this.store.dispatch(storeModulePath + '/setStatus', null);
        }, STATUS_DISPLAY_DURATION);
    }
}
