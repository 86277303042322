import { fixWebmDuration } from '@fix-webm-duration/fix';
import { RECORDING_INPUT_DEVICE_VIDEO, RECORDING_INPUT_DEVICE_AUDIO } from '@/js/constants';

class WebRTCService {
    async getUserInputDevices(audioOnly = false) {
        if (!navigator.mediaDevices?.enumerateDevices) return {};

        let videoStream, audioStream;
        let videoDevicesError = false,
            audioDevicesError = false;

        // Separate try/catch blocks to allow listing when user
        // has not given permission for only one type of devices
        if (!audioOnly) {
            try {
                videoStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
            } catch (error) {
                reportError(error);
                videoDevicesError = true;
            }
        }

        try {
            audioStream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
        } catch (error) {
            reportError(error);
            audioDevicesError = true;
        }

        try {
            const devices = await navigator.mediaDevices?.enumerateDevices();
            const inputDevices = {
                [RECORDING_INPUT_DEVICE_VIDEO]: !videoDevicesError
                    ? parseInputDevices(devices, RECORDING_INPUT_DEVICE_VIDEO)
                    : [],
                [RECORDING_INPUT_DEVICE_AUDIO]: !audioDevicesError
                    ? parseInputDevices(devices, RECORDING_INPUT_DEVICE_AUDIO)
                    : []
            };

            this.stopStream(videoStream);
            this.stopStream(audioStream);

            return inputDevices;
        } catch (error) {
            reportError(error);

            this.stopStream(videoStream);
            this.stopStream(audioStream);

            return {};
        }
    }

    async getUserStream(videoDeviceId = '', audioDeviceId = '') {
        const config = {
            video: !!videoDeviceId && { deviceId: videoDeviceId },
            audio: !!audioDeviceId && { deviceId: audioDeviceId }
        };

        try {
            return await navigator.mediaDevices.getUserMedia(config);
        } catch (error) {
            reportError(error);
            return null;
        }
    }

    async getDisplayStream(audioDeviceId = '') {
        try {
            const stream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
                audio: false,
                displaySurface: 'browser',
                selfBrowserSurface: 'exclude',
                monitorTypeSurface: 'include',
                preferCurrentTab: false
            });

            if (audioDeviceId) {
                const audioStream = await this.getUserStream('', audioDeviceId);
                const [audioTrack] = audioStream.getAudioTracks();
                stream.addTrack(audioTrack);

                // If the user clicks on the browser's default
                // stop capture dialog, we need to stop manually
                // the audio track to stop the entire stream
                const [screenTrack] = stream.getVideoTracks();
                screenTrack.onended = () => audioTrack.stop();
            }

            return stream;
        } catch (error) {
            reportError(error);
            return null;
        }
    }

    stopStream(stream) {
        if (stream?.active) stream.getTracks().forEach((track) => track.stop());
    }

    async getDisplayRecorder(audioDeviceId = '', callback, errorCallback = null) {
        const stream = await this.getDisplayStream(audioDeviceId);

        return stream ? this.createRecorder(stream, 'video/webm', callback, errorCallback) : null;
    }

    async getUserRecorder(videoDeviceId = '', audioDeviceId = '', callback, errorCallback = null) {
        const stream = await this.getUserStream(videoDeviceId, audioDeviceId);

        return stream
            ? this.createRecorder(
                  stream,
                  stream.getVideoTracks().length ? 'video/webm' : 'audio/webm',
                  callback,
                  errorCallback
              )
            : null;
    }

    createRecorder(stream, mimeType, callback, errorCallback = null) {
        const recorder = new MediaRecorder(stream);
        const chunks = [];
        let startTime;

        recorder.onstart = (event) => {
            startTime = Date.now();
        };

        recorder.ondataavailable = (event) => {
            if (event.data.size > 0) chunks.push(event.data);
        };

        recorder.onstop = async (event) => {
            const duration = Date.now() - startTime;
            const blob = new Blob(chunks, { type: mimeType });

            callback(await fixWebmDuration(blob, duration), recorder);
        };

        recorder.onerror = (event) => {
            reportError(event.error);
            if (errorCallback) errorCallback(event.error, recorder);
        };

        return recorder;
    }

    stopRecorder(recorder) {
        this.stopStream(recorder.stream);
    }
}

const parseInputDevices = (devices, deviceKind) => {
    let defaultDevice = null,
        defaultDeviceIndex = -1;

    return devices
        .filter((device) => device.deviceId && device.kind == deviceKind + 'input')
        .reduce((list, device) => {
            if (device.groupId == defaultDevice?.groupId) {
                // Note: For Chromium browsers the OS default device
                // is listed twice, so we detect it and replace its label
                list[defaultDeviceIndex].label = device.label;
                return list;
            }

            if (device.deviceId == 'default') {
                defaultDevice = device;
                defaultDeviceIndex = list.length;

                // Note: For Chromium browsers the OS default device
                // is listed twice, so we detect the duplication
                // and replace its deviceId
                const duplicatedDeviceIndex = list.findIndex(
                    (parsedDevice) => parsedDevice.groupId == defaultDevice.groupId
                );
                if (duplicatedDeviceIndex != -1) {
                    list[duplicatedDeviceIndex].value = device.deviceId;
                    return list;
                }
            }

            list.push({
                label: device.label,
                value: device.deviceId,
                groupId: device.groupId
            });

            return list;
        }, []);
};

const reportError = (error) => console.error(`[WebRTCService] ${error.name}: ${error.message}`);

export default WebRTCService;
