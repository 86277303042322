export default class StoreProfileDataService {
    constructor(store) {
        this.store = store;
    }

    storeUserData(data) {
        this.store.dispatch('auth/setUserData', data);
    }

    storeUserLatestVideosData(data) {
        this.store.dispatch('auth/setUserLatestVideosData', data);
    }
}
