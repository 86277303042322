<template>
  <div class="hover-bar-container" :style="{ left: `${position}px` }">
    <CurrentTimeDisplay
      :current-time="hoverTime"
      position="center"
      :x="x"
      :y="y"
      :rate="rate"
    />
    <div v-if="!isDragging" class="hover-bar"></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import CurrentTimeDisplay from './CurrentTimeDisplay.vue';

export default defineComponent({
  name: 'HoverBar',
  components: {
    CurrentTimeDisplay
  },
  props: {
    position: {
      type: Number,
      required: true
    },
    hoverTime: {
      type: Number,
      required: true
    },
    x: {
      type: Number,
      required: true
    },
    y: {
      type: Number,
      required: true
    },
    rate: {
      type: Number,
      required: true
    },
    isDragging: {
      type: Boolean,
      required: true
    }
  }
});
</script>
<style scoped>
.hover-bar-container {
    position: absolute;
    top: 0;
    height: 100%;
    pointer-events: none;
    z-index: 15;
}

.hover-timecode {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
}

.hover-bar {
    width: 1px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
}
</style>