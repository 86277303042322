<template>
    <ui-dropdown-item :disabled="!canCopyCard" @click.prevent="copyCard">
        <template #dropdown-menu-item-label="{ instance }">
            <fa-icon class="icon" icon="fa-regular fa-copy" />
            {{ $t('Copy') }}
        </template>
    </ui-dropdown-item>
</template>

<script>
import { useStore } from 'vuex';
import { defineComponent, computed } from 'vue';
import UiIcon from '../../../../../../components/UiIcon.vue';
import UiDropdownItem from '../../../../../../components/UiDropdownItem.vue';
import { useSequence } from '../../../../../composables/useSequence';

export default defineComponent({
    name: 'UiBackgroundCopySelector',
    components: {
        UiIcon,
        UiDropdownItem
    },
    props: {
        id: String,
        seqId: String
    },
    setup(props) {
        const store = useStore();
        const { sequenceStoreModulePath } = useSequence(props.seqId);

        const canCopyCard = computed(() => {
            return store.getters['ui/canCopyCard'](props.id);
        });

        const copyCard = () => {
            if (canCopyCard.value) {
                store.dispatch(sequenceStoreModulePath.value + '/copyCardToClipboard', props.id);
            }
        };

        return {
            canCopyCard,
            copyCard
        };
    }
});
</script>
