<template>
    <div class="ui-media-library" :class="libraryClasses" @[transitionEndEvent]="handleAnimationEnd">
        <div class="ui-media-library-inner">
            <header class="ui-media-library-header" v-if="showHeader">
                <h5 class="ui-media-library-title">{{ $t('Media library') }}</h5>
                <button class="ui-media-library-close ui-action-button" @click.prevent="cancel">
                    <span class="visually-hidden">{{ $t('Close') }}</span>
                    <fa-icon class="icon" icon="fa-solid fa-xmark" />
                </button>
            </header>
            <iframe
                ref="$libraryFrame"
                class="ui-media-library-frame"
                :class="{ ready: libraryFrameIsReady, 'gen-ai-tab': isGenImageAi }"
                :src="libraryFrameUrl"
            />
            <ui-image-generation v-if="isGenImageAi" ref="$imageGeneration" @select-media="selectMedia" />
            <footer class="ui-media-library-footer">
                <button
                    v-if="!addNewMediaHidden"
                    class="ui-simple-button ui-simple-button-select"
                    :disabled="disabled || addNewMediaDisabled"
                    @click.prevent="addNewMedia"
                >
                    <span>{{ $t('Add a new media') }}</span>
                </button>
                <button
                    v-if="showCancelButton"
                    class="ui-simple-button ui-simple-button-cancel"
                    @click.prevent="cancel"
                >
                    <span>{{ $t('Cancel') }}</span>
                </button>

                <button
                    v-if="isGenImageAi"
                    class="ui-simple-button ui-simple-button-cancel"
                    @click.prevent="clearGenImageHistory"
                >
                    <span>{{ $t('image-generation.clear-history') }}</span>
                </button>
            </footer>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { UsesPostMessage } from '../mixins';
import { UI_MEDIA_SELECTOR_HIDE } from './UiMediaSelector.vue';
import { COMMON_LIBRARY_ID, GEN_IMAGE_AI_ID } from '../constants/index.js';
import UiImageGeneration from '@/js/components/image-generation/UiImageGeneration.vue';

export default {
    components: { UiImageGeneration },
    mixins: [UsesPostMessage],

    inject: ['$videoStudio'],

    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        animation: {
            type: Boolean,
            default: true
        },
        showHeader: {
            type: Boolean,
            default: false
        },
        showCancelButton: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            show: false,
            addNewMediaHidden: true,
            addNewMediaDisabled: false,
            libraryFrameIsReady: false,
            currentSelectedMediaId: null,
            isGenImageAi: false
        };
    },

    computed: {
        ...mapState({
            mediaType: (state) => state.ui.currentMediaLibrary.mediaType,
            selectedMediaId: (state) => state.ui.currentMediaLibrary.selectedMediaId,
            selector: (state) => state.ui.currentMediaLibrary.selector
        }),

        ...mapGetters({
            mediaLibraryUrl: 'ui/mediaLibraryUrl',
            mediaCreateUrl: 'ui/mediaCreateUrl'
        }),

        libraryFrameUrl() {
            return !!this.mediaType ? this.mediaLibraryUrl(this.mediaType, this.currentSelectedMediaId) : '';
        },

        libraryClasses() {
            return {
                show: this.show,
                'with-animation': this.animation
            };
        },

        transitionEndEvent() {
            return this.animation && !this.show ? 'transitionend' : null;
        }
    },

    watch: {
        mediaType(newValue) {
            if (!!newValue) {
                this.postMessageTarget = this.$refs.$libraryFrame;
                this.startPostMessageListening();
                this.show = true;
            }
        },

        libraryFrameUrl(newValue) {
            this.postMessageOrigin = this.parsePostMessageOrigin(newValue);
        },

        selector(newValue) {
            if (!!newValue) this.selector.$el.addEventListener(UI_MEDIA_SELECTOR_HIDE, this.close, { once: true });
        },

        selectedMediaId(newValue) {
            // Note: Update currentSelectedMediaId only when show
            // is true, to avoid a useless refresh of the iframe
            // when the library is closing after a media is selected
            if (this.show) this.currentSelectedMediaId = newValue;
        }
    },

    methods: {
        ...mapMutations({
            addModal: 'ui/modals/add'
        }),

        select() {
            this.selector.select();
        },

        openMediaDetails(url) {
            this.addModal({ id: 'media-details', type: 'ui-media-modal', url, caller: this, libraryModal: this });
        },

        openExternalMedia(url) {
            this.addModal({
                id: 'external-media-details',
                type: 'ui-media-modal',
                url,
                caller: this,
                libraryModal: this
            });
        },

        selectMedia(mediaId) {
            this.$store.commit('ui/setCurrentMediaLibrarySelectedMedia', mediaId);
            this.select();
        },

        disableAddNewMedia(disable = true) {
            this.addNewMediaDisabled = disable;
        },

        addNewMedia() {
            this.addModal({
                id: 'media-create',
                type: 'ui-media-modal',
                url: this.mediaCreateUrl(this.mediaType),
                caller: this,
                libraryModal: this
            });
        },

        cancel() {
            this.selector.cancel();
        },

        close() {
            this.endPostMessageListening();
            this.show = false;
            if (!this.animation) this.emptyLibrary();
            this.isGenImageAi = false;
        },

        emptyLibrary() {
            this.$store.commit('ui/setCurrentMediaLibrary', { mediaType: null, selectedMediaId: null, selector: null });
            this.addNewMediaHidden = false;
            this.libraryFrameIsReady = false;
            this.currentSelectedMediaId = null;
        },

        handleAnimationEnd(event) {
            if (event.target == this.$el && event.propertyName == 'opacity') {
                this.emptyLibrary();
            }
        },

        receiveMessageEvent(data, event) {
            // console.log('UiMediaLibrary receiveMessageEvent', data);
            if (data.type == 'error') {
                this.close();
                return;
            }

            switch (data.message) {
                case 'ready':
                    this.libraryFrameIsReady = true;
                    break;
                case 'media-bank-switched':
                    this.addNewMediaHidden = [COMMON_LIBRARY_ID, GEN_IMAGE_AI_ID].includes(data.library);
                    this.isGenImageAi = data.library === GEN_IMAGE_AI_ID;
                    break;
                case 'show-medium':
                    this.$videoStudio?.studio.$stage.pauseTimeline();
                    this.openMediaDetails(data.url);
                    break;
                case 'show-medium-external':
                    this.$videoStudio?.studio.$stage.pauseTimeline();
                    this.openExternalMedia(data.url);
                    break;
                case 'select-medium':
                    this.selectMedia(data.id);
                    break;
                case 'rename-tag':
                    this.addModal({ id: 'rename-tag', type: 'ui-media-modal', url: data.url, libraryModal: this });
                    break;
            }
        },

        clearGenImageHistory() {
            this.$refs.$imageGeneration.handleClearHistory();
        }
    },

    mounted() {
        //
    }
};
</script>
