<template>
    <button
        class="ui-simple-button ui-simple-button-run-action"
        :class="buttonClass"
        :disabled="readOnly"
        @[clickEvent]="$emit('startTTS')"
        v-tooltip="tooltipMessage"
    >
        <span v-if="displayCompletedStatus" class="ui-tts-status-success">
            <fa-icon class="icon" icon="fa-solid fa-check" />
            {{ $t('AI Voice is ready') }}
        </span>
        <span v-else-if="displayLoaderStatus">
            <svg-icon icon="dots-loader" />
        </span>
        <span v-else>
            {{ $t('Generate AI Voice') }}
        </span>
        <fa-icon v-if="locked" icon="fa-solid fa-lock" class="icon right-centered" />
    </button>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import { UsesTooltip } from '../../../../../../../mixins';
import { UiIcon } from '../../../../../../../components';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    mixins: [UsesTooltip],

    components: {
        UiIcon
    },

    props: {
        isProcessing: {
            type: Boolean,
            default: false
        },
        status: {
            type: String,
            default: ''
        },
        upToDate: {
            type: Boolean,
            default: false
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    setup(props) {
        const store = useStore();
        const { t } = useI18n();

        const isSupervisor = computed(() => {
            return store.state.ui.isSupervisor;
        });

        const locked = computed(() => {
            return !store.state.ui.restrictions.enableTTS;
        });

        const displayCompletedStatus = computed(() => {
            return props.upToDate && !props.isProcessing && !locked.value;
        });

        const displayLoaderStatus = computed(() => {
            return props.isProcessing;
        });

        const buttonClass = computed(() => {
            return {
                active: !displayCompletedStatus.value && props.isProcessing,
                disabled: props.disabled || displayCompletedStatus.value,
                locked: locked.value
            };
        });

        const clickEvent = computed(() => {
            return locked.value ? null : 'click';
        });

        const tooltipMessage = computed(() => {
            if (locked.value && isSupervisor.value)
                return t('Please activate this function in your subscription area.');
            else if (locked.value) return t('Please contact your supervisor to activate this feature.');

            return null;
        });

        return {
            clickEvent,
            tooltipMessage,
            locked,
            buttonClass,
            displayCompletedStatus,
            displayLoaderStatus
        };
    }
});
</script>
