<template>
    <div class="ui-modal-container" :class="containerClasses">
        <component
            v-for="(modal, index) in modals"
            :is="modal.type"
            v-bind="getModalProps(modal)"
            :key="'ui-modal-' + modal.id"
            :id="modal.id"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { ProvidesIcons } from '../mixins';
import * as UiModalComponents from './modals';
import * as UiBrandModalComponents from '../brands/components/ui/modals';
import * as UiVideoModalComponents from '../videos/components/ui/modals';

export default {
    mixins: [ProvidesIcons],

    components: {
        ...UiModalComponents,
        ...UiBrandModalComponents,
        ...UiVideoModalComponents
    },

    props: {
        //
    },

    data() {
        return {
            //
        };
    },

    computed: {
        ...mapState({
            modals: (state) => state.ui.modals.list
        }),

        containerClasses() {
            return {
                active: !!this.modals.length
            };
        }
    },

    watch: {
        //
    },

    methods: {
        getModalProps(data) {
            let { id, type, on, ...props } = data;
            return props;
        }
    },

    mounted() {
        //
    }
};
</script>
