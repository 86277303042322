<template>
    <div class="ui-main-modal-container" v-if="opened">
        <div class="ui-main-modal-overlay" @click="close()" />
        <div class="ui-main-modal">
            <dynamic-modal-loader />
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import DynamicModalLoader from '@/js/components/modals/DynamicModalLoader.vue';

const store = useStore();
const opened = computed(() => store.getters['modals/opened']);

const close = () => {
    store.dispatch('modals/closeModal');
};
</script>
