<template>
    <div ref="containerRef" class="ui-video-preview" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
        <img
            :src="currentThumbnail"
            class="static-image-preview"
            :class="{ 'default-preview': isDefaultThumbnail, hidden: isVideoPlaying }"
            alt="Video thumbnail"
            loading="lazy"
            @error="handleStaticImageError"
        />
        <video
            v-if="isInViewport"
            ref="videoElement"
            :src="video.thumbnails.videoSrc"
            class="video-preview"
            :class="{ hidden: !isVideoPlaying }"
            loop
            muted
            preload="none"
            @loadedmetadata="onVideoLoaded"
            @error="onVideoError"
        ></video>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, onUnmounted } from 'vue';
import VideoDTO from '@/js/dto/VideoDTO.js';
import TemplateDTO from '@/js/dto/TemplateDTO.js';
import format from '@/js/video-studio/constants/format.js';
import previewLandscape from '@/assets/images/preview-landscape.png';
import previewSquare from '@/assets/images/preview-square.png';
import previewVertical from '@/assets/images/preview-vertical.png';

const props = defineProps({
    video: {
        type: [VideoDTO, TemplateDTO],
        required: true
    },
    debugMode: {
        type: Boolean,
        default: false
    }
});

const containerRef = ref(null);
const videoElement = ref(null);
const isVideoPlaying = ref(false);
const videoLoaded = ref(false);
const isInViewport = ref(false);
const useDefaultThumbnail = ref(false);
let playTimeout = null;
let stopTimeout = null;
let observer = null;

const getDefaultThumbnail = computed(() => {
    const ratio = props.video.format.width / props.video.format.height;
    switch (ratio) {
        case format.RATIO_16X9:
            return previewLandscape;
        case format.RATIO_1X1:
            return previewSquare;
        case format.RATIO_9X16:
            return previewVertical;
        default:
            return previewSquare;
    }
});

const currentThumbnail = computed(() => {
    return useDefaultThumbnail.value
        ? getDefaultThumbnail.value
        : props.video.thumbnails.staticSrc || getDefaultThumbnail.value;
});

const isDefaultThumbnail = computed(() => {
    return useDefaultThumbnail.value || !props.video.thumbnails.staticSrc;
});

const loadAndPlayVideo = async () => {
    if (!isInViewport.value) return;

    if (props.debugMode) console.log('Tentative de chargement et de lecture de la vidéo');
    if (videoElement.value && !videoLoaded.value) {
        try {
            videoElement.value.preload = 'auto';
            await videoElement.value.load();
            videoLoaded.value = true;
            if (props.debugMode) console.log('Vidéo chargée avec succès');
        } catch (error) {
            console.error('Erreur lors du chargement de la vidéo:', error);
            return;
        }
    }

    if (videoElement.value && videoLoaded.value) {
        try {
            await videoElement.value.play();
            isVideoPlaying.value = true;
            if (props.debugMode) console.log('Lecture de la vidéo démarrée');
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error('Erreur lors de la lecture de la vidéo:', error);
                isVideoPlaying.value = false;
            }
        }
    }
};

const stopVideo = () => {
    if (videoElement.value) {
        videoElement.value.pause();
        isVideoPlaying.value = false;
        if (props.debugMode) console.log('Vidéo mise en pause');
    }
};

const handleMouseEnter = () => {
    clearTimeout(stopTimeout);
    clearTimeout(playTimeout);
    playTimeout = setTimeout(loadAndPlayVideo, 100);
};

const handleMouseLeave = () => {
    clearTimeout(playTimeout);
    clearTimeout(stopTimeout);
    stopTimeout = setTimeout(stopVideo, 100);
};

const onVideoLoaded = () => {
    if (props.debugMode) console.log('Métadonnées de la vidéo chargées');
    videoLoaded.value = true;
};

const onVideoError = (e) => {
    console.error('Erreur de chargement de la vidéo:', e);
    isVideoPlaying.value = false;
};

const handleStaticImageError = () => {
    useDefaultThumbnail.value = true;
    if (props.debugMode) console.log("Erreur de chargement de l'image statique, utilisation de l'image par défaut");
};

const setupIntersectionObserver = () => {
    observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                isInViewport.value = entry.isIntersecting;
                if (props.debugMode) console.log('Component in viewport:', isInViewport.value);
            });
        },
        {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        }
    );

    if (containerRef.value) {
        observer.observe(containerRef.value);
    }
};

onMounted(() => {
    setupIntersectionObserver();
    if (props.debugMode) console.log('Composant monté, URL de la vidéo:', props.video.thumbnails.videoSrc);
});

watch(
    () => props.video.thumbnails.videoSrc,
    (newSrc) => {
        if (props.debugMode) console.log('URL de la vidéo mise à jour:', newSrc);
        if (newSrc) {
            videoLoaded.value = false;
            useDefaultThumbnail.value = false;
        }
    }
);

onUnmounted(() => {
    clearTimeout(playTimeout);
    clearTimeout(stopTimeout);
    if (observer) {
        observer.disconnect();
    }
});
</script>

<style scoped>
.ui-video-preview {
    position: relative;
    width: 100%;
    height: 100%;
}

.static-image-preview,
.video-preview {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
}

.video-preview {
    position: absolute;
    top: 0;
    left: 0;
}

.hidden {
    display: none;
}
</style>
    