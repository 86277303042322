const studioScaleCSSVar = '--preview-studio-scale';

export default class StudioShowPreviewManager {
    constructor(store) {
        this.store = store;
    }

    initPreviewResize() {
        const previewContainer = document.querySelector('.preview-studio-wrapper');
        const previewHeader = document.querySelector('.preview-studio-header');

        const containerWidth = previewContainer.offsetWidth,
            containerHeight = previewContainer.offsetHeight,
            containerRatio = containerWidth / containerHeight,
            stageRatio = this.store.state.display.format.width / this.store.state.display.format.height,
            studioScale =
                containerRatio <= stageRatio
                    ? containerWidth / this.store.state.display.format.width
                    : containerHeight / this.store.state.display.format.height;

        window.$studio.proxy.setContainerScale(studioScale, true);
        previewHeader?.style.setProperty(studioScaleCSSVar, studioScale);
    }
}
