<!--
  -- Component name: Glitch2
  -- Type: Transition
  -- Uses: 
  -- Tags: tv
  -->
<template>
    <div class="studio-sequence-transition studio-container" :class="classes" :id="this.containerID">
        <AssetSpritesheet
            ref="$transitionSpritesheet"
            name="$transitionSpritesheet"
            :src="spritesheetURL"
            width="100%"
            height="100%"
            :cover="true"
            grid="6x5"
            :frames="25"
            :fps="30"
            @update="update($event)"
        />
    </div>
</template>

<script>
import TransitionMixin from '../../mixins/Transition';
import AssetSpritesheet from '../assets/AssetSpritesheet.vue';
import glitch2 from '@resources/assets/transitions/Glitch2.png';
import gsap from 'gsap';

export default {
    components: {
        AssetSpritesheet
    },

    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            needsRefresh: true, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true, // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
            spritesheetURL: glitch2
        };
    },

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            t.add(this.$refs.$transitionSpritesheet.timeline());
            t.set(this.$refs.$transitionSpritesheet.$el, { opacity: 0.85 }, 0);
            t.fromTo(
                this.$refs.$transitionSpritesheet.$el,
                { opacity: 0.85 },
                { duration: 0.2, opacity: 0, ease: 'power1.out' },
                '-=0.2'
            );
            return t;
        }
    }
};
</script>
