import { useStore } from 'vuex';
import { computed } from 'vue';
import { useSequence } from './useSequence';
import { useHistory } from './useHistory';

export function useCard(sequenceId, cardId) {
    const store = useStore();
    const { saveHistoryStep } = useHistory();
    const { sequenceStoreModulePath } = useSequence(sequenceId);

    const canCopyCard = computed(() => store.getters['ui/canCopyCard'](cardId));
    const canPasteCard = computed(() => {
        return store.getters['ui/canPasteCard'](cardId)
    });
    const canRemoveCard = computed(() => store.getters['ui/canRemoveCard']);

    const copyCard = () => {
        if (canCopyCard.value) {
            store.dispatch(sequenceStoreModulePath.value + '/copyCardToClipboard', cardId);
        }
    };

    const pasteCard = () => {
        if (canPasteCard.value) {
            $studio.$stage.pauseTimeline();
            saveHistoryStep(() => {
                store.dispatch(sequenceStoreModulePath.value + '/pasteCard', cardId);
            });
        }
    };

    return {
        canCopyCard,
        canPasteCard,
        canRemoveCard,
        copyCard,
        pasteCard
    };
}
