import { ref, computed } from 'vue';
import FetchTemplatesDataService from '@/js/application/services/templates/FetchTemplatesDataService.js';
import FetchTemplateCategoriesService from '@/js/application/services/templates/FetchTemplateCategoriesService.js';
import usePagination from '@/js/composables/usePagination';

export function useTemplates(store) {
    const templates = ref([]);
    const isLoading = ref(false);
    const isFirstLoad = ref(true);
    const templateCategories = ref([]);
    let abortController = null;
    let lastFilters = null;
    let currentRequestId = 0;

    const { page, perPage, totalPages, setPerPage, setTotalPages } = usePagination();

    const templatesLoading = computed(() => {
        return isLoading.value || (isFirstLoad.value && templates.value.length === 0);
    });

    const fetchTemplates = async (brandId, options) => {
        if (brandId) {
            const filtersCopy = { ...options };
            delete filtersCopy.page;
            delete filtersCopy.per_page;

            const filtersChanged = JSON.stringify(filtersCopy) !== JSON.stringify(lastFilters);

            if (filtersChanged) {
                if (abortController) {
                    abortController.abort();
                }
                templates.value = [];
                page.value = 1;
                lastFilters = JSON.parse(JSON.stringify(filtersCopy));
            }

            const requestId = ++currentRequestId;
            abortController = new AbortController();
            isLoading.value = true;

            try {
                const templatesResponse = await new FetchTemplatesDataService(store).fetchTemplates(
                    brandId,
                    options,
                    abortController.signal
                );

                if (requestId === currentRequestId) {
                    if (filtersChanged) {
                        templates.value = templatesResponse.templates;
                    } else {
                        templates.value = templates.value.concat(templatesResponse.templates);
                    }

                    setPerPage(templatesResponse.perPage);
                    setTotalPages(templatesResponse.lastPage);
                    isFirstLoad.value = false;
                }
            } catch (error) {
                if (error.name === 'AbortError') {
                    console.log('Fetch aborted');
                } else {
                    console.error('Error fetching templates:', error);
                }
            } finally {
                if (requestId === currentRequestId) {
                    isLoading.value = false;
                }
            }
        }
    };

    const fetchTemplateCategories = async (brandId) => {
        try {
            const response = await new FetchTemplateCategoriesService(store).handle(brandId);
            templateCategories.value = ['all', ...response.available_categories.map((category) => category.name)];
        } catch (error) {
            console.error('Error fetching template categories:', error);
        }
    };

    const resetDataForTemplates = () => {
        templates.value = [];
        page.value = 1;
        totalPages.value = 0;
        isFirstLoad.value = true;
    };

    return {
        templateCategories,
        fetchTemplateCategories,
        fetchTemplates,
        page,
        perPage,
        resetDataForTemplates,
        templates,
        templatesLoading,
        totalPages
    };
}
