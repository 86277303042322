export default class StudioShareInitializer {
    constructor(videoStudioService) {
        this.videoStudioService = videoStudioService;
    }

    async fetchData(videoId, isSequenceTemplate = false, isTemplate = false, brandId = null) {
        let response;

        try {
            if (isSequenceTemplate) {
                response = await this.videoStudioService.getSequenceTemplateShareResource(brandId, videoId);
            } else if (isTemplate) {
                response = await this.videoStudioService.getTemplateShareResource(brandId, videoId);
            } else {
                response = await this.videoStudioService.getVideoShareResource(videoId);
            }
        } catch (error) {
            return { errorCode: error.response?.status || 500 };
        }

        return response.data;
    }
}
