import './sass/vendor.scss';
import './sass/fonts.scss';
import './sass/app.scss';

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue3Tour from 'vue3-tour';
import store from '@/libs/vuex.js';
import i18n from '@/libs/i18n.js';
import initIcons from '@/libs/fontAwesome.js';
import { initSentry } from '@/libs/sentry.js';
import SvgIcon from '@/js/components/SvgIcon.vue';
import SvgIdRandomizedIcon from '@/js/components/SvgIdRandomizedIcon.vue';
import { initGoogleAnalytics } from '@/libs/googleAnalytics.js';

const app = createApp(App);

initIcons();

app.use(router);
app.use(store);
app.use(Vue3Tour);
app.use(i18n);

app.component('fa-icon', FontAwesomeIcon);
app.component('svg-icon', SvgIcon);
app.component('svg-id-randomized-icon', SvgIdRandomizedIcon);

app.mount('#app');

initSentry(app);
initGoogleAnalytics(app);
