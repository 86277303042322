import { ref } from 'vue';
import { useStore } from 'vuex';
import ClientDataService from '@/js/application/services/client/ClientDataService.js';
import UserDTO from '@/js/dto/UserDTO.js';
import FolderAllowableUserDTO from '@/js/dto/FolderAllowableUserDTO.js';
import usePagination from '@/js/composables/usePagination.js';
import FoldersDataService from '@/js/application/services/folders/FoldersDataService.js';

export function useUsers(clientId, folderShortname) {
    const store = useStore();

    const users = ref([]);

    const { page, totalPages, isLoading, paginationRequest, resetData } = usePagination();

    const fetchClientUsers = async (options) => {
        let paginatedResult = await paginationRequest(() =>
            new ClientDataService(store).fetchClientUsers(clientId.value, options)
        );
        if (paginatedResult) addUsers(paginatedResult.data);
    };

    const fetchClientAllowableUsers = async (options) => {
        let paginatedResult = await paginationRequest(() =>
            new ClientDataService(store).fetchClientUsers(clientId.value, options)
        );
        if (paginatedResult) addClientAllowableUsers(paginatedResult.data);
    };

    const fetchFolderUsers = async (options) => {
        let paginatedResult = await paginationRequest(() =>
            new FoldersDataService(store).fetchFolderUsers(folderShortname, options)
        );
        if (paginatedResult) addFolderUsers(paginatedResult.data);
    };

    const addUsers = (items) => {
        users.value = users.value.concat(items.map((item) => new UserDTO(item)));
    };

    const addFolderUsers = (items) => {
        users.value = users.value.concat(items.map((item) => new FolderAllowableUserDTO(item)));
    };

    const addClientAllowableUsers = (items) => {
        users.value = users.value.concat(
            items.map(
                (item) =>
                    new FolderAllowableUserDTO({
                        ...item,
                        is_allowed: true
                    })
            )
        );
    };

    const resetDataForUsers = () => {
        users.value = [];
        resetData();
    };

    return {
        page,
        totalPages,
        isLoading,
        users,
        fetchClientUsers,
        fetchClientAllowableUsers,
        fetchFolderUsers,
        addUsers,
        addFolderUsers,
        addClientAllowableUsers,
        resetDataForUsers
    };
}
