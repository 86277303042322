<!--
  -- Component name: Store
  -- Type: Transition
  -- Uses: color.start
  -- Tags: color
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <rect class="store-left" x="0%" y="0%" width="7%" height="100%" :fill="state.color.start"></rect>
        <rect class="store-left" x="7%" y="0%" width="7%" height="100%" :fill="state.color.start"></rect>
        <rect class="store-left" x="14%" y="0%" width="7%" height="100%" :fill="state.color.start"></rect>
        <rect class="store-left" x="21%" y="0%" width="7%" height="100%" :fill="state.color.start"></rect>
        <rect class="store-left" x="28%" y="0%" width="7%" height="100%" :fill="state.color.start"></rect>
        <rect class="store-left" x="35%" y="0%" width="7%" height="100%" :fill="state.color.start"></rect>
        <rect class="store-left" x="42%" y="0%" width="7%" height="100%" :fill="state.color.start"></rect>
        <rect class="store-right" x="49%" y="0%" width="7%" height="100%" :fill="state.color.start"></rect>
        <rect class="store-right" x="56%" y="0%" width="7%" height="100%" :fill="state.color.start"></rect>
        <rect class="store-right" x="63%" y="0%" width="7%" height="100%" :fill="state.color.start"></rect>
        <rect class="store-right" x="70%" y="0%" width="7%" height="100%" :fill="state.color.start"></rect>
        <rect class="store-right" x="77%" y="0%" width="7%" height="100%" :fill="state.color.start"></rect>
        <rect class="store-right" x="84%" y="0%" width="7%" height="100%" :fill="state.color.start"></rect>
        <rect class="store-right" x="91%" y="0%" width="7%" height="100%" :fill="state.color.start"></rect>
        <rect class="store-right" x="98%" y="0%" width="7%" height="100%" :fill="state.color.start"></rect>
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            isAbove: true
        };
    },

    computed: {},

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 }, 0.001);
            t.fromTo(
                '#' + this.containerID + ' .store-left',
                { scaleX: 1 },
                { duration: 0.3, scaleX: 0, transformOrigin: '100% 50%' },
                0.2
            );
            t.fromTo(
                '#' + this.containerID + ' .store-right',
                { attr: { width: '7%' } },
                { duration: 0.3, attr: { width: '0%' } },
                0.2
            );
            return t;
        }
    }
};
</script>
