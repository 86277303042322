/**
 * Video Studio
 * Overlay constants
 */

const NONE = 'None';

const files = import.meta.glob('../components/overlays/*.vue');
const overlays = {};
Object.keys(files).forEach((key) => {
    key = key
        .split('/')
        .pop()
        .replace(/(\.\/|\.vue)/g, '');
    let parts = key.match(/([A-Z\d]+(?:[a-z]+|$))/g);
    overlays[parts.join('_').toUpperCase()] = key;
});

const Overlay = {
    PREFIX_CLASS: 'Overlay',

    ASSET_RATIO_PLACEHOLDER: '%%RATIO%%',

    ANIMATION_ZOOM_SCALE_DEFAULT: 1.3,

    DEFAULT: NONE,

    NONE,

    ...overlays
};

export default Overlay;
