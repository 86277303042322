/**
 * Video Studio
 * Format constants
 */

const WIDTH_DEFAULT = 1280;
const HEIGHT_DEFAULT = 720;

const RATIO_16X9 = 16 / 9;
const RATIO_1X1 = 1;
const RATIO_9X16 = 9 / 16;
const RATIO_4X3 = 4 / 3;
const RATIOS = [RATIO_16X9, RATIO_1X1, RATIO_9X16, RATIO_4X3];

const RATIO_16X9_STRING = '16x9';
const RATIO_1X1_STRING = '1x1';
const RATIO_9X16_STRING = '9x16';
const RATIO_4X3_STRING = '4x3';
const RATIOS_STRING = [RATIO_16X9_STRING, RATIO_1X1_STRING, RATIO_9X16_STRING, RATIO_4X3_STRING];

const Format = {
    WIDTH_DEFAULT,
    HEIGHT_DEFAULT,

    RATIO_16X9,
    RATIO_1X1,
    RATIO_9X16,
    RATIO_4X3,
    RATIO_DEFAULT: RATIO_16X9,
    RATIOS,

    RATIO_16X9_STRING,
    RATIO_1X1_STRING,
    RATIO_9X16_STRING,
    RATIO_4X3_STRING,
    RATIO_STRING_DEFAULT: RATIO_16X9_STRING,
    RATIOS_STRING
};

export default Format;
