<template>
    <footer class="ui-save-banner" :class="bannerClasses">
        <p class="banner-message">
            {{ $t('By saving these changes, they will be applied to all videos using this brand guidelines.') }}
        </p>
        <button
            class="ui-simple-button ui-simple-button-select ui-save-button ui-loading-button"
            :class="saveButtonClasses"
            :disabled="isSaving"
            @click.prevent="handleSave"
        >
            <span class="ui-save-button-inner">{{ $t('Save') }}</span>
            <svg-icon class="loader" icon="dots-loader" />
        </button>
    </footer>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { ProvidesIcons } from '../../../mixins';
import { UiIcon } from '../../../components';

export default {
    mixins: [ProvidesIcons],

    components: {
        UiIcon
    },

    data() {
        return {
            //
        };
    },

    computed: {
        ...mapState({
            readOnly: (state) => state.ui.readOnly,
            saveStatus: (state) => state.ui.saveStatus
        }),

        ...mapGetters({
            needsSave: 'ui/needsSave',
            isSaving: 'ui/isSaving',
            isRefreshingToken: 'ui/isRefreshingToken',
            mediaLibraryIsShown: 'ui/mediaLibraryIsShown'
        }),

        bannerClasses() {
            return {
                show: this.needsSave && !this.mediaLibraryIsShown
            };
        },

        saveButtonClasses() {
            return {
                loading: this.isSaving || this.isRefreshingToken
            };
        }
    },

    watch: {
        //
    },

    methods: {
        ...mapActions({
            saveBrand: 'ui/saveBrand'
        }),

        handleSave() {
            if (!this.isSaving || this.isRefreshingToken) {
                this.saveBrand();
            }
        }
    },

    mounted() {
        //
    }
};
</script>
