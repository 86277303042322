<template>
    <div class="studio-sequence-overlay studio-container" :id="id" :style="styles">
        <svg class="studio-hidden-svg">
            <defs>
                <filter :id="overlayColorFilterId">
                    <feFlood :flood-color="colorStart" />
                    <feComposite operator="in" in2="SourceAlpha" />
                </filter>
            </defs>
        </svg>
        <div class="studio-sequence-overlay-inner studio-container" :id="overlayInnerId" :style="overlayStyles">
            <component ref="$overlay" :is="component" :id="id" :seqId="seqId" :resolvedSeqId="resolvedSeqId" />
        </div>
    </div>
</template>

<script>
import { Overlay } from '../constants';
import gsap from 'gsap';
import { mapState } from 'vuex';
import { defineAsyncComponent } from 'vue';

export default {
    inject: ['$stage'],

    props: {
        id: String,
        seqId: String,
        resolvedSeqId: String
    },

    data() {
        return {
            //
        };
    },

    computed: {
        ...mapState({
            state(state) {
                return state.sequences[this.resolvedSeqId].background.overlay;
            }
        }),

        isPrevious() {
            return this.$store.getters['sequences/' + this.seqId + '/hasPreviousBackground'];
        },

        component() {
            const componentName = Overlay.PREFIX_CLASS + this.state.type;
            return defineAsyncComponent(() => import(`./overlays/${componentName}.vue`));
        },

        colorStart() {
            return this.$store.getters['sequences/' + this.resolvedSeqId + '/backgroundOverlayColorStart'];
        },

        styles() {
            return {
                backgroundColor: this.state.type == Overlay.NONE ? this.colorStart : null,
                opacity: this.state.opacity
            };
        },

        overlayStyles() {
            return {
                filter: this.state.type != Overlay.NONE ? 'url(#' + this.overlayColorFilterId + ')' : null
            };
        },

        overlayInnerId() {
            return this.id + '-inner';
        },

        overlayColorFilterId() {
            return this.id + '-color-filter';
        }
    },

    watch: {
        'state.type': {
            handler() {
                if (!this.isPrevious) this.$nextTick(this.$stage.requestTimelineUpdate);
            }
        },

        'state.animation.enabled': {
            handler(newValue) {
                if (!this.isPrevious) this.$stage.requestTimelineUpdate();
            }
        }
    },

    methods: {
        timeline() {
            return this.$refs.$overlay && this.state.animation.enabled
                ? this.$refs.$overlay.timeline()
                : gsap.timeline();
        },

        releaseTimeline() {
            gsap.set('#' + this.overlayInnerId, { clearProps: 'opacity,visibility,scale' });
        }
    },

    created() {
        //
    },

    mounted() {
        //
    }
};
</script>
