<template>
    <splash-screen v-if="loading" />
    <div v-else-if="!errorCode" class="container-fluid h-100">
        <div class="row h-100">
            <main class="content col-12 p-0" :class="{ skeleton: !isReady }">
                <div class="share-preview h-100">
                    <div id="studio-share" class="preview-layout" :style="{ '--preview-studio-width': playerWidth }">
                        <div class="preview-studio-header">
                            <h1 class="preview-name">{{ videoName }}</h1>
                            <a
                                class="ui-simple-button ui-simple-button-purple preview-download-button"
                                :href="videoDownloadUrl"
                            >
                                <fa-icon class="icon" icon="fa-regular fa-arrow-down-to-line" />
                                <span>{{ $t('Download') }}</span>
                            </a>
                        </div>

                        <div class="preview-studio-wrapper">
                            <video
                                ref="videoPlayer"
                                id="preview-video"
                                :src="videoUrl"
                                :poster="videoPosterUrl"
                            ></video>
                            <canvas
                                ref="playerCanvas"
                                :width="formatWidth"
                                :height="formatHeight"
                                class="preview-studio-shape"
                            ></canvas>
                        </div>

                        <div class="preview-studio-footer">
                            <h2 class="preview-signature">
                                {{ sprintf($t('Made with %1$s'), '') }}
                                <svg-id-randomized-icon icon="cte-logo-inline" />
                            </h2>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
    <error-screen v-else :error-code="errorCode" />
</template>

<script setup>
import { useStore } from 'vuex';
import { printf as sprintf } from 'fast-printf';
import { nextTick, onBeforeUnmount, ref } from 'vue';
import { useRoute } from 'vue-router';
import _throttle from 'lodash/throttle.js';
import Plyr from 'plyr';

import SvgIdRandomizedIcon from '@/js/components/SvgIdRandomizedIcon.vue';
import VideoStudioService from '@/js/infrastructure/api/video/VideoStudioService.js';
import StudioShareInitializer from '@/js/videos/application/StudioShareInitializer.js';
import ErrorScreen from '@/js/components/ErrorScreen.vue';
import SplashScreen from '@/js/components/SplashScreen.vue';

const store = useStore();
const route = useRoute();
const videoStudioService = new VideoStudioService(store);
const studioShareInitializer = new StudioShareInitializer(videoStudioService);

const videoPlayer = ref(null);
const playerCanvas = ref(null);
const isReady = ref(false);
const playerWidth = ref('0px');

// video data
const videoName = ref('');
const formatWidth = ref('1920');
const formatHeight = ref('1080');
const videoDownloadUrl = ref('');
const videoUrl = ref(null);
const videoPosterUrl = ref(null);

// component state with API call
const loading = ref(true);
const errorCode = ref(null);

// fetch data to initialize component
studioShareInitializer
    .fetchData(route.params.id, route.meta.isSequenceTemplate, route.meta.isTemplate, route.params.brandId ?? null)
    .then((response) => {
        loading.value = false;

        if (response.errorCode) errorCode.value = response.errorCode;
        else {
            videoName.value = response.videoName;
            formatWidth.value = response.format.width;
            formatHeight.value = response.format.height;
            videoDownloadUrl.value = response.videoDownloadUrl;
            videoUrl.value = response.videoUrl;
            videoPosterUrl.value = response.videoPosterUrl;

            nextTick(initializePlyrPlayer);
        }
    });

const resizePreview = () => {
    playerWidth.value = playerCanvas.value.offsetWidth + 'px';
};

const initializePlyrPlayer = () => {
    const plyrPlayer = new Plyr(videoPlayer.value, {
        controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
        invertTime: false
    });

    const ready = () => {
        plyrPlayer.off('ready', ready);
        // Note: Workaround to avoid weird misplacement bug on Chrome?
        setTimeout(() => (isReady.value = true), 100);
    };
    plyrPlayer.on('ready', ready);

    window.addEventListener('resize', _throttle(resizePreview, 200));
    resizePreview();
};

document.body.classList.add('share-video');
onBeforeUnmount(() => {
    document.body.classList.remove('share-video');
});
</script>
