import DuplicateVideoApiService from '@/js/infrastructure/api/video/DuplicateVideoApiService.js';
import VideoDTO from '@/js/dto/VideoDTO.js';

export default class DuplicateVideoService {
    constructor(store) {
        this.duplicateVideoApiService = new DuplicateVideoApiService(store);
    }

    async handle(videoId) {
        let data = (await this.duplicateVideoApiService.handle(videoId)).data;
        return new VideoDTO(data);
    }
}
