import Tooltip from 'bootstrap/js/dist/tooltip';

const attachTooltip = (element, lightBg = false) => {
    return new Tooltip(element, {
        container: 'body',
        placement: 'top',
        fallbackPlacements: ['bottom'],
        trigger: 'hover',
        customClass: lightBg ? 'tooltip-light-background' : ''
    });
};

const updateTooltipText = (element, text) => {
    element.setAttribute('data-bs-original-title', text);

    let tooltip = Tooltip.getInstance(element);
    tooltip.setContent(tooltip.getTipElement());
    tooltip.update();
};

const detachTooltip = (element) => {
    let tooltip = Tooltip.getInstance(element);
    if (tooltip) tooltip.dispose();
};

export default {
    directives: {
        tooltip: {
            mounted(el, binding) {
                if (!!binding.value) {
                    el.setAttribute('title', binding.value);
                    attachTooltip(el, binding.modifiers.light);
                }
            },

            updated(el, binding) {
                if (binding.value != binding.oldValue) {
                    if (!binding.value) {
                        detachTooltip(el);
                    } else if (!!binding.oldValue) {
                        updateTooltipText(el, binding.value);
                    } else {
                        el.setAttribute('title', binding.value);
                        attachTooltip(el, binding.modifiers.light);
                    }
                }
            },

            unmounted(el) {
                detachTooltip(el);
            }
        }
    }
};
