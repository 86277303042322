<template>
    <ui-caption-button
        :seqId="seqId"
        v-model="voiceOverCaptions"
        :captioning="voiceOverCaptioning"
        :status="voiceOverCaptioningStatus"
        :modifier="storeModulePath + '/setCaptions'"
        :label="$t('Captions')"
        :disabled="disabled"
        :timerangeSegments="timerangeSegments"
        :playback-rate="playbackRate"
        :toggleStyle="toggleStyle"
        :iconOnly="iconOnly"
        :tooltip="voiceOverCaptioning ? $t('Captioning in progress...') : $t('Caption')"
        @[captionButtonShowEvent]="handleCaptioning"
        @[captionButtonCaptioningChangeEvent]="voiceOverCaptioning = $event"
    />
</template>

<script>
import { useStore } from 'vuex';
import { defineComponent, computed, ref } from 'vue';
import UiCaptionButton from '../../cards/tools/caption/UiCaptionButton.vue';
import { useHistory } from '../../../../../composables/useHistory';
import { useVideo } from '../../../../../composables/useVideo';
import {
    UI_CAPTION_BUTTON_SHOW,
    UI_CAPTION_BUTTON_CAPTIONING_CHANGE
} from '../../../../../components/ui/body/cards/tools/caption/UiCaptionButton.vue';
import { useVoiceOver } from '../../../../../composables/useVoiceOver';

export default defineComponent({
    name: 'UiBackgroundCaptionSelector',
    components: {
        UiCaptionButton
    },
    props: {
        useInSequence: true,
        seqId: String,
        readOnly: Boolean,
        iconOnly: Boolean
    },
    setup(props) {
        const useInSequence = ref(props.useInSequence);
        const seqId = ref(props.seqId);

        const store = useStore();
        const { saveVideo } = useVideo();
        const { saveHistoryStep } = useHistory();
        const { storeModulePath, timerangeSegments, playbackRate, voiceOverMediaState } = useVoiceOver(
            useInSequence,
            seqId
        );

        const captionButtonShowEvent = UI_CAPTION_BUTTON_SHOW;
        const captionButtonCaptioningChangeEvent = UI_CAPTION_BUTTON_CAPTIONING_CHANGE;

        const disabled = computed(() => {
            return props.readOnly || !voiceOverMediaState?.value?.src;
        });

        const voiceOverCaptions = computed({
            get: () => {
                return store.getters[storeModulePath.value + '/captions'] || [];
            },
            set: (value) => {
                saveHistoryStep(() => {
                    store.dispatch(storeModulePath.value + '/setCaptions', value);
                });
            }
        });

        const voiceOverCaptioning = computed({
            get: () => {
                return store.getters[storeModulePath.value + '/captioning'];
            },
            set: (value) => {
                if (!!value) {
                    store.dispatch(storeModulePath.value + '/startCaptioning');
                } else {
                    store.dispatch(storeModulePath.value + '/cancelCaptioning');
                }
                store.dispatch(storeModulePath.value + '/setCaptioningStatus', null);
                saveVideo();
            }
        });

        const voiceOverCaptioningStatus = computed(() => store.getters[storeModulePath.value + '/captioningStatus']);

        const hasCaptions = computed(() => {
            return voiceOverCaptions.value.length > 0 && voiceOverCaptions.value[0].text.length > 0;
        });

        const toggleStyle = computed(() => {
            return hasCaptions.value ? { color: 'var(--edit-mode-color)' } : {};
        });

        const openCaptionEditor = (button) => {
            store.commit('ui/setCurrentCaptionButton', button);
        };

        const startCaptioning = () => {
            voiceOverCaptioning.value = true;
        };

        const handleCaptioning = (button) => {
            if (hasCaptions.value) openCaptionEditor(button);
            else startCaptioning();
        };

        return {
            captionButtonShowEvent,
            captionButtonCaptioningChangeEvent,
            disabled,
            storeModulePath,
            playbackRate,
            timerangeSegments,
            toggleStyle,
            voiceOverCaptions,
            voiceOverCaptioning,
            voiceOverCaptioningStatus,
            openCaptionEditor,
            handleCaptioning
        };
    }
});
</script>
