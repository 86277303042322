<template>
    <button class="ui-simple-button-transparent follow-project-button" :class="{ active: active }" @click="follow()">
        <fa-icon class="icon" :icon="`fa-solid ${icon}`" />
    </button>
</template>

<script>
import FolderDTO from '@/js/dto/FolderDTO.js';
import { useAuth } from '@/js/composables/useAuth.js';
import { computed, onMounted, ref } from 'vue';
import FollowFolderService from '@/js/application/services/folders/FollowFolderService.js';
import { useStore } from 'vuex';

export default {
    props: {
        folder: {
            type: FolderDTO,
            required: true
        }
    },
    setup(props) {
        const store = useStore();
        const { user } = useAuth();

        const active = ref(false);

        const icon = computed(() => {
            return active.value ? 'fa-bell-on' : 'fa-bell';
        });

        const follow = () => {
            active.value = !active.value;

            new FollowFolderService(store).handle({
                publicId: props.folder.shortname,
                follow: active.value
            });
        };

        onMounted(() => {
            active.value = user.value.followedProjectIds.includes(props.folder.id);
        });

        return {
            active,
            icon,
            follow
        };
    }
};
</script>
