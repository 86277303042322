const setGlobalConfig = (config) => {
    Object.assign(window, {
        STUDIO_BASE_URL: config.studioBaseUrl || '',
        STUDIO_MAP_API_KEY: config.mapApiKey || ''
    });

    if (config.readOnly) {
        Object.assign(window, { readOnly: config.readOnly });
    }
};

const setGlobalVideoData = (videoResource) => {
    Object.assign(window, { videoData: videoResource });
};

export { setGlobalConfig, setGlobalVideoData };
