<template>
    <ui-dropdown
        class="audio-dropdown centered"
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="bottom-end"
        menu-strategy="fixed"
        :toggle-style="toggleStyle"
        :disabled="disableDropDown"
        :icon-only="iconOnly"
    >
        <template #dropdown-toggle>
            <svg-icon icon="audio-icon" />
            <span v-if="!iconOnly">{{ $t('Music') }}</span>
        </template>
        <div>
            <ui-range-input
                class="ui-content-row"
                v-model="voiceOverVolumeModel"
                :horizontal="false"
                :label="$t('Audio volume')"
                :disabled="readOnly"
            />
        </div>
    </ui-dropdown>
</template>
<script>
import { useStore } from 'vuex';
import { defineComponent, computed, ref } from 'vue';
import { UiIcon } from '../../../../../../components';
import UiRangeInput from '../../../../../../components/UiRangeInput.vue';
import UiNumberInput from '../../../../../../components/UiNumberInput.vue';
import UiDropdown from '../../../../../../components/UiDropdown.vue';
import { useHistory } from '../../../../../composables/useHistory';
import { useVoiceOver } from '../../../../../composables/useVoiceOver';
import { Sound } from 'cte-video-studio';

export default defineComponent({
    components: { UiIcon, UiRangeInput, UiNumberInput, UiDropdown },
    props: { useInSequence: Boolean, seqId: String, readOnly: Boolean, iconOnly: Boolean },
    setup(props) {
        const useInSequence = ref(props.useInSequence);
        const seqId = ref(props.seqId);

        const store = useStore();
        const { saveHistoryStep } = useHistory();
        const { storeModulePath, voiceOverMediaState } = useVoiceOver(useInSequence, seqId);

        const defaultVolume = Sound.VOLUME_DEFAULT;

        const voiceOverVolume = computed(() => {
            return store.getters[storeModulePath.value + '/volume'] ?? defaultVolume;
        });

        const voiceOverVolumeModel = computed({
            get: () => {
                return Math.round(100 * voiceOverVolume.value);
            },
            set: (value) => {
                saveHistoryStep(() => {
                    store.dispatch(storeModulePath.value + '/setVolume', value / 100);
                });
            }
        });

        const disableDropDown = computed(() => {
            return props.readOnly || !voiceOverMediaState?.value?.src;
        });

        const toggleStyle = computed(() => {
            return voiceOverVolume.value !== defaultVolume && voiceOverMediaState?.value?.src
                ? { color: 'var(--edit-mode-color)' }
                : {};
        });

        return {
            voiceOverVolume,
            voiceOverVolumeModel,
            disableDropDown,
            toggleStyle
        };
    }
});
</script>

<style></style>
