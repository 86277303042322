import { Slice } from 'prosemirror-model';
import { TextSelection } from 'prosemirror-state';
import Highlight from '@tiptap/extension-highlight';

const HighlightMark = Highlight.extend({
    name: 'highlightMark',

    addOptions() {
        return {
            enabled: true,
            HTMLAttributes: {}
        };
    },

    addAttributes() {
        return {};
    },

    addKeyboardShortcuts() {
        return {};
    }
});

HighlightMark.parseStudioMessage = (htmlContent, enabled = true, remove = false) => {
    return enabled || remove
        ? htmlContent.replace(/\{\{/g, !remove ? '<mark>' : '').replace(/\}\}/g, !remove ? '</mark>' : '')
        : htmlContent;
};

HighlightMark.toStudioMessage = (htmlContent, enabled = true, remove = false) => {
    return enabled || remove
        ? htmlContent.replace(/<mark>/g, !remove ? '{{' : '').replace(/<\/mark>/g, !remove ? '}}' : '')
        : htmlContent;
};

HighlightMark.parseEditorMessage = (editor) => {
    let { doc } = editor.view.state,
        chain,
        tr = editor.captureTransaction(() => {
            chain = editor.chain();
            chain.run();
        });

    tr.setMeta('preventUpdate', true);

    let started = false,
        startPos = -1;

    doc.descendants((node, position) => {
        if (!node.text) return;

        let text = node.text,
            searchRegExp = !started ? /\{\{/ : /\}\}/,
            searchedIndex = -1;

        while ((searchedIndex = text.search(searchRegExp)) != -1) {
            if (!started) {
                startPos = position + searchedIndex;
            } else {
                let endIndex = position + searchedIndex;

                // Making selection directly on transaction because tiptap's setTextSelection doesn't map positions
                tr.setSelection(TextSelection.create(tr.doc, tr.mapping.map(startPos), tr.mapping.map(endIndex + 2)));

                // Adding highlight marks
                chain = chain.setHighlight();

                // Removing highlight studio marks
                let slice = tr.selection.content();
                slice.content = slice.content.cut(3, slice.content.size - 3);
                tr.replaceSelection(!!slice.content.size ? slice : Slice.empty);

                startPos = -1;
            }

            started = !started;
            position += searchedIndex + 2;
            text = text.slice(searchedIndex + 2);
            searchRegExp = !started ? /\{\{/ : /\}\}/;
        }
    });

    chain.setTextSelection(0).run();
};

export { HighlightMark, HighlightMark as default };
