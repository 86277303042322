<template>
    <component :is="modalComponent" v-if="modalComponent" />
</template>

<script>
import { computed, defineAsyncComponent, defineComponent, h } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
    setup() {
        const store = useStore();

        const modalComponents = {
            ConfirmDeleteFolderModal: () => import('@/js/components/modals/ConfirmDeleteFolderModal.vue'),
            ConfirmDeleteVideosModal: () => import('@/js/components/modals/ConfirmDeleteVideosModal.vue'),
            ConfirmDuplicateVideosModal: () => import('@/js/components/modals/ConfirmDuplicateVideosModal.vue'),
            CopyVideoModal: () => import('@/js/components/modals/CopyVideoModal.vue'),
            CreateFolderModal: () => import('@/js/components/modals/CreateFolderModal.vue'),
            CreateVideoModal: () => import('@/js/components/modals/CreateVideoModal.vue'),
            TransformTemplateModal: () => import('@/js/components/modals/TransformTemplateModal.vue'),
            ShareFolderModal: () => import('@/js/components/modals/ShareFolderModal.vue'),
            UseTemplateModal: () => import('@/js/components/modals/UseTemplateModal.vue'),
            EditProfileModal: () => import('@/js/components/modals/profile/EditProfileModal.vue'),
            ExportVideoModal: () => import('@/js/components/modals/export/ExportVideoModal.vue'),
            CGSModal: () => import('@/js/components/modals/CGSModal.vue'),
            EditUserModal: () => import('@/js/components/modals/user/EditUserModal.vue'),
            InviteUserModal: () => import('@/js/components/modals/user/InviteUserModal.vue'),
            WelcomeModal: () => import('@/js/components/modals/profile/WelcomeModal.vue')
        };

        const currentModal = computed(() => store.getters['modals/currentModal']);

        const modalComponent = computed(() => {
            return defineAsyncComponent(() =>
                modalComponents[currentModal.value]()
                    .then((module) => {
                        return module.default;
                    })
                    .catch((error) => {
                        console.error(`Unable to find modal component ${currentModal.value}`, error);
                        return h('div');
                    })
            );
        });

        return { modalComponent };
    }
});
</script>
