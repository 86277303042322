import * as Sentry from '@sentry/browser';

const initSentry = (app) => {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
        environment: import.meta.env.VITE_APP_ENV ?? 'production'
    });
};

export { initSentry };
