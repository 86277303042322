export default class VideoDTO {
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.organizationId = data.organization_id;
        this.folderId = data.folder_id;
        this.ownerId = data.owner_id;
        this.ownerName = data.owner_name;
        this.format = {
            size: data.format?.size,
            width: data.format?.width,
            height: data.format?.height
        };
        this.sequencesTotal = data.sequences_total;
        this.needsMigration = data.needs_migration;
        this.thumbnails = {
            staticSrc: data.thumbnails?.static_src,
            previewSrc: data.thumbnails?.preview_src
        };
        this.validationStatus = data.validation_status;
        this.submitterId = data.submitter_id;
        this.productionStatus = data.production_status;
        this.isProducing = data.is_producing;
        this.producedAt = data.produced_at;
        this.latestProducedUrl = data.latest_produced_url;
        this.latestProducedPosterUrl = data.latest_produced_poster_url;
        this.latestProducedIsOutdated = data.latest_produced_is_outdated;
        this.hasFailedProduction = data.has_failed_production;
        this.latestProductionError = data.latest_production_error;
        this.createdAt = data.created_at;
        this.updatedAt = data.updated_at;
        this.readableCreatedAt = data.readable_created_at;
        this.readableUpdatedAt = data.readable_updated_at;
        this.permissions = {
            edit: data.permissions?.edit,
            copy: data.permissions?.copy,
            move: data.permissions?.move,
            createTemplateFrom: data.permissions?.create_template_from,
            delete: data.permissions?.delete,
            submit: data.permissions?.submit,
            validate: data.permissions?.validate,
            produce: data.permissions?.produce
        };
        this.shareUrl = data.share_url;
        this.downloadUrl = data.download_url;
    }
}
