<template>
    <link :href="fontSrc" rel="stylesheet" />
</template>

<script>
import { Font as FontConstants } from '../constants';
import FontFaceObserver from 'fontfaceobserver';
import FONT_LIST from '../constants/font-list.json';

export default {
    props: {
        fontName: {
            type: String,
            default: FontConstants.DEFAULT
        }
    },

    data() {
        return {
            fontReferences: null
        };
    },

    computed: {
        src() {
            return !!FONT_LIST[this.fontName]?.src
                ? FONT_LIST[this.fontName].src
                : `/src/sass/video-studio/fonts/${this.fontName.toLowerCase()}.scss`;
        },

        fontSrc() {
            if (/^(?:https?\:)?\/\//.test(this.src)) return this.src;
            return this.fontReferences[this.src];
        },

        fontLoaded() {
            return this.$store.getters['loading/isLoaded'](this.fontSrc);
        },

        fontReserved() {
            return this.$store.getters['loading/isReserved'](this.fontSrc);
        },

        fontFamily() {
            return FONT_LIST[this.fontName]?.family || FontConstants.DEFAULT;
        },

        fontFamilyGeneric() {
            return FONT_LIST[this.fontName]?.generic || 'sans-serif';
        }
    },

    watch: {
        fontName(newValue, oldValue) {
            this.unloadFont(oldValue);

            this.$store.commit('loading/add', this.fontSrc);
            if (!this.fontReserved) {
                this.$store.commit('loading/reserve', this.fontSrc);
                this._styleElements = {};
                this.load();
            } else {
                this.$emit('load', this.fontSrc);
            }
        }
    },

    methods: {
        load() {
            let styles,
                style,
                families,
                element = document.createElement('div');

            this._styleElements = {
                regular: {
                    element: document.createElement('span'),
                    family: '',
                    weight: 'normal',
                    style: 'normal',
                    observer: null,
                    loaded: false
                },
                bold: {
                    element: document.createElement('b'),
                    family: '',
                    weight: 'bold',
                    style: 'normal',
                    observer: null,
                    loaded: false
                },
                italic: {
                    element: document.createElement('i'),
                    family: '',
                    weight: 'normal',
                    style: 'italic',
                    observer: null,
                    loaded: false
                },
                boldItalic: {
                    element: document.createElement('b'),
                    family: '',
                    weight: 'bold',
                    style: 'italic',
                    observer: null,
                    loaded: false
                }
            };
            let bi = document.createElement('i');
            this._styleElements.boldItalic.element.appendChild(bi);
            this._styleElements.boldItalic.element = bi;

            element.appendChild(this._styleElements.regular.element);
            element.appendChild(this._styleElements.bold.element);
            element.appendChild(this._styleElements.italic.element);
            element.appendChild(this._styleElements.boldItalic.element.parentNode);
            document.body.appendChild(element);

            for (style in this._styleElements) {
                styles = window.getComputedStyle(this._styleElements[style].element);
                families = styles.fontFamily.split(/\s*,\s*/);
                if (families.length) {
                    this._styleElements[style].family = families[0].replace(/^["'\s]*/, '').replace(/["'\s]*$/, '');
                    this._styleElements[style].weight = styles.fontWeight;
                    this._styleElements[style].style = styles.fontStyle;
                    this._styleElements[style].observer = new FontFaceObserver(this._styleElements[style].family, {
                        name: style,
                        weight: this._styleElements[style].weight,
                        style: this._styleElements[style].style
                    });
                    this._styleElements[style].observer
                        .load(null, FontConstants.FONT_TIMEOUT)
                        .then(this.onFontResourceLoaded(style), this.onFontResourceLoaded(style, true));
                }
            }

            setTimeout(() => {
                document.body.removeChild(element);
            }, 500);
        },

        onFontResourceLoaded(style, error) {
            return () => {
                this._styleElements[style].loaded = true;
                let s,
                    allLoaded = true;
                for (s in this._styleElements) {
                    allLoaded = allLoaded && this._styleElements[s].loaded;
                }
                if (allLoaded) this.onFontLoaded(error);
            };
        },

        onFontLoaded(error) {
            if (!error && !this.fontLoaded) this.$store.commit('loading/prepare', this.src);
            this.$store.commit('loading/loaded', { path: this.fontSrc });

            this.$emit('load', this.fontSrc);
            this.$store.commit('loading/prepared', this.src);
        },

        unloadFont(fontName) {
            const src = !!FONT_LIST[fontName]?.src
                ? FONT_LIST[fontName].src
                : `/src/sass/video-studio/fonts/${fontName.toLowerCase()}.scss`;
            const fontSrc = /^(?:https?\:)?\/\//.test(src) ? src : this.fontReferences[src];
            const fontLoaded = this.$store.getters['loading/isLoaded'](fontSrc);

            this.$store.commit('loading/prepared', src);
            if (!fontLoaded) this.$store.commit('loading/release', fontSrc);
            this.$store.commit('loading/remove', fontSrc);
        }
    },

    created() {
        this.fontReferences = import.meta.glob('@resources/sass/video-studio/fonts/*.scss', {
            eager: true,
            query: '?url',
            import: 'default'
        });
    },

    mounted() {
        this.$store.commit('loading/add', this.fontSrc);
        if (!this.fontReserved) {
            this.$store.commit('loading/reserve', this.fontSrc);
            this._styleElements = {};
            this.load();
        }
    },

    beforeUnmount() {
        this.$store.commit('loading/prepared', this.src);
        if (!this.fontLoaded) this.$store.commit('loading/release', this.fontSrc);
        this.$store.commit('loading/remove', this.fontSrc);
    }
};
</script>
