import { debounce } from 'lodash';
import { ref } from 'vue';

export function useDragInteractions({
    segments,
    isDragging,
    updateSegmentPosition,
    mergeAdjacentSegments,
    mergeSegments,
    updateCurrentTimeAndPos,
    startDrag,
    stopDrag,
    sliderWrapper,
    sliderContainer,
    sequenceProps,
    updateSegmentsInStore,
}) {
    const isSliderDragging = ref(false);
    const startX = ref(0);
    const scrollLeft = ref(0);
    const dragHandle = ref(null);

    const dragSegmentIndex = ref(-1);
    const dragStartTime = ref(0);
    const dragStartPosition = ref(0);
    const dragSegmentIsDragging = ref(false);

    const debouncedUpdateCurrentTimeAndPos = debounce(updateCurrentTimeAndPos, 300);

    const startSegmentDrag = (event, index) => {
        startDrag();
        dragSegmentIndex.value = index;
        dragStartTime.value = segments.value[index].start;
        dragStartPosition.value = event.clientX;
        dragSegmentIsDragging.value = true;
        document.addEventListener('mousemove', handleSegmentDrag);
        document.addEventListener('mouseup', stopSegmentDrag);
    };

    const handleSegmentDrag = (event) => {
        if (!isDragging.value || dragSegmentIndex.value === -1) return;        

        const { width } = sliderContainer.value.getBoundingClientRect();
        const pixelOffset = event.clientX - dragStartPosition.value;
        const timeOffset = (pixelOffset / width) * sequenceProps.endTime;

        const segment = segments.value[dragSegmentIndex.value];
        const segmentDuration = segment.end - segment.start;
        let newStart = dragStartTime.value + timeOffset;

        // Ensure the segment stays within bounds
        newStart = Math.max(0, Math.min(newStart, sequenceProps.endTime - segmentDuration));

        segment.start = newStart;
        segment.end = newStart + segmentDuration;
        dragHandle.value = null;

        mergeSegments();
        mergeAdjacentSegments(dragSegmentIndex.value);
    };

    const stopSegmentDrag = () => {
        stopDrag();
        dragSegmentIndex.value = -1;
        dragSegmentIsDragging.value = false;
        document.removeEventListener('mousemove', handleSegmentDrag);
        document.removeEventListener('mouseup', stopSegmentDrag);
        
        mergeSegments();
        updateSegmentsInStore(segments.value);
    };

    const handleStartDrag = (handle, index) => {
        startDrag();
        dragHandle.value = handle;
        dragSegmentIndex.value = index;
        document.addEventListener('mousemove', handleMouseMove);
    };

    const handleMouseMove = (event) => {
        if (!sliderContainer.value?.contains(event.target)) {
            handleStopDrag();
        }
    };

    const onDrag = (event) => {
        if (!isDragging.value || !sliderContainer.value || dragSegmentIsDragging.value) return;
        
        const { left, width } = sliderContainer.value.getBoundingClientRect();
        const newPos = event.clientX - left;
        const newTime = calculateNewTime(newPos, width);
        const currentSegment = segments.value[dragSegmentIndex.value];

        let adjustedNewTime = newTime;

        updateSegmentPosition(adjustedNewTime, 0.1, currentSegment, dragHandle.value === 'start');
        mergeAdjacentSegments(dragSegmentIndex.value);
        mergeSegments();

        // debouncedUpdateCurrentTimeAndPos(adjustedNewTime);

        sliderContainer.value.addEventListener('mouseup', handleStopDrag);
    };

    const calculateNewTime = (newPos, containerWidth) => {
        const rawTime = (newPos / containerWidth) * sequenceProps.endTime;
        return Math.max(0, Math.min(rawTime, sequenceProps.endTime));
    };

    const handleStopDrag = () => {
        stopDrag();        

        sliderContainer.value.removeEventListener('mouseup', handleStopDrag);
        document.removeEventListener('mousemove', handleMouseMove);
        dragHandle.value = null;
        dragSegmentIndex.value = -1;
        updateSegmentsInStore(segments.value);            
    };

    const startSliderDrag = (event, wrapperEl) => {
        if (isDragging.value || !wrapperEl) {
            !wrapperEl && console.warn('sliderWrapper is not initialized');
            return;
        }
        
        isSliderDragging.value = true;
        startX.value = event.pageX - wrapperEl.offsetLeft;
        scrollLeft.value = wrapperEl.scrollLeft;
    };

    const onSliderDrag = (event) => {
        if (!isSliderDragging.value || !sliderWrapper.value) return;        
        const x = event.pageX - sliderWrapper.value.offsetLeft;
        const walk = (x - startX.value) * 2; // Scroll 2x faster
        sliderWrapper.value.scrollLeft = scrollLeft.value - walk;

        sliderWrapper.value.addEventListener('mouseup', stopSliderDrag);
    };

    const stopSliderDrag = () => {
        isSliderDragging.value = false;
        updateSegmentsInStore(segments.value);
        document.removeEventListener('mousemove', handleMouseMove);
        sliderWrapper.value.removeEventListener('mouseup', stopSliderDrag);
    };

    return {
        handleStartDrag,
        dragHandle,
        onDrag,
        handleStopDrag,
        startSliderDrag,
        onSliderDrag,
        stopSliderDrag,
        dragSegmentIndex,
        startSegmentDrag,
        dragSegmentIsDragging
    };
}
