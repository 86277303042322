<template>
    <div class="ui-caption-editor" :class="editorClasses" @[transitionEndEvent]="emptyEditor">
        <div class="ui-caption-editor-inner">
            <ui-caption-editor-header :cancel-method="cancel"></ui-caption-editor-header>
            <ui-caption-editor-actions
                :enable="processing"
                :captioning="captioning"
                :current-captions="currentCaptions"
                :current-caption-button="currentCaptionButton"
                :short-language="shortLanguage"
                :translating-status="translatingStatus"
                @update:captioning="updateCaptioning"
                @update:translating-status="updateTranslatingStatus"
                @update:current-captions="updateCurrentCaptions"
            >
                <fa-icon class="icon" icon="fa-solid fa-language" />
            </ui-caption-editor-actions>
            <ui-caption-editor-content
                :is-visible="!processing"
                :current-captions="currentCaptions"
                :current-caption-button="currentCaptionButton"
            >
                <template #caption-add-icon>
                    <slot name="caption-add-icon"></slot>
                </template>
            </ui-caption-editor-content>
            <ui-caption-editor-processing
                :is-visible="processing"
                :captioning="captioning"
                :translating="translating"
                :has-processing-error="hasProcessingError"
                :has-captioning-error="hasCaptioningError"
                :current-caption-button-captioning-status="currentCaptionButtonCaptioningStatus"
                :translating-status="translatingStatus"
                :cancel-translation="cancelTranslation"
                :cancel-auto-captions="cancelAutoCaptions"
            ></ui-caption-editor-processing>
            <ui-caption-editor-footer
                :is-visible="!processing"
                :current-captions="currentCaptions"
                :current-caption-button="currentCaptionButton"
                @update:current-captions="updateCurrentCaptions"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import _cloneDeep from 'lodash/cloneDeep';
import { ProvidesIcons } from '../../../../../../../mixins';
import { UI_CAPTION_BUTTON_HIDE } from './UiCaptionButton.vue';
import UiTextInput from '../../../../../../../components/UiTextInput.vue';
import UiCaptionEditorHeader from './UiCaptionEditorHeader.vue';
import UiCaptionEditorFooter from './UiCaptionEditorFooter.vue';
import UiCaptionEditorActions from './UiCaptionEditorActions.vue';
import UiCaptionEditorProcessing from './UiCaptionEditorProcessing.vue';
import UiCaptionEditorContent from './UiCaptionEditorContent.vue';

const UPDATE_CAPTION_DELAY = 500;
const DEFAULT_CAPTION_DURATION = 3;
const DEFAULT_CAPTION = {
    startTime: 0,
    endTime: DEFAULT_CAPTION_DURATION,
    text: ''
};

export default {
    mixins: [ProvidesIcons],

    components: {
        UiTextInput,
        UiCaptionEditorHeader,
        UiCaptionEditorFooter,
        UiCaptionEditorActions,
        UiCaptionEditorProcessing,
        UiCaptionEditorContent
    },

    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            show: false,
            currentCaptions: [],
            captioning: false,
            captioningStatus: null,
            translatingStatus: null
        };
    },

    computed: {
        ...mapState({
            supportEmail: (state) => state.ui.supportEmail,
            language: (state) => state.ui.language,
            shortLanguage: (state) => state.ui.shortLanguage,
            captionLanguages: (state) => state.ui.captionsConfig.languages,
            translationLanguages: (state) => state.ui.translationConfig.languages,
            currentCaptionButton: (state) => state.ui.currentCaptionButton,
            translating: (state) => state.ui.currentCaptionTranslating,
            currentTimelineId: (state) => state.preview.currentTimelineId
        }),

        ...mapGetters({
            editorIsShown: 'ui/captionEditorIsShown'
        }),

        currentCaptionButtonValue() {
            return this.currentCaptionButton && this.currentCaptionButton.modelValue;
        },

        currentCaptionButtonCaptioning() {
            return this.currentCaptionButton && this.currentCaptionButton.captioning;
        },

        currentCaptionButtonCaptioningStatus() {
            return this.currentCaptionButton ? this.currentCaptionButton.status : null;
        },

        hasCaptioningError() {
            return !!this.currentCaptionButtonCaptioningStatus;
        },

        hasTranslatingError() {
            return !!this.translatingStatus;
        },

        hasProcessingError() {
            return this.hasCaptioningError || this.hasTranslatingError;
        },

        processing() {
            return this.captioning || this.translating || this.hasProcessingError;
        },

        editorClasses() {
            return {
                show: this.show
            };
        },

        transitionEndEvent() {
            return !this.show ? 'transitionend' : null;
        }
    },

    watch: {
        editorIsShown(newValue) {
            if (!!newValue) {
                this.show = true;
            }
        },

        currentCaptionButton(newValue) {
            if (!!newValue)
                this.currentCaptionButton.$el.addEventListener(UI_CAPTION_BUTTON_HIDE, this.close, { once: true });
        },

        currentCaptionButtonValue(newValue) {
            this.currentCaptions = newValue && newValue.length ? _cloneDeep(newValue) : [{ ...DEFAULT_CAPTION }];
        },

        currentCaptionButtonCaptioning(newValue) {
            this.captioning = !!newValue;
        },

        currentCaptionButtonCaptioningStatus(newValue) {
            this.captioningStatus = newValue;
        },

        currentTimelineId(newVal) {
            if (newVal === null) {
                this.close();
            }
        }
    },

    methods: {
        ...mapActions({
            startCaptionsTranslating: 'ui/startCaptionsTranslating',
            cancelCaptionsTranslating: 'ui/cancelCaptionsTranslating'
        }),

        cancel() {
            this.cancelTranslation();
            this.currentCaptionButton.cancel();
        },

        close() {
            this.show = false;
        },

        emptyEditor(event) {
            if (event.target == this.$el && event.propertyName == 'opacity') {
                this.$store.commit('ui/setCurrentCaptionButton', null);
                this.captions = [];
                this.translatingStatus = null;
            }
        },

        cancelAutoCaptions() {
            this.captioning = false;
            this.currentCaptionButton.changeCaptioning(false, true);
        },

        cancelTranslation() {
            this.cancelCaptionsTranslating();
            this.translatingStatus = null;
        },

        updateCurrentCaptions(newCaptions) {
            this.currentCaptions = newCaptions;
        },

        updateTranslatingStatus(newStatus) {
            this.translatingStatus = newStatus;
        },

        updateCaptioning(newStatus) {
            this.captioning = newStatus;
        }
    }
};
</script>
