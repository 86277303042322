import { Duration, Sound } from '@/js/video-studio/constants';
import { conversions } from '@/js/video-studio/utils';
import _cloneDeep from 'lodash/cloneDeep';

const state = () => ({
    src__ref: '',
    src__id: '',
    src: '',

    volume: Sound.VOLUME_DEFAULT,

    start: Duration.START_DEFAULT,

    playbackRate: Duration.PLAY_BACK_RATE_DEFAULT,

    timerange: {
        start: Duration.START_DEFAULT,
        end: Duration.END_DEFAULT
    },

    timerangeSegments: [],

    totalDuration: () => 0,

    captions: false,
    captioning: false,
    captioningStatus: null
});

const getters = {
    sourceRef(state) {
        return state.src__ref;
    },

    track: (state) => {
        return state.timerange &&
            state.timerange.start !== Duration.START_DEFAULT &&
            state.timerange.end !== Duration.END_DEFAULT
            ? conversions.mediaFragmentAssetSrc(state.src, state.totalDuration(), state.timerange)
            : state.src;
    },

    volume: (state) => {
        return state.volume;
    },

    start: (state) => {
        return state.start;
    },

    playbackRate: (state) => {
        return state.playbackRate;
    },

    timerange: (state) => {
        return state.timerange;
    },

    captions: (state) => {
        return state.captions;
    },

    captioning: (state) => {
        return state.captioning;
    },

    captioningStatus: (state) => {
        return state.captioningStatus;
    },

    totalDuration: (state) => {
        return state.totalDuration();
    }
};

const mutations = {
    setSource(state, src) {
        state.src = src;
    },

    setSourceId(state, id) {
        state.src__id = id;
    },

    setSourceRef(state, ref) {
        state.src__ref = ref;
    },

    setVolume(state, volume) {
        state.volume = volume;
    },

    setStart(state, start) {
        state.start = start;
    },

    setPlaybackRate(state, playbackRate) {
        state.playbackRate = playbackRate;
    },

    setTimerange(state, timerange) {
        state.timerange = Object.assign({ start: 0, end: -1 }, timerange || {});
    },

    setTimerangeSegments(state, segments) {
        state.timerangeSegments = Array.isArray(segments) && !!segments.length ? _cloneDeep(segments) : [];
    },

    setTotalDuration(state, totalDuration) {
        state.totalDuration = () => totalDuration;
    },

    setCaptions(state, captions) {
        state.captions = Array.isArray(captions) && !!captions.length && captions;
    },

    setCaptioning(state, captioning) {
        state.captioning = !!captioning;
    },

    setCaptioningStatus(state, status) {
        state.captioningStatus = status;
    }
};

const actions = {
    init({ commit }, data) {
        if (data.src) commit('setSource', data.src);
        if (data.src__id) commit('setSourceId', data.src__id);
        if (data.src__ref) commit('setSourceRef', data.src__ref);
        if (data.volume && data.volume !== Sound.VOLUME_DEFAULT) commit('setVolume', data.volume);
        if (data.start && data.start !== Duration.START_DEFAULT) commit('setStart', data.start);
        if (data.playbackRate && data.playbackRate !== Duration.PLAY_BACK_RATE_DEFAULT)
            commit('setPlaybackRate', data.playbackRate);
        if (data.timerange) commit('setTimerange', data.timerange);
        if (data.timerangeSegments) commit('setTimerangeSegments', data.timerangeSegments);
        if (data.captions) commit('setCaptions', data.captions);
    },

    startCaptioning(context) {
        let { commit, dispatch, state, getters } = context;

        if (!!state.src__id) {
            commit('setCaptioning', true);
            dispatch(
                'ui/processes/startMediaCaptioningProcess',
                {
                    mediaId: state.src__id,
                    subscriberId: getters.path + '/setCaptions',
                    success: (captions) => {
                        dispatch('ui/history/startStep', null, { root: true });
                        commit('setCaptions', captions);
                        commit('setCaptioning', false);
                        commit('setCaptioningStatus', null);
                        dispatch('ui/saveVideo', null, { root: true });
                    },
                    error: (error) => {
                        commit('setCaptioning', false);
                        commit('setCaptioningStatus', error);
                        dispatch('ui/saveVideo', null, { root: true });
                    }
                },
                { root: true }
            );
        }
    },

    cancelCaptioning({ commit, dispatch, state, getters }) {
        if (!!state.src__id && state.captioning) {
            dispatch(
                'ui/processes/cancelMediaCaptioningProcess',
                {
                    mediaId: state.src__id,
                    subscriberId: getters.path + '/setCaptions'
                },
                { root: true }
            );
            commit('setCaptioning', false);
            commit('setCaptioningStatus', null);
            dispatch('ui/saveVideo', null, { root: true });
        }
    }
};

export default {
    namespaced: true,

    state,
    getters,
    mutations,
    actions
};
