<template>
    <div id="edit-client-view" class="editing-view" v-if="client">
        <div class="ui-layout">
            <div class="ui-header">
                <div class="ui-header-content">
                    <div class="ui-header-details">
                        <h1 class="ui-header-title">{{ $t('Parameters') }}</h1>
                    </div>
                </div>
                <div class="ui-header-actions">
                    <client-selector
                        v-if="isCTE"
                        v-model="selectedClientShortname"
                        @update:model-value="cloneClient"
                        class="right"
                    />
                </div>
            </div>
            <div class="ui-sidebar">
                <ul class="ui-sidebar-items">
                    <li v-for="tab in tabs">
                        <div
                            class="ui-sidebar-item-handle"
                            :class="{ active: currentTab === tab.name }"
                            @click="setCurrentTab(tab.name)"
                        >
                            <fa-icon class="icon" :icon="tab.icon" />
                            <span class="item-name">{{ $t(`clients.tabs.${tab.name}`) }}</span>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="ui-body">
                <ui-scrollable
                    class="ui-body-inner"
                    ref="$scrollable"
                    :scroll-from="['.ui-sidebar', '.ui-body']"
                    :margin-top="20"
                    :margin-bottom="20"
                >
                    <edit-client-section-general v-if="currentTab === 'general'" v-model="client" />
                    <edit-client-section-team v-else-if="currentTab === 'team'" :client="client" />
                </ui-scrollable>
            </div>
        </div>
    </div>
</template>

<script setup>
import '@/sass/views/edit-client-view.scss';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import _debounce from 'lodash/debounce';
import { useAuth } from '@/js/composables/useAuth.js';
import { UiScrollable } from '@/js/components/index.js';
import EditClientSectionGeneral from '@/views/EditClientView/components/EditClientSectionGeneral.vue';
import ClientDataService from '@/js/application/services/client/ClientDataService.js';
import EditClientSectionTeam from '@/views/EditClientView/components/EditClientSectionTeam.vue';
import { onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n';
import ClientSelector from '@/js/components/clients/ClientSelector.vue';

const store = useStore();
const { user, isCTE } = useAuth();
const { t } = useI18n();

const tabs = [
    { name: 'general', icon: 'fa-regular fa-suitcase' },
    { name: 'team', icon: 'fa-regular fa-users' }
];
const currentTab = ref('general');

const confirmationMessage = t('You have unsaved changes, are you sure you want to leave this page?');

const setCurrentTab = (tab) => {
    currentTab.value = tab;
};

const selectedClientShortname = ref(user.value.client.shortname);
const client = ref(user.value.client);
const clonedClient = ref(null);

const saving = ref(false);

const sendSaveRequest = (params) => {
    console.debug('sendSaveRequest');
    let payload = {};
    Object.entries(params).forEach((value) => {
        payload[value[0].replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()] = value[1];
    });
    new ClientDataService().put(client.value.shortname, payload);
};

const saveClient = () => {
    let newParams = Object.keys(client.value)
        .filter((key) => !_isEqual(client.value[key], clonedClient.value[key]))
        .reduce((obj, key) => {
            obj[key] = client.value[key];
            return obj;
        }, {});

    if (Object.keys(newParams).length) {
        sendSaveRequest(newParams);
        cloneClient();
    }

    saving.value = false;
};

const cloneClient = () => {
    clonedClient.value = _cloneDeep(client.value);
};

const checkSavingBeforeLeave = (e) => {
    if (saving.value) {
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
    }
};

onBeforeRouteLeave((to, from, next) => {
    if (saving.value) {
        const answer = window.confirm(confirmationMessage);
        if (answer) {
            next();
        } else {
            next(false);
        }
    } else {
        next();
    }
});

watch(
    client,
    () => {
        if (clonedClient.value && client.value.id === clonedClient.value.id) {
            console.debug('client param changed');
            saving.value = true;
            _debounce(saveClient, 3000)();
        } else cloneClient();
    },
    { deep: true }
);

watch(selectedClientShortname, () => {
    new ClientDataService(store).getByShortname(selectedClientShortname.value).then((newClient) => {
        client.value = newClient;
    });
});

onMounted(() => {
    cloneClient();

    window.addEventListener('beforeunload', checkSavingBeforeLeave);
});

onUnmounted(() => {
    window.removeEventListener('beforeunload', checkSavingBeforeLeave);
});
</script>
