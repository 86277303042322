/**
 * Edit video Message Vuex store extension
 */

import _merge from 'lodash/merge';
import _omit from 'lodash/omit';
import { Align, Color, conversions, Dimension, Duration, Message, types } from 'cte-video-studio';
import { parseEditorMessage } from '@/js/extensions/tiptap/utils.js';
import { MESSAGE_ELEMENT_ID, CARD_CLIPBOARD_TYPE } from '@/js/constants';
import { filterState } from '@/js/videos/utils';
import { getPrefixedUuid } from '@/js/utils.js';
import timeline from '../timeline';
import Font from '@/js/video-studio/constants/font.js';

export const resolveMessageCardData = (data, findLibraryItem) => {
    let resolvedData = _merge({}, data);

    if (!findLibraryItem('fonts', '', data.font)) resolvedData.font = { value: null, custom: null };
    if (!findLibraryItem('messages', '', data.animation.type)) resolvedData.animation.type = Message.DEFAULT;

    return resolvedData;
};

const mergeTemplateData = (state, data, findLibraryItem) => {
    // First we remove potentially disabled component
    let resolvedData = resolveMessageCardData(data, findLibraryItem);

    // Then we keep the full template data, except for the text, and the potential image
    let key,
        omittedKeys = ['text'];
    for (key in resolvedData.images) {
        if (!!state.images[key]) {
            omittedKeys.push('images.' + key);
        }
    }

    // Finally we return the merge of the current state and the relevant template keys
    return _merge({}, state, _omit(resolvedData, omittedKeys));
};

// Initial state
const state = () => ({
    ...timeline.state(),

    id: '',
    path: [],
    text: '',
    font: {
        value: null,
        custom: null
    },
    fontScale: {
        value: Message.FONT_SCALE_DEFAULT,
        custom: null
    },
    align: null,
    color: {
        start: Color.NONE,
        end: Color.NONE,
        angle: Color.GRADIENT_ANGLE_DEFAULT,
        start__ref: Color.NONE,
        end__ref: Color.NONE
    },
    palette: {
        color1: Color.NONE,
        color1__ref: Color.NONE,
        color2: Color.NONE,
        color2__ref: Color.NONE,
        color3: Color.NONE,
        color3__ref: Color.NONE,
        color4: Color.NONE,
        color4__ref: Color.NONE,
        color5: Color.NONE,
        color5__ref: Color.NONE,
        quotes: Color.NONE,
        quotes__ref: Color.NONE
    },
    shadow: false,
    images: {
        image1: '',
        image2: '',
        image3: '',
        image4: '',
        image5: '',
        image1__ref: '',
        image1__id: '',
        image2__ref: '',
        image2__id: '',
        image3__ref: '',
        image3__id: '',
        image4__ref: '',
        image4__id: '',
        image5__ref: '',
        image5__id: ''
    },
    imageSelectors: 0,
    imageColor: {
        enabled: false,
        start: Color.NONE,
        end: Color.NONE,
        start__ref: Color.NONE,
        end__ref: Color.NONE,
        angle: Color.GRADIENT_ANGLE_DEFAULT
    },
    size: {
        autoSize: true,
        width: {
            value: Dimension.AUTO,
            custom: null
        },
        height: {
            enabled: false,
            value: Dimension.AUTO,
            custom: null
        }
    },
    rotation: 0,
    padding: {
        enabled: false
    },
    position: {
        alignH: Align.CENTER,
        alignV: Align.DISTRIBUTED_DEFAULT,
        alignX: Dimension.ZERO,
        alignY: Dimension.ZERO
    },
    animation: {
        type: Message.DEFAULT,
        start: {
            value: Duration.START_DEFAULT,
            custom: Duration.NONE
        },
        end: {
            value: Duration.END_DEFAULT,
            custom: Duration.NONE
        }
    }
});

// Getters
const getters = {
    font: (state, getters, rootState, rootGetters) => {
        let font = state.font.value != Font.CUSTOM ? state.font.value : state.font.custom;
        return font || rootGetters['branding/defaultFont'];
    },

    fontScale: (state) => conversions.optionValue(state.fontScale, [], Message.FONT_SCALE_DEFAULT),

    image: (state) => (index) => state.images['image' + index] || '',

    size: (state) => {
        let w = conversions.optionValue(state.size.width, Dimension.AUTO),
            h =
                (state.size.height.enabled && conversions.optionValue(state.size.height, Dimension.AUTO)) ||
                Dimension.AUTO;
        if (state.size.autoSize && w == Dimension.AUTO && h == Dimension.AUTO) {
            w = Dimension.MESSAGE_DEFAULT;
            // h = Dimension.MESSAGE_DEFAULT;
        }
        return {
            width: w,
            height: h
        };
    },

    position: (state) =>
        conversions.alignment(
            state.position.alignH,
            state.position.alignV,
            state.position.alignX,
            state.position.alignY
        ),

    padding: (state, getters, rootState) =>
        state.padding.enabled
            ? (rootState.display.format.height * Dimension.MESSAGE_PADDING_DEFAULT) / Format.HEIGHT_DEFAULT +
              Dimension.PIXEL_UNIT
            : 0,

    start: (state) => conversions.optionValue(state.animation.start, [], Duration.START_DEFAULT),

    end: (state) => conversions.optionValue(state.animation.end, [], Duration.END_DEFAULT),

    isDistributedAlign: (state) => {
        return (
            types.isDistributedAlign(state.position.alignH, false, false, true) ||
            types.isDistributedAlign(state.position.alignV, false, false, true)
        );
    },

    isHalfPosition: (state) => {
        return types.isHalfPosition(state.position.alignH) || types.isHalfPosition(state.position.alignV);
    },

    imageRef: (state) => (index) => state.images['image' + index + '__ref'] || '',

    imageId: (state) => (index) => state.images['image' + index + '__id'] || ''
};

// Mutations
const mutations = {
    ...timeline.mutations,

    setID(state, id) {
        state.id = id;
        state.path[state.path.length - 1] = id;
    },

    setPath(state, path) {
        state.path = path;
        state.id = path[path.length - 1];
    },

    setText(state, text) {
        state.text = text;
    },

    setFont(state, font) {
        if (types.isFont(font.value)) {
            state.font.value = font.value;
            state.font.custom = null;
        } else if (types.isFont(font.custom)) {
            state.font.value = Font.CUSTOM;
            state.font.custom = font.custom;
        } else {
            state.font.value = null;
            state.font.custom = null;
        }
    },

    setFontScale(state, fontScale) {
        if (types.isPositive(fontScale.value)) {
            state.fontScale.value = parseFloat(fontScale.value);
            state.fontScale.custom = null;
        } else if (types.isPositive(fontScale.custom)) {
            state.fontScale.value = Message.FONT_SCALE_CUSTOM;
            state.fontScale.custom = parseFloat(fontScale.custom);
        } else {
            state.fontScale.value = Message.FONT_SCALE_DEFAULT;
            state.fontScale.custom = null;
        }
    },

    setAlign(state, align) {
        state.align = types.isTextAlign(align) ? align : null;
    },

    enableShadow(state, enabled) {
        state.shadow = enabled;
    },

    enableImageColor(state, enabled) {
        state.imageColor.enabled = enabled;
    },

    enableAutoSize(state, enabled) {
        state.size.autoSize = enabled;
    },

    setWidth(state, width) {
        if (types.isDimension(width.value, true)) {
            state.size.width.value = width.value;
            state.size.width.custom = null;
        } else if (types.isDimension(width.custom, true)) {
            state.size.width.value = Dimension.CUSTOM;
            state.size.width.custom = width.custom;
        } else {
            state.size.width.value = Dimension.AUTO;
            state.size.width.custom = null;
        }
    },

    enableHeight(state, enabled) {
        state.size.height.enabled = enabled;
    },

    setHeight(state, height) {
        if (types.isDimension(height.value, true)) {
            state.size.height.value = height.value;
            state.size.height.custom = null;
        } else if (types.isDimension(height.custom, true)) {
            state.size.height.value = Dimension.CUSTOM;
            state.size.height.custom = height.custom;
        } else {
            state.size.height.value = Dimension.AUTO;
            state.size.height.custom = null;
        }
    },

    enablePadding(state, enabled) {
        state.padding.enabled = enabled;
    },

    setPosition(state, position) {
        state.position.alignH = types.isAlign(position.alignH) ? position.alignH : Align.CENTER;
        state.position.alignV = types.isAlign(position.alignV)
            ? position.alignV
            : types.isAlign(position.alignH)
              ? Align.CENTER
              : Align.DISTRIBUTED_DEFAULT;
        state.position.alignX =
            state.position.alignH == Align.CUSTOM && types.isDimension(position.alignX)
                ? position.alignX
                : Dimension.ZERO;
        state.position.alignY =
            state.position.alignV == Align.CUSTOM && types.isDimension(position.alignY)
                ? position.alignY
                : Dimension.ZERO;
    },

    setAnimation(state, type) {
        state.animation.type = types.isMessage(type) ? type : Message.DEFAULT;
    },

    setStart(state, start) {
        if (types.isZeroPositive(start.value)) {
            state.animation.start.value = parseFloat(start.value);
            state.animation.start.custom = Duration.NONE;
        } else if (types.isZeroPositive(start.custom)) {
            state.animation.start.value = Duration.CUSTOM;
            state.animation.start.custom = parseFloat(start.custom);
        } else {
            state.animation.start.value = Duration.START_DEFAULT;
            state.animation.start.custom = Duration.NONE;
        }
    },

    setEnd(state, end) {
        if (types.isZeroPositive(end.value)) {
            state.animation.end.value = parseFloat(end.value);
            state.animation.end.custom = Duration.NONE;
        } else if (types.isZeroPositive(end.custom)) {
            state.animation.end.value = Duration.CUSTOM;
            state.animation.end.custom = parseFloat(end.custom);
        } else {
            state.animation.end.value = Duration.END_DEFAULT;
            state.animation.end.custom = Duration.NONE;
        }
    },

    setColor(state, color) {
        state.color.start = types.isColor(color.start) ? color.start : Color.NONE;
        state.color.start__ref = color.start__ref || state.color.start;
        state.color.end = types.isColor(color.end) ? color.end : Color.NONE;
        state.color.end__ref = color.end__ref || state.color.end;
        state.color.angle = types.isAngle(color.angle) ? color.angle : Color.GRADIENT_ANGLE_DEFAULT;
    },

    setPalette(state, palette) {
        let p;
        for (p in palette) {
            if (!/__ref$/.test(p) && types.isColor(palette[p])) state.palette[p] = palette[p];
        }
        for (p in palette) {
            if (/__ref$/.test(p)) state.palette[p] = palette[p] || state.palette[p.replace(/__ref$/, '')];
        }
    },

    setImages(state, images) {
        let p;
        for (p in images) {
            if (!/(?:__ref|__id)$/.test(p) && images[p] != state.images[p]) state.images[p] = images[p];
        }
    },

    setImageReferences(state, images) {
        let p;
        for (p in images) {
            if (/__ref$/.test(p)) state.images[p] = images[p];
        }
    },

    setImageIds(state, images) {
        let p;
        for (p in images) {
            if (/__id$/.test(p)) state.images[p] = images[p];
        }
    },

    setImageSelectors(state, total) {
        state.imageSelectors = total || 0;
    },

    setImageColor(state, color) {
        state.imageColor.start = types.isColor(color.start) ? color.start : Color.NONE;
        state.imageColor.start__ref = color.start__ref || state.imageColor.start;
        state.imageColor.end = types.isColor(color.end) ? color.end : Color.NONE;
        state.imageColor.end__ref = color.end__ref || state.imageColor.end;
        state.imageColor.angle = types.isAngle(color.angle) ? color.angle : Color.GRADIENT_ANGLE_DEFAULT;
    },

    setRotationDegrees(state, rotationDegrees) {
        state.rotation = rotationDegrees || 0;
    }
};

// Actions
const actions = {
    ...timeline.actions,

    init({ commit, dispatch }, data) {
        commit('setText', data.text);
        commit('setFont', data.font);
        commit('setFontScale', data.fontScale);
        commit('setAlign', data.align);
        commit('setColor', data.color);
        commit('setPalette', data.palette);
        commit('enableShadow', data.shadow);
        commit('setImages', data.images);
        commit('enableImageColor', data.imageColor.enabled);
        commit('setImageColor', data.imageColor);
        commit('enableAutoSize', data.size.autoSize);
        commit('setWidth', data.size.width);
        commit('enableHeight', data.size.height.enabled);
        commit('setHeight', data.size.height);
        commit('enablePadding', data.padding.enabled);
        commit('setPosition', data.position);
        commit('setAnimation', data.animation.type);
        commit('setStart', data.animation.start);
        commit('setEnd', data.animation.end);
        dispatch('parseTextMarks');
        commit('setImageIds', data.images);
        commit('setImageReferences', data.images);
        commit('setImageSelectors', data.imageSelectors);
        commit('setRotationDegrees', data.rotation);
    },

    removeSelf({ commit, state }) {
        let seqPath = state.path.slice(0, -1).join('/');
        commit(
            seqPath + '/remove',
            {
                module: true,
                path: state.path
            },
            { root: true }
        );
    },

    moveSelf({ commit, state }, index) {
        // data: { id, index }
        let seqPath = state.path.slice(0, -1).join('/');
        commit(seqPath + '/move', { id: state.id, index }, { root: true });
    },

    initDefaults({ state, rootState, commit }) {
        let palette = Object.keys(state.palette).reduce((colors, key) => {
            if (/__ref$/.test(key)) return colors;

            switch (key) {
                case 'color1':
                case 'quotes':
                    if (!types.isColor(state.palette[key])) {
                        colors[key] = rootState.branding.palette.color1;
                        colors[key + '__ref'] = rootState.ui.prefixes.settingsReference + 'palette.color1';
                    } else {
                        colors[key] = state.palette[key];
                    }
                    break;
                default:
                    if (!types.isColor(state.palette[key])) {
                        colors[key] = rootState.branding.palette.color2;
                        colors[key + '__ref'] = rootState.ui.prefixes.settingsReference + 'palette.color2';
                    } else {
                        colors[key] = state.palette[key];
                    }
                    break;
            }

            return colors;
        }, {});

        commit('setPalette', palette);
    },

    parseTextMarks({ commit, state, rootGetters }) {
        let metadata = rootGetters['branding/libraries/findLibraryItem']('messages', '', state.animation.type) || null,
            activeMarks = (metadata ? metadata.marks : []).reduce((marks, name) => {
                marks[name] = true;
                return marks;
            }, {});
        commit('setText', parseEditorMessage(state.text, activeMarks));
    },

    updateImages({ commit }, images) {
        commit('setImageIds', images);
        commit('setImages', images);
    },

    applyTemplateMessage({ state, rootGetters, dispatch }, data) {
        dispatch('init', mergeTemplateData(state, data, rootGetters['branding/libraries/findLibraryItem']));
    },

    copyToClipboard({ dispatch, state }) {
        dispatch(
            'ui/clipboard/copy',
            {
                type: CARD_CLIPBOARD_TYPE + MESSAGE_ELEMENT_ID,
                data: filterState(state, [], ['id', 'path'])
            },
            { root: true }
        );
    },

    pasteCard({ dispatch, rootState, rootGetters }) {
        if (rootGetters['ui/clipboard/hasPastableDataOfType'](CARD_CLIPBOARD_TYPE + MESSAGE_ELEMENT_ID)) {
            const data =
                rootState.ui.clipboard.brandId == rootState.branding.id
                    ? rootState.ui.clipboard.data
                    : resolveMessageCardData(
                          rootState.ui.clipboard.data,
                          rootGetters['branding/libraries/findLibraryItem']
                      );

            for (const imageIndex of [1, 2, 3, 4, 5]) {
                const keyName = `image${imageIndex}__ref`;
                if (data.images[keyName]) data.images[keyName] = getPrefixedUuid(rootState.ui.prefixes.mediaReference);
            }

            dispatch('init', data);
        }
    }
};

export default {
    namespaced: true,

    state,
    getters,
    mutations,
    actions
};
