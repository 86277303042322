import { ref } from 'vue';
import FetchBrandsDataService from '@/js/application/services/brands/FetchBrandsDataService.js';
import BrandDTO from '@/js/dto/BrandDTO.js';
import { useStore } from 'vuex';
import usePagination from '@/js/composables/usePagination.js';

export function useBrands() {
    const store = useStore();

    const brands = ref([]);

    const { page, totalPages, isLoading, paginationRequest, resetData } = usePagination();

    const fetchBrands = async (options) => {
        let paginatedResult = await paginationRequest(() => new FetchBrandsDataService(store).fetchBrands(options));
        if (paginatedResult) addBrands(paginatedResult.data);
    };

    const addBrands = (items) => {
        brands.value = brands.value.concat(items.map((item) => new BrandDTO(item)));
    };

    const resetDataForBrands = () => {
        brands.value = [];
        resetData();
    };

    return {
        brands,
        page,
        totalPages,
        isLoading,
        fetchBrands,
        resetDataForBrands,
        addBrands
    };
}
