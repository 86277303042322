<template>
    <div class="ui-body" :class="containerClasses">
        <ui-scrollable
            class="ui-body-inner"
            ref="$scrollable"
            :scroll-from="['.ui-sidebar', '.ui-body']"
            :margin-top="20"
            :margin-bottom="20"
            :disabled="isSaving"
        >
            <ui-settings-panel v-show="currentPanel == settingsPanelId" />
            <ui-components-panel v-show="currentPanel == componentsPanelId" />
            <ui-sequences-panel
                v-if="canCreateTemplate('sequenceTemplates')"
                v-show="currentPanel == sequencesPanelId"
            />
            <ui-templates-panel v-show="currentPanel == templatesPanelId" />
        </ui-scrollable>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import {
    SETTINGS_BRAND_PANEL_ID,
    COMPONENTS_BRAND_PANEL_ID,
    SEQUENCES_BRAND_PANEL_ID,
    TEMPLATES_BRAND_PANEL_ID,
    LIBRARY_SORT_UPDATED_AT
} from '../../../../constants';
import { ProvidesIcons } from '../../../../mixins';
import { UiScrollable } from '../../../../components';
import UiSettingsPanel from './UiSettingsPanel.vue';
import UiComponentsPanel from './UiComponentsPanel.vue';
import UiSequencesPanel from './UiSequencesPanel.vue';
import UiTemplatesPanel from './UiTemplatesPanel.vue';

export default {
    mixins: [ProvidesIcons],

    components: {
        UiScrollable,
        UiSettingsPanel,
        UiComponentsPanel,
        UiSequencesPanel,
        UiTemplatesPanel
    },

    props: {
        //
    },

    data() {
        return {
            settingsPanelId: SETTINGS_BRAND_PANEL_ID,
            componentsPanelId: COMPONENTS_BRAND_PANEL_ID,
            sequencesPanelId: SEQUENCES_BRAND_PANEL_ID,
            templatesPanelId: TEMPLATES_BRAND_PANEL_ID,

            currentTemplateLibraryId: ''
        };
    },

    computed: {
        ...mapState({
            shortLanguage: (state) => state.ui.shortLanguage,
            currentPanel: (state) => state.ui.currentPanel,
            brand: (state) => state.branding
        }),

        ...mapGetters({
            canCreateTemplate: 'ui/canCreateTemplate',
            needsSave: 'ui/needsSave',
            isSaving: 'ui/isSaving'
        }),

        currentTemplateFilter() {
            return this.brand.libraries.filters[this.currentTemplateLibraryId] || '';
        },

        currentTemplateSortType() {
            return this.brand.libraries.sortTypes[this.currentTemplateLibraryId] || LIBRARY_SORT_UPDATED_AT;
        },

        currentTemplateLanguage() {
            return this.brand.libraries.languages[this.currentTemplateLibraryId] || this.shortLanguage;
        },

        containerClasses() {
            return {
                unsaved: this.needsSave,
                saving: this.isSaving
            };
        }
    },

    watch: {
        currentPanel(newValue) {
            this.updateTemplateLibraryId(newValue);
            this.refreshScrollable(true);
        },

        needsSave() {
            this.refreshScrollable();
        },

        currentTemplateFilter() {
            this.refreshScrollable(true);
        },

        currentTemplateSortType() {
            this.refreshScrollable(true);
        },

        currentTemplateLanguage() {
            this.refreshScrollable(true);
        }
    },

    methods: {
        updateTemplateLibraryId(panelId) {
            if (panelId == SEQUENCES_BRAND_PANEL_ID && this.canCreateTemplate('sequenceTemplates'))
                this.currentTemplateLibraryId = 'sequenceTemplates';
            else if (panelId == TEMPLATES_BRAND_PANEL_ID) this.currentTemplateLibraryId = 'templates';
            else this.currentTemplateLibraryId = '';
        },

        refreshScrollable(resetScroll) {
            this.$nextTick(() => {
                this.$refs.$scrollable.refresh(resetScroll);
            });
        }
    },

    created() {
        this.updateTemplateLibraryId(this.currentPanel);

        let currentHash = this.$route.hash.slice(1);
        if (currentHash) {
            this.$store.dispatch('ui/updateCurrentPanel', currentHash);
        }
    },

    mounted() {
        // TODO: Find why scrollable container hasn't its final height at this point
    }
};
</script>
