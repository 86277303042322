<template>
    <section class="ui-body-panel">
        <div class="ui-card-container">
            <ui-card id="edit-client-summary-card" :title="$t('Summary')">
                <template #header-icon>
                    <fa-icon class="icon" icon="fa-regular fa-square-list" />
                </template>
                <div class="ui-card-row">
                    <edit-client-summary :client="client" />
                </div>
            </ui-card>
            <ui-card id="edit-client-privacy-card" :title="$t('Privacy')">
                <template #header-icon>
                    <fa-icon class="icon" icon="fa-regular fa-lock" />
                </template>
                <div class="ui-card-row">
                    <edit-client-privacy v-model="client" />
                </div>
            </ui-card>
            <ui-card v-if="displayConfigEdition" id="edit-client-config-card" :title="$t('Configuration')">
                <template #header-icon>
                    <fa-icon class="icon" icon="fa-regular fa-screwdriver-wrench" />
                </template>
                <div class="ui-card-row">
                    <edit-client-config v-model="client" />
                </div>
            </ui-card>
        </div>
    </section>
</template>

<script setup>
import { computed } from 'vue';
import { useAuth } from '@/js/composables/useAuth.js';
import UiCard from '@/js/components/UiCard.vue';
import EditClientSummary from '@/views/EditClientView/components/EditClientSummary.vue';
import EditClientPrivacy from '@/views/EditClientView/components/EditClientPrivacy.vue';
import EditClientConfig from '@/views/EditClientView/components/EditClientConfig.vue';

const client = defineModel();

const { isCTE, isClientAdmin } = useAuth();

const displayConfigEdition = computed(() => {
    return (
        isCTE.value || (isClientAdmin.value && (client.value.validationAccessEnabled || client.value.enableLockingTags))
    );
});
</script>
