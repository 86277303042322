<template>
    <section class="ui-user-prompt ui-answer">
        <header class="ui-answer-user">
            <div class="ui-answer-user-avatar">
                <svg-icon icon="user-avatar-icon" />
            </div>
            <h2 class="ui-answer-user-name">{{ $t('You') }}</h2>
            <p class="ui-answer-user-title">{{ $t('Brief for my video') }}</p>
        </header>
        <ul class="ui-answer-content">
            <template v-for="(value, key) in promptData">
                <li>
                    <span class="ui-prompt-context-key">{{ sprintf($t('%s:'), $t('companion.brief.' + key)) }}</span>
                    <span class="ui-prompt-context-value">&nbsp;{{ value }}</span>
                </li>
            </template>
        </ul>
    </section>
</template>

<script>
import { printf as sprintf } from 'fast-printf';
import { UiIcon } from '../../../../components';

export default {
    components: {
        UiIcon
    },

    props: {
        prompt: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            //
        };
    },

    computed: {
        promptData() {
            if (!this.prompt?.name) return {};

            return Object.fromEntries(
                Object.entries({
                    input: this.prompt.input,
                    goal: this.prompt.context.goal || null,
                    key_elements: this.prompt.context.key_elements || null,
                    target: this.prompt.context.target || null,
                    place: this.prompt.context.place || null,
                    brand: this.prompt.brand.name || null,
                    tone: this.prompt.context.tone || null,
                    style: this.prompt.context.style || null,
                    format: this.prompt.context.format
                        ? this.$t('companion.formats.' + this.prompt.context.format)
                        : null,
                    duration: this.prompt.context.duration
                        ? sprintf($t('%s seconds'), this.prompt.context.duration)
                        : null,
                    language: this.prompt.context.language || null
                }).filter(([key, value]) => value !== null)
            );
        }
    },

    methods: {
        sprintf
    },

    mounted() {
        //
    }
};
</script>
