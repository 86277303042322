/**
 * Edit video Sequence audio Vuex store extension
 */

import _isEqualWith from 'lodash/isEqualWith';
import _cloneDeep from 'lodash/cloneDeep';
import { conversions, Duration, Sound, types } from 'cte-video-studio';
import card from '../card';
import timeline from '../timeline.js';
import sequence from './index.js';

const state = () => ({
    ...timeline.state(),
    ...card.state(),

    volume: {
        value: Sound.VOLUME_DEFAULT,
        custom: Sound.VOLUME_EMPTY
    },
    fade: {
        value: Sound.FADE_DEFAULT,
        custom: Sound.FADE_EMPTY
    },
    track: {
        src: '',
        src__ref: '',
        src__id: '',
        captioning: false,
        captioningStatus: null,
        captions: false,
        volume: {
            value: Sound.VOLUME_DEFAULT,
            custom: Sound.VOLUME_EMPTY
        },
        start: {
            value: Duration.START_DEFAULT,
            custom: Duration.NONE
        },
        timerange: { start: 0, end: -1 },
        timerangeSegments: [],
        totalDuration: () => 0
    }
});

// Getters
const getters = {
    audioTimeline: (state) => state.timeline,

    mainMusicVolume: (state, getters, rootState, rootGetters) =>
        conversions.optionValue(state.volume, [], Sound.VOLUME_DEFAULT) * rootGetters['settings/musicVolume'],

    mainTrackVolume: (state, getters, rootState, rootGetters) =>
        conversions.optionValue(state.volume, [], Sound.VOLUME_DEFAULT) * rootGetters['settings/trackVolume'],

    mainVolumeFade: (state) => conversions.optionValue(state.fade, [], Sound.FADE_DEFAULT),

    trackVolume: (state) => conversions.optionValue(state.track.volume, [], Sound.VOLUME_DEFAULT),

    trackStart: (state) => conversions.optionValue(state.track.start, [], Duration.START_DEFAULT),

    trackSrc: (state, getters, rootState) => {
        const hasTimerangeSegments =
            Array.isArray(state.track.timerangeSegments) && state.track.timerangeSegments.length > 0;

        //Optimize playback by using media fragments if quickcut is not open
        if (hasTimerangeSegments && !rootState.ui.quickcut.isQuickCutOpen) {
            const firstSegment = state.track.timerangeSegments[0];
            const lastSegment = state.track.timerangeSegments[state.track.timerangeSegments.length - 1];

            const start = firstSegment?.start ?? 0;
            const end = lastSegment?.end ?? state.track.totalDuration();

            return conversions.mediaFragmentAssetSrc(state.track.src, state.track.totalDuration(), {
                start,
                end
            });
        }

        return state.track.src;
    },
    
    hasAudioDirtyState: (state) => {
        // omit functions comparison
        return !_isEqualWith(state.track, sequence.modules.audio.state().track, (a, b) => {
            return typeof a === 'function' && typeof b === 'function' ? true : undefined;
        });
    }
};

// Mutations
const mutations = {
    setAudioTimeline: timeline.mutations.setTimeline,

    setMainVolume(state, volume) {
        let val = conversions.percentNum(volume.value, false),
            custom = conversions.percentNum(volume.custom, false);
        if (val !== undefined) {
            state.volume.value = val;
            state.volume.custom = Sound.VOLUME_EMPTY;
        } else if (custom !== undefined) {
            state.volume.value = Sound.VOLUME_CUSTOM;
            state.volume.custom = custom;
        } else {
            state.volume.value = Sound.VOLUME_DEFAULT;
            state.volume.custom = Sound.VOLUME_EMPTY;
        }
    },

    setMainVolumeFade(state, fade) {
        if (types.isZeroPositive(fade.value)) {
            state.fade.value = parseFloat(fade.value);
            state.fade.custom = Sound.FADE_EMPTY;
        } else if (types.isZeroPositive(fade.custom)) {
            state.fade.value = Sound.FADE_CUSTOM;
            state.fade.custom = parseFloat(fade.custom);
        } else {
            state.fade.value = Sound.FADE_DEFAULT;
            state.fade.custom = Sound.FADE_EMPTY;
        }
    },

    setTrack(state, src) {
        state.track.src = src;
    },

    setTrackCaptions(state, captions) {
        state.track.captions = Array.isArray(captions) && !!captions.length && captions;
    },

    setTrackVolume(state, volume) {
        let val = conversions.percentNum(volume.value, false),
            custom = conversions.percentNum(volume.custom, false);
        if (val !== undefined) {
            state.track.volume.value = val;
            state.track.volume.custom = Sound.VOLUME_EMPTY;
        } else if (custom !== undefined) {
            state.track.volume.value = Sound.VOLUME_CUSTOM;
            state.track.volume.custom = custom;
        } else {
            state.track.volume.value = Sound.VOLUME_DEFAULT;
            state.track.volume.custom = Sound.VOLUME_EMPTY;
        }
    },

    setTrackStart(state, start) {
        if (types.isZeroPositive(start.value)) {
            state.track.start.value = parseFloat(start.value);
            state.track.start.custom = Duration.NONE;
        } else if (types.isZeroPositive(start.custom)) {
            state.track.start.value = Duration.CUSTOM;
            state.track.start.custom = parseFloat(start.custom);
        } else {
            state.track.start.value = Duration.START_DEFAULT;
            state.track.start.custom = Duration.NONE;
        }
    },

    setTrackTimerange(state, timerange) {
        state.track.timerange = Object.assign({ start: 0, end: -1 }, timerange || {});
    },
    
    setTrackTimerangeSegments(state, segments) {
        state.track.timerangeSegments = Array.isArray(segments) && !!segments.length ? _cloneDeep(segments) : [];
    },

    setTrackTotalDuration(state, duration) {
        state.track.totalDuration = () => duration;
    },

    setTrackReference(state, ref) {
        state.track.src__ref = ref;
    },

    setTrackId(state, id) {
        state.track.src__id = id;
    },

    setTrackCaptioning(state, captioning) {
        state.track.captioning = !!captioning;
    },

    setTrackCaptioningStatus(state, status) {
        state.track.captioningStatus = status;
    },

    showAudioCard: card.mutations.showCard
};

// Actions
const actions = {
    initAudio({ commit, dispatch, getters }, data) {
        commit('setMainVolume', data.volume);
        commit('setMainVolumeFade', data.fade);
        commit('setTrack', data.track.src);
        commit('setTrackCaptions', data.track.captions);
        commit('setTrackVolume', data.track.volume);
        commit('setTrackStart', data.track.start);
        commit('setTrackTimerange', data.track.timerange);
        commit('setTrackTimerangeSegments', data.track.timerangeSegments);
        commit('setTrackId', data.track.src__id);
        commit('setTrackReference', data.track.src__ref);
        commit('setTrackCaptioning', data.track.captioning);

        if (getters.hasAudioDirtyState) commit('showAudioCard', true);

        if (data.track.captioning) {
            dispatch('startTrackCaptioning');
        }
    },

    updateTrack({ commit, state }, data) {
        if (!data.src__id) {
            commit('setTrackTotalDuration', 0);
        }
        if (state.track.src__id !== data.src__id) {
            commit('setTrackTimerange', { start: 0, end: -1 });
        }

        commit('setTrackId', data.src__id);
        commit('setTrack', data.src);
    },

    startTrackCaptioning(context) {
        let { commit, dispatch, state, getters } = context;

        if (!!state.track.src__id) {
            commit('setTrackCaptioning', true);
            dispatch(
                'ui/processes/startMediaCaptioningProcess',
                {
                    mediaId: state.track.src__id,
                    subscriberId: getters.path + '/setTrackCaptions',
                    success: (captions) => {
                        dispatch('ui/history/startStep', null, { root: true });
                        commit('setTrackCaptions', captions);
                        commit('setTrackCaptioning', false);
                        commit('setTrackCaptioningStatus', null);
                        dispatch('ui/saveVideo', null, { root: true });
                    },
                    error: (error) => {
                        commit('setTrackCaptioning', false);
                        commit('setTrackCaptioningStatus', error);
                        dispatch('ui/saveVideo', null, { root: true });
                    }
                },
                { root: true }
            );
        }
    },

    cancelTrackCaptioning({ commit, dispatch, state, getters }) {
        if (!!state.track.src__id && state.track.captioning) {
            dispatch(
                'ui/processes/cancelMediaCaptioningProcess',
                {
                    mediaId: state.track.src__id,
                    subscriberId: getters.path + '/setTrackCaptions'
                },
                { root: true }
            );
            commit('setTrackCaptioning', false);
            commit('setTrackCaptioningStatus', null);
            dispatch('ui/saveVideo', null, { root: true });
        }
    },

    showAudioCard({ commit }, show) {
        if (show) commit('showAudioCard', show);
        else commit('resetAudioState');
    },

    pasteAudioCard({ commit, getters }, data) {
        commit('setTrack', data.src);
        commit('setTrackId', data.src__id);
        commit('setTrackReference', data.src__ref);
        commit('setTrackCaptions', data.captions);
        commit('setTrackVolume', data.volume);
        commit('setTrackStart', data.start);
        if (getters.hasAudioDirtyState) commit('showAudioCard', true);
    }
};

export default {
    state,
    getters: getters,
    mutations: mutations,
    actions: actions
};
