/**
 * Video Studio
 * Color constants
 */

const CSS_REGEXP =
    /^(#[0-9a-f]{3}|#([0-9a-f]{2}){2,4}|rgb\((-?\d+\s*,\s*){2}(-?\d+\s*)\)|rgb\((-?\d+%\s*,\s*){2}(-?\d+%\s*)\)|rgba\((-?\d+\s*,\s*){3}(-?\d+(\.\d+)?\s*)\)|rgba\((-?\d+%\s*,\s*){3}(-?\d+(\.\d+)?\s*)\)|hsl\((-?\d+\s*,\s*)(-?\d+%\s*,\s*)(-?\d+%\s*)\)|hsla\((-?\d+\s*,\s*)(-?\d+%\s*,\s*){2}(-?\d+(\.\d+)?\s*)\))$/i;

const WHITE = '#fff';
const BLACK = '#000';
const TRANSPARENT = 'transparent';
const NONE = '';
const CARTOON_START_COLOR = '#2a64c7';
const CARTOON_SECOND_COLOR = '#abb6c8';
const CARTOON_THIRD_COLOR = '#f4bcad';
const SKINS = [CARTOON_THIRD_COLOR, '#FBEACF', '#FFDDB8', '#E6BB8E', '#C68E60', '#A26330', '#5B4436'];

const OPACITY_FULL = 1;
const OPACITY_NONE = 0;
const OPACITY_EMPTY = null;
const OPACITY_CUSTOM = 'custom';

const GRADIENT_ANGLE_TO_TOP = 0;
const GRADIENT_ANGLE_TO_RIGHT = 90;
const GRADIENT_ANGLE_TO_BOTTOM = 180;
const GRADIENT_ANGLE_TO_LEFT = 270;

const Color = {
    CSS_REGEXP,

    WHITE,
    BLACK,
    TRANSPARENT,
    CARTOON_START_COLOR,
    CARTOON_SECOND_COLOR,
    CARTOON_THIRD_COLOR,
    SKINS,
    NONE,

    OPACITY_FULL,
    OPACITY_NONE,
    OPACITY_EMPTY,
    OPACITY_CUSTOM,

    GRADIENT_ANGLE_TO_TOP,
    GRADIENT_ANGLE_TO_RIGHT,
    GRADIENT_ANGLE_TO_BOTTOM,
    GRADIENT_ANGLE_TO_LEFT,

    CAPTIONS_DEFAULT: WHITE,
    BORDER_DEFAULT: BLACK,
    TRANSITION_DEFAULT: BLACK,
    BACKGROUND_DEFAULT: WHITE,
    PANEL_DEFAULT: BLACK,
    MESSAGE_DEFAULT: BLACK,
    OPACITY_DEFAULT: OPACITY_FULL,
    BACKGROUND_OPACITY_DEFAULT: 0.7,
    GRADIENT_ANGLE_DEFAULT: GRADIENT_ANGLE_TO_TOP
};

export default Color;
