import { Node, mergeAttributes } from '@tiptap/core';
import { Slice } from 'prosemirror-model';
import { TextSelection } from 'prosemirror-state';

const HighlightGroupSeparator = Node.create({
    name: 'highlightGroupSeparator',

    inline: true,
    group: 'inline',
    atom: true,

    addOptions() {
        return {
            enabled: true
        };
    },

    parseHTML() {
        return [
            {
                tag: 'br[data-highlight-group-separator]'
            }
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['br', mergeAttributes(HTMLAttributes, { 'data-highlight-group-separator': '' })];
    },

    renderText() {
        return ' ';
    },

    addCommands() {
        return {
            insertHighlightGroupSeparator:
                (force = false) =>
                ({ editor, commands, tr }) => {
                    if (editor.isActive('highlightGroup') || force) return commands.insertContent({ type: this.name });
                }
        };
    },

    addKeyboardShortcuts() {
        return {
            Backspace: () =>
                this.editor.commands.command(({ tr, state }) => {
                    let isSeparator = false,
                        { empty, anchor } = state.selection;

                    if (!empty) return false;

                    state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
                        if (node.type.name === this.name) {
                            isSeparator = true;
                            tr.insertText('', pos, pos + node.nodeSize);

                            return false;
                        }
                    });

                    return isSeparator;
                })
        };
    }
});

const HighlightGroup = Node.create({
    name: 'highlightGroup',

    inline: true,
    group: 'inline',

    content() {
        return 'text* (' + this.options.separatorTypeName + ' text*)*';
    },

    defining: true,
    definingForContent: true,
    selectable: true,

    addOptions() {
        return {
            enabled: true,
            separatorTypeName: 'highlightGroupSeparator',
            HTMLAttributes: {}
        };
    },

    addExtensions() {
        return [HighlightGroupSeparator.configure({ enabled: this.options.enabled })];
    },

    parseHTML() {
        return [
            {
                tag: 'q[data-highlight-group]'
            }
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['q', mergeAttributes({ 'data-highlight-group': '' }, this.options.HTMLAttributes, HTMLAttributes), 0];
    },

    addCommands() {
        return {
            setHighlightGroup:
                () =>
                ({ editor, state, chain }) => {
                    if (!editor.isActive(this.name) && !state.selection.empty) {
                        let { $from, $to } = state.selection,
                            replaceRange = { from: $from.pos, to: $to.pos },
                            selectionRange = { from: 1, to: -1 },
                            nodeContent = [];

                        if ($from.nodeBefore?.type.name == this.name) {
                            replaceRange.from = $from.pos - $from.nodeBefore.nodeSize;
                            selectionRange.from = $from.nodeBefore.nodeSize - 1;
                        } else if ($from.parent.type.name == this.name) {
                            replaceRange.from = $from.before();
                            selectionRange.from = $from.parentOffset + 1;
                        }
                        if ($to.nodeAfter?.type.name == this.name) {
                            replaceRange.to = $to.pos + $to.nodeAfter.nodeSize;
                            selectionRange.to = 1 - $to.nodeAfter.nodeSize;
                        } else if ($to.parent.type.name == this.name) {
                            replaceRange.to = $to.after();
                            selectionRange.to = $to.pos - $to.after();
                        }

                        let slice = state.doc.slice(replaceRange.from, replaceRange.to),
                            totalParagraphs = 0;

                        slice.content.descendants((node, position) => {
                            if (node.type.name == this.name) return;

                            if (node.type.name == 'paragraph') {
                                totalParagraphs++;
                                if (totalParagraphs > 1) {
                                    nodeContent.push({ type: 'text', text: ' ' });
                                }
                                return;
                            }

                            nodeContent.push(node.toJSON());
                            return false;
                        });

                        return chain()
                            .insertContentAt(replaceRange, { type: this.name, content: nodeContent })
                            .command(({ tr, dispatch }) => {
                                if (dispatch) {
                                    let { $to } = tr.selection,
                                        from = $to.pos - $to.nodeBefore.nodeSize + selectionRange.from,
                                        to = $to.pos + selectionRange.to;
                                    tr.setSelection(TextSelection.create(tr.doc, from, to));
                                    tr.scrollIntoView();
                                }

                                return true;
                            })
                            .run();
                    }
                },

            unsetHighlightGroup:
                () =>
                ({ editor, state, chain }) => {
                    if (editor.isActive(this.name) && !state.selection.empty) {
                        let { $from, $to } = state.selection,
                            replaceRange = { from: $from.before(), to: $to.after() },
                            parentNode = $from.parent,
                            nodeContent = [],
                            nodeBefore,
                            nodeAfter;

                        state.selection.content().content.descendants((node, position) => {
                            if (node.type.name == 'paragraph' || node.type.name == this.name) return;

                            if (node.type.name == this.options.separatorTypeName) {
                                nodeContent.push({ type: 'text', text: ' ' });
                            } else {
                                nodeContent.push(node.toJSON());
                            }

                            return false;
                        });

                        nodeBefore = parentNode.cut(0, $from.parentOffset);
                        nodeAfter = parentNode.cut($to.parentOffset);
                        if (!!nodeBefore.childCount) nodeContent.unshift(nodeBefore.toJSON());
                        if (!!nodeAfter.childCount) nodeContent.push(nodeAfter.toJSON());

                        return chain()
                            .insertContentAt(replaceRange, nodeContent)
                            .command(({ tr, dispatch }) => {
                                if (dispatch) {
                                    let { $to } = tr.selection,
                                        from = replaceRange.from + (!!nodeBefore.childCount ? nodeBefore.nodeSize : 0),
                                        to = $to.pos + (!!nodeAfter.childCount ? -nodeAfter.nodeSize : 0);
                                    tr.setSelection(TextSelection.create(tr.doc, from, to));
                                    tr.scrollIntoView();
                                }

                                return true;
                            })
                            .run();
                    }
                },

            toggleHighlightGroup:
                () =>
                ({ editor, commands }) => {
                    return !editor.isActive(this.name) ? commands.setHighlightGroup() : commands.unsetHighlightGroup();
                },

            insertHighlightGroupTemplate:
                (...sentences) =>
                ({ editor, commands }) => {
                    if (!editor.isActive(this.name)) {
                        let sentenceNodes = !!sentences.length ? [] : [{ type: 'text', text: ' ' }];

                        return commands.insertContent({
                            type: this.name,
                            content: sentences.reduce((nodes, sentence) => {
                                if (!!nodes.length) {
                                    nodes.push({ type: this.options.separatorTypeName });
                                }
                                nodes.push({ type: 'text', text: sentence });
                                return nodes;
                            }, sentenceNodes)
                        });
                    }
                }
        };
    }
});

HighlightGroup.parseStudioMessage = (htmlContent, enabled = true, remove = false) => {
    return enabled || remove
        ? htmlContent
              .replace(/\{\{/g, !remove ? '<q data-highlight-group>' : '')
              .replace(/\}\}/g, !remove ? '</q>' : '')
              .replace(/\s*(?:(&[^;\s]+?;)|;)\s*/g, (match, entity) =>
                  !entity ? (!remove ? '<br data-highlight-group-separator>' : '') : match
              )
        : htmlContent;
};

HighlightGroup.toStudioMessage = (htmlContent, enabled = true, remove = false) => {
    return enabled || remove
        ? htmlContent
              .replace(/<q data\-highlight\-group(?:="[^"]*")?>/g, !remove ? '{{' : '')
              .replace(/<\/q>/g, !remove ? '}}' : '')
              .replace(/<br data\-highlight\-group\-separator(?:="[^"]*")?>/g, !remove ? ';' : '')
        : htmlContent;
};

HighlightGroup.parseEditorMessage = (editor) => {
    let { doc } = editor.view.state,
        chain,
        tr = editor.captureTransaction(() => {
            chain = editor.chain();
            chain.run();
        });

    tr.setMeta('preventUpdate', true);

    let started = false,
        startPos = -1;

    doc.descendants((node, position) => {
        if (!node.text) return;

        let text = node.text,
            searchRegExp = !started ? /\{\{/ : /\}\}/,
            searchedIndex = -1;

        while ((searchedIndex = text.search(searchRegExp)) != -1) {
            if (searchedIndex != -1) {
                if (!started) {
                    startPos = position + searchedIndex;
                } else {
                    let endIndex = position + searchedIndex;

                    // Making selection directly on transaction because tiptap's setTextSelection doesn't map positions
                    tr.setSelection(
                        TextSelection.create(tr.doc, tr.mapping.map(startPos), tr.mapping.map(endIndex + 2))
                    );

                    // Adding highlight group marks
                    chain = chain.setHighlightGroup();

                    // Removing highlight studio marks
                    let slice = tr.selection.content();
                    slice.content = slice.content.cut(4, slice.content.size - 4);
                    tr.replaceSelection(!!slice.content.size ? slice : Slice.empty);

                    startPos = -1;
                }

                started = !started;
                position += searchedIndex + 2;
                text = text.slice(searchedIndex + 2);
                searchRegExp = !started ? /\{\{/ : /\}\}/;
            }
        }
    });

    let searchSeparatorRegExp = /;/,
        groupEndPos = -1;

    tr.doc.descendants((node, position) => {
        if (node.type.name == 'highlightGroup') {
            groupEndPos = position + node.nodeSize;
            return;
        }

        if (!node.text || position >= groupEndPos) return;

        let text = node.text,
            searchedSeparatorIndex = -1;

        while ((searchedSeparatorIndex = text.search(searchSeparatorRegExp)) != -1) {
            let separatorIndex = position + searchedSeparatorIndex;

            // Making selection directly on transaction because tiptap's setTextSelection doesn't map positions
            tr.setSelection(TextSelection.create(tr.doc, separatorIndex, separatorIndex + 1));

            // Insert highlight group separator
            chain = chain.insertHighlightGroupSeparator(true);

            position += searchedSeparatorIndex + 1;
            text = text.slice(searchedSeparatorIndex + 1);
        }
    });

    chain.setTextSelection(0).run();
};

export { HighlightGroup, HighlightGroup as default };
