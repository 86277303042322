/**
 * Video Studio
 * Loader
 */

import { fetch } from 'whatwg-fetch';

let $store,
    files = {};

const init = (store) => {
    $store = store;
};

const load = (path, error) => {
    $store.commit('loading/add', path);
    if (!files[path]) {
        let promise = fetch(path);
        promise
            .then((response) => {
                if (response.ok) return response.blob();
                throw new Error(response.statusText);
            })
            .then((blob) => {
                files[path].blob = blob;
                $store.commit('loading/loaded', { path, blob: URL.createObjectURL(files[path].blob) });
            })
            .catch(callErrorCallbacks(path));
        files[path] = {
            promise,
            blob: false,
            callbacks: []
        };
    } else if (!$store.getters['loading/isLoaded'](path) && !!files[path].blob) {
        $store.commit('loading/loaded', { path, blob: URL.createObjectURL(files[path].blob) });
    }

    if (error && error instanceof Function && files[path].callbacks.indexOf(error) == -1) {
        files[path].callbacks.push(error);
        if ($store.getters['loading/hasError'](path)) error();
    }
};

const refresh = (path, error) => {
    let promise = fetch(path);
    promise
        .then((response) => {
            if (response.ok) return response.blob();
            throw new Error(response.statusText);
        })
        .then((blob) => {
            files[path].blob = blob;
            $store.commit('loading/loaded', { path, blob: URL.createObjectURL(files[path].blob) });
            console.log('media refresh : ' + path);
        })
        .catch(callErrorCallbacks(path));

    files[path] = {
        promise,
        blob: false,
        callbacks: []
    };

    if (error && error instanceof Function && files[path].callbacks.indexOf(error) == -1) {
        files[path].callbacks.push(error);
        if ($store.getters['loading/hasError'](path)) error();
    }
};

const callErrorCallbacks = (path) => () => {
    $store.commit('loading/error', path);
    files[path].callbacks.forEach((callback) => {
        callback();
    });
};

const unload = (path, error) => {
    $store.commit('loading/remove', path);
    let ei = files[path].callbacks.indexOf(error);
    if (ei != -1) files[path].callbacks.splice(ei, 1);
};

export default {
    init,
    load,
    refresh,
    unload
};
