/**
 * Edit video Settings captions Vuex store extension
 */

import { Color, types, Caption, Align, Font, Dimension, conversions, Format } from 'cte-video-studio';

const state = {
    font: {
        value: null,
        custom: null
    },
    fontScale: {
        value: Caption.FONT_SCALE_DEFAULT,
        custom: null
    },
    align: Align.TEXT_CENTER,
    style: Font.STYLE_BOLD,
    background: {
        color: {
            start: Color.NONE,
            end: Color.NONE,
            angle: Color.GRADIENT_ANGLE_DEFAULT,
            start__ref: Color.NONE,
            end__ref: Color.NONE
        },
        opacity: Color.OPACITY_DEFAULT
    },
    color: {
        start: Color.CAPTIONS_DEFAULT,
        end: Color.NONE,
        angle: Color.GRADIENT_ANGLE_DEFAULT,
        start__ref: Color.CAPTIONS_DEFAULT,
        end__ref: Color.NONE
    },
    shadow: true,
    padding: {
        enabled: true
    },
    position: {
        alignH: Align.CENTER,
        alignV: Align.BOTTOM,
        alignX: Dimension.ZERO,
        alignY: Dimension.ZERO
    }
};

// Getters
const getters = {
    captionsFont: (state, getters, rootState, rootGetters) => {
        let font = state.font.value != Font.CUSTOM ? state.font.value : state.font.custom;
        return font || rootGetters['branding/defaultFont'];
    },

    captionsFontScale: (state) => conversions.optionValue(state.fontScale, [], Caption.FONT_SCALE_DEFAULT),

    captionsPadding: (state, getters, rootState) =>
        state.padding.enabled
            ? (rootState.display.format.height * Dimension.CAPTIONS_PADDING_DEFAULT) / Format.HEIGHT_DEFAULT +
              Dimension.PIXEL_UNIT
            : 0
};

// Mutations
const mutations = {
    setFont(state, font) {
        if (types.isFont(font.value)) {
            state.font.value = font.value;
            state.font.custom = null;
        } else if (types.isFont(font.custom)) {
            state.font.value = Font.CUSTOM;
            state.font.custom = font.custom;
        } else {
            state.font.value = null;
            state.font.custom = null;
        }
    },

    setFontScale(state, fontScale) {
        if (types.isPositive(fontScale.value)) {
            state.fontScale.value = parseFloat(fontScale.value);
            state.fontScale.custom = null;
        } else if (types.isPositive(fontScale.custom)) {
            state.fontScale.value = Caption.FONT_SCALE_CUSTOM;
            state.fontScale.custom = parseFloat(fontScale.custom);
        } else {
            state.fontScale.value = Caption.FONT_SCALE_DEFAULT;
            state.fontScale.custom = null;
        }
    },

    setAlign(state, align) {
        state.align = types.isTextAlign(align) ? align : Align.TEXT_CENTER;
    },

    setStyle(state, style) {
        state.style = types.isFontStyle(style) ? style : Font.STYLE_BOLD;
    },

    enableShadow(state, enabled) {
        state.shadow = enabled;
    },

    enablePadding(state, enabled) {
        state.padding.enabled = enabled;
    },

    setPosition(state, position) {
        state.position.alignH = types.isAlign(position.alignH) ? position.alignH : Align.CENTER;
        state.position.alignV = types.isAlign(position.alignV) ? position.alignV : Align.BOTTOM;
        state.position.alignX =
            state.position.alignH == Align.CUSTOM && types.isDimension(position.alignX)
                ? position.alignX
                : Dimension.ZERO;
        state.position.alignY =
            state.position.alignV == Align.CUSTOM && types.isDimension(position.alignY)
                ? position.alignY
                : Dimension.ZERO;
    },

    setBackgroundOpacity(state, opacity) {
        state.background.opacity = conversions.percentNum(opacity, false) || Color.OPACITY_DEFAULT;
    },

    setBackground(state, background) {
        state.background.color.start = types.isColor(background.color.start) ? background.color.start : Color.NONE;
        state.background.color.start__ref = background.color.start__ref || state.background.color.start;
        state.background.color.end = types.isColor(background.color.end) ? background.color.end : Color.NONE;
        state.background.color.end__ref = background.color.end__ref || state.background.color.end;
        state.background.color.angle = types.isAngle(background.color.angle)
            ? background.color.angle
            : Color.GRADIENT_ANGLE_DEFAULT;
    },

    setColor(state, color) {
        state.color.start = types.isColor(color.start) ? color.start : Color.CAPTIONS_DEFAULT;
        state.color.start__ref = color.start__ref || state.color.start;
        state.color.end = types.isColor(color.end) ? color.end : Color.NONE;
        state.color.end__ref = color.end__ref || state.color.end;
        state.color.angle = types.isAngle(color.angle) ? color.angle : Color.GRADIENT_ANGLE_DEFAULT;
    }
};

// Actions
const actions = {
    initCaptions({ commit }, data) {
        commit('setFont', data.font);
        commit('setFontScale', data.fontScale);
        commit('setAlign', data.align);
        commit('setBackground', data.background);
        commit('setBackgroundOpacity', data.background.opacity);
        commit('setColor', data.color);
        commit('setStyle', data.style);
        commit('enableShadow', data.shadow);
        commit('enablePadding', data.padding.enabled);
        commit('setPosition', data.position);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
