import { setGlobalConfig, setGlobalVideoData } from './utilsInit';
import loader from '@/js/video-studio/loader.js';
import { Production } from '@/js/video-studio/constants/index.js';
import { getLoadingProductionSequence } from '@/js/video-studio/utils/index.js';

export default class StudioProduceInitializer {
    constructor(store, videoStudioService) {
        this.store = store;
        this.videoStudioService = videoStudioService;
    }

    async initVideoStudio(productionId) {
        try {
            const production = await this.videoStudioService.getProductionResource(productionId);

            setGlobalConfig(production.data.config);
            setGlobalVideoData(production.data.videoResource);
            loader.init(this.store);

            this.initStore(production.data.config, production.data.videoResource);
        } catch (error) {
            return { errorCode: error.response?.status || 500 };
        }
    }

    initStore(config, resource) {
        this.store.dispatch('display/setReadOnly', true);

        this.store.dispatch('display/setFormat', {
            width: resource.display.format.width,
            height: resource.display.format.height
        });

        this.store.dispatch('branding/init', resource.branding);
        this.store.dispatch('settings/init', resource.settings);

        // In production mode only: insert a first empty sequence to avoid lag on first video
        this.store.dispatch('sequences/addSequence', { item: getLoadingProductionSequence() });

        for (let i = 0; i < resource.sequences.length; ++i) {
            this.store.dispatch('sequences/addSequence', { item: resource.sequences[i] });
        }

        this.store.commit('display/setCaptureMode', Production.FULL_CAPTURE_MODE);
    }
}
