export default class GetFormatNameService {
    handle(format) {
        if (format.width > format.height) {
            return 'landscape';
        } else if (format.width < format.height) {
            return 'portrait';
        } else {
            return 'square';
        }
    }
}
