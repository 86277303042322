<template>
    <main class="ui-view ui-view-light-grey-bg" id="folders-view">
        <ui-view-header :title="$t('Folders')" :enable-search="true" v-model="searchFilter">
            <template #header-tools-left>
                <client-selector v-if="isCTE" v-model="selectedClientShortname" />
            </template>
            <template #header-tools v-if="user.role.canAddProjects">
                <div class="view-header-action">
                    <button class="ui-simple-button-transparent" id="add-new-project" @click="createFolder">
                        <fa-icon class="icon" icon="fa-solid fa-plus" />
                    </button>
                </div>
            </template>
        </ui-view-header>
        <div class="ui-view-items-list">
            <UiInfiniteScrollPaginator
                v-model="page"
                :max-page="totalPages"
                :loading="isLoading"
                :use-scrollable="false"
            >
                <folders-list-item v-for="folder in folders" :key="folder.id" :folder="folder" />
                <template v-slot:loading>
                    <div class="folder-loader-container">
                        <LoadingMarker v-if="isLoading" />
                    </div>
                </template>
            </UiInfiniteScrollPaginator>
        </div>

        <div v-if="user.role.canAddProjects" class="ui-folders-view-create">
            <button class="ui-simple-button ui-simple-button-select" @click="createFolder">
                <fa-icon icon="fa-solid fa-plus" class="icon" />
                <span>{{ $t('Create new folder') }}</span>
            </button>
        </div>
    </main>
</template>

<script setup>
import '@/sass/views/folders-view.scss';
import { computed, onMounted, ref, watch } from 'vue';
import UiViewHeader from '@/js/components/UiViewHeader.vue';
import { useModal } from '@/js/composables/useModal.js';
import { useAuth } from '@/js/composables/useAuth.js';
import { useFolders } from '@/js/composables/useFolders.js';
import FoldersListItem from '@/js/components/folders/FoldersListItem.vue';
import LoadingMarker from '@/js/components/LoadingMarker.vue';
import UiInfiniteScrollPaginator from '@/js/components/UiInfiniteScrollPaginator.vue';
import { debounce } from 'lodash';
import ClientSelector from '@/js/components/clients/ClientSelector.vue';

const { openModal } = useModal();
const { user, isCTE } = useAuth();

const selectedClientShortname = ref(user.value.client.shortname);

const searchFilter = ref('');

const options = computed(() => ({
    page: page.value,
    search: searchFilter.value
}));

const { page, totalPages, isLoading, folders, fetchFolders, resetDataForFolders } = useFolders();

const createFolder = () => {
    openModal('CreateFolderModal', { clientShortname: selectedClientShortname.value });
};

const debounceSearchFolders = debounce(() => {
    fetchFolders(selectedClientShortname.value, options.value);
}, 500);

watch(page, () => {
    fetchFolders(selectedClientShortname.value, options.value);
});

watch(selectedClientShortname, () => {
    resetDataForFolders();
    fetchFolders(selectedClientShortname.value, options.value);
});

watch(searchFilter, () => {
    resetDataForFolders();
    debounceSearchFolders();
});

onMounted(() => {
    fetchFolders(selectedClientShortname.value, options.value);
});
</script>
