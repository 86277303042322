<template>
    <ui-dropdown
        :id="'card-seq-' + cardElementId + '-bg-type'"
        :select="true"
        v-model="selectedBackgroundType"
        :disabled="readOnly"
        :key="backgroundTypesRender"
        menu-strategy="fixed"
    >
        <template #dropdown-toggle="{ instance }">
            <component
                :is="backgroundTypeIcons[instance.selectedValue.toLowerCase()].component"
                :icon="backgroundTypeIcons[instance.selectedValue.toLowerCase()].name"
                class="icon"
            />
            <span>{{ instance.toggleLabel }}</span>
        </template>

        <ui-dropdown-item
            v-for="type in backgroundTypes"
            :key="type"
            :value="type"
            :label="$t('studio.background_types.' + type)"
            :disabled="shouldDisablePreviousType || recordingDisabled(type)"
            :show-icon-on-disabled="true"
            v-tooltip="recordingDisabled(type) && $t('studio.features.recording-disabled')"
        >
            <template #dropdown-menu-item-label="{ instance }">
                <component
                    :is="backgroundTypeIcons[instance.value.toLowerCase()].component"
                    :icon="backgroundTypeIcons[instance.value.toLowerCase()].name"
                    class="icon"
                />
                {{ instance.label || instance.value }}
            </template>
        </ui-dropdown-item>
    </ui-dropdown>
</template>

<script>
import { ref, watch, defineComponent } from 'vue';
import UiDropdown from '../../../../../../components/UiDropdown.vue';
import UiDropdownItem from '../../../../../../components/UiDropdownItem.vue';
import { backgroundTypeIcons } from '@/js/constants/icons.js';
import { useStore } from 'vuex';
import { UsesTooltip } from '@/js/mixins/index.js';
import { RECORDING_ELEMENT_ID } from '@/js/constants/index.js';

export default defineComponent({
    name: 'UiMediaTypeSelector',
    components: {
        UiDropdown,
        UiDropdownItem
    },
    props: {
        seqId: String,
        cardElementId: String,
        readOnly: Boolean,
        backgroundType: String,
        backgroundTypes: Array,
        shouldDisablePreviousType: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:backgroundType'],
    mixins: [UsesTooltip],
    setup(props, { emit }) {
        const store = useStore();
        const backgroundTypesRender = ref(0);
        const selectedBackgroundType = ref(props.backgroundType);

        const recordingDisabled = (backgroundType) => {
            return backgroundType === RECORDING_ELEMENT_ID && !store.state.auth.user.client.enableRecording;
        };

        watch(
            () => props.backgroundType,
            (newVal) => {
                selectedBackgroundType.value = newVal;
            }
        );

        watch(selectedBackgroundType, (newValue) => {
            emit('update:backgroundType', newValue);
            backgroundTypesRender.value++;
        });

        return {
            selectedBackgroundType,
            backgroundTypesRender,
            recordingDisabled,
            backgroundTypeIcons
        };
    }
});
</script>
