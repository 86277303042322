<template>
    <UiQuickCut
        v-if="isReady"
        :segments="segments"
        :media="media"
        :media-type="mediaType"
        :playback-rate="playbackRate"
        :start-time="startTime"
        @update-segments="updateSegments"
        @close="close"
    />
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useStoreManagement } from '../composables/useStoreManagement';
import { useSequence } from '@video-composables/useSequence';
import { useElement } from '@video-composables/useElement';
import { useVoiceOver } from '@video-composables/useVoiceOver';

import UiQuickCut from '../UiQuickCut.vue';

const store = useStore();
const { sequenceId, elementId, isOpenQuickCut, mediaType, mediaSrc, closeQuickCut } = useStoreManagement(store);

const segments = ref([]);
const media = ref(null);
const playbackRate = ref(1);
const startTime = ref(0);
const timerangeEnd = ref(0);
const needResetSegments = ref(false);

const sequenceData = computed(() => {
    if (!sequenceId.value) return null;

    const {
        backgroundVideo,
        audioSrc,
        playbackRate: seqPlaybackRate,
        videoTimerangeSegments: backgroundVideoSegments,
        videoRange,
        audioTimerangeSegments,
        sequenceState,
        recordingTimerangeSegments,
        recordingSrc
    } = useSequence(sequenceId.value, store);

    return {
        backgroundVideo,
        audioSrc,
        seqPlaybackRate,
        backgroundVideoSegments,
        videoRange,
        audioTimerangeSegments,
        sequenceState,
        recordingTimerangeSegments,
        recordingSrc
    };
});

const setMediaSrcInStore = (newMedia) => {
    if (newMedia && mediaSrc.value !== newMedia.src) {
        if (mediaSrc.value !== null) {
            needResetSegments.value = true;
        }
        store.dispatch('ui/quickcut/setMediaSrc', newMedia.src);
    }
};

const elementData = computed(() => {
    if (!sequenceId.value || !elementId.value) return null;

    const {
        elementState,
        playbackRate: elementPlaybackRate,
        videoTimerangeSegments: visualSegments,
        recordingVisualTimerangeSegments,
        recordingVisualSrc,
        videoRange,
        videoSrc,
        audioSrc
    } = useElement(sequenceId.value, elementId.value, store);

    return {
        elementState,
        elementPlaybackRate,
        visualSegments,
        recordingVisualTimerangeSegments,
        recordingVisualSrc,
        videoRange,
        videoSrc,
        audioSrc
    };
});

const voiceOverData = computed(() => {
    if (!sequenceId.value) return null;
    const useInSequence = ref(true);
    const { voiceOverMediaState, timerange, timerangeSegments } = useVoiceOver(useInSequence, sequenceId, store);
    return { voiceOverMediaState, timerange, timerangeSegments };
});

const videoTimerangeSegments = computed(() => {
    if (mediaType.value === 'backgroundVideo' && sequenceData.value) {
        return sequenceData.value.backgroundVideoSegments.value;
    }
    if (mediaType.value === 'visual' && elementData.value) {
        return elementData.value.visualSegments.value;
    }
    if (mediaType.value === 'audio' && sequenceData.value) {
        return sequenceData.value.audioTimerangeSegments.value;
    }
    if (mediaType.value === 'tts' && voiceOverData.value) {
        return voiceOverData.value.timerangeSegments.value;
    }
    if (mediaType.value === 'recording' && sequenceData.value) {
        return sequenceData.value.recordingTimerangeSegments.value;
    }
    if (mediaType.value === 'recordingVisual' && sequenceData.value) {
        return elementData.value.recordingVisualTimerangeSegments.value;
    }
    return [];
});

const isReady = computed(() => sequenceId.value && media.value && isOpenQuickCut.value);

const initializeData = {
    backgroundVideo: () => {
        if (!sequenceData.value) return false;

        const { backgroundVideo, seqPlaybackRate, backgroundVideoSegments } = sequenceData.value;
        media.value = backgroundVideo.value;
        playbackRate.value = seqPlaybackRate.value;
        segments.value = backgroundVideoSegments.value;
        timerangeEnd.value = calculateTotalDuration(segments.value);
        setMediaSrcInStore(media.value);
        return true;
    },
    visual: () => {
        if (!elementData.value) return false;

        const { videoSrc, elementState, elementPlaybackRate, visualSegments } = elementData.value;
        media.value = videoSrc.value;
        playbackRate.value = elementPlaybackRate.value;
        startTime.value = elementState.value.animation.start.value;
        segments.value = visualSegments.value;
        timerangeEnd.value = calculateTotalDuration(segments.value);
        setMediaSrcInStore(media.value);
        return true;
    },
    audio: () => {
        if (!sequenceData.value) return false;

        const { audioSrc, sequenceState, audioTimerangeSegments } = sequenceData.value;
        media.value = audioSrc.value;
        playbackRate.value = 1;
        segments.value = audioTimerangeSegments.value;
        timerangeEnd.value = calculateTotalDuration(segments.value);
        startTime.value = sequenceState.value.audio.track.start.value;
        setMediaSrcInStore(media.value);
        return true;
    },
    tts: () => {
        if (!voiceOverData.value) return false;

        const { voiceOverMediaState, timerangeSegments } = voiceOverData.value;

        media.value = voiceOverMediaState.value;
        playbackRate.value = 1;
        segments.value = timerangeSegments.value;
        timerangeEnd.value = calculateTotalDuration(segments.value);
        setMediaSrcInStore(media.value);
        return true;
    },
    recording: () => {
        if (!sequenceData.value) return false;

        const { recordingTimerangeSegments, recordingSrc } = sequenceData.value;
        media.value = recordingSrc.value;
        playbackRate.value = 1;
        segments.value = recordingTimerangeSegments.value;
        timerangeEnd.value = calculateTotalDuration(segments.value);
        setMediaSrcInStore(media.value);
        return true;
    },
    recordingVisual: () => {
        if (!elementData.value) return false;

        const { recordingVisualSrc, elementState, elementPlaybackRate, recordingVisualTimerangeSegments } =
            elementData.value;
        media.value = recordingVisualSrc.value;
        playbackRate.value = elementPlaybackRate.value;
        startTime.value = elementState.value.animation.start.value;
        segments.value = recordingVisualTimerangeSegments.value;
        timerangeEnd.value = calculateTotalDuration(segments.value);
        setMediaSrcInStore(media.value);
        return true;
    }
};

watch(
    isOpenQuickCut,
    (isOpen) => {
        if (!isOpen) return;
        const initializeFunction = initializeData[mediaType.value];

        if (initializeFunction && !initializeFunction()) {
            console.warn('Failed to initialize data for QuickCut');
            close();
        }
    },
    { immediate: true }
);

watch(
    videoTimerangeSegments,
    (newSegments) => {
        segments.value = newSegments;
    },
    { deep: true }
);

const close = () => {
    segments.value = [];
    media.value = null;
    playbackRate.value = 1;
    startTime.value = 0;
    timerangeEnd.value = 0;
    closeQuickCut();
};

const updateSegments = (newSegments) => {
    //remove segment.gapStyle  property
    newSegments.forEach((segment) => {
        delete segment.gapStyle;
    });

    if (mediaType.value === 'backgroundVideo' && sequenceData.value) {
        sequenceData.value.backgroundVideoSegments.value = newSegments;
    } else if (mediaType.value === 'visual' && elementData.value) {
        elementData.value.visualSegments.value = newSegments;
    } else if (mediaType.value === 'audio' && sequenceData.value) {
        sequenceData.value.audioTimerangeSegments.value = newSegments;
    } else if (mediaType.value === 'tts' && voiceOverData.value) {
        voiceOverData.value.timerangeSegments.value = newSegments;
    } else if (mediaType.value === 'recording' && voiceOverData.value) {
        sequenceData.value.recordingTimerangeSegments.value = newSegments;
    } else if (mediaType.value === 'recordingVisual' && voiceOverData.value) {
        elementData.value.recordingVisualTimerangeSegments.value = newSegments;
    }

    segments.value = newSegments;
};

const calculateTotalDuration = (segments) => {
    return segments.reduce((acc, segment) => acc + (segment.end - segment.start), 0);
};
</script>
