import axios from 'axios';

export default class {
    constructor() {
        this.axiosInstance = axios.create({
            withCredentials: true,
            baseURL: `${import.meta.env.VITE_LEGACY_APP_URL}/api`
        });
    }

    generateImages = async (prompt, format, style) => {
        try {
            const response = await this.axiosInstance.post('image-generation/generate', {
                prompt,
                format,
                style
            });

            if (response.status !== 201) {
                return { error: true };
            }

            return response.data;
        } catch (err) {
            console.error(err);
        }

        return { error: true };
    };

    fetchUserHistory = async () => {
        try {
            const response = await this.axiosInstance.get('image-generation/history');
            if (response.status !== 200) {
                return { error: true };
            }

            return response.data;
        } catch (err) {
            console.error(err);
        }

        return { error: true };
    };

    useImage = async (imageUuid) => {
        try {
            const response = await this.axiosInstance.post(`image-generation/${imageUuid}/use`);
            if (response.status !== 201) {
                return { error: true };
            }

            return response.data;
        } catch (err) {
            console.error(err);
        }

        return { error: true };
    };

    clearUserHistory = async () => {
        try {
            const response = await this.axiosInstance.delete('image-generation/clear-history');
            if (response.status !== 200) {
                return { error: true };
            }

            return response.data;
        } catch (err) {
            console.error(err);
        }

        return { error: true };
    };

    getGenerateButtonData = (unauthorized, isSupervisor, enoughCredits, forbiddenWordUsed) => {
        let data = { icon: 'fa-solid fa-circle-check', tooltipKey: null };

        switch (true) {
            case unauthorized:
                data.icon = 'fa-solid fa-lock';
                data.tooltipKey = isSupervisor
                    ? 'image-generation.enable-supervisor'
                    : 'image-generation.contact-supervisor';
                break;
            case !enoughCredits:
                data.icon = 'fa-solid fa-circle-xmark';
                data.tooltipKey = 'image-generation.insufficient-credits';
                break;
            case forbiddenWordUsed:
                data.tooltipKey = 'image-generation.forbidden-word-used';
                break;
        }

        return data;
    };
}
