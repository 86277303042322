<template>
    <div class="edit-client-team-summary">
        <div class="edit-client-team-summary-left">
            <div class="edit-client-team-summary-title">{{ $t('Users licenses') }}</div>
            <div class="edit-client-team-summary-text text-light-gray-alt">
                {{ $t('Invite new users, customize them or remove them from your workspace.') }}
            </div>
        </div>
        <div class="edit-client-team-summary-licenses">
            <div class="edit-client-team-summary-licenses-item">
                <div class="edit-client-team-summary-licenses-item-title">{{ $t('Licenses') }}</div>
                <div class="edit-client-team-summary-licenses-item-value">
                    {{ `${licensesNumber}/${licensesLimitNumber}` }}
                </div>
            </div>
            <div class="edit-client-team-summary-licenses-item">
                <div class="edit-client-team-summary-licenses-item-title">{{ $t('Emotion') }}</div>
                <div class="edit-client-team-summary-licenses-item-value">{{ client.nbLicensesSupervisors }}</div>
            </div>
            <div class="edit-client-team-summary-licenses-item">
                <div class="edit-client-team-summary-licenses-item-title">{{ $t('Content') }}</div>
                <div class="edit-client-team-summary-licenses-item-value">{{ client.nbLicensesCreators }}</div>
            </div>
        </div>
        <div class="edit-client-team-summary-actions">
            <div class="add-licence-button ui-simple-button ui-simple-button-dark" ref="ctaButtonContainer">
                <fa-icon class="icon" icon="fa-solid fa-arrow-up-right-from-square" />
                <span>{{ $t('Add licenses') }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import ClientDTO from '@/js/dto/ClientDTO.js';
import { computed, onMounted, ref } from 'vue';

const props = defineProps({
    client: {
        type: Object,
        required: ClientDTO
    },
    licensesNumber: Number,
    licensesLimitNumber: Number
});

const ctaButtonContainer = ref(null);

onMounted(() => {
    let ctaButton = document.getElementById('cta-button');
    if (!window.ctaButton) window.ctaButton = ctaButton;
    ctaButtonContainer.value.appendChild(window.ctaButton);
});
</script>
