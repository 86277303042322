<template>
    <li :id="'ui-prompt-item-' + prompt.id">
        <a
            :href="prompt.show_url"
            class="ui-sidebar-prompt"
            :class="promptClasses"
            :title="prompt.name"
            @click.prevent="emitClick"
        >
            <span>{{ prompt.name }}</span>
        </a>

        <ui-dropdown
            :id="'ui-prompt-item-actions-' + prompt.id"
            :icon="true"
            :caret="false"
            menu-placement="bottom-start"
            menu-strategy="fixed"
            class="ui-sidebar-prompt-actions"
            v-on="dropdownListeners"
        >
            <template #dropdown-toggle>
                <span class="visually-hidden">{{ $t('Prompt actions...') }}</span>
                <svg-icon icon="menu-icon" />
            </template>

            <ui-dropdown-item classes="ui-remove-item" @click.prevent="emitDelete">
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-trash-can" />
                    {{ $t('Delete') }}
                </template>
            </ui-dropdown-item>
        </ui-dropdown>
    </li>
</template>

<script>
import { UiDropdown, UiDropdownItem, UiIcon } from '../../../../components';
import { UI_DROPDOWN_MENU_HIDE, UI_DROPDOWN_MENU_SHOW } from '../../../../components/UiDropdown.vue';

export const UI_PROMPT_ITEM_ACTIONS_OPEN = 'ui-prompt-item-actions-open';
export const UI_PROMPT_ITEM_ACTIONS_CLOSE = 'ui-prompt-item-actions-close';
export const UI_PROMPT_ITEM_DELETE = 'ui-prompt-item-delete';

export default {
    components: {
        UiDropdown,
        UiDropdownItem,
        UiIcon
    },

    emits: ['click', UI_PROMPT_ITEM_ACTIONS_OPEN, UI_PROMPT_ITEM_ACTIONS_CLOSE, UI_PROMPT_ITEM_DELETE],

    props: {
        prompt: {
            type: Object,
            default: () => ({})
        },
        currentPromptId: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            actionMenuActive: false
        };
    },

    computed: {
        promptClasses() {
            return {
                active: this.prompt.id == this.currentPromptId,
                'menu-open': this.actionMenuActive
            };
        },

        dropdownListeners() {
            return {
                [UI_DROPDOWN_MENU_SHOW]: this.handleDropdownMenuShow,
                [UI_DROPDOWN_MENU_HIDE]: this.handleDropdownMenuHide
            };
        }
    },

    methods: {
        emitClick(event) {
            this.$emit('click', this.prompt);
        },

        emitDelete() {
            this.$emit(UI_PROMPT_ITEM_DELETE, this.prompt);
        },

        handleDropdownMenuShow() {
            this.actionMenuActive = true;
            this.$emit(UI_PROMPT_ITEM_ACTIONS_OPEN, this);
        },

        handleDropdownMenuHide() {
            this.actionMenuActive = false;
            this.$emit(UI_PROMPT_ITEM_ACTIONS_CLOSE, this);
        }
    }
};
</script>
