/**
 * Edit video Settings logo Vuex store extension
 */

import { types, conversions, Dimension, Align } from 'cte-video-studio';
import timeline from '../timeline.js';

const state = {
    ...timeline.state(),

    enabled: false,
    overrideBranding: false,
    animation: {
        src: '',
        src__ref: '',
        src__id: '',
        type: ''
    },
    size: {
        overrideBranding: false,
        width: {
            value: null,
            custom: null
        },
        height: {
            enabled: false,
            value: null,
            custom: null
        }
    },
    position: {
        overrideBranding: false,
        alignH: null,
        alignV: null,
        alignX: null,
        alignY: null
    }
};

const getters = {
    logoTimeline: (state) => state.timeline,

    logoTimelineOut: (state) => state.timelineOut,

    logoNeedsRefresh: (state) => state.needsRefresh,

    logo: (state, getters, rootState) =>
        (state.overrideBranding && state.animation.src) || rootState.branding.logo.animation.src,

    logoAnimation: (state, getters, rootState) =>
        (state.overrideBranding && state.animation.type) || rootState.branding.logo.animation.type,

    logoSize(state, getters, rootState, rootGetters) {
        if (!state.enabled) return false;
        let brandingSize = rootGetters['branding/logoSize'];
        return {
            width:
                (state.size.overrideBranding && conversions.optionValue(state.size.width, Dimension.AUTO)) ||
                brandingSize.width,
            height:
                (state.size.overrideBranding && conversions.optionValue(state.size.height, Dimension.AUTO)) ||
                brandingSize.height
        };
    },

    logoPosition(state, getters, rootState) {
        if (!state.enabled) return false;
        let brandingPosition = rootState.branding.logo.position;
        return {
            alignH: (state.position.overrideBranding && state.position.alignH) || brandingPosition.alignH,
            alignV: (state.position.overrideBranding && state.position.alignV) || brandingPosition.alignV,
            alignX: (state.position.overrideBranding && state.position.alignX) || brandingPosition.alignX,
            alignY: (state.position.overrideBranding && state.position.alignY) || brandingPosition.alignY
        };
    },

    logoReference: (state, getters, rootState) =>
        (state.overrideBranding && state.animation.src && state.animation.src__ref) ||
        rootState.branding.logo.animation.src__ref,

    logoId: (state, getters, rootState) =>
        (state.overrideBranding && state.animation.src && state.animation.src__id) ||
        rootState.branding.logo.animation.src__id
};

const mutations = {
    setLogoTimeline: timeline.mutations.setTimeline,

    setLogoTimelineOut: timeline.mutations.setTimelineOut,

    enabledLogoRefresh: timeline.mutations.enableRefresh,

    enableLogo(state, enabled) {
        state.enabled = enabled;
        if (!state.enabled) {
            state.timeline = () => null;
            state.timelineOut = () => null;
        }
    },

    overrideLogo(state, override) {
        state.overrideBranding = override;
    },

    setLogo(state, src) {
        state.animation.src = src;
    },

    setLogoAnimation(state, type) {
        state.animation.type = types.isLogoAnimation(type) ? type : '';
    },

    overrideLogoPosition(state, override) {
        state.size.overrideBranding = override;
        state.position.overrideBranding = override;
    },

    setLogoWidth(state, width) {
        if (types.isDimension(width.value, true)) {
            state.size.width.value = width.value;
            state.size.width.custom = null;
        } else if (types.isDimension(width.custom, true)) {
            state.size.width.value = Dimension.CUSTOM;
            state.size.width.custom = width.custom;
        } else {
            state.size.width.value = null;
            state.size.width.custom = null;
        }
    },

    enableLogoHeight(state, enabled) {
        state.size.height.enabled = enabled;
    },

    setLogoHeight(state, height) {
        if (types.isDimension(height.value, true)) {
            state.size.height.value = height.value;
            state.size.height.custom = null;
        } else if (types.isDimension(height.custom, true)) {
            state.size.height.value = Dimension.CUSTOM;
            state.size.height.custom = height.custom;
        } else {
            state.size.height.value = null;
            state.size.height.custom = null;
        }
    },

    setLogoPosition(state, position) {
        state.position.alignH = types.isAlign(position.alignH) ? position.alignH : null;
        state.position.alignV = types.isAlign(position.alignV) ? position.alignV : null;
        state.position.alignX =
            state.position.alignH == Align.CUSTOM && types.isDimension(position.alignX) ? position.alignX : null;
        state.position.alignY =
            state.position.alignV == Align.CUSTOM && types.isDimension(position.alignY) ? position.alignY : null;
    },

    setLogoReference(state, ref) {
        state.animation.src__ref = ref;
    },

    setLogoId(state, id) {
        state.animation.src__id = id;
    }
};

const actions = {
    initLogo({ commit }, data) {
        commit('enableLogo', data.enabled);
        commit('overrideLogo', data.overrideBranding);
        commit('setLogo', data.animation.src);
        commit('setLogoAnimation', data.animation.type);
        commit('overrideLogoPosition', data.size.overrideBranding);
        commit('setLogoWidth', data.size.width);
        commit('enableLogoHeight', data.size.height.enabled);
        commit('setLogoHeight', data.size.height);
        commit('setLogoPosition', data.position);
        commit('setLogoId', data.animation.src__id);
        commit('setLogoReference', data.animation.src__ref);
    },

    updateLogo({ commit }, data) {
        commit('setLogoId', data.src__id);
        commit('setLogo', data.src);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
