<!--
  -- Component name: IntroLogoWhite
  -- Type: Transition
  -- Uses: 
  -- Tags: allianz
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container studio-transition-intro-logo-white"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1920 1080"
        preserveAspectRatio="xMidYMid slice"
    >
        <g :style="formatScaleStyle">
            <defs>
                <mask :id="textMaskID">
                    <g class="outro-white-mask">
                        <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
                        <g :style="maskStyle">
                            <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
                            <rect x="50%" y="0" width="50%" height="100%" fill="#000" />
                            <circle fill="#000" cx="50%" cy="50.3%" r="67" stroke="#000" stroke-width="11" />
                        </g>
                    </g>
                </mask>
                <mask :id="circleMaskID">
                    <g class="outro-white-mask">
                        <g :style="circleMaskStyle">
                            <rect
                                x="0"
                                :y="(100 - 100 / this.formatScale) / 2 + '%'"
                                width="100%"
                                :height="100 / this.formatScale + '%'"
                                fill="#fff"
                            />
                            <path
                                fill="#000"
                                d="m940.08,513.14h-12.47c-6.17,0-8.58,2.28-8.58,8.61v63.02h21.05v-71.63Z"
                            />
                            <path
                                fill="#000"
                                d="m992.35,513.14h-12.42v71.63h21.03v-63.02c0-6.33-2.44-8.61-8.61-8.61Z"
                            />
                            <path
                                fill="#000"
                                d="m970.57,584.77v-80.92c0-6.45-2.29-8.62-8.55-8.62h-18.93v9.04h1.12c4.32,0,5.16.98,5.16,6.18v74.32h21.19Z"
                            />
                            <circle
                                class="intro-image-white-logo-circle"
                                fill-opacity="0"
                                cx="50%"
                                cy="50.3%"
                                r="67"
                                stroke="#000"
                                stroke-width="12"
                            />
                        </g>
                    </g>
                </mask>
            </defs>
            <rect
                class="intro-logo-frame"
                x="0"
                :y="(100 - 100 / this.formatScale) / 2 + '%'"
                width="100%"
                :height="100 / this.formatScale + '%'"
                fill="#fff"
                :style="frameStyle"
            />
            <g :mask="textMask" :style="logoTextStyle" class="intro-image-white-text-logo">
                <rect x="0" y="0" width="100%" height="100%" fill-opacity="0" />
                <path
                    fill="#003781"
                    d="m718.36,544.29h-20.18l10.73-35.05,9.43,35.05h.01Zm-30.65,38.66l6.87-23.03h27.19l6.45,23.03h25.19l-25.03-87.88c-1.43-5.19-4.58-7.58-10.04-7.58h-35.63v8.29h3c3.73,0,5.74,1.45,5.74,4.3,0,1.86-.41,3.43-1.89,7.72l-25.06,75.13h23.21Z"
                />
                <path
                    fill="#003781"
                    d="m782.45,582.94v-88.14c0-4.6-2.69-7.32-7.14-7.32h-22.03v8.29h1.43c3.71,0,5.28,1.58,5.28,5.32v81.84h22.46Z"
                />
                <path
                    fill="#003781"
                    d="m820.65,582.94v-88.14c0-4.6-2.74-7.32-7.16-7.32h-22.03v8.29h1.4c3.74,0,5.31,1.58,5.31,5.32v81.84h22.47Z"
                />
                <path
                    fill="#003781"
                    d="m859.84,496.19c0-6.4-4.73-10.27-12.88-10.27s-12.91,3.87-12.91,10.27,4.89,10.32,12.91,10.32,12.88-3.83,12.88-10.32m-.41,86.75v-64.27c0-4.58-2.74-7.29-7.17-7.29h-21.75v8.15h1.29c3.73,0,5.29,1.71,5.29,5.44v57.97h22.35-.01Z"
                />
                <path
                    fill="#003781"
                    d="m908.61,548.58v13.91c-3.74,3.87-8.29,6.18-12.15,6.18s-5.44-1.98-5.44-7.35,1.12-7.7,5.13-9.27c3-1.3,6.87-2.44,12.46-3.49m-30.22-16.71c7.45-3.58,15.61-5.59,22.04-5.59,6.03,0,8.18,2.14,8.18,8.41v1.6c-9.15,1.45-12.02,2.04-17.18,3.18-2.97.69-5.74,1.55-8.46,2.71-9.03,3.62-13.29,10.6-13.29,21.32,0,13.6,5.85,20.17,18.02,20.17,4.44,0,8.46-.84,12.03-2.26,3.13-1.39,5-2.57,9.59-6.15v.99c0,4.44,2.28,6.71,6.73,6.71h20.46v-7.7h-1.01c-3.86,0-5.28-1.73-5.28-6.3v-34.08c0-18.17-6.3-24.5-24.5-24.5-5.85,0-11.58.74-17.15,2.03-5.6,1.27-8.49,2.42-15.17,5.72,0,0,4.97,13.75,4.97,13.75Z"
                />
                <path
                    fill="#003781"
                    d="m969.12,582.94v-48.37c3.74-4.15,7.58-6.03,12.03-6.03,4.75,0,5.87,1.88,5.87,9.06v45.34h22.32v-48.65c0-11.32-.83-14.9-4.42-18.75-3.02-3.31-7.58-5.01-13.48-5.01-8.74,0-14.89,2.71-23.15,10.44v-2.85c0-4.45-2.32-6.73-6.9-6.73h-21.2v8.15h1.29c3.73,0,5.31,1.71,5.31,5.44v57.97h22.34Z"
                />
                <path
                    fill="#003781"
                    d="m1075.6,567.92h-31.21l29.77-41.76v-14.77h-46.64c-5.29,0-7.73,2.42-7.73,7.73v13h8.29v-1.27c0-3.28,1.71-4.86,5.28-4.86h16.06l-30.06,42.24v14.72h56.25v-15.02h-.01Z"
                />
            </g>
            <g :style="circleStyle" class="intro-image-colored-logo">
                <rect x="0" y="0" width="100%" height="100%" fill-opacity="0" />
                <path fill="#003781" d="m940.08,513.14h-12.47c-6.17,0-8.58,2.28-8.58,8.61v63.02h21.05v-71.63Z" />
                <path fill="#003781" d="m992.35,513.14h-12.42v71.63h21.03v-63.02c0-6.33-2.44-8.61-8.61-8.61Z" />
                <path
                    fill="#003781"
                    d="m970.57,584.77v-80.92c0-6.45-2.29-8.62-8.55-8.62h-18.93v9.04h1.12c4.32,0,5.16.98,5.16,6.18v74.32h21.19Z"
                />
                <circle
                    class="intro-image-white-logo-circle"
                    fill-opacity="0"
                    cx="50%"
                    cy="50.3%"
                    r="67"
                    stroke="#003781"
                    stroke-width="12"
                />
            </g>
        </g>
    </svg>
</template>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true, // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
            circleScale: 1,
            circleX: 0,
            frameY: 0,
            logoTextX: 0
        };
    },

    computed: {
        formatScale() {
            if (this.format.width === this.format.height) return 0.8;
            else if (this.format.width < this.format.height) return 0.65;
            return 1;
        },

        textMaskID() {
            return this.containerID + '-text-mask';
        },

        circleMaskID() {
            return this.containerID + '-circle-mask';
        },

        textMask() {
            return 'url(#' + this.textMaskID + ')';
        },

        circleMask() {
            return 'url(#' + this.circleMaskID + ')';
        },

        frameStyle() {
            return {
                transform: 'translateY(' + -(this.frameY / this.formatScale) + '%)',
                transformOrigin: '50% 50%'
            };
        },

        formatScaleStyle() {
            return {
                transform: 'scale(' + this.formatScale + ')',
                transformOrigin: '50% 50%'
            };
        },

        circleStyle() {
            return {
                transformOrigin: '50% 50%',
                transform: 'scale(' + this.circleScale + ') translateX(' + this.circleX + '%)'
            };
        },

        circleMaskStyle() {
            return {
                transformOrigin: '50% 50%',
                transform:
                    'scale(' +
                    this.circleScale +
                    ') translateY(' +
                    this.frameY / this.formatScale / this.circleScale +
                    '%)'
            };
        },

        logoTextStyle() {
            return {
                transformOrigin: '50% 50%',
                transform: 'translateX(' + this.logoTextX + '%)'
            };
        },

        maskStyle() {
            return {
                transformOrigin: '50% 50%',
                transform: 'translateX(' + (this.circleX - this.logoTextX) + '%)'
            };
        }
    },

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();

            t.fromTo(this, { circleX: 11.5 }, { circleX: 0, duration: 1, ease: 'power1.inOut' }, 0.32);
            t.fromTo(this, { logoTextX: 0 }, { logoTextX: 12.5, duration: 1, ease: 'power1.inOut' }, 0.32);
            t.set('#' + this.containerID + ' .intro-image-white-text-logo', { display: 'none' }, 1.32);
            t.set('#' + this.containerID + ' .intro-logo-frame', { mask: this.circleMask }, 1.32);
            t.fromTo(this, { circleScale: 1 }, { circleScale: 1.8, duration: 0.5 }, 1.32);
            t.fromTo(this, { circleScale: 1.8 }, { circleScale: 2.3, duration: 1.5, immediateRender: false }, 1.82);
            t.fromTo(
                '#' + this.containerID + ' .intro-image-white-logo-circle',
                { strokeWidth: 12 },
                { strokeWidth: 0, duration: 0.8, ease: 'power1.out' },
                1.92
            );
            t.fromTo(
                '#' + this.containerID + ' .intro-image-colored-logo',
                { opacity: 1 },
                { opacity: 0, duration: 0.6, ease: 'power1.out' },
                1.32
            );
            t.fromTo(this, { frameY: 0 }, { frameY: 100, duration: 1.2, ease: 'power1.inOut' }, 2.24);

            return t;
        }
    }
};
</script>
