<template>
    <div class="ui-color-picker" :id="id" :class="containerClasses">
        <div v-if="hasPaletteColors" class="ui-color-picker-palette">
            <template v-for="(paletteColor, key) in resolvedPalette" :key="key">
                <button
                    v-if="!!paletteColor"
                    class="palette-color"
                    :style="{ background: paletteColor }"
                    :title="sprintf($t('Select brand color %1$s'), key.replace('color', ''))"
                    @click="handlePaletteClick(paletteColor, key)"
                >
                    <span class="visually-hidden">{{ sprintf($t('Brand color %1$s'), key.replace('color', '')) }}</span>
                </button>
            </template>
            <button
                v-if="enableEmpty"
                class="palette-color empty-color"
                key="empty"
                :title="$t('Select empty color')"
                @click="handleEmptyClick"
            >
                <checkboard />
                <span class="visually-hidden">{{ $t('Empty color') }}</span>
            </button>
        </div>
        <div v-if="(enableOtherColors && expanded) || !hasPaletteColors" class="ui-color-picker-saturation">
            <saturation
                ref="$saturation"
                v-model="colors"
                @change="handleColorChange"
                @mousedown="listenToMouseUpEvent(true)"
            />
        </div>
        <div v-if="(enableOtherColors && expanded) || !hasPaletteColors || enableAlpha" class="ui-color-picker-sliders">
            <div class="slider-container" v-if="(enableOtherColors && expanded) || !hasPaletteColors">
                <hue v-model="colors" @change="handleColorChange" @mousedown="listenToMouseUpEvent" />
            </div>
            <div class="slider-container" v-if="enableAlpha">
                <alpha v-model="colors" @change="handleAlphaChannelChange" @mousedown="listenToMouseUpEvent" />
            </div>
        </div>
        <div v-if="(enableOtherColors && expanded) || !hasPaletteColors" class="ui-color-picker-fields">
            <ui-text-input
                :id="id + '-hex'"
                class="hex-field"
                :model-value="hexValue"
                :label="$t('Hex')"
                @input="handleHexInput"
                @change="handleHexChange"
            />
            <ui-text-input
                v-if="enableAlpha"
                :id="id + '-alpha'"
                class="alpha-field"
                :model-value="alphaChannel"
                :label="$t('Opacity')"
                @input="handleAlphaInput"
                @change="handleAlphaChange"
            />
        </div>
        <button
            v-if="hasPaletteColors && enableOtherColors && !expanded"
            class="ui-color-picker-expander ui-simple-button"
            @click.prevent="handleExpand"
        >
            {{ $t('More...') }}
        </button>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { printf as sprintf } from 'fast-printf';
import { Alpha, Checkboard, ColorMixin, EditableInput, Hue, Saturation } from '@lk77/vue3-color';
import { Color, types } from 'cte-video-studio';
import UiTextInput from './UiTextInput.vue';

export const UI_COLOR_PICKER_CHANGE = 'update:modelValue';
export const UI_COLOR_PICKER_EXPAND = 'ui-color-picker-expand';

const PALETTE_REFERENCED_KEYS = ['color1', 'color2'];
const WHITE_PALETTE_KEY = 'white';
const BLACK_PALETTE_KEY = 'black';

export default {
    emits: [UI_COLOR_PICKER_CHANGE, UI_COLOR_PICKER_EXPAND],

    mixins: [ColorMixin],

    components: {
        Alpha,
        Checkboard,
        EditableInput,
        Hue,
        Saturation,
        UiTextInput
    },

    props: {
        id: {
            type: String
        },
        defaultColor: {
            type: String,
            default: Color.BLACK
        },
        palette: {
            type: Object,
            default: () => ({})
        },
        enableEmpty: {
            type: Boolean,
            default: false
        },
        colorReference: {
            type: String,
            default: ''
        },
        enableAlpha: {
            type: Boolean,
            default: false
        },
        defaultAlpha: {
            type: Number,
            default: Color.OPACITY_DEFAULT
        },
        enableOtherColors: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isBrandPalette: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            selectedPaletteColor: this.colorReference,
            expanded: false
        };
    },

    computed: {
        ...mapState({
            prefixes: (state) => state.ui.prefixes
        }),

        ...mapGetters({
            //
        }),

        hasPaletteColors() {
            return !!Object.keys(this.palette).length;
        },

        resolvedPalette() {
            return {
                ...this.palette,
                [WHITE_PALETTE_KEY]: Color.WHITE,
                [BLACK_PALETTE_KEY]: Color.BLACK
            };
        },

        hexValue() {
            return this.isEmpty ? Color.NONE : this.colors.hex.replace('#', '');
        },

        isEmpty() {
            return this.enableEmpty && this.modelValue.hex == Color.NONE;
        },

        alphaChannel() {
            return ~~(this.colors.a * 100);
        },

        activeColor() {
            var rgba = this.colors.rgba;
            return 'rgba(' + [rgba.r, rgba.g, rgba.b, rgba.a].join(',') + ')';
        },

        containerClasses() {
            return {
                disabled: this.disabled
            };
        }
    },

    watch: {
        //
    },

    methods: {
        sprintf,

        getPaletteReference(key) {
            return this.prefixes.settingsReference + 'palette.' + key;
        },

        getResolvedDefaultColor() {
            return types.isColor(this.defaultColor)
                ? this.defaultColor
                : this.palette[this.defaultColor] || (this.enableEmpty ? Color.NONE : Color.BLACK);
        },

        handlePaletteClick(color, key) {
            this.selectedPaletteColor =
                this.isBrandPalette && PALETTE_REFERENCED_KEYS.includes(key) ? this.getPaletteReference(key) : '';
            this.colorChange({
                hex: color,
                a: this.colors.a,
                source: 'hex'
            });
            this.emitChangeEvent();
        },

        handleEmptyClick() {
            this.selectedPaletteColor = '';
            this.$emit(UI_COLOR_PICKER_CHANGE, { hex: Color.NONE, a: this.colors.a });
        },

        handleExpand() {
            this.expanded = true;
            this.$emit(UI_COLOR_PICKER_EXPAND, this);
        },

        handleColorChange(data) {
            this.selectedPaletteColor = '';
            this.colorChange({
                ...data,
                // Note: Force values on saturation and light when field is empty to avoid black color on first hue click
                ...(this.isEmpty && !data.s && { s: 0.5, l: 0.5 }),
                a: this.colors.a
            });
        },

        handleAlphaChannelChange(data) {
            this.colorChange({
                r: this.colors.rgba.r,
                g: this.colors.rgba.g,
                b: this.colors.rgba.b,
                a: data.a,
                source: 'rgba'
            });
        },

        handleHexInput(event) {
            this.selectedPaletteColor = '';
            let value = event.target.value;
            if (value.length == 6 && this.isValidHex(value)) {
                this.colorChange({
                    hex: value,
                    a: this.colors.a,
                    source: 'hex'
                });
            }
        },

        handleHexChange(event) {
            let value = event.target.value;
            this.selectedPaletteColor = this.isValidHex(value)
                ? ''
                : this.palette[this.defaultColor]
                  ? this.getPaletteReference(this.defaultColor)
                  : '';

            let hexValue = this.isValidHex(value) ? value : this.getResolvedDefaultColor();

            if (this.enableEmpty && hexValue == Color.NONE) {
                this.$emit(UI_COLOR_PICKER_CHANGE, { hex: hexValue, a: this.colors.a });
            } else {
                this.colorChange({
                    hex: this.isValidHex(value) ? value : this.getResolvedDefaultColor(),
                    a: this.colors.a,
                    source: 'hex'
                });
                this.emitChangeEvent();
            }
        },

        handleAlphaInput(event) {
            let value = event.target.value;
            if (/\S/.test(value) && !isNaN(value) && value >= 0 && value <= 100) {
                this.colorChange({
                    r: this.colors.rgba.r,
                    g: this.colors.rgba.g,
                    b: this.colors.rgba.b,
                    a: ~~value / 100,
                    source: 'rgba'
                });
            }
        },

        handleAlphaChange(event) {
            let value = event.target.value;
            this.colorChange({
                r: this.colors.rgba.r,
                g: this.colors.rgba.g,
                b: this.colors.rgba.b,
                a: !/\S/.test(value) || isNaN(value) ? this.defaultAlpha : ~~Math.max(0, Math.min(value, 100)) / 100,
                source: 'rgba'
            });
            this.emitChangeEvent();
        },

        listenToMouseUpEvent(prepare = false) {
            window.addEventListener('mouseup', prepare ? this.prepareEmitChangeEvent : this.emitChangeEvent);
        },

        prepareEmitChangeEvent(event) {
            window.removeEventListener('mouseup', this.prepareEmitChangeEvent);
            this.emitChangeEvent();
            this.$refs.$saturation?.$el.addEventListener('change', this.emitChangeEvent, { once: true });
        },

        emitChangeEvent() {
            window.removeEventListener('mouseup', this.emitChangeEvent);
            this.$emit(UI_COLOR_PICKER_CHANGE, this.colors);
        }
    },

    mounted() {
        //
    }
};
</script>
