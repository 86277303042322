/**
 * Video Studio
 * Font constants
 */

const UBUNTU = 'Ubuntu';

const STYLE_NORMAL = 'normal';
const STYLE_BOLD = 'bold';
const STYLE_ITALIC = 'italic';
const STYLE_BOLD_ITALIC = 'bold-italic';

const Font = {
    PREFIX_CLASS: 'Font',

    FONT_TIMEOUT: 5000,

    STYLE_NORMAL: STYLE_NORMAL,
    STYLE_BOLD: STYLE_BOLD,
    STYLE_ITALIC: STYLE_ITALIC,
    STYLE_BOLD_ITALIC: STYLE_BOLD_ITALIC,

    STYLES: [
        STYLE_NORMAL,
        STYLE_BOLD,
        STYLE_ITALIC,
        STYLE_BOLD_ITALIC
    ],

    CUSTOM: 'Custom',

    DEFAULT: UBUNTU
};

export default Font;
