<template>
    <div class="export-video-gif-export">
        <div class="gif-generation-config" v-if="!gifName">
            <label>{{ $t('Gif.gif-primary-text') }}</label>

            <GenerationFields
                :gifWidthSelect="gifWidthSelect"
                :gifWidth="gifWidth"
                :gifFramerateSelect="gifFramerateSelect"
                :gifFramerate="gifFramerate"
                :gifLoop="gifLoop"
                @update:gifWidthSelect="gifWidthSelect = $event"
                @update:gifWidth="gifWidth = $event"
                @update:gifFramerateSelect="gifFramerateSelect = $event"
                @update:gifFramerate="gifFramerate = $event"
                @update:gifLoop="gifLoop = $event"
            />
            <Player @updateStartTime="handleStartTimeUpdate" @updateEndTime="handleEndTimeUpdate" :video="video" />

            <div class="modal-body-actions-bottom">
                <button
                    v-if="!isLoading"
                    @click="generateGIF"
                    class="ui-simple-button ui-simple-button-purple download-button cte-input"
                    :disabled="isLoading"
                >
                    <fa-icon :icon="['far', 'rocket-launch']" />
                    {{ $t('Gif.gif-generate') }}
                </button>

                <div v-if="isLoading" class="loader"></div>
            </div>
        </div>
        <Result :gifName="gifName" @remake-gif="remakeGifHandler"></Result>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import GenerationFields from '@/js/components/modals/export/GifGenerationFields.vue';
import Player from '@/js/components/modals/export/GifPlayer.vue';
import Result from '@/js/components/modals/export/GifResults.vue';
import { useStore } from 'vuex';
import GifService from '@/js/application/services/video/GenerateGifService.js';

const props = defineProps({
    video: Object
});

const gifName = ref('');
const gifWidthSelect = ref(480);
const gifWidth = ref(480);
const gifFramerateSelect = ref(15);
const gifFramerate = ref(15);
const gifLoop = ref(true);
const startTime = ref(0);
const endTime = ref(0);
const isLoading = ref(false);
const store = useStore();

const handleStartTimeUpdate = (newStartTime) => {
    startTime.value = newStartTime;
};

const handleEndTimeUpdate = (newEndTime) => {
    endTime.value = newEndTime;
};

const remakeGifHandler = () => {
    gifName.value = '';
    isLoading.value = false;
};

const generateGIF = async () => {
    isLoading.value = true;
    const gifService = new GifService(store);

    try {
        const responseGif = await gifService.generateGIF({
            video: props.video,
            startTime: startTime.value,
            endTime: endTime.value,
            gifWidthSelect: gifWidthSelect.value,
            gifWidth: gifWidth.value,
            gifFramerateSelect: gifFramerateSelect.value,
            gifFramerate: gifFramerate.value,
            gifLoop: gifLoop.value
        });
        gifName.value = responseGif.gifName;
    } catch (error) {
        console.error('Erreur lors de la génération du GIF:', error);
    }
};
</script>
