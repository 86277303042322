<template>
    <div class="ui-media-selector">
        <button :id="selectorId" class="selector" :class="{ empty: !fileUrl }" @click="openBrowserFileSelection">
            <div class="selector-preview" :style="filePreviewStyles" />
            <span class="visually-hidden">{{ label || $t('file-selector.select') }}</span>
        </button>
        <div v-if="!fileUrl" class="selector-empty">
            <svg class="border">
                <rect x="0" y="0" width="100%" height="100%" rx="10" fill="none" />
            </svg>
            <fa-icon class="icon" :icon="icon" />
        </div>
        <div v-else class="selector-actions">
            <button class="action-change" :title="$t('file-selector.change')" @click="openBrowserFileSelection">
                <span class="visually-hidden">{{ $t('file-selector.change') }}</span>
                <fa-icon class="icon" icon="fa-solid fa-rotate" />
            </button>
            <button class="action-remove" :title="$t('file-selector.remove')" @click="removeFile">
                <span class="visually-hidden">{{ $t('file-selector.remove') }}</span>
                <svg-icon icon="close-icon" />
            </button>
        </div>

        <div class="label-container">
            <label :class="labelClasses" :for="selectorId" :aria-hidden="!showLabel || null">
                {{ fileSizeTooLarge ? $t('file-selector.too-large') : label || $t('file-selector.select') }}
            </label>

            <div class="label-hint">{{ sprintf($t('file-selector.max-size-label-hint'), maxFileSize) }}</div>
        </div>

        <input type="file" ref="inputFileRef" :accept="accept.join(',')" @change="updatePreview" />
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { printf as sprintf } from 'fast-printf';
import { useStore } from 'vuex';

const store = useStore();
const emit = defineEmits(['update-file', 'remove-file']);
const props = defineProps({
    id: String,
    accept: Array,
    icon: [Array, String],
    label: String,
    fileType: {
        type: String,
        required: true
    },
    showLabel: {
        type: Boolean,
        default: true
    },
    maxFileSize: {
        type: Number,
        default: 1
    }
});

const inputFileRef = ref(null);
const fileSizeTooLarge = ref(false);
const selectorId = `ui-file-selector-${props.id}`;
const labelClasses = computed(() => ({ 'visually-hidden': !props.showLabel, error: fileSizeTooLarge.value }));

const fileUrl = computed(() => store.getters['ui/files/templateThumbnailUrl']);
const filePreviewStyles = computed(() => ({
    backgroundImage: fileUrl.value ? `url(${fileUrl.value})` : null
}));

const openBrowserFileSelection = () => {
    fileSizeTooLarge.value = false;
    inputFileRef.value.click();
};

const removeFile = () => {
    inputFileRef.value.value = '';
    emit('remove-file');
};

const updatePreview = () => {
    const [file] = inputFileRef.value.files;

    // check file size (1048576 bytes = 1MB)
    // file can be undefined when cancelling request
    if (!!file) {
        if (file?.size <= props.maxFileSize * 1048576) {
            emit('update-file', file);
        } else {
            fileSizeTooLarge.value = true;
        }
    } else emit('remove-file');
};
</script>

<style scoped>
input[type='file'] {
    display: none;
}
</style>
