/**
 * Video Studio
 * Detection data Vuex store module
 */

const USER_AGENT = window.navigator.userAgent;

// Initial state
const state = {
    browser: {
        chrome: !!USER_AGENT.match(/Chrome/g) && !USER_AGENT.match(/Edg/g),
        firefox: !!USER_AGENT.match(/Firefox/g),
        safari: !!USER_AGENT.match(/Safari/g) && !USER_AGENT.match(/Chrome/g),
        edge: !!USER_AGENT.match(/Edge?\/1\d\./g),
        edgeChromium: !!USER_AGENT.match(/Edg/g) && !USER_AGENT.match(/Edge?\/1\d\./g),
        ipad: !!USER_AGENT.match(/iPad/g),
        iphone: !!USER_AGENT.match(/iPhone/g),
        ipod: !!USER_AGENT.match(/iPod/g),
        android: !!USER_AGENT.match(/Android/g)
    },

    features: {
        backdropFilter:
            document.body.style.backdropFilter !== undefined || document.body.style.webkitBackdropFilter !== undefined
    }
};

// Getters
const getters = {
    ios: (state) => state.browser.iphone || state.browser.ipad || state.browser.ipod,

    mobile: (state) => state.browser.iphone || state.browser.ipad || state.browser.ipod || state.browser.android,

    mac: (state, getters) => !getters.ios && !!USER_AGENT.match(/mac/gi),

    shortcutKey: (state, getters) => (getters.mac ? 'metaKey' : 'ctrlKey')
};

// Mutations
const mutations = {};

// Actions
const actions = {};

export default {
    namespaced: true,

    state,
    getters,
    mutations,
    actions
};
