/**
 * Edit video preview Vuex store
 */

import { Duration } from 'cte-video-studio';

const originalState = () => ({
    currentTimelineId: null
});

const state = {
    ...originalState()
};

const getters = {
    isMainTimeline: (state, getters, rootState) => !rootState.ui.sequenceMode && !state.currentTimelineId,

    currentTimelineLabels: (state, getters, rootState, rootGetters) => {
        let labels = {};

        if (!getters.isMainTimeline) {
            let timelineId = !rootState.ui.sequenceMode
                ? state.currentTimelineId
                : rootGetters['sequences/childAt'](0).id;

            rootGetters['sequences/' + timelineId + '/all']?.forEach((elementState) => {
                let startTime = rootGetters['sequences/' + timelineId + '/' + elementState.id + '/start'],
                    endTime = rootGetters['sequences/' + timelineId + '/' + elementState.id + '/end'];
                labels[elementState.id] = startTime;
                if (endTime != Duration.END_DEFAULT) labels[elementState.id + '_end'] = endTime;
            });
        }

        return labels;
    }
};

const mutations = {
    setCurrentTimelineId(state, timelineId) {
        state.currentTimelineId = timelineId;
    }
};

const actions = {
    clearState({ state }) {
        Object.assign(state, originalState());
    }
};

export default {
    namespaced: true,

    state,
    getters,
    mutations,
    actions
};
