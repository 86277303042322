<template>
    <div class="editing-view">
        <template v-if="!loading">
            <div class="ui-layout">
                <ui-header></ui-header>
                <ui-media-library :animation="false"></ui-media-library>
                <ui-sidebar></ui-sidebar>
                <ui-body></ui-body>
            </div>
            <ui-save-banner></ui-save-banner>
            <ui-modal-container></ui-modal-container>
        </template>
        <div v-else class="view-loading-wrapper">
            <img class="loading-logo animation-breathing" src="@/assets/images/logo-cte-white.png" alt="" />
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, provide, ref, watch } from 'vue';
import { UiMediaLibrary, UiModalContainer } from '@/js/components';
import { useStore } from 'vuex';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import { BRAND_CHANNEL, VIDEO_PRODUCTION_UPDATED } from '@/js/constants/index.js';
import { subscribeToChannel } from '@/js/utils.js';
import FetchBrandsDataService from '@/js/application/services/brands/FetchBrandsDataService.js';
import BrandDTO from '@/js/dto/BrandDTO.js';
import { useI18n } from 'vue-i18n';
import UiHeader from '@/js/brands/components/ui/header/UiHeader.vue';
import UiSidebar from '@/js/brands/components/ui/sidebar/UiSidebar.vue';
import UiBody from '@/js/brands/components/ui/body/UiBody.vue';
import UiSaveBanner from '@/js/brands/components/ui/UiSaveBanner.vue';

const route = useRoute();
const store = useStore();
const loading = ref(true);
const { t } = useI18n();
const confirmationMessage = t('You have unsaved changes, are you sure you want to leave this page?');

provide('$videoStudio', null);

const brandId = computed(() => route.params.id);
const brand = ref({});

const needsSave = computed(() => store.getters['ui/needsSave']);

const initStore = (config, resources) => {
    store.dispatch('ui/init', config);
    store.dispatch('branding/init', resources);
    store.dispatch('ui/saveOriginalState');
    loading.value = false;
};

const initWebSockets = () => {
    subscribeToChannel(BRAND_CHANNEL, { id: brandId.value }, 'private', {
        [VIDEO_PRODUCTION_UPDATED]: (event) => {
            store.dispatch('branding/libraries/updateItem', {
                library: event.producible_library,
                id: event.producible_id,
                data: event.producible_data,
                applyToOriginal: true
            });
        }
    });
};

const fetchBrand = () => {
    new FetchBrandsDataService(store).fetchBrandResources(brandId.value).then((data) => {
        if (data?.brand) {
            brand.value = new BrandDTO(data.brand);
        }
        if (data?.config && data?.resources) {
            initStore(data.config, data.resources);
        }
    });
};

const preventUnsavedChangesLoss = (e) => {
    if (needsSave.value) {
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
    }
};

const clearStore = () => {
    store.dispatch('branding/clearState');
    store.dispatch('ui/clearState');
};

watch(brandId, () => {
    loading.value = true;
    clearStore();
    fetchBrand();
});

onBeforeRouteLeave((to, from, next) => {
    if (needsSave.value) {
        const answer = window.confirm(confirmationMessage);
        if (answer) {
            next();
        } else {
            next(false);
        }
    } else {
        next();
    }
});

onMounted(() => {
    initWebSockets();
    fetchBrand();

    window.addEventListener('beforeunload', preventUnsavedChangesLoss);
});

onUnmounted(() => {
    window.removeEventListener('beforeunload', preventUnsavedChangesLoss);
    clearStore();
});
</script>
