import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';
import tinycolor from 'tinycolor2';

export const PLUGIN_NAME = 'textColorContrast';
export const PLUGIN_KEY = new PluginKey(PLUGIN_NAME);

// Utility function to resolve CSS variable to actual color value
function resolveCssVariableToColor(cssVariable) {
    // Create a temporary element
    const tempElement = document.createElement('span');
    tempElement.style.display = 'none'; // Make sure it's not visible
    tempElement.style.color = cssVariable;

    const element = document.querySelector('.ui-text-editor-element');
    if (!element) {
        return cssVariable;
    }

    element.appendChild(tempElement);
    // Get the computed style to resolve the color value
    const resolvedColor = tinycolor(getComputedStyle(tempElement).color).toHexString();
    element.removeChild(tempElement);

    return resolvedColor;
}

const findColorSpans = (doc) => {
    const decorations = [];

    doc.descendants((node, position) => {
        if (!node.isInline || !node.text || !node.marks.length) return;

        node.marks.forEach((mark) => {
            if (mark.type.name != 'textStyle' || !mark.attrs.color) return;

            const actualColor = resolveCssVariableToColor(mark.attrs.color);

            //For the purpose of Success Criteria 1.4.3 and 1.4.6, contrast is measured with respect to the specified background over
            //which the text is rendered in normal usage. If no background color is specified, then white is assumed.
            if (tinycolor.readability(actualColor, '#ffffff') < 1.43) {
                decorations.push(
                    Decoration.inline(position, position + node.text.length, {
                        class: 'non-readable-color'
                    })
                );
            }
        });
    });

    return DecorationSet.create(doc, decorations);
};

export default Extension.create({
    name: PLUGIN_NAME,

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: PLUGIN_KEY,
                state: {
                    init(_, { doc }) {
                        return findColorSpans(doc);
                    },
                    apply(transaction, value, oldState, newState) {
                        return transaction.docChanged ? findColorSpans(transaction.doc) : value;
                    }
                },
                props: {
                    decorations(state) {
                        return this.getState(state);
                    }
                }
            })
        ];
    }
});
