/**
 * Edit video Sequence background Vuex store extension
 */
import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';
import _pick from 'lodash/pick';
import {
    Background,
    Color,
    types,
    Sound,
    Align,
    Dimension,
    Overlay,
    Duration,
    Timeline,
    conversions
} from 'cte-video-studio';
import timeline from '../timeline.js';
import media from '../commons/media.js';
import gsap from 'gsap';

export const resolveBackgroundCardData = (data, findLibraryItem) => {
    let resolvedData = _merge({}, data);
    if (!findLibraryItem('backgroundAnimations', '', data.animation.type))
        resolvedData.animation.type = Background.ANIMATION_DEFAULT;

    return resolvedData;
};

const mergeTemplateData = (state, data, findLibraryItem) => {
    // First we remove potentially disabled component
    let resolvedData = resolveBackgroundCardData(data, findLibraryItem);

    // Then we pick only the relevant template data
    let mergedKeys = ['type', 'color', 'blur', 'overlay'];

    switch (resolvedData.type) {
        case Background.IMAGE_TYPE:
            mergedKeys.push('animation');
            if (resolvedData.type != state.type || !state.image.src) {
                mergedKeys.push('image.src', 'image.src__id', !state.image.src__ref && 'image.src__ref');
            }
            break;
        case Background.VIDEO_TYPE:
            mergedKeys.push('animation');
            if (resolvedData.type != state.type || !state.video.src) {
                mergedKeys.push(
                    'video.src',
                    'video.src__id',
                    !state.video.src__ref && 'video.src__ref',
                    'video.captions',
                    'video.volume',
                    'video.fade'
                );
            }
            break;
        case Background.ANIMATED_TYPE:
            mergedKeys.push('animation');
            if (resolvedData.type != state.type || !state.video.animatedSrc) {
                mergedKeys.push(
                    'video.animatedSrc',
                    'video.animatedSrc__id',
                    !state.video.animatedSrc__ref && 'video.animatedSrc__ref',
                    'video.captions',
                    'video.volume',
                    'video.fade'
                );
            }
            break;
        case Background.CARTOON_TYPE:
            mergedKeys.push('animation');
            if (resolvedData.type != state.type || !state.video.cartoonSrc) {
                mergedKeys.push(
                    'image.cartoonSrc',
                    'image.cartoonSrc__id',
                    !state.image.cartoonSrc__ref && 'image.cartoonSrc__ref'
                );
            }
            break;
        case Background.COLLAGE_TYPE:
            mergedKeys.push('animation.type', 'collage.animated');
            if (resolvedData.type != state.type || !state.collage.images.image1)
                mergedKeys.push(
                    'collage.images.image1',
                    'collage.images.image1__id',
                    !state.collage.images.image1__ref && 'collage.images.image1__ref'
                );
            if (resolvedData.type != state.type || !state.collage.images.image2)
                mergedKeys.push(
                    'collage.images.image2',
                    'collage.images.image2__id',
                    !state.collage.images.image2__ref && 'collage.images.image2__ref'
                );
            if (resolvedData.type != state.type || !state.collage.images.image3)
                mergedKeys.push(
                    'collage.images.image3',
                    'collage.images.image3__id',
                    !state.collage.images.image3__ref && 'collage.images.image3__ref'
                );
            if (resolvedData.type != state.type || !state.collage.images.image4)
                mergedKeys.push(
                    'collage.images.image4',
                    'collage.images.image4__id',
                    !state.collage.images.image4__ref && 'collage.images.image4__ref'
                );
            break;
        case Background.MAP_ZOOM_TYPE:
            mergedKeys.push('mapZoom.marker.enabled');
            if (resolvedData.type != state.type || (!state.mapZoom.coords && !state.mapZoom.address)) {
                mergedKeys.push('mapZoom.coords', 'mapZoom.address');
            }
            if (resolvedData.type != state.type || !state.mapZoom.marker.src) {
                mergedKeys.push(
                    'mapZoom.marker.src',
                    'mapZoom.marker.src__id',
                    !state.mapZoom.marker.src && 'mapZoom.marker.src__ref'
                );
            }
            break;
        case Background.RECORDING_TYPE:
            mergedKeys.push('animation');
            if (resolvedData.type != state.type || !state.recording.src) {
                mergedKeys.push(
                    'recording.src',
                    'recording.src__id',
                    !state.recording.src__ref && 'recording.src__ref',
                    'recording.captions',
                    'recording.volume',
                    'video.fade'
                );
            }
            break;
    }

    resolvedData = _pick(resolvedData, mergedKeys);

    // Finally we return the merge of the current state and the relevant template keys
    return _merge({}, state, resolvedData);
};

const state = () => ({
    ...timeline.state(),

    el: null,
    type: Background.DEFAULT,
    color: {
        start: Color.BACKGROUND_DEFAULT,
        end: Color.NONE,
        start__ref: Color.BACKGROUND_DEFAULT,
        end__ref: Color.NONE,
        angle: Color.GRADIENT_ANGLE_DEFAULT
    },
    image: {
        src: '',
        src__ref: '',
        src__id: '',
        cartoonSrc__ref: '',
        cartoonSrc__id: '',
        cartoonSrc: ''
    },
    video: {
        src: '',
        src__ref: '',
        src__id: '',
        animatedSrc: '',
        animatedSrc__ref: '',
        animatedSrc__id: '',
        captions: false,
        captioning: false,
        captioningStatus: null,
        volume: {
            value: Sound.BACKGROUND_VOLUME_DEFAULT,
            custom: Sound.VOLUME_EMPTY
        },
        fade: {
            value: Sound.BACKGROUND_FADE_DEFAULT,
            custom: Sound.FADE_EMPTY
        },
        timerange: { start: 0, end: -1 },
        timerangeSegments: [],
        playbackRate: 1,
        totalDuration: () => 0
    },
    collage: {
        animated: false,
        images: {
            image1: '',
            image2: '',
            image3: '',
            image4: '',
            image1__ref: '',
            image1__id: '',
            image2__ref: '',
            image2__id: '',
            image3__ref: '',
            image3__id: '',
            image4__ref: '',
            image4__id: ''
        }
    },
    blur: false,
    animation: {
        type: Background.ANIMATION_DEFAULT,
        zoomTarget: Align.CENTER,
        zoomTargetX: Dimension.ZERO,
        zoomTargetY: Dimension.ZERO
    },
    mapZoom: {
        coords: Background.DEFAULT_MAP_ZOOM_COORDS,
        address: '',
        marker: {
            enabled: true,
            src: '',
            src__ref: '',
            src__id: ''
        }
    },
    overlay: {
        enabled: false,
        type: Overlay.DEFAULT,
        color: {
            start: Color.NONE,
            end: Color.NONE,
            start__ref: Color.NONE,
            end__ref: Color.NONE,
            angle: Color.GRADIENT_ANGLE_DEFAULT
        },
        opacity: Color.BACKGROUND_OPACITY_DEFAULT,
        animation: {
            enabled: false,
            start: Duration.START_DEFAULT,
            end: Duration.END_DEFAULT
        }
    },

    timeline: () => gsap.timeline({ id: Timeline.BACKGROUND_TIMELINE_ID })
});

// Getters
const getters = {
    hasPreviousBackground: (state) => state.type == Background.PREVIOUS_TYPE,

    hasColorBackground: (state) => state.type == Background.COLOR_TYPE,

    hasImageBackground: (state) => state.type == Background.IMAGE_TYPE,

    hasVideoBackground: (state) => state.type == Background.VIDEO_TYPE,

    hasAnimatedBackground: (state) => state.type == Background.ANIMATED_TYPE,

    hasCartoonBackground: (state) => state.type == Background.CARTOON_TYPE,

    hasCollageBackground: (state) => state.type == Background.COLLAGE_TYPE,

    hasMapZoomBackground: (state) => state.type == Background.MAP_ZOOM_TYPE,

    hasRecordingBackground: (state) => state.type == Background.RECORDING_TYPE,

    hasLoadingProductionBackground: (state) => state.type == Background.LOADING_PRODUCTION_TYPE,

    backgroundTimeline: (state) => state.timeline,

    backgroundNeedsRefresh: (state) => state.needsRefresh,

    backgroundVolume: (state) => conversions.optionValue(state.video.volume, [], Sound.BACKGROUND_VOLUME_DEFAULT),

    backgroundVolumeFade: (state) => conversions.optionValue(state.video.fade, [], Sound.BACKGROUND_FADE_DEFAULT),

    backgroundPlaybackRate: (state, getters) =>
        (getters.hasVideoBackground && state.video.playbackRate) ||
        (getters.hasRecordingBackground && state.recording.playbackRate) ||
        Duration.PLAY_BACK_RATE_DEFAULT,

    backgroundOverlayColorStart: (state, getters, rootState) =>
        state.overlay.color.start || rootState.branding.palette.color1 || Color.BACKGROUND_DEFAULT,

    collageImage: (state) => (index) => state.collage.images['image' + index] || '',

    backgroundVideoTotalDuration: (state) => state.video.totalDuration(),

    backgroundVideoSrc: (state, getters, rootState) => {
        const hasTimerangeSegments =
            Array.isArray(state.video.timerangeSegments) && state.video.timerangeSegments.length > 0;

        //Optimize video playback by using media fragments if quickcut is not open
        if (hasTimerangeSegments && !rootState.ui.quickcut.isQuickCutOpen) {
            const firstSegment = state.video.timerangeSegments[0];
            const lastSegment = state.video.timerangeSegments[state.video.timerangeSegments.length - 1];

            const start = firstSegment?.start ?? 0;
            const end = lastSegment?.end ?? state.video.totalDuration();

            return conversions.mediaFragmentAssetSrc(state.video.src, state.video.totalDuration(), {
                start,
                end
            });
        }

        return state.video.src;
    },

    getBackgroundVideoTimerangeSegments: (state) => state.video.timerangeSegments,

    backgroundRecordingSrc: (state) =>
        state.recording.timerange
            ? conversions.mediaFragmentAssetSrc(
                  state.recording.src,
                  state.recording.totalDuration(),
                  state.recording.timerange
              )
            : state.recording.src,

    collageImageRef: (state) => (index) => state.collage.images['image' + index + '__ref'] || '',

    collageImageId: (state) => (index) => state.collage.images['image' + index + '__id'] || ''
};

// Mutations
const mutations = {
    setBackgroundEl(state, el) {
        state.el = el;
    },

    setBackgroundTimeline: timeline.mutations.setTimeline,

    enableBackgroundRefresh: timeline.mutations.enableRefresh,

    setBackground(state, type) {
        state.type = types.isBackground(type) ? type : Background.DEFAULT;
    },

    setBackgroundImage(state, src) {
        state.image.src = src;
    },

    setBackgroundVideo(state, src) {
        state.video.src = src;
    },

    setBackgroundVideoTimerange(state, timerange) {
        state.video.timerange = Object.assign({ start: 0, end: -1 }, timerange || {});
    },
    
    setBackgroundVideoTimerangeSegments(state, segments) {
        state.video.timerangeSegments = Array.isArray(segments) && !!segments.length ? _cloneDeep(segments) : [];
    },

    setBackgroundVideoTotalDuration(state, duration) {
        state.video.totalDuration = () => duration;
    },

    setBackgroundCollageAnimation(state, animated) {
        state.collage.animated = animated;
    },

    setBackgroundCollage(state, collage) {
        state.collage.animated = collage.animated;
        let p,
            images = collage.images ?? [];
        for (p in images) {
            if (images[p]) state.collage.images[p] = images[p];
        }
    },

    setBackgroundVideoCaptions(state, captions) {
        state.video.captions = Array.isArray(captions) && !!captions.length && captions;
    },

    setBackgroundCartoon(state, src) {
        state.image.cartoonSrc = src;
    },

    setBackgroundAnimated(state, src) {
        state.video.animatedSrc = src;
    },

    setBackgroundVolume(state, volume) {
        let val = conversions.percentNum(volume.value, false),
            custom = conversions.percentNum(volume.custom, false);
        if (val !== undefined) {
            state.video.volume.value = val;
            state.video.volume.custom = Sound.VOLUME_EMPTY;
        } else if (custom !== undefined) {
            state.video.volume.value = Sound.VOLUME_CUSTOM;
            state.video.volume.custom = custom;
        } else {
            state.video.volume.value = Sound.BACKGROUND_VOLUME_DEFAULT;
            state.video.volume.custom = Sound.VOLUME_EMPTY;
        }
    },

    setBackgroundVolumeFade(state, fade) {
        if (types.isZeroPositive(fade.value)) {
            state.video.fade.value = parseFloat(fade.value);
            state.video.fade.custom = Sound.FADE_EMPTY;
        } else if (types.isZeroPositive(fade.custom)) {
            state.video.fade.value = Sound.FADE_CUSTOM;
            state.video.fade.custom = parseFloat(fade.custom);
        } else {
            state.video.fade.value = Sound.BACKGROUND_FADE_DEFAULT;
            state.video.fade.custom = Sound.FADE_EMPTY;
        }
    },

    enableBackgroundOverlay(state, enable) {
        state.overlay.enabled = enable;
    },

    setBackgroundOverlay(state, type) {
        state.overlay.type = types.isBackgroundOverlay(type) ? type : Overlay.DEFAULT;
    },

    setBackgroundOverlayOpacity(state, opacity) {
        state.overlay.opacity = conversions.percentNum(opacity, false) || Color.BACKGROUND_OPACITY_DEFAULT;
    },

    enableBackgroundOverlayAnimation(state, enable) {
        state.overlay.animation.enabled = enable;
    },

    setBackgroundOverlayStart(state, start) {
        state.overlay.animation.start = types.isZeroPositive(start) ? parseFloat(start) : Duration.START_DEFAULT;
    },

    setBackgroundOverlayEnd(state, end) {
        state.overlay.animation.end = types.isZeroPositive(end) ? parseFloat(end) : Duration.END_DEFAULT;
    },

    enableBackgroundBlur(state, blur) {
        state.blur = blur;
    },

    setBackgroundAnimation(state, type) {
        state.animation.type = types.isBackgroundAnimation(type) ? type : Background.ANIMATION_DEFAULT;
    },

    setBackgroundAnimationZoom(state, data) {
        state.animation.zoomTarget = types.isAlign(data.zoomTarget) ? data.zoomTarget : Align.CENTER;
        state.animation.zoomTargetX =
            state.animation.zoomTarget == Align.CUSTOM && types.isDimension(data.zoomTargetX)
                ? data.zoomTargetX
                : Dimension.ZERO;
        state.animation.zoomTargetY =
            state.animation.zoomTarget == Align.CUSTOM && types.isDimension(data.zoomTargetY)
                ? data.zoomTargetY
                : Dimension.ZERO;
    },

    setBackgroundMapZoomCoords(state, coords) {
        state.mapZoom.coords = coords || Background.DEFAULT_MAP_ZOOM_COORDS;
    },

    enableBackgroundMapZoomMarker(state, enabled) {
        state.mapZoom.marker.enabled = enabled;
    },

    setBackgroundMapZoomMarker(state, src) {
        state.mapZoom.marker.src = src;
    },

    setVideoPlaybackRate(state, playbackRate) {
        state.video.playbackRate = playbackRate;
    },

    setBackgroundColor(state, color) {
        state.color.start = types.isColor(color.start) ? color.start : Color.BACKGROUND_DEFAULT;
        state.color.start__ref = color.start__ref || state.color.start;
        state.color.end = types.isColor(color.end) ? color.end : Color.NONE;
        state.color.end__ref = color.end__ref || state.color.end;
        state.color.angle = types.isAngle(color.angle) ? color.angle : Color.GRADIENT_ANGLE_DEFAULT;
    },

    setBackgroundImageReference(state, ref) {
        state.image.src__ref = ref;
    },

    setBackgroundImageId(state, id) {
        state.image.src__id = id;
    },

    setBackgroundVideoReference(state, ref) {
        state.video.src__ref = ref;
    },

    setBackgroundVideoId(state, id) {
        state.video.src__id = id;
    },

    setBackgroundVideoCaptioning(state, captioning) {
        state.video.captioning = !!captioning;
    },

    setBackgroundVideoCaptioningStatus(state, status) {
        state.video.captioningStatus = status;
    },

    setBackgroundCartoonReference(state, ref) {
        state.image.cartoonSrc__ref = ref;
    },

    setBackgroundCartoonId(state, id) {
        state.image.cartoonSrc__id = id;
    },

    setBackgroundAnimatedReference(state, ref) {
        state.video.animatedSrc__ref = ref;
    },

    setBackgroundAnimatedId(state, id) {
        state.video.animatedSrc__id = id;
    },

    setBackgroundOverlayColor(state, color) {
        state.overlay.color.start = types.isColor(color.start) ? color.start : Color.NONE;
        state.overlay.color.start__ref = color.start__ref || state.overlay.color.start;
        state.overlay.color.end = types.isColor(color.end) ? color.end : Color.NONE;
        state.overlay.color.end__ref = color.end__ref || state.overlay.color.end;
        state.overlay.color.angle = types.isAngle(color.angle) ? color.angle : Color.GRADIENT_ANGLE_DEFAULT;
    },

    setBackgroundMapZoomAddress(state, address) {
        state.mapZoom.address = address || '';
    },

    setBackgroundMapZoomMarkerReference(state, ref) {
        state.mapZoom.marker.src__ref = ref;
    },

    setBackgroundMapZoomMarkerId(state, id) {
        state.mapZoom.marker.src__id = id;
    },

    setCollageImages(state, images) {
        let p;
        for (p in images) {
            if (!/(?:__ref|__id)$/.test(p) && images[p] != state.collage.images[p]) state.collage.images[p] = images[p];
        }
    },

    setCollageImageReferences(state, images) {
        let p;
        for (p in images) {
            if (/__ref$/.test(p)) state.collage.images[p] = images[p];
        }
    },

    setCollageImageIds(state, images) {
        let p;
        for (p in images) {
            if (/__id$/.test(p)) state.collage.images[p] = images[p];
        }
    }
};

// Actions
const actions = {
    initBackground({ commit, dispatch }, data) {
        commit('setBackground', data.type);
        commit('setBackgroundColor', data.color);
        commit('setBackgroundImage', data.image.src);
        commit('setBackgroundVideo', data.video.src);
        commit('setBackgroundVideoCaptions', data.video.captions);
        commit('setBackgroundCollage', data.collage || {});
        commit('setBackgroundCartoon', data.image.cartoonSrc);
        commit('setBackgroundAnimated', data.video.animatedSrc);
        commit('setBackgroundVolume', data.video.volume);
        commit('setBackgroundVolumeFade', data.video.fade);
        commit('setBackgroundVideoTimerange', data.video.timerange);
        commit('setBackgroundVideoTimerangeSegments', data.video.timerangeSegments);
        commit('setVideoPlaybackRate', data.video?.playbackRate ? data.video.playbackRate : 1);
        commit('enableBackgroundOverlay', data.overlay.enabled);
        commit('setBackgroundOverlay', data.overlay.type);
        commit('setBackgroundOverlayColor', data.overlay.color);
        commit('setBackgroundOverlayOpacity', data.overlay.opacity);
        commit('enableBackgroundOverlayAnimation', data.overlay.animation.enabled);
        commit('setBackgroundOverlayStart', data.overlay.animation.start);
        commit('setBackgroundOverlayEnd', data.overlay.animation.end);
        commit('enableBackgroundBlur', data.blur);
        commit('setBackgroundAnimation', data.animation.type);
        commit('setBackgroundAnimationZoom', data.animation);
        commit('setBackgroundMapZoomCoords', data.mapZoom.coords);
        commit('enableBackgroundMapZoomMarker', data.mapZoom.marker.enabled);
        commit('setBackgroundMapZoomMarker', data.mapZoom.marker.src);
        commit('setBackgroundImageId', data.image.src__id);
        commit('setBackgroundImageReference', data.image.src__ref);
        commit('setBackgroundVideoId', data.video.src__id);
        commit('setBackgroundVideoReference', data.video.src__ref);
        commit('setBackgroundVideoCaptioning', data.video.captioning);
        commit('setBackgroundCartoonId', data.image.cartoonSrc__id);
        commit('setBackgroundCartoonReference', data.image.cartoonSrc__ref);
        commit('setBackgroundAnimatedId', data.video.animatedSrc__id);
        commit('setBackgroundAnimatedReference', data.video.animatedSrc__ref);
        commit('setBackgroundMapZoomAddress', data.mapZoom.address);
        commit('setBackgroundMapZoomMarkerId', data.mapZoom.marker.src__id);
        commit('setBackgroundMapZoomMarkerReference', data.mapZoom.marker.src__ref);

        if (data.video.captioning) {
            dispatch('startBackgroundVideoCaptioning');
        }

        if (data.recording) dispatch('recording/init', data.recording);
    },

    updateBackgroundImage({ commit }, data) {
        commit('setBackgroundImageId', data.src__id);
        commit('setBackgroundImage', data.src);
    },

    updateBackgroundVideo({ commit, state }, data) {
        if (!data.src__id) {
            commit('setBackgroundVideoTotalDuration', 0);
        }
        if (state.video.src__id !== data.src__id) {
            commit('setBackgroundVideoTimerange', { start: 0, end: -1 });
        }

        commit('setBackgroundVideoId', data.src__id);
        commit('setBackgroundVideo', data.src);
    },

    updateBackgroundCartoon({ commit }, data) {
        commit('setBackgroundCartoonId', data.src__id);
        commit('setBackgroundCartoon', data.src);
    },

    updateBackgroundAnimated({ commit }, data) {
        commit('setBackgroundAnimatedId', data.src__id);
        commit('setBackgroundAnimated', data.src);
    },

    updateBackgroundRecording({ commit, state }, data) {
        if (!data.src__id) {
            commit('recording/setTotalDuration', 0);
        }
        if (state.recording.src__id !== data.src__id) {
            commit('recording/setTimerange', { start: 0, end: -1 });
        }

        commit('recording/setSourceId', data.src__id);
        commit('recording/setSource', data.src);
    },

    updateBackgroundMapZoomLocation({ commit }, { coords, address }) {
        commit('setBackgroundMapZoomCoords', coords);
        commit('setBackgroundMapZoomAddress', address);
    },

    updateBackgroundMapZoomMarker({ commit }, data) {
        commit('setBackgroundMapZoomMarkerId', data.src__id);
        commit('setBackgroundMapZoomMarker', data.src);
    },

    startBackgroundVideoCaptioning(context) {
        let { commit, dispatch, state, getters } = context;

        if (!!state.video.src__id) {
            commit('setBackgroundVideoCaptioning', true);
            dispatch(
                'ui/processes/startMediaCaptioningProcess',
                {
                    mediaId: state.video.src__id,
                    subscriberId: getters.path + '/setBackgroundVideoCaptions',
                    success: (captions) => {
                        dispatch('ui/history/startStep', null, { root: true });
                        commit('setBackgroundVideoCaptions', captions);
                        commit('setBackgroundVideoCaptioning', false);
                        commit('setBackgroundVideoCaptioningStatus', null);
                        dispatch('ui/saveVideo', null, { root: true });
                    },
                    error: (error) => {
                        commit('setBackgroundVideoCaptioning', false);
                        commit('setBackgroundVideoCaptioningStatus', error);
                        dispatch('ui/saveVideo', null, { root: true });
                    }
                },
                { root: true }
            );
        }
    },

    cancelBackgroundVideoCaptioning({ commit, dispatch, state, getters }) {
        if (!!state.video.src__id && state.video.captioning) {
            dispatch(
                'ui/processes/cancelMediaCaptioningProcess',
                {
                    mediaId: state.video.src__id,
                    subscriberId: getters.path + '/setBackgroundVideoCaptions'
                },
                { root: true }
            );
            commit('setBackgroundVideoCaptioning', false);
            commit('setBackgroundVideoCaptioningStatus', null);
            dispatch('ui/saveVideo', null, { root: true });
        }
    },

    updateCollageImages({ commit }, images) {
        commit('setCollageImageIds', images);
        commit('setCollageImages', images);
    },

    applyTemplateBackground({ state, rootGetters, dispatch }, data) {
        dispatch('initBackground', mergeTemplateData(state, data, rootGetters['branding/libraries/findLibraryItem']));
    }
};

export default {
    state,
    modules: {
        recording: media
    },
    getters,
    mutations,
    actions
};
