import { executeRequest } from '../apiClient.js';

const BASE_URL = import.meta.env.VITE_LEGACY_APP_URL;

class OnboardingService {
    constructor(store) {
        this.store = store;
    }

    getInitialOnboardingStep(userId) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${BASE_URL}/onboarding/${userId}`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'getOnboardingStep'
        });
    }

    updateOnboardingStep(userId, data) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.put(`${BASE_URL}/onboarding/${userId}`, data);
            },
            onSuccess: async (response) => response.data,
            actionType: 'updateOnboardingStep'
        });
    }
}

export default OnboardingService;
