<!--
  -- Component name: DiscoverBTT
  -- Type: Transition
  -- Uses: 
  -- Tags: translation
  -->
<template></template>

<script>
import TransitionMixin from '../../mixins/Transition';
import { conversions } from '../../utils';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true, // The transition component is placed above the in and out elements
            swapElements: true // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            if (this.previous)
                t.fromTo(
                    [this.backgroundOutElement, this.sequenceOutElement],
                    { yPercent: 0 },
                    { yPercent: -100, duration: 0.4, ease: 'power1.in' }
                );
            return t;
        }
    }
};
</script>
