import { executeRequest } from '../apiClient.js';

const BASE_URL = import.meta.env.VITE_LEGACY_APP_URL;

export default class VideoTtsApiService {
    constructor(store) {
        this.store = store;
    }

    createTask(voiceId, text, videoId, exaggeration) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.post(`${BASE_URL}/tts`, {
                    voice_id: voiceId,
                    text,
                    video_id: videoId,
                    style_exaggeration: exaggeration
                });
            },
            onSuccess: async (response) => response.data,
            actionType: 'createTask'
        });
    }

    getTaskResult(taskId) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${BASE_URL}/tts/${taskId}`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'getTaskResult'
        });
    }
}
