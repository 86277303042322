<template>
    <div class="slide-bar-background" ref="backgroundRef">
        <div
            class="slide-bar-selection"
            :style="{ left: `${startPercent}%`, width: `${endPercent - startPercent}%` }"
        ></div>
        <div class="slide-bar-handle start" @mousedown="startDrag('start')" :style="{ left: `${startPercent}%` }"></div>
        <div class="slide-bar-handle end" @mousedown="startDrag('end')" :style="{ left: `${endPercent}%` }"></div>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue';

const backgroundRef = ref(null);
const startPercent = ref(0);
const endPercent = ref(100);

const props = defineProps({
    videoDuration: {
        type: Number,
        required: true
    }
});

const emit = defineEmits(['updateStartTime', 'updateEndTime']);

const startDrag = (handle) => {
    const onMouseMove = (e) => {
        const rect = backgroundRef.value.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        const newPercent = Math.max(0, Math.min(100, (offsetX / rect.width) * 100));

        if (handle === 'start') {
            if (newPercent < endPercent.value) {
                startPercent.value = newPercent;
                emit('updateStartTime', (startPercent.value / 100) * props.videoDuration);
            }
        } else if (handle === 'end') {
            if (newPercent > startPercent.value) {
                endPercent.value = newPercent;
                emit('updateEndTime', (endPercent.value / 100) * props.videoDuration);
            }
        }
    };

    const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
};
</script>
