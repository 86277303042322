export default class BrandDTO {
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.prefixedName = data.prefixed_name;
        this.organizationId = parseInt(data.organization_id);
        this.needsMigration = data.needs_migration;
        this.isMaster = data.is_master;
        this.masterId = data.master_id;
        this.autoActivationEnabled = data.auto_activation_enabled;
        this.folderIds = data.folder_ids;
        this.enabledTemplateLanguages = data.enabled_template_languages;
        this.createdAt = data.created_at;
        this.updatedAt = data.updated_at;
        this.readableCreatedAt = data.readable_created_at;
        this.readableUpdatedAt = data.readable_updated_at;
        this.permissions = {
            edit: data.permissions?.edit,
            attachFolder: data.permissions?.attach_folder,
            promoteMaster: data.permissions?.promote_master,
            attachToMaster: data.permissions?.attach_to_master,
            copy: data.permissions?.copy,
            move: data.permissions?.move,
            delete: data.permissions?.delete
        };
        this.nbChilds = data.child_ids?.length ?? 0;
        this.nbFolders = data.folder_ids?.length ?? 0;
        this.logoUrl = data.logo?.media_url ?? '';
    }
}
