<template>
    <div :id="'ui-media-modal-' + id" class="ui-media-modal" :class="modalClasses" @[transitionEndEvent]="destroy">
        <iframe ref="$modalFrame" class="ui-media-modal-frame" :src="url" />
    </div>
</template>

<script>
import { BaseModal, UsesPostMessage } from '../../mixins';

export default {
    mixins: [BaseModal, UsesPostMessage],

    props: {
        url: {
            type: String,
            default: ''
        },
        caller: {
            type: Object,
            default: null
        },
        libraryModal: {
            type: Object,
            default: null
        },
        extraData: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            modalFrameIsReady: false,
            willHide: false,
            hidden: false
        };
    },

    computed: {
        modalClasses() {
            return {
                ready: this.modalFrameIsReady
                // 'hidden': this.hidden,
            };
        },

        transitionEndEvent() {
            return !this.modalFrameIsReady ? 'transitionend' : null;
        }
    },

    watch: {
        url(newValue) {
            this.postMessageOrigin = this.parsePostMessageOrigin(newValue);
        }
    },

    methods: {
        selectMedia(mediaId) {
            this.$store.commit('ui/setCurrentMediaLibrarySelectedMedia', mediaId);
            if (this.caller) this.caller.select();
        },

        close() {
            this.endPostMessageListening();
            this.endCapturingFocus();
            this.modalFrameIsReady = false;
        },

        destroy(event) {
            if (event.target == this.$el && event.propertyName == 'visibility') {
                if (!this.willHide) this.$store.commit('ui/modals/remove', this.id);
            }
        },

        hide() {
            this.willHide = true;
            this.modalFrameIsReady = false;
            this.endCapturingFocus();
        },

        closeAfterHiding() {
            this.endPostMessageListening();
            this.$store.commit('ui/modals/remove', this.id);
        },

        receiveMessageEvent(data, event) {
            // console.log('UiMediaModal', this.id, 'receiveMessageEvent', data);
            if (data.type == 'error') {
                this.close();
                this.$store.commit('ui/modals/remove', this.id);
                return;
            }

            if (data.type == 'event') {
                this.handleMessageEvent(data);
                return;
            }

            if (data.type == 'action') {
                this.handleMessageAction(data);
                return;
            }
        },

        handleMessageEvent(data) {
            let { type, message, ...other } = data;

            switch (data.message) {
                case 'ready':
                    if (this.id == 'media-create-rights') {
                        this.postMessageEvent('action', 'medium-rights-init', this.extraData);
                    }
                    this.modalFrameIsReady = true;
                    break;

                case 'medium-upload-processing':
                    this.libraryModal.postMessageEvent(type, message, other);
                    this.hide();
                    break;
                case 'medium-upload-success':
                case 'medium-upload-error':
                case 'medium-upload-progress':
                    this.libraryModal.postMessageEvent(type, message, other);
                    if (data.response) this.libraryModal.disableAddNewMedia(data.response.total_size_limit);
                    if (data.complete) this.closeAfterHiding();
                    break;

                case 'external-medium-imported':
                    this.libraryModal.postMessageEvent(type, message, other);
                    this.libraryModal.disableAddNewMedia(data.total_size_limit);
                    this.close();
                    break;

                case 'medium-imported-to-library':
                    this.libraryModal.postMessageEvent(type, message, other);
                    this.libraryModal.disableAddNewMedia(data.total_size_limit);
                    this.close();
                    this.libraryModal.openMediaDetails(data.url);
                    break;

                case 'medium-variation-created':
                    this.libraryModal.postMessageEvent(type, message, other);
                    this.libraryModal.disableAddNewMedia(data.total_size_limit);
                    this.close();
                    this.libraryModal.openMediaDetails(data.version.url);
                    break;
                case 'medium-variation-deleted':
                    this.caller.postMessageEvent(type, message, other);
                    this.libraryModal.postMessageEvent(type, message, other);
                    this.libraryModal.selector.remove();
                    this.close();
                    break;

                case 'medium-rights-created':
                    this.caller.postMessageEvent(type, message, other);
                    this.close();
                    break;
                case 'medium-rights-updated':
                    this.caller.postMessageEvent(type, message, other);
                    this.libraryModal.postMessageEvent(type, message, other);
                    this.close();
                    break;

                case 'tag-renamed':
                case 'variation-duplicated':
                    this.libraryModal.postMessageEvent(type, message, other);
                    this.close();
                    break;

                case 'medium-tag-removed':
                    this.libraryModal.postMessageEvent(type, message, other);
                    break;
            }
        },

        handleMessageAction(data) {
            switch (data.message) {
                case 'close':
                    this.close();
                    break;
                case 'select-medium':
                    this.selectMedia(data.id);
                    this.close();
                    break;
                case 'edit-medium':
                    this.addModal({
                        id: 'media-edit',
                        type: 'ui-media-modal',
                        url: data.url,
                        caller: this.caller,
                        libraryModal: this.libraryModal
                    });
                    this.close();
                    break;
                case 'create-medium-rights':
                    this.addModal({
                        id: 'media-create-rights',
                        type: 'ui-media-modal',
                        url: data.url,
                        caller: this,
                        extraData: { rights: data.rights }
                    });
                    break;
                case 'edit-medium-rights':
                    this.addModal({
                        id: 'media-edit-rights',
                        type: 'ui-media-modal',
                        url: data.url,
                        caller: this,
                        libraryModal: this.libraryModal
                    });
                    break;
                case 'delete-medium-variation':
                    this.addModal({
                        id: 'media-delete-variation',
                        type: 'ui-media-modal',
                        url: data.url,
                        caller: this,
                        libraryModal: this.libraryModal
                    });
                    break;
            }
        }
    },

    mounted() {
        this.postMessageTarget = this.$refs.$modalFrame;
        this.postMessageOrigin = this.parsePostMessageOrigin(this.url);
        this.startPostMessageListening();
    }
};
</script>
