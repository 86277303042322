<template>
    <ui-dropdown
        :id="cardElementId + '-audio'"
        class="audio-dropdown centered"
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="bottom-end"
        menu-strategy="fixed"
        :disabled="enableDropDown"
        :toggle-style="toggleStyle"
        :icon-only="iconOnly"
        :tooltip="enableDropDown ? null : $t('Volume')"
    >
        <template #dropdown-toggle>
            <svg-icon icon="audio-icon" />
            <span v-if="!iconOnly">{{ $t('Audio') }}</span>
        </template>
        <div>
            <ui-range-input
                :id="cardElementId + '-asset-audio-volume'"
                class="ui-content-row"
                v-model="volume"
                :horizontal="false"
                :disabled="readOnly"
                :label="$t('Video volume')"
            />
        </div>
    </ui-dropdown>
</template>
<script>
import { useStore } from 'vuex';
import { defineComponent, computed } from 'vue';
import { UiIcon } from '../../../../../../components';
import UiRangeInput from '../../../../../../components/UiRangeInput.vue';
import UiNumberInput from '../../../../../../components/UiNumberInput.vue';
import UiDropdown from '../../../../../../components/UiDropdown.vue';
import { useHistory } from '../../../../../composables/useHistory';
import { useElement } from '../../../../../composables/useElement';

export default defineComponent({
    components: { UiIcon, UiRangeInput, UiNumberInput, UiDropdown },
    props: { seqId: String, cardElementId: String, elementId: String, readOnly: Boolean, iconOnly: Boolean },
    setup(props) {
        const store = useStore();
        const { saveHistoryStep } = useHistory();
        const { elementState, elementStoreModulePath, videoState, isRecordingCategory } = useElement(
            props.seqId,
            props.elementId
        );

        const toggleStyle = computed(() => {
            return Math.round(100 * videoState.value.volume) != 100 ? { color: 'var(--edit-mode-color)' } : {};
        });

        const enableDropDown = computed(() => {
            return props.readOnly || !videoState.value?.src;
        });

        const volume = computed({
            get: () => {
                return Math.round(100 * videoState.value.volume);
            },
            set: (value) => {
                saveHistoryStep(() => {
                    if (isRecordingCategory.value) {
                        store.commit(`${elementStoreModulePath.value}/recording/setVolume`, value / 100);
                    } else {
                        store.commit(`${elementStoreModulePath.value}/setVideoVolume`, value / 100);
                    }
                });
            }
        });

        return {
            toggleStyle,
            enableDropDown,
            volume
        };
    }
});
</script>

<style></style>
