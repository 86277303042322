/**
 * Video Studio
 * Ordered list Vuex store module
 */

// Initial state
const state = () => ({
    id: '',
    path: [],
    order: []
});

// Getters
const getters = {
    path: (state) => state.path.join('/'),

    total: (state) => state.order.length,

    all: (state) => state.order.map((name) => state[name]).filter((s) => !!s),

    indexOf: (state) => (item) => state.order.indexOf(item),

    childAt: (state) => (index) => state[state.order[index]]
};

// Mutations
const mutations = {
    setID(state, id) {
        state.id = id;
        state.path[state.path.length - 1] = id;
    },

    setPath(state, path) {
        state.path = path;
        state.id = path[path.length - 1];
    },

    add(state, data) {
        let id = '',
            path = [];
        if (data.module) {
            id = data.id;
            path = [...state.path, data.id];
            this.registerModule(path, data.item);
        } else {
            id = data.id;
            state[data.id] = data.item;
        }
        state.order.push(id);
    },

    remove(state, data) {
        let id = '';
        if (data.module) {
            id = data.path[data.path.length - 1];
            this.unregisterModule(data.path);
        } else {
            id = data.id;
            delete state[data.id];
        }
        state.order.splice(state.order.indexOf(id), 1);
    },

    move(state, data) {
        let currentIndex = state.order.indexOf(data.id);
        state.order.splice(currentIndex, 1);
        state.order.splice(data.index, 0, data.id);
    }
};

// Actions
const actions = {
    addItem({ commit, state, getters }, data) {
        // data: { module, id, item, index }
        commit('add', data);
        if (data.module) commit(data.id + '/setPath', [...state.path, data.id]);
        if (typeof data.index == 'number') {
            commit('move', { id: data.id, index: data.index });
        }
    },

    removeItem({ commit }, data) {
        // data: { module, path, id }
        commit('remove', data);
    },

    moveItem({ commit }, data) {
        // data: { id, index }
        commit('move', data);
    }
};

export default {
    namespaced: true,

    state,
    getters,
    mutations,
    actions
};
