/**
 * Video Studio
 * Edition data Vuex store module
 */

// Initial state
const originalState = () => ({
    editingElement: null,
    editing: false,
    movementEvent: null
});

const state = {
    ...originalState()
};

// Getters
const getters = {};

// Mutations
const mutations = {
    setEditingElement(state, element) {
        state.editingElement = element;
    },

    setEditing(state, editing) {
        state.editing = editing;
    },

    sendMovementEvent(state, event) {
        state.movementEvent = event;
    }
};

// Actions
const actions = {
    clearState({ state }) {
        Object.assign(state, originalState());
    }
};

export default {
    namespaced: true,

    state,
    getters,
    mutations,
    actions
};
