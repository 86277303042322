export default class StudioProdPreviewManager {
    constructor(store) {
        this.store = store;

        this.socket = null;
        this.stopped = false;
        this.studioContainer = document.querySelector('#studio-container');
    }

    initPreviewResize() {
        const { format } = this.store.state.display;
        this.studioContainer.style.width = format.width / window.devicePixelRatio + 'px';
        this.studioContainer.style.height = format.height / window.devicePixelRatio + 'px';

        window.$studio.proxy.setContainerScale(1 / window.devicePixelRatio, true);
        window.$studio.proxy.studio.$stage.scale = 1 / window.devicePixelRatio;
    }

    startCapture(useFrameCapture) {
        console.log('start ' + (useFrameCapture ? 'frame ' : '') + 'capture');

        let messageObject = {
            name: useFrameCapture ? 'start-frame' : 'start',
            x: (window.screenX + (window.outerWidth - window.innerWidth) / 2) * window.devicePixelRatio,
            y: (window.screenY + window.outerHeight - window.innerHeight) * window.devicePixelRatio,
            rw: this.studioContainer.clientWidth,
            rh: this.studioContainer.clientHeight,
            pr: window.devicePixelRatio
        };
        messageObject.w = messageObject.rw * window.devicePixelRatio;
        messageObject.h = messageObject.rh * window.devicePixelRatio;

        this.socket?.emit('message', messageObject);
    }

    stopCapture(useFrameCapture) {
        console.log('stop ' + (useFrameCapture ? 'frame ' : '') + 'capture');

        if (this.socket && this.socket.connected) {
            console.log('stopping ' + (useFrameCapture ? 'frame ' : '') + 'capture...');

            if (useFrameCapture) {
                this.socket.emit('message', { name: 'stop-frame' });
            } else if (!this.stopped) {
                this.stopped = true;
                this.socket.emit('message', { name: 'stop' });
            }
        }
    }

    initIoSocket() {
        if (window.io) {
            this.socket = io.connect(import.meta.env.VITE_PRODUCTION_URL);

            this.socket.on('reload', () => {
                console.log('reload capture');
                this.socket.close();
                window.location.reload();
            });

            this.socket.on('play', () => {
                console.log('play capture');
                window.postMessage(
                    {
                        target: 'studio',
                        type: 'start'
                    },
                    window.location.origin
                );
            });

            this.socket.on('seek', (data) => {
                console.log('seek capture ' + data.time);
                window.postMessage(
                    {
                        target: 'studio',
                        type: 'seek',
                        time: data.time
                    },
                    window.location.origin
                );
            });

            this.socket.on('quit', () => {
                console.log('quit capture');
                window.close();
            });
        }

        window.addEventListener('message', (event) => {
            if (event.origin == window.location.origin && event.data.target != 'studio') {
                switch (event.data) {
                    case 'startCapture':
                        this.startCapture();
                        break;
                    case 'startFrameCapture':
                        this.startCapture(true);
                        break;
                    case 'stopCapture':
                        this.stopCapture();
                        break;
                    case 'stopFrameCapture':
                        this.stopCapture(true);
                        break;
                }
            }
        });
    }
}
