import { ref, watch, nextTick, inject, onMounted, onBeforeUnmount } from 'vue';
import { debounce } from 'lodash';

import { usePreview } from '@video-composables/usePreview';
import { useLoading } from '@video-composables/useLoading';

let currentExtraction = null;

export function useMediaPlayer(
    videoSource,
    mediaType,
    onDurationLoaded,
    onAudioDataLoaded,
    sequenceProps,
    dragHandle,
    sequenceTotalTime
) {
    const videoStudio = inject('$videoStudio');

    const EPSILON = 0.001;

    const videoWidth = ref(0);
    const videoHeight = ref(0);

    const { currentTimelineId } = usePreview();

    const videoEndTime = ref(0);
    const videoElement = ref(null);
    const { studioLoading } = useLoading();

    onMounted(() => {
        createVideoElement();
    });

    onBeforeUnmount(() => {
        if (videoElement.value) {
            videoElement.value.remove();
        }
    });

    function createVideoElement() {
        if (!videoElement.value) {
            videoElement.value = document.createElement('video');
            videoElement.value.style.display = 'none';
            document.body.appendChild(videoElement.value);
        }
    }

    watch(() => videoSource.value.src, handleSourceChange);

    async function handleSourceChange(newSrc) {
        if (!newSrc) return;

        await nextTick();
        if (!videoElement.value) {
            createVideoElement();
        }
        videoElement.value.src = newSrc;
        videoElement.value.onloadedmetadata = () => handleMetadataLoaded(videoElement.value);
    }

    async function handleMetadataLoaded(video) {
        videoEndTime.value = video.duration;
        videoWidth.value = video.videoWidth;
        videoHeight.value = video.videoHeight;
        onDurationLoaded(video.duration);
        onAudioDataLoaded(videoSource.value.src);
        sequenceProps.rate = await calculateMediaRate(video);
    }

    async function calculateMediaRate(video) {
        return 100;

        // return mediaType.value === 'audio'
        //     ? await calculateBitrate(videoSource.value.src, video.duration)
        //     : estimateFPS(video);
    }

    function estimateFPS(video) {
        if (!video) return 0;

        const commonFrameRates = [24, 30, 60];
        return commonFrameRates.reduce((closestRate, currentRate) => {
            const currentDifference = Math.abs(videoEndTime.value / video.duration - 1 / currentRate);
            const closestDifference = Math.abs(videoEndTime.value / video.duration - 1 / closestRate);
            return currentDifference < closestDifference ? currentRate : closestRate;
        }, commonFrameRates[0]);
    }

    async function calculateBitrate(source, duration) {
        //simplification pour ne pas avoir à charger le fichier audio
        return 100;
    }

    const extractFrames = (canvasElement, canvasWidth, canvasHeight) => {
        if (!canvasElement) return;

        if (currentExtraction) {
            currentExtraction.cancel();
        }

        const video = videoElement.value;
        const context = canvasElement.getContext('2d');
        if (!video || !context) return;

        const videoAspectRatio = video.videoWidth / video.videoHeight;

        // Calculer la largeur de frame qui remplit exactement le canvas en hauteur
        const frameWidth = Math.ceil(canvasHeight * videoAspectRatio);
        const frameHeight = canvasHeight;

        canvasElement.width = canvasWidth;
        canvasElement.height = canvasHeight;

        // Calculer le nombre de frames nécessaires pour couvrir le canvas
        const frameCount = Math.ceil(canvasWidth / frameWidth) + 1; // Ajouter une frame supplémentaire pour éviter les blancs

        // Calculer l'espacement entre les centres des frames
        const spacing = canvasWidth / (frameCount - 1);

        const timeBetweenFrames = videoEndTime.value / (frameCount - 1);

        if (!isFinite(timeBetweenFrames) || timeBetweenFrames <= 0) {
            console.error('Invalid time between frames');
            return;
        }

        context.clearRect(0, 0, canvasElement.width, canvasElement.height);

        let isCancelled = false;
        currentExtraction = {
            cancel: () => {
                isCancelled = true;
            }
        };

        function seekVideo(video, time) {
            return new Promise((resolve) => {
                const onSeeked = () => {
                    video.removeEventListener('seeked', onSeeked);
                    resolve();
                };
                video.addEventListener('seeked', onSeeked);
                video.currentTime = time;
            });
        }

        // Commence par dessiner le premier frame partout
        seekVideo(video, 0)
            .then(() => {
                if (isCancelled) return;
                for (let i = 0; i < frameCount; i++) {
                    drawFrame(i, 0);
                }
                // Puis dessine la dernière frame (qui sera en dessous de l'avant-dernière)
                return seekVideo(video, videoEndTime.value);
            })
            .then(() => {
                if (isCancelled) return;
                drawFrame(frameCount - 1, videoEndTime.value);
                processFrame(0);
            });

        function processFrame(frameIndex) {
            if (isCancelled || frameIndex >= frameCount - 1) {
                currentExtraction = null;
                return;
            }

            const frameTime = frameIndex * timeBetweenFrames;

            if (!isFinite(frameTime) || frameTime < 0 || frameTime > videoEndTime.value) {
                console.error('Skipping invalid frame time:', frameTime);
                processFrame(frameIndex + 1);
                return;
            }

            seekVideo(video, frameTime).then(() => {
                if (isCancelled) return;
                drawFrame(frameIndex, frameTime);
                // Utilise nextTick pour permettre au navigateur de mettre à jour l'UI
                nextTick(() => processFrame(frameIndex + 1));
            });
        }

        function drawFrame(frameIndex, time) {
            // Calculer la position du centre de la frame
            const centerX = frameIndex * spacing;

            // Calculer la position de départ pour dessiner la frame
            let xPosition = centerX - frameWidth / 2;

            // Ajuster la position pour les frames aux extrémités
            if (frameIndex === 0) {
                xPosition = 0;
            } else if (frameIndex === frameCount - 1) {
                xPosition = canvasWidth - frameWidth;
            }

            // Dessiner la frame
            context.drawImage(video, xPosition, 0, frameWidth, frameHeight);

            // Debug : Ajouter un indicateur de temps sur chaque frame
            // context.fillStyle = 'white';
            // context.font = '12px Arial';
            // context.fillText(time.toFixed(2) + 's', xPosition + 5, frameHeight - 5);

            // Debug : Dessiner une ligne au centre de la frame pour vérification
            // context.beginPath();
            // context.moveTo(centerX, 0);
            // context.lineTo(centerX, frameHeight);
            // context.strokeStyle = 'red';
            // context.stroke();
        }
    };

    const initializeTimeline = () => {
        nextTick(() => {
            if (currentTimelineId.value) {
                executeSeekWhenReady(sequenceProps.startTime + EPSILON);
            }
        });
    };

    function performSeekOperation(elapsedTime) {
        const debouncedSeek = debounce(executeSeek, 50);
        debouncedSeek(elapsedTime);
    }

    function simpleSeek(elapsedTime) {
        if (elapsedTime <= 0) {
            elapsedTime = EPSILON;
        }
        const seekTime = elapsedTime + sequenceProps.startTime;
        executeSeekWhenReady(seekTime);
    }

    function executeSeek(elapsedTime) {
        const seekTime = calculateSeekTime(elapsedTime);

        //verify if the seek time is within the video duration
        if (seekTime > sequenceTotalTime.value) {
            simpleSeek(sequenceTotalTime.value - 1);
            return;
        }

        simpleSeek(seekTime);
    }

    function executeSeekWhenReady(seekTime) {
        if (!studioLoading.value) {
            nextTick(() => {
                videoStudio.value.studio.$stage.seekSequenceTimeline(currentTimelineId.value, seekTime);
            });
        } else {
            const unwatch = watch(studioLoading, (newValue) => {
                if (!newValue) {
                    nextTick(() => {
                        videoStudio.value.studio.$stage.seekSequenceTimeline(currentTimelineId.value, seekTime);
                    });
                    unwatch();
                }
            });
        }
    }

    function calculateSeekTime(elapsedTime) {
        if (dragHandle.value === 'start' && sequenceTotalTime.value > elapsedTime) {
            return elapsedTime + EPSILON;
        } else if (dragHandle.value === 'end' && elapsedTime > sequenceProps.startTime) {
            return elapsedTime - EPSILON;
        }

        return elapsedTime - EPSILON;
    }

    return {
        videoElement,
        videoEndTime,
        videoWidth,
        videoHeight,
        extractFrames,
        performSeekOperation,
        initializeTimeline,
        simpleSeek
    };
}
