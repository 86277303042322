export default {
    provide() {
        return {
            iconSlots: () =>
                Object.keys(this.$slots)
                    .filter((key) => /\-icon$/.test(key))
                    .reduce((slots, key) => Object.assign(slots, { [key]: this.$slots[key] }), {})
        };
    }
};
