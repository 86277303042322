<template>
    <ui-card :id="id" :class="'ui-card-' + id" :title="$t('Brand colors')">
        <template #header-icon>
            <fa-icon class="icon" icon="fa-regular fa-palette" />
        </template>

        <fieldset class="settings-main-colors">
            <legend>{{ $t('Main colors') }}</legend>
            <div class="color-selectors">
                <ui-color-selector
                    :id="id + '-color1'"
                    :color="paletteColors[0]"
                    :label="sprintf($t('Color %1$d'), 1)"
                    :showLabel="false"
                    :disabled="readOnly"
                    @[colorSelectorChangeEvent]="setPaletteColor(1, $event)"
                />
                <ui-color-selector
                    :id="id + '-color2'"
                    :color="paletteColors[1]"
                    :label="sprintf($t('Color %1$d'), 2)"
                    :showLabel="false"
                    :disabled="readOnly"
                    @[colorSelectorChangeEvent]="setPaletteColor(2, $event)"
                />
            </div>
            <p class="color-info">
                <fa-icon class="icon" icon="fa-regular fa-circle-info" />
                {{ $t('These colors will be used in your templates.') }}
            </p>
        </fieldset>

        <fieldset class="settings-additional-colors">
            <legend>{{ $t('Additional colors') }}</legend>
            <ul class="color-selectors ui-card-row">
                <ui-removable-color-selector
                    v-for="(color, index) in paletteColors.slice(2)"
                    ref="$additionalColorSelectors"
                    :key="'color-selector-' + (index + 3)"
                    :id="id + '-color1'"
                    :color="color"
                    :default-color="colorEmpty"
                    :enable-empty="true"
                    :label="sprintf($t('Brand color %1$s'), index + 3)"
                    :showLabel="false"
                    :disabled="readOnly"
                    @[colorSelectorChangeEvent]="setPaletteColor(index + 3, $event)"
                    @[colorSelectorRemoveEvent]="removePaletteColor(index + 3, $event)"
                />
                <button
                    v-if="!readOnly && colorSelectorsTotal < paletteColorsMax"
                    class="add-color-selector empty"
                    @click="addPaletteColor"
                >
                    <span class="visually-hidden">{{ $t('Add a color') }}</span>
                    <div class="selector-empty" aria-hidden="true">
                        <svg class="border">
                            <rect x="0" y="0" width="100%" height="100%" rx="10" fill="none" />
                        </svg>
                        <fa-icon class="icon" icon="fa-solid fa-plus" />
                    </div>
                </button>
            </ul>
            <ui-switch-button
                :id="id + '-enable-other-colors'"
                class="ui-card-row"
                :label="$t('Allow usage of custom colors')"
                v-model="otherColorsEnabled"
                :disabled="readOnly"
            />
        </fieldset>
    </ui-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Color } from 'cte-video-studio';
import { UI_COLOR_SELECTOR_CHANGE } from '../../../../../components/UiColorSelector.vue';
import UiRemovableColorSelector, { UI_COLOR_SELECTOR_REMOVE } from '../UiRemovableColorSelector.vue';
import { BrandCard } from '../../../../mixins';
import { printf as sprintf } from 'fast-printf';

export default {
    mixins: [BrandCard],

    components: {
        UiRemovableColorSelector
    },

    props: {
        id: {
            type: String,
            default: 'settings-colors'
        }
    },

    data() {
        return {
            colorSelectorChangeEvent: UI_COLOR_SELECTOR_CHANGE,
            colorSelectorRemoveEvent: UI_COLOR_SELECTOR_REMOVE,
            colorEmpty: Color.NONE,
            colorSelectorsTotal: 0
        };
    },

    computed: {
        ...mapState({
            //
        }),

        ...mapGetters({
            //
        }),

        paletteColorsMax() {
            return Object.keys(this.state.palette).length - 2;
        },

        paletteColors() {
            return Object.values(this.state.palette)
                .slice(0, this.colorSelectorsTotal + 2)
                .map((color) => ({ value: color }));
        },

        otherColorsEnabled: {
            get() {
                return this.state.enableOtherColors;
            },
            set(enable) {
                this.modifyBrand(() => {
                    this.$store.commit('branding/enableOtherColors', enable);
                });
            }
        }
    },

    watch: {
        //
    },

    methods: {
        sprintf,

        setPaletteColor(index, color) {
            this.modifyBrand(() => {
                this.$store.commit('branding/setPaletteWithEmpty', { ['color' + index]: color.value });
            });
        },

        addPaletteColor() {
            if (this.colorSelectorsTotal < this.paletteColorsMax) {
                this.setPaletteColor(this.colorSelectorsTotal + 3, { value: Color.NONE });
                this.colorSelectorsTotal++;
                this.$nextTick(() => {
                    this.$refs.$additionalColorSelectors[this.colorSelectorsTotal - 1].showPicker();
                });
            }
        },

        removePaletteColor(index) {
            let i,
                palette = {};
            for (i = index; i < this.colorSelectorsTotal + 2; i++) {
                palette['color' + i] = this.state.palette['color' + (i + 1)];
            }
            palette['color' + (this.colorSelectorsTotal + 2)] = Color.NONE;
            this.colorSelectorsTotal--;
            this.modifyBrand(() => {
                this.$store.commit('branding/setPaletteWithEmpty', palette);
            });
        }
    },

    created() {
        let firstEmptyColorIndex = Object.values(this.state.palette).findIndex((color) => !color);
        this.colorSelectorsTotal =
            firstEmptyColorIndex > -1 ? Math.max(0, firstEmptyColorIndex - 2) : this.paletteColorsMax;
    },

    mounted() {
        //
    }
};
</script>
