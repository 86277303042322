<template>
    <div class="studio-warning studio-container studio-container-align" v-if="warning.active && preview">
        <svg width="90" height="80" viewBox="0 0 90 80">
            <path
                d="M89,68.75A7.51,7.51,0,0,1,82.49,80h-75A7.51,7.51,0,0,1,1,68.75l37.49-65a7.51,7.51,0,0,1,13,0L89,68.75ZM45,55.31a7.19,7.19,0,1,0,7.19,7.19A7.19,7.19,0,0,0,45,55.31ZM38.18,29.48l1.16,21.25a1.87,1.87,0,0,0,1.87,1.77h7.58a1.87,1.87,0,0,0,1.87-1.77l1.16-21.25a1.87,1.87,0,0,0-1.87-2h-9.9a1.87,1.87,0,0,0-1.87,2Z"
            />
        </svg>
        <p>{{ displayedWarning }}</p>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: {
        warning: Object,
        preview: Boolean
    },

    computed: {
        ...mapState({
            i18n: (state) => state.i18n
        }),

        displayedWarning() {
            return this.warning.stack.length ? this.i18n.warnings[this.warning.stack[0]] || this.warning.stack[0] : '';
        }
    }
};
</script>
