<!--
  -- Component name: DivideColor
  -- Type: Transition
  -- Uses: color.start
  -- Tags: color
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <defs>
            <clipPath :id="svgMaskID" clipPathUnits="objectBoundingBox" :style="clipPathTransform">
                <rect class="divide-mask-left" x="0%" y="0%" width="50%" height="100%"></rect>
                <rect class="divide-mask-right" x="50%" y="0%" width="50%" height="100%"></rect>
            </clipPath>
        </defs>
        <rect class="divide-color-left" x="50%" y="0%" width="0%" height="100%" :fill="state.color.start"></rect>
        <rect class="divide-color-right" x="50%" y="0%" width="0%" height="100%" :fill="state.color.start"></rect>
        <rect class="divide-white-left" x="50%" y="0%" width="0%" height="100%" fill="white"></rect>
        <rect class="divide-white-right" x="50%" y="0%" width="0%" height="100%" fill="white"></rect>
    </svg>
</template>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            isAbove: true,
            needsRefresh: true,
            swapElements: true,
            keepActive: true
        };
    },

    computed: {
        svgMaskID() {
            return this.containerID + '-divide-color-mask';
        },

        svgMask() {
            return 'url(#' + this.svgMaskID + ')';
        },

        clipPathTransform() {
            return this.format && this.getClipPathTransform(1280, 720);
        }
    },

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            t.set(this, { outClipPathProxy: this.svgMask }, 0.0001);
            t.fromTo(
                '#' + this.svgMaskID + ' .divide-mask-left',
                { attr: { x: '0%' } },
                { attr: { x: '-50%' }, duration: 2, ease: 'power1.inOut' },
                0
            );
            t.fromTo(
                '#' + this.svgMaskID + ' .divide-mask-right',
                { attr: { x: '50%' } },
                { attr: { x: '100%' }, duration: 2, ease: 'power1.inOut' },
                0
            );

            t.fromTo(
                '#' + this.containerID + ' .divide-color-left',
                { attr: { x: '50%', width: '0%' } },
                { attr: { x: '-30%', width: '30%' }, duration: 2, ease: 'power1.inOut' },
                0
            );
            t.fromTo(
                '#' + this.containerID + ' .divide-color-right',
                { attr: { x: '50%', width: '0%' } },
                { attr: { x: '100%', width: '30%' }, duration: 2, ease: 'power1.inOut' },
                0
            );

            t.fromTo(
                '#' + this.containerID + ' .divide-white-left',
                { attr: { x: '50%', width: '0%' } },
                { attr: { x: '-20%', width: '20%' }, duration: 2.05, ease: 'power1.inOut' },
                0
            );
            t.fromTo(
                '#' + this.containerID + ' .divide-white-right',
                { attr: { x: '50%', width: '0%' } },
                { attr: { x: '100%', width: '20%' }, duration: 2.05, ease: 'power1.inOut' },
                0
            );

            return t;
        }
    }
};
</script>
