<template>
    <AssetImage
        v-if="enabled"
        ref="$cover"
        name="$cover"
        :src="cover"
        width="100%"
        height="100%"
        :cover="true"
        :imageStyles="coverStyles"
        class="studio-cover"
        @load-success="update($event)"
    />
</template>

<script>
import { Align, Timeline } from '../constants';
import AssetImage from './assets/AssetImage.vue';
import { conversions } from '../utils';
import gsap from 'gsap';
import { mapGetters, mapState } from 'vuex';

export default {
    components: {
        AssetImage
    },

    data() {
        return {};
    },

    computed: {
        ...mapState('settings', {
            enabled: (state) => state.cover.enabled,
            position: (state) => state.cover.position
        }),

        ...mapGetters({
            cover: 'settings/cover'
        }),

        coverStyles() {
            return {
                objectPosition: conversions.coverAlignment(
                    this.position.alignH,
                    this.position.alignV,
                    this.position.alignX,
                    this.position.alignY
                )
            };
        }
    },

    methods: {
        update(event) {
            this.updateTimeline();
            this.updateTimelineOut();
        },

        updateTimeline() {
            this.$store.commit('settings/setCoverTimeline', () => this.getTimelineBase());
        },

        getTimeline() {
            return gsap.timeline();
        },

        getTimelineBase() {
            let t = this.getTimeline();
            t.vars.id = Timeline.COVER_TIMELINE_ID;
            t.fromTo(
                this.$refs.$cover.$el,
                { autoAlpha: 0 },
                { duration: 0.5, autoAlpha: 1, overwrite: false, ease: 'none' },
                0
            );
            return t;
        },

        updateTimelineOut() {
            this.$store.commit('settings/setCoverTimelineOut', () => this.getTimelineOut());
        },

        getTimelineOut() {
            let t = gsap.timeline();
            t.fromTo(
                this.$refs.$cover.$el,
                { autoAlpha: 1 },
                { duration: 0.1, autoAlpha: 0, overwrite: false, immediateRender: false, ease: 'none' }
            );
            return t;
        }
    },

    mounted() {}
};
</script>
