<!--
  -- Component name: EDFEventail1
  -- Type: Transition
  -- Uses: 
  -- Tags: edf
  -->
<template>
    <div class="studio-sequence-transition studio-container" :class="classes" :id="this.containerID">
        <AssetVideo
            width="100%"
            height="100%"
            :cover="true"
            :volume="0"
            :src="assetSrc"
            ref="$transitionVideo"
            @update="update($event)"
            :seqId="this.id"
        />
    </div>
</template>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';
import AssetVideo from '../assets/AssetVideo.vue';
import edfEventail1 from '@resources/assets/transitions/EDFEventail1l.webm';

export default {
    components: { AssetVideo },

    mixins: [TransitionMixin],

    data() {
        return {
            isAbove: true,
            inElementsAutoAppear: false,
            assetSrc: edfEventail1
        };
    },

    computed: {},

    methods: {
        getTimeline() {
            const t = gsap.timeline();
            t.add(this.$refs.$transitionVideo.timeline().play());
            t.set([this.backgroundInElement, this.sequenceInElement], { autoAlpha: 1 }, 1);
            return t;
        }
    }
};
</script>
