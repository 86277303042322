<template>
    <div class="preview-action-produce">
        <button
            class="ui-action-button preview-action preview-action-alt"
            @click="manageProduction"
            @mouseover="showProductionLabelAction"
            @mouseleave="hideProductionLabelAction"
        >
            <span class="action-icon">
                <svg-icon v-if="isProducing" class="loader" icon="circle-loader" />
                <fa-icon v-else class="icon" icon="fa-regular fa-arrow-down-to-line" />
            </span>
            <span class="preview-action-produce-tooltip" v-if="isProductionLabelVisible && productionLabelKey">{{
                $t(productionLabelKey)
            }}</span>
            <div v-if="productionTooltipKey" class="preview-action-produce-tooltip">
                {{ $t(productionTooltipKey) }}
            </div>
        </button>
        <div :class="previewProductionStatusClass"></div>
    </div>
</template>

<script>
import {
    PRODUCTION_STATUS_PENDING,
    PRODUCTION_STATUS_PRODUCED,
    PRODUCTION_STATUS_PRODUCING,
    STATUS_SAVED,
    STATUS_SAVING
} from '../../../../constants';
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import FetchVideoDataService from '../../../../application/services/video/FetchVideoDataService.js';
import ProduceVideoService from '../../../../application/services/video/ProduceVideoService';
import SubscribeVideoChannelService from '../../../../application/services/video/SubscribeVideoChannelService';
import { useModal } from '@/js/composables/useModal.js';

export default defineComponent({
    name: 'ProductionButton',

    setup() {
        const store = useStore();
        const route = useRoute();
        const { openModal } = useModal();

        const isProductionLabelVisible = ref(false);

        const saveStatus = computed(() => store.state.ui.saveStatus);
        const videoId = computed(() => route.params.id);
        const video = computed(() => store.getters['videos/currentVideo']);

        const isProducing = computed(() => {
            if (!video.value) {
                return false;
            }
            return (
                video.value.isProducing ||
                video.value.productionStatus === PRODUCTION_STATUS_PENDING ||
                video.value.productionStatus === PRODUCTION_STATUS_PRODUCING
            );
        });

        const productionLabelKey = computed(() => {
            if (video.value && !isProducing.value && !isProduced.value) {
                return 'production.produce';
            }

            return null;
        });

        const productionTooltipKey = computed(() => {
            if (video.value && isProducing.value) {
                return 'production.in-progress';
            } else if (video.value && isProduced.value && productionIsUpToDate.value) {
                return 'production.download';
            } else if (video.value && isProduced.value && !productionIsUpToDate.value) {
                return 'production.video-edited';
            }

            return null;
        });

        const previewProductionStatusClass = computed(() => {
            if (productionIsUpToDate.value) {
                return 'preview-production-status preview-production-status--latest-version';
            } else if (isProduced.value) {
                return 'preview-production-status preview-production-status--old-version';
            }
            return 'preview-production-status';
        });

        const isProduced = computed(() => {
            return !!(video.value && video.value.productionStatus === PRODUCTION_STATUS_PRODUCED);
        });

        const productionIsUpToDate = computed(() => {
            return !!(isProduced.value && video.value.updatedAt <= video.value.producedAt);
        });

        const fetchVideoData = () => {
            if (videoId.value) {
                new FetchVideoDataService(store).handle(videoId.value);
            }
        };

        const manageProduction = () => {
            if (isProduced.value) {
                openExportModal();
            } else if (!isProducing.value) {
                produceVideo();
            }
        };

        const showProductionLabelAction = () => {
            isProductionLabelVisible.value = true;
        };

        const hideProductionLabelAction = () => {
            isProductionLabelVisible.value = false;
        };

        const produceVideo = () => {
            video.value.isProducing = true;
            new ProduceVideoService(store).handle(video.value.id).catch(() => {
                video.value.isProducing = false;
            });
        };

        const initChannels = () => {
            if (videoId.value) {
                new SubscribeVideoChannelService(store).handle(videoId.value);
            }
        };

        const openExportModal = () => {
            openModal('ExportVideoModal', { video });
        };

        watch(saveStatus, (newStatus, oldStatus) => {
            if (newStatus === STATUS_SAVED && oldStatus === STATUS_SAVING) {
                fetchVideoData();
            }
        });

        onMounted(() => {
            fetchVideoData();
            initChannels();
        });

        onUnmounted(() => {
            store.dispatch('videos/clearCurrentVideo');
        });

        return {
            videoId,
            video,
            isProductionLabelVisible,
            productionLabelKey,
            productionTooltipKey,
            manageProduction,
            showProductionLabelAction,
            hideProductionLabelAction,
            produceVideo,
            isProducing,
            isProduced,
            previewProductionStatusClass
        };
    }
});
</script>
