<template>
    <ui-scrollable v-if="isVisible" ref="$scrollable" :margin-bottom="0" :margin-top="0">
        <ul class="captions" ref="$captions">
            <li
                v-for="(caption, index) in filteredCurrentCaptions"
                :key="'caption-' + index"
                class="caption-content"
                :class="{
                    active: index === activeCaptionIndex,
                    'between-segments': !isCaptionInValidSegment(caption) || caption.startTime === caption.endTime,
                    'zero-duration': caption.endTime === caption.startTime
                }"
                @click="seekToCaption(index)"
            >
                <div class="caption-timing">
                    <div class="caption-time caption-start">
                        <svg-icon icon="timer-start-icon" />
                        <input
                            type="text"
                            :value="formatSecondsToTime(caption.relativeStartTime)"
                            @change="handleRelativeTimingChange(index, 'startTime', $event.target.value)"
                            @focus="setCaptionActive(index)"
                            @blur="setCaptionActive(index, false)"
                        />
                    </div>

                    <div class="caption-time caption-end">
                        <svg-icon icon="timer-end-icon" />
                        <input
                            type="text"
                            :value="formatSecondsToTime(caption.relativeEndTime)"
                            @change="handleRelativeTimingChange(index, 'endTime', $event.target.value)"
                            @focus="setCaptionActive(index)"
                            @blur="setCaptionActive(index, false)"
                        />
                    </div>
                </div>

                <ui-text-input
                    :textarea="true"
                    :label="$t('Caption text')"
                    :show-label="false"
                    :model-value="caption.text"
                    @[textInputInputEvent]="_debouncedHandleCaptionChange(index, 'text', $event)"
                    @focus="setCaptionActive(index)"
                    @blur="setCaptionActive(index, false)"
                />

                <div class="caption-actions">
                    <button
                        class="ui-caption-editor-remove"
                        :disabled="currentCaptions.length < 2"
                        @click.prevent="removeCaption(index)"
                    >
                        <svg-icon icon="close-icon" />
                        <span class="visually-hidden">{{ $t('Remove caption') }}</span>
                    </button>

                    <button
                        class="ui-caption-editor-add"
                        :disabled="
                            index === filteredCurrentCaptions.length - 1 &&
                            isValidTimerange &&
                            currentCaptions.at(-1).endTime >= currentCaptionButton.timerange.end
                        "
                        @click.prevent="addNewCaption(index + 1)"
                    >
                        <fa-icon class="icon" icon="fa-solid fa-plus" />
                        <span class="visually-hidden">{{ $t('Add new caption') }}</span>
                    </button>
                </div>
            </li>
        </ul>
    </ui-scrollable>
</template>

<script>
import _debounce from 'lodash/debounce';
import UiScrollable from '../../../../../../../components/UiScrollable.vue';
import UiTextInput from '../../../../../../../components/UiTextInput.vue';
import { Duration } from 'cte-video-studio';
import { UI_TEXT_INPUT_INPUT } from '../../../../../../../components/UiTextInput.vue';
import { mapState } from 'vuex';

const UPDATE_CAPTION_DELAY = 500;
const DEFAULT_CAPTION_DURATION = 3;
const DEFAULT_CAPTION = {
    startTime: 0,
    endTime: DEFAULT_CAPTION_DURATION,
    text: ''
};

export default {
    name: 'UiCaptionEditorContent',

    inject: ['$videoStudio'],

    data() {
        return {
            textInputInputEvent: UI_TEXT_INPUT_INPUT,
            currentActiveCaptionIndex: null,
            _debouncedHandleCaptionChange: _debounce(this.handleCaptionChange, UPDATE_CAPTION_DELAY)
        };
    },

    components: {
        UiScrollable,
        UiTextInput
    },

    props: {
        isVisible: {
            type: Boolean,
            default: true
        },
        currentCaptions: {
            type: Array,
            default: () => [DEFAULT_CAPTION]
        },
        currentCaptionButton: {
            type: Object
        }
    },

    computed: {
        ...mapState({
            sequenceElapsedTime: (state) => state.display.timeline.sequenceElapsedTime,
            currentEditedItemId: (state) => state.ui.currentEditedItemId
        }),

        segments() {
            const segments = this.currentCaptionButton?.segments || [];
            // Si pas de segments ou tableau vide, on considre la vidéo entière comme un segment
            if (!segments.length) {
                return [{ start: 0, end: -1 }];
            }
            return segments;
        },

        // Calcule la durée totale des segments
        totalSegmentsDuration() {
            return this.segments.reduce((total, segment) => {
                const end = segment.end === -1 ? this.currentCaptionButton?.duration || 0 : segment.end;
                return total + (end - segment.start);
            }, 0);
        },

        filteredCurrentCaptions() {
            if (!this.isVisible || !this.currentCaptionButton) {
                return [];
            }

            const playbackRate = this.currentCaptionButton?.playbackRate || Duration.PLAY_BACK_RATE_DEFAULT;

            return [...this.currentCaptions]
                .sort((a, b) => a.startTime - b.startTime)
                .map((caption) => {
                    const adjustedCaption = {
                        ...caption,
                        startTime: caption.startTime / playbackRate,
                        endTime: caption.endTime / playbackRate
                    };

                    // Calcul des temps relatifs pour début et fin
                    adjustedCaption.relativeStartTime = this.calculateRelativeTime(adjustedCaption.startTime);
                    adjustedCaption.relativeEndTime = this.calculateRelativeTime(adjustedCaption.endTime);

                    return adjustedCaption;
                });
        },

        // Garder validSegments pour la validation des temps lors de l'édition
        validSegments() {
            return this.segments
                .filter((segment) => segment.end !== undefined && segment.start !== undefined)
                .sort((a, b) => a.start - b.start);
        },

        activeCaptionIndex() {
            return this.currentActiveCaptionIndex;
        },

        isValidTimerange() {
            return (
                this.currentCaptionButton?.timerange &&
                typeof this.currentCaptionButton.timerange.start !== 'undefined' &&
                typeof this.currentCaptionButton.timerange.end !== 'undefined'
            );
        }
    },

    methods: {
        calculateSegmentOffset(targetSegment) {
            let offset = 0;
            for (const segment of this.segments) {
                if (segment === targetSegment) break;
                const segmentEnd = segment.end === -1 ? this.currentCaptionButton?.duration : segment.end;
                offset += segmentEnd - segment.start;
            }
            return offset;
        },
        calculateGlobalTime(relativeTime) {
            // Si pas de segments ou segment unique couvrant toute la vidéo
            if (this.segments.length === 1 && this.segments[0].start === 0 && this.segments[0].end === -1) {
                return relativeTime;
            }

            let accumulatedDuration = 0;

            for (const segment of this.segments) {
                const segmentEnd = segment.end === -1 ? this.currentCaptionButton?.duration : segment.end;
                const segmentDuration = segmentEnd - segment.start;

                if (relativeTime <= accumulatedDuration + segmentDuration) {
                    return segment.start + (relativeTime - accumulatedDuration);
                }

                accumulatedDuration += segmentDuration;
            }

            // Si on dépasse tous les segments, on retourne le temps relatif
            return relativeTime;
        },
        handleTimingChange(index, property, value) {
            if (!this.currentCaptionButton) return;

            const playbackRate = this.currentCaptionButton?.playbackRate || Duration.PLAY_BACK_RATE_DEFAULT;
            const timeInSeconds = this.formatTimeToSeconds(value);
            const globalTime = timeInSeconds * playbackRate;

            let newCaptions = [...this.currentCaptions];
            let caption = { ...newCaptions[index] };
            caption[property] = globalTime;

            // Validation des temps
            if (property === 'startTime') {
                if (index > 0) {
                    caption.startTime = Math.max(caption.startTime, newCaptions[index - 1].endTime);
                }
                caption.endTime = Math.max(caption.endTime, caption.startTime + 0.1);
            } else if (property === 'endTime') {
                caption.endTime = Math.max(caption.endTime, caption.startTime + 0.1);
                if (index < newCaptions.length - 1) {
                    caption.endTime = Math.min(caption.endTime, newCaptions[index + 1].startTime);
                }
            }

            newCaptions[index] = caption;
            newCaptions.sort((a, b) => a.startTime - b.startTime);
            this.currentCaptionButton.change(newCaptions);
        },

        isTimeInValidSegment(time) {
            return this.segments.some((segment) => {
                const segmentEnd = segment.end === -1 ? this.currentCaptionButton?.duration : segment.end;
                return time >= segment.start && time <= segmentEnd;
            });
        },

        handleCaptionChange(index, property, value) {
            this.currentCaptions.splice(
                index,
                1,
                Object.assign({}, this.currentCaptions[index], { [property]: value })
            );
            this.currentCaptionButton.change(this.currentCaptions);
        },

        addNewCaption(index = null) {
            if (!this.currentCaptionButton) return;

            const playbackRate = this.currentCaptionButton?.playbackRate || Duration.PLAY_BACK_RATE_DEFAULT;

            const actualIndex =
                index !== null && index >= 0
                    ? Math.min(index, this.currentCaptions.length)
                    : this.currentCaptions.length;

            let startTime;
            if (actualIndex > 0) {
                startTime = this.currentCaptions[actualIndex - 1].endTime / playbackRate;
                console.log('StartTime basé sur le sous-titre précédent:', startTime);
            } else {
                startTime = this.segments[0]?.start || 0;
                console.log('StartTime basé sur le premier segment:', startTime);
            }

            const currentSegment = this.segments.find(
                (segment) =>
                    startTime >= segment.start &&
                    startTime <= (segment.end === -1 ? this.currentCaptionButton?.duration : segment.end)
            );
            console.log('Segment trouvé:', currentSegment);

            if (!currentSegment) {
                const nextSegment = this.segments.find((segment) => startTime < segment.start);
                if (nextSegment) {
                    startTime = nextSegment.start;
                    console.log('Ajustement startTime au segment suivant:', startTime);
                }
            }

            // Pour une nouvelle section à la fin d'un segment, on utilise le même temps pour start et end
            let endTime;
            if (startTime >= (currentSegment?.end || 0)) {
                endTime = startTime;
                console.log('EndTime égal à startTime (fin de segment):', endTime);
            } else {
                endTime = startTime + DEFAULT_CAPTION_DURATION;
                console.log('EndTime initial:', endTime);

                if (actualIndex < this.currentCaptions.length) {
                    endTime = Math.min(endTime, this.currentCaptions[actualIndex].startTime / playbackRate);
                    console.log('EndTime ajusté avec le sous-titre suivant:', endTime);
                }

                if (currentSegment) {
                    const segmentEnd =
                        currentSegment.end === -1 ? this.currentCaptionButton?.duration : currentSegment.end;
                    endTime = Math.min(endTime, segmentEnd);
                    console.log('EndTime ajusté avec la fin du segment:', endTime);
                }
            }

            const newCaption = {
                startTime: startTime * playbackRate,
                endTime: endTime * playbackRate,
                text: ''
            };
            console.log('Nouveau sous-titre:', newCaption);

            const newCaptions = [...this.currentCaptions];
            newCaptions.splice(actualIndex, 0, newCaption);
            newCaptions.sort((a, b) => a.startTime - b.startTime);
            console.log('Nouvelle liste de sous-titres:', newCaptions);

            this.currentCaptionButton.change(newCaptions);
        },

        formatTimeToSeconds(value) {
            let matches = String(value).match(/^(\d+):([0-5]?\d)\.(\d)$/);
            if (matches) {
                // Conversion correcte en secondes
                return parseInt(matches[1]) * 60 + parseInt(matches[2]) + parseFloat(`0.${matches[3]}`);
            }
            // Fallback pour les autres formats
            return Math.round(parseFloat(value) * 10) / 10 || 0;
        },

        formatSecondsToTime(value) {
            let v = this.formatTimeToSeconds(value),
                d = new Date();
            d.setTime(parseFloat(v) * 1000);
            return (
                ('0' + (d.getUTCHours() * 60 + d.getUTCMinutes())).slice(-2) +
                ':' +
                ('0' + d.getUTCSeconds()).slice(-2) +
                '.' +
                Math.round(d.getUTCMilliseconds() / 100)
            );
        },

        setCaptionActive(index, active = true) {
            this.currentActiveCaptionIndex = active ? index : null;
        },

        removeCaption(index) {
            // On utilise directement l'index car il est déjà dans le contexte filtré
            if (index < 0 || index >= this.filteredCurrentCaptions.length) {
                console.warn(`Index invalide pour la suppression: ${index}`);
                return;
            }

            const removedCaption = this.filteredCurrentCaptions[index];

            const newCaptions = [...this.currentCaptions];

            // Trouver l'index réel dans currentCaptions
            const realIndex = this.currentCaptions.findIndex(
                (caption) =>
                    caption.startTime === removedCaption.startTime &&
                    caption.endTime === removedCaption.endTime &&
                    caption.text === removedCaption.text
            );

            if (realIndex === -1) {
                console.warn('Impossible de trouver le sous-titre à supprimer');
                return;
            }

            newCaptions.splice(realIndex, 1);

            if (realIndex < newCaptions.length) {
                const nextCaption = newCaptions[realIndex];
                if (this.isTimeInValidSegment(nextCaption.startTime)) {
                    nextCaption.startTime = removedCaption.startTime;
                }
            }

            if (realIndex > 0) {
                const prevCaption = newCaptions[realIndex - 1];
                if (this.isTimeInValidSegment(prevCaption.endTime)) {
                    prevCaption.endTime = removedCaption.endTime;
                }
            }

            this.currentCaptionButton.change(newCaptions);
        },

        updateActiveCaptionIndex() {
            const playbackRate = this.currentCaptionButton?.playbackRate || Duration.PLAY_BACK_RATE_DEFAULT;
            const globalTime = this.calculateGlobalTime(this.sequenceElapsedTime * playbackRate);

            this.currentActiveCaptionIndex = this.currentCaptions.findIndex((caption, index) => {
                const isLastCaption = index === this.currentCaptions.length - 1;
                return globalTime >= caption.startTime && (globalTime <= caption.endTime || isLastCaption);
            });
        },

        scrollToActiveCaption() {
            this.$nextTick(() => {
                if (this.currentActiveCaptionIndex !== null && this.$refs.$scrollable) {
                    const container = this.$refs.$scrollable.$refs.$scrollableContainer;
                    const targetElement = container.querySelector(
                        `.caption-content:nth-child(${this.currentActiveCaptionIndex + 1})`
                    );

                    if (targetElement) {
                        const containerHeight = container.clientHeight;
                        const elementHeight = targetElement.clientHeight;
                        const centerOffset = containerHeight / 2 - elementHeight / 2;

                        this.$refs.$scrollable.scrollTo(
                            `.caption-content:nth-child(${this.currentActiveCaptionIndex + 1})`,
                            true,
                            centerOffset
                        );
                    }
                }
            });
        },

        seekToCaption(index) {
            if (!this.currentCaptions[index]) {
                return;
            }

            const EPSILON = 0.5;
            const playbackRate = this.currentCaptionButton?.playbackRate || Duration.PLAY_BACK_RATE_DEFAULT;
            const absoluteTime = this.currentCaptions[index].startTime / playbackRate + EPSILON;

            // Vérifier s'il n'y a pas de segments ou un segment unique couvrant toute la vidéo
            if (this.segments.length === 1 && this.segments[0].start === 0 && this.segments[0].end === -1) {
                this.$videoStudio.studio.$stage.seekSequenceTimeline(this.currentEditedItemId, absoluteTime);
                return;
            }

            // Trouver le segment approprié avec une marge d'erreur
            const targetSegment = this.segments.find((segment) => {
                const segmentEnd = segment.end === -1 ? this.currentCaptionButton?.duration : segment.end;
                return absoluteTime >= segment.start - EPSILON && absoluteTime <= segmentEnd + EPSILON;
            });

            // Si aucun segment n'est trouvé, vérifier si c'est le dernier segment
            if (!targetSegment) {
                const lastSegment = this.segments[this.segments.length - 1];
                const lastSegmentEnd = lastSegment.end === -1 ? this.currentCaptionButton?.duration : lastSegment.end;
                if (absoluteTime >= lastSegment.start - EPSILON && absoluteTime <= lastSegmentEnd + EPSILON) {
                    // Calculer le temps relatif pour le dernier segment
                    let relativeTime = 0;
                    for (let i = 0; i < this.segments.length - 1; i++) {
                        const segment = this.segments[i];
                        const segmentEnd = segment.end === -1 ? this.currentCaptionButton?.duration : segment.end;
                        relativeTime += segmentEnd - segment.start;
                    }
                    // Ajouter le temps relatif dans le dernier segment
                    relativeTime += absoluteTime - lastSegment.start;

                    if (relativeTime === 0) {
                        relativeTime = 0.01;
                    }

                    this.$videoStudio.studio.$stage.seekSequenceTimeline(this.currentEditedItemId, relativeTime);
                }
                return;
            }

            // Calculer le temps relatif en additionnant les durées des segments précédents
            let relativeTime = 0;
            for (const segment of this.segments) {
                if (segment === targetSegment) {
                    relativeTime += absoluteTime - segment.start;
                    break;
                }
                const segmentEnd = segment.end === -1 ? this.currentCaptionButton?.duration : segment.end;
                relativeTime += segmentEnd - segment.start;
            }

            this.$videoStudio.studio.$stage.seekSequenceTimeline(this.currentEditedItemId, relativeTime);
        },

        isCaptionInValidSegment(caption) {
            if (this.segments.length === 1 && this.segments[0].start === 0 && this.segments[0].end === -1) {
                return true;
            }

            const playbackRate = this.currentCaptionButton?.playbackRate || Duration.PLAY_BACK_RATE_DEFAULT;
            const startTime = caption.startTime * playbackRate;
            const endTime = caption.endTime * playbackRate;

            return this.segments.some((segment) => {
                const segmentEnd = segment.end === -1 ? this.currentCaptionButton?.duration : segment.end;
                return (
                    (startTime >= segment.start && startTime < segmentEnd) ||
                    (endTime > segment.start && endTime <= segmentEnd) ||
                    (startTime < segment.start && endTime > segmentEnd)
                );
            });
        },

        // Nouvelle méthode pour calculer le temps relatif
        calculateRelativeTime(captionTime) {
            // Si pas de segments ou segment unique couvrant toute la vidéo
            if (this.segments.length === 1 && this.segments[0].start === 0 && this.segments[0].end === -1) {
                return captionTime;
            }

            let relativeTime = 0;
            let accumulatedDuration = 0;

            for (const segment of this.segments) {
                const segmentEnd = segment.end === -1 ? this.currentCaptionButton?.duration : segment.end;

                // Si le temps est avant ce segment, on retourne le temps accumulé
                if (captionTime < segment.start) {
                    return accumulatedDuration;
                }

                // Si le temps est dans ce segment
                if (captionTime <= segmentEnd) {
                    return accumulatedDuration + (captionTime - segment.start);
                }

                // Ajouter la durée de ce segment
                accumulatedDuration += segmentEnd - segment.start;
            }

            // Si on arrive ici, le temps est après tous les segments
            return accumulatedDuration;
        },

        handleRelativeTimingChange(index, property, value) {
            if (!this.currentCaptionButton) return;

            const playbackRate = this.currentCaptionButton?.playbackRate || Duration.PLAY_BACK_RATE_DEFAULT;
            const relativeTimeInSeconds = this.formatTimeToSeconds(value);

            let absoluteTime = this.calculateGlobalTime(relativeTimeInSeconds) * playbackRate;

            let newCaptions = [...this.currentCaptions];
            let caption = { ...newCaptions[index] };

            const oldValue = caption[property];

            caption[property] = absoluteTime;

            if (property === 'startTime') {
                if (caption.startTime >= caption.endTime) {
                    caption[property] = oldValue;
                    return;
                }
            } else if (property === 'endTime') {
                if (index < newCaptions.length - 1) {
                    const nextCaption = newCaptions[index + 1];
                    if (caption.endTime > nextCaption.startTime) {
                        caption[property] = oldValue;
                        return;
                    }
                }

                if (caption.endTime <= caption.startTime) {
                    caption[property] = oldValue;
                    return;
                }
            }

            newCaptions[index] = caption;
            this.currentCaptionButton.change(newCaptions);
        }
    },

    watch: {
        currentActiveCaptionIndex() {
            this.scrollToActiveCaption();
        },
        sequenceElapsedTime() {
            this.updateActiveCaptionIndex();
        }
    }
};
</script>
