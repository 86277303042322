<template>
    <ui-dropdown
        :id="cardElementId + '-audio'"
        class="audio-dropdown centered"
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="bottom-end"
        menu-strategy="fixed"
        :icon-only="iconOnly"
        :toggle-style="toggleStyle"
    >
        <template #dropdown-toggle>
            <fa-icon class="icon" icon="fa-solid fa-music" />
            <span v-if="!iconOnly">{{ $t('Music') }}</span>
        </template>
        <div>
            <ui-range-input
                :id="cardElementId + '-main-audio-volume'"
                class="ui-content-row"
                v-model="mainVolumeModel"
                :horizontal="false"
                :label="$t('Music volume')"
                :disabled="readOnly"
            />
            <ui-number-input
                :id="cardElementId + '-main-audio-fade'"
                class="ui-content-row"
                v-model="mainVolumeFadeModel"
                :min="0"
                :label="$t('Music fade (in s.)')"
                :disabled="readOnly"
            />
        </div>
    </ui-dropdown>
</template>
<script>
import { useStore } from 'vuex';
import { defineComponent, computed } from 'vue';
import { UiIcon } from '../../../../../../components';
import UiRangeInput from '../../../../../../components/UiRangeInput.vue';
import UiNumberInput from '../../../../../../components/UiNumberInput.vue';
import UiDropdown from '../../../../../../components/UiDropdown.vue';
import { useHistory } from '../../../../../composables/useHistory';
import { useSequence } from '../../../../../composables/useSequence';
import { Sound, conversions } from 'cte-video-studio';

export default defineComponent({
    components: { UiIcon, UiRangeInput, UiNumberInput, UiDropdown },
    props: { seqId: String, cardElementId: String, readOnly: Boolean, iconOnly: Boolean },
    setup(props) {
        const store = useStore();
        const { saveHistoryStep } = useHistory();
        const { sequenceState, sequenceStoreModulePath } = useSequence(props.seqId);

        const mainVolume = computed({
            get: () => {
                return Math.round(
                    100 * conversions.optionValue(sequenceState.value.audio.volume, [], Sound.VOLUME_DEFAULT)
                );
            },
            set: (value) => {
                saveHistoryStep(() => {
                    store.commit(sequenceStoreModulePath.value + '/setMainVolume', {
                        value: (value / 100).toFixed(2),
                        custom: null
                    });
                });
            }
        });

        const mainVolumeFade = computed({
            get: () => {
                return store.getters[sequenceStoreModulePath.value + '/mainVolumeFade'];
            },
            set(value) {
                if (value != mainVolumeFade.value) {
                    saveHistoryStep(() => {
                        store.commit(sequenceStoreModulePath.value + '/setMainVolumeFade', { value });
                    });
                }
            }
        });

        const toggleStyle = computed(() => {
            if(mainVolume.value !== 100 || mainVolumeFade.value !== Sound.FADE_DEFAULT) {
                return { color: 'var(--edit-mode-color)' };
            } else return {};
        });

        return {
            mainVolumeModel: mainVolume,
            mainVolumeFadeModel: mainVolumeFade,
            toggleStyle
        };
    }
});
</script>

<style></style>
