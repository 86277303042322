<template>
    <button
        :id="id"
        class="ui-caption-button"
        :class="buttonClasses"
        :style="toggleStyle"
        :disabled="disabled"
        @click="showEditor"
        v-tooltip="tooltip"
    >
        <span v-if="!iconOnly" class="ui-caption-button-inner">
            {{ label || $t('Captions') }}
        </span>
        <fa-icon class="icon ui-caption-button-icon" icon="fa-regular fa-comment-dots" />
        <svg-icon icon="circle-loader" class="loader" />
    </button>
</template>

<script>
import { mapGetters } from 'vuex';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import UiIcon from '../../../../../../../components/UiIcon.vue';
import { UsesTooltip } from '../../../../../../../mixins';

export const UI_CAPTION_BUTTON_SHOW = 'ui-caption-button-show';
export const UI_CAPTION_BUTTON_HIDE = 'ui-caption-button-hide';
export const UI_CAPTION_BUTTON_CHANGE = 'update:modelValue';
export const UI_CAPTION_BUTTON_CAPTIONING_CHANGE = 'ui-caption-button-captioning-change';

export default {
    mixins: [UsesTooltip],

    name: 'UiCaptionButton',

    emits: [UI_CAPTION_BUTTON_SHOW, UI_CAPTION_BUTTON_CHANGE, UI_CAPTION_BUTTON_CAPTIONING_CHANGE],

    components: {
        UiIcon
    },

    props: {
        id: {
            type: String
        },
        iconOnly: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: Array,
            default: () => []
        },
        captioning: {
            type: Boolean,
            default: false
        },
        status: {
            default: null
        },
        modifier: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        toggleStyle: {
            type: Object,
            default: () => ({})
        },
        timerange: {
            type: Object,
            default: () => ({})
        },
        segments: {
            type: Array,
            default: () => []
        },
        playbackRate: {
            type: Number,
            default: 1
        },
        tooltip: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            currentValue: _cloneDeep(this.modelValue),
            currentCaptioning: this.captioning
        };
    },

    computed: {
        ...mapGetters({
            editorIsShown: 'ui/captionEditorIsShown'
        }),

        buttonClasses() {
            return {
                show: this.editorIsShown,
                loading: this.currentCaptioning,
                'ui-caption-button-icon-only': this.iconOnly
            };
        }
    },

    watch: {
        modelValue: {
            handler(newValue) {
                this.currentValue = _cloneDeep(newValue);
            },
            deep: true
        },

        captioning(newValue) {
            this.currentCaptioning = newValue;
        }
    },

    methods: {
        showEditor() {
            this.$emit(UI_CAPTION_BUTTON_SHOW, this);
        },

        hideEditor() {
            this.$el.dispatchEvent(new Event(UI_CAPTION_BUTTON_HIDE));
        },

        change(captions) {
            this.currentValue = captions;

            if (!_isEqual(this.currentValue, this.modelValue)) {
                this.emitChangeEvent();
                this.changeCaptioning(false);
            }
        },

        changeCaptioning(captioning, forStatus = false) {
            this.currentCaptioning = captioning;

            if (this.currentCaptioning != this.captioning || forStatus) {
                this.emitCaptioningChangeEvent();
            }
        },

        cancel() {
            this.hideEditor();
        },

        emitChangeEvent() {
            this.$emit(UI_CAPTION_BUTTON_CHANGE, this.currentValue);
        },

        emitCaptioningChangeEvent() {
            this.$emit(UI_CAPTION_BUTTON_CAPTIONING_CHANGE, this.currentCaptioning);
        }
    },

    mounted() {
        //
    }
};
</script>
