import RoleDTO from '@/js/dto/RoleDTO.js';
import ClientDTO from '@/js/dto/ClientDTO.js';

export default class UserDTO {
    constructor(data) {
        this.id = data.id;
        this.uuid = data.uuid;
        this.email = data.email;
        this.firstName = data.first_name;
        this.lastName = data.last_name;
        this.mobile = data.mobile;
        this.roleId = data.role_id;
        this.clientId = data.client_id;
        this.idpEnabled = data.idp_enabled;
        this.connectedAt = data.connected_at;
        this.rememberToken = data.remember_token;
        this.createdAt = data.created_at;
        this.updatedAt = data.updated_at;
        this.enableNotifications = !!data.enable_notifications;
        this.advancedDisplayMode = data.advanced_display_mode;
        this.preferences = data.preferences;
        this.verifySince = data.verify_since;
        this.language = data.language;
        this.emailVerified = data.email_verified;
        this.welcomeModalAccepted = data.welcome_modal_accepted;
        this.eLearningAccess = data.e_learning_access;
        this.lockResizing = data.lock_resizing;
        this.nbVideos = 0;
        if (data.role) this.role = new RoleDTO(data.role);
        if (data.client) this.client = new ClientDTO(data.client);
        this.followedProjectIds = data.followed_folder_ids || [];
    }
}
