import FetchTemplatesApiService from '@/js/infrastructure/api/templates/FetchTemplatesApiService.js';
import TemplateDTO from '@/js/dto/TemplateDTO.js';

export default class FetchTemplatesDataService {
    constructor(store) {
        this.fetchTemplatesApiService = new FetchTemplatesApiService(store);
    }

    async fetchTemplates(brandId, options, signal) {
        try {
            let response = await this.fetchTemplatesApiService.fetchTemplates(brandId, options, signal);
            return {
                templates: response.data.data.map((templateData) => new TemplateDTO(templateData)),
                page: response.data.meta.current_page,
                perPage: response.data.meta.per_page,
                lastPage: response.data.meta.last_page
            };
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Fetch aborted in FetchTemplatesDataService');
                throw error; // Re-throw to be handled by the caller
            }
            console.error('Error in FetchTemplatesDataService:', error);
            throw error;
        }
    }
}
