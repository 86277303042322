<template>
    <div class="ui-view-header">
        <h1 class="view-title">
            <span v-if="!editableTitle">{{ title }}</span>
            <template v-else>
                <div v-if="!editingTitle" class="ui-view-header-editable-title" @click="editingTitle = true">
                    <span>{{ truncateString(editedTitle) }}</span>
                    <fa-icon class="icon" icon="fa-regular fa-pen" />
                </div>
                <ui-text-input
                    v-else
                    id="modal-header"
                    type="text"
                    :placeholder="$t('Folder name')"
                    :model-value="editedTitle"
                    :show-label="false"
                    :no-style="true"
                    :autofocus="true"
                    @blur="saveTitle"
                />
            </template>
        </h1>

        <div class="ui-view-header-tools">
            <slot name="header-tools-left"></slot>
            <ui-text-input
                v-if="enableSearch"
                id="view-header-search-filter"
                class="view-header-search-filter"
                type="search"
                :placeholder="$t('Search')"
                :show-label="false"
                icon="search-icon"
                v-model="searchFilter"
            />
            <!--            <ui-dropdown
                v-if="enableSort"
                id="view-header-sorting-list"
                class="view-header-action"
                toggle-class="ui-action-button"
                :icon="true"
                :caret="false"
                :select="true"
                menu-placement="bottom-end"
                v-model="sorting"
            >
                <template #dropdown-toggle>
                    <svg-icon icon="sorting-icon" />
                </template>
                <ui-dropdown-item key="updatedAt" value="updatedAt" :label="$t('Sort by update date')" />
                <ui-dropdown-item key="createdAt" value="createdAt" :label="$t('Sort by creation date')" />
                <ui-dropdown-item key="name" value="name" :label="$t('Sort by name')" />
                &lt;!&ndash;<ui-dropdown-item key="owner" value="owner" :label="$t('Sort by owner')" />&ndash;&gt;
            </ui-dropdown>-->
            <slot name="header-tools"></slot>
        </div>
    </div>
</template>

<script setup>
import UiTextInput from '@/js/components/UiTextInput.vue';
import { truncateString } from '@/js/utils.js';
import { ref, watch } from 'vue';

const emit = defineEmits(['title-updated']);
const props = defineProps({
    title: {
        type: String,
        required: true
    },
    editableTitle: {
        type: Boolean,
        default: false
    },
    enableSearch: {
        type: Boolean,
        default: false
    },
    enableSort: {
        type: Boolean,
        default: false
    }
});

// handle folder change through sidebar
watch(
    () => props.title,
    (newValue) => (editedTitle.value = newValue)
);

const editedTitle = ref(props.title);
const editingTitle = ref(false);
const saveTitle = (e) => {
    editingTitle.value = false;
    if (e.target.value.trim() !== editedTitle.value) {
        editedTitle.value = e.target.value.trim();
        emit('title-updated', editedTitle.value);
    }
};

const searchFilter = defineModel();
</script>
