<template>
    <ui-modal
        v-bind="{ ...$props, ...$attrs }"
        ref="$modal"
        class="ui-confirm-modal ui-modal-sm"
        :show-title="false"
        :show-close="false"
    />
</template>

<script>
import { Modal } from '../../mixins';

export default {
    mixins: [Modal],

    components: {
        //
    },

    props: {
        //
    },

    data() {
        return {
            //
        };
    },

    computed: {
        //
    },

    watch: {
        //
    },

    methods: {
        //
    },

    mounted() {
        //
    }
};
</script>
