import { executeRequest } from '@/js/infrastructure/api/apiClient.js';

const EDITION_URL = import.meta.env.VITE_EDITION_API_URL;

export default class FetchVideosApiService {
    constructor(store) {
        this.store = store;
    }

    handle(folderId, options = {}) {
        const { page = 1, search = '', count = 10 } = options;

        const queryParams = new URLSearchParams({
            page: page.toString(),
            count: count.toString()
        });

        if (search) queryParams.append('search', search);

        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(
                    `${EDITION_URL}/api/folders/${folderId}/videos?count=${count}&page=${page}&search=${search}`
                );
            },
            onSuccess: async (response) => response.data,
            actionType: 'FetchVideosApiService'
        });
    }
}
