<template>
    <img :src="currentSrc" @error="handleImageError" alt="" v-bind="$attrs" />
</template>

<script setup>
import { ref, watch } from 'vue';
import defaultFallbackUrl from '@/assets/images/preview-square.png';

const props = defineProps({
    src: {
        type: String,
        required: true
    },
    fallbackSrc: {
        type: String,
        default: defaultFallbackUrl
    }
});

const currentSrc = ref(props.src);
const handleImageError = () => (currentSrc.value = props.fallbackSrc);

watch(
    () => props.src,
    () => {
        currentSrc.value = props.src;
    }
);
</script>
