<!--
  -- Component name: Circles
  -- Type: Transition
  -- Uses: 
  -- Tags: color
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <defs>
            <clipPath :id="svgMaskID" clipPathUnits="objectBoundingBox" :style="clipPathTransform">
                <path
                    class="circle-1"
                    d="M632.5 350.5c0-4.4 3.6-8 8-8s8 3.6 8 8-3.6 8-8 8-8-3.6-8-8zm8 6c3.3 0 6-2.7 6-6s-2.7-6-6-6-6 2.7-6 6 2.7 6 6 6z"
                />
                <path
                    class="circle-2"
                    d="M632.5 350.5c0-4.4 3.6-8 8-8s8 3.6 8 8-3.6 8-8 8-8-3.6-8-8zm8 6c3.3 0 6-2.7 6-6s-2.7-6-6-6-6 2.7-6 6 2.7 6 6 6z"
                />
                <circle class="circle-3" cx="50%" cy="50%" r="0" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            needsRefresh: true, // The transition needs Chrome and Safari to force a re-rendering during the animation
            keepActive: true,
            // isAbove: false, // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
            w: 1280,
            h: 720
        };
    },

    computed: {
        svgMaskID() {
            return this.containerID + '-circles-mask';
        },

        svgMaskRadius() {
            return Math.ceil(Math.sqrt(Math.pow(this.w, 2) + Math.pow(this.h, 2))) / 2;
        },

        svgMask() {
            return 'url(#' + this.svgMaskID + ')';
        },

        clipPathTransform() {
            return this.format && this.getClipPathTransform(this.w, this.h);
        }
    },

    watch: {
        svgMaskRadius: {
            handler() {
                this.updateTimeline();
            }
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline(),
                radius = this.svgMaskRadius + 25;
            t.set(this, { inClipPathProxy: this.svgMask }, 0.0001);
            t.fromTo(
                '#' + this.svgMaskID + ' .circle-1',
                { scale: 0 },
                { duration: 1, scale: 150, transformOrigin: '50% 50%' },
                0
            );
            t.fromTo(
                '#' + this.svgMaskID + ' .circle-2',
                { scale: 0 },
                { duration: 1, scale: 150, transformOrigin: '50% 50%' },
                0.4
            );
            t.fromTo(
                '#' + this.svgMaskID + ' .circle-3',
                { attr: { r: 0 } },
                { duration: 1, attr: { r: radius } },
                0.8
            );
            return t;
        }
    }
};
</script>
