/**
 * Video Studio
 * Visual constants
 */
import { defineAsyncComponent, markRaw } from 'vue';

const IMAGE_STATIC = 'ImageStatic';
const PREFIX_CLASS = 'Visual';

const IMAGE_CATEGORY = 'image';
const ICON_CATEGORY = 'icon';
const ANIMATED_CATEGORY = 'animated';
const VIDEO_CATEGORY = 'video';
const BLUR_CATEGORY = 'blur';
const COLLAGE_CATEGORY = 'collage';
const LOTTIE_CATEGORY = 'lottie';
const RECORDING_CATEGORY = 'recording';

const files = import.meta.glob('../components/visuals/*.vue');
const visuals = {};
Object.keys(files).forEach((key) => {
    key = key
        .split('/')
        .pop()
        .replace(/(\.\/|\.vue)/g, '');
    let parts = key.match(/([A-Z\d]+(?:[a-z]+|$))/g);
    visuals[parts.join('_').toUpperCase()] = key;
});

const visualList = Object.values(visuals).reduce((_visuals, componentName) => {
    _visuals[componentName.replace(PREFIX_CLASS, '')] = markRaw(
        defineAsyncComponent(() => import(`../components/visuals/${componentName}.vue`))
    );
    return _visuals;
}, {});

const BLUR_DEFAULT = 20;
const DEFAULT_CROP_POSITION = { width: 1, height: 1, left: 0, top: 0 };

// lists the visual animations where the inner asset should be cropped instead of the visual
const ANIMATIONS_CROPPING_ASSET = [
    'CircleBreathing',
    'Desktop',
    'DeviceCard1',
    'DeviceCard2',
    'Laptop',
    'LaptopSymbol',
    'Phone',
    'PhoneFrame',
    'PhoneHand1',
    'PhoneHand2',
    'PopUp',
    'PopUp2',
    'Shape',
    'ShapeBubble',
    'ShapeCircle',
    'ShapeCircleBrush',
    'ShapeHalfCircle',
    'ShapeQuarterCircle',
    'ShapeRectangleBrush',
    'ShapeRectangleBubble',
    'ShapeRoundedRectangle',
    'ShapeRoundedSquare',
    'ShapeSquareBubble',
    'Tablet'
];

const Visual = {
    PREFIX_CLASS,
    PREFIX_ID: 'visual_',

    DEFAULT: IMAGE_STATIC,
    CUSTOM: 'Custom',

    IMAGE_CATEGORY,
    ANIMATED_CATEGORY,
    VIDEO_CATEGORY,
    ICON_CATEGORY,
    BLUR_CATEGORY,
    COLLAGE_CATEGORY,
    LOTTIE_CATEGORY,
    RECORDING_CATEGORY,
    CATEGORIES: [
        IMAGE_CATEGORY,
        ICON_CATEGORY,
        LOTTIE_CATEGORY,
        ANIMATED_CATEGORY,
        VIDEO_CATEGORY,
        BLUR_CATEGORY,
        COLLAGE_CATEGORY,
        RECORDING_CATEGORY
    ],
    CATEGORY_DEFAULT: IMAGE_CATEGORY,

    BLUR_DEFAULT,
    BLUR_ANIMATION_NAME: 'Blurred',

    COLLAGE_DEFAULT: 'MosaicHorizontal',
    COLLAGE_MAX_MEDIA: 4,

    LOTTIE_DEFAULT: 'LottieFemmeFleche',
    LOTTIE_DEFAULT_DURATION: 5,

    DEFAULT_CROP_POSITION,
    ANIMATIONS_CROPPING_ASSET,

    visualList,
    ...visuals
};

export default Visual;
