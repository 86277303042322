<template>
    <ui-modal
        v-bind="{ ...$props, ...$attrs }"
        ref="$modal"
        class="auto-activation-modal ui-modal-lg"
        :title="$t('Creative Flow')"
        :show-footer="false"
    >
        <p>
            {{
                $t(
                    'In order to free your creativity and allow you to express your identity in video, we frequently update the platform and its functionalities.'
                )
            }}
        </p>
        <p
            v-html="
                sprintf(
                    $t('You will enjoy %1$snew animations%2$s of text, visuals, panels, and transitions!'),
                    '<b>',
                    '</b>'
                )
            "
        ></p>
        <p>
            {{
                $t(
                    'By automatically enabling new elements, these will be enabled by default as soon as they are released. Otherwise, you will have to manually enable the new creative elements of your choice.'
                )
            }}
        </p>
        <p><b v-html="sprintf($t('Any question? %s'), supportLink)"></b></p>
        <img
            class="ui-modal-image"
            :src="assetImageUrl('black_coffee.jpg')"
            :srcset="assetImageUrl('black_coffee.jpg') + ', ' + assetImageUrl('black_coffee@2x.jpg') + ' 2x'"
            :alt="$t('Modal image')"
        />
    </ui-modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Modal } from '../../../../mixins';
import { printf as sprintf } from 'fast-printf';

export default {
    mixins: [Modal],

    components: {
        //
    },

    props: {
        //
    },

    data() {
        return {
            //
        };
    },

    computed: {
        ...mapState({
            supportEmail: (state) => state.ui.supportEmail
        }),

        ...mapGetters({
            assetImageUrl: 'ui/assetImageUrl'
        }),

        supportLink() {
            return '<a href="mailto:' + this.supportEmail + '">' + this.supportEmail + '</a>';
        }
    },

    watch: {
        //
    },

    methods: {
        sprintf
    },

    mounted() {
        //
    }
};
</script>
