// src/js/application/services/video/GenerateGifService.js
import ExportGifApiService from '@/js/infrastructure/api/video/ExportGifApiService.js';

export default class GifService {
    constructor(store) {
        this.store = store;
        this.exportGifApiService = new ExportGifApiService(this.store);
    }

    async generateGIF({
        video,
        startTime,
        endTime,
        gifWidthSelect,
        gifWidth,
        gifFramerateSelect,
        gifFramerate,
        gifLoop
    }) {
        const finalWidth = gifWidthSelect === -1 ? gifWidth : gifWidthSelect;
        const finalFramerate = gifFramerateSelect === -1 ? gifFramerate : gifFramerateSelect;

        const params = {
            name: video.name,
            start: startTime,
            duration: endTime - startTime,
            framerate: finalFramerate,
            width: finalWidth,
            loop: gifLoop,
            item_id: video.id,
            url: video.latestProducedUrl.replace(/\r?\n|\r/g, '')
        };

        try {
            const response = await this.exportGifApiService.createGif(params);
            return response.data;
        } catch (error) {
            console.error("Erreur lors de l'importation du module:", error);
            throw error;
        }
    }
}
