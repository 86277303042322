<template>
    <ui-dropdown
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="bottom"
        menu-strategy="fixed"
        :disabled="disableDropDown"
        :toggle-style="toggleStyle"
        :icon-only="iconOnly"
    >
        <template #dropdown-toggle>
            <fa-icon class="icon" icon="fa-regular fa-gauge-high" />
        </template>
        <ui-number-input
            class="ui-content-row"
            v-model="playbackRate"
            :default-value="playbackRateDefault"
            :min="0.25"
            :max="4"
            :step="0.25"
            :label="$t('Speed')"
            :disabled="readOnly"
        />
    </ui-dropdown>
</template>

<script>
import { defineComponent, computed, ref } from 'vue';
import UiIcon from '../../../../../../components/UiIcon.vue';
import UiNumberInput from '../../../../../../components/UiNumberInput.vue';
import UiDropdown from '../../../../../../components/UiDropdown.vue';
import { Duration } from 'cte-video-studio';
import { useVoiceOver } from '../../../../../composables/useVoiceOver';

export default defineComponent({
    name: 'UiVoiceOverSpeedSelector',
    components: {
        UiIcon,
        UiNumberInput,
        UiDropdown
    },
    props: {
        useInSequence: Boolean,
        seqId: String,
        readOnly: Boolean,
        iconOnly: Boolean
    },
    setup(props) {
        const useInSequence = ref(props.useInSequence);
        const seqId = ref(props.seqId);

        const { playbackRate, voiceOverMediaState } = useVoiceOver(useInSequence, seqId);

        const disableDropDown = computed(() => {
            return props.readOnly || !voiceOverMediaState?.value?.src;
        });

        const playbackRateDefault = Duration.PLAY_BACK_RATE_DEFAULT;

        const toggleStyle = computed(() => {
            return playbackRate.value !== playbackRateDefault && voiceOverMediaState?.value?.src
                ? { color: 'var(--edit-mode-color)' }
                : {};
        });

        return {
            playbackRate,
            playbackRateDefault,
            toggleStyle,
            disableDropDown
        };
    }
});
</script>
