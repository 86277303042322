/**
 * Edit video Sequence options Vuex store extension
 */

import _merge from 'lodash/merge';
import _pick from 'lodash/pick';
import { Align, conversions, Duration, types } from 'cte-video-studio';
import card from '../card';

const mergeTemplateData = (state, data) => {
    // We keep the logo display setting
    let mergedKeys = ['logo.enabled'];

    // Then we keep the template duration, only if the sequence has no set duration
    if (state.duration == Duration.NONE) mergedKeys.push('duration');

    // Finally we return the merge of the current state and the relevant template keys
    return _merge({}, state, _pick(data, mergedKeys));
};

const state = () => ({
    duration: Duration.NONE,
    logo: {
        enabled: true
    },
    footer: {
        text: '',
        align: Align.TEXT_LEFT,
        start: {
            value: Duration.FOOTER_START_DEFAULT,
            custom: null
        },
        ...card.state()
    },
    hidden: false
});

// Getters
const getters = {
    footerStart: (state) => conversions.optionValue(state.footer.start, [], Duration.FOOTER_START_DEFAULT)
};

// Mutations
const mutations = {
    setDuration(state, duration) {
        state.duration = types.isPositive(duration) ? duration : Duration.NONE;
    },

    enableLogo(state, enabled) {
        state.logo.enabled = enabled;
    },

    setFooter(state, text) {
        state.footer.text = text;
    },

    setFooterAlign(state, align) {
        state.footer.align =
            [Align.TEXT_LEFT, Align.TEXT_CENTER, Align.TEXT_RIGHT].indexOf(align) != -1 ? align : Align.TEXT_LEFT;
    },

    setFooterStart(state, start) {
        if (types.isZeroPositive(start.value)) {
            state.footer.start.value = parseFloat(start.value);
            state.footer.start.custom = Duration.NONE;
        } else if (types.isZeroPositive(start.custom)) {
            state.footer.start.value = Duration.CUSTOM;
            state.footer.start.custom = parseFloat(start.custom);
        } else {
            state.footer.start.value = Duration.FOOTER_START_DEFAULT;
            state.footer.start.custom = Duration.NONE;
        }
    },

    hide(state, hidden) {
        state.hidden = hidden;
    },

    showFooterCard(state, show) {
        card.mutations.showCard(state.footer, show);
    }
};

// Actions
const actions = {
    initOptions({ state, commit }, data) {
        commit('setDuration', data.duration);
        commit('enableLogo', data.logo.enabled);
        commit('setFooter', data.footer.text);
        commit('setFooterAlign', data.footer.align);
        commit('setFooterStart', data.footer.start);
        commit('hide', data.hidden);
        if (state.footer.text !== '') commit('showFooterCard', true);
    },

    toggleHide({ commit, state }) {
        commit('hide', !state.hidden);
    },

    applyTemplateOptions({ state, dispatch }, data) {
        dispatch('initOptions', mergeTemplateData(state, data));
    },

    showFooterCard({ commit }, show) {
        if (show) commit('showFooterCard', show);
        else commit('resetFooterState');
    },

    pasteFooterCard({ commit, state }, data) {
        commit('setFooter', data.text);
        commit('setFooterAlign', data.align);
        commit('setFooterStart', data.start);
        if (state.footer.text !== '') commit('showFooterCard', true);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
