import { executeRequest } from '@/js/infrastructure/api/apiClient.js';

const LEGACY_URL = import.meta.env.VITE_LEGACY_APP_URL;
const EDITION_URL = import.meta.env.VITE_EDITION_API_URL;

export default class ProfileApiService {
    constructor(store) {
        this.store = store;
    }

    fetchProfileData() {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${LEGACY_URL}/front/profile/me`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'fetchProfileData'
        });
    }

    fetchProfileLatestVideosData() {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${EDITION_URL}/api/user/latest-videos`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'fetchProfileLatestVideosData'
        });
    }

    postProfileGeneralData(params) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.post(`${LEGACY_URL}/front/profile/general`, {
                    first_name: params.firstName,
                    last_name: params.lastName,
                    language: params.language,
                    enable_notifications: params.enableNotifications
                });
            },
            onSuccess: async (response) => response.data,
            actionType: 'postProfileGeneralData'
        });
    }

    postProfileSecurityData(params) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.post(`${LEGACY_URL}/front/profile/security`, params);
            },
            onSuccess: async (response) => response.data,
            actionType: 'postProfileSecurityData'
        });
    }

    postWelcomeModalAccepted() {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.post(`${LEGACY_URL}/front/profile/accept-welcome-modal`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'postWelcomeModalAccepted'
        });
    }
}
