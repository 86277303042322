import { executeRequest } from '@/js/infrastructure/api/apiClient.js';

const API_URL = import.meta.env.VITE_EDITION_API_URL;

export default class BroadcastingApiService {
    constructor(store) {
        this.store = store;
    }

    postBroadcastingAuth(params) {
        return executeRequest(
            this.store,
            {
                request: async (client) => {
                    return await client.post(`${API_URL}/broadcasting/auth`, params);
                },
                onSuccess: async (response) => response.data,
                actionType: 'BroadcastingApiService.postBroadcastingAuth'
            },
            true
        );
    }
}
