import { ref } from 'vue';

export function useHoverBar(wrapper, canvasWidth, endTime) {
    const showHoverBar = ref(false);
    const hoverBarPosition = ref(0);
    const hoverTime = ref(0);
    const hoverDisplayX = ref(0);
    const hoverDisplayY = ref(0);

    const handleMouseMove = (event) => {
        if (!wrapper.value) return;

        const { left, top } = wrapper.value.getBoundingClientRect();
        const computedStyle = window.getComputedStyle(wrapper.value);
        const paddingLeft = parseInt(computedStyle.paddingLeft, 10);

        let relativePosition = event.clientX - left - paddingLeft;
        relativePosition = Math.max(0, Math.min(relativePosition, canvasWidth.value));

        hoverBarPosition.value = relativePosition;
        hoverTime.value = (hoverBarPosition.value / canvasWidth.value) * endTime.value;
        hoverDisplayX.value = event.clientX;
        hoverDisplayY.value = top + 2;
        showHoverBar.value = true;
    };

    const handleMouseLeave = () => {
        showHoverBar.value = false;
    };

    return {
        showHoverBar,
        hoverBarPosition,
        hoverTime,
        hoverDisplayX,
        hoverDisplayY,
        handleMouseMove,
        handleMouseLeave
    };
}
