import BroadcastingApiService from '@/js/infrastructure/api/broadcasting/BroadcastingApiService.js';

export default class BroadcastingDataService {
    constructor(store) {
        this.broadcastingApiService = new BroadcastingApiService(store);
    }

    async postBroadcastingAuth(params) {
        return (await this.broadcastingApiService.postBroadcastingAuth(params)).data;
    }
}
