<template>
    <div class="nav-item-create-video nav-dropdown-item" :class="{ opened: opened }" @click="open">
        <div class="nav-item-create-video-overlay" />
        <div class="nav-icon-container">
            <fa-icon class="icon" icon="fa-solid fa-plus" />
        </div>
        <div class="nav-item-route-name">
            <div class="nav-item-name-label">{{ $t('Create a video') }}</div>
        </div>
        <fa-icon icon="fa-solid fa-chevron-right" class="icon nav-dropdown-item-arrow-icon" />
    </div>
    <ui-nav-sub-list parent-name="create">
        <ui-nav-item :link="false" :label="$t('From scratch')" icon="fa-square-dashed" @click="createVideo" />
        <ui-nav-item
            :route="{ name: 'Home', hash: '#templates-list' }"
            :label="$t('From a template')"
            icon="video-from-template"
            icon-type="svg"
        />
        <ui-nav-item
            v-bind:route="AIenabled ? { name: 'Companion' } : undefined"
            :label="$t('From a prompt')"
            :icon="AIenabled ? 'video-from-ai' : 'fa-lock'"
            v-bind:icon-type="AIenabled ? 'svg' : undefined"
            :class="[{ disabled: !AIenabled }]"
        />
    </ui-nav-sub-list>
</template>

<script setup>
import UiNavItem from '@/js/components/UiNavItem.vue';
import { computed, inject } from 'vue';
import { useModal } from '@/js/composables/useModal.js';
import UiNavSubList from '@/js/components/UiNavSubList.vue';
import { useStore } from 'vuex';

const props = defineProps({
    label: {
        type: String,
        default: ''
    },
    rightLabelData: {
        type: String,
        default: ''
    },
    icon: {
        type: String,
        default: ''
    },
    iconType: {
        type: String,
        default: 'fa'
    }
});

const store = useStore();

const AIenabled = computed(() => {
    return store.state.auth.user.client.videoCompanionEnabled;
});

const navListName = 'create';

const { openModal } = useModal();

const { openedNavItem, openNavItem } = inject('openedNavItem');

const opened = computed(() => {
    return openedNavItem.value === navListName;
});

const open = () => {
    if (navListName === openedNavItem.value) {
        openNavItem(null);
        return;
    }
    openNavItem(navListName);
};

const createVideo = () => {
    openModal('CreateVideoModal');
};
</script>
