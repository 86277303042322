import { BREAKPOINT_LG } from '../../../constants';

class StudioEditPreviewManager {
    constructor(store) {
        this.store = store;
    }

    resizePreview() {
        const previewContainer = document.querySelector('.preview-studio-wrapper');

        const isNarrowScreen = window.innerWidth < BREAKPOINT_LG;
        const { format } = this.store.state.display;
        const formatRatio = format.width / format.height;
        this.adjustContainerHeight(previewContainer, isNarrowScreen, formatRatio);
        this.adjustStudioScale(previewContainer, formatRatio);
    }

    adjustContainerHeight(container, isNarrowScreen, formatRatio) {
        if (isNarrowScreen) {
            const playerWidth = this.store.getters['display/formatRatioString'] !== '16x9' ? 0.5 : 1;
            container.style.height = `${(window.innerWidth * playerWidth) / formatRatio}px`;
        } else {
            container.style.removeProperty('height');
        }
    }

    adjustStudioScale(container, stageRatio) {
        const containerWidth = container.offsetWidth;
        const containerHeight = container.offsetHeight;
        const containerRatio = containerWidth / containerHeight;
        const studioScale =
            containerRatio <= stageRatio
                ? containerWidth / this.store.state.display.format.width
                : containerHeight / this.store.state.display.format.height;

        window.$studio.proxy.setContainerScale(studioScale, true);
        window.$studio.proxy.studio.$stage.scale = studioScale;
    }
}

export default StudioEditPreviewManager;
