<template>
    <section class="ui-body-panel" role="application" aria-describedby="ui-components-title">
        <h2 id="ui-components-title" class="visually-hidden">{{ $t('brands.panels.components') }}</h2>

        <div class="ui-card-container">
            <card-components
                id="components-logo-animations"
                icon="logo-icon"
                iconType="svg"
                :title="$t('Logo animations')"
                :accordion-id="this.accordionId"
                library="logoAnimations"
                preview-path="logos/"
            />
            <card-components
                id="components-overlays"
                icon="overlay-icon"
                iconType="svg"
                :title="$t('Overlays')"
                :accordion-id="this.accordionId"
                library="overlays"
                preview-path="overlays/"
                :group-switch="true"
            />
            <card-components
                id="components-messages"
                icon="message-icon"
                iconType="svg"
                :title="$t('Message animations')"
                :accordion-id="this.accordionId"
                library="messages"
                preview-path="messages/"
            />
            <card-components
                id="components-image-visuals"
                icon="fa-regular fa-image"
                :title="$t('Image visual animations')"
                :accordion-id="this.accordionId"
                library="visuals"
                category="image"
                preview-path="visuals/image/"
            />
            <card-components
                id="components-video-visuals"
                icon="fa-regular fa-circle-play"
                :title="$t('Video visual animations')"
                :accordion-id="this.accordionId"
                library="visuals"
                category="video"
                preview-path="visuals/video/"
            />
            <card-components
                id="components-animated-visuals"
                icon="fa-regular fa-square-bolt"
                :title="$t('Animated stickers')"
                :accordion-id="this.accordionId"
                library="visuals"
                category="animated"
                preview-path="visuals/animated/"
                :group-switch="true"
            />
            <card-components
                id="components-animated-cartoon"
                icon="fa-regular fa-house-tree"
                :title="$t('Cartoon visuals')"
                :accordion-id="this.accordionId"
                library="visuals"
                category="lottie"
                preview-path="visuals/lottie/"
                :group-switch="true"
            />
            <card-components
                id="components-panels"
                icon="side-panel-icon"
                icon-type="svg"
                :title="$t('Panels')"
                :accordion-id="this.accordionId"
                library="panels"
                preview-path="panels/"
            />
            <card-components
                id="components-transitions"
                icon="transition-icon"
                icon-type="svg"
                :title="$t('Transitions')"
                :accordion-id="this.accordionId"
                library="transitions"
                preview-path="transitions/"
            />
            <card-components
                id="components-musics-universal"
                icon="audio-icon"
                icon-type="svg"
                :title="$t('Universal Production Playlists')"
                :accordion-id="this.accordionId"
                library="musics"
                category="universal"
                preview-path="musics/universal/"
                :group-switch="true"
            />
            <card-components
                id="components-musics-2emotion"
                icon="audio-icon"
                icon-type="svg"
                :title="$t('2Emotion Playlists')"
                :accordion-id="this.accordionId"
                library="musics"
                category="2emotion"
                preview-path="musics/2emotion/"
                :group-switch="true"
            />
            <card-components
                id="components-voices"
                icon="voice-ai-icon"
                icon-type="svg"
                :title="$t('AI Voices')"
                :accordion-id="this.accordionId"
                library="voices"
                category="tts"
                preview-path="voices/"
                :group-switch="true"
            />
        </div>
    </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { CardComponents } from './cards';
import { BaseAccordion } from '../../../../mixins';

export default {
    mixins: [BaseAccordion],

    components: {
        CardComponents
    },

    props: {
        //
    },

    data() {
        return {
            //
        };
    },

    computed: {
        ...mapState({
            //
        }),

        ...mapGetters({
            //
        })
    },

    watch: {
        //
    },

    methods: {
        //
    },

    mounted() {
        //
    }
};
</script>
