import CopyVideoApiService from '@/js/infrastructure/api/video/CopyVideoApiService.js';
import VideoDTO from '@/js/dto/VideoDTO.js';

export default class CopyVideoService {
    constructor(store) {
        this.copyVideoApiService = new CopyVideoApiService(store);
    }

    async handle(params) {
        let data = (await this.copyVideoApiService.handle(params)).data;
        return (data ?? []).map((video) => new VideoDTO(video));
    }
}
