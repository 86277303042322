/**
 * Video Studio
 * Utilities
 */

import fastdomLib from 'fastdom';
import fastdomPromised from 'fastdom/extensions/fastdom-promised';

export const fastdom = fastdomLib.extend(fastdomPromised);

import conversions from './conversions';
import types from './types';
import getLoadingProductionSequence from './getLoadingProductionSequence';

const randomID = (prefix) => (prefix || '') + Date.now() + ('00000' + Math.round(Math.random() * 1000000)).substr(-6);

export * from './hooks';
export * from './events';
export { conversions, types, getLoadingProductionSequence, randomID };
