<!--
  -- Component name: CW4
  -- Type: Transition
  -- Uses: color.start,color.second
  -- Tags: cushman-wakefield
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        v-if="landscapeFormat"
        :class="classes"
        :id="this.containerID"
        viewBox="0 0 1920 1080"
        preserveAspectRatio="xMinYMin slice"
    >
        <polygon
            class="polygon4"
            :fill="state.color.start"
            points="0 1080.12 531.58 0.12 3087.11 0.12 2555.76 1080.12 0.23 1080.12"
        />
        <polygon
            class="polygon3"
            :fill="state.color.second"
            fill-opacity="0.75"
            points="0 1080.12 531.34 0.12 3086.87 0.12 2555.53 1080.12 0 1080.12"
        />
        <polygon
            class="polygon2"
            :fill="state.color.second"
            fill-opacity="0.5"
            points="0 1080.12 531.34 0.12 3086.87 0.12 2555.53 1080.12 0 1080.12"
        />
        <polygon class="polygon1" fill="#fff" points="0.53 0 1920.53 1440.96 1920.53 4028.96 0.53 2588 0.53 0" />
    </svg>
    <svg
        class="studio-sequence-transition studio-container"
        v-else-if="squareFormat"
        :class="classes"
        :id="this.containerID"
        viewBox="0 0 1000 1000"
        preserveAspectRatio="xMinYMin slice"
    >
        <polygon
            class="polygon4"
            :fill="state.color.start"
            points="491.99 0.36 2073.84 0.36 1581.86 1000.37 0 1000.37 491.99 0.36"
        />
        <polygon
            class="polygon3"
            :fill="state.color.second"
            fill-opacity="0.75"
            points="492.03 0.36 2073.88 0.36 1581.9 1000.37 0.04 1000.37 492.03 0.36"
        />
        <polygon
            class="polygon2"
            :fill="state.color.second"
            fill-opacity="0.5"
            points="492.03 0.36 2073.88 0.36 1581.9 1000.37 0.04 1000.37 492.03 0.36"
        />
        <polygon class="polygon1" fill="#fff" points="0.38 0 1000.38 750.5 1000.38 2444.62 0.38 1694.12 0.38 0" />
    </svg>
    <svg
        class="studio-sequence-transition studio-container"
        v-else-if="portraitFormat"
        :class="classes"
        :id="this.containerID"
        viewBox="0 0 1080 1920"
        preserveAspectRatio="xMinYMin slice"
    >
        <polygon
            class="polygon4"
            :fill="state.color.start"
            points="944.91 0 3239.15 0 2294.54 1920 0.3 1920 944.91 0"
        />
        <polygon
            class="polygon3"
            :fill="state.color.second"
            fill-opacity="0.75"
            points="944.61 0 3238.85 0 2294.24 1920 0 1920 944.61 0"
        />
        <polygon
            class="polygon2"
            :fill="state.color.second"
            fill-opacity="0.5"
            points="944.61 0 3238.85 0 2294.24 1920 0 1920 944.61 0"
        />
        <polygon
            class="polygon1"
            fill="#fff"
            points="0.49 0.08 1080.49 810.62 1080.49 3638.15 0.49 2827.61 0.49 0.08"
        />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: true, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true, // The transition component is placed above the in and out elements
            // swapElements: true, // The in and out parent elements are swapped to have the out elements above the in elements
            inElementsAutoAppear: false // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {
        landscapeFormat() {
            return this.format.width > this.format.height;
        },

        portraitFormat() {
            return this.format.width < this.format.height;
        },

        squareFormat() {
            return this.format.width === this.format.height;
        }
    },

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            t.fromTo('#' + this.containerID + ' .polygon2', { xPercent: -100 }, { xPercent: 100, duration: 0.7 });
            t.fromTo('#' + this.containerID + ' .polygon3', { xPercent: -100 }, { xPercent: 100, duration: 0.7 }, 0.4);
            t.fromTo('#' + this.containerID + ' .polygon4', { xPercent: 100 }, { xPercent: -100, duration: 0.7 }, 0.3);
            t.fromTo('#' + this.containerID + ' .polygon1', { yPercent: 100 }, { yPercent: -100, duration: 0.7 }, 0.3);
            t.set([this.backgroundInElement, this.sequenceInElement], { autoAlpha: 1 }, 0.7);
            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 }, 0.7);
            return t;
        }
    }
};
</script>
