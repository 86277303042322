/**
 * Video Studio
 * Events
 */

// Event names
export const STUDIO_MOUNTED_EVENT = 'studio-mounted';

export const STAGE_TIMELINE_UPDATED_EVENT = 'stage-timeline-updated';
export const STAGE_LOADING_STARTED_EVENT = 'stage-loading-started';
export const STAGE_LOADING_ENDED_EVENT = 'stage-loading-ended';
