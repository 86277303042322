<template>
    <nav class="ui-sidebar">
        <ui-settings-item />
        <ui-sequence-list />
    </nav>
</template>

<script>
import { ProvidesIcons } from '../../../../mixins';
import UiSettingsItem from './UiSettingsItem.vue';
import UiSequenceList from './UiSequenceList.vue';

export default {
    mixins: [ProvidesIcons],

    components: {
        UiSettingsItem,
        UiSequenceList
    },

    props: {
        //
    }
};
</script>
