<template>
    <div class="ui-body">
        <ui-scrollable class="ui-body-inner" :margin-top="30" :margin-bottom="30">
            <div v-if="currentPromptNeedsLoading" class="ui-body-loader">
                <svg-icon icon="dots-loader" />
            </div>
            <div v-if="!currentPromptExists" class="ui-welcome">
                <slot></slot>
            </div>
            <ui-companion-session
                v-if="!currentPromptNeedsLoading && currentPromptExists"
                :prompt="currentPromptExtended"
                :folders="availableFolders"
                @[companionVideoUseEvent]="useVideo"
            />
        </ui-scrollable>
        <ui-companion-bar
            :current-prompt="currentPrompt"
            :current-prompt-exists="currentPromptExists"
            :brands="availableBrands"
            :formats="availableFormats"
            :focus="focusCompanionBar"
            :loading="hasRequestedVideoCreation"
            @[companionBarSubmitEvent]="requestVideosCreation"
        />
        <footer>
            <p>{{ $t('2Emotion.ai is creative and can make mistakes. Consider checking important information.') }}</p>
        </footer>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { ProvidesIcons } from '../../../../mixins';
import { UiIcon, UiScrollable } from '../../../../components';
import UiCompanionSession from './UiCompanionSession.vue';
import UiCompanionBar, { UI_COMPANION_BAR_SUBMIT } from './UiCompanionBar.vue';
import { UI_COMPANION_VIDEO_USE } from './UiCompanionVideoCreated.vue';
import CopyVideoService from '@/js/application/services/video/CopyVideoService.js';
import router from '@/router/index.js';

export default {
    mixins: [ProvidesIcons],

    components: {
        UiIcon,
        UiScrollable,
        UiCompanionSession,
        UiCompanionBar
    },

    props: {
        currentPrompt: {
            type: Object,
            default: null
        },
        focusCompanionBar: {
            type: Boolean,
            default: null
        }
    },

    data() {
        return {
            companionBarSubmitEvent: UI_COMPANION_BAR_SUBMIT,
            companionVideoUseEvent: UI_COMPANION_VIDEO_USE
        };
    },

    computed: {
        ...mapState('ui', ['availableBrands', 'availableFormats', 'availableFolders', 'hasRequestedVideoCreation']),

        ...mapGetters([
            'currentPromptExists',
            'currentPromptNeedsLoading',
            'currentPromptIsPending',
            'currentPromptIsProcessing',
            'currentPromptIsCreating',
            'currentPromptIsCompleted',
            'currentPromptIsIgnored',
            'currentPromptIsFailed',
            'currentPromptIsOngoing',
            'currentPromptIsEnded'
        ]),

        currentPromptExtended() {
            return {
                ...this.currentPrompt,
                isPending: this.currentPromptIsPending,
                isProcessing: this.currentPromptIsProcessing,
                isCreating: this.currentPromptIsCreating,
                isCompleted: this.currentPromptIsCompleted,
                isIgnored: this.currentPromptIsIgnored,
                isFailed: this.currentPromptIsFailed,
                isOngoing: this.currentPromptIsOngoing,
                isEnded: this.currentPromptIsEnded
            };
        }
    },

    methods: {
        async requestVideosCreation(data) {
            const newPrompt = await this.$store.dispatch('ui/requestVideosCreation', data);

            if (newPrompt) {
                this.$store.dispatch('setPrompt', newPrompt);
                this.$store.dispatch('showPrompt', { prompt: newPrompt });
            }
        },

        async useVideo(videoData) {
            new CopyVideoService(this.$store)
                .handle({
                    videoId: videoData.videoId,
                    folderIds: [videoData.folderId]
                })
                .then((response) => {
                    if (response.length) {
                        router.push({ name: 'EditVideo', params: { id: response[0].id } });
                    }
                });
        }
    },

    mounted() {
        if (this.currentPromptNeedsLoading) {
            this.$store.dispatch('loadPrompt', this.currentPrompt.resourceUrl);
        }
    }
};
</script>
