import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';

export const PLUGIN_NAME = 'studioMarkers';
export const PLUGIN_KEY = new PluginKey(PLUGIN_NAME);

// Custom nodes and marks
import { HighlightGroup } from './HighlightGroup';
import { HighlightMark } from './HighlightMark';
import { LineSeparator } from './LineSeparator';

export const customNodes = [HighlightGroup, HighlightMark, LineSeparator];

export const parseStudioMessage = (htmlContent, activeNodes = {}) => {
    let parsedContent = htmlContent;

    // Replace studio marks by tiptap nodes and marks
    parsedContent = customNodes.reduce((content, node) => {
        return node.parseStudioMessage(content, !!activeNodes[node.name]);
    }, parsedContent);

    // Remove remaining studio marks
    parsedContent = customNodes.reduce((content, node) => {
        return node.parseStudioMessage(content, null, true);
    }, parsedContent);

    return parsedContent;
};

export const toStudioMessage = (htmlContent, activeNodes = {}) => {
    let renderedContent = htmlContent;

    // Replace tiptap nodes and marks by studio marks
    renderedContent = customNodes.reduce((content, node) => {
        return node.toStudioMessage(content, !!activeNodes[node.name]);
    }, renderedContent);

    // Remove remaining tiptap nodes and marks
    renderedContent = customNodes.reduce((content, node) => {
        return node.toStudioMessage(content, null, true);
    }, renderedContent);

    return renderedContent;
};

export default Extension.create({
    name: PLUGIN_NAME,

    addOptions() {
        return {
            allEnabled: false
        };
    },

    addExtensions() {
        return customNodes.map((node) =>
            node.configure({ enabled: !!this.options[node.name] || this.options.allEnabled })
        );
    }
});
