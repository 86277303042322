import CGSAgreementDTO from '@/js/dto/CGSAgreementDTO.js';
import MinimalClientDTO from '@/js/dto/MinimalClientDTO.js';

export default class ClientDTO extends MinimalClientDTO {
    constructor(data) {
        super(data);
        this.id = data.id;
        this.shortname = data.shortname;
        this.logoSrc = data.logo_src;
        this.licenceStartsAt = data.licence_starts_at;
        this.licenceExpiresAt = data.licence_expires_at;
        this.testLicence = !!data.test_licence;
        this.tacitRenewal = !!data.tacit_renewal;
        this.tacitRenewalAchieved = data.tacit_renewal_achieved;
        this.emotionUsersLimit = Number.parseInt(data.emotion_users_limit) || 0;
        this.contentUsersLimit = Number.parseInt(data.content_users_limit) || 0;
        this.idpId = data.idp_id;
        this.mediaLimit = data.media_limit;
        this.mediaNotificationSent = !!data.media_notification_sent;
        this.ttsTotal = data.tts_total;
        this.ttsLimit = data.tts_limit;
        this.ttsNotificationSent = !!data.tts_notification_sent;
        this.sttTotal = data.stt_total;
        this.sttLimit = data.stt_limit;
        this.sttNotificationSent = !!data.stt_notification_sent;
        this.translationTotal = data.translation_total;
        this.translationLimit = data.translation_limit;
        this.translationNotificationSent = !!data.translation_notification_sent;
        this.impressionsTotal = data.impressions_total;
        this.impressionsLimit = data.impressions_limit;
        this.impressionsLimitDisplay = !!data.impressions_limit_display;
        this.impressionsNotificationSent = !!data.impressions_notification_sent;
        this.productionPriority = data.production_priority;
        this.mediaUsageEnabled = !!data.media_usage_enabled;
        this.mediaRightsEnabled = !!data.media_rights_enabled;
        this.validationAccessEnabled = !!data.validation_access_enabled;
        this.validationEnabled = !!data.validation_enabled;
        this.activityAccessEnabled = !!data.activity_access_enabled;
        this.brandingAccessEnabled = !!data.branding_access_enabled;
        this.verificationEnabled = !!data.verification_enabled;
        this.mediaFolderAccessEnabled = !!data.media_folder_access_enabled;
        this.customTemplatePreviewsEnabled = !!data.custom_template_previews_enabled;
        this.templateAutoProductionEnabled = !!data.template_auto_production_enabled;
        this.createdAt = data.created_at;
        this.updatedAt = data.updated_at;
        this.enableContinuousImprovement = !!data.enable_continuous_improvement;
        this.enableSupportTool = !!data.enable_support_tool;
        this.enableNotificationPanel = !!data.enable_notification_panel;
        this.enableExternalLinks = !!data.enable_external_links;
        this.enableQualityMonitoring = !!data.enable_quality_monitoring;
        this.enableTags = !!data.enable_tags;
        this.enableLockingTags = !!data.enable_locking_tags;
        this.lockedTags = !!data.locked_tags;
        this.mediaApiProviderOptions = data.media_api_provider_options;
        this.mediaStorageProvider = data.media_storage_provider;
        this.mediaVersion = data.media_version;
        this.v2 = !!data.v2;
        this.maxEditionMessageCards = data.max_edition_message_cards;
        this.maxEditionVisualCards = data.max_edition_visual_cards;
        this.disableEditionRightAlignment = !!data.disable_edition_right_alignment;
        this.enableScormExport = !!data.enable_scorm_export;
        this.enableRecording = !!data.enable_recording;
        this.enableAi = !!data.enable_ai;
        this.videoCompanionEnabled = !!data.video_companion_enabled;
        this.enableTts = !!data.enable_tts;
        this.enableGenImageAi = !!data.enable_gen_image_ai;
        this.aiImagesCredits = data.ai_images_credits;
        this.cgvAgreements = [];
        this.nbLicensesSupervisors = Number.parseInt(data.nb_licenses_supervisors) || 0;
        this.nbLicensesCreators = Number.parseInt(data.nb_licenses_creators) || 0;
        if (data.cgv_agreements) {
            this.cgvAgreements = data.cgv_agreements.map((agreement) => new CGSAgreementDTO(agreement));
        }
    }
}
