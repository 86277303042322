<template>
    <ui-caption-button
        v-if="hasVideoBackground || hasRecordingBackground"
        :id="cardElementId + '-captions'"
        :seqId="seqId"
        :iconOnly="iconOnly"
        v-model="backgroundVideoCaptions"
        :captioning="backgroundVideoCaptioning"
        :status="backgroundVideoCaptioningStatus"
        :modifier="backgroundVideoCaptionModifier"
        :label="$t('Captions')"
        :disabled="enableDropDown"
        :toggleStyle="toggleStyle"
        :segments="videoTimerangeSegments"
        :playback-rate="playbackRate"
        :tooltip="backgroundVideoCaptioning ? $t('Captioning in progress...') : $t('Caption')"
        @[captionButtonShowEvent]="handleCaptioning"
        @[captionButtonCaptioningChangeEvent]="backgroundVideoCaptioning = $event"
    />
</template>

<script>
import { useStore } from 'vuex';
import { defineComponent, computed } from 'vue';
import UiCaptionButton from '../../cards/tools/caption/UiCaptionButton.vue';

import { useHistory } from '../../../../../composables/useHistory';
import { useSequence } from '../../../../../composables/useSequence';
import { useVideo } from '../../../../../composables/useVideo';

import {
    UI_CAPTION_BUTTON_SHOW,
    UI_CAPTION_BUTTON_CAPTIONING_CHANGE
} from '../../../../../components/ui/body/cards/tools/caption/UiCaptionButton.vue';

export default defineComponent({
    name: 'UiBackgroundCaptionSelector',
    components: {
        UiCaptionButton
    },
    props: {
        seqId: String,
        readOnly: Boolean,
        iconOnly: Boolean,
        cardElementId: String
    },
    setup(props) {
        const store = useStore();
        const {
            backgroundVideo,
            backgroundVideoCaptioningStatus,
            playbackRate,
            videoTimerangeSegments,
            resolvedBackgroundState,
            hasVideoBackground,
            hasRecordingBackground,
            hasPreviousBackground,
            sequenceStoreModulePath
        } = useSequence(props.seqId);

        const { saveVideo } = useVideo();
        const { saveHistoryStep } = useHistory();

        const captionButtonShowEvent = UI_CAPTION_BUTTON_SHOW;
        const captionButtonCaptioningChangeEvent = UI_CAPTION_BUTTON_CAPTIONING_CHANGE;

        const backgroundVideoCaptions = computed({
            get: () => {
                return (
                    (hasVideoBackground.value && resolvedBackgroundState.value.video.captions) ||
                    (hasRecordingBackground.value && resolvedBackgroundState.value.recording.captions) ||
                    []
                );
            },
            set: (value) => {
                if (!hasPreviousBackground.value) {
                    saveHistoryStep(() => {
                        if (hasVideoBackground.value) {
                            store.commit(sequenceStoreModulePath.value + '/setBackgroundVideoCaptions', value);
                        } else if (hasRecordingBackground.value) {
                            store.commit(sequenceStoreModulePath.value + '/recording/setCaptions', value);
                        }
                    });
                }
            }
        });

        const backgroundVideoCaptioning = computed({
            get: () => {
                return (
                    !hasPreviousBackground.value &&
                    ((hasVideoBackground.value && resolvedBackgroundState.value.video.captioning) ||
                        (hasRecordingBackground.value && resolvedBackgroundState.value.recording.captioning))
                );
            },
            set(value) {
                if (!hasPreviousBackground.value) {
                    if (hasVideoBackground.value) {
                        if (!!value) {
                            store.dispatch(sequenceStoreModulePath.value + '/startBackgroundVideoCaptioning');
                        } else {
                            store.dispatch(sequenceStoreModulePath.value + '/cancelBackgroundVideoCaptioning');
                        }
                        store.commit(sequenceStoreModulePath.value + '/setBackgroundVideoCaptioningStatus', null);
                    } else if (hasRecordingBackground.value) {
                        if (!!value) {
                            store.dispatch(sequenceStoreModulePath.value + '/recording/startCaptioning');
                        } else {
                            store.dispatch(sequenceStoreModulePath.value + '/recording/cancelCaptioning');
                        }
                        store.commit(sequenceStoreModulePath.value + '/recording/setCaptioningStatus', null);
                    }
                    saveVideo();
                }
            }
        });

        const backgroundVideoCaptionModifier = computed(() => {
            if (hasVideoBackground.value) return sequenceStoreModulePath + '/setBackgroundVideoCaptions';
            else if (hasRecordingBackground.value) return sequenceStoreModulePath + '/recording/setCaptions';
            return '';
        });

        const hasCaptions = computed(() => {
            return backgroundVideoCaptions.value.length > 0 && backgroundVideoCaptions.value[0].text.length > 0;
        });

        const toggleStyle = computed(() => {
            return hasCaptions.value ? { color: 'var(--edit-mode-color)' } : {};
        });

        const enableDropDown = computed(() => {
            return props.readOnly || !backgroundVideo.value.src || hasPreviousBackground.value;
        });

        const openCaptionEditor = (button) => {
            store.commit('ui/setCurrentCaptionButton', button);
        };

        const startCaptioning = () => {
            backgroundVideoCaptioning.value = true;
        };

        const handleCaptioning = (button) => {
            if (hasCaptions.value) openCaptionEditor(button);
            else startCaptioning();
        };

        return {
            playbackRate,
            videoTimerangeSegments,
            toggleStyle,
            enableDropDown,
            hasVideoBackground,
            hasRecordingBackground,
            hasPreviousBackground,
            backgroundVideoCaptions,
            backgroundVideoCaptioningStatus,
            backgroundVideoCaptioning,
            backgroundVideoCaptionModifier,
            openCaptionEditor,
            handleCaptioning,
            captionButtonShowEvent,
            captionButtonCaptioningChangeEvent
        };
    }
});
</script>
