/**
 * Edit video Sequence panel Vuex store extension
 */

import _merge from 'lodash/merge';
import _omit from 'lodash/omit';
import { Color, Panel, types, Align, Dimension, Duration, conversions } from 'cte-video-studio';
import timeline from '../timeline.js';

export const resolvePanelCardData = (data, findLibraryItem) => {
    let resolvedData = _merge({}, data);

    if (!findLibraryItem('panels', '', data.animation.type)) resolvedData.animation.type = Panel.ANIMATION_DEFAULT;

    return resolvedData;
};

const mergeTemplateData = (state, data, findLibraryItem) => {
    // First we remove potentially disabled component
    let resolvedData = resolvePanelCardData(data, findLibraryItem);

    // Then we keep the full template data, except for the potential custom panel
    let omittedKeys = [];
    if (resolvedData.shape.type != Panel.CUSTOM || !!state.shape.src) {
        omittedKeys.push('shape.src', 'shape.src__id', !!state.shape.src__ref && 'shape.src__ref');
    }

    // Finally we return the merge of the current state and the relevant template keys
    return _merge({}, state, _omit(resolvedData, omittedKeys));
};

const getSideName = (side) => {
    switch (side) {
        case Align.LEFT:
            return 'left';
        case Align.RIGHT:
            return 'right';
        case Align.TOP:
            return 'top';
        case Align.BOTTOM:
            return 'bottom';
        default:
            return '';
    }
};

// Initial state
const state = () => ({
    ...timeline.state(),

    enabled: false,
    shape: {
        category: Panel.CATEGORY_DEFAULT,
        type: Panel.DEFAULT,
        src: '',
        src__ref: '',
        src__id: ''
    },
    color: {
        enabled: false,
        start: Color.NONE,
        end: Color.NONE,
        start__ref: Color.NONE,
        end__ref: Color.NONE,
        angle: Color.GRADIENT_ANGLE_DEFAULT
    },
    opacity: Color.OPACITY_DEFAULT,
    size: {
        width: Dimension.AUTO,
        height: Dimension.PANEL_DEFAULT,
        ratioConstraint: true
    },
    position: {
        side: Align.LEFT,
        alignH: Align.CENTER,
        alignV: Align.CENTER,
        alignX: Dimension.ZERO,
        alignY: Dimension.ZERO
    },
    animation: {
        enabled: true,
        start: Duration.START_DEFAULT,
        end: Duration.END_DEFAULT,
        enabledBgMove: true
    }
});

// Getters
const getters = {
    panelTimeline: (state) => state.timeline,

    panelTimelineOut: (state) => state.timelineOut,

    panelNeedsRefresh: (state) => state.needsRefresh,

    panelShapeSrc: (state) => (state.shape.type == Panel.CUSTOM ? state.shape.src : ''),

    panelColorStart: (state, getters, rootState) =>
        state.color.start || rootState.branding.palette.color1 || Color.PANEL_DEFAULT,

    panelColorEnd: (state, getters, rootState) =>
        state.color.end || rootState.branding.palette.color2 || Color.PANEL_DEFAULT,

    panelColor: (state, getters) => ({
        start: getters.panelColorStart,
        end: getters.panelColorEnd,
        angle: state.color.angle
    }),

    panelOppositeSide: (state) => {
        switch (state.position.side) {
            case Align.LEFT:
                return Align.RIGHT;
            case Align.RIGHT:
                return Align.LEFT;
            case Align.TOP:
                return Align.BOTTOM;
            case Align.BOTTOM:
                return Align.TOP;
        }
    },

    panelSideName: (state) => getSideName(state.position.side),

    panelOppositeSideName: (state, getters) => getSideName(getters.panelOppositeSide),

    panelHasBgMove: (state) =>
        state.enabled &&
        state.shape.category == Panel.SIDE_CATEGORY &&
        state.opacity == Color.OPACITY_FULL &&
        state.animation.enabledBgMove,

    isPanelHalfPosition: (state) => {
        return types.isHalfPosition(state.position.alignH) || types.isHalfPosition(state.position.alignV);
    }
};

// Mutations
const mutations = {
    setPanelTimeline: timeline.mutations.setTimeline,

    setPanelTimelineOut: timeline.mutations.setTimelineOut,

    enablePanelRefresh: timeline.mutations.enableRefresh,

    enablePanel(state, enabled) {
        state.enabled = enabled;
        if (!state.enabled) state.timeline = () => null;
    },

    setPanelCategory(state, category) {
        state.shape.category = types.isPanelCategory(category) ? category : Panel.CATEGORY_DEFAULT;
        if (state.shape.category == Panel.SIDE_CATEGORY && state.size.width == Dimension.AUTO) {
            state.size.width = Dimension.PANEL_DEFAULT;
        }
    },

    setPanel(state, type) {
        state.shape.type = types.isPanel(type) ? type : Panel.DEFAULT;
    },

    setPanelSource(state, source) {
        state.shape.src = source;
    },

    enablePanelColor(state, enabled) {
        state.color.enabled = enabled;
    },

    setPanelOpacity(state, opacity) {
        state.opacity = conversions.percentNum(opacity, false) || Color.OPACITY_DEFAULT;
    },

    setPanelWidth(state, width) {
        state.size.width =
            types.isDimension(width, true) &&
            (width != Dimension.AUTO ||
                (state.shape.category != Panel.SIDE_CATEGORY &&
                    state.size.ratioConstraint &&
                    state.size.height != Dimension.AUTO))
                ? width
                : Dimension.PANEL_DEFAULT;
    },

    setPanelHeight(state, height) {
        state.size.height =
            types.isDimension(height, true) &&
            (height != Dimension.AUTO || (state.size.ratioConstraint && state.size.width != Dimension.AUTO))
                ? height
                : Dimension.PANEL_DEFAULT;
    },

    setPanelRatioConstraint(state, constrain) {
        state.size.ratioConstraint = constrain;
        if (!constrain) {
            if (state.shape.category != Panel.SIDE_CATEGORY && state.size.width == Dimension.AUTO)
                state.size.width = Dimension.PANEL_DEFAULT;
            if (state.size.height == Dimension.AUTO) state.size.height = Dimension.PANEL_DEFAULT;
        }
    },

    setPanelPosition(state, { side, alignH, alignV, alignX, alignY }) {
        state.position.side = [Align.TOP, Align.RIGHT, Align.BOTTOM, Align.LEFT].includes(side) ? side : Align.LEFT;
        state.position.alignH = types.isAlign(alignH) ? alignH : Align.CENTER;
        state.position.alignV = types.isAlign(alignV) ? alignV : Align.CENTER;
        state.position.alignX =
            state.position.alignH == Align.CUSTOM && types.isDimension(alignX) ? alignX : Dimension.ZERO;
        state.position.alignY =
            state.position.alignV == Align.CUSTOM && types.isDimension(alignY) ? alignY : Dimension.ZERO;
    },

    enablePanelAnimation(state, enable) {
        state.animation.enabled = enable;
    },

    setPanelStart(state, start) {
        state.animation.start = types.isZeroPositive(start) ? parseFloat(start) : Duration.START_DEFAULT;
    },

    setPanelEnd(state, end) {
        state.animation.end = types.isZeroPositive(end) ? parseFloat(end) : Duration.END_DEFAULT;
    },

    enablePanelBgMove(state, enable) {
        state.animation.enabledBgMove = enable;
    },

    setPanelColor(state, color) {
        state.color.start = types.isColor(color.start) ? color.start : Color.NONE;
        state.color.start__ref = color.start__ref || state.color.start;
        state.color.end = types.isColor(color.end) ? color.end : Color.NONE;
        state.color.end__ref = color.end__ref || state.color.end;
        state.color.angle = types.isAngle(color.angle) ? color.angle : Color.GRADIENT_ANGLE_DEFAULT;
    },

    setPanelSourceReference(state, ref) {
        state.shape.src__ref = ref;
    },

    setPanelSourceId(state, id) {
        state.shape.src__id = id;
    },

    initPanel(context, data) {
        actions.initPanel(context, data);

        let { commit, dispatch } = context;

        commit('setPanelSourceId', data.shape.src__id);
        commit('setPanelSourceReference', data.shape.src__ref);
    }
};

// Actions
const actions = {
    initPanel({ commit }, data) {
        commit('enablePanel', data.enabled);
        commit('setPanelCategory', data.shape.category);
        commit('setPanel', data.shape.type);
        commit('setPanelSource', data.shape.src);
        commit('enablePanelColor', data.color.enabled);
        commit('setPanelColor', data.color);
        commit('setPanelOpacity', data.opacity);
        commit('setPanelWidth', data.size.width);
        commit('setPanelHeight', data.size.height);
        commit('setPanelRatioConstraint', data.size.ratioConstraint);
        commit('setPanelPosition', data.position);
        commit('enablePanelAnimation', data.animation.enabled);
        commit('setPanelStart', data.animation.start);
        commit('setPanelEnd', data.animation.end);
        commit('enablePanelBgMove', data.animation.enabledBgMove);
        commit('setPanelSourceId', data.shape.src__id);
        commit('setPanelSourceReference', data.shape.src__ref);
    },

    updatePanelSource({ commit }, data) {
        commit('setPanelSourceId', data.src__id);
        commit('setPanelSource', data.src);
    },

    applyTemplatePanel({ state, rootGetters, dispatch }, data) {
        dispatch('initPanel', mergeTemplateData(state, data, rootGetters['branding/libraries/findLibraryItem']));
    },

    showPanelCard({ commit, state }, show) {
        if (show) {
            commit('enablePanel', show);
            commit('setPanelWidth', state.size.width);
        } else {
            commit('resetPanelState');
        }
    },

    pastePanelCard({ dispatch, rootState, rootGetters }, data) {
        dispatch(
            'initPanel',
            rootState.ui.clipboard.brandId == rootState.branding.id
                ? data
                : resolvePanelCardData(data, rootGetters['branding/libraries/findLibraryItem'])
        );
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
