import FetchVideosApiService from '@/js/infrastructure/api/video/FetchVideosApiService.js';
import PaginatedResultDTO from '@/js/dto/PaginatedResultDTO.js';

export default class FetchVideosDataService {
    constructor(store) {
        this.fetchVideosApiService = new FetchVideosApiService(store);
    }

    async handle(folderId, options) {
        let data = (await this.fetchVideosApiService.handle(folderId, options)).data;
        return new PaginatedResultDTO(data);
    }
}
