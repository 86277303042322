<template>
    <div class="ui-number-input horizontal" :class="containerClasses">
        <label class="ui-number-label" :class="labelClasses" :for="inputId" :aria-hidden="!showLabel || null">
            <slot>{{ label }}</slot>
        </label>
        <div class="ui-number-input-group">
            <button
                :id="buttonStepId + '-down'"
                class="ui-number-step ui-number-step-down"
                :title="$t('Decrement value')"
                :disabled="disabled"
                @mousedown="handlePressStepDown()"
            >
                <span class="visually-hidden">{{ $t('Decrement value') }}</span>
                <fa-icon class="icon" icon="fa-solid fa-minus" />
            </button>
            <input
                type="number"
                ref="$input"
                :id="inputId"
                v-model="displayedValue"
                :step="step"
                :min="min"
                :max="max"
                :disabled="disabled"
                @focus="handleFocus"
                @blur="handleBlur"
                @change="handleChange"
            />
            <button
                :id="buttonStepId + '-up'"
                class="ui-number-step ui-number-step-up"
                :title="$t('Increment value')"
                :disabled="disabled"
                @mousedown="handlePressStepUp()"
            >
                <span class="visually-hidden">{{ $t('Increment value') }}</span>
                <fa-icon class="icon" icon="fa-solid fa-plus" />
            </button>
        </div>
    </div>
</template>

<script>
import UiIcon from './UiIcon.vue';

export const UI_NUMBER_INPUT_CHANGE = 'update:modelValue';

const FIRST_REPEAT_STEP_DELAY = 750;
const REPEAT_STEP_DELAY = 250;

export default {
    components: { UiIcon },

    emits: [UI_NUMBER_INPUT_CHANGE],

    props: {
        id: {
            type: String
        },
        modelValue: {
            type: [String, Number],
            default: 0
        },
        defaultValue: {
            type: [String, Number],
            default: 0
        },
        step: {
            type: Number,
            default: null
        },
        min: {
            type: Number,
            default: null
        },
        max: {
            type: Number,
            default: null
        },
        decimals: {
            type: Number,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        showLabel: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            inputValue: !isNaN(this.modelValue) ? this.modelValue : this.defaultValue,
            hasFocus: false
        };
    },

    computed: {
        containerClasses() {
            return {
                disabled: this.disabled
            };
        },

        inputId() {
            return this.id + '-number-input';
        },

        buttonStepId() {
            return this.inputId + '-step';
        },

        labelClasses() {
            return {
                'visually-hidden': !this.showLabel
            };
        },

        displayedValue: {
            get() {
                if (this.decimals == null || this.hasFocus) return this.inputValue;

                return !isNaN(parseFloat(this.inputValue))
                    ? parseFloat(this.inputValue).toFixed(this.decimals)
                    : !isNaN(parseFloat(this.modelValue))
                      ? parseFloat(this.modelValue).toFixed(this.decimals)
                      : this.modelValue;
            },
            set(value) {
                this.inputValue = value;
            }
        }
    },

    watch: {
        modelValue(newValue) {
            this.inputValue = !isNaN(newValue) ? this.modelValue : this.defaultValue;
        }
    },

    methods: {
        handleFocus(event) {
            if (isNaN(parseFloat(this.inputValue))) this.inputValue = this.modelValue;
            this.hasFocus = true;
        },

        handleBlur(event) {
            this.hasFocus = false;
        },

        handleChange(event) {
            if (!/\S/.test(this.inputValue) || isNaN(this.inputValue)) {
                this.inputValue = this.defaultValue;
            }
            if (this.min != null && this.inputValue != this.defaultValue)
                this.inputValue = Math.max(this.min, this.inputValue);
            if (this.max != null && this.inputValue != this.defaultValue)
                this.inputValue = Math.min(this.inputValue, this.max);
            this.emitChangeEvent();
        },

        handlePressStepDown(delay) {
            clearTimeout(this._stepTimeout);
            if (!this.min || this.inputValue > this.min) {
                this._stepTimeout = setTimeout(
                    this.handlePressStepDown,
                    delay || FIRST_REPEAT_STEP_DELAY,
                    REPEAT_STEP_DELAY
                );
                document.addEventListener('mouseup', this.handleReleaseStep);
                this.updateStepDown();
            }
        },

        updateStepDown() {
            if (isNaN(this.inputValue)) {
                this.inputValue = this.defaultValue;
            }
            this.$refs.$input.stepDown();
            this.inputValue = Number(this.$refs.$input.value);
            if (this.min == Number(this.$refs.$input.value)) {
                clearTimeout(this._stepTimeout);
            }
        },

        handlePressStepUp(delay) {
            clearTimeout(this._stepTimeout);
            if (!this.max || this.inputValue < this.max) {
                this._stepTimeout = setTimeout(
                    this.handlePressStepUp,
                    delay || FIRST_REPEAT_STEP_DELAY,
                    REPEAT_STEP_DELAY
                );
                document.addEventListener('mouseup', this.handleReleaseStep);
                this.updateStepUp();
            }
        },

        updateStepUp() {
            if (isNaN(this.inputValue)) {
                this.inputValue = this.defaultValue;
            }
            this.$refs.$input.stepUp();
            this.inputValue = Number(this.$refs.$input.value);
            if (this.max == Number(this.$refs.$input.value)) {
                clearTimeout(this._stepTimeout);
            }
        },

        handleReleaseStep(event) {
            clearTimeout(this._stepTimeout);
            document.removeEventListener('mouseup', this.handleReleaseStep);
            this.emitChangeEvent();
        },

        emitChangeEvent() {
            this.$emit(UI_NUMBER_INPUT_CHANGE, this.inputValue);
        }
    },

    mounted() {
        //
    }
};
</script>
