/**
 * Edit video Sequence transition Vuex store extension
 */

import _merge from 'lodash/merge';
import { Color, Transition, types } from 'cte-video-studio';
import card from '../card';
import timeline from '../timeline.js';
import gsap from 'gsap';
import { Timeline } from '@/js/video-studio/constants/index.js';

export const resolveTransitionCardData = (data, findLibraryItem) => {
    let resolvedData = _merge({}, data);

    if (!findLibraryItem('transitions', '', data.type)) resolvedData.type = Transition.DEFAULT;

    return resolvedData;
};

const mergeTemplateData = (state, data, findLibraryItem) => {
    // We remove potentially disabled component, and keep the full template data
    return resolveTransitionCardData(data, findLibraryItem);
};

// Initial state
const state = () => ({
    ...timeline.state(),
    ...card.state(),

    type: Transition.DEFAULT,
    color: {
        start: Color.NONE,
        start__ref: Color.NONE,
        second: Color.NONE,
        second__ref: Color.NONE,
        third: Color.NONE,
        third__ref: Color.NONE,
        end: Color.NONE,
        end__ref: Color.NONE,
        angle: Color.GRADIENT_ANGLE_DEFAULT
    },
    image: {
        src: '',
        src__ref: '',
        src__id: '',
        color: Color.NONE,
        color__ref: Color.NONE
    },
    scene: false,
    timeline: () => gsap.timeline({ id: Timeline.TRANSITION_TIMELINE_ID })
});

// Getters
const getters = {
    transitionTimeline: (state) => state.timeline,

    transitionNeedsRefresh: (state) => state.needsRefresh
};

// Mutations
const mutations = {
    setTransitionTimeline: timeline.mutations.setTimeline,

    enableTransitionRefresh: timeline.mutations.enableRefresh,

    setTransition(state, type) {
        state.type = types.isTransition(type) ? type : Transition.DEFAULT;
    },

    setTransitionImage(state, src) {
        state.image.src = src;
    },

    enableTransitionScene(state, enabled) {
        state.scene = enabled;
    },

    setTransitionColor(state, color) {
        state.color.start = types.isColor(color.start) ? color.start : state.color.start || Color.TRANSITION_DEFAULT;
        state.color.start__ref = color.start__ref || state.color.start__ref || state.color.start;
        state.color.second = types.isColor(color.second)
            ? color.second
            : state.color.second || Color.TRANSITION_DEFAULT;
        state.color.second__ref = color.second__ref || state.color.second__ref || state.color.second;
        state.color.third = types.isColor(color.third) ? color.third : state.color.third || Color.TRANSITION_DEFAULT;
        state.color.third__ref = color.third__ref || state.color.third__ref || state.color.third;
        state.color.end = types.isColor(color.end) ? color.end : state.color.end || Color.NONE;
        state.color.end__ref = color.end__ref || state.color.end__ref || state.color.end;
        state.color.angle = types.isAngle(color.angle)
            ? color.angle
            : state.color.angle || Color.GRADIENT_ANGLE_DEFAULT;
    },

    setTransitionImageReference(state, ref) {
        state.image.src__ref = ref;
    },

    setTransitionImageId(state, id) {
        state.image.src__id = id;
    },

    setTransitionImageColor(state, data) {
        state.image.color = types.isColor(data.color) ? data.color : Color.NONE;
        state.image.color__ref = data.color__ref || state.image.color;
    },

    showTransitionCard: card.mutations.showCard
};

// Actions
const actions = {
    initTransition({ commit }, data) {
        commit('setTransition', data.type);
        commit('setTransitionColor', data.color);
        commit('setTransitionImage', data.image?.src || '');
        commit('setTransitionImageColor', data.image?.color || '');
        commit('enableTransitionScene', data.scene);

        commit('setTransitionImageId', data.image?.src__id || '');
        commit('setTransitionImageReference', data.image?.src__ref || '');
        commit('setTransitionImageColor', { color: data.image?.color || '', color__ref: data.image?.color__ref || '' });

        if (data.type != Transition.DEFAULT) commit('showTransitionCard', true);
    },

    updateTransitionImage({ commit }, data) {
        commit('setTransitionImageId', data.src__id);
        commit('setTransitionImage', data.src);
    },

    applyTemplateTransition({ state, dispatch, rootGetters }, data) {
        dispatch('initTransition', mergeTemplateData(state, data, rootGetters['branding/libraries/findLibraryItem']));
    },

    showTransitionCard({ commit }, show) {
        if (show) commit('showTransitionCard', show);
        else commit('resetTransitionState');
    },

    pasteTransitionCard({ dispatch, rootState, rootGetters }, data) {
        dispatch(
            'initTransition',
            rootState.ui.clipboard.brandId == rootState.branding.id
                ? data
                : resolveTransitionCardData(data, rootGetters['branding/libraries/findLibraryItem'])
        );
    },

    initTransitionDefaults({ state, rootState, commit }) {
        let colors = Object.keys(state.color).reduce((colors, key) => {
            if (/__ref$/.test(key)) return colors;

            switch (key) {
                case 'start':
                    if (!types.isColor(state.color[key])) {
                        colors[key] = rootState.branding.palette.color1;
                        colors[key + '__ref'] = rootState.ui.prefixes.settingsReference + 'palette.color1';
                    } else {
                        colors[key] = state.color.start;
                    }
                    break;
                case 'second':
                case 'third':
                    if (!types.isColor(state.color[key])) {
                        colors[key] = rootState.branding.palette.color2;
                        colors[key + '__ref'] = rootState.ui.prefixes.settingsReference + 'palette.color2';
                    } else {
                        colors[key] = state.color.start;
                    }
                    break;
            }

            return colors;
        }, {});

        commit('setTransitionColor', colors);
    }
};

export default {
    state,
    getters: getters,
    mutations: mutations,
    actions: actions
};
