<template>
    <div class="studio-controls studio-container" :class="controlsClasses" :style="controlsStyles">
        <svg class="studio-hidden-svg">
            <defs>
                <symbol id="studio-controls-play-icon" viewBox="0 0 40 50">
                    <path
                        d="M37.83,21.65,6.32.67A4.08,4.08,0,0,0,.67,1.83,4.16,4.16,0,0,0,0,4.05V45.94A4.06,4.06,0,0,0,4.06,50a4,4,0,0,0,2.26-.69l31.52-21a4,4,0,0,0,1.08-5.61A3.87,3.87,0,0,0,37.83,21.65Z"
                    />
                </symbol>
                <symbol id="studio-controls-pause-icon" viewBox="0 0 40 50">
                    <path
                        d="M7.5,0A6.38,6.38,0,0,0,1,6.25v37.5A6.38,6.38,0,0,0,7.5,50,6.38,6.38,0,0,0,14,43.75V6.25A6.38,6.38,0,0,0,7.5,0M39,6.25v37.5a6.51,6.51,0,0,1-13,0V6.25a6.51,6.51,0,0,1,13,0"
                        fill-rule="evenodd"
                    />
                </symbol>
                <symbol id="studio-controls-previous-icon" viewBox="0 0 48 50">
                    <path
                        d="M8.49,3.51A3.51,3.51,0,0,0,5,0H3.51A3.51,3.51,0,0,0,0,3.51v43A3.51,3.51,0,0,0,3.51,50H5a3.51,3.51,0,0,0,3.52-3.5V27.44L25.94,37.49,42.34,47a3.52,3.52,0,0,0,5.28-3V6.07a3.51,3.51,0,0,0-5.28-3L25.92,12.49l-17.43,10v-19Z"
                    />
                </symbol>
            </defs>
        </svg>
        <div class="studio-control-toggle studio-container" :class="toggleClasses" @click="togglePlay">
            <div class="studio-control-toggle-inner">
                <svg class="studio-control-toggle-icon studio-play-icon" viewBox="0 0 40 50">
                    <use xlink:href="#studio-controls-play-icon" />
                </svg>
                <svg class="studio-control-toggle-icon studio-pause-icon" viewBox="0 0 40 50">
                    <use xlink:href="#studio-controls-pause-icon" />
                </svg>
            </div>
        </div>
        <div class="studio-control-bar" v-if="isLegacy">
            <div class="studio-control-bar-inner">
                <button class="studio-control studio-previous" @click.prevent="previous">
                    <svg class="studio-control-icon" viewBox="0 0 48 50">
                        <use xlink:href="#studio-controls-previous-icon" />
                    </svg>
                </button>
                <button class="studio-control studio-play-pause" :class="playClasses" @click.prevent="togglePlay">
                    <svg class="studio-control-icon studio-play-icon" viewBox="0 0 40 50">
                        <use xlink:href="#studio-controls-play-icon" />
                    </svg>
                    <svg class="studio-control-icon studio-pause-icon" viewBox="0 0 40 50">
                        <use xlink:href="#studio-controls-pause-icon" />
                    </svg>
                </button>
                <button class="studio-control studio-next" @click.prevent="next">
                    <svg class="studio-control-icon" viewBox="0 0 48 50">
                        <use xlink:href="#studio-controls-previous-icon" />
                    </svg>
                </button>
                <div
                    class="studio-timeline"
                    ref="$controlTimeline"
                    :style="timelineStyles"
                    @click.prevent="seek($event)"
                    @mousemove="updateTimelineHandle($event)"
                >
                    <ol class="studio-timeline-sequences">
                        <li
                            v-for="(time, index) in timelineLabels"
                            class="studio-timeline-sequence"
                            :class="timelineLabelsClasses[index]"
                            :style="timelineLabelStyles(time)"
                        >
                            <span>{{ index + 1 }} / {{ sequences.length }}</span>
                        </li>
                        <li class="studio-timeline-handle" :class="timelineHandleClasses">
                            <div class="studio-timeline-handle-sequence">{{ timelineHoveredSequence }}</div>
                            <div class="studio-timeline-tooltip">
                                <div class="studio-timeline-tooltip-time">{{ timelineHoveredTime }}</div>
                                <div class="studio-timeline-tooltip-sequence">{{ timelineHoveredSequenceName }}</div>
                            </div>
                        </li>
                    </ol>
                    <div class="studio-timeline-progress" :style="timelineProgressStyles"></div>
                </div>
                <div class="studio-time">
                    <span class="studio-time-total">{{ timeTotal }}</span>
                </div>
                <button class="studio-control studio-mute" :class="soundClasses" @click.prevent="toggleMute">
                    <svg class="studio-control-icon" viewBox="0 0 64 50">
                        <path
                            d="M26.89.87l-15.64,12H3.6A3.6,3.6,0,0,0,0,16.49H0v17a3.6,3.6,0,0,0,3.59,3.6h7.67l15.7,12a4.14,4.14,0,0,0,6.69-3.34V4.15A4.2,4.2,0,0,0,26.89.87Z"
                        />
                        <g class="studio-unmute-icon">
                            <path
                                d="M44.84,14.19A3.07,3.07,0,1,0,41.05,19a7.75,7.75,0,0,1,1.07,10.9,7.61,7.61,0,0,1-1.4,1.32A2.7,2.7,0,0,0,40.2,35l.06.08.26.26a3.1,3.1,0,0,0,4.18.51,13.89,13.89,0,0,0,.13-21.68Z"
                            />
                            <path
                                d="M51.85,5.57A3.09,3.09,0,0,0,47.61,6h0A3,3,0,0,0,48,10.27a19,19,0,0,1,0,29.47A3,3,0,0,0,47.61,44h0a3,3,0,0,0,4.24.49,25,25,0,0,0,3.83-35.1,24.71,24.71,0,0,0-3.83-3.82Z"
                            />
                        </g>
                        <path
                            class="studio-mute-icon"
                            d="M57,25.08l5.81-5.82a3.63,3.63,0,0,0,0-5.06,3.53,3.53,0,0,0-5-.07.46.46,0,0,1-.07.07L52,20,46.13,14.2a3.53,3.53,0,0,0-5-.07.46.46,0,0,0-.07.07,3.53,3.53,0,0,0-.06,5l.06.07,5.82,5.82-5.82,5.81a3.53,3.53,0,0,0-.07,5l.07.08a3.65,3.65,0,0,0,5.06,0L52,30.14,57.76,36a3.59,3.59,0,1,0,5.31-4.83l-.25-.24Z"
                        />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.studio-controls {
    .studio-timeline-handle {
        left: var(--controls-timeline-handle-left, 0);
    }
}
</style>

<script>
import { CTE_URL, Dimension } from '../constants';
import { conversions } from '../utils';
import gsap from 'gsap';
import { mapState } from 'vuex';

export default {
    inject: ['$stage'],

    props: {
        format: Object,
        previewStart: Number,
        playing: Boolean,
        muted: Boolean,
        elapsedTime: Number,
        sequences: Array
    },

    data() {
        return {
            timelineHandleLeft: 0
        };
    },

    computed: {
        ...mapState({
            totalTime: (state) => state.display.timeline.totalTime,
            labels: (state) => state.display.timeline.labels
        }),

        isLegacy() {
            return !this.$store.hasModule('ui');
        },

        controlsClasses() {
            return {
                'studio-controls-reduced': this.format.width * this.$stage.scale <= Dimension.CONTROLS_REDUCED_MAX_WIDTH
            };
        },

        controlsStyles() {
            return {
                fontSize: (Dimension.CONTROLS_FONT_SIZE_DEFAULT / this.$stage.scale).toFixed(1) + Dimension.PIXEL_UNIT
            };
        },

        toggleClasses() {
            return {
                'studio-play-on': this.playing,
                'studio-play-off': !this.playing
            };
        },

        previewElapsedTime() {
            return this.elapsedTime - this.previewTimeOffset;
        },
        previewTotalTime() {
            return this.totalTime - this.previewTimeOffset;
        },
        previewTimeOffset() {
            return (this.previewStart != -1 && this.labels[this.sequences[this.previewStart].id]) || 0;
        },

        timelineLabels() {
            if (this.previewStart != -1) return [0];
            return Object.entries(this.labels)
                .filter((entry) => entry[0].indexOf('seq') == 0)
                .map((entry) => entry[1])
                .sort();
        },

        timelineLabelsClasses() {
            return this.timelineLabels.map((time) => ({
                'studio-timeline-sequence-played': time - this.previewTimeOffset <= this.previewElapsedTime
            }));
        },

        timeTotal() {
            return conversions.minutes(Math.floor(this.previewTotalTime), 0);
        },
        timeProgress() {
            return this.previewElapsedTime / this.previewTotalTime;
        },

        playClasses() {
            return {
                'studio-play-on': this.playing,
                'studio-play-off': !this.playing
            };
        },

        soundClasses() {
            return {
                'studio-sound-on': !this.muted,
                'studio-sound-off': this.muted
            };
        },

        timelineStyles() {
            return {
                '--controls-timeline-handle-left': this.timelineHandleLeft * 100 + Dimension.PERCENT_UNIT
            };
        },

        timelineProgressStyles() {
            return {
                transform: 'scale(' + this.timeProgress + ', 1)'
            };
        },

        timelineHandleClasses() {
            return {
                'studio-timeline-handle-played': this.timelineHandleLeft <= this.timeProgress
            };
        },

        timelineHoveredSequence() {
            return Math.max(
                1,
                this.timelineLabels.filter((time) => time <= this.timelineHandleLeft * this.previewTotalTime).length
            );
        },

        timelineHoveredSequenceName() {
            let index = this.previewStart == -1 ? this.timelineHoveredSequence - 1 : this.previewStart;
            return this.sequences[index] ? this.sequences[index].name : '';
        },

        timelineHoveredTime() {
            return conversions.minutes(Math.floor(this.timelineHandleLeft * this.previewTotalTime), 0);
        }
    },

    methods: {
        togglePlay() {
            this.$stage.toggleTimeline();
            gsap.fromTo(
                '.studio-control-toggle-inner',
                { autoAlpha: 1, scale: 1 },
                { duration: 0.5, autoAlpha: 0, scale: 1.5, ease: 'power1.out' }
            );
        },

        previous() {
            this.$stage.previousSequenceTimeline();
        },

        next() {
            this.$stage.nextSequenceTimeline();
        },

        exit() {
            window.open(CTE_URL, '_blank');
        },

        toggleMute() {
            this.$stage.toggleMuteTimeline();
        },

        seek(event) {
            let rect = this.$refs.$controlTimeline.getBoundingClientRect(),
                p = (event.clientX - rect.left) / rect.width;
            this.$stage.seekTimeline(p * this.previewTotalTime + this.previewTimeOffset);
        },

        timelineLabelStyles(time) {
            return {
                left: (100 * time) / this.previewTotalTime + Dimension.PERCENT_UNIT
            };
        },

        updateTimelineHandle(event) {
            let timelineRect = this.$refs.$controlTimeline.getBoundingClientRect();
            this.timelineHandleLeft = Math.max(
                0,
                Math.min((event.clientX - timelineRect.left) / timelineRect.width, 1)
            );
        }
    }
};
</script>
