<template>
    <splash-screen v-if="loading" />
    <div v-else-if="!errorCode" id="studio-container">
        <video-studio
            v-if="studioReady"
            id="studio"
            :production="true"
            :preview="false"
            :autoplay="false"
        ></video-studio>
    </div>
    <error-screen v-else :error-code="errorCode" />
</template>

<script setup>
import { useStore } from 'vuex';
import { nextTick, ref } from 'vue';
import { useRoute } from 'vue-router';

import VideoStudioService from '@/js/infrastructure/api/video/VideoStudioService.js';
import StudioProduceInitializer from '@/js/videos/application/StudioProduceInitializer.js';
import SplashScreen from '@/js/components/SplashScreen.vue';
import ErrorScreen from '@/js/components/ErrorScreen.vue';
import VideoStudio from '@/js/video-studio/VideoStudio.vue';

const store = useStore();
const route = useRoute();
const studioReady = ref(false);

// component state with API call
const loading = ref(true);
const errorCode = ref(null);

const videoStudioService = new VideoStudioService(store);
const studioProduceInitializer = new StudioProduceInitializer(store, videoStudioService);

studioProduceInitializer.initVideoStudio(route.params.production).then((response) => {
    loading.value = false;

    if (response?.errorCode) errorCode.value = response.errorCode;
    else nextTick(() => (studioReady.value = true));
});

// replace class manually to body
document.body.className = 'production produce-video';

// load socket.io library
const socketIoScript = document.createElement('script');
socketIoScript.setAttribute('src', `${import.meta.env.VITE_PRODUCTION_URL}/socket.io/socket.io.js`);
document.body.appendChild(socketIoScript);
</script>
