import ClientApiService from '@/js/infrastructure/api/client/ClientApiService.js';
import ClientDTO from '@/js/dto/ClientDTO.js';
import PaginatedResultDTO from '@/js/dto/PaginatedResultDTO.js';

export default class ClientDataService {
    constructor(store) {
        this.clientApiService = new ClientApiService(store);
    }

    async getAll(options) {
        let data = (await this.clientApiService.getAll(options)).data;
        return new PaginatedResultDTO(data);
    }

    async getByShortname(shortname) {
        let data = (await this.clientApiService.getByShortname(shortname)).data;
        return new ClientDTO(data.client);
    }

    async put(shortname, params) {
        return (await this.clientApiService.put(shortname, params)).data;
    }

    async fetchClientBrands(clientId, search, page) {
        let data = (await this.clientApiService.fetchClientBrands(clientId, search, page)).data;
        return new PaginatedResultDTO(data);
    }

    async fetchClientUsers(clientId, options) {
        let data = (await this.clientApiService.fetchClientUsers(clientId, options)).data;
        return new PaginatedResultDTO(data);
    }

    async fetchClientFoldersGrant(clientId, options) {
        let data = (await this.clientApiService.fetchFoldersGrant(clientId, options)).data;
        return new PaginatedResultDTO(data);
    }
}
