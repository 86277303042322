<template>
    <ui-card :id="id" :class="'ui-card-' + id" :title="$t('Video music')">
        <template #header-icon>
            <fa-icon class="icon" icon="fa-solid fa-music" />
        </template>

        <div class="settings-music-type ui-card-row">
            <template v-for="category in musicLibraryCategories" :key="category">
                <ui-music-library-selector
                    v-if="musicType === category"
                    v-model="libraryMusic"
                    :id="'settings-music-' + category"
                    library="musics"
                    :category="category"
                    :preview-path="'musics/' + category + '/'"
                    :show-label="false"
                    :disabled="readOnly"
                    icon="fa-solid fa-music"
                    :trigger-opening="triggerSelection && musicType === category && !libraryId"
                    @[librarySelectorShowEvent]="openMusicLibrary"
                />
            </template>

            <ui-media-selector
                v-if="musicType === musicCustomCategory || musicTypes.length == 1"
                id="settings-music-audio"
                v-model:media="music"
                :type="mediaTypeMusic"
                :label="$t('Music')"
                :showLabel="musicTypes.length == 1"
                :disabled="readOnly"
                icon="fa-solid fa-volume"
                :trigger-opening="
                    triggerSelection && (musicType === musicCustomCategory || musicTypes.length == 1) && !libraryId
                "
                @[mediaSelectorShowEvent]="openMediaLibrary"
            />

            <div class="settings-music-selector-container" v-if="musicTypes.length > 1">
                <ui-dropdown
                    id="settings-music-type"
                    :select="true"
                    :disabled="readOnly"
                    v-model="musicType"
                    menu-strategy="fixed"
                    :default-label="$t('Add music')"
                >
                    <ui-dropdown-item
                        v-for="type in musicTypes"
                        :key="type"
                        :value="type"
                        :label="$t('studio.music_categories.' + type)"
                    />
                </ui-dropdown>

                <a
                    :href="universalMusicTosUrl"
                    class="settings-music-note"
                    v-if="musicType === musicUniversalCategory"
                    target="_blank"
                >
                    <fa-icon class="icon" icon="fa-solid fa-circle-info" width="11px" />
                    {{ $t('Music Terms of Use') }}
                </a>
            </div>
        </div>

        <ui-range-input
            id="settings-music-volume"
            class="settings-music-volume ui-card-row"
            v-model="musicVolume"
            :label="$t('Volume')"
            :disabled="readOnly"
        />

        <template #footer>
            <!-- TODO: this causes a bug to toggleClass where the dropdown stays shown after closing so we use toggleStyle -->
            <ui-dropdown
                id="settings-music-timerange"
                :menu="false"
                :caret="false"
                :scrollable="false"
                menu-placement="bottom"
                menu-strategy="fixed"
                :disabled="readOnly || (!state.music.src && !state.music.library_src) || !state.music.totalDuration()"
                :toggle-style="activeTimerange ? { color: 'var(--edit-mode-color)' } : {}"
            >
                <template #dropdown-toggle>
                    <svg-icon icon="scissors-icon" />
                    <span>{{ $t('Quick cut') }}</span>
                </template>

                <ui-interval-input
                    id="settings-music-timerange"
                    v-model:values="musicRange"
                    class="ui-content-row"
                    unit="s."
                    :step="0.01"
                    :max="state.music.totalDuration()"
                    :min-range-length="0.5"
                    :range-decimals="2"
                    :label="$t('Select the part to keep')"
                    :disabled="readOnly"
                />
            </ui-dropdown>

            <ui-dropdown
                id="settings-music-timing"
                class="centered timing-dropdown"
                :menu="false"
                :caret="false"
                :scrollable="false"
                menu-placement="bottom-end"
                menu-strategy="fixed"
            >
                <template #dropdown-toggle>
                    <fa-icon class="icon" icon="fa-regular fa-stopwatch" />
                    <span>{{ $t('Timing') }}</span>
                </template>

                <ui-number-input
                    id="settings-music-start"
                    class="ui-content-row"
                    v-model="musicStart"
                    :default-value="0"
                    :min="0"
                    :label="$t('Starting time')"
                    :disabled="readOnly"
                />

                <ui-switch-button
                    id="settings-music-fade"
                    class="settings-music-fade ui-card-row"
                    v-model="enabledEndFade"
                    :label="$t('Fade out at video\'s end')"
                    :disabled="readOnly"
                />
            </ui-dropdown>
        </template>
    </ui-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import * as UiComponents from '../../../../../components';
import { UI_MEDIA_SELECTOR_SHOW, UI_MEDIA_TYPE_MUSIC } from '../../../../../components/UiMediaSelector.vue';
import { UI_LIBRARY_SELECTOR_SHOW } from '../../../../../components/UiLibrarySelector.vue';
import { getPrefixedUuid } from '../../../../../utils';
import { ModifiesHistory, SavesVideo } from '../../../../mixins';
import { CUSTOM_MUSIC_CATEGORY, MUSIC_CATEGORIES, UNIVERSAL_MUSIC_CATEGORY } from '../../../../../constants';

export default {
    mixins: [ModifiesHistory, SavesVideo],

    components: {
        ...UiComponents
    },

    props: {
        id: {
            type: String,
            default: 'settings-music'
        }
    },

    data() {
        return {
            musicUniversalCategory: UNIVERSAL_MUSIC_CATEGORY,
            musicCustomCategory: CUSTOM_MUSIC_CATEGORY,
            mediaTypeMusic: UI_MEDIA_TYPE_MUSIC,
            mediaSelectorShowEvent: UI_MEDIA_SELECTOR_SHOW,
            librarySelectorShowEvent: UI_LIBRARY_SELECTOR_SHOW,
            triggerSelection: false
        };
    },

    computed: {
        ...mapState({
            readOnly: (state) => state.ui.readOnly,
            prefixes: (state) => state.ui.prefixes,
            state: (state) => state.settings,
            universalMusicTosUrl: (state) => state.ui.urls.universalMusicTos,
            libraryTagOrders: (state) => state.branding.libraries.tagOrders,
            libraryId: (state) => state.ui.currentLibrary.id
        }),

        ...mapGetters({
            getBrandLibraryItems: 'branding/libraries/libraryItems'
        }),

        isBrandLibraryEmpty() {
            return (library, category) => !this.getBrandLibraryItems(library, category).length;
        },

        musicLibraryCategories() {
            return [...MUSIC_CATEGORIES].filter((category) => !this.isBrandLibraryEmpty('musics', category));
        },

        musicTypes() {
            return [...this.musicLibraryCategories, CUSTOM_MUSIC_CATEGORY];
        },

        musicType: {
            get() {
                return this.state.music.type;
            },
            set(type) {
                this.startHistoryStep();
                this.setMusicType(type);
                this.updateLibraryMusic({ src: '', src__id: '' });
                this.saveVideo();
            }
        },

        music: {
            get() {
                return {
                    src: this.state.music.src,
                    id: this.state.music.src__id
                };
            },
            set({ src, id }) {
                this.startHistoryStep();
                if (!this.state.music.src__ref) {
                    this.setMusicReference(getPrefixedUuid(this.prefixes.mediaReference));
                }
                this.updateMusic({ src, src__id: id });
                if (this.musicTypes.length == 1 && this.musicType != CUSTOM_MUSIC_CATEGORY) {
                    this.setMusicType(CUSTOM_MUSIC_CATEGORY);
                }
                this.saveVideo();
            }
        },

        libraryMusic: {
            get() {
                return {
                    src: this.state.music.library_src,
                    id: this.state.music.library_src__id
                };
            },
            set({ src, id }) {
                this.startHistoryStep();
                if (!this.state.music.library_src__ref) {
                    this.setLibraryMusicReference(getPrefixedUuid(this.prefixes.musicReference));
                }
                this.updateLibraryMusic({ src, src__id: id });
                this.saveVideo();
            }
        },

        musicStart: {
            get() {
                return this.state.music.start;
            },
            set(value) {
                if (value !== this.musicStart) {
                    this.startHistoryStep();
                    this.setMusicStart(value);
                    this.saveVideo();
                }
            }
        },

        musicVolume: {
            get() {
                return Math.round(100 * this.$store.getters['settings/musicVolume']);
            },
            set(value) {
                this.startHistoryStep();
                this.setMusicVolume({ value: (value / 100).toFixed(2), custom: null });
                this.saveVideo();
            }
        },

        enabledEndFade: {
            get() {
                return this.state.fade.end.enabled;
            },
            set(value) {
                this.startHistoryStep();
                this.enableEndFade(value);
                this.saveVideo();
            }
        },

        musicRange: {
            get() {
                return {
                    start: this.state.music.timerange?.start || 0,
                    end: this.state.music.timerange?.end || -1
                };
            },
            set({ start, end }) {
                if (start !== this.musicRange.start || end !== this.musicRange.end) {
                    this.startHistoryStep();
                    this.setMusicTimerange({ start, end });
                    this.saveVideo();
                }
            }
        },

        activeTimerange() {
            const musicTotalDurationRounded =
                Math.floor((this.state.music.totalDuration() + Number.EPSILON) * 100) / 100;
            return (
                this.musicType &&
                (this.state.music.src || this.state.music.library_src) &&
                (this.musicRange.start > 0 ||
                    (this.musicRange.end < musicTotalDurationRounded && this.musicRange.end > -1))
            );
        }
    },

    watch: {
        musicType() {
            this.triggerSelection = true; // need to be false at mounted
        }
    },

    methods: {
        ...mapMutations({
            setCurrentMediaLibrary: 'ui/setCurrentMediaLibrary',
            setMusicReference: 'settings/setMusicReference',
            setLibraryMusicReference: 'settings/setLibraryMusicReference',
            setMusicType: 'settings/setMusicType',
            setMusicStart: 'settings/setMusicStart',
            setMusicVolume: 'settings/setMusicVolume',
            enableEndFade: 'settings/enableEndFade',
            setMusicTimerange: 'settings/setMusicTimerange'
        }),

        ...mapActions({
            updateMusic: 'settings/updateMusic',
            updateLibraryMusic: 'settings/updateLibraryMusic'
        }),

        openMediaLibrary(selector) {
            this.setCurrentMediaLibrary({
                mediaType: selector.type,
                selectedMediaId: selector.media.id,
                selector
            });
        },

        openMusicLibrary(selector) {
            this.$store.commit('ui/setCurrentLibrary', {
                id: selector.library,
                category: selector.category,
                tagOrder: selector.tagOrder || this.libraryTagOrders[selector.library] || null,
                selectedItemId: selector.modelValue.id,
                selector
            });
        }
    },

    mounted() {
        //
    }
};
</script>
