// Libraries constants

import { Align } from 'cte-video-studio';
import { ZOOM_TARGETS } from './position';

export const LIBRARY_STATUS_HIDDEN = 0;
export const LIBRARY_STATUS_ACTIVABLE = 1;
export const LIBRARY_STATUS_ACTIVE = 2;

export const LIBRARY_SORT_UPDATED_AT = 'updated_at';
export const LIBRARY_SORT_NAME = 'name';
export const LIBRARY_SORT_CATEGORY = 'category';
export const LIBRARY_SORT_OWNER = 'owner_name';
export const LIBRARY_SORT_STATUS = 'status';
export const LIBRARY_SORT_LANGUAGE = 'language';
export const LIBRARY_SORT_AI_NAME = 'ai_name';

export const LIBRARY_FILTER_PROPERTIES = ['name', 'owner_name', 'ai_name'];

export const LIBRARY_DEFAULT_TAG = 'default';

export const LIBRARY_NEW_ITEM_ID = 'new';

export const TEMPLATE_LIBRARY_PAGE_SIZE = 15;

export const UNIVERSAL_MUSIC_CATEGORY = 'universal';
export const CTE_MUSIC_CATEGORY = '2emotion';
export const CUSTOM_MUSIC_CATEGORY = 'my';
export const MUSIC_CATEGORIES = [UNIVERSAL_MUSIC_CATEGORY, CTE_MUSIC_CATEGORY];

export const LIBRARY_SPECIFIC_TAG_CHAR = '-';
export const CATEGORIES_INCLUDING_BRAND_NAME = ['my-sequences', 'my-intro-outro'];

export const GEN_IMAGE_AI_ID = 'gen-image-ai';
export const COMMON_LIBRARY_ID = 'common';

const getZoomTargetTag = (target) => {
    switch (true) {
        case RegExp('^' + Align.TOP).test(target):
            return 'top';
        case RegExp('^' + Align.HALF_TOP).test(target):
            return 'half-top';
        case RegExp('^' + Align.HALF_BOTTOM).test(target):
            return 'half-bottom';
        case RegExp('^' + Align.BOTTOM).test(target):
            return 'bottom';
        default:
            return 'horizontal-axis';
    }
};

export const ZOOM_TARGET_LIBRARY = ZOOM_TARGETS.map((target, index) => ({
    name: target,
    type: 'ZoomTarget',
    uses: [],
    tags: [getZoomTargetTag(target)],
    tagIndexes: {
        [getZoomTargetTag(target)]: index
    },
    disabled: false
}));
