<template>
    <ui-color-selector v-bind="selectorAttributes" ref="$selector" class="ui-removable-color-selector">
        <template #selector-toggle-after>
            <button
                v-if="!disabled && enableRemove"
                class="ui-color-selector-remove"
                :title="$t('Remove color')"
                @click="handleRemove"
            >
                <span class="visually-hidden">{{ $t('Remove color') }}</span>
                <svg-icon icon="close-icon" />
            </button>
        </template>
    </ui-color-selector>
</template>

<script>
import { UiColorSelector, UiIcon } from '../../../../components';

export const UI_COLOR_SELECTOR_REMOVE = 'ui-color-selector-remove';

export default {
    components: {
        UiColorSelector,
        UiIcon
    },

    props: {
        ...UiColorSelector.props,

        enableRemove: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            //
        };
    },

    computed: {
        selectorAttributes() {
            return {
                ...this.$props,
                ...this.$attrs
            };
        }
    },

    watch: {
        //
    },

    methods: {
        togglePicker() {
            this.$refs.$selector.togglePicker();
        },
        showPicker() {
            this.$refs.$selector.showPicker();
        },
        hidePicker() {
            this.$refs.$selector.hidePicker();
        },

        handleRemove(event) {
            this.$emit(UI_COLOR_SELECTOR_REMOVE, this);
        }
    },

    mounted() {
        //
    }
};
</script>
