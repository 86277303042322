<template>
    <button
        :id="selectorId"
        class="ui-sequence-library-selector"
        :class="containerClasses"
        :disabled="disabled"
        v-tooltip="$t('Add a sequence')"
        @click="showLibrary"
    >
        <slot>
            <fa-icon class="icon" :icon="icon" />
            <span :class="labelClasses">{{ label || $t('Select...') }}</span>
        </slot>
    </button>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import _merge from 'lodash/merge';
import { getPrefixedUuid } from '../../../utils';
import { ModifiesHistory } from '../../mixins';
import {
    UI_LIBRARY_SELECTOR_SHOW,
    UI_LIBRARY_SELECTOR_HIDE,
    UI_LIBRARY_SELECTOR_PREVIEW_CHANGE,
    UI_LIBRARY_SELECTOR_CHANGE
} from '../../../components/UiLibrarySelector.vue';
import UiIcon from '../../../components/UiIcon.vue';
import { UsesTooltip } from '../../../mixins';

export default {
    mixins: [ModifiesHistory, UsesTooltip],

    emits: [UI_LIBRARY_SELECTOR_SHOW, UI_LIBRARY_SELECTOR_PREVIEW_CHANGE, UI_LIBRARY_SELECTOR_CHANGE],

    components: {
        UiIcon
    },

    props: {
        id: {
            type: String
        },
        createIndex: {
            type: [Boolean, Number],
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        icon: {
            type: String,
            default: 'selector-sequence-template-icon'
        }
    },

    data() {
        return {
            library: 'sequenceTemplates',
            tagTranslationPath: 'brands.categories.sequenceTemplates'
        };
    },

    computed: {
        ...mapState({
            sequenceIdPrefix: (state) => state.ui.prefixes.sequenceId,
            currentEditedItemId: (state) => state.ui.currentEditedItemId,
            selectedItemId: (state) => state.ui.currentLibrary.selectedItemId
        }),

        ...mapGetters({
            findLibraryItem: 'branding/libraries/findLibraryItem',
            isEmotionMode: 'ui/isEmotionMode'
        }),

        createsNewItem() {
            return this.isEmotionMode;
        },

        createsNewSequence() {
            return this.createIndex !== false;
        },

        selectedItemMetadata() {
            return this.findLibraryItem(this.library, null, this.selectedItemId) || null;
        },

        containerClasses() {
            return {
                disabled: this.disabled
            };
        },

        selectorId() {
            return this.id + '-selector';
        },

        labelClasses() {
            return {
                'visually-hidden': !this.showLabel
            };
        }
    },

    watch: {
        //
    },

    methods: {
        showLibrary() {
            this.startHistoryStep();

            if (this.createsNewSequence) {
                let id = getPrefixedUuid(this.sequenceIdPrefix);
                this.$store.dispatch('sequences/addEmptySequence', {
                    id: id,
                    index: this.createsNewSequence ? this.createIndex : null
                });
                this.$store.dispatch('ui/updateCurrentEditedItem', id);
            }

            this._savedState = _merge({}, this.$store.state.sequences[this.currentEditedItemId]);
            this.$emit(UI_LIBRARY_SELECTOR_SHOW, this);
        },

        hideLibrary() {
            this._savedState = null;
            this.$el.dispatchEvent(new Event(UI_LIBRARY_SELECTOR_HIDE));
        },

        select() {
            this.emitChangeEvent();
            this.hideLibrary();
        },

        cancel() {
            this.undo({ keep: false, save: false });
            if (!this.createsNewSequence) {
                this.$emit(UI_LIBRARY_SELECTOR_PREVIEW_CHANGE, null, this._savedState, true);
            }
            this.hideLibrary();
        },

        previewChange() {
            this.$emit(UI_LIBRARY_SELECTOR_PREVIEW_CHANGE, this.selectedItemMetadata?.data, this._savedState);
        },

        emitChangeEvent() {
            this.$emit(UI_LIBRARY_SELECTOR_CHANGE, this.selectedItemMetadata?.data, this._savedState);
        }
    },

    mounted() {
        this._savedState = null;
    }
};
</script>
