<template>
    <button
        class="ui-caption-button ui-caption-button-icon-only"
        :disabled="enableDropDown"
        :style="toggleStyle"
        @click="openQuickCutTimeline"
    >
        <svg-icon icon="scissors-icon" />
    </button>
</template>

<script>
import { computed, defineComponent } from 'vue';
import UiIcon from '../../../../../../components/UiIcon.vue';
import { useElement } from '../../../../../composables/useElement';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'UiAssetVideoTimeRangeSelector',
    components: {
        UiIcon
    },
    props: {
        seqId: String,
        elementId: String,
        cardElementId: String,
        readOnly: Boolean,
        iconOnly: Boolean
    },
    setup(props) {
        const { videoState, videoRange, maxDuration, videoTimerangeSegments, isRecordingCategory } = useElement(
            props.seqId,
            props.elementId
        );

        // Computed property to determine if the timerange selector should be active
        const isActiveTimerange = computed(() => {
            if (videoTimerangeSegments.value.length > 0) {
                return (
                    videoTimerangeSegments.value[0].start > 0 ||
                    videoTimerangeSegments.value[0].end != maxDuration.value
                );
            }
            return false;
        });

        const toggleStyle = computed(() => {
            return isActiveTimerange.value ? { color: 'var(--edit-mode-color)' } : {};
        });

        const isDropdownVisible = computed(() => videoState?.value);

        const enableDropDown = computed(() => {
            return props.readOnly || !videoState.value?.src || !maxDuration.value;
        });

        const store = useStore();

        function openQuickCutTimeline() {
            if (isRecordingCategory.value) {
                store.dispatch('ui/quickcut/openQuickCutForVisualRecording', {
                    seqId: props.seqId,
                    elementId: props.elementId
                });
            } else {
                store.dispatch('ui/quickcut/openQuickCutForVisual', { seqId: props.seqId, elementId: props.elementId });
            }
        }

        return {
            videoRange,
            isActiveTimerange,
            isDropdownVisible,
            toggleStyle,
            maxDuration,
            enableDropDown,
            openQuickCutTimeline
        };
    }
});
</script>
