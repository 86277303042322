<template>
    <button
        class="ui-caption-button ui-caption-button-icon-only"
        :disabled="enableDropDown"
        :style="toggleStyle"
        @click="openQuickCutTimeline"
    >
        <svg-icon icon="scissors-icon" />
    </button>
</template>

<script>
import { defineComponent, computed, ref } from 'vue';
import UiIcon from '../../../../../../components/UiIcon.vue';
import UiIntervalInput from '../../../../../../components/UiIntervalInput.vue';
import UiDropdown from '../../../../../../components/UiDropdown.vue';
import { useVoiceOver } from '../../../../../composables/useVoiceOver';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'UiVoiceOverTimeRangeSelector',
    components: {
        UiIcon,
        UiIntervalInput,
        UiDropdown
    },
    props: {
        useInSequence: Boolean,
        seqId: String,
        readOnly: Boolean,
        iconOnly: Boolean
    },
    setup(props) {
        const store = useStore();
        const useInSequence = ref(props.useInSequence);
        const seqId = ref(props.seqId);

        const { voiceOverMediaState, timerange } = useVoiceOver(useInSequence, seqId);

        const maxDuration = computed(() => voiceOverMediaState.value.totalDuration());

        // Computed property to determine if the timerange selector should be active
        const isActiveTimerange = computed(() => {
            const totalDurationRounded =
                Math.floor((voiceOverMediaState.value.totalDuration() + Number.EPSILON) * 100) / 100;

            return (
                voiceOverMediaState.value.src &&
                (timerange.value.start > 0 || (timerange.value.end < totalDurationRounded && timerange.value.end > -1))
            );
        });

        const toggleStyle = computed(() => {
            return isActiveTimerange.value ? { color: 'var(--edit-mode-color)' } : {};
        });

        const isDropdownVisible = computed(() => voiceOverMediaState?.value);

        const enableDropDown = computed(() => {
            return props.readOnly || !voiceOverMediaState?.value?.src || !maxDuration.value;
        });

        function openQuickCutTimeline() {
            store.dispatch('ui/quickcut/openQuickCutForTTs', props.seqId);
        }

        return {
            timerange,
            isActiveTimerange,
            isDropdownVisible,
            toggleStyle,
            maxDuration,
            enableDropDown,
            openQuickCutTimeline
        };
    }
});
</script>
