<template>
    <nav class="ui-layout-nav">
        <div class="nav-container">
            <div id="nav-logo">
                <RouterLink to="/">
                    <svg-id-randomized-icon icon="cte-logo" class="logo" />
                    <svg-id-randomized-icon icon="cte-logo-inline" class="hover-logo" />
                </RouterLink>
            </div>
            <div class="nav-items-list">
                <ui-nav-item :route="{ name: 'Home' }" :label="$t('Homepage')" icon="fa-house" />

                <ui-nav-create-video />

                <ui-nav-item name="folders" :link="false" :dropdown="true" :label="$t('Folders')" icon="fa-folders">
                    <ui-nav-sub-list parent-name="folders">
                        <ui-nav-folder-link-item v-for="folder in folders" :key="folder.id" :folder="folder" />
                        <ui-nav-item :route="{ name: 'Folders' }" :label="$t('See more')" icon="fa-circle-ellipsis" />
                    </ui-nav-sub-list>
                </ui-nav-item>

                <ui-nav-item name="medias" :route="{ name: 'Medias' }" :label="$t('Medias')" icon="fa-folder-image" />

                <ui-nav-item
                    v-if="isClientAdmin || isCTE"
                    name="brands"
                    :link="false"
                    :dropdown="true"
                    :label="$t('Brands')"
                    icon="fa-swatchbook"
                >
                    <ui-nav-sub-list parent-name="brands">
                        <ui-nav-item
                            v-for="brand in brands"
                            :key="brand.id"
                            :route="{ name: 'EditBrand', params: { id: brand.id } }"
                            :label="brand.name"
                            icon="fa-swatchbook"
                        />
                        <ui-nav-item :route="{ name: 'Brands' }" :label="$t('See more')" icon="fa-circle-ellipsis" />
                    </ui-nav-sub-list>
                </ui-nav-item>

                <ui-nav-item
                    name="learn"
                    :link="false"
                    :dropdown="true"
                    :label="$t('Academy')"
                    :hide-activation="true"
                    icon="fa-graduation-cap"
                >
                    <ui-nav-sub-list parent-name="learn">
                        <ui-nav-item :route="{ name: 'Academy' }" :label="$t('Resources center')" icon="fa-book" />
                        <ui-nav-item
                            :route="{ name: 'Elearning' }"
                            :label="$t('E-learning')"
                            icon="fa-head-side-gear"
                        />
                    </ui-nav-sub-list>
                </ui-nav-item>

                <div class="nav-separator" />

                <ui-nav-beamer-item v-if="user.client.enableNotificationPanel" />

                <ui-nav-item
                    v-if="isCTE || isClientAdmin"
                    name="params"
                    :route="{ name: 'EditClient' }"
                    :label="$t('Parameters')"
                    icon="fa-gear"
                />

                <ui-nav-item
                    v-if="user"
                    name="profile"
                    :link="false"
                    :label="userFullName"
                    icon="fa-user"
                    @click="editProfile()"
                />

                <ui-nav-item
                    v-if="user"
                    name="logout"
                    :link="false"
                    :label="$t('Logout')"
                    icon="fa-right-from-bracket"
                    @click="logout()"
                />
            </div>
        </div>
    </nav>
</template>

<script setup>
import { computed, onMounted, provide, ref } from 'vue';
import UiNavItem from '@/js/components/UiNavItem.vue';
import { useModal } from '@/js/composables/useModal.js';
import { useAuth } from '@/js/composables/useAuth.js';
import { useBrands } from '@/js/composables/useBrands.js';
import { useFolders } from '@/js/composables/useFolders.js';
import UiNavFolderLinkItem from '@/js/components/UiNavFolderLinkItem.vue';
import UiNavBeamerItem from '@/js/components/UiNavBeamerItem.vue';
import UiNavCreateVideo from '@/js/components/UiNavCreateVideo.vue';
import UiNavSubList from '@/js/components/UiNavSubList.vue';

const { openModal } = useModal();
const { user, isCTE, isClientAdmin, logout } = useAuth();

const openedNavItem = ref(null);

const openNavItem = (item) => {
    openedNavItem.value = item;
};

provide('openedNavItem', { openedNavItem, openNavItem });

const userFullName = computed(() => {
    return user.value ? `${user.value.firstName} ${user.value.lastName}` : '';
});

const editProfile = () => {
    openModal('EditProfileModal');
};

const { brands, fetchBrands } = useBrands();
const { folders, fetchFolders } = useFolders();

onMounted(() => {
    fetchFolders(user.value.client.shortname, { count: 3 });
    fetchBrands({ count: 3 });
});
</script>
