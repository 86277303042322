<template>
    <div class="gen-image-ai-options">
        <ui-dropdown
            id="gen-image-ai-format"
            :icon-only="true"
            :select="true"
            :scrollable="false"
            :disabled="disabled"
            :model-value="format"
            @update:modelValue="handleFormatSelection"
            :tooltip="$t('image-generation.format')"
        >
            <template #dropdown-toggle="{ instance }">
                <fa-icon class="icon" :icon="formatIcons[instance.selectedValue]" />
            </template>

            <ui-dropdown-item
                v-for="format in availableFormats"
                :key="format"
                :value="format"
                :label="$t(`image-generation.formats.${format}`)"
            />
        </ui-dropdown>

        <ui-dropdown
            id="gen-image-ai-style"
            class="gen-image-ai-style-dropdown"
            :icon-only="true"
            :select="true"
            :disabled="disabled"
            :model-value="style"
            @update:modelValue="handleStyleSelection"
            :tooltip="$t('image-generation.style')"
        >
            <template #dropdown-toggle="{ instance }">
                <img
                    v-if="instance.selectedValue !== styleNone"
                    :src="`/assets/thumbnails/image-generation/style-categories/${instance.selectedValue}.png`"
                    class="gen-image-ai-selected-image"
                    alt="image"
                />
                <fa-icon v-else icon="fa-regular fa-palette" />
            </template>

            <ui-dropdown-item
                v-for="(style, index) in availableStyles"
                :key="style"
                :value="style"
                :label="$t(`image-generation.styles.${style}`)"
                :style="getDropdownStyle(style)"
            />
        </ui-dropdown>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import UiDropdownItem from '../UiDropdownItem.vue';
import UiDropdown from '../UiDropdown.vue';

import { AVAILABLE_FORMATS, AVAILABLE_STYLES, FORMAT_ICONS, STYLE_NONE } from '../../constants/image-generation.js';

export default defineComponent({
    name: 'UiImageGenerationOptions',
    components: { UiDropdown, UiDropdownItem },

    props: {
        disabled: Boolean,
        style: String,
        format: String
    },

    emits: ['update:style', 'update:format'],

    data() {
        return {
            availableFormats: AVAILABLE_FORMATS,
            availableStyles: AVAILABLE_STYLES,
            styleNone: STYLE_NONE,
            formatIcons: FORMAT_ICONS
        };
    },

    setup(_, { emit }) {
        const handleStyleSelection = (value) => emit('update:style', value);
        const handleFormatSelection = (format) => emit('update:format', format);

        const getDropdownStyle = (style) => ({
            '--thumbnail': `url(/assets/thumbnails/image-generation/style-categories/${style}.png)`
        });

        return {
            handleStyleSelection,
            handleFormatSelection,
            getDropdownStyle
        };
    }
});
</script>
