import { printf as sprintf } from 'fast-printf';
import i18n from '@/libs/i18n';

export default class FormatUpdatedVideoDateService {
    handle(timestamp) {
        let date = new Date(timestamp * 1000);
        return sprintf(
            i18n.global.t('Updated on %1$s/%2$s/%3$s at %4$s:%5$s'),
            ('0' + date.getDate()).slice(-2),
            ('0' + (date.getMonth() + 1)).slice(-2),
            date.getFullYear(),
            ('0' + date.getHours()).slice(-2),
            ('0' + date.getMinutes()).slice(-2)
        );
    }
}
