import HorizontalRule from '@tiptap/extension-horizontal-rule';

const LineSeparator = HorizontalRule.extend({
    name: 'lineSeparator',

    addOptions() {
        return {
            enabled: true,
            HTMLAttributes: {}
        };
    },

    addCommands() {
        return {
            insertLineSeparator: this.parent?.().setHorizontalRule
        };
    }
});

LineSeparator.parseStudioMessage = (htmlContent, enabled = true, remove = false) => {
    return enabled || remove ? htmlContent.replace(/(<p[^>]*>)\s*;\s*<\/p>/g, !remove ? '<hr>' : '') : htmlContent;
};

LineSeparator.toStudioMessage = (htmlContent, enabled = true, remove = false) => {
    return enabled || remove ? htmlContent.replace(/<hr>/g, !remove ? '<p>;</p>' : '') : htmlContent;
};

LineSeparator.parseEditorMessage = (editor) => {
    let parsedContent = LineSeparator.parseStudioMessage(editor.getHTML());
    editor.commands.setContent(parsedContent, false, { preserveWhitespace: true });
};

export { LineSeparator, LineSeparator as default };
