import { Dimension, Timeline, Transition as TransitionConstants } from '../constants';
import gsap from 'gsap';
import { mapGetters, mapState } from 'vuex';

export default {
    props: {
        id: String,
        detection: Object,
        format: Object,
        currentSequences: Array
    },

    data() {
        return {
            active: false,
            needsRefresh: false,
            isAbove: false,
            swapElements: false,
            inElementsAutoAppear: true,
            keepActive: false
        };
    },

    computed: {
        ...mapState({
            sequence(state) {
                return state.sequences[this.id];
            },
            state(state) {
                return state.sequences[this.id].transition;
            }
        }),

        ...mapGetters({
            formatRatio: 'display/formatRatio'
        }),

        previous() {
            return this.$store.getters['sequences/' + this.id + '/previousVisible'];
        },

        containerID() {
            return this.id + '-transition';
        },

        selector() {
            return '#' + this.containerID;
        },

        classes() {
            return {
                'studio-active': this.currentSequences.indexOf(this.id) != -1 && this.active,
                'studio-above': this.isAbove
            };
        },

        sequenceInElement() {
            return this.sequence.el || this._emptyObjects.sequenceInElement;
        },
        sequenceInParentElement() {
            return (
                (this.sequenceInElement &&
                    this.sequenceInElement.closest &&
                    this.sequenceInElement.closest('.studio-sequence-content')) ||
                this._emptyObjects.sequenceInParentElement
            );
        },

        backgroundInElement() {
            return this.sequence.background.el || this._emptyObjects.backgroundInElement;
        },
        backgroundInParentElement() {
            return (
                (this.backgroundInElement &&
                    this.backgroundInElement.closest &&
                    this.backgroundInElement.closest('.studio-sequence-bg')) ||
                this._emptyObjects.backgroundInParentElement
            );
        },

        sequenceOutElement() {
            return (this.previous && this.previous.el) || this._emptyObjects.sequenceOutElement;
        },
        sequenceOutParentElement() {
            return (
                (this.sequenceOutElement &&
                    this.sequenceOutElement.closest &&
                    this.sequenceOutElement.closest('.studio-sequence-content')) ||
                this._emptyObjects.sequenceOutParentElement
            );
        },

        backgroundOutElement() {
            return (this.previous && this.previous.background.el) || this._emptyObjects.backgroundOutElement;
        },
        backgroundOutParentElement() {
            return (
                (this.backgroundOutElement &&
                    this.backgroundOutElement.closest &&
                    this.backgroundOutElement.closest('.studio-sequence-bg')) ||
                this._emptyObjects.backgroundOutParentElement
            );
        },

        elements() {
            return [
                this.sequenceInElement,
                this.backgroundInElement,
                this.sequenceOutElement,
                this.backgroundOutElement
            ];
        },

        inClipPathProxy: {
            get() {
                return this.sequenceInElement.style?.webkitClipPath || this.sequenceInElement.style?.clipPath || 'none';
            },
            set(value) {
                if (this.backgroundInElement.style)
                    this.backgroundInElement.style.clipPath = this.backgroundInElement.style.webkitClipPath = value;
                if (this.sequenceInElement.style)
                    this.sequenceInElement.style.clipPath = this.sequenceInElement.style.webkitClipPath = value;
            }
        },
        outClipPathProxy: {
            get() {
                return (
                    this.sequenceOutElement.style?.webkitClipPath || this.sequenceOutElement.style?.clipPath || 'none'
                );
            },
            set(value) {
                if (this.backgroundOutElement.style)
                    this.backgroundOutElement.style.clipPath = this.backgroundOutElement.style.webkitClipPath = value;
                if (this.sequenceOutElement.style)
                    this.sequenceOutElement.style.clipPath = this.sequenceOutElement.style.webkitClipPath = value;
            }
        }
    },

    watch: {
        format(newValue, oldValue) {
            if (newValue.width != oldValue.width || newValue.height != oldValue.height) this.updateTimeline();
        },

        elements: {
            handler(newValue, oldValue) {
                let hasChanged = newValue.reduce((changed, element, index) => {
                    return changed || element != oldValue[index];
                }, false);

                if (hasChanged) this.updateTimeline();
            },
            deep: true
        }
    },

    methods: {
        update(event) {
            this.updateTimeline();
        },

        updateTimeline() {
            this.$store.commit('sequences/' + this.id + '/setTransitionTimeline', () => this.getTimelineBase());
        },

        releaseTimeline() {
            gsap.set([this.backgroundInElement, this.sequenceInElement], {
                clearProps: 'opacity,visibility,transformOrigin,transform,clipPath,webkitClipPath'
            });
        },

        getTimeline() {
            return gsap.timeline();
        },

        getTimelineBase() {
            let t = this.getTimeline();
            t.vars.id = Timeline.TRANSITION_TIMELINE_ID;
            t.set(this, { active: true }, 0.0001);
            if (this.inElementsAutoAppear)
                t.set([this.backgroundInElement, this.sequenceInElement], { autoAlpha: 1 }, 0.0001);
            if (this.previous) {
                if (this.swapElements) {
                    t.set(
                        [this.backgroundOutParentElement, this.sequenceOutParentElement],
                        { zIndex: TransitionConstants.SWAP_ELEMENTS_INDEX },
                        0.0001
                    );
                    t.set([this.backgroundOutParentElement, this.sequenceOutParentElement], { zIndex: null });
                }
                t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 });
            }
            if (!this.keepActive) t.set(this, { active: false, inClipPathProxy: 'none', outClipPathProxy: 'none' });
            return t;
        },

        enableRefresh(enable) {
            this.$store.commit('sequences/' + this.id + '/enableTransitionRefresh', enable);
        },

        getClipPathTransform(width, height, cover) {
            let vr = width / height,
                fr = this.format.width / this.format.height,
                c = cover !== undefined ? cover : true,
                sw = 1,
                sh = 1,
                tx = 0,
                ty = 0,
                transform = [],
                zoom = 1;

            if (this.detection.browser.chrome || this.detection.browser.edgeChromium) zoom = window.devicePixelRatio;

            if (c ^ (vr < fr)) {
                sh = 1 / height;
                sw = sh / fr;
            } else {
                sw = 1 / width;
                sh = sw * fr;
            }
            tx = c ^ (vr < fr) ? (zoom * (this.format.width - (this.format.height / height) * width)) / 2 : 0;
            ty = c ^ (vr < fr) ? 0 : (zoom * (this.format.height - (this.format.width / width) * height)) / 2;

            if (tx != 0) transform.push('translateX(' + tx + Dimension.PIXEL_UNIT + ')');
            if (ty != 0) transform.push('translateY(' + ty + Dimension.PIXEL_UNIT + ')');
            if (sw != 1 || sh != 1) transform.push('scale(' + sw + ', ' + sh + ')');
            if (this.detection.browser.edge) transform.reverse();
            transform = transform.join(' ');

            return { transform };
        }
    },

    beforeCreate() {
        this._willUpdateTimeline = false;
        this._emptyObjects = {
            sequenceInElement: {},
            sequenceInParentElement: {},
            backgroundInElement: {},
            backgroundInParentElement: {},
            sequenceOutElement: {},
            sequenceOutParentElement: {},
            backgroundOutElement: {},
            backgroundOutParentElement: {}
        };
    },

    created() {
        this.enableRefresh(this.needsRefresh);
    },

    mounted() {
        this.updateTimeline();
    }
};
