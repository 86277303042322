import axios from 'axios';
import { STATUS_REFRESHING_TOKEN } from '../../constants/index.js';
import * as Sentry from '@sentry/browser';

const LOGIN_URL = import.meta.env.VITE_IDENTITY_URL;
const REFRESH_TOKEN_URL = LOGIN_URL + '/service-tokens/refresh';

const axiosInstance = axios.create({
    withCredentials: true
});

const handleUserNotConnected = () => {
    window.location.assign(LOGIN_URL);
};

const handleServerError = (store, error) => {
    Sentry.captureException(error);
    console.error(error);
};

const executeRequest = async (store, { request, onSuccess, onError, actionType }, disableRedirection) => {
    return await handleTokenRefresh(
        () =>
            request(axiosInstance).then((response) => {
                if (onSuccess) onSuccess(response, store);
                return response;
            }),
        store,
        disableRedirection
    );
};

const handleTokenRefresh = async (request, store, disableRedirection = false) => {
    try {
        return await request();
    } catch (error) {
        if ([401, 409, 419].includes(error.response?.status)) {
            return refreshServiceToken(store)
                .then(request)
                .catch(() => {
                    if (!disableRedirection) handleUserNotConnected();
                });
        } else {
            handleServerError(store, error);
            throw error;
        }
    }
};

const refreshServiceToken = async (store) => {
    await axios.get(REFRESH_TOKEN_URL, { withCredentials: true });
    if (store) store.commit('ui/setSaveStatus', STATUS_REFRESHING_TOKEN, { root: true });
};

export { refreshServiceToken, executeRequest };
