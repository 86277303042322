import { computed, ref, inject } from 'vue';

export function useTimeline(currentTimelineId, labels) {
    const videoStudio = inject('$videoStudio');

    const endSequencePauseOverride = ref(false);

    const currentTimelineEnd = computed(() => {
        const timeline = videoStudio.value.studio.$stage.getTimeline();
        return Number((labels.value[timeline.nextLabel(currentTimelineId.value)] - 0.001).toFixed(3));
    });

    function isEndSequencePause() {
        return Math.abs(currentTimelineEnd.value - videoStudio.value.studio.$stage.getTimeline().totalTime()) < 0.02;
    }

    function setEndSequencePause(value) {
        endSequencePauseOverride.value = value;
    }

    function toggleTimeline() {
        videoStudio.value.studio.$stage.toggleTimeline();
    }

    function playTimeline() {
        videoStudio.value.studio.$stage.playTimeline();
    }

    function pauseTimeline() {
        videoStudio.value.studio.$stage.pauseTimeline();
    }

    return {
        currentTimelineEnd,
        isEndSequencePause,
        setEndSequencePause,
        playTimeline,
        pauseTimeline,
        toggleTimeline
    };
}
