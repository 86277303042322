<template>
    <ui-card :id="cardElementId" :class="'ui-card-' + id" :title="$t('Footer')" :added-card-id="addedCardId">
        <template #header-icon>
            <svg-icon icon="footer-icon" />
        </template>
        <template #menu-content>
            <ui-dropdown-item :disabled="!canCopyCard" @click.prevent="copyCard">
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-copy" />
                    {{ $t('Copy') }}
                </template>
            </ui-dropdown-item>

            <ui-dropdown-item :disabled="!canPasteCard" @click.prevent="pasteCard">
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-clipboard" />
                    {{ $t('Paste') }}
                </template>
            </ui-dropdown-item>

            <ui-dropdown-item
                v-if="isEmotionMode"
                classes="ui-remove-item"
                :disabled="!canRemoveFooter"
                @click.prevent="removeCard"
            >
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-trash-can" />
                    {{ $t('Remove') }}
                </template>
            </ui-dropdown-item>
        </template>

        <div class="sequence-footer-text ui-card-row">
            <ui-text-editor
                :id="cardElementId + '-text'"
                v-model="footerText"
                :enable-font-size="false"
                :align="footerAlign"
                :enable-align-justify="false"
                :label="$t('Footer text')"
                :show-label="false"
                :disabled="readOnly"
                @[textEditorAlignChangeEvent]="footerAlign = $event"
            />
        </div>

        <template #footer>
            <ui-dropdown
                :id="cardElementId + '-timing'"
                class="centered timing-dropdown"
                :menu="false"
                :caret="false"
                :scrollable="false"
                menu-placement="bottom"
                :toggle-style="toggleTimingFooterStyle"
            >
                <template #dropdown-toggle>
                    <fa-icon class="icon" icon="fa-regular fa-stopwatch" />
                    <span>{{ $t('Timing') }}</span>
                </template>
                <ui-number-input
                    :id="cardElementId + '-start'"
                    class="ui-content-row"
                    v-model="footerStart"
                    :default-value="footerStartDefault"
                    :min="0"
                    :label="$t('Starting time')"
                    :disabled="readOnly"
                />
            </ui-dropdown>
        </template>
    </ui-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Align, Duration } from 'cte-video-studio';
import { FOOTER_ELEMENT_ID } from '../../../../../constants';
import { UI_TEXT_EDITOR_ALIGN_CHANGE } from '../../../../../components/UiTextEditor.vue';
import { SequenceCard } from '../../../../mixins';

export default {
    mixins: [SequenceCard],

    components: {
        //
    },

    props: {
        id: {
            type: String,
            default: FOOTER_ELEMENT_ID
        },

        addedCardId: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            textEditorAlignChangeEvent: UI_TEXT_EDITOR_ALIGN_CHANGE,
            footerStartDefault: Duration.FOOTER_START_DEFAULT,
            footerEndDefault: Duration.END_DEFAULT
        };
    },

    computed: {
        ...mapState({
            //
        }),

        ...mapGetters({
            //
        }),

        footerText: {
            get() {
                return this.sequenceState.options.footer.text;
            },
            set(value) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.sequenceStoreModulePath + '/setFooter', value);
                });
            }
        },

        footerAlign: {
            get() {
                return this.sequenceState.options.footer.align || Align.TEXT_LEFT;
            },
            set(value) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.sequenceStoreModulePath + '/setFooterAlign', value);
                });
            }
        },

        footerStart: {
            get() {
                return this.$store.getters[this.sequenceStoreModulePath + '/footerStart'];
            },
            set(value) {
                if (value != this.footerStart) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(this.sequenceStoreModulePath + '/setFooterStart', {
                            value,
                            custom: Duration.NONE
                        });
                    });
                }
            }
        },

        toggleTimingFooterStyle() {
            if(this.footerStart !== this.footerStartDefault) {
                return { color: 'var(--edit-mode-color)' };
            } else return {};
        }
    },

    watch: {
        //
    },

    methods: {
        removeCard() {
            this.saveHistoryStep(() => {
                this.$store.dispatch(this.sequenceStoreModulePath + '/showFooterCard', false);
            });
        }
    },

    mounted() {
        //
    }
};
</script>
