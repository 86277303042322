<!--
  -- Component name: Square6
  -- Type: Transition
  -- Uses: color.start
  -- Tags: color
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <polygon class="square-back-1" :fill="state.color.start" points="-1146,-430 1964,-979 2154,109 -949,660 " />
        <polygon
            class="square-back-2"
            :fill="state.color.start"
            points="-981.1,661.8 2128.9,112.8 2318.9,1200.8 -784.1,1751.8 "
        />
        <polygon class="square-front-1" :fill="state.color.start" points="-1146,-430 1964,-979 2154,109 -949,660 " />
        <polygon
            class="square-front-2"
            :fill="state.color.start"
            points="-981.1,661.8 2128.9,112.8 2318.9,1200.8 -784.1,1751.8 "
        />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            isAbove: true
        };
    },

    computed: {},

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 }, 0.001);
            t.set('#' + this.containerID + ' .square-back-1', { opacity: 0.5 }, 0);
            t.set('#' + this.containerID + ' .square-back-2', { opacity: 0.5 }, 0);

            t.fromTo(
                '#' + this.containerID + ' .square-front-1',
                { x: 0, y: 0 },
                { y: '-=622', x: '+=3500', duration: 1 },
                0
            );
            t.fromTo(
                '#' + this.containerID + ' .square-front-2',
                { x: 0, y: 0 },
                { y: '+=622', x: '-=3500', duration: 1 },
                0
            );

            t.fromTo(
                '#' + this.containerID + ' .square-back-1',
                { yPercent: 0 },
                { yPercent: -100, duration: 1 },
                0.42
            );
            t.fromTo('#' + this.containerID + ' .square-back-2', { yPercent: 0 }, { yPercent: 100, duration: 1 }, 0.42);
            return t;
        }
    }
};
</script>
