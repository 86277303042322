
const state = () => ({
    seqId: null,
    elementId: null,
    isQuickCutOpen: false,
    mediaType: null,
    mediaSrc: null,
});

const mutations = {
    SET_OPEN_QUICK_CUT(state, isOpen) {
        state.isQuickCutOpen = isOpen;
    },
    SET_SEQ_ID(state, seqId) {
        state.seqId = seqId;
    },
    SET_ELEMENT_ID(state, elementId) {
        state.elementId = elementId;
    },
    SET_MEDIA_TYPE(state, mediaType) {
        state.mediaType = mediaType;
    },
    SET_MEDIA_SRC(state, src) {
        state.mediaSrc = src;
    },
};

const actions = {
    openQuickCutForBackground({ commit }, seqId) {
        commit('SET_OPEN_QUICK_CUT', true);
        commit('SET_SEQ_ID', seqId);
        commit('SET_ELEMENT_ID', null);
        commit('SET_MEDIA_TYPE', 'backgroundVideo');
    },

    openQuickCutForVisual({ commit }, { seqId, elementId }) {
        commit('SET_OPEN_QUICK_CUT', true);
        commit('SET_SEQ_ID', seqId);
        commit('SET_ELEMENT_ID', elementId);
        commit('SET_MEDIA_TYPE', 'visual');
    },

    openQuickCutForAudio({ commit }, { seqId }) {
        commit('SET_OPEN_QUICK_CUT', true);
        commit('SET_SEQ_ID', seqId);
        commit('SET_ELEMENT_ID', null);
        commit('SET_MEDIA_TYPE', 'audio');
    },

    openQuickCutForTTs({ commit }, seqId ) {        
        commit('SET_OPEN_QUICK_CUT', true);
        commit('SET_SEQ_ID', seqId);
        commit('SET_MEDIA_TYPE', 'tts');
    },
    
    openQuickCutForRecording({ commit }, seqId ) {
        commit('SET_OPEN_QUICK_CUT', true);
        commit('SET_SEQ_ID', seqId);
        commit('SET_MEDIA_TYPE', 'recording');
    },

    openQuickCutForVisualRecording({ commit }, { seqId, elementId }) {
        commit('SET_OPEN_QUICK_CUT', true);
        commit('SET_SEQ_ID', seqId);
        commit('SET_ELEMENT_ID', elementId);
        commit('SET_MEDIA_TYPE', 'recordingVisual');
    },

    setMediaSrc({ commit }, src) {
        commit('SET_MEDIA_SRC', src);
    },

    closeQuickCut({ commit }) {
        commit('SET_OPEN_QUICK_CUT', false);
        commit('SET_SEQ_ID', null);
        commit('SET_ELEMENT_ID', null);
        commit('SET_MEDIA_TYPE', null);
    }
};

const getters = {
    getSeqId: (state) => state.seqId,
    getElementId: (state) => state.elementId,
    getMediaType: (state) => state.mediaType,
    getMediaSrc: (state) => state.mediaSrc,
    isOpenQuickCut: (state) => state.isQuickCutOpen
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
