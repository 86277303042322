export function useCanvasInteraction({ sequenceProps, segments, currentTimePos, canvasWidth }) {
   
    const onCanvasClick = (event, canvasElement) => {
        if (!canvasElement) return;

        const rect = canvasElement.getBoundingClientRect();
        const clickX = event.clientX - rect.left;
        const newTime = (clickX / canvasElement.width) * sequenceProps.endTime;

        const containerWidth = canvasWidth.value;
        currentTimePos.value = (newTime / sequenceProps.endTime) * containerWidth;

        let elapsedTime = 0;
        let lastSegmentEnd = 0;

        for (const segment of segments.value) {
            if (newTime < segment.start) {
                break;
            }

            if (newTime >= segment.start && newTime <= segment.end) {
                elapsedTime += newTime - segment.start;
                break;
            } else {
                elapsedTime += segment.end - Math.max(segment.start, lastSegmentEnd);
            }

            lastSegmentEnd = segment.end;
        }

        return Math.round((elapsedTime / sequenceProps.playbackRate) * 1000) / 1000;
    };

    return { onCanvasClick };
}
