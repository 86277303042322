<!--
  -- Component name: Square5
  -- Type: Transition
  -- Uses: color.start
  -- Tags: color
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <path class="square-back-1" :fill="state.color.start" d="M306.9-470.2l1137.8-203.6L1776 1194.6 638.2 1394.2z" />
        <path
            class="square-back-2"
            :fill="state.color.start"
            d="M-847.7-369.6L290.1-573.2l331.3 1868.3-1137.7 199.6z"
        />
        <path
            class="square-front-1"
            :fill="state.color.start"
            d="M306.9-470.2l1137.8-203.6L1776 1194.6 638.2 1394.2z"
        />
        <path
            class="square-front-2"
            :fill="state.color.start"
            d="M-847.7-369.6L290.1-573.2l331.3 1868.3-1137.7 199.6z"
        />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            isAbove: true
        };
    },

    computed: {},

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 }, 0.001);
            t.set('#' + this.containerID + ' .square-back-1', { opacity: 0.5 }, 0);
            t.set('#' + this.containerID + ' .square-back-2', { opacity: 0.5 }, 0);

            t.fromTo(
                '#' + this.containerID + ' .square-front-1',
                { x: 0, y: 0 },
                { x: '+=357', y: '+=2000', duration: 1 },
                0.2
            );
            t.fromTo(
                '#' + this.containerID + ' .square-front-2',
                { x: 0, y: 0 },
                { x: '-=357', y: '-=2000', duration: 1 },
                0.2
            );

            t.fromTo('#' + this.containerID + ' .square-back-1', { xPercent: 0 }, { xPercent: 100, duration: 1 }, 0.5);
            t.fromTo('#' + this.containerID + ' .square-back-2', { xPercent: 0 }, { xPercent: -100, duration: 1 }, 0.5);
            return t;
        }
    }
};
</script>
