<template>
    <ui-dropdown
        :id="'card-seq-' + cardElementId + '-duration'"
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="bottom"
        menu-strategy="fixed"
        :toggle-style="toggleStyle"
        :icon-only="iconOnly"
    >
        <template #dropdown-toggle>
            <fa-icon class="icon" icon="fa-regular fa-stopwatch" />
            <span v-if="iconOnly">{{ $t('Timing') }}</span>
        </template>
        <div class="sequence-duration ui-card-row">
            <ui-number-input
                :id="cardElementId + '-start'"
                class="ui-content-row"
                v-model="visualStart"
                :default-value="0"
                :min="0"
                :label="$t('Starting time')"
                :disabled="readOnly"
            />
            <ui-number-input
                :id="cardElementId + '-end'"
                class="ui-content-row"
                v-model="visualEnd"
                :default-value="visualEndDefault"
                :min="visualStart"
                :label="$t('Ending time')"
                :disabled="readOnly"
            />
        </div>
    </ui-dropdown>
</template>

<script>
import { useStore } from 'vuex';
import { defineComponent, computed } from 'vue';
import UiIcon from '../../../../../../components/UiIcon.vue';
import UiNumberInput from '../../../../../../components/UiNumberInput.vue';
import UiDropdown from '../../../../../../components/UiDropdown.vue';
import { useHistory } from '../../../../../composables/useHistory';
import { useElement } from '../../../../../composables/useElement';
import { useDisplay } from '../../../../../composables/useDisplay';

import { Duration } from 'cte-video-studio';

export default defineComponent({
    name: 'UiAssetDurationSelector',
    components: {
        UiIcon,
        UiNumberInput,
        UiDropdown,
        useDisplay
    },
    props: {
        cardElementId: String,
        seqId: String,
        elementId: String,
        readOnly: Boolean,
        iconOnly: Boolean
    },
    setup(props) {
        const store = useStore();
        const { saveHistoryStep } = useHistory();
        const { elementState, elementStoreModulePath, maxDuration } = useElement(props.seqId, props.elementId);

        const visualStart = computed({
            get: () => {
                return store.getters[elementStoreModulePath.value + '/start'];
            },
            set(value) {
                if (value != visualStart.value) {
                    saveHistoryStep(() => {
                        if (visualEnd.value != '' && value > visualEnd.value) {
                            store.commit(elementStoreModulePath.value + '/setEnd', {
                                value: value + (visualEnd.value - visualStart.value),
                                custom: Duration.NONE
                            });
                        }
                        store.commit(elementStoreModulePath.value + '/setStart', { value, custom: Duration.NONE });
                    });
                }
            }
        });

        const visualEnd = computed({
            get: () => {
                return store.getters[elementStoreModulePath.value + '/end'] != Duration.END_DEFAULT
                    ? store.getters[elementStoreModulePath.value + '/end']
                    : maxDuration.value;
            },
            set: (value) => {
                if (value != visualEnd.value) {
                    saveHistoryStep(() => {
                        store.commit(elementStoreModulePath.value + '/setEnd', { value, custom: Duration.NONE });
                    });
                }
            }
        });

        const toggleStyle = computed(() => {
            if (visualStart.value != 0 || (visualEnd.value != maxDuration.value && visualEnd.value != 0))
                return { color: 'var(--edit-mode-color)' };
        });

        return {
            visualStart,
            visualEnd,
            toggleStyle,
            visualEndDefault: Duration.END_DEFAULT
        };
    }
});
</script>
