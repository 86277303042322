<template>
    <ui-card
        :id="id"
        :class="'ui-card-' + id"
        class="ui-card-components-library"
        :title="title"
        :title-data="libraryItems.length.toString()"
        :accordion-id="accordionId"
        content-class="ui-library show"
    >
        <template #header-icon>
            <fa-icon v-if="iconType === 'fa'" class="icon" :icon="icon" />
            <svg-icon v-if="iconType === 'svg'" :icon="icon" />
        </template>

        <template #header-tools>
            <ui-switch-button
                v-if="groupSwitch && !!libraryItems.length"
                :id="id + '-group-switch'"
                :show-label="false"
                :label="libraryStatusLabel"
                :tooltip="true"
                :disabled="readOnly"
                v-model="libraryHasActiveItems"
                @click.stop
            />
        </template>

        <template #default="{ instance }">
            <ui-scrollable class="ui-library-inner" ref="$scrollable" :disabled="isSaving">
                <ul class="ui-library-tags">
                    <li
                        v-for="tag in libraryTags"
                        :key="'library-tag-' + tag"
                        :id="id + '-library-tag-' + tag"
                        class="ui-library-tag"
                        @transitionend="resizeScrollable"
                    >
                        <div v-if="hasTags" class="ui-library-tag-header">
                            <h4>{{ $t('studio.tags.' + tag) }}</h4>
                            <div
                                v-if="librarySortedByTag[tag].length > libraryItemColumns"
                                class="ui-library-tag-toggle"
                            >
                                <input
                                    type="checkbox"
                                    :id="id + '-library-tag-' + tag + '-toggle'"
                                    :checked="expandedTag == tag"
                                    @change="toggleTag(tag)"
                                />
                                <label :for="id + '-library-tag-' + tag + '-toggle'">
                                    <span v-show="expandedTag != tag" class="expand">{{ $t('See more') }}</span>
                                    <span v-show="expandedTag == tag" class="reduce">{{ $t('Reduce') }}</span>
                                    <fa-icon class="icon" icon="fa-solid fa-chevron-right" />
                                </label>
                            </div>
                        </div>
                        <ul ref="$libraryItems" class="ui-library-items with-status" :style="itemsStyles[tag]">
                            <li v-for="item in librarySortedByTag[tag]" :key="'library-item-' + item.name">
                                <fieldset
                                    :id="id + '-library-item-' + tag + '-' + item.id"
                                    class="ui-library-item"
                                    :class="'ui-library-item-status-' + item.status"
                                    :style="item.styles"
                                    :disabled="readOnly"
                                >
                                    <legend class="visually-hidden">
                                        {{ sprintf($t('Change %1$s activation status'), item.name) }}
                                    </legend>
                                    <template v-for="status in allowedLibraryStatus">
                                        <input
                                            type="radio"
                                            :id="id + '-library-item-' + tag + '-' + item.id + '-status-' + status"
                                            :name="id + '-library-item-' + tag + '-' + item.id + '-status'"
                                            :value="status"
                                            :checked="item.status == status"
                                            aria-hidden="true"
                                        />
                                        <label
                                            :for="id + '-library-item-' + tag + '-' + item.id + '-status-' + status"
                                            aria-hidden="true"
                                        >
                                            {{ $t('brands.status.' + status + '.label') }}
                                        </label>
                                    </template>
                                    <fa-icon
                                        icon="fa-solid fa-eye-slash"
                                        class="icon status-hidden-icon"
                                        aria-hidden="true"
                                    />
                                </fieldset>
                                <button
                                    v-if="!readOnly"
                                    @click.prevent="setItemNextStatus(item, $event)"
                                    :tabindex="!instance.isActiveAccordionItem ? -1 : null"
                                >
                                    <span class="visually-hidden">
                                        {{ $t('brands.status.' + nextLibraryStatus[item.status] + '.action') }}
                                    </span>
                                </button>
                                <span class="ui-library-item-index">{{ item.tagIndexes[tag] + 1 }}</span>
                            </li>
                        </ul>
                    </li>
                </ul>
            </ui-scrollable>
        </template>
    </ui-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import _isArray from 'lodash/isArray';
import _throttle from 'lodash/throttle';
import { printf as sprintf } from 'fast-printf';
import {
    LIBRARY_STATUS_HIDDEN,
    LIBRARY_STATUS_ACTIVABLE,
    LIBRARY_STATUS_ACTIVE,
    COMPONENTS_BRAND_PANEL_ID
} from '../../../../../constants';
import { BrandCard } from '../../../../mixins';

const LIBRARY_NEW_TAG = 'new';
const DEFAULT_CARD_ICON = 'components-library-icon';

export default {
    mixins: [BrandCard],

    components: {
        //
    },

    props: {
        title: {
            type: String,
            default: ''
        },
        library: {
            type: String,
            default: ''
        },
        category: {
            type: String,
            default: ''
        },
        previewPath: {
            type: String,
            default: ''
        },
        accordionId: {
            type: String,
            default: ''
        },
        groupSwitch: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: DEFAULT_CARD_ICON
        },
        iconType: {
            type: String,
            default: 'fa'
        }
    },

    data() {
        return {
            libraryStatusHidden: LIBRARY_STATUS_HIDDEN,
            libraryStatusActivable: LIBRARY_STATUS_ACTIVABLE,
            libraryStatusActive: LIBRARY_STATUS_ACTIVE,

            expandedTag: null,
            libraryItemColumns: -1
        };
    },

    computed: {
        ...mapState({
            currentPanel: (state) => state.ui.currentPanel,
            libraries: (state) => state.branding.libraries,
            libraryTagOrders: (state) => state.branding.libraries.tagOrders
        }),

        ...mapGetters({
            canHideElement: 'ui/canHideElement',
            isSaving: 'ui/isSaving',
            getLibraryItems: 'branding/libraries/libraryItems',
            getLibrarySortedByTag: 'branding/libraries/librarySortedByTag',
            getLibraryTags: 'branding/libraries/libraryTags'
        }),

        allowedLibraryStatus() {
            let status = [];
            if (this.canHideElement) status.push(LIBRARY_STATUS_HIDDEN);
            if (!this.state.isMaster) status.push(LIBRARY_STATUS_ACTIVABLE);
            status.push(LIBRARY_STATUS_ACTIVE);

            return status;
        },

        nextLibraryStatus() {
            return this.allowedLibraryStatus.reduce((next, status, index) => {
                next[status] =
                    this.allowedLibraryStatus[
                        (index + this.allowedLibraryStatus.length - 1) % this.allowedLibraryStatus.length
                    ];
                return next;
            }, {});
        },

        libraryTagOrder() {
            return this.libraryTagOrders[this.library];
        },

        libraryItems() {
            return this.getLibraryItems(this.library, this.category);
        },

        libraryHasActiveItems: {
            get() {
                return !!this.libraryItems.find((item) => item.status == LIBRARY_STATUS_ACTIVE);
            },
            set(activate) {
                if (!!this.libraryItems.length && this.allowedLibraryStatus.length > 1) {
                    const inactiveStatus = this.allowedLibraryStatus.includes(LIBRARY_STATUS_ACTIVABLE)
                        ? LIBRARY_STATUS_ACTIVABLE
                        : LIBRARY_STATUS_HIDDEN;
                    const nextStatus = activate ? LIBRARY_STATUS_ACTIVE : inactiveStatus;

                    this.modifyBrand(() => {
                        this.libraryItems.forEach((item) => {
                            // We only mass activate/deactivate non-hidden components
                            if (
                                (activate && item.status == inactiveStatus) ||
                                (!activate && item.status == LIBRARY_STATUS_ACTIVE)
                            )
                                this.setLibraryItemStatus({ library: this.library, id: item.id, status: nextStatus });
                        });
                    });
                }
            }
        },

        libraryStatusLabel() {
            return this.libraryHasActiveItems ? this.$t('Disable all components') : this.$t('Enable all components');
        },

        librarySortedByTag() {
            return Object.entries(this.getLibrarySortedByTag(this.library, this.category)).reduce(
                (sorted, [tag, items]) => {
                    sorted[tag] = items
                        .reduce((modifiedItems, item, index) => {
                            item = { ...item };

                            // music thumbnails are in PNG format
                            const thumbnailExtension = this.library === 'musics' ? 'png' : 'gif';
                            item.styles = {
                                '--item-thumbnail': `url(/assets/thumbnails/${this.previewPath + item.name}.${thumbnailExtension})`
                            };

                            modifiedItems.push(item);

                            return modifiedItems;
                        }, [])
                        .sort((itemA, itemB) => itemA.tagIndexes[tag] - itemB.tagIndexes[tag]);

                    return sorted;
                },
                {}
            );
        },

        libraryTags() {
            let compareTagFunction =
                !this.libraryTagOrder || (_isArray(this.libraryTagOrder) && !this.libraryTagOrder.length)
                    ? this.compareTags
                    : !_isArray(this.libraryTagOrder)
                      ? this.libraryTagOrder
                      : (tagA, tagB) => this.libraryTagOrder.indexOf(tagA) - this.libraryTagOrder.indexOf(tagB);

            return this.getLibraryTags(this.library, this.category).sort(compareTagFunction);
        },

        hasTags() {
            return !!this.getLibraryTags(this.library, this.category).length;
        },

        itemsStyles() {
            return Object.entries(this.librarySortedByTag).reduce((styles, [tag, items]) => {
                styles[tag] = {
                    '--item-rows-total':
                        this.expandedTag == tag ? Math.ceil(items.length / this.libraryItemColumns) : null
                };
                return styles;
            }, {});
        }
    },

    watch: {
        currentPanel(newValue) {
            if (newValue == COMPONENTS_BRAND_PANEL_ID) {
                this.$nextTick(this._throttledUpdateItemColumns);
                window.addEventListener('resize', this._throttledUpdateItemColumns);
            } else {
                window.removeEventListener('resize', this._throttledUpdateItemColumns);
            }
        }
    },

    methods: {
        sprintf,

        updateItemColumns() {
            if (!!this.$refs.$libraryItems) {
                let cssTemplateColumns = window.getComputedStyle(this.$refs.$libraryItems[0]).gridTemplateColumns;
                // Note: Remove '0px' string that browsers sometimes add at the end
                this.libraryItemColumns = cssTemplateColumns.replace(/ 0px/, '').split(' ').length;
            }
        },

        toggleTag(tag) {
            this.expandedTag = this.expandedTag != tag ? tag : null;
        },

        resizeScrollable(event) {
            if (event.propertyName == 'max-height') {
                this.$refs.$scrollable.refresh();
            }
        },

        setItemNextStatus(item, event) {
            this.modifyBrand(() => {
                this.setLibraryItemStatus({
                    library: this.library,
                    id: item.id,
                    status: this.nextLibraryStatus[item.status]
                });
            });
        },

        compareTags(tagA, tagB) {
            if (tagA == LIBRARY_NEW_TAG) return -1;
            if (tagB == LIBRARY_NEW_TAG) return 1;
            return this.$t('studio.tags.' + tagA).localeCompare(this.$t('studio.tags.' + tagB), this.shortLanguage);
        }
    },

    created() {
        this._throttledUpdateItemColumns = _throttle(this.updateItemColumns, 200);
    },

    mounted() {
        this._throttledUpdateItemColumns();
    },

    beforeUnmount() {
        window.removeEventListener('resize', this._throttledUpdateItemColumns);
    }
};
</script>
