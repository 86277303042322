<template>
    <div class="studio">
        <stage
            ref="stage"
            :autoplay="autoplay"
            :preview="preview"
            :capture-mode="captureMode"
            @[stageLoadingEndedEvent]="startDebugging"
            @[stageLoadingStarted]="startLoading"
        ></stage>
    </div>
</template>

<script>
import { Production } from '../constants';
import { getLoadingProductionSequence, STAGE_LOADING_ENDED_EVENT,STAGE_LOADING_STARTED_EVENT } from '../utils';
import Stage from './Stage.vue';
import { mapState } from 'vuex';

export default {
    components: {
        Stage
    },

    props: {
        deferStore: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            stage: null,
            stageLoadingEndedEvent: STAGE_LOADING_ENDED_EVENT,
            stageLoadingStarted: STAGE_LOADING_STARTED_EVENT
        };
    },

    computed: {
        ...mapState({
            preview: (state) => state.display.preview,
            autoplay: (state) => state.display.timeline.autoplay,
            captureMode: (state) => state.display.captureMode
        })
    },

    methods: {
        startLoading() {
            this.$store.dispatch('loading/setStudioLoading', true);
        },

        // debugging available when video studio has been loaded and in production mode
        startDebugging() {
            this.$store.dispatch('loading/setStudioLoading', false);
            
            const urlParams = new URLSearchParams(window.location.search);

            if (this.captureMode && urlParams.has('seqId') && urlParams.has('messageIndex') && urlParams.has('exp')) {
                const seqId = urlParams.get('seqId');
                const messageIndex = urlParams.get('messageIndex');

                const sequence = document.getElementById(`seq-${seqId}-content`);
                const message = sequence?.querySelectorAll('[data-message-id]')?.[messageIndex];
                const fontSize = parseFloat(message?.querySelector('.studio-message')?.style.fontSize);

                // font size must be found
                if (!fontSize) return alert('Font size not found');

                const expected = parseFloat(urlParams.get('exp'));
                const DELTA = 0.02; // in percent
                const minAcceptableValue = fontSize * (1 - DELTA);
                const maxAcceptableValue = fontSize * (1 + DELTA);

                console.info('Expected -', expected);
                console.info('Minimum value -', minAcceptableValue);
                console.info('Maximum value -', maxAcceptableValue);

                // reload page if expected value is correct
                if (expected > maxAcceptableValue || expected < minAcceptableValue) {
                    alert('ERROR FOUND');
                } else {
                    window.location.reload();
                }
            }
        }
    },

    created() {
        /*if (!this.deferStore) {
            // Initialize Vuex store
            this.$store.dispatch('display/setReadOnly', !!window.videoData.readOnly);

            this.$store.dispatch('display/setFormat', {
                width: window.videoData.display.format.width,
                height: window.videoData.display.format.height
            });

            this.$store.dispatch('branding/init', window.videoData.branding);
            this.$store.dispatch('settings/init', window.videoData.settings);

            // In production mode only: insert an first empty sequence to avoid lag on first video
            if (!this.preview && this.captureMode != Production.FRAME_CAPTURE_MODE) {
                this.$store.dispatch('sequences/addSequence', {
                    item: getLoadingProductionSequence()
                });
            }

            let i, p;
            for (i = 0; i < window.videoData.sequences.length; ++i) {
                this.$store.dispatch('sequences/addSequence', {
                    item: window.videoData.sequences[i]
                });
            }

            if (window.videoData.display.previewStart !== undefined)
                this.$store.dispatch('display/setPreviewStart', window.videoData.display.previewStart);
        }*/
    },

    mounted() {
        this.$stage = this.$refs.stage;
    }
};
</script>
