import { executeRequest } from '@/js/infrastructure/api/apiClient.js';

const API_URL = import.meta.env.VITE_EDITION_API_URL;

export default class FetchBrandsApiService {
    constructor(store) {
        this.store = store;
    }

    fetchBrands(options = {}) {
        const { page = 1, search = '', count = 10 } = options;

        const queryParams = new URLSearchParams({
            page: page.toString(),
            count: count.toString()
        });

        if (search) queryParams.append('search', search);

        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${API_URL}/api/brands?expand=logo&${queryParams.toString()}`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'fetchBrands'
        });
    }

    fetchBrandResources(brandId) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${API_URL}/api/brands/${brandId}/resources`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'fetchBrandResources'
        });
    }
}
