<template>
    <ui-card :id="id" :class="'ui-card-' + id" :title="$t('Brand fonts')">
        <template #header-icon>
            <svg-icon icon="message-icon" />
        </template>

        <div class="default-font-selection ui-card-row" :class="{ disabled: readOnly }">
            <label>{{ $t('Default font') }}</label>
            <ui-dropdown
                id="settings-default-font"
                :select="true"
                v-model="defaultFont"
                :disabled="readOnly"
                :toggle-style="getDefaultFontStyles(defaultFont)"
            >
                <ui-dropdown-item
                    v-for="font in activeFontLibrary"
                    :key="font.name"
                    :value="font.name"
                    :disabled="font.disabled"
                    :style="getDefaultFontStyles(font.name)"
                />
            </ui-dropdown>
        </div>

        <div class="allowed-font-selection ui-card-row" :class="{ disabled: readOnly }">
            <label>{{ $t('Allowed fonts') }}</label>
            <ui-dropdown id="settings-allowed-fonts" :select="true" :multiple="true" :disabled="readOnly">
                <template #dropdown-toggle>
                    {{ allowedFontsLabel }}
                </template>
                <template #dropdown-menu-top>
                    <ui-text-input
                        id="settings-allowed-fonts-filter"
                        type="search"
                        :placeholder="$t('Search a font')"
                        :show-label="false"
                        icon="search-icon"
                        v-model="fontLibraryFilter"
                    />
                </template>
                <ui-dropdown-library-item
                    v-for="font in filteredSortedFontLibrary"
                    :key="font.name"
                    :item="font"
                    :hideable="canHideElement"
                    :activable="!state.isMaster"
                    :disabled="font.name == baseDefaultFont"
                    :style="getDefaultFontStyles(font.name)"
                    @[dropdownLibraryItemChangeEvent]="handleChangeFontStatus"
                />
            </ui-dropdown>
        </div>
    </ui-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import _debounce from 'lodash/debounce';
import { Font } from 'cte-video-studio';
import { LIBRARY_STATUS_ACTIVE } from '@/js/constants/index.js';
import UiDropdownLibraryItem, { UI_DROPDOWN_LIBRARY_ITEM_CHANGE } from '../UiDropdownLibraryItem.vue';
import { BrandCard } from '../../../../mixins';

export default {
    mixins: [BrandCard],

    components: {
        UiDropdownLibraryItem
    },

    props: {
        id: {
            type: String,
            default: 'settings-fonts'
        }
    },

    data() {
        return {
            dropdownLibraryItemChangeEvent: UI_DROPDOWN_LIBRARY_ITEM_CHANGE,
            baseDefaultFont: Font.DEFAULT,
            fontFilter: ''
        };
    },

    computed: {
        ...mapState({
            fontLibrary: (state) => state.branding.libraries.fonts
        }),

        ...mapGetters({
            canHideElement: 'ui/canHideElement'
        }),

        sortedFontLibrary() {
            return this.fontLibrary
                .slice()
                .sort((fontA, fontB) => fontA.name.localeCompare(fontB.name, this.shortLanguage));
        },

        filteredSortedFontLibrary() {
            if (!/\S/.test(this.fontLibraryFilter)) return this.sortedFontLibrary;

            let filters = this.fontLibraryFilter.split(/\s+/).filter((f) => f.length > 1);
            return this.sortedFontLibrary.filter((font) =>
                filters.every((filter) => font.name.toLowerCase().includes(filter.toLowerCase()))
            );
        },

        activeFontLibrary() {
            return this.sortedFontLibrary.filter((font) => font.status == LIBRARY_STATUS_ACTIVE);
        },

        defaultFont: {
            get() {
                return this.$store.getters['branding/defaultFont'];
            },
            set(font) {
                this.modifyBrand(() => {
                    this.$store.commit('branding/setFont', { value: font, custom: null });
                });
            }
        },

        defaultFontId() {
            return this.activeFontLibrary.find((font) => font.name == this.defaultFont)?.id;
        },

        allowedFontsLabel() {
            let fontLabels = this.activeFontLibrary.map((font) => font.name);
            return fontLabels.length > 1
                ? this.$t('Several')
                : !!fontLabels.length
                  ? fontLabels[0]
                  : this.$t('None selected');
        },

        fontLibraryFilter: {
            get() {
                return this.fontFilter;
            },
            set(filter) {
                this._debouncedSetFontFilter(filter);
            }
        }
    },

    watch: {
        //
    },

    methods: {
        setFontFilter(filter) {
            this.fontFilter = filter;
        },

        handleChangeFontStatus(status, font) {
            this.modifyBrand(() => {
                this.setLibraryItemStatus({ library: 'fonts', id: font.id, status });
                // Note: Check if removed font was the current default font, and replace it appropriately if it is
                if (this.defaultFontId == null) this.defaultFont = Font.DEFAULT;
            });
        },

        getDefaultFontStyles(font) {
            return {
                '--font-thumbnail': `url(/assets/thumbnails/fonts/${font}.gif)`
            };
        }
    },

    created() {
        this._debouncedSetFontFilter = _debounce(this.setFontFilter, 600);
    },

    mounted() {
        //
    }
};
</script>
