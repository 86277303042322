/**
 * Video Studio
 * Internationalization data Vuex store module
 */

// Initial state
const state = {
    warnings: {
        'internal-error': 'An error occured while constructing the video', // 'Une erreur a eu lieu lors de la construction de la vidéo',
        'no-sequences': "The video doesn't contain any visible sequence" // La vidéo ne comporte aucune séquence visible'
    }
};

// Getters
const getters = {};

// Mutations
const mutations = {};

// Actions
const actions = {};

export default {
    namespaced: true,

    state,
    getters,
    mutations,
    actions
};
