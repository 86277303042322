<!--
  -- Component name: Number3
  -- Type: Transition
  -- Uses: color.start
  -- Tags: number
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <defs>
            <mask :id="svgMaskID">
                <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
                <path
                    class="color-number"
                    d="M672,258c-6.1,0-11.5,0-17,0c-31.5,0-63.1-0.1-94.6,0.1c-3.9,0-5.1-1.2-5-5c0.2-8,0.2-16.1,0-24.1
				c-0.1-3,1-3.9,4-3.9c54.3,0.1,108.6,0.1,162.9,0.1c0.9,0,1.8,0.1,3.3,0.2c0,3.6,0,7.1,0,10.6c0,2.3-0.3,4.7,0.1,6.9
				c1.3,8.2-2,14-7.9,19.7c-12,11.6-23.3,24-34.8,36.1c-11,11.6-21.8,23.3-32.7,34.9c-0.4,0.4-0.8,0.9-0.9,1.1
				c8.6,1.3,17.5,1.8,25.9,4c22.7,6,41.5,17.6,52,39.8c4.4,9.4,6,19.3,6.2,29.7c0.2,14.5-1.5,28.5-7.4,41.8
				c-8.2,18.5-22.5,30.3-41.1,37.3c-12.1,4.5-24.7,7.2-37.6,7.7c-9.9,0.4-19.9,1.2-29.8,0.6c-20.5-1.1-40.8-3.2-60.8-8.2
				c-2.4-0.6-2.9-1.6-2.7-3.7c1-9,2.1-17.9,3.1-26.9c0.2-2.1,1.5-2.7,3.5-2.3c10.5,2.1,20.9,4.3,31.4,6.2c5.5,1,11.1,1.6,16.7,1.9
				c7.5,0.5,15,1.1,22.5,0.7c12.5-0.5,24.9-2.2,36-8.7c13.8-8.1,20.7-20.5,21.5-36.2c0.3-6,0.3-12.2-0.7-18.1
				c-2.8-16.4-13.1-26.7-28.8-30.9c-8.8-2.4-18-3.3-27.1-4c-9.5-0.7-19.2-0.2-28.7-0.1c-3,0-4.4-0.8-4.3-4
				c0.2-6.9-0.1-13.8,0.2-20.7c0.1-1.8,1-4,2.2-5.4c9.6-10.6,19.4-21,29.1-31.5c13.6-14.8,27-29.6,40.5-44.4
				C671.4,259.2,671.5,258.8,672,258z"
                />
            </mask>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" :fill="state.color.start" :mask="svgMask" />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {
        svgMaskID() {
            return this.containerID + '-number3-mask';
        },

        svgMask() {
            return 'url(#' + this.svgMaskID + ')';
        }
    },

    watch: {
        svgMaskRadius: {
            handler() {
                this.updateTimeline();
            }
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            t.fromTo(
                '#' + this.svgMaskID + ' .color-number',
                { scale: 1 },
                { scale: 1.5, transformOrigin: '50% 50%', duration: 1 },
                0
            );
            t.fromTo(
                '#' + this.svgMaskID + ' .color-number',
                { scale: 1.5 },
                { scale: 50, translateX: '-100%', transformOrigin: '50% 50%', duration: 0.2 },
                1
            );
            return t;
        }
    }
};
</script>
