<template>
    <div class="ui-scrollable" :class="scrollableClasses" role="none" @[wheelEvent]="updateScroll">
        <div
            class="ui-scrollable-container"
            ref="$scrollableContainer"
            @scroll="_throttledUpdateHandlePosition"
            @[wheelEvent].stop=""
            role="none"
            tabindex="-1"
        >
            <slot></slot>
        </div>
        <div
            v-show="!disabled && !!scrollMax"
            class="ui-scrollable-handle"
            ref="$scrollableHandle"
            :style="handleStyles"
            role="none"
            @mousedown.left="startHandleMove"
            @[wheelEvent]="updateScroll"
        ></div>
    </div>
</template>

<script>
import _throttle from 'lodash/throttle';
import { fastdom } from 'cte-video-studio';

export const UI_SCROLLABLE_ENDED_EVENT = 'ui-scrollable-ended';

const HANDLE_MIN_HEIGHT = 20; // In pixels
const END_SCROLL_THRESHOLD = 0.98; // Percentage of scroll considered to have reach the end

export default {
    inject: {
        scrollEmitters: { default: [] }
    },

    provide() {
        return {
            scrollEmitters: [this, ...this.scrollEmitters]
        };
    },

    props: {
        scrollFrom: {
            type: Array,
            default: () => []
        },
        marginTop: {
            type: Number,
            default: 0
        },
        marginBottom: {
            type: Number,
            default: 0
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            handleMoving: false,
            handleHeight: 1,
            handleTop: 0,
            scrollMax: 0,
            scrollableHeight: 0,
            reachedScroll: 0,
            cardSpacing: 15 // in pixels
        };
    },

    computed: {
        scrollableClasses() {
            return {
                'ui-scrollable-moving': this.handleMoving,
                disabled: this.disabled
            };
        },

        handleStyles() {
            return {
                top: this.marginTop + 'px',
                height: this.handleHeight * this.scrollableHeight - this.marginTop - this.marginBottom + 'px',
                transform: 'translate(0, ' + this.handleTop + 'px)'
            };
        },

        wheelEvent() {
            return !this.disabled && !!this.scrollMax ? 'wheel' : null;
        }
    },

    watch: {
        reachedScroll(newValue, oldValue) {
            if (newValue >= END_SCROLL_THRESHOLD && oldValue < END_SCROLL_THRESHOLD) {
                // use CustomEvent to pass data if needed
                this.$el.dispatchEvent(new Event(UI_SCROLLABLE_ENDED_EVENT));
            }
        }
    },

    methods: {
        async resizeHandle() {
            await fastdom.measure(async () => {
                // Note: The component may have been unmounted between
                // registering this call, and executing this call
                if (!this.$refs.$scrollableContainer) return;

                let scrollableHeight = this.$refs.$scrollableContainer.clientHeight,
                    scrollHeight = this.$refs.$scrollableContainer.scrollHeight,
                    scrollTop = this.$refs.$scrollableContainer.scrollTop;

                this.scrollableHeight = scrollableHeight;
                this.handleHeight = Math.min(
                    1,
                    Math.max(
                        (HANDLE_MIN_HEIGHT + this.marginTop + this.marginBottom) / this.scrollableHeight,
                        this.scrollableHeight / scrollHeight
                    )
                );
                this.scrollMax = Math.max(0, scrollHeight - this.scrollableHeight);
                this.reachedScroll = Math.max(0, Math.min(scrollTop / this.scrollMax, 1));
            });
        },

        async updateHandlePosition() {
            await fastdom.measure(async () => {
                // Note: The component may have been unmounted between
                // registering this call, and executing this call
                if (!this.$refs.$scrollableContainer) return;

                let scrollTop = this.$refs.$scrollableContainer.scrollTop;

                this.handleTop =
                    this.scrollMax > 0
                        ? (this.scrollableHeight * (1 - this.handleHeight) * scrollTop) / this.scrollMax
                        : 0;
                this.reachedScroll = Math.max(0, Math.min(scrollTop / this.scrollMax, 1), this.reachedScroll);
                this.$emit('scroll');
            });
        },

        updateScroll(event) {
            event.preventDefault();
            event.stopImmediatePropagation();
            this.stopHandleMove();
            this.$refs.$scrollableContainer.scrollTop += event.deltaY;
            this.showHandle();
        },

        showHandle() {
            clearTimeout(this._handleMovingTimeoutId);
            this.handleMoving = true;
            // Note: We use setTimeout() instead of $nextTick() to allow an easy cancellation.
            // There is no specified delay in order to set the callback on next browser tick
            this._handleMovingTimeoutId = setTimeout(this.hideHandle);
        },

        hideHandle() {
            clearTimeout(this._handleMovingTimeoutId);
            this.handleMoving = false;
        },

        startHandleMove(event) {
            event.preventDefault();
            clearTimeout(this._handleMovingTimeoutId);
            this.handleMoving = true;
            this._referenceHandleTop = JSON.parse(JSON.stringify(this.handleTop));
            this._referenceClientY = event.clientY;
            document.addEventListener('mouseup', this.stopHandleMove);
            document.addEventListener('mousemove', this._throttledDoHandleMove);
        },

        doHandleMove(event) {
            event.preventDefault();
            this.handleTop = Math.max(
                0,
                Math.min(
                    this._referenceHandleTop + event.clientY - this._referenceClientY,
                    this.scrollableHeight * (1 - this.handleHeight)
                )
            );
            this.$refs.$scrollableContainer.scrollTop =
                (this.handleTop * this.scrollMax) / (this.scrollableHeight * (1 - this.handleHeight));
        },

        stopHandleMove(event) {
            this.handleMoving = false;
            document.removeEventListener('mouseup', this.stopHandleMove);
            document.removeEventListener('mousemove', this._throttledDoHandleMove);
        },

        refresh(resetScroll) {
            if (!!resetScroll) this.$refs.$scrollableContainer.scrollTop = 0;
            this.resizeHandle();
            this.updateHandlePosition();
        },

        handleResizeEvent(event) {
            this._throttledRefresh();
        },

        scrollTo(elementSelector, smooth = false, customOffset = null) {
            fastdom.measure(() => {
                if (!!this.scrollMax) {
                    let targetElement = this.$refs.$scrollableContainer.querySelector(elementSelector);
                    if (targetElement) {
                        let offset = targetElement.offsetTop;
                        while (targetElement.parentElement != this.$refs.$scrollableContainer) {
                            if (targetElement.parentElement == targetElement.offsetParent) {
                                offset += targetElement.parentElement.offsetTop;
                            }
                            targetElement = targetElement.parentElement;
                        }
                        if (targetElement.offsetParent != this.$refs.$scrollableContainer) {
                            offset -= this.$refs.$scrollableContainer.offsetTop;
                        }

                        // Utiliser l'offset personnalisé s'il est fourni, sinon utiliser cardSpacing
                        const finalOffset = customOffset !== null ? customOffset : this.cardSpacing;

                        const options = smooth
                            ? { top: offset - finalOffset, left: 0, behavior: 'smooth' }
                            : { top: offset - finalOffset, left: 0 };
                        this.$refs.$scrollableContainer.scrollTo(options);
                    }
                }
            });
        },

        getScrollEventData() {
            return {
                scroll: this.$refs.$scrollableContainer.scrollTop / this.scrollMax,
                ended: this.reachedScroll >= END_SCROLL_THRESHOLD
            };
        }
    },

    created() {
        this._throttledRefresh = _throttle(this.refresh, 200);
        this._throttledUpdateHandlePosition = _throttle(this.updateHandlePosition, 25);
        this._throttledDoHandleMove = _throttle(this.doHandleMove, 50);
        this._contentObserver = new MutationObserver(this.handleResizeEvent);
        this._handleMovingTimeoutId = -1;
        this._referenceHandleTop = null;
        this._referenceClientY = null;
    },

    mounted() {
        this._throttledRefresh();
        window.addEventListener('resize', this.handleResizeEvent);
        this._contentObserver.observe(this.$refs.$scrollableContainer, { childList: true, subtree: true });
        if (this.scrollFrom.length) {
            document.querySelectorAll(this.scrollFrom.join(', ')).forEach((element) => {
                element.addEventListener('wheel', this.updateScroll);
            });
        }
    },

    beforeUnmount() {
        if (this.scrollFrom.length) {
            document.querySelectorAll(this.scrollFrom.join(', ')).forEach((element) => {
                element.removeEventListener('wheel', this.updateScroll);
            });
        }
        document.removeEventListener('mouseup', this.stopHandleMove);
        document.removeEventListener('mousemove', this._throttledDoHandleMove);
        window.removeEventListener('resize', this.handleResizeEvent);
        this._contentObserver.disconnect();
    }
};
</script>
