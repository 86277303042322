<template>
    <div>
        <canvas ref="waveformCanvas" :width="canvasWidth" :height="canvasHeight"></canvas>
        <div ref="waveformColor" class="waveform-color"></div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed, nextTick } from 'vue';
import { useWaveformDrawing } from '../composables/useWaveformDrawing';

const props = defineProps({
    audioData: {
        type: [Float32Array, Array],
        required: true
    },
    canvasWidth: {
        type: Number,
        required: true
    },
    canvasHeight: {
        type: Number,
        required: true
    },
    waveformType: {
        type: String,
        default: 'wave',
        validator: (value) => ['bar', 'wave', 'wave-middle'].includes(value)
    },
    zoomLevel: {
        type: Number,
        default: 0.1
    }
});

const waveformCanvas = ref(null);
const waveformColor = ref(null);
const { drawWaveform } = useWaveformDrawing();
const isUpdating = ref(false);

const getWaveformColor = () => {
    if (!waveformColor.value) return 'rgba(0, 0, 0, 0.8)';

    const color = getComputedStyle(waveformColor.value).backgroundColor;

    if (props.waveformType === 'bar' || props.waveformType === 'wave-middle') return color;

    const rgba = color.replace(')', ', 0.8)').replace('rgb', 'rgba');
    return rgba;
};

const updateWaveform = async () => {
    if (!waveformCanvas.value || isUpdating.value) return;

    isUpdating.value = true;
    try {
        const ctx = waveformCanvas.value.getContext('2d');
        const color = await getWaveformColor();

        await drawWaveform(ctx, props.canvasWidth, props.canvasHeight, color, props.audioData, props.waveformType);
    } finally {
        isUpdating.value = false;
    }
};

onMounted(updateWaveform);

watch(() => props.audioData, updateWaveform);
watch(() => props.canvasWidth, updateWaveform);
watch(() => props.waveformType, updateWaveform);
watch(() => props.zoomLevel, updateWaveform);
</script>

<style scoped>
canvas {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}

.waveform-color {
    background-color: var(--edit-mode-color);
    display: none;
}
</style>
