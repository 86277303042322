<template>
    <main class="ui-view ui-view-light-grey-bg" id="brands-view">
        <ui-view-header :title="$t('Brands')" :enable-search="true" v-model="searchFilter" />
        <ui-infinite-scroll-paginator
            v-model="page"
            :max-page="totalPages"
            :loading="isLoading"
            :use-scrollable="false"
        >
            <router-link
                v-for="brand of brands"
                :to="{ name: 'EditBrand', params: { id: brand.id } }"
                class="view-brand-list-item"
            >
                <ui-card :hide-header="true" :shadow="true">
                    <div class="card-brand-data">
                        <div class="card-brand-image">
                            <image-fallback :src="brand.logoUrl" loading="lazy" />
                        </div>
                        <div class="card-brand-info">
                            <div class="card-brand-name">{{ brand.name }}</div>
                        </div>
                    </div>
                </ui-card>
            </router-link>
            <template v-slot:loading>
                <div class="brands-loader-container">
                    <LoadingMarker v-if="isLoading" />
                </div>
            </template>
        </ui-infinite-scroll-paginator>
    </main>
</template>

<script setup>
import '@/sass/views/brands-view.scss';
import UiCard from '@/js/components/UiCard.vue';
import { useBrands } from '@/js/composables/useBrands.js';
import ImageFallback from '@/js/components/ImageFallback.vue';
import UiInfiniteScrollPaginator from '@/js/components/UiInfiniteScrollPaginator.vue';
import UiViewHeader from '@/js/components/UiViewHeader.vue';
import { computed, onMounted, ref, watch } from 'vue';
import { debounce } from 'lodash';
import LoadingMarker from '@/js/components/LoadingMarker.vue';

const { page, totalPages, brands, isLoading, fetchBrands, resetDataForBrands } = useBrands();

const searchFilter = ref('');

const options = computed(() => ({
    page: page.value,
    search: searchFilter.value
}));

const debouncedSearch = debounce(() => {
    resetDataForBrands();
    fetchBrands(options.value);
}, 500);

watch(searchFilter, () => {
    debouncedSearch();
});

watch(page, () => {
    fetchBrands(options.value);
});

onMounted(() => {
    fetchBrands(options.value);
});
</script>
