import { ref } from 'vue';

export function useAudioWaveform(canvasWidth) {
    const audioData = ref([]);
    const isLoading = ref(false);
    const amplificationFactor = ref(3.5); // Facteur d'amplification par défaut
    const amplificationThreshold = ref(1); // Seuil en dessous duquel on amplifie

    const loadAudioData = async (audioSrc) => {
        isLoading.value = true;
        try {
            const arrayBuffer = await fetchAudioBuffer(audioSrc);

            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

            const numberOfChannels = audioBuffer.numberOfChannels;
            const length = audioBuffer.length;

            const channelDataArray = [];
            for (let channel = 0; channel < numberOfChannels; channel++) {
                channelDataArray.push(audioBuffer.getChannelData(channel));
            }

            const worker = new Worker(new URL('../workers/audioWorker.js', import.meta.url), {
                type: 'module'
            });

            worker.postMessage(
                {
                    channelDataArray,
                    canvasWidth: canvasWidth.value,
                    numberOfChannels,
                    length,
                    amplificationFactor: amplificationFactor.value,
                    amplificationThreshold: amplificationThreshold.value
                },
                channelDataArray.map((channelData) => channelData.buffer)
            );

            worker.onmessage = (e) => {
                if (e.data.error) {
                    console.error('Erreur lors du traitement des données audio:', e.data.error);
                } else {
                    audioData.value = e.data.audioData;
                }
                isLoading.value = false;
                worker.terminate();
            };
        } catch (error) {
            console.error('Erreur lors du chargement des données audio:', error);
            isLoading.value = false;
        }
    };

    const fetchAudioBuffer = async (audioSrc) => {
        if (audioSrc instanceof Blob) {
            return await audioSrc.arrayBuffer();
        } else {
            const response = await fetch(audioSrc);
            return await response.arrayBuffer();
        }
    };

    const setAmplificationFactor = (factor) => {
        amplificationFactor.value = factor;
    };

    const setAmplificationThreshold = (threshold) => {
        amplificationThreshold.value = threshold;
    };

    return {
        audioData,
        loadAudioData,
        isLoading,
        setAmplificationFactor,
        setAmplificationThreshold
    };
}
