<template>
    <studio ref="studio"></studio>
</template>

<script>
import Studio from '@/js/video-studio/components/Studio.vue';
import { defineComponent, getCurrentInstance, onBeforeMount, onMounted, provide, ref } from 'vue';
import { Production } from '@/js/video-studio/constants/index.js';
import { useStore } from 'vuex';
import StudioEditPreviewManager from '@/js/videos/application/previewManager/StudioEditPreviewManager.js';
import _throttle from 'lodash/throttle.js';
import StudioProdPreviewManager from '@/js/videos/application/previewManager/StudioProdPreviewManager.js';
import StudioShowPreviewManager from '@/js/videos/application/previewManager/StudioShowPreviewManager.js';
import StudioCompanionPreviewManager from '@/js/videos/application/previewManager/StudioCompanionPreviewManager.js';

const studioScaleCSSVar = '--preview-studio-scale';

export default defineComponent({
    name: 'VideoStudio',

    compilerOptions: {
        whitespace: 'preserve'
    },

    components: {
        Studio
    },

    props: {
        preview: {
            type: Boolean,
            default: true
        },
        autoplay: {
            type: Boolean,
            default: false
        },
        production: {
            type: Boolean,
            default: false
        },
        externalPreview: {
            type: Boolean,
            default: false
        },
        companionPreview: {
            type: Boolean,
            default: false
        }
    },

    setup(props) {
        let originalContainerScale;

        const store = useStore();

        const studio = ref(null);

        const changeVisibility = (event) => {
            if (document.visibilityState === 'hidden') {
                studio.value?.$stage?.pauseTimeline();
            }
        };

        const setContainerScale = (scale, onlyIfOriginal = false) => {
            if (onlyIfOriginal && originalContainerScale != null) {
                originalContainerScale = scale;
            } else {
                originalContainerScale = null;
                applyContainerScale(scale);
            }
        };

        const removeContainerScale = () => {
            if (!originalContainerScale) {
                originalContainerScale = applyContainerScale(null);
            }
            return originalContainerScale;
        };

        const applyContainerScale = (scale) => {
            let original = studio.value.$el.style.getPropertyValue(studioScaleCSSVar),
                next = scale ?? 1;
            if (original != next) studio.value.$el.style.setProperty(studioScaleCSSVar, next);
            return original;
        };

        const receiveProductionMessage = (event) => {
            if (event.origin === Production.ORIGIN_URL && event.data.target === 'studio') {
                switch (event.data.type) {
                    case 'start':
                        studio.value?.$stage?.playTimeline();
                        break;
                    case 'seek':
                        studio.value?.$stage?.captureFrame(data.time);
                        break;
                }
            }
        };

        const initStudioEditPreviewManager = () => {
            const studioEditPreviewManager = new StudioEditPreviewManager(store);
            window.addEventListener(
                'resize',
                _throttle(() => studioEditPreviewManager.resizePreview(), 200)
            );
            studioEditPreviewManager.resizePreview();
        };

        const initStudioProdPreviewManager = () => {
            const studioProdPreviewManager = new StudioProdPreviewManager(store);
            window.addEventListener(
                'resize',
                _throttle(() => studioProdPreviewManager.initPreviewResize(), 200)
            );
            studioProdPreviewManager.initPreviewResize();
            studioProdPreviewManager.initIoSocket();
        };

        const initStudioShowPreviewManager = () => {
            const studioShowPreviewManager = new StudioShowPreviewManager(store);
            window.addEventListener(
                'resize',
                _throttle(() => studioShowPreviewManager.initPreviewResize(), 200)
            );
            studioShowPreviewManager.initPreviewResize();
        };

        const initStudioCompanionPreviewManager = () => {
            const studioShowPreviewManager = new StudioCompanionPreviewManager(store);
            window.addEventListener(
                'resize',
                _throttle(() => studioShowPreviewManager.initPreviewResize(), 200)
            );
            studioShowPreviewManager.initPreviewResize();
        };

        window.$studio = getCurrentInstance();
        provide('$studio', getCurrentInstance());

        onBeforeMount(() => {
            // let $el = document.querySelector(this.$options.selector),
            //     preview = $el.getAttribute('data-preview'),
            //     autoplay = $el.getAttribute('data-autoplay'),
            //     deferStore = $el.getAttribute('data-defer-store'),
            //     production = $el.getAttribute('data-production'),
            //     timestamp = $el.getAttribute('data-timestamp');
            //
            // this.$version = STUDIO_VERSION;
            store.commit('loading/prepare', this);
            store.commit('display/setPreview', props.preview);
            store.commit('display/setAutoplay', props.autoplay);
            if (!store.state.display.timeline.autoplay) store.commit('display/setEdit', true);
            // if (deferStore !== null) this.deferStore = deferStore.toLowerCase() != 'false' && deferStore != '0';
            // if (production)
            //     this.$store.commit('display/setCaptureMode', production.toLowerCase() || Production.FULL_CAPTURE_MODE);
            // if (timestamp) {
            //     this.$store.commit('loading/setTimestamp', timestamp);
            //     window.STUDIO_TIMESTAMP = timestamp;
            // }
        });

        onMounted(() => {
            if (props.production) initStudioProdPreviewManager();
            else if (props.externalPreview) initStudioShowPreviewManager();
            else if (props.companionPreview) initStudioCompanionPreviewManager();
            else initStudioEditPreviewManager();

            document.addEventListener('visibilitychange', changeVisibility);
            if (!store.state.display.preview) window.addEventListener('message', receiveProductionMessage);

            store.commit('loading/prepared', this);
        });

        return {
            removeContainerScale,
            setContainerScale,
            studio
        };
    }
});
</script>
