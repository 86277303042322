import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import VideoDTO from '@/js/dto/VideoDTO.js';
import FetchVideosDataService from '@/js/application/services/video/FetchVideosDataService.js';
import ProfileDataService from '@/js/application/services/profile/ProfileDataService.js';
import usePagination from '@/js/composables/usePagination.js';

export function useVideos() {
    const store = useStore();

    const videos = ref([]);

    const { page, totalPages, isLoading, paginationRequest, resetData } = usePagination();

    const fetchVideos = async (folderId, options) => {
        if (!folderId) {
            throw new Error('Folder ID is required while fetching videos');
        }

        let paginatedResult = await paginationRequest(() =>
            new FetchVideosDataService(store).handle(folderId, options)
        );
        if (paginatedResult) addVideos(paginatedResult.data);
    };

    const fetchLatestVideos = async () => {
        try {
            await new ProfileDataService(store).fetchUserLatestVideosData();
        } catch (error) {
            console.error('Error fetching latest videos:', error);
        }
    };

    const latestVideos = computed(() => store.getters['auth/getLatestVideos']);

    const addVideos = (items) => {
        videos.value = videos.value.concat(
            items.map((item) => {
                let video = new VideoDTO(item);
                video.selected = false;
                return video;
            })
        );
    };

    const resetDataForVideos = () => {
        videos.value = [];
        resetData();
    };

    return {
        videos,
        page,
        totalPages,
        fetchVideos,
        isLoading,
        resetDataForVideos,
        addVideos,
        fetchLatestVideos,
        latestVideos
    };
}
