import { executeRequest } from '@/js/infrastructure/api/apiClient.js';

const API_URL = import.meta.env.VITE_IDENTITY_URL;

export default class AuthApiService {
    constructor(store) {
        this.store = store;
    }

    postLogout() {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.post(`${API_URL}/logout`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'AuthApiService.postLogout'
        });
    }
}
