<template>
    <transition>
        <div v-if="updateAvailable" class="ui-toast ui-new-update-toast">
            <div class="ui-toast-text">{{ $t('toast.new-update-available') }}</div>
            <button class="ui-simple-button" @click="reload">{{ $t('toast.refresh') }}</button>
        </div>
    </transition>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { subscribeToChannel } from '@/js/utils.js';
import { MAIN_CHANNEL, NEW_BUILD_DEPLOYED } from '@/js/constants/index.js';
import { useAuth } from '@/js/composables/useAuth.js';

const { isLoggedIn } = useAuth();

const updateAvailable = ref(false);

const reload = () => window.location.reload(true);

const subscribeToMainChannel = () => {
    subscribeToChannel(MAIN_CHANNEL, {}, 'private', {
        [NEW_BUILD_DEPLOYED]: () => (updateAvailable.value = true)
    });
};

const checkLogin = () => {
    if (isLoggedIn.value) subscribeToMainChannel();
};

watch(isLoggedIn, () => {
    checkLogin();
});

onMounted(() => {
    checkLogin();
});
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: all 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    transform: translateY(20px);
    opacity: 0;
}
</style>
