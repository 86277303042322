<template>
    <ui-modal v-bind="{ ...$props, ...$attrs }" ref="$modal" class="export-modal" :title="template.name">
        <video
            ref="$video"
            class="ui-modal-video"
            :src="template.latest_produced_url"
            :poster="template.latest_produced_poster_url"
        ></video>

        <template #modal-footer>
            <a class="ui-simple-button ui-simple-button-purple download-button" :href="template.download_url">
                <svg-icon icon="download-icon" />
                <span>{{ $t('Download') }}</span>
            </a>
            <a class="ui-simple-button ui-simple-button-purple share-button" target="_blank" :href="template.share_url">
                <svg-icon icon="share-icon" />
                <span>{{ $t('Share') }}</span>
            </a>
        </template>
    </ui-modal>
</template>

<script>
import Plyr from 'plyr';
import { Modal } from '../../../../mixins';
import { UiIcon } from '../../../../components';

export default {
    mixins: [Modal],

    components: {
        UiIcon
    },

    props: {
        template: Object
    },

    data() {
        return {
            //
        };
    },

    computed: {
        // ...mapState({
        //     supportEmail: state => state.ui.supportEmail,
        // }),
    },

    watch: {
        //
    },

    methods: {
        //
    },

    mounted() {
        this._player = new Plyr(this.$refs.$video, {
            controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
            invertTime: false
        });
    },

    beforeUnmount() {
        if (this._player) this._player.destroy();
    }
};
</script>
