<template>
    <div class="studio-captions studio-container studio-container-align" :style="containerStyles">
        <font :font-name="font" ref="$font" @load="onFontLoaded($event)" />
        <ul class="studio-captions-list" :class="captionsClasses" :style="captionsStyles">
            <li class="studio-caption" v-for="(caption, index) in captions" :key="index">
                <span>{{ caption }}</span>
            </li>
        </ul>
    </div>
</template>

<style lang="scss">
.studio-captions-font {
    font-family: v-bind('$refs.$font.fontFamily'), v-bind('$refs.$font.fontFamilyGeneric');
    font-weight: normal;

    b,
    strong {
        font-weight: bold;
    }
}
</style>

<script>
import { Align, Caption, Color, Dimension } from '../constants';
import { conversions } from '../utils';
import Font from './Font.vue';
import { mapGetters, mapState } from 'vuex';

export default {
    components: {
        Font
    },

    data() {
        return {
            activeCaptions: {}
        };
    },

    computed: {
        ...mapState('settings', {
            state: (state) => state.captions
        }),

        ...mapGetters({
            formatFontRatio: 'display/formatFontRatio',
            font: 'settings/captionsFont',
            fontScale: 'settings/captionsFontScale',
            padding: 'settings/captionsPadding'
        }),

        captions() {
            let c = [];
            Object.values(this.activeCaptions).forEach((captions) => {
                Array.from(captions).forEach((caption) => {
                    c.push(caption.text);
                });
            });
            return c;
        },

        containerStyles() {
            return {
                fontSize: Math.round(this.formatFontRatio * Caption.FONT_SIZE_DEFAULT) + Dimension.PIXEL_UNIT
            };
        },

        captionsClasses() {
            return [
                'studio-captions-font',
                'studio-captions-' + this.state.style,
                {
                    'studio-captions-with-background': this.state.background.color.start !== Color.NONE,
                    'studio-captions-shadow': this.state.shadow
                }
            ];
        },

        captionsStyles() {
            let position = conversions.alignment(
                this.state.position.alignH,
                this.state.position.alignV,
                this.state.position.alignX,
                this.state.position.alignY
            );
            return Object.assign(position, {
                '--captions-bg-color': this.state.background.color.start,
                '--captions-bg-opacity': this.state.background.opacity,
                paddingLeft:
                    this.state.position.alignH != Align.CUSTOM || position.left === undefined ? this.padding : 0,
                paddingRight:
                    this.state.position.alignH != Align.CUSTOM || position.right === undefined ? this.padding : 0,
                paddingTop: this.state.position.alignV != Align.CUSTOM || position.top === undefined ? this.padding : 0,
                paddingBottom:
                    this.state.position.alignV != Align.CUSTOM || position.bottom === undefined ? this.padding : 0,
                color: this.state.color.start,
                fontSize: 100 * this.fontScale + Dimension.PERCENT_UNIT,
                textAlign: this.state.align
            });
        }
    },

    methods: {
        onFontLoaded(url) {
            // this.$emit(EVENT_FONT_UPDATED, url);
        },

        updateActiveCaptions(captions, id) {
            this.activeCaptions = Object.assign({}, this.activeCaptions, { [id]: captions });
        }
    }
};
</script>
