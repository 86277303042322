<template>
    <nav class="ui-sidebar">
        <header class="ui-sidebar-header">
            <slot></slot>
            <button class="ui-sidebar-add" @click.stop="addNewPrompt">+</button>
        </header>
        <div v-if="!promptsLoaded" class="ui-sidebar-loader">
            <svg-icon icon="dots-loader" />
        </div>
        <ui-scrollable
            v-if="promptsLoaded"
            class="ui-sidebar-prompts-list"
            :class="scrollableClasses"
            ref="$promptsContainer"
        >
            <ul class="ui-sidebar-prompts">
                <template v-for="prompt in prompts">
                    <ui-prompt-item :prompt="prompt" :current-prompt-id="currentPromptId" v-on="itemListeners" />
                </template>
            </ul>
        </ui-scrollable>
    </nav>
</template>

<script>
import { ProvidesIcons } from '../../../../mixins';
import { UiIcon, UiScrollable } from '../../../../components';
import UiPromptItem, * as UiPromptItemEvent from './UiPromptItem.vue';

export const UI_SIDEBAR_ADD_PROMPT = 'add-prompt';

export default {
    emits: [UI_SIDEBAR_ADD_PROMPT],

    mixins: [ProvidesIcons],

    components: {
        UiIcon,
        UiScrollable,
        UiPromptItem
    },

    props: {
        prompts: {
            type: Array,
            default: () => []
        },
        currentPromptId: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            promptsLoaded: false,
            scrollToPrompt: true,
            lockedScroll: 0
        };
    },

    computed: {
        itemListeners() {
            return {
                click: this.showPrompt,
                [UiPromptItemEvent.UI_PROMPT_ITEM_ACTIONS_OPEN]: this.handlePromptActionsOpen,
                [UiPromptItemEvent.UI_PROMPT_ITEM_ACTIONS_CLOSE]: this.handlePromptActionsClose,
                [UiPromptItemEvent.UI_PROMPT_ITEM_DELETE]: this.deletePrompt
            };
        },

        scrollableClasses() {
            return {
                locked: !!this.lockedScroll
            };
        }
    },

    watch: {
        currentPromptId(newValue) {
            if (this.scrollToPrompt && this.$refs.$promptsContainer) {
                this.$refs.$promptsContainer.scrollTo('#ui-prompt-item-' + newValue, true);
            }
            this.scrollToPrompt = true;
        }
    },

    methods: {
        addNewPrompt(replaceState = false) {
            this.$store.dispatch('showNewPrompt', replaceState);
            this.$emit(UI_SIDEBAR_ADD_PROMPT);
        },

        showPrompt(prompt) {
            if (prompt.id != this.$store.state.currentPrompt.id) {
                this.scrollToPrompt = false;
                this.$store.dispatch('showPrompt', { prompt });
            }
        },

        async deletePrompt(prompt) {
            let promptIndex = this.prompts.findIndex((p) => p.id === prompt.id);

            if (prompt.id == this.$store.state.currentPrompt.id) {
                this.scrollToPrompt = false;
            }

            try {
                await this.$store.dispatch('deletePrompt', prompt);

                if (prompt.id == this.$store.state.currentPrompt.id) {
                    if (!!this.prompts.length) {
                        this.$store.dispatch('showPrompt', {
                            prompt: this.prompts[Math.min(promptIndex, this.prompts.length - 1)],
                            replace: true
                        });
                    } else {
                        // Invite to create a new prompt if there are no more prompt in the list
                        this.addNewPrompt(true);
                    }
                }
            } catch (error) {
                this.scrollToPrompt = true;
            }
        },

        handlePromptActionsOpen(promptItem) {
            this.lockedScroll++;
        },

        handlePromptActionsClose(promptItem) {
            this.lockedScroll--;
        }
    },

    async mounted() {
        await this.$store.dispatch('loadAllPrompts');
        this.promptsLoaded = true;

        if (this.currentPromptId) {
            this.$nextTick(() => this.$refs.$promptsContainer.scrollTo('#ui-prompt-item-' + this.currentPromptId));
        }
    }
};
</script>
