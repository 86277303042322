/**
 * Video Companion prompts Vuex store
 */

import axios from 'axios';
import { requestWithServiceTokenRefresh, maybeRefreshLogin } from '@/js/utils.js';

const state = {
    all: []
};

const getters = {
    //
};

const mutations = {
    setPrompts(state, prompts) {
        state.all = prompts;
    },

    setPrompt(state, prompt) {
        let { id, name, created_at, resource_url, show_url, delete_url } = prompt;
        let existingIndex = state.all.findIndex((p) => p.id === id);

        if (existingIndex == -1) {
            state.all.splice(
                state.all.findIndex((p) => p.created_at <= prompt.created_at),
                0,
                { id, name, created_at, resource_url, show_url, delete_url }
            );
        } else {
            state.all.splice(existingIndex, 1, { id, name, created_at, resource_url, show_url, delete_url });
        }
    },

    deletePrompt(state, promptId) {
        let existingIndex = state.all.findIndex((p) => p.id === promptId);

        if (existingIndex != -1) {
            state.all.splice(existingIndex, 1);
        }
    }
};

const actions = {
    async loadAllPrompts(context) {
        let { commit, dispatch, rootState } = context;

        await requestWithServiceTokenRefresh(
            async () => {
                let response = await axios.get(rootState.ui.urls.listPrompts, { withCredentials: true });
                commit('setPrompts', response.data);
            },
            (refreshError) => {
                console.log('[Error] Loading prompts: ' + refreshError.message);
                if (!!refreshError.response?.data?.token) {
                    maybeRefreshLogin(context, refreshError.response.data.token, () => dispatch('loadAllPrompts'));
                } else if (!!refreshError.response?.data?.redirect) {
                    // commit('setAuthError', refreshError.response.data);
                    window.location.assign(refreshError.response.data.redirect);
                } else {
                    // TODO: Display error message?
                }
            },
            context
        );
    },

    setPrompt({ commit }, prompt) {
        commit('setPrompt', prompt);
    },

    async deletePrompt(context, prompt) {
        let { commit, state } = context;
        let { id: promptId, delete_url } = prompt;

        return await requestWithServiceTokenRefresh(
            async () => {
                let response = await axios.delete(delete_url, { withCredentials: true });
                commit('deletePrompt', promptId);
            },
            (refreshError) => {
                console.log('[Error] Deleting prompt: ' + refreshError.message);
                if (!!refreshError.response?.data?.token) {
                    maybeRefreshLogin(context, refreshError.response.data.token);
                } else if (!!refreshError.response?.data?.redirect) {
                    // commit('setAuthError', refreshError.response.data);
                    window.location.assign(refreshError.response.data.redirect);
                } else {
                    // TODO: Display error message?
                }
                throw refreshError;
            },
            context
        );
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
