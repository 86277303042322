/**
 * Video Studio
 * Transition constants
 */

const NONE = 'None';
const PREFIX_CLASS = 'Transition';

const SWAP_ELEMENTS_INDEX = 102;

const files = import.meta.glob('../components/transitions/*.vue');
const transitions = {};
Object.keys(files).forEach((key) => {
    key = key
        .split('/')
        .pop()
        .replace(/(\.\/|\.vue)/g, '');
    let parts = key.match(/([A-Z\d]+(?:[a-z]+|$))/g);
    transitions[parts.join('_').toUpperCase()] = key;
});

const Transition = {
    PREFIX_CLASS,

    SWAP_ELEMENTS_INDEX,

    DEFAULT: NONE,

    NONE,
    ...transitions
};

export default Transition;
