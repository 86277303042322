import { computed } from 'vue';

export function useSegmentStyles({ sequenceProps, segments, canvasWidth }) {
    const MIN_SEGMENT_SIZE_PX = 14;
    const FRAME_BORDER = 2;
    const FRAME_HEIGHT = 58;
    const GAP_COLOR = 'rgba(44, 52, 58, 0.76)';

    const getHandlePos = (time, isEndHandle = false) => {
        const containerWidth = canvasWidth.value;
        const position = (time / sequenceProps.endTime) * containerWidth;

        if (isEndHandle) {
            return position - MIN_SEGMENT_SIZE_PX;
        }

        return position;
    };

    const getGapStyle = (index) => {
        if (index >= segments.value.length - 1) return {};
        const segmentEnd = segments.value[index].end;
        const nextSegmentStart = segments.value[index + 1].start;
        let gapWidth = getHandlePos(nextSegmentStart) - getHandlePos(segmentEnd);

        const leftOffset = getHandlePos(segmentEnd) - MIN_SEGMENT_SIZE_PX;
        gapWidth += MIN_SEGMENT_SIZE_PX * 2;

        return {
            top: `-${FRAME_BORDER}px`,
            left: `${leftOffset}px`,
            width: `${gapWidth}px`,
            height: `${FRAME_HEIGHT + FRAME_BORDER * 2}px`,
            backgroundColor: GAP_COLOR
        };
    };

    const getInitialGapStyle = computed(() => {
        if (!segments.value.length) return {};
        const firstSegmentStart = segments.value[0].start;
        let gapWidth = getHandlePos(firstSegmentStart);

        gapWidth += MIN_SEGMENT_SIZE_PX;

        return {
            top: `-${FRAME_BORDER}px`,
            left: `0px`,
            width: `${gapWidth}px`,
            height: `${FRAME_HEIGHT + FRAME_BORDER * 2}px`,
            backgroundColor: GAP_COLOR
        };
    });

    const getFinalGapStyle = computed(() => {
        if (!segments.value.length) return {};
        const lastSegmentEnd = segments.value[segments.value.length - 1].end;
        let gapWidth = canvasWidth.value - getHandlePos(lastSegmentEnd);

        const leftOffset = getHandlePos(lastSegmentEnd) - MIN_SEGMENT_SIZE_PX;
        gapWidth += MIN_SEGMENT_SIZE_PX;

        if (gapWidth < 0) gapWidth = MIN_SEGMENT_SIZE_PX;

        return {
            top: `-${FRAME_BORDER}px`,
            left: `${leftOffset}px`,
            width: `${gapWidth}px`,
            height: `${FRAME_HEIGHT + FRAME_BORDER * 2}px`,
            backgroundColor: GAP_COLOR
        };
    });

    const recalculateGapStyles = () => {
        segments.value.forEach((segment, index) => {
            if (index < segments.value.length - 1) {
                segment.gapStyle = getGapStyle(index);
            }
        });
    };

    return {
        getHandlePos,
        getGapStyle,
        getInitialGapStyle,
        getFinalGapStyle,
        MIN_SEGMENT_SIZE_PX,
        recalculateGapStyles
    };
}
