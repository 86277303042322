import { computed } from 'vue';
import { useStore } from 'vuex';
import AuthDataService from '@/js/application/services/auth/AuthDataService.js';

export function useAuth() {
    const store = useStore();

    const user = computed(() => store.getters['auth/user']);

    const isCTE = computed(() => store.getters['auth/isCTE']);

    const isClientAdmin = computed(() => store.getters['auth/isClientAdmin']);

    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);

    const logout = () => {
        new AuthDataService(store).logout();
    };

    return {
        user,
        isCTE,
        isClientAdmin,
        isLoggedIn,
        logout
    };
}
