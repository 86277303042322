import FetchVideoApiService from '@/js/infrastructure/api/video/FetchVideoApiService.js';
import StoreCurrentVideoDataService from '@/js/infrastructure/store/video/StoreCurrentVideoDataService.js';
import VideoDTO from '@/js/dto/VideoDTO.js';

export default class FetchVideoDataService {
    constructor(store) {
        this.fetchVideoApiService = new FetchVideoApiService(store);
        this.storeCurrentVideoDataService = new StoreCurrentVideoDataService(store);
    }

    handle(videoId) {
        this.fetchVideoApiService.handle(videoId).then((response) => {
            this.storeCurrentVideoDataService.handle(response.data);
        });
    }

    async getVideoData(videoId) {
        let data = (await this.fetchVideoApiService.handle(videoId)).data;
        return new VideoDTO(data);
    }
}
