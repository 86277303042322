import { executeRequest } from '@/js/infrastructure/api/apiClient.js';

const LEGACY_URL = import.meta.env.VITE_LEGACY_APP_URL;
const EDITION_URL = import.meta.env.VITE_EDITION_API_URL;
const IDENTITY_URL = import.meta.env.VITE_IDENTITY_URL;

export default class UserApiService {
    constructor(store) {
        this.store = store;
    }

    put(params) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.put(`${LEGACY_URL}/front/users/${params.uuid}`, {
                    first_name: params.firstName,
                    last_name: params.lastName,
                    role_id: params.roleId
                });
            },
            onSuccess: async (response) => response.data,
            actionType: 'UserApiService.put'
        });
    }

    getFolders(userId, options = {}) {
        const { page = 1, search = '', count = 10 } = options;

        const queryParams = new URLSearchParams({
            page: page.toString(),
            count: count.toString()
        });

        if (search) queryParams.append('search', search);

        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(
                    `${LEGACY_URL}/front/users/${userId}/allowable-folders?${queryParams.toString()}`
                );
            },
            onSuccess: async (response) => response.data,
            actionType: 'UserApiService.getFolders'
        });
    }

    postAuthorizeFolder(uuid, folderShortname, isAllowed) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.post(`${LEGACY_URL}/front/users/${uuid}/authorize-folder`, {
                    folder_shortname: folderShortname,
                    is_allowed: isAllowed
                });
            },
            onSuccess: async (response) => response.data,
            actionType: 'UserApiService.postAuthorizeFolder'
        });
    }

    getNbVideos(id) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${EDITION_URL}/api/stats/users/${id}/nb-videos`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'UserApiService.getNbVideos'
        });
    }

    loginAs(id) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.post(`${IDENTITY_URL}/login/aliased`, {
                    target_user_id: id
                });
            },
            onSuccess: async (response) => response.data,
            actionType: 'UserApiService.loginAs'
        });
    }

    invite(data) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.post(`${LEGACY_URL}/front/users/invite`, data);
            },
            onSuccess: async (response) => response.data,
            actionType: 'UserApiService.invite'
        });
    }

    delete(userUuid) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.delete(`${LEGACY_URL}/front/users/${userUuid}/delete`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'UserApiService.delete'
        });
    }
}
