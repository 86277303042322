import { useI18n } from 'vue-i18n';
import { printf } from 'fast-printf';

export function useDates() {
    const { t } = useI18n();

    const getTimeSince = (date) => {
        let seconds = Math.floor((new Date() - date) / 1000);
        let interval = seconds / 31536000;

        if (interval > 1) {
            return printf(t('%d year(s) ago'), Math.floor(interval));
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return printf(t('%d month(s) ago'), Math.floor(interval));
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return printf(t('%d day(s) ago'), Math.floor(interval));
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return printf(t('%d hour(s) ago'), Math.floor(interval));
        }
        interval = seconds / 60;
        if (interval > 1) {
            return printf(t('%d minute(s) ago'), Math.floor(interval));
        }
        return printf(t('%d second(s) ago'), seconds);
    };

    return {
        getTimeSince
    };
}
