<template>
    <div>
        <div class="home-view-templates-banner-header">
            <h2 class="underline-selected">{{ $t('Templates') }}</h2>
            <div class="home-view-templates-filters">
                <BrandSelector :modelValue="localSelectedBrandId" @update:modelValue="updateSelectedBrand" />
                <UiTextInput
                    id="home-templates-search"
                    type="search"
                    :placeholder="$t('Search')"
                    :show-label="false"
                    icon="search-icon"
                    v-model="localTemplateFilterText"
                />
            </div>
        </div>
        <div class="home-view-templates-banner-categories">
            <div class="template-format-filter-list">
                <TemplateFormatItem
                    v-for="format in formats"
                    :key="format"
                    :format="format"
                    :is-selected="selectedTemplateFormat === format"
                    @select="selectFormat"
                />
            </div>
            <div class="template-filter-separator" />
            <div class="template-category-filter-list">
                <UiScrollableHorizontal class="template-category-filter-list">
                    <TemplateCategoryItem
                        v-for="category in templateCategories"
                        :key="category"
                        :category="category"
                        :is-selected="selectedTemplateCategory === category"
                        @select="selectCategory"
                    />
                </UiScrollableHorizontal>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import BrandSelector from './BrandSelector.vue';
import UiTextInput from '@/js/components/UiTextInput.vue';
import UiScrollableHorizontal from '@/js/components/UiScrollableHorizontal.vue';
import TemplateCategoryItem from './TemplateCategoryItem.vue';
import TemplateFormatItem from './TemplateFormatItem.vue';

const props = defineProps({
    templateCategories: {
        type: Array,
        required: true
    },
    formats: {
        type: Array,
        required: true
    },
    selectedBrandId: {
        type: [String, null],
        default: null
    },
    templateFilterText: {
        type: String,
        default: ''
    },
    selectedTemplateCategory: {
        type: String,
        default: 'all'
    },
    selectedTemplateFormat: {
        type: String,
        default: 'landscape'
    }
});

const emit = defineEmits([
    'update:selectedBrandId',
    'update:templateFilterText',
    'update:selectedTemplateCategory',
    'update:selectedTemplateFormat'
]);

const localSelectedBrandId = computed({
    get: () => props.selectedBrandId,
    set: (value) => emit('update:selectedBrandId', value)
});

const updateSelectedBrand = (newValue) => {
    localSelectedBrandId.value = newValue;
};

const localTemplateFilterText = computed({
    get: () => props.templateFilterText,
    set: (value) => emit('update:templateFilterText', value)
});

const localSelectedTemplateCategory = computed({
    get: () => props.selectedTemplateCategory,
    set: (value) => emit('update:selectedTemplateCategory', value)
});

const localSelectedTemplateFormat = computed({
    get: () => props.selectedTemplateFormat,
    set: (value) => emit('update:selectedTemplateFormat', value)
});

const selectCategory = (category) => {
    localSelectedTemplateCategory.value = category;
};

const selectFormat = (format) => {
    localSelectedTemplateFormat.value = format;
};
</script>
