import { mapState, mapGetters } from 'vuex';
import * as UiComponents from '../../components';
import {
    UI_MEDIA_TYPE_IMAGE,
    UI_MEDIA_TYPE_ICON,
    UI_MEDIA_TYPE_GIF,
    UI_MEDIA_TYPE_VIDEO,
    UI_MEDIA_TYPE_CARTOON,
    UI_MEDIA_TYPE_ANIMATED,
    UI_MEDIA_TYPE_AUDIO,
    UI_MEDIA_TYPE_MUSIC,
    UI_MEDIA_SELECTOR_SHOW,
    UI_MEDIA_SELECTOR_CHANGE
} from '../../components/UiMediaSelector.vue';
import {
    UI_LIBRARY_SELECTOR_SHOW,
    UI_LIBRARY_SELECTOR_HIDE,
    UI_LIBRARY_SELECTOR_PREVIEW_CHANGE
} from '../../components/UiLibrarySelector.vue';
import {
    UI_CAPTION_BUTTON_SHOW,
    UI_CAPTION_BUTTON_HIDE,
    UI_CAPTION_BUTTON_CHANGE,
    UI_CAPTION_BUTTON_CAPTIONING_CHANGE
} from '../components/ui/body/cards/tools/caption/UiCaptionButton.vue';
import ModifiesHistory from './ModifiesHistory';
import SavesVideo from './SavesVideo';

export default {
    mixins: [ModifiesHistory, SavesVideo],

    inject: ['$videoStudio'],

    components: {
        ...UiComponents
    },

    props: {
        seqId: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            mediaTypeImage: UI_MEDIA_TYPE_IMAGE,
            mediaTypeIcon: UI_MEDIA_TYPE_ICON,
            mediaTypeGif: UI_MEDIA_TYPE_GIF,
            mediaTypeVideo: UI_MEDIA_TYPE_VIDEO,
            mediaTypeCartoon: UI_MEDIA_TYPE_CARTOON,
            mediaTypeAnimated: UI_MEDIA_TYPE_ANIMATED,
            mediaTypeAudio: UI_MEDIA_TYPE_AUDIO,
            mediaTypeMusic: UI_MEDIA_TYPE_MUSIC,
            mediaSelectorShowEvent: UI_MEDIA_SELECTOR_SHOW,
            mediaSelectorChangeEvent: UI_MEDIA_SELECTOR_CHANGE,
            librarySelectorShowEvent: UI_LIBRARY_SELECTOR_SHOW,
            librarySelectorPreviewChangeEvent: UI_LIBRARY_SELECTOR_PREVIEW_CHANGE,
            captionButtonShowEvent: UI_CAPTION_BUTTON_SHOW,
            captionButtonCaptioningChangeEvent: UI_CAPTION_BUTTON_CAPTIONING_CHANGE
        };
    },

    computed: {
        ...mapState({
            language: (state) => state.ui.language,
            shortLanguage: (state) => state.ui.shortLanguage,
            readOnly: (state) => state.ui.readOnly,
            prefixes: (state) => state.ui.prefixes,
            branding: (state) => state.branding,
            brandPalette: (state) => state.branding.palette,
            brandEnableOtherColors: (state) => state.branding.enableOtherColors,
            libraryTagOrders: (state) => state.branding.libraries.tagOrders,
            sequenceState(state) {
                return state.sequences[this.seqId];
            }
        }),

        ...mapGetters('ui', [
            'isEmotionMode',
            'isContentMode',
            'canDuplicateCard',
            'canRemoveMessage',
            'canRemoveVisual',
            'canRemovePanel',
            'canRemoveAudio',
            'canRemoveTransition',
            'canRemoveFooter'
        ]),

        ...mapGetters({
            getBrandLibraryItems: 'branding/libraries/libraryItems'
        }),

        canCopyCard() {
            return this.$store.getters['ui/canCopyCard'](this.id);
        },

        canPasteCard() {
            return this.$store.getters['ui/canPasteCard'](this.id);
        },

        cardElementId() {
            return this.seqId + '-' + this.id;
        },

        sequenceStoreModulePath() {
            return 'sequences/' + this.seqId;
        },

        resolvedId() {
            return this.$store.getters[this.sequenceStoreModulePath + '/resolvedId'];
        },

        isFirst() {
            return this.$store.getters[this.sequenceStoreModulePath + '/isFirstVisible'];
        },
        isLast() {
            return this.$store.getters[this.sequenceStoreModulePath + '/isLastVisible'];
        },

        previous() {
            return this.$store.getters[this.sequenceStoreModulePath + '/previousVisible'];
        },
        next() {
            return this.$store.getters[this.sequenceStoreModulePath + '/nextVisible'];
        },

        isBrandLibraryEmpty() {
            return (library, category) => !this.getBrandLibraryItems(library, category).length;
        }
    },

    methods: {
        saveHistoryStep(actions) {
            this.startHistoryStep();
            actions();
            this.saveVideo();
        },

        copyCard() {
            if (this.canCopyCard) {
                this.$store.dispatch(this.sequenceStoreModulePath + '/copyCardToClipboard', this.id);
            }
        },

        pasteCard() {
            if (this.canPasteCard) {
                this.$videoStudio.studio.$stage.pauseTimeline();
                this.saveHistoryStep(() => {
                    this.$store.dispatch(this.sequenceStoreModulePath + '/pasteCard', this.id);
                });
            }
        },

        playPreview(time) {
            this.$store.commit('preview/setCurrentTimelineId', this.seqId);
            this.$videoStudio.studio.$stage.seekSequenceTimeline(this.seqId, time || 0.001);
            this.$videoStudio.studio.$stage.playTimeline();
        },

        openLibrary(selector) {
            const isMusicLibrary = selector.library === 'musics';

            this.$store.commit('ui/setCurrentLibrary', {
                id: selector.library,
                category: selector.category,
                tagOrder: selector.tagOrder || this.libraryTagOrders[selector.library] || null,
                selectedItemId: isMusicLibrary ? selector.modelValue.id : selector.modelValue,
                selector
            });
        },

        closeLibrary() {
            //
        },

        openMediaLibrary(selector) {
            this.$store.commit('ui/setCurrentMediaLibrary', {
                mediaType: selector.type,
                selectedMediaId: selector.media.id,
                selector
            });
        },

        closeMediaLibrary() {
            //
        },

        openCaptionEditor(button) {
            this.$store.commit('ui/setCurrentCaptionButton', button);
        },

        closeCaptionEditor() {
            //
        }
    }
};
