<!--
  -- Component name: Square3
  -- Type: Transition
  -- Uses: color.start
  -- Tags: color
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <path class="square-top" :fill="state.color.start" d="M1371.6-586.5l139.7 778.8-1702.9 298.2-138-776.4z" />
        <path class="square-right" :fill="state.color.start" d="M498.1-467.6l778.8-139.6 298.2 1702.9-776.4 138z" />
        <path class="square-bottom" :fill="state.color.start" d="M1497.7 181.5l139.6 778.9-1702.9 298.1-138-776.3z" />
        <path class="square-left" :fill="state.color.start" d="M-272.9-318.9l778.8-139.7 298.2 1703-776.4 138z" />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            isAbove: true
        };
    },

    computed: {},

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 }, 0.001);
            t.set('#' + this.containerID + ' .square-right', { x: 100 }, 0);
            t.set('#' + this.containerID + ' .square-left', { x: -100 }, 0);

            t.to('#' + this.containerID + ' .square-top', { y: -100, duration: 0.2 }, 0.2);
            t.to('#' + this.containerID + ' .square-bottom', { y: 100, duration: 0.2 }, 0.2);

            t.to(
                '#' + this.containerID + ' .square-top',
                { transformOrigin: '50% 110%', rotate: 180, duration: 0.2 },
                0.4
            );
            t.to(
                '#' + this.containerID + ' .square-bottom',
                { transformOrigin: '50% -10%', rotate: 180, duration: 0.2 },
                0.4
            );

            t.to(
                '#' + this.containerID + ' .square-right',
                { transformOrigin: '-10% 50%', rotate: 180, duration: 0.3 },
                0.6
            );
            t.to(
                '#' + this.containerID + ' .square-left',
                { transformOrigin: '110% 50%', rotate: 180, duration: 0.3 },
                0.6
            );
            t.to(
                '#' + this.containerID + ' .square-top',
                { transformOrigin: '0% 0%', yPercent: 100, duration: 0.3 },
                0.6
            );
            t.to(
                '#' + this.containerID + ' .square-bottom',
                { transformOrigin: '0% 0%', yPercent: -100, duration: 0.3 },
                0.6
            );

            t.to(
                '#' + this.containerID + ' .square-right',
                { transformOrigin: '0% 0%', xPercent: -100, duration: 0.3 },
                0.9
            );
            t.to(
                '#' + this.containerID + ' .square-left',
                { transformOrigin: '0% 0%', xPercent: 100, duration: 0.3 },
                0.9
            );
            return t;
        }
    }
};
</script>
