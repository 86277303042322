/**
 * Video Studio
 * Message constants
 */
import { defineAsyncComponent, markRaw } from 'vue';

const STATIC_1 = 'Static1';
const PREFIX_CLASS = 'Message';

const files = import.meta.glob('../components/messages/*.vue');
const messages = {};
Object.keys(files).forEach((key) => {
    key = key
        .split('/')
        .pop()
        .replace(/(\.\/|\.vue)/g, '');
    if (key.indexOf('MessageContainer') !== -1) return;
    let parts = key.match(/([A-Z\d]+(?:[a-z]+|$))/g);
    messages[parts.join('_').toUpperCase()] = key;
});

const messageList = Object.values(messages).reduce((_messages, componentName) => {
    _messages[componentName.replace(PREFIX_CLASS, '')] = markRaw(
        defineAsyncComponent(() => import(`../components/messages/${componentName}.vue`))
    );
    return _messages;
}, {});

const Message = {
    PREFIX_CLASS,
    PREFIX_ID: 'message_',

    FONT_SIZE_DEFAULT: 48,
    FONT_SCALE_DEFAULT: 1,
    FONT_SCALE_CUSTOM: 'custom',

    DEFAULT: STATIC_1,

    // SOCIETE GENERALE
    SG: {
        CSS_PROPERTIES: {
            TITLE: {
                textTransform: 'uppercase',
                fontFamily: '"Montserrat Extra Bold", sans-serif',
                fontWeight: 800 // extra-bold
            },
            SUBTITLE: {
                fontFamily: '"Source Sans Pro", sans-serif',
                fontSize: '0.7em'
            }
        },
        MOVING_LINE_MB: '0.5em'
    },

    messageList,
    ...messages
};

export default Message;
