import { mapState } from 'vuex';
import { STAGE_LOADING_ENDED_EVENT } from 'cte-video-studio';
import { LIBRARY_NEW_ITEM_ID } from '../../constants';
import {
    UI_LIBRARY_SELECTOR_SHOW,
    UI_LIBRARY_SELECTOR_HIDE,
    UI_LIBRARY_SELECTOR_PREVIEW_CHANGE,
    UI_LIBRARY_SELECTOR_CHANGE
} from '../../components/UiLibrarySelector.vue';
import ModifiesHistory from './ModifiesHistory';
import SavesVideo from './SavesVideo';

export default {
    mixins: [ModifiesHistory, SavesVideo],

    inject: ['$videoStudio'],

    data() {
        return {
            librarySelectorShowEvent: UI_LIBRARY_SELECTOR_SHOW,
            librarySelectorPreviewChangeEvent: UI_LIBRARY_SELECTOR_PREVIEW_CHANGE,
            librarySelectorChangeEvent: UI_LIBRARY_SELECTOR_CHANGE
        };
    },

    computed: {
        ...mapState({
            readOnly: (state) => state.ui.readOnly,
            currentEditedItemId: (state) => state.ui.currentEditedItemId,
            libraryTagOrders: (state) => state.branding.libraries.tagOrders
        })
    },

    methods: {
        openSequenceLibrary(selector) {
            this.$store.commit('ui/setCurrentLibrary', {
                id: selector.library,
                tagOrder: this.libraryTagOrders[selector.library] || null,
                selectedItemId: null,
                selector
            });
        },

        closeSequenceLibrary() {
            //
        },

        previewSequenceTemplate(templateData, originalState, cancel) {
            this.ignoreHistoryStep(() => {
                this.$store.dispatch('sequences/' + this.currentEditedItemId + '/applyTemplate', {
                    templateData,
                    originalState
                });
                if (!cancel) {
                    this.$videoStudio?.studio.$stage.$el.addEventListener(
                        STAGE_LOADING_ENDED_EVENT,
                        this.playSequenceTemplatePreview,
                        { once: true }
                    );
                }
            });
        },

        playSequenceTemplatePreview() {
            this.$store.commit('preview/setCurrentTimelineId', this.currentEditedItemId);
            this.$videoStudio?.studio.$stage.seekSequenceTimeline(this.currentEditedItemId, 0.001);
            this.$videoStudio?.studio.$stage.playTimeline();
        },

        applySequenceTemplate(templateData, originalState) {
            // Note: Messages use marks, which are parsed during preview.
            // But when applying the template, the parsing is lost, so we need
            // to retrieve it to set it again after applying the template.
            let messageTexts = this.$store.getters['sequences/' + this.currentEditedItemId + '/messages'].reduce(
                (texts, message) => {
                    texts[message.id] = message.text;
                    return texts;
                },
                {}
            );

            this.$store.dispatch('sequences/' + this.currentEditedItemId + '/applyTemplate', {
                templateData,
                originalState
            });
            /*Object.entries(messageTexts).forEach(([messageId, text]) => {
                this.$store.commit('sequences/' + this.currentEditedItemId + '/' + messageId + '/setText', text);
            });*/
            this.saveVideo();
        }
    }
};
