import { v4 as uuidv4 } from 'uuid';

export default {
    data() {
        return {
            accordionId: uuidv4()
        };
    },

    computed: {
        //
    },

    watch: {
        //
    },

    methods: {
        //
    },

    created() {
        this.$store.commit('ui/accordions/add', {
            id: this.accordionId,
            itemActive: null
        });
    },

    beforeUnmount() {
        this.$store.commit('ui/accordions/remove', this.accordionId);
    }
};
