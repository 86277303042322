<template>
    <section
        class="ui-body-panel"
        role="application"
        :aria-description="sprintf($t('Sequence %1$s settings'), sequenceIndex + 1)"
    >
        <header class="ui-sequence-panel-header">
            <!--            <ui-sequence-library-selector v-if="canUseSequenceTemplate"
                ref="$changeSequenceSelector"
                id="change-sequence-library"
                key="change-sequence-library"
                class="ui-action-button change-sequence"
                :label="$t('Change sequence with...')"
                :show-label="false"
                :disabled="readOnly"
                @[librarySelectorShowEvent]="openSequenceLibrary"
                @[librarySelectorPreviewChangeEvent]="previewSequenceTemplate"
                @[librarySelectorChangeEvent]="applySequenceTemplate" />-->

            <h2 class="ui-sequence-name" :aria-description="$t('Sequence name')">
                <input
                    :id="seqId + '-name-input'"
                    ref="$sequenceNameInput"
                    class="sequence-name"
                    v-model.trim.lazy="sequenceName"
                    :disabled="readOnly"
                    @focus="startEditSequenceName"
                    @blur="stopEditSequenceName"
                    @keyup.enter="stopEditSequenceName"
                />
                <label class="visually-hidden" :for="seqId + '-name-input'">{{ $t('Edit sequence name') }}</label>
                <span class="sequence-name-placeholder" aria-hidden="true">{{ sequenceName }}</span>
            </h2>

            <ui-dropdown
                v-if="!readOnly && isEmotionMode"
                :id="seqId + '-panel-add-card'"
                class="add-card video-onboarding-step-3-emotion"
                toggle-class="ui-action-button"
                :caret="false"
                :scrollable="false"
                menu-placement="bottom-end"
                menu-strategy="fixed"
                :tooltip="$t('Add an element')"
            >
                <template #dropdown-toggle>
                    <fa-icon class="icon" icon="fa-solid fa-plus" />
                    <span class="visually-hidden">{{ $t('Add an element') }}</span>
                </template>
                <ui-card-selector :seq-id="seqId" @[addedCardEvent]="emitAddedCard" />
            </ui-dropdown>
        </header>

        <div class="ui-card-container">
            <card-sequence-transition
                :seq-id="seqId"
                :key="'sc-' + seqId + '-transition'"
                v-if="hasTransition"
                :added-card-id="addedCardId"
            />

            <card-sequence-message
                v-for="(message, index) in messages"
                :key="'sc-' + seqId + '-' + message.id"
                :seq-id="seqId"
                :element-id="message.id"
                :editing="!!editedElement && editedElement.id === message.id"
                :added-card-id="addedCardId"
                :number="index + 1"
            />

            <card-sequence-visual
                v-for="(visual, index) in visuals"
                :key="'sc-' + seqId + '-' + visual.id"
                :seq-id="seqId"
                :element-id="visual.id"
                :editing="!!editedElement && editedElement.id === visual.id"
                :added-card-id="addedCardId"
                :number="index + 1"
                ref="cardSequenceVisual"
            />

            <card-sequence-footer
                :seq-id="seqId"
                :key="'sc-' + seqId + '-footer'"
                v-if="hasFooter"
                :added-card-id="addedCardId"
            />

            <card-sequence-panel
                :seq-id="seqId"
                :key="'sc-' + seqId + '-panel'"
                v-if="hasPanel"
                :editing="!!editedElement && editedElement.id === seqId + '-panel'"
                :added-card-id="addedCardId"
            />

            <card-sequence-voice-over
                :seq-id="seqId"
                :key="'sc-' + seqId + '-voice-over'"
                v-if="hasTts"
                :added-card-id="addedCardId"
            />

            <card-sequence-audio
                :seq-id="seqId"
                :key="'sc-' + seqId + '-audio'"
                v-if="hasAudio"
                :added-card-id="addedCardId"
            />

            <card-sequence
                :seq-id="seqId"
                :key="'sc-' + seqId + '-sequence'"
                :editing="!!editedElement && editedElement.id === seqId"
            />
        </div>

        <footer class="ui-sequence-panel-footer">
            <ui-dropdown
                v-if="!readOnly && isEmotionMode"
                :id="seqId + '-panel-footer-add-card'"
                class="add-card"
                toggle-class="ui-action-button"
                :caret="false"
                :scrollable="false"
                menu-placement="top"
                menu-strategy="fixed"
            >
                <template #dropdown-toggle>
                    <fa-icon class="icon" icon="fa-solid fa-plus" />
                    <span>{{ $t('Add an element') }}</span>
                </template>
                <ui-card-selector :seq-id="seqId" @[addedCardEvent]="emitAddedCard" />
            </ui-dropdown>
        </footer>
    </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { printf as sprintf } from 'fast-printf';
import { UiDropdown, UiIcon } from '../../../../components';
import { InteractsWithSequenceLibrary, ModifiesHistory, SavesVideo } from '../../../mixins';
import UiSequenceLibrarySelector from '../UiSequenceLibrarySelector.vue';
import UiCardSelector, { UI_CARD_SELECTOR_ADDED_CARD } from './UiCardSelector.vue';
import {
    CardSequence,
    CardSequenceMessage,
    CardSequenceVisual,
    CardSequencePanel,
    CardSequenceAudio,
    CardSequenceTransition,
    CardSequenceFooter,
    CardSequenceVoiceOver
} from './cards';
import { Visual } from '../../../../video-studio/constants';

export const UI_SEQUENCE_ADDED_CARD = 'ui-sequence-added-card';

export default {
    mixins: [InteractsWithSequenceLibrary, ModifiesHistory, SavesVideo],

    components: {
        UiDropdown,
        UiIcon,
        UiSequenceLibrarySelector,
        UiCardSelector,
        CardSequence,
        CardSequenceMessage,
        CardSequenceVisual,
        CardSequencePanel,
        CardSequenceAudio,
        CardSequenceTransition,
        CardSequenceFooter,
        CardSequenceVoiceOver
    },

    props: {
        seqId: {
            type: String,
            default: ''
        },
        editedElement: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            editSequenceName: false,
            addedCardEvent: UI_CARD_SELECTOR_ADDED_CARD,
            addedCardId: null
        };
    },

    computed: {
        ...mapState({
            readOnly: (state) => state.ui.readOnly,
            sequenceMode: (state) => state.ui.sequenceMode,
            sequenceState(state) {
                return state.sequences[this.seqId];
            }
        }),

        ...mapGetters({
            canUseSequenceTemplate: 'ui/canUseSequenceTemplate',
            isEmotionMode: 'ui/isEmotionMode'
        }),

        sequenceStoreModulePath() {
            return 'sequences/' + this.seqId;
        },

        sequenceIndex() {
            return this.$store.getters[this.sequenceStoreModulePath + '/index'];
        },

        sequenceName: {
            get() {
                let name = /\S/.test(this.sequenceState.name) ? this.sequenceState.name : this.$t('Untitled sequence');
                return this.editSequenceName
                    ? name
                    : // TRANSLATORS: Placeholders are for sequence index (1) and name (2)
                      sprintf(this.$t('%1$d. %2$s'), this.sequenceIndex + 1, name);
            },
            set(value) {
                value = value.replace(/\s+/g, ' ');
                this.startHistoryStep();
                this.$store.commit(this.sequenceStoreModulePath + '/setName', value);
                if (this.sequenceMode) this.$store.commit('settings/setName', value);
                this.saveVideo();
            }
        },

        messages() {
            return this.$store.getters[this.sequenceStoreModulePath + '/messages'];
        },

        visuals() {
            return this.$store.getters[this.sequenceStoreModulePath + '/visuals'];
        },

        hasPanel() {
            return this.$store.getters[this.sequenceStoreModulePath + '/hasPanel'];
        },
        hasAudio() {
            return this.$store.getters[this.sequenceStoreModulePath + '/hasAudio'];
        },
        hasTransition() {
            return this.$store.getters[this.sequenceStoreModulePath + '/hasTransition'];
        },
        hasFooter() {
            return this.$store.getters[this.sequenceStoreModulePath + '/hasFooter'];
        },
        hasNotes() {
            return this.$store.getters[this.sequenceStoreModulePath + '/hasNotes'];
        },
        hasTts() {
            return this.$store.getters[this.sequenceStoreModulePath + '/hasTts'];
        }
    },

    watch: {},

    methods: {
        sprintf,

        startEditSequenceName() {
            this.editSequenceName = true;
            this.$nextTick(() => {
                this.$refs['$sequenceNameInput'].setSelectionRange(0, this.sequenceName.length);
            });
        },

        stopEditSequenceName() {
            this.editSequenceName = false;
            this.$refs['$sequenceNameInput'].blur();
        },

        emitAddedCard(card) {
            // id, category
            this.addedCardId = this.seqId + '-' + card.id;
            this.$emit(UI_SEQUENCE_ADDED_CARD, card.id, this.seqId, '#' + this.seqId + '-' + card.id);
            if (card.id.includes('visual')) {
                this.emitVisualAddedCard(card);
            }
        },

        emitVisualAddedCard(card) {
            // id, category
            let visualCard = this.$refs.cardSequenceVisual.find((ref) => ref.elementId === card.id);
            visualCard.visualCategory = card.category;
            visualCard.triggerCategoryDropdown();
            if (card.category === Visual.IMAGE_CATEGORY) {
                visualCard.openMediaLibrary(visualCard.$refs.$imageVisualSelector);
            }
        }
    },

    mounted() {
        //
    }
};
</script>
