<template>
    <div class="ui-scrollable-horizontal">
        <div v-if="showScrollLeft" class="scroll-button scroll-button-left" @click="scrollLeft">
            <fa-icon class="icon" icon="fa-solid fa-chevron-left" />
        </div>
        <div class="scroll-horizontal-list" ref="scrollContainer">
            <slot />
        </div>
        <div v-if="showScrollRight" class="scroll-button scroll-button-right" @click="scrollRight">
            <fa-icon class="icon" icon="fa-solid fa-chevron-right" />
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, onUpdated, ref } from 'vue';

const scrollStep = 200;

export default defineComponent({
    name: 'UiScrollableHorizontal',

    setup() {
        const scrollContainer = ref(null);
        const showScrollLeft = ref(false);
        const showScrollRight = ref(false);

        const scrollLeft = () => {
            scrollContainer.value.scrollLeft -= scrollStep;
            checkScroll();
        };

        const scrollRight = () => {
            scrollContainer.value.scrollLeft += scrollStep;
            checkScroll();
        };

        const checkScroll = () => {
            showScrollLeft.value = scrollContainer.value.scrollLeft > 0;
            showScrollRight.value =
                scrollContainer.value.scrollLeft + scrollContainer.value.clientWidth <
                scrollContainer.value.scrollWidth;
        };

        onMounted(() => {
            checkScroll();
            window.addEventListener('resize', checkScroll);
        });

        onUpdated(() => {
            checkScroll();
        });

        onUnmounted(() => {
            window.removeEventListener('resize', checkScroll);
        });

        return {
            scrollContainer,
            showScrollLeft,
            showScrollRight,
            checkScroll,
            scrollLeft,
            scrollRight
        };
    }
});
</script>
