/**
 * Video Studio
 * Timeline constants
 */

const Timeline = {
    SEQUENCE_TIMELINE_ID: 'sequence',
    BACKGROUND_TIMELINE_ID: 'background',
    TRANSITION_TIMELINE_ID: 'transition',
    VISUAL_TIMELINE_ID: 'visual',
    MESSAGE_TIMELINE_ID: 'message',
    PANEL_TIMELINE_ID: 'panel',
    PANEL_BG_TIMELINE_ID: 'panel_bg',
    FOOTER_TIMELINE_ID: 'footer',
    LOGO_TIMELINE_ID: 'logo',
    COVER_TIMELINE_ID: 'cover',
    INNER_BACKGROUND_TIMELINE_ID: 'inner_background',
    VIDEO_TIMELINE_ID: 'video',
    AUDIO_TIMELINE_ID: 'audio',
    TTS_TIMELINE_ID: 'tts',
    SPRITESHEET_TIMELINE_ID: 'spritesheet'
};

export default Timeline;
