/**
 * Video Studio
 * Caption constants
 */

const Caption = {
    FONT_SIZE_DEFAULT: 40,
    FONT_SCALE_DEFAULT: 1,
    FONT_SCALE_CUSTOM: 'custom'
};

export default Caption;
