<template>
    <div class="ui-onboarding-tour">
        <v-tour name="onboardingTour" :steps="steps" :options="options">
            <template
                #default="{
                    currentStep,
                    steps,
                    previousStep,
                    nextStep,
                    stop,
                    skip,
                    finish,
                    isFirst,
                    isLast,
                    labels,
                    enabledButtons,
                    highlight,
                    debug
                }"
            >
                <UiOnboardingStep
                    v-if="steps[currentStep]"
                    :stepNumber="currentStep + 1"
                    :step="steps[currentStep]"
                    :key="currentStep"
                    :previous-step="previousStep"
                    :nextStep="nextStep"
                    :stop="stop"
                    :isFirst="isFirst"
                    :isLast="isLast"
                    :labels="labels"
                    :enabledButtons="enabledButtons"
                    :highlight="highlight"
                    :debug="debug"
                    :context="context"
                    :nbSteps="steps.length"
                    @update-display="updateDisplay"
                />
            </template>
        </v-tour>
    </div>
</template>

<script>
import VTour from 'vue3-tour/src/components/VTour.vue';
import UiOnboardingStep from './UiOnboardingStep.vue';
import ProvidesIcons from '../mixins/ProvidesIcons';
import {
    BRAND_CONTEXT,
    BRAND_ONBOARDING_STEPS,
    VIDEO_ONBOARDING_EMOTION_MODE_STEPS,
    VIDEO_ONBOARDING_CONTENT_MODE_STEPS,
    VIDEO_CONTEXT
} from '../constants/onboarding';
import { EMOTION_EDITION_MODE } from '../constants';
import { DEFAULT_OPTIONS } from 'vue3-tour/src/shared/constants';
import { mapState } from 'vuex';
import OnboardingService from '@/js/infrastructure/api/onboarding/OnboardingService';
import LocalStorageManager from '@/js/infrastructure/local_storage/LocalStorageManager';
import i18n from '@/libs/i18n.js';

export default {
    name: 'UiOnboardingTour',

    mixins: [ProvidesIcons],

    components: {
        VTour,
        UiOnboardingStep
    },

    props: {
        context: {
            type: String,
            required: true,
            default: BRAND_CONTEXT
        }
    },

    data() {
        return {
            options: {
                ...DEFAULT_OPTIONS,
                enabledButtons: {
                    ...DEFAULT_OPTIONS.enabledButtons,
                    buttonPrevious: false
                },
                labels: {
                    buttonSkip: i18n.global.t("Don't show anymore"),
                    buttonPrevious: i18n.global.t('Previous'),
                    buttonNext: i18n.global.t('Next'),
                    buttonStop: i18n.global.t('Got it !')
                },
                initialStep: -1
            },
            onboardingService: new OnboardingService(this.$store)
        };
    },

    computed: {
        ...mapState({
            editionMode: (state) => state.ui.editionMode,
            userId: (state) => state.ui.userId
        }),

        steps() {
            return this.context === BRAND_CONTEXT
                ? BRAND_ONBOARDING_STEPS
                : this.editionMode === EMOTION_EDITION_MODE
                  ? VIDEO_ONBOARDING_EMOTION_MODE_STEPS
                  : VIDEO_ONBOARDING_CONTENT_MODE_STEPS;
        }
    },

    methods: {
        async getInitialOnboardingStep() {
            try {
                const response = await this.onboardingService.getInitialOnboardingStep(this.$store.state.ui.userId);
                return response.data;
            } catch (error) {
                console.error('Error while getting initial onboarding step', error);
            }
        },

        updateDisplay(stepNumber) {
            if (this.context === VIDEO_CONTEXT && stepNumber >= 2) {
                try {
                    const itemId = this.$store.state.sequences.order[0];
                    const item = this.$store.state.sequences[itemId];
                    if (item !== this.$store.state.ui.currentEditedItem) {
                        this.$store.dispatch('ui/updateCurrentEditedItem', item);
                    }
                } catch {
                    console.error('Error while updating display');
                }
            }
        },

        loadDataFromLocalStorage() {
            let storedStep = '';
            let storedDisplay = '';
            if (this.context === BRAND_CONTEXT) {
                storedStep = LocalStorageManager.load(this.userId + '_branding_onboarding_last_step');
                storedDisplay = LocalStorageManager.load(this.userId + '_display_branding_onboarding');
            } else {
                storedStep = LocalStorageManager.load(this.userId + '_edition_onboarding_last_step');
                storedDisplay = LocalStorageManager.load(this.userId + '_display_edition_onboarding');
            }
            return { storedStep, storedDisplay };
        },

        saveDataToLocalStorage(step, display) {
            if (this.context === BRAND_CONTEXT) {
                LocalStorageManager.save(this.userId + '_branding_onboarding_last_step', step);
                LocalStorageManager.save(this.userId + '_display_branding_onboarding', display);
            } else {
                LocalStorageManager.save(this.userId + '_edition_onboarding_last_step', step);
                LocalStorageManager.save(this.userId + '_display_edition_onboarding', display);
            }
        },

        initOnboardingTour(step) {
            this.options.initialStep = step - 1;
            this.updateDisplay(this.options.initialStep);
            this.$tours['onboardingTour'].start(this.options.initialStep);
        }
    },

    mounted: function () {
        let { storedStep, storedDisplay } = this.loadDataFromLocalStorage();
        // Get initial onboarding step from data in local storage
        if (storedStep !== null && storedDisplay !== null) {
            if (storedDisplay) {
                this.initOnboardingTour(storedStep);
            }
        } else {
            // Get initial onboarding step from API
            this.getInitialOnboardingStep()
                .then((step) => {
                    if (this.context === BRAND_CONTEXT) {
                        if (step !== '') {
                            if (step.display_branding_onboarding)
                                this.initOnboardingTour(step.branding_onboarding_last_step);
                            this.saveDataToLocalStorage(
                                step.branding_onboarding_last_step,
                                step.display_branding_onboarding
                            );
                        }
                    } else {
                        if (step !== '') {
                            if (step.display_edition_onboarding)
                                this.initOnboardingTour(step.edition_onboarding_last_step);
                            this.saveDataToLocalStorage(
                                step.edition_onboarding_last_step,
                                step.display_edition_onboarding
                            );
                        }
                    }
                })
                .catch((error) => {
                    console.error('Error while getting initial onboarding step', error);
                });
        }
    }
};
</script>

<style scoped></style>
