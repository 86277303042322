<template>
    <div :id="id" class="ui-emoji-picker">
        <ul class="ui-emoji-category-tabs">
            <li v-for="categoryId in categories">
                <a
                    :href="'#emoji-category-' + categoryId"
                    :title="$t('studio.emoji_categories.' + categoryId)"
                    @click.prevent="selectCategory(categoryId)"
                    @keyup.space="selectCategory(categoryId)"
                >
                    <span
                        class="emoji-icon"
                        :style="
                            getEmojiStyles(
                                emojiByCategories[categoryId][0].sheet_x,
                                emojiByCategories[categoryId][0].sheet_y
                            )
                        "
                        >{{ getEmoji(emojiByCategories[categoryId][0].unified) }}</span
                    >
                </a>
            </li>
        </ul>
        <ui-scrollable class="ui-emoji-list" ref="$scrollable">
            <dl
                v-for="(emojiList, categoryId) in emojiByCategories"
                :id="'emoji-category-' + categoryId"
                class="ui-emoji-category"
            >
                <dt>{{ $t('studio.emoji_categories.' + categoryId) }}</dt>
                <dd>
                    <button
                        v-for="emoji in emojiList"
                        class="ui-simple-button"
                        :title="emoji.short_name"
                        @click.prevent="select(emoji)"
                    >
                        <span class="emoji-icon" :style="getEmojiStyles(emoji.sheet_x, emoji.sheet_y)">{{
                            getEmoji(emoji.unified)
                        }}</span>
                    </button>
                </dd>
            </dl>
        </ui-scrollable>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _groupBy from 'lodash/groupBy';
import _sortBy from 'lodash/sortBy';
import * as emojiData from 'emoji-datasource-apple';
import UiScrollable from './UiScrollable.vue';
import UiTextInput from './UiTextInput.vue';

export const UI_EMOJI_PICKER_SELECT = 'ui-emoji-picker-select';

const EMOJI_SHEET_SIZE = 60;

export default {
    components: {
        UiScrollable,
        UiTextInput
    },

    props: {
        id: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            //
        };
    },

    computed: {
        ...mapGetters({
            //
        }),

        emojis() {
            return _sortBy(emojiData, 'sort_order').filter((emoji) => emoji.category != 'Component');
        },

        emojiByCategories() {
            return _groupBy(this.emojis, (emoji) => emoji.category.toLowerCase().replace(/[\s&]+/, '-'));
        },

        categories() {
            return Object.keys(this.emojiByCategories);
        }
    },

    watch: {
        //
    },

    methods: {
        getEmoji(codepoints) {
            return String.fromCodePoint(...codepoints.split('-').map((code) => Number('0x' + code)));
        },

        getEmojiStyles(posX, posY) {
            return {
                backgroundPosition:
                    (100 * posX) / (EMOJI_SHEET_SIZE - 1) + '% ' + (100 * posY) / (EMOJI_SHEET_SIZE - 1) + '%'
            };
        },

        selectCategory(categoryId) {
            this.$refs.$scrollable.scrollTo('#emoji-category-' + categoryId);
        },

        select(emoji) {
            this.$emit(UI_EMOJI_PICKER_SELECT, this.getEmoji(emoji.unified));
        }
    },

    mounted() {
        //
    }
};
</script>
