<template>
    <component
        :is="sequenceContainerTagName"
        class="studio-sequence-content studio-container"
        :class="classes"
        :style="sequenceStyle"
        :id="containerID"
        @[containerClickEvent].self="startEditingSequence()"
    >
        <component :is="sequenceInnerTagName" width="100%" height="100%" class="studio-container">
            <div
                class="studio-container studio-container-align"
                :class="containerClasses"
                xmlns="http://www.w3.org/1999/xhtml"
            >
                <AssetAudio
                    v-if="state.audio.track.src"
                    ref="$seqTrack"
                    name="$seqTrack"
                    :seqId="id"
                    :src="trackSrc"
                    :basisVolume="trackVolume"
                    :volume="volume"
                    :captions="state.audio.track.captions"
                    :timerange="state.audio.track.timerange"
                    :segments="state.audio.track.timerangeSegments"
                    @update="updateTimeline"
                />
                <AssetAudio
                    v-if="trackTTS"
                    ref="$seqTTS"
                    name="$seqTTS"
                    :seqId="id"
                    :src="trackTTS"
                    :playback-rate="trackTTSPlaybackRate"
                    :basisVolume="trackTTSVolume"
                    :volume="volume"
                    :captions="trackTTSCaptions"
                    :timerange="trackTTSTimerange"
                    :segments="trackTTSTimerangeSegments"
                    @update="updateTimeline"
                />
                <panel
                    v-if="state.panel.enabled"
                    :ref="panelId"
                    :id="panelId"
                    :seqId="id"
                    @[elementMousedownEvent]="startEditingElement($event, panelId)"
                />
                <template v-for="(element, index) in elements" :key="element.id">
                    <component
                        :is="
                            Object.hasOwn(element, 'text')
                                ? messageList[state[element.id].animation.type]
                                : visualList[state[element.id].animation.type]
                        "
                        :id="element.id"
                        :seqId="id"
                        :ref="element.id"
                        @[elementMousedownEvent]="startEditingElement($event, element.id)"
                    />
                </template>
                <div
                    class="studio-sequence-footer"
                    :ref="footerRef"
                    :style="footerStyles"
                    v-html="state.options.footer.text"
                ></div>
            </div>
        </component>
    </component>
</template>

<script>
import { Dimension, Duration, Message, Production, Timeline, Visual } from '../constants';
import SequenceMixin from '../mixins/Sequence';
import AssetAudio from './assets/AssetAudio.vue';
import Panel from './Panel.vue';
import gsap from 'gsap';
import { mapState, mapGetters } from 'vuex';

export default {
    inject: ['$stage'],

    components: {
        AssetAudio,
        Panel
    },

    mixins: [SequenceMixin],

    props: {
        settings: Object,
        edit: Boolean
    },

    data() {
        return {
            footerRef: '$footer',
            messageList: Message.messageList,
            visualList: Visual.visualList
        };
    },

    computed: {
        ...mapState({
            state(state) {
                return state.sequences[this.id];
            }
        }),

        ...mapGetters({
            formatFontSize: 'display/formatFontSize',
            borders: 'settings/borders'
        }),

        containerID() {
            return this.id + '-content';
        },

        panelId() {
            return this.id + '-panel';
        },

        sequenceStyle() {
            return {
                fontSize: this.formatFontSize + Dimension.PIXEL_UNIT,
                'pointer-events': this.isVisible ? 'auto' : 'none'
            };
        },

        elements() {
            return this.$store.getters['sequences/' + this.id + '/all'];
        },

        elementTimelines() {
            let timelines = [];
            this.elements.forEach((elementState) => {
                timelines.push(elementState.timeline);
                timelines.push(elementState.timelineOut);
            });
            return timelines;
        },

        elementTimings() {
            return this.elements.map((elementState) => {
                return {
                    start: this.$store.getters['sequences/' + this.id + '/' + elementState.id + '/start'],
                    end: this.$store.getters['sequences/' + this.id + '/' + elementState.id + '/end']
                };
            });
        },

        footerStart() {
            return this.$store.getters['sequences/' + this.id + '/footerStart'];
        },
        footerStyles() {
            let borderSize = Math.ceil(this.settings.borders.size / this.$stage.scale) + Dimension.PIXEL_UNIT,
                margin =
                    '0 ' +
                    (this.borders && this.borders.right.size !== Dimension.ZERO ? borderSize : 0) +
                    ' ' +
                    (this.borders && this.borders.bottom.size !== Dimension.ZERO ? borderSize : 0) +
                    ' ' +
                    (this.borders && this.borders.left.size !== Dimension.ZERO ? borderSize : 0);
            return {
                textAlign: this.state.options.footer.align,
                margin: margin
            };
        },

        trackVolume() {
            return this.$store.getters['sequences/' + this.id + '/trackVolume'];
        },
        trackStart() {
            return this.$store.getters['sequences/' + this.id + '/trackStart'];
        },
        trackSrc() {
            return this.$store.getters['sequences/' + this.id + '/trackSrc'];
        },

        trackTTS() {
            return this.$store.getters['sequences/' + this.id + '/tts/track'];
        },
        trackTTSStart() {
            return this.$store.getters['sequences/' + this.id + '/tts/start'];
        },
        trackTTSVolume() {
            return this.$store.getters['sequences/' + this.id + '/tts/volume'];
        },
        trackTTSPlaybackRate() {
            return this.$store.getters['sequences/' + this.id + '/tts/playbackRate'];
        },
        trackTTSTimerange() {
            return this.$store.getters['sequences/' + this.id + '/tts/timerange'];
        },
        trackTTSTimerangeSegments() {
            return this.$store.getters['sequences/' + this.id + '/tts/media'].timerangeSegments;
        },
        trackTTSCaptions() {
            return this.$store.getters['sequences/' + this.id + '/tts/captions'];
        },

        containerClickEvent() {
            return this.isVisible && this.edit ? 'click' : null;
        },
        elementMousedownEvent() {
            return this.isVisible && this.edit ? 'mousedown' : null;
        },

        mainTTSStart() {
            return this.$store.getters['settings/tts/start'];
        },
        mainTTSDuration() {
            return this.$store.getters['settings/tts/totalDuration'];
        }
    },

    watch: {
        isLast(newValue) {
            // Ensure timeline will update when last sequence is removed (especially via undo action)
            if (newValue) this.$stage.requestTimelineUpdate();
        },
        'settings.logo.timeline': {
            handler() {
                this.updateTimeline();
            }
        },

        'settings.cover.timeline': {
            handler() {
                this.updateTimeline();
            }
        },

        'state.options.duration': {
            handler() {
                this.updateTimeline();
            }
        },

        'state.options.logo.enabled': {
            handler() {
                this.updateTimeline();
            }
        },

        elementTimelines: {
            handler(newValue, oldValue) {
                let hasChanged =
                    newValue.length != oldValue.length ||
                    newValue.reduce((changed, timeline, index) => {
                        return changed || timeline != oldValue[index];
                    }, false);

                if (hasChanged) this.updateTimeline();
            },
            deep: true
        },

        elementTimings: {
            handler(newValue, oldValue) {
                let hasChanged =
                    newValue.length != oldValue.length ||
                    newValue.reduce((changed, timings, index) => {
                        return (
                            changed ||
                            !oldValue[index] ||
                            timings.start != oldValue[index].start ||
                            timings.end != oldValue[index].end
                        );
                    }, false);

                if (hasChanged) this.$stage.requestTimelineUpdate(false);
            },
            deep: true
        },

        footerStart() {
            this.$stage.requestTimelineUpdate(false);
        },

        trackStart() {
            this.updateTimeline();
        },

        trackTTSStart() {
            this.updateTimeline();
        }
    },

    methods: {
        setEl() {
            let selector =
                '#' +
                this.containerID +
                ' > ' +
                this.sequenceInnerTagName +
                (!this.detection.browser.edge ? ' > div' : '');
            this.$store.commit('sequences/' + this.id + '/setEl', document.querySelector(selector));
        },

        getPanel() {
            return this.$refs[this.panelId];
        },

        getElement(id) {
            return this.$refs[id] && this.$refs[id][0];
        },

        updateTimeline() {
            this.$store.commit('sequences/' + this.id + '/setTimeline', (timing) => this.getTimeline(timing));
        },

        getTimeline(timing) {
            this._timeline.seek(0);
            this._timeline.clear();
            this._timeline.kill();
            this._timeline = gsap.timeline({ id: Timeline.SEQUENCE_TIMELINE_ID });
            this._timeline.to({}, { duration: 0.0001 }); // Workaround for zero duration timeline bug

            // Logo animation
            if (this.settings.logo.enabled) {
                if (this.state.options.logo.enabled && (this.isFirst || !this.previous.options.logo.enabled)) {
                    this._timeline.add(this.settings.logo.timeline(), 0);
                    this._timeline.add(this.settings.cover.timeline(), 0);
                } else if (!this.state.options.logo.enabled && !this.isFirst && this.previous.options.logo.enabled) {
                    this._timeline.add(this.settings.logo.timelineOut(), 0);
                    this._timeline.add(this.settings.cover.timelineOut(), 0);
                }
            }

            // Panel animation
            this.getPanel()?.releaseTimeline();
            if (this.state.panel.enabled) {
                let panelStart = this.state.panel.animation.start,
                    panelEnd = this.state.panel.animation.end;
                this._timeline.addLabel('panel', panelStart);
                this._timeline.add(this.state.panel.timeline(), panelStart);
                if (panelEnd != Duration.END_DEFAULT) {
                    this._timeline.add(this.state.panel.timelineOut(), panelEnd);
                }
            }

            // Message and Visual animations
            if (this.elements.length) {
                this.elements.forEach((elementState) => {
                    let start = this.$store.getters['sequences/' + this.id + '/' + elementState.id + '/start'],
                        end = this.$store.getters['sequences/' + this.id + '/' + elementState.id + '/end'],
                        animIn = elementState.timeline();
                    this._timeline.addLabel(elementState.id, start);
                    this._timeline.add(animIn, start);
                    if (
                        animIn.data &&
                        animIn.data.hasSequenceDuration &&
                        animIn.totalDuration() < Duration.INFINITE_DURATION
                    )
                        animIn.timeScale(10000);
                    if (end > start) {
                        animIn.data = animIn.data ? { ...animIn.data, hasOutTimeline: true } : { hasOutTimeline: true };
                        let animOut = elementState.timelineOut();
                        this._timeline.addLabel(elementState.id + '_end', end);
                        this._timeline.add(animOut, end);
                    }
                });
            }

            // Footer animation
            this._timeline.fromTo(
                this.$refs[this.footerRef],
                { autoAlpha: 0 },
                { id: Timeline.FOOTER_TIMELINE_ID, duration: Duration.FOOTER_ANIMATION_TIME_DEFAULT, autoAlpha: 1 },
                this.footerStart
            );

            // Audio
            if (this.$refs.$seqTrack) {
                this._timeline.add(this.$refs.$seqTrack.timeline().paused(false), this.trackStart);
                this.$store.commit(
                    'sequences/' + this.id + '/setTrackTotalDuration',
                    this.$refs.$seqTrack.totalDuration
                );
            }

            // TTS (Voice Over)
            if (this.$refs.$seqTTS) {
                this._timeline.add(this.$refs.$seqTTS.timeline().paused(false), this.trackTTSStart);
                this.$store.dispatch(
                    'sequences/' + this.id + '/tts/setTotalDuration',
                    this.$refs.$seqTTS.totalDuration
                );
            }

            if (this.isLast && !this.state.options.duration) {
                let timelineMusicStart = !this.$stage.hasLoadingProductionSequence
                    ? 0
                    : Production.LOADING_SEQUENCE_DURATION;

                let globalTTSDuration = timelineMusicStart + this.mainTTSStart + this.mainTTSDuration;
                let globalTTSDurationOverflow = globalTTSDuration - timing;

                if (globalTTSDurationOverflow > 0) this._timeline.add(() => {}, globalTTSDurationOverflow);
            }

            this._timeline.addLabel('end');
            return this._timeline;
        },

        startEditingElement(event, elementId) {
            let element = this.$refs[elementId];
            element = Array.isArray(element) ? element[0] : element;
            this.$store.commit('edition/setEditingElement', element);
            this.$store.commit('edition/sendMovementEvent', event);
            if (!!event) document.addEventListener('click', this.captureClickEvent, { once: true, capture: true });
        },

        startEditingSequence() {
            this.$store.commit('edition/setEditingElement', this);
        },

        captureClickEvent(event) {
            event.stopPropagation();
        }
    },

    created() {
        this._timeline = gsap.timeline({ id: Timeline.SEQUENCE_TIMELINE_ID });
    },

    mounted() {
        if (!this.elements.length) {
            this.updateTimeline();
        }
    },

    beforeUnmount() {
        this._timeline.kill();
    }
};
</script>
