/**
 * Video Studio
 * Logo constants
 */

const STATIC = 'Static';

const files = import.meta.glob('../components/logos/*.vue');
const logos = {};
Object.keys(files).forEach((key) => {
    key = key
        .split('/')
        .pop()
        .replace(/(\.\/|\.vue)/g, '');
    let parts = key.match(/([A-Z\d]+(?:[a-z]+|$))/g);
    logos[parts.join('_').toUpperCase()] = key;
});

const Logo = {
    PREFIX_CLASS: 'Logo',

    DEFAULT: STATIC,

    ...logos
};

export default Logo;
