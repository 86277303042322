// useTimelineControls.js
import { debounce } from 'lodash';
import { ref, computed } from 'vue';

export function useTimelineControls(
    { FRAME_RATE, FRAME_WIDTH },
    { segments, videoEndTime, updateCurrentTimeAndPos, zoomLevel, sliderContainer, sequenceProps }
) {
    const currentTimePos = ref(0);
    const currentTimeDrag = ref(false);

    const canvasWidth = computed(() => {
        return videoEndTime.value * FRAME_RATE * FRAME_WIDTH * zoomLevel.value;
    });

    const startCurrentTimeDrag = (event) => {
        currentTimeDrag.value = true;
    };

    const getCurrentRealTime = () => {
        return (currentTimePos.value / canvasWidth.value) * videoEndTime.value;
    };

    const onCurrentTimeDrag = (event, sliderContainer) => {
        if (!currentTimeDrag.value) return;
        const rect = sliderContainer.getBoundingClientRect();
        const newX = event.clientX - rect.left;
        const newTime = (newX / canvasWidth.value) * videoEndTime.value;
        currentTimePos.value = newX;
        updateCurrentTimeAndPos(newTime);
    };

    const stopCurrentTimeDrag = () => {
        currentTimeDrag.value = false;
    };

    const updateCurrentTimeAndPosition = (newTime) => {
        let targetTime = 0,
            found = false;
        const containerWidth = canvasWidth.value;
        const playbackRate = sequenceProps.playbackRate || 1;

        for (const segment of segments.value) {
            const segmentDuration = (segment.end - segment.start) / playbackRate;
            if (newTime < segmentDuration) {
                targetTime = segment.start + newTime * playbackRate;
                found = true;
                break;
            } else {
                newTime -= segmentDuration;
            }
        }
        //verify if segments as end property
        if (!found && segments.value.length > 0 && segments.value.hasOwnProperty('end')) {
            targetTime = segments.value[segments.value.length - 1].end;
        }

        currentTimePos.value = (targetTime / videoEndTime.value) * containerWidth;
    };

    return {
        currentTimePos,
        canvasWidth,
        updateCurrentTimeAndPosition,
        startCurrentTimeDrag,
        onCurrentTimeDrag,
        stopCurrentTimeDrag,
        getCurrentRealTime,
        currentTimeDrag
    };
}
