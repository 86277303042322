<template>
    <video
        ref="gifPlayer"
        id="video-preview-gif"
        class="video-js vjs-default-skin vjs-purple-skin vjs-tech"
        width="100%"
        height="403px"
        :src="producedVideo"
        controls
        muted
        autoplay
        preload="auto"
    ></video>

    <SlideSelectionbar
        v-if="videoLoaded"
        :videoDuration="videoDuration"
        @updateStartTime="onUpdateStartTime"
        @updateEndTime="onUpdateEndTime"
    />
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import Plyr from 'plyr';
import SlideSelectionbar from '@/js/components/modals/export/SlideSelectionBar.vue';

const startTime = ref(0);
const endTime = ref(0);
const videoLoaded = ref(false);

const emit = defineEmits(['updateStartTime', 'updateEndTime']);

const props = defineProps({
    video: Object
});

const videoDuration = ref(0);
const producedVideo = ref(props.video.latestProducedUrl);
const gifPlayer = ref(null);
const plyrInstance = ref(null);

const onUpdateStartTime = (newStartTime) => {
    startTime.value = newStartTime;
    emit('updateStartTime', startTime.value);

    if (plyrInstance.value) {
        plyrInstance.value.currentTime = startTime.value;
        plyrInstance.value.play();
    }
};

const onUpdateEndTime = (newEndTime) => {
    endTime.value = newEndTime;
    emit('updateEndTime', endTime.value);
};

onMounted(() => {
    plyrInstance.value = new Plyr(gifPlayer.value, {
        controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen']
    });

    gifPlayer.value.addEventListener('loadedmetadata', () => {
        if (gifPlayer.value) {
            videoDuration.value = gifPlayer.value.duration;
            endTime.value = videoDuration.value;
            emit('updateEndTime', endTime.value);
            videoLoaded.value = true;
        }
    });

    gifPlayer.value.addEventListener('timeupdate', () => {
        if (plyrInstance.value && plyrInstance.value.currentTime >= endTime.value) {
            plyrInstance.value.pause();
            plyrInstance.value.currentTime = startTime.value;
        }
    });
});

onBeforeUnmount(() => {
    plyrInstance.value?.destroy();
});
</script>
