import { Production, Sound } from '../constants';
import { mapState } from 'vuex';

export default {
    props: {
        id: String,
        detection: Object,
        currentSequences: Array,
        preview: Boolean,
        captureMode: [Boolean, String]
    },

    data() {
        return {
            volume: Sound.VOLUME_DEFAULT
        };
    },

    computed: {
        ...mapState({
            currentTransition: (state) => state.display.timeline.currentTransition
        }),

        isFirst() {
            return this.$store.getters['sequences/' + this.id + '/isFirstVisible'];
        },
        isLast() {
            return this.$store.getters['sequences/' + this.id + '/isLastVisible'];
        },
        previous() {
            return this.$store.getters['sequences/' + this.id + '/previousVisible'];
        },
        next() {
            return this.$store.getters['sequences/' + this.id + '/nextVisible'];
        },
        isVisible() {
            return this.currentSequences.indexOf(this.id) !== -1;
        },

        isLoadingProductionSequence() {
            return (
                !this.preview &&
                this.captureMode != Production.FRAME_CAPTURE_MODE &&
                this.id == Production.LOADING_SEQUENCE_ID
            );
        },

        sequenceContainerTagName() {
            return this.detection.browser.edge ? 'svg' : 'div';
        },

        sequenceInnerTagName() {
            return this.detection.browser.edge ? 'foreignObject' : 'div';
        },

        needsRefresh() {
            return (
                this.currentSequences.indexOf(this.id) != -1 &&
                ((this.currentTransition == this.id &&
                    this.$store.getters['sequences/' + this.id + '/transitionNeedsRefresh']) ||
                    (!this.isLast &&
                        this.currentTransition == this.next.id &&
                        this.$store.getters['sequences/' + this.next.id + '/transitionNeedsRefresh']))
            );
        },

        classes() {
            return {
                'studio-first': this.isFirst,
                'studio-last': this.isLast,
                'studio-loading-production-sequence': this.isLoadingProductionSequence,
                'studio-showing': this.isVisible
            };
        },

        containerClasses() {
            return {
                'needs-refresh': this.needsRefresh,
                'horizontal-distribution':
                    this.$store.getters['sequences/' + this.id + '/hasHorDistributedAsset'] &&
                    !this.$store.getters['sequences/' + this.id + '/hasVerDistributedAsset']
            };
        }
    },

    methods: {
        setEl() {},
        updateTimeline() {}
    },

    mounted() {
        this.setEl();
    }
};
