/**
 * Video Studio
 * Loading data Vuex store module
 */

// Initial state
const originalState = () => ({
    timestamp: '',
    preparing: [],
    manifest: [],
    converting: [],
    studioLoading: false
});

const state = {
    ...originalState()
};

// Getters
const getters = {
    getFile: (state) => (path) => state.manifest.find((file) => file.path == path),

    exists: (state, getters) => (path) => !!getters.getFile(path),

    isLoaded: (state, getters) => (path) => getters.exists(path) && getters.getFile(path).loaded,

    isReserved: (state, getters) => (path) => getters.exists(path) && getters.getFile(path).reserved,

    isConverting: (state) => (path) => state.converting.indexOf(path) !== -1,

    hasError: (state, getters) => (path) => getters.exists(path) && getters.getFile(path).error,

    getBlob: (state, getters) => (path) => getters.exists(path) && getters.getFile(path).blob,

    filesLoading: (state) => state.manifest.filter((file) => !file.loaded && !file.error),

    filesLoaded: (state) => state.manifest.filter((file) => file.loaded),

    active: (state, getters) => !!state.preparing.length || !!getters.filesLoading.length,

    studioLoading: (state) => state.studioLoading
};

// Mutations
const mutations = {
    studioLoading(state, loading) {
        state.studioLoading = loading;
    },

    setTimestamp(state, timestamp) {
        state.timestamp = timestamp;
    },

    prepare(state, element) {
        if (state.preparing.indexOf(element) == -1) state.preparing.push(element);
    },

    prepared(state, element) {
        let index = state.preparing.indexOf(element);
        if (index != -1) state.preparing.splice(index, 1);
    },

    addConvert(state, element) {
        if (state.converting.indexOf(element) === -1) state.converting.push(element);
    },

    removeConvert(state, element) {
        let index = state.converting.indexOf(element);
        if (index !== -1) state.converting.splice(index, 1);
    },

    add(state, path) {
        if (!path.match(/^\s*$/)) {
            let fileIndex = state.manifest.findIndex((f) => f.path == path);
            if (fileIndex == -1) {
                console.log('add to queue: ' + path);
                fileIndex =
                    state.manifest.push({
                        path,
                        loaded: false,
                        error: false,
                        subscribers: 0,
                        reserved: false,
                        blob: ''
                    }) - 1;
            }
            state.manifest[fileIndex] = {
                ...state.manifest[fileIndex],
                subscribers: state.manifest[fileIndex].subscribers + 1
            };
        }
    },

    loaded(state, data) {
        let fileIndex = state.manifest.findIndex((f) => f.path == data.path);
        if (fileIndex != -1)
            state.manifest[fileIndex] = { ...state.manifest[fileIndex], loaded: true, blob: data.blob || '' };
    },

    error(state, path) {
        let fileIndex = state.manifest.findIndex((f) => f.path == path);
        if (fileIndex != -1) state.manifest[fileIndex] = { ...state.manifest[fileIndex], error: true };
    },

    remove(state, path) {
        let fileIndex = state.manifest.findIndex((f) => f.path == path);
        if (fileIndex != -1) {
            if (state.manifest[fileIndex].subscribers > 1)
                state.manifest[fileIndex] = {
                    ...state.manifest[fileIndex],
                    subscribers: state.manifest[fileIndex].subscribers - 1
                };
            else {
                console.log('remove from queue: ' + path);
                if (state.manifest[fileIndex].blob) window.URL.revokeObjectURL(state.manifest[fileIndex].blob);
                state.manifest.splice(fileIndex, 1);
            }
        }
    },

    reserve(state, path) {
        let fileIndex = state.manifest.findIndex((f) => f.path == path);
        if (fileIndex != -1 && !state.manifest[fileIndex].reserved) {
            state.manifest[fileIndex] = { ...state.manifest[fileIndex], reserved: true };
        }
    },

    release(state, path) {
        let fileIndex = state.manifest.findIndex((f) => f.path == path);
        if (fileIndex != -1 && !!state.manifest[fileIndex].reserved) {
            state.manifest[fileIndex] = { ...state.manifest[fileIndex], reserved: false };
        }
    }
};

// Actions
const actions = {
    clearState({ state }) {
        Object.assign(state, originalState());
    },
    setStudioLoading({ commit }, loading) {
        commit('studioLoading', loading);
    }
};

export default {
    namespaced: true,

    state,
    getters,
    mutations,
    actions
};
