import { mapMutations } from 'vuex';

export default {
    props: {
        id: {
            type: String
        },
        oldFocus: {
            type: Element,
            default: null
        }
    },

    data() {
        return {
            show: false
        };
    },

    computed: {
        disabled() {
            return false;
        },

        modalClasses() {
            return {
                show: this.show
            };
        },

        transitionEndEvent() {
            return !this.show ? 'transitionend' : null;
        }
    },

    watch: {
        //
    },

    methods: {
        ...mapMutations({
            addModal: 'ui/modals/add'
        }),

        open() {
            this.show = true;
            this.startCapturingFocus();
        },

        close() {
            if (!this.disabled) {
                this.show = false;
                this.endCapturingFocus();
            }
        },

        destroy(event) {
            console.log('BaseModal.js: destroy');
            if (event.target == this.$el && event.propertyName == 'visibility') {
                this.$store.commit('ui/modals/remove', this.id);
            }
        },

        startCapturingFocus() {
            this.$el.focus();
            document.addEventListener('focus', this.captureFocus, true);
        },

        captureFocus(event) {
            if (!this.$el.contains(event.target)) {
                event.stopPropagation();
                this.$el.focus();
            }
        },

        endCapturingFocus() {
            document.removeEventListener('focus', this.captureFocus, { capture: true, once: true });
            this.oldFocus?.focus();
        }
    },

    mounted() {
        // Note: Using setTimeout() instead of $nextTick() because of strange behavior
        // where $nextTick() seems too fast for the DOM to trigger the CSS transition
        setTimeout(this.open);
    }
};
