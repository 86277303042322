<template>
    <div class="edit-client-user-list-item">
        <div>
            <img :src="defaultUserImage" class="edit-client-user-image" alt="" />
        </div>
        <div class="edit-client-user-main-data">
            <div>{{ `${user.firstName} ${user.lastName}` }}</div>
            <div class="edit-client-user-email text-light-gray-alt">{{ user.email }}</div>
        </div>
        <div class="text-center">
            <span v-if="user.role">{{ $t(Roles[user.roleId]) }}</span>
        </div>
        <div class="text-center text-light-gray-alt">
            <span v-if="user.nbVideos">{{ sprintf($t('%1$d video(s)'), user.nbVideos) }}</span>
        </div>
        <div class="text-center text-light-gray-alt">
            <span v-if="user.connectedAt">{{ getTimeSince(new Date(user.connectedAt)) }}</span>
        </div>
        <div class="edit-client-user-actions">
            <div
                class="ui-simple-button ui-simple-button-gradient-hover edit-client-user-action-button"
                @click="modify(user)"
            >
                <fa-icon class="icon" icon="fa-regular fa-pen-to-square" />
                <span>{{ $t('Modify') }}</span>
            </div>
            <div
                v-if="isCTE && user.roleId !== Roles.ADMIN_ROLE_ID"
                class="ui-simple-button ui-simple-button-dark-reversed edit-client-user-action-button"
                @click="loginAs(user)"
            >
                <fa-icon class="icon" icon="fa-regular fa-right-to-bracket" />
                <span>{{ $t('Login as') }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import defaultUserImage from '@/assets/images/logo.png';
import { printf as sprintf } from 'fast-printf';
import { useDates } from '@/js/composables/useDates.js';
import UserDTO from '@/js/dto/UserDTO.js';
import { useModal } from '@/js/composables/useModal.js';
import Roles from '../../../js/constants/roles.js';
import { onMounted } from 'vue';
import UserDataService from '@/js/application/services/user/UserDataService.js';
import { useStore } from 'vuex';
import { useAuth } from '@/js/composables/useAuth.js';

const store = useStore();

const props = defineProps({
    user: {
        type: UserDTO,
        required: true
    }
});

const { getTimeSince } = useDates();
const { openModal } = useModal();
const { isCTE } = useAuth();

const modify = (user) => {
    openModal('EditUserModal', { user });
};

const loginAs = (user) => {
    new UserDataService(store).loginAs(user.id).then(() => {
        window.location.assign(window.location.origin);
    });
};

onMounted(() => {
    new UserDataService(store).getNbVideos(props.user.id).then((nbVideos) => {
        props.user.nbVideos = Number.parseInt(nbVideos);
    });
});
</script>
