export const SQUARE_FORMAT = 'square';
export const PORTRAIT_FORMAT = 'portrait';
export const WIDESCREEN_FORMAT = 'widescreen';
export const AVAILABLE_FORMATS = [SQUARE_FORMAT, PORTRAIT_FORMAT, WIDESCREEN_FORMAT];

export const FORMAT_ICONS = {
    [SQUARE_FORMAT]: 'fa-regular fa-square',
    [PORTRAIT_FORMAT]: 'fa-regular fa-rectangle-vertical',
    [WIDESCREEN_FORMAT]: 'fa-regular fa-rectangle'
};

export const STYLE_NONE = 'none';
export const STYLE_PHOTO = 'photo';
const STYLE_VECTOR = 'vector';
export const AVAILABLE_STYLES = [
    STYLE_NONE,
    STYLE_PHOTO,
    'studio-shot',
    'vintage',
    '70s-vibe',
    '3d',
    STYLE_VECTOR,
    'cartoon',
    'comic',
    'anime',
    'pixel-art',
    'digital-art',
    'art-nouveau',
    'painting',
    'watercolor',
    'sketch',
    'origami',
    'surreal',
    'dark',
    'cyberpunk'
];

import businessWoman from '@/assets/image-generation/suggestions/businesswoman.jpeg';
import plantGrowing from '@/assets/image-generation/suggestions/plantgrowing.jpeg';
import familyRestaurant from '@/assets/image-generation/suggestions/familyrestaurant.jpg';
export const PROMPT_SUGGESTIONS = [
    {
        // Une femme d'affaires qui travaille dans un bureau
        promptKey: 'image-generation.first-prompt',
        image: businessWoman,
        style: STYLE_PHOTO
    },
    {
        // Une plante poussant dans une forêt
        promptKey: 'image-generation.second-prompt',
        image: plantGrowing,
        style: STYLE_PHOTO
    },
    {
        // Une famille au restaurant
        promptKey: 'image-generation.third-prompt',
        image: familyRestaurant,
        style: STYLE_VECTOR
    }
];

export const FORBIDDEN_WORDS = [
    // FR
    'abattage',
    'baiser',
    'bite',
    'blessure',
    'bordel',
    'boyaux',
    'caca',
    'cadavre',
    'cannibale',
    'cannibalisme',
    'censuré',
    'chirurgie',
    'cocaïne',
    'con',
    'couper',
    'crucifixion',
    'crucifié',
    'cul',
    'dominatrice',
    'drogues',
    'décapiter',
    'dérangeant',
    'érotique',
    'esclave',
    'excité',
    'fasciste',
    'hémoglobine',
    'héroïne',
    'inapproprié',
    'inceste',
    'infecté',
    'infesté',
    'mamelon',
    'massacre',
    'menottes',
    'merde',
    'méthamphétamine',
    'nazi',
    'nu',
    'nue',
    'nus',
    'nudisme',
    'nudiste',
    'nudité',
    'organes',
    'orgie',
    'ovaires',
    'phallus',
    'playboy',
    'pénis',
    'sadique',
    'sang',
    'sanglant',
    'seins',
    'sensuel',
    'sexy',
    'sperme',
    'succube',
    'suicide',
    'sulfureux',
    'tabou',
    'torture',
    'tryphophobie',
    'tuer',
    'twerk',
    'vagin',
    'viscéral',
    'vivisection',
    'vomir',

    // EN
    'arse',
    'ass',
    'badonkers',
    'bdsm',
    'bimbo',
    'blood',
    'bloodbath',
    'bloodshot',
    'bloody',
    'bondage',
    'booba',
    'booty',
    'bosom',
    'breasts',
    'brown pudding',
    'bruises',
    'bunghole',
    'busty',
    'cannibal',
    'cannibalism',
    'car crash',
    'censored',
    'clunge',
    'cocaine',
    'coon',
    'corpse',
    'crack',
    'cronenberg',
    'crotch',
    'crucified',
    'crucifixion',
    'cutting',
    'decapitate',
    'deepfake',
    'dick',
    'disturbing',
    'drugs',
    'errect',
    'fart',
    'fascist',
    'flesh',
    'girth',
    'gory',
    'gruesome',
    'guts',
    'handcuffs',
    'hardcore',
    'hemoglobin',
    'hentai',
    'heroin',
    'honkers',
    'honkey',
    'hooters',
    'hot',
    'inappropriate',
    'infected',
    'infested',
    'jail',
    'jerk',
    'khorne',
    'kill',
    'killing',
    'knob',
    'labia',
    'mammaries',
    'massacre',
    'meth',
    'minge',
    'naked',
    'nazi',
    'nipple',
    'nude',
    'nudity',
    'oppai',
    'organs',
    'ovaries',
    'penis',
    'phallus',
    'playboy',
    'pleasure',
    'poop',
    'pus',
    'sadist',
    'seductive',
    'sensored',
    'sexy',
    'shag',
    'shit',
    'silenced',
    'skimpy',
    'slaughter',
    'slave',
    'slavegirl',
    'smut',
    'sperm',
    'succubus',
    'suicide',
    'sultry',
    'surgery',
    'taboo',
    'teratoma',
    'thick',
    'torture',
    'tryphophobia',
    'vagina',
    'veiny',
    'visceral',
    'vivisection',
    'voluptuous',
    'vomit',
    'waifu',
    'warts',
    'wound',
    'xxx',
    'ahegao',
    'ballgag',
    'boudoir',
    'brothel',
    'dominatrix',
    'erotic',
    'fuck',
    'horny',
    'incest',
    'jav',
    'kinbaku',
    'naughty',
    'orgy',
    'pinup',
    'pleasure',
    'pleasures',
    'rule34',
    'seducing',
    'seductive',
    'sensual',
    'sexy',
    'shibari',
    'succubus',
    'thot',
    'transparent',
    'twerk',
    'voluptuous',
    'wincest'
];
