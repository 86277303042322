<template>
    <div
        v-if="!error"
        class="asset-container image-container"
        ref="$imageContainer"
        :class="containerClasses"
        :style="containerStyles"
    >
        <svg v-if="color.start" class="studio-hidden-svg">
            <defs>
                <filter :id="colorFilterID">
                    <feFlood :flood-color="color.start" />
                    <feComposite operator="in" in2="SourceAlpha" />
                </filter>
            </defs>
        </svg>
        <img :src="blobSrc || null" :ref="imageReference" class="asset" :style="imageComputedStyles" />
        <svg v-if="detection.browser.edge">
            <image
                :xlink:href="blobSrc"
                :ref="svgImageReference"
                :preserveAspectRatio="svgImageAspectRatio"
                width="100%"
                height="100%"
                :style="imageComputedStyles"
            />
        </svg>
    </div>
</template>

<script>
import { Dimension } from '../../constants';
import { fastdom, conversions, randomID } from '../../utils';
import { mapState } from 'vuex';
import loader from '@/js/video-studio/loader.js';
import Croppable from '@/js/video-studio/mixins/Croppable.js';

export default {
    props: {
        src: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        classes: {
            type: String,
            default: ''
        },
        width: {
            type: String,
            default: Dimension.AUTO
        },
        height: {
            type: String,
            default: Dimension.AUTO
        },
        position: {
            type: Object,
            default: () => ({})
        },
        emSize: {
            type: Number,
            default: -1
        },
        keepAspectRatio: {
            type: Boolean,
            default: true
        },
        cover: {
            type: Boolean,
            default: false
        },
        color: {
            type: Object,
            default: () => ({})
        },
        styles: {
            type: Object,
            default: () => ({})
        },
        imageStyles: {
            type: Object,
            default: () => ({})
        }
    },

    mixins: [Croppable],

    data() {
        return {
            error: false,
            size: {
                width: 0,
                height: 0
            }
        };
    },

    computed: {
        ...mapState({
            detection: (state) => state.detection,
            format: (state) => state.display.format,
            avoidTimelineReflow: (state) => state.display.avoidTimelineReflow
        }),

        containerClasses() {
            return [
                this.classes,
                {
                    'keep-aspect-ratio': this.keepAspectRatio,
                    'cover-mode': this.cover,
                    'svg-mode': this.detection.browser.edge,
                    'asset-cropped': this.hasCropPosition
                }
            ];
        },

        containerStyles() {
            return Object.assign(
                {},
                this.styles,
                {
                    width: this.size.width || this.width,
                    height: this.size.height || this.height,
                    filter: this.color.start ? 'url(#' + this.colorFilterID + ')' : null
                },
                conversions.alignment(
                    this.position.alignH,
                    this.position.alignV,
                    this.position.alignX,
                    this.position.alignY
                )
            );
        },

        blobSrc() {
            return this.$store.getters['loading/getBlob'](this.src);
        },

        hasError() {
            return this.$store.getters['loading/hasError'](this.src);
        },

        imageReference() {
            return this.name + '-img';
        },

        imageComputedStyles() {
            return Object.assign(
                {},
                this.imageStyles,
                { width: this.width == Dimension.AUTO ? Dimension.AUTO : null },
                this.cropStyles
            );
        },

        svgImageReference() {
            return this.name + '-svg-img';
        },

        svgImageAspectRatio() {
            return this.keepAspectRatio ? 'xMidYMid ' + (this.cover ? 'slice' : 'meet') : 'none';
        },

        colorFilterID() {
            return randomID('asset') + '-color-filter';
        }
    },

    watch: {
        format(newValue, oldValue) {
            if (!this.cover && (newValue.width != oldValue.width || newValue.height != oldValue.height))
                this.updateSize();
        },

        src(newValue, oldValue) {
            this.updateSrc(newValue, oldValue);
        },

        width() {
            if (!this.cover) this.updateSize();
        },

        height() {
            if (!this.cover) this.updateSize();
        }
    },

    methods: {
        updateSrc(newValue, oldValue) {
            this.size = Object.assign({}, this.size, { width: 0, height: 0 });
            if (oldValue) loader.unload(oldValue, this.onImageError);
            if (newValue) {
                this.$store.commit('loading/prepare', this);
                if (!!this.$refs[this.imageReference]) {
                    this.setImageListeners(this.onImageLoaded, this.onImageError);
                    loader.load(newValue, this.onImageError);
                } else {
                    this.$nextTick(() => {
                        this.setImageListeners(this.onImageLoaded, this.onImageError);
                        loader.load(newValue, this.onImageError);
                    });
                }
            } else {
                this.removeImageListeners();
                this.$store.commit('loading/prepared', this);
            }
            this.error = !newValue;
        },

        updateSize(callback) {
            if (!!this.$refs[this.imageReference]) {
                this.size = Object.assign({}, this.size, { width: 0, height: 0 });

                this.$nextTick(async () => {
                    let s = { width: 0, height: 0 };

                    if (this.keepAspectRatio) {
                        await fastdom.measure(() => {
                            // Note: The component may have been unmounted between
                            // registering this call, and executing this call
                            if (this._destroyed) return;

                            let r =
                                    this.$refs[this.imageReference].naturalWidth /
                                    this.$refs[this.imageReference].naturalHeight,
                                cw = this.$refs.$imageContainer.clientWidth,
                                ch = this.$refs.$imageContainer.clientHeight,
                                rc = cw / ch;

                            if (rc > r) {
                                s.width = Math.round(r * ch);
                                if (this.height == Dimension.AUTO) s.height = ch;
                            } else {
                                s.height = Math.round(cw / r);
                                if (this.width == Dimension.AUTO) s.width = cw;
                            }
                            if (this.emSize == -1) {
                                s.width = s.width ? s.width + Dimension.PIXEL_UNIT : s.width;
                                s.height = s.height ? s.height + Dimension.PIXEL_UNIT : s.height;
                            } else {
                                s.width = s.width ? s.width / this.emSize + Dimension.EM_UNIT : s.width;
                                s.height = s.height ? s.height / this.emSize + Dimension.EM_UNIT : s.height;
                            }
                        });
                    }

                    this.size = s;
                    if (callback) this.$nextTick(callback);
                });
            }
        },

        setImageListeners(onLoad, onError) {
            this.$refs[this.imageReference].onload = onLoad;
            this.$refs[this.imageReference].onerror = onError;
        },

        removeImageListeners() {
            if (!!this.$refs[this.imageReference]) {
                this.setImageListeners(null, null);
            }
        },

        onImageLoaded() {
            this.removeImageListeners();
            if (this.cover) {
                this.$emit('load-success');
                this.$store.commit('loading/prepared', this);
            } else {
                this.updateSize(() => {
                    this.$emit('load-success');
                    if (!this.avoidTimelineReflow) this.$emit('update', { el: this.$refs.$imageContainer });
                    this.$store.commit('loading/prepared', this);
                });
            }
        },

        onImageError() {
            if (this.blobSrc || this.hasError) {
                this.removeImageListeners();
                this.error = true;
                this.$store.commit('loading/prepared', this);
                this.$emit('load-error');
            }
        }
    },

    mounted() {
        this._destroyed = false;
        this.updateSrc(this.src);
    },

    beforeUnmount() {
        this.updateSrc('', this.src);
        this._destroyed = true;
    }
};
</script>
