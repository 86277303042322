import FetchVideoApiService from '@/js/infrastructure/api/video/FetchVideoApiService.js';
import StoreCurrentVideoDataService from '@/js/infrastructure/store/video/StoreCurrentVideoDataService.js';

export default class UpdateCurrentVideoDataService {
    constructor(store) {
        this.fetchVideoApiService = new FetchVideoApiService(store);
        this.storeCurrentVideoDataService = new StoreCurrentVideoDataService(store);
    }

    handle(id) {
        this.fetchVideoApiService.handle(id).then((response) => {
            this.storeCurrentVideoDataService.handle(response.data);
        });
    }
}
