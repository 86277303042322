<template>
    <ui-dropdown
        v-if="isEmotionMode"
        :id="`${cardElementId}-style`"
        class="style-dropdown centered"
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="bottom"
        menu-strategy="fixed"
        :toggle-style="toggleStyle"
    >
        <template #dropdown-toggle>
            <fa-icon class="icon" icon="fa-regular fa-sparkles" />
            <span>{{ $t('Style') }}</span>
        </template>
        <ui-switch-button
            v-if="hasCollageBackground"
            :id="`${cardElementId}-enable-collage-animation`"
            class="ui-content-row"
            :label="$t('Animate')"
            v-model="collageAnimationEnabled"
            :disabled="readOnly"
        />
        <ui-switch-button
            :id="`${cardElementId}-hide-brand-logo`"
            class="ui-content-row"
            :label="$t('Hide brand logo')"
            v-model="hiddenBrandLogo"
            :disabled="readOnly"
        />
        <ui-switch-button
            v-if="!hasColorBackground"
            :id="`${cardElementId}-enable-blur`"
            class="ui-content-row"
            :label="$t('Blur background')"
            v-model="enableBlur"
            :disabled="readOnly || hasPreviousBackground"
        />
        <ui-switch-button
            v-if="!hasColorBackground"
            :id="`${cardElementId}-enable-bg-overlay`"
            class="ui-content-row"
            :label="$t('Add color effect')"
            v-model="enableOverlay"
            :disabled="readOnly || hasPreviousBackground"
        />
        <ui-background-overlay-selector :seq-id="seqId"></ui-background-overlay-selector>

        <ui-switch-button
            v-if="hasMapZoomBackground"
            :id="cardElementId + '-bg-map-zoom-enable-marker'"
            class="ui-content-row"
            :label="$t('Show map marker')"
            v-model="enableMapZoomMarker"
            :disabled="readOnly || hasPreviousBackground"
        />
        <!-- <ui-media-selector
            v-if="hasMapZoomBackground && enableMapZoomMarker"
            :id="cardElementId + '-bg-map-zoom-marker'"
            class="ui-content-row"
            v-model="mapZoomMarker"
            :type="mediaTypeIcon"
            :label="$t('Custom map marker')"
            :disabled="readOnly || hasPreviousBackground"
            @[mediaSelectorShowEvent]="openMediaLibrary"
        /> -->
    </ui-dropdown>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import UiIcon from '../../../../../../components/UiIcon.vue';
import { getPrefixedUuid } from '../../../../../../utils';

import UiDropdown from '../../../../../../components/UiDropdown.vue';
import UiSwitchButton from '../../../../../../components/UiSwitchButton.vue';
import UiBackgroundOverlaySelector from '../../UiBackgroundOverlaySelector.vue';

import { useHistory } from '../../../../../composables/useHistory';
import { useSequence } from '../../../../../composables/useSequence';
import { useBrand } from '../../../../../composables/useBrand';
import { useVideo } from '../../../../../composables/useVideo';
import { Overlay } from 'cte-video-studio';

import { UI_MEDIA_TYPE_ICON } from '../../../../../../components/UiMediaSelector.vue';

export default defineComponent({
    name: 'UiStyleBackgroundSelector',
    components: { UiIcon, UiBackgroundOverlaySelector, UiDropdown, UiSwitchButton },
    props: {
        seqId: String,
        readOnly: Boolean,
        cardElementId: String,
        iconOnly: Boolean
    },
    setup(props) {
        const store = useStore();
        const { saveHistoryStep } = useHistory();
        const { isBrandLibraryEmpty } = useBrand();
        const { isEmotionMode } = useVideo();
        const {
            sequenceState,
            sequenceStoreModulePath,
            hasCollageBackground,
            hasColorBackground,
            hasPreviousBackground,
            hasMapZoomBackground,
            resolvedBackgroundState
        } = useSequence(props.seqId);

        const collageAnimationEnabled = computed({
            get: () => sequenceState.value.background.collage.animated,
            set: (value) => {
                saveHistoryStep(() => {
                    store.commit(`${sequenceStoreModulePath.value}/setBackgroundCollageAnimation`, value);
                });
            }
        });

        const hiddenBrandLogo = computed({
            get: () => !sequenceState.value.options.logo.enabled,
            set: (value) => {
                saveHistoryStep(() => store.commit(`${sequenceStoreModulePath.value}/enableLogo`, !value));
            }
        });

        const enableBlur = computed({
            get: () => resolvedBackgroundState.value.blur,
            set: (value) => {
                if (!hasPreviousBackground.value) {
                    saveHistoryStep(() => store.commit(`${sequenceStoreModulePath.value}/enableBackgroundBlur`, value));
                }
            }
        });

        const enableOverlay = computed({
            get: () => resolvedBackgroundState.value.overlay.enabled,
            set: (value) => {
                if (!hasPreviousBackground.value) {
                    saveHistoryStep(() => {
                        store.commit(`${sequenceStoreModulePath.value}/enableBackgroundOverlay`, value);
                        if (value && isBrandLibraryEmpty('overlays') && overlayType.value != Overlay.DEFAULT) {
                            store.commit(`${sequenceStoreModulePath.value}/setBackgroundOverlay`, Overlay.DEFAULT);
                        }
                    });
                }
            }
        });

        const overlayType = computed({
            get: () => resolvedBackgroundState.value.overlay.type,
            set: (value) => {
                if (!hasPreviousBackground.value) {
                    saveHistoryStep(() => store.commit(`${sequenceStoreModulePath.value}/setBackgroundOverlay`, value));
                }
            }
        });

        const enableMapZoomMarker = computed({
            get: () => {
                return resolvedBackgroundState.value.mapZoom.marker.enabled;
            },
            set(value) {
                if (!hasPreviousBackground.value) {
                    saveHistoryStep(() => {
                        store.commit(sequenceStoreModulePath.value + '/enableBackgroundMapZoomMarker', value);
                    });
                }
            }
        });

        const mapZoomMarker = computed({
            get: () => {
                let ref = { src: '', id: '' };

                if (hasMapZoomBackground.value && enableMapZoomMarker.value) {
                    ref.src = resolvedBackgroundState.value.mapZoom.marker.src;
                    ref.id = resolvedBackgroundState.value.mapZoom.marker.src__id;
                }

                return ref;
            },
            set({ src, id }) {
                if (!hasPreviousBackground.value) {
                    saveHistoryStep(() => {
                        if (!sequenceState.value.background.mapZoom.marker.src__ref) {
                            store.commit(
                                sequenceStoreModulePath.value + '/setBackgroundMapZoomMarkerReference',
                                getPrefixedUuid(prefixes.value.mediaReference)
                            );
                        }
                        store.dispatch(sequenceStoreModulePath.value + '/updateBackgroundMapZoomMarker', {
                            src,
                            src__id: id
                        });
                    });
                }
            }
        });

        const toggleStyle = computed(() => {
            if(
                (hasCollageBackground.value && collageAnimationEnabled.value ) ||
                hiddenBrandLogo.value ||
                (!hasColorBackground.value && enableBlur.value) ||
                (!hasColorBackground.value && enableOverlay.value) ||
                (hasMapZoomBackground.value && enableMapZoomMarker.value)
            ) {
                return { color: 'var(--edit-mode-color)' };
            } else return {};
        });

        return {
            isEmotionMode,
            hiddenBrandLogo,
            enableBlur,
            enableOverlay,
            enableMapZoomMarker,
            collageAnimationEnabled,
            hasCollageBackground,
            hasColorBackground,
            hasPreviousBackground,
            hasMapZoomBackground,
            mediaTypeIcon: UI_MEDIA_TYPE_ICON,
            mapZoomMarker,
            toggleStyle
        };
    }
});
</script>
