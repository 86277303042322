<template>
    <li class="ui-companion-video ui-companion-video-created" :class="containerClasses">
        <div class="ui-video-preview" @click="emitClickEvent">
            <template v-if="!selected">
                <svg-icon icon="play-icon" />
                <p class="ui-video-preview-title">{{ video.name }}</p>
            </template>
            <template v-else>
                <iframe :src="videoPreviewUrl" allowtransparency="true" />
            </template>
        </div>
        <div class="ui-video-details">
            <h3>{{ video.name }}</h3>
            <p>{{ video.description }}</p>
            <div class="ui-video-tools">
                <button class="ui-video-edit" @click.prevent="emitUseEvent">{{ $t('Use') }}</button>
                <ui-dropdown
                    id="video-target-folders"
                    :select="true"
                    :menu="false"
                    :items="folderItems"
                    v-model="targetFolderId"
                >
                    <template #dropdown-toggle="{ instance }">
                        <svg-icon icon="folder-icon" />
                        {{ instance.toggleLabel }}
                    </template>
                </ui-dropdown>
            </div>
        </div>
    </li>
</template>

<script>
import { UiDropdown, UiIcon } from '../../../../components';

export const UI_COMPANION_VIDEO_CLICK = 'ui-companion-video-click';
export const UI_COMPANION_VIDEO_USE = 'ui-companion-video-use';

const BASE_URL = import.meta.env.VITE_APP_URL;

export default {
    emits: [UI_COMPANION_VIDEO_CLICK, UI_COMPANION_VIDEO_USE],

    components: {
        UiDropdown,
        UiIcon
    },

    props: {
        video: {
            type: Object,
            default: null
        },
        format: {
            type: String,
            default: '16x9'
        },
        selected: {
            type: Boolean,
            default: false
        },
        folders: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            targetFolderId: null
        };
    },

    computed: {
        containerClasses() {
            return [
                'ui-video-format-' + this.format,
                {
                    selected: this.selected
                }
            ];
        },

        folderItems() {
            return this.folders.map(({ id, name }) => ({ value: id, label: name, tooltip: name }));
        },

        videoPreviewUrl() {
            return BASE_URL + '/companion/' + this.video.id + '/preview';
        }
    },

    methods: {
        emitClickEvent() {
            this.$emit(UI_COMPANION_VIDEO_CLICK, this);
        },

        emitUseEvent() {
            this.$emit(UI_COMPANION_VIDEO_USE, { videoId: this.video.id, folderId: this.targetFolderId });
        }
    },

    created() {
        this.targetFolderId = this.folderItems[0]?.value;
    },

    mounted() {
        //
    }
};
</script>
