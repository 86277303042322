import { computed } from 'vue';
import { useStore } from 'vuex';

export function useLoading() {
    const store = useStore();

    const active = computed(() => store.state.loading.active);
    const studioLoading = computed(() => store.state.loading.studioLoading);
  
    return {
        active,
        studioLoading
    };
}
