/**
 * Edit video Vuex store extension
 */

import i18n from './modules/i18n.js';
import detection from './modules/detection.js';
import edition from './modules/edition.js';
import loading from './modules/loading.js';
import display from './modules/display.js';
import branding from './modules/branding/index.js';
import settings from './modules/settings/index.js';
import sequences from './modules/sequences.js';
import ui from './modules/ui/index.js';
import preview from './modules/preview/index.js';
import auth from '@/js/store/modules/auth.js';
import promptsStoreConfig from '@/js/store/modules/prompts/index.js';
import currentPromptStoreConfig from '@/js/store/modules/current-prompt/index.js';
import modals from '@/js/store/modules/modals.js';
import videos from '@/js/store/modules/videos.js';

export default {
    modules: {
        i18n,
        detection,
        edition,
        loading,
        display,
        branding,
        settings,
        sequences,
        ui,
        preview,
        auth,
        videos,
        prompts: promptsStoreConfig,
        currentPrompt: currentPromptStoreConfig,
        modals
    }
};
