/**
 * Video Studio
 * Timeline Vuex store module
 */

import gsap from 'gsap';

// Initial state
const state = () => ({
    timeline: () => gsap.timeline(),
    timelineOut: () => gsap.timeline(),
    needsRefresh: false
});

// Getters
const getters = {};

// Mutations
const mutations = {
    setTimeline(state, timeline) {
        state.timeline = timeline;
    },

    setTimelineOut(state, timeline) {
        state.timelineOut = timeline;
    },

    enableRefresh(state, enable) {
        state.needsRefresh = enable;
    }
};

// Actions
const actions = {};

export default {
    namespaced: true,

    state,
    getters,
    mutations,
    actions
};
