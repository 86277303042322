/**
 * Video Studio
 * Display data Vuex store module
 */

import { Format, Message, Production, Sound } from '../../../js/video-studio/constants';
import { types } from '../../../js/video-studio/utils';

const originalState = () => ({
    readOnly: false,
    preview: true,
    previewStart: -1,
    edit: false,
    format: {
        width: Format.WIDTH_DEFAULT,
        height: Format.HEIGHT_DEFAULT
    },
    timeline: {
        autoplay: false,
        playing: false,
        seeking: [],
        muted: false,
        labels: {},
        sequencesLabels: {},
        currentLabel: 'start',
        fallbackLabel: 'start',
        currentSequences: [],
        currentTransition: false,
        sequenceElapsedTime: 0,
        sequenceTotalTime: 0,
        elapsedTime: 0,
        totalTime: 0
    },
    warning: {
        active: false,
        stack: []
    },
    captureMode: false,
    avoidTimelineReflow: false
});

const state = {
    ...originalState()
};

// Getters
const getters = {
    formatString: (state) => state.format.width + 'x' + state.format.height,

    formatRatio: (state) => state.format.width / state.format.height,

    formatRatioString: (state) => {
        let r = state.format.width / state.format.height;
        return Format.RATIOS.indexOf(r) != -1
            ? Format.RATIOS_STRING[Format.RATIOS.indexOf(r)]
            : Format.RATIO_STRING_DEFAULT;
    },

    formatFontSize: (state) =>
        Number(((state.format.height * Message.FONT_SIZE_DEFAULT) / Format.HEIGHT_DEFAULT).toFixed(2)),

    formatFontRatio: (state) => state.format.height / Format.HEIGHT_DEFAULT,

    seeking: (state, getters) => !!state.timeline.seeking.length
};

// Mutations
const mutations = {
    setReadOnly(state, readOnly) {
        state.readOnly = readOnly;
    },

    setPreview(state, preview) {
        state.preview = preview;
    },

    setPreviewStart(state, start) {
        state.previewStart = types.isZeroPositive(start) ? start : -1;
    },

    setEdit(state, edit) {
        state.edit = edit;
    },

    setAutoplay(state, autoplay) {
        state.timeline = { ...state.timeline, autoplay }; //, muted: autoplay || state.timeline.muted };
    },

    setPlaying(state, playing) {
        state.timeline = { ...state.timeline, playing };
    },

    seek(state, asset) {
        if (state.timeline.seeking.indexOf(asset) == -1) state.timeline.seeking.push(asset);
    },

    seeked(state, asset) {
        let index = state.timeline.seeking.indexOf(asset);
        if (index != -1) state.timeline.seeking.splice(index, 1);
    },

    setMuted(state, muted) {
        state.timeline = { ...state.timeline, muted };
    },

    setLabels(state, labels) {
        state.timeline.labels = labels;
    },

    setSequencesLabels(state, labels) {
        state.timeline.sequencesLabels = labels;
    },

    setCurrentLabel(state, currentLabel) {
        state.timeline = { ...state.timeline, currentLabel: currentLabel || 'start' };
    },

    setFallbackLabel(state, fallbackLabel) {
        state.timeline = { ...state.timeline, fallbackLabel: fallbackLabel || 'start' };
    },

    setCurrentSequences(state, currentSequences) {
        currentSequences = !Array.isArray(currentSequences) ? [currentSequences] : currentSequences;
        state.timeline = { ...state.timeline, currentSequences };
    },

    setCurrentTransition(state, currentTransition) {
        state.timeline = { ...state.timeline, currentTransition };
    },

    setSequenceElapsedTime(state, sequenceElapsedTime) {
        state.timeline = { ...state.timeline, sequenceElapsedTime };
    },

    setSequenceTotalTime(state, sequenceTotalTime) {
        state.timeline = { ...state.timeline, sequenceTotalTime };
    },

    setElapsedTime(state, elapsedTime) {
        state.timeline = { ...state.timeline, elapsedTime };
    },

    setTotalTime(state, totalTime) {
        state.timeline = { ...state.timeline, totalTime };
    },

    setFormatSize(state, format) {
        let w = parseFloat(format.width),
            h = parseFloat(format.height);
        state.format = {
            width: types.isPositive(w) ? w : Format.WIDTH_DEFAULT,
            height: types.isPositive(h) ? h : Format.HEIGHT_DEFAULT
        };
    },

    addWarning(state, warning) {
        state.warning.active = true;
        if (state.warning.stack.indexOf(warning) == -1) state.warning.stack.push(warning);
    },

    removeWarning(state, warning) {
        let index = state.warning.stack.indexOf(warning);
        if (index != -1) state.warning.stack.splice(index, 1);
        state.warning.active = !!state.warning.stack.length;
    },

    setCaptureMode(state, mode) {
        state.captureMode = types.isCaptureMode(mode) ? mode : Production.FULL_CAPTURE_MODE;
    },

    setAvoidTimelineReflow(state, avoid) {
        state.avoidTimelineReflow = avoid;
    }
};

// Actions
const actions = {
    setReadOnly({ commit }, readOnly) {
        commit('setReadOnly', readOnly);
    },

    setFormat({ commit }, format) {
        commit('setFormatSize', format);
    },

    setPreviewStart({ commit, state, rootGetters }, start) {
        commit('setPreviewStart', start);
        if (state.previewStart >= 0) commit('setCurrentLabel', rootGetters['sequences/all'][state.previewStart].id);
    },

    updateTimeline({ commit }, data) {
        commit('setCurrentLabel', data.currentLabel);
        commit('setFallbackLabel', data.fallbackLabel);
        commit('setCurrentSequences', data.currentSequences);
        commit('setCurrentTransition', data.currentTransition);
        commit('setSequenceElapsedTime', data.sequenceElapsedTime);
        commit('setSequenceTotalTime', data.sequenceTotalTime);
        commit('setElapsedTime', data.elapsedTime);
        commit('setTotalTime', data.totalTime);
    },

    updateTimelineLabels({ commit }, { labels, sequencesLabels }) {
        commit('setLabels', labels);
        commit('setSequencesLabels', sequencesLabels);
    },

    clearState({ state }) {
        Object.assign(state, originalState());
    }
};

export default {
    namespaced: true,

    state,
    getters,
    mutations,
    actions
};
