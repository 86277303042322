<template>
    <div class="ui-settings-item ui-sidebar-item" :class="itemClasses">
        <a class="ui-sidebar-item-handle" id="video-onboarding-step-1" @click="handleItemSelect" v-once>
            <svg-icon icon="video-settings-icon" />
        </a>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { SETTINGS_ITEM_ID } from '../../../../constants';
import { UiIcon } from '../../../../components';
import { ModifiesHistory } from '../../../mixins';
import SvgIcon from '@/js/components/SvgIcon.vue';

export default {
    mixins: [ModifiesHistory],

    components: {
        SvgIcon,
        UiIcon
    },

    props: {
        //
    },

    data() {
        return {
            //
        };
    },

    computed: {
        ...mapState({
            state: (state) => state.settings,
            currentEditedItemId: (state) => state.ui.currentEditedItemId
        }),

        ...mapGetters({
            // sequences: 'sequences/all',
        }),

        itemClasses() {
            return {
                active: this.currentEditedItemId == SETTINGS_ITEM_ID
            };
        }
    },

    watch: {
        //
    },

    methods: {
        handleItemSelect() {
            if (this.currentEditedItemId != SETTINGS_ITEM_ID) {
                this.startHistoryStep();
                this.$store.dispatch('ui/updateCurrentEditedItem', SETTINGS_ITEM_ID);
            }
        }
    },

    mounted() {
        //
    }
};
</script>
