<template>
    <section class="ui-body-panel" role="application" aria-describedby="ui-settings-title">
        <h2 id="ui-settings-title" class="visually-hidden">{{ $t('brands.panels.settings') }}</h2>

        <div class="ui-card-container">
            <card-settings-identity class="col-xl-6" />
            <card-settings-fonts class="col-xl-6" />
            <card-settings-colors />
            <card-settings-template-languages class="col-xl-6" />
            <card-settings-brand-voice-over class="col-xl-6" />
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import {
    CardSettingsIdentity,
    CardSettingsFonts,
    CardSettingsColors,
    CardSettingsTemplateLanguages,
    CardSettingsBrandVoiceOver
} from './cards';

export default {
    components: {
        CardSettingsIdentity,
        CardSettingsFonts,
        CardSettingsColors,
        CardSettingsTemplateLanguages,
        CardSettingsBrandVoiceOver
    },

    props: {
        //
    },

    data() {
        return {
            //
        };
    },

    computed: {
        ...mapGetters({
            //
        })
    },

    watch: {
        //
    },

    methods: {
        //
    },

    mounted() {
        //
    }
};
</script>
