import { Align, Dimension, Edition } from '../constants';
import { conversions } from '../utils';
import { mapState } from 'vuex';

export default {
    inject: ['$stage'],

    data() {
        return {
            previewing: false,
            needRefreshFrame: false,
            editingConf: {
                corners: Edition.CORNERS,
                borders: Edition.BORDERS,
                movement: true,
                initFrameBeforeTransform: false,
                homotheticOnCorners: true,
                disableSizePreview: false,
                clearnessPreview: false,
                clearBeforePreview: false,
                previewAttribute: null,
                disableFinalState: false,
                homotheticMinimalSize: true
            }
        };
    },

    computed: {
        ...mapState({
            editingElement: (state) => state.edition.editingElement,
            editing: (state) => state.edition.editing,
            edit: (state) => state.display.edit,
            currentSequences: (state) => state.display.timeline.currentSequences,
            state(state) {
                return state.sequences[this.seqId][this.id];
            },
            convertDistributedAlign(state) {
                return state.sequences[this.seqId].convertDistributedAlign;
            }
        }),

        editingData() {
            return this.editingConf;
        },

        isEdited() {
            return (
                this.edit &&
                !!this.editingElement &&
                this.editingElement.id === this.id &&
                this.currentSequences.indexOf(this.seqId) !== -1
            );
        },

        isEditing() {
            return (
                this.edit &&
                this.editingElement &&
                this.editing &&
                this.editingElement.id === this.id &&
                this.currentSequences.indexOf(this.seqId) !== -1
            );
        },

        isDistributedAlign() {
            return this.$store.getters['sequences/' + this.seqId + '/' + this.id + '/isDistributedAlign'];
        },

        isHalfPosition() {
            return this.$store.getters['sequences/' + this.seqId + '/' + this.id + '/isHalfPosition'];
        },

        commonRefreshingProperties() {
            return [...Object.values(this.state.position)];
        },

        refreshingPropertiesString() {
            return this.commonRefreshingProperties.toString();
        }
    },

    watch: {
        convertDistributedAlign(convert) {
            if (convert && this.isDistributedAlign) this.convertCustomPosition();
        },

        refreshingPropertiesString() {
            if (this.isEdited && !this.editing) this.needRefreshFrame = true;
        },

        isEdited(val, oldVal) {
            if (!this.editingData.disableFinalState) {
                if (val && !oldVal) {
                    this.setFinalState();
                } else if (!val && oldVal && this.$stage._timeline.time() === this._stageTimeBeforeEdit) {
                    this._timeline.time(this._timeBeforeEdit);
                    this._timelineOut.time(this._timeoutBeforeEdit);
                }
            }
        }
    },

    methods: {
        setFinalState() {
            let finalStatePosition = this.editingData.finalStatePosition ?? this._timeline.endTime();
            this._timeoutBeforeEdit = this._timelineOut.time();
            this._timeBeforeEdit = this._timeline.time();
            this._stageTimeBeforeEdit = this.$stage._timeline.time();
            this._timelineOut.time(0);
            this._timeline.time(finalStatePosition);
        },

        editPosition(position, alignH, alignV) {
            this.$store.commit('sequences/' + this.seqId + '/' + this.id + '/setPosition', {
                alignH: alignH || Align.CUSTOM,
                alignV: alignV || Align.CUSTOM,
                alignX: position.left,
                alignY: position.top
            });
        },

        editSize(size) {
            this.$store.commit('sequences/' + this.seqId + '/' + this.id + '/enableHeight', true);
            this.$store.commit('sequences/' + this.seqId + '/' + this.id + '/setWidth', {
                value: Dimension.CUSTOM,
                custom: size.width
            });
            this.$store.commit('sequences/' + this.seqId + '/' + this.id + '/setHeight', {
                value: Dimension.CUSTOM,
                custom: size.height
            });
        },

        editCropPosition(width, height, left, top) {
            this.$store.commit('sequences/' + this.seqId + '/' + this.id + '/setCropPosition', {
                width,
                height,
                left,
                top
            });
        },

        editRotation(degrees) {
            this.$store.commit('sequences/' + this.seqId + '/' + this.id + '/setRotationDegrees', degrees);
        },

        convertCustomPosition() {
            let stageRect = this.$stage.$el.getBoundingClientRect(),
                elementRef = this.$refs[this.containerRef] ?? this.$refs[this.visualRef],
                containerRect = elementRef.$el.getBoundingClientRect();

            this.$nextTick(() => {
                if (this.$store.hasModule('ui')) this.$store.dispatch('ui/history/startStep');
                this.$store.commit('sequences/' + this.seqId + '/' + this.id + '/setPosition', {
                    alignH: Align.CUSTOM,
                    alignV: Align.CUSTOM,
                    alignX:
                        conversions.roundPercentage((containerRect.left - stageRect.left) / stageRect.width) +
                        Dimension.PERCENT_UNIT,
                    alignY:
                        conversions.roundPercentage((containerRect.top - stageRect.top) / stageRect.height) +
                        Dimension.PERCENT_UNIT
                });
                if (this.$store.hasModule('ui')) this.$store.dispatch('ui/saveVideo');
            });
        },

        setFinalStatePosition(value) {
            this.editingConf.finalStatePosition = value;
        },

        setHorizontalFlip(value) {
            this.$store.commit('sequences/' + this.seqId + '/' + this.id + '/enableHorizontalFlip', value);
        },

        setVerticalFlip(value) {
            this.$store.commit('sequences/' + this.seqId + '/' + this.id + '/enableVerticalFlip', value);
        }
    },

    beforeUnmount() {
        if (this.isEdited) this.$store.commit('edition/setEditingElement', null);
    }
};
