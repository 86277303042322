import { Dimension, Visual } from '@/js/video-studio/constants/index.js';
import _isEqual from 'lodash/isEqual.js';
import { conversions } from '@/js/video-studio/utils/index.js';

export default {
    props: {
        cropPosition: {
            type: Object,
            default: () => Visual.DEFAULT_CROP_POSITION
        },
        flip: {
            type: Object,
            default: () => ({ horizontal: false, vertical: false })
        }
    },

    data() {
        return {
            containerRect: null
        };
    },

    computed: {
        hasCropPosition() {
            return !_isEqual(this.cropPosition, Visual.DEFAULT_CROP_POSITION);
        },

        // applied directly en asset
        cropStyles() {
            const styles = { transform: '' };

            // crop position calculation inside asset container
            if (this.containerRect && this.hasCropPosition) {
                // calculate how much bigger the image width should be based on cropped width
                // height is set to "auto" to keep image aspect ratio anyway
                styles.width = conversions.roundPercentage(1 / this.cropPosition.width) + Dimension.PERCENT_UNIT;

                // translate image on X and Y axis accordingly
                const translateX = conversions.roundPercentage(this.cropPosition.left) + Dimension.PERCENT_UNIT;
                const translateY = conversions.roundPercentage(this.cropPosition.top) + Dimension.PERCENT_UNIT;
                styles.transform = `translate(${translateX}, ${translateY}) `;
            }

            // apply symmetry scale
            if (this.flip.horizontal && this.flip.vertical) styles.transform += 'scale(-1, -1)';
            else if (this.flip.horizontal) styles.transform += 'scale(-1, 1)';
            else if (this.flip.vertical) styles.transform += 'scale(1, -1)';

            return styles;
        }
    },

    mounted() {
        // used to get container rect as a reference for cropping size
        const reference = this.$refs.$imageContainer || this.$refs.$videoContainer;
        this.containerRect = reference.getBoundingClientRect();
    }
};
