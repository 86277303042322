/**
 * Video Studio
 * Conversion utilities
 */

import { Align, Dimension } from '../constants';
import types from './types';

const boolean = (value) => {
    let val = Number(value);
    return (!isNaN(val) && !!val) || value == 'true';
};

const percentage = (value) => {
    let val = parseFloat(value);
    return !isNaN(val) ? val + Dimension.PERCENT_UNIT : undefined;
};

const roundPercentage = (value) => {
    let val = parseFloat(value);
    return !isNaN(val) ? Number((val * 100).toFixed(2)) : undefined;
};

const percentNum = (value, convert) => {
    convert = convert === undefined || !!convert ? 100 : 1;
    let val = parseFloat(value) / convert;
    return !isNaN(val) ? Math.max(0, Math.min(val, 1)) : undefined;
};

const dimension = (value) => {
    let val = parseFloat(value);
    if (isNaN(val)) return undefined;
    if (isNaN(value) && value.substr(-2) == Dimension.PIXEL_UNIT) return val + Dimension.PIXEL_UNIT;
    return val + Dimension.PERCENT_UNIT;
};

const positiveDimension = (value) => {
    return types.isNegative(value) ? dimension(value).substring(1) : dimension(value);
};

const substractDimension = (value, substractor) => {
    let val = parseFloat(value),
        sub = parseFloat(substractor);
    if (
        isNaN(val) ||
        isNaN(sub) ||
        (value.substr(-2) == Dimension.PIXEL_UNIT) ^ (substractor.substr(-2) == Dimension.PIXEL_UNIT)
    )
        return undefined;
    if (isNaN(value) && value.substr(-2) == Dimension.PIXEL_UNIT) return val - sub + Dimension.PIXEL_UNIT;
    return val - sub + Dimension.PERCENT_UNIT;
};

const divideDimension = (value, divider) => {
    let val = parseFloat(value);
    if (isNaN(val)) return undefined;
    if (isNaN(value) && value.substr(-2) == Dimension.PIXEL_UNIT) return val / divider + Dimension.PIXEL_UNIT;
    return val / divider + Dimension.PERCENT_UNIT;
};

const cleanText = (text) => {
    return text.replace(/\u2028/g, '');
};

const alignment = (valueH, valueV, valueX, valueY, padding) => {
    let p = {};
    switch (valueH) {
        case Align.TOP_LEFT:
            p.left = Dimension.ZERO;
            p.top = Dimension.ZERO;
            if (parseFloat(padding)) p.margin = padding;
            break;
        case Align.TOP:
            p.top = Dimension.ZERO;
            if (parseFloat(padding)) p.margin = padding;
            break;
        case Align.TOP_RIGHT:
            p.right = Dimension.ZERO;
            p.top = Dimension.ZERO;
            if (parseFloat(padding)) p.margin = padding;
            break;
        case Align.HALF_TOP_LEFT:
            p.left = '25%';
            p.top = '25%';
            p.transform = 'translate(-50%, -50%)';
            break;
        case Align.HALF_TOP:
            p.top = '25%';
            p.transform = 'translate(0, -50%)';
            break;
        case Align.HALF_TOP_RIGHT:
            p.right = '25%';
            p.top = '25%';
            p.transform = 'translate(50%, -50%)';
            break;
        case Align.LEFT:
            p.left = Dimension.ZERO;
            if (types.isDistributedAlign(valueV)) p.alignSelf = 'flex-start';
            if (parseFloat(padding)) p.marginLeft = padding;
            break;
        case Align.HALF_LEFT:
            if (!types.isDistributedAlign(valueV)) {
                p.left = '25%';
                p.transform = 'translate(-50%, 0)';
            } else {
                p.right = '25%';
            }
            break;
        case Align.HALF_RIGHT:
            if (!types.isDistributedAlign(valueV)) {
                p.right = '25%';
                p.transform = 'translate(50%, 0)';
            } else {
                p.left = '25%';
            }
            break;
        case Align.RIGHT:
            p.right = Dimension.ZERO;
            if (types.isDistributedAlign(valueV)) p.alignSelf = 'flex-end';
            if (parseFloat(padding)) p.marginRight = padding;
            break;
        case Align.HALF_BOTTOM_LEFT:
            p.left = '25%';
            p.bottom = '25%';
            p.transform = 'translate(-50%, 50%)';
            break;
        case Align.HALF_BOTTOM:
            p.bottom = '25%';
            p.transform = 'translate(0, 50%)';
            break;
        case Align.HALF_BOTTOM_RIGHT:
            p.right = '25%';
            p.bottom = '25%';
            p.transform = 'translate(50%, 50%)';
            break;
        case Align.BOTTOM_LEFT:
            p.left = Dimension.ZERO;
            p.bottom = Dimension.ZERO;
            if (parseFloat(padding)) p.margin = padding;
            break;
        case Align.BOTTOM:
            p.bottom = Dimension.ZERO;
            if (parseFloat(padding)) p.margin = padding;
            break;
        case Align.BOTTOM_RIGHT:
            p.right = Dimension.ZERO;
            p.bottom = Dimension.ZERO;
            if (parseFloat(padding)) p.margin = padding;
            break;
        case Align.DISTRIBUTED_LEFT:
            p.order = 2;
            p.position = 'relative';
            p.flexShrink = 0;
            break;
        case Align.DISTRIBUTED_DEFAULT:
            p.order = 3;
            p.position = 'relative';
            p.flexShrink = 0;
            break;
        case Align.DISTRIBUTED_RIGHT:
            p.order = 4;
            p.position = 'relative';
            p.flexShrink = 0;
            break;
        case Align.CUSTOM:
            if (types.isPercentage(valueX) || types.isPixels(valueX)) {
                p.left = dimension(valueX);
            }
            break;
        case Align.CENTER:
        default:
            break;
    }
    if (valueV) {
        switch (valueV) {
            case Align.TOP:
                p.top = Dimension.ZERO;
                if (types.isDistributedAlign(valueH)) p.alignSelf = 'flex-start';
                if (parseFloat(padding)) p.marginTop = padding;
                break;
            case Align.HALF_TOP:
                if (!types.isDistributedAlign(valueH)) {
                    p.top = '25%';
                    p.transform = 'translate(0, -50%)' + (p.transform ? ' ' + p.transform : '');
                } else {
                    p.bottom = '25%';
                }
                break;
            case Align.HALF_BOTTOM:
                if (!types.isDistributedAlign(valueH)) {
                    p.bottom = '25%';
                    p.transform = 'translate(0, 50%)' + (p.transform ? ' ' + p.transform : '');
                } else {
                    p.top = '25%';
                }
                break;
            case Align.BOTTOM:
                p.bottom = Dimension.ZERO;
                if (types.isDistributedAlign(valueH)) p.alignSelf = 'flex-end';
                if (parseFloat(padding)) p.marginBottom = padding;
                break;
            case Align.DISTRIBUTED_TOP:
                p.order = 1;
                p.position = 'relative';
                p.flexShrink = 0;
                break;
            case Align.DISTRIBUTED_DEFAULT:
                p.order = 3;
                p.position = 'relative';
                p.flexShrink = 0;
                break;
            case Align.DISTRIBUTED_BOTTOM:
                p.order = 5;
                p.position = 'relative';
                p.flexShrink = 0;
                break;
            case Align.CUSTOM:
                if (types.isPercentage(valueY) || types.isPixels(valueY)) {
                    p.top = dimension(valueY);
                }
                break;
            case Align.CENTER:
            default:
                break;
        }
    }
    return p;
};

const textAlignment = (value) => {
    switch (value) {
        case Align.TEXT_LEFT:
            return 'flex-start';
        case Align.TEXT_CENTER:
            return 'center';
        case Align.TEXT_RIGHT:
            return 'flex-end';
        case Align.TEXT_JUSTIFY:
            return 'stretch';
    }
    return 'flex-start';
};

const pointAlignment = (value) => {
    switch (value) {
        case Align.TOP_LEFT:
            return 'left top';
        case Align.TOP:
            return 'center top';
        case Align.TOP_RIGHT:
            return 'right top';
        case Align.HALF_TOP_LEFT:
            return '25% 25%';
        case Align.HALF_TOP:
            return 'center 25%';
        case Align.HALF_TOP_RIGHT:
            return '75% 25%';
        case Align.LEFT:
            return 'left center';
        case Align.HALF_LEFT:
            return '25% center';
        case Align.CENTER:
            return 'center center';
        case Align.HALF_RIGHT:
            return '75% center';
        case Align.RIGHT:
            return 'right center';
        case Align.HALF_BOTTOM_LEFT:
            return '25% 75%';
        case Align.HALF_BOTTOM:
            return 'center 75%';
        case Align.HALF_BOTTOM_RIGHT:
            return '75% 75%';
        case Align.BOTTOM_LEFT:
            return 'left bottom';
        case Align.BOTTOM:
            return 'center bottom';
        case Align.BOTTOM_RIGHT:
            return 'right bottom';
    }
    return undefined;
};

const coverAlignment = (valueH, valueV, valueX, valueY) => {
    let p = [];
    switch (valueH) {
        case Align.LEFT:
            p.push('left');
            break;
        case Align.HALF_LEFT:
            p.push('25%');
            break;
        case Align.CENTER:
            p.push('center');
            break;
        case Align.HALF_RIGHT:
            p.push('75%');
            break;
        case Align.RIGHT:
            p.push('right');
            break;
        case Align.CUSTOM:
            if (types.isPercentage(valueX) || types.isPixels(valueX)) {
                p.push(dimension(valueX));
            }
            break;
        default:
            break;
    }
    switch (valueV) {
        case Align.TOP:
            p.push('top');
            break;
        case Align.HALF_TOP:
            p.push('25%');
            break;
        case Align.CENTER:
            p.push('center');
            break;
        case Align.HALF_BOTTOM:
            p.push('75%');
            break;
        case Align.BOTTOM:
            p.push('bottom');
            break;
        case Align.CUSTOM:
            if (types.isPercentage(valueY) || types.isPixels(valueY)) {
                p.push(dimension(valueY));
            }
            break;
        default:
            break;
    }
    return p.join(' ');
};

const sidePanelPosition = (position, size) => {
    let p = {
        left: Dimension.AUTO,
        right: Dimension.AUTO,
        top: Dimension.AUTO,
        bottom: Dimension.AUTO,
        width: Dimension.FULL,
        height: Dimension.FULL
    };

    switch (position) {
        case Align.LEFT:
            p.left = p.top = 0;
            p.width = size;
            break;
        case Align.RIGHT:
            p.right = p.top = 0;
            p.width = size;
            break;
        case Align.TOP:
            p.left = p.top = 0;
            p.height = size;
            break;
        case Align.BOTTOM:
            p.left = p.bottom = 0;
            p.height = size;
            break;
    }

    return p;
};

const optionValue = (data, allowed, defaultValue) => {
    allowed = allowed !== undefined ? (!Array.isArray(allowed) ? [allowed] : allowed) : [];
    if (!isNaN(parseFloat(data.value)) || allowed.indexOf(data.value) != -1) return data.value;
    if (!isNaN(parseFloat(data.custom)) || allowed.indexOf(data.custom) != -1) return data.custom;
    return defaultValue !== undefined ? defaultValue : undefined;
};

const asset = (url) => {
    return (window.STUDIO_BASE_URL || '') + url + (window.STUDIO_TIMESTAMP ? '?' + window.STUDIO_TIMESTAMP : '');
};

const minutes = (seconds, precision) => {
    let m = Math.floor(seconds / 60),
        s = seconds - 60 * m,
        p = precision !== undefined ? precision : 2,
        l = p ? p + 1 : p;
    return m + ':' + ('0' + s.toFixed(p)).substr(-2 - p);
};

const hundredths = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    let sec = Math.floor(seconds - 60 * minutes);
    const hundredths = Math.round((seconds % 1) * 100);

    // hack in case hundredths are rounded to 100 (i.e. end of sequence)
    if (hundredths === 100) sec += 1;

    return minutes + ':' + ('0' + sec).slice(-2) + '.' + ('0' + hundredths).slice(-2);
};

// Source: https://stackoverflow.com/a/5624139
const rgbColor = (hex) => {
    let longHex = hex.replace(/^#([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b);
    let channels = /^#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(longHex);
    return channels
        ? {
              r: parseInt(channels[1], 16),
              g: parseInt(channels[2], 16),
              b: parseInt(channels[3], 16)
          }
        : null;
};

const mediaFragmentAssetSrc = (assetSrc, assetTotalDuration, timerange) => {
    const floorDuration = Math.floor(assetTotalDuration * 100) / 100;

    if (
        timerange.end === -1 ||
        (timerange.start === 0 && timerange.end === floorDuration) ||
        timerange.end <= timerange.start
    )
        return assetSrc.split('#')[0]; // Supprime tout fragment existant

    // Supprime tout fragment existant et ajoute le nouveau
    const baseUrl = assetSrc.split('#')[0];
    return `${baseUrl}#t=${timerange.start},${timerange.end}`;
};
export default {
    boolean,
    percentage,
    roundPercentage,
    percentNum,
    dimension,
    positiveDimension,
    substractDimension,
    divideDimension,
    cleanText,
    alignment,
    textAlignment,
    pointAlignment,
    coverAlignment,
    sidePanelPosition,
    optionValue,
    asset,
    minutes,
    hundredths,
    rgbColor,
    mediaFragmentAssetSrc
};
