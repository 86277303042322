<template>
    <ui-card
        :id="cardElementId"
        :class="[{ 'ui-card-editing': editing }, 'ui-card-' + id]"
        :title="$t('Panel')"
        :added-card-id="addedCardId"
        v-on:mousedown="editingElement"
    >
        <template #header-icon>
            <svg-icon icon="panel-icon" />
        </template>
        <template #menu-content>
            <ui-dropdown-item :disabled="!canCopyCard" @click.prevent="copyCard">
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-copy" />
                    {{ $t('Copy') }}
                </template>
            </ui-dropdown-item>

            <ui-dropdown-item :disabled="!canPasteCard" @click.prevent="pasteCard">
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-clipboard" />
                    {{ $t('Paste') }}
                </template>
            </ui-dropdown-item>

            <ui-dropdown-item
                v-if="isEmotionMode"
                classes="ui-remove-item"
                :disabled="!canRemovePanel"
                @click.prevent="removeCard"
            >
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-trash-can" />
                    {{ $t('Remove') }}
                </template>
            </ui-dropdown-item>
        </template>

        <div v-if="isEmotionMode" class="sequence-panel-shape-category ui-card-row">
            <ui-library-selector
                v-if="!isCustomCategory"
                ref="$panelSelector"
                :id="cardElementId + '-shape-category'"
                key="library-selector-panel-shape-category"
                v-model="panelShapeType"
                :default-value="panelShapeDefaultTypes[panelShapeCategory]"
                library="panels"
                :category="panelShapeCategory"
                :preview-path="panelShapeCategoryPreviewPath"
                :label="$t('Choose a panel shape')"
                :show-label="false"
                :use-selection-label="false"
                :disabled="readOnly"
                @[librarySelectorShowEvent]="openLibrary"
                @[librarySelectorPreviewChangeEvent]="previewPanelShapeType"
            />
            <ui-media-selector
                v-else
                :id="cardElementId + '-shape-image'"
                key="media-selector-panel-shape-image"
                v-model:media="panelShapeImage"
                :type="mediaTypeImage"
                :label="$t('studio.panel_categories.custom')"
                :showLabel="false"
                :disabled="readOnly"
                icon="fa-solid fa-image"
                @[mediaSelectorShowEvent]="openMediaLibrary"
            />

            <ui-dropdown
                :id="cardElementId + '-shape-category'"
                :select="true"
                v-model="panelShapeCategory"
                :disabled="readOnly"
            >
                <template #dropdown-toggle="{ instance }">
                    <svg-icon :icon="instance.selectedValue.toLowerCase() + '-panel-icon'" />
                    <span>{{ instance.toggleLabel }}</span>
                </template>
                <ui-dropdown-item
                    v-for="category in panelShapeCategories"
                    :key="category"
                    :value="category"
                    :label="$t('studio.panel_categories.' + category)"
                >
                    <template #dropdown-menu-item-label="{ instance }">
                        <svg-icon :icon="instance.value.toLowerCase() + '-panel-icon'" />
                        {{ instance.label || instance.value }}
                    </template>
                </ui-dropdown-item>
            </ui-dropdown>
        </div>

        <ui-switch-button
            v-if="isCustomCategory"
            :id="cardElementId + '-enable-color'"
            class="ui-card-row"
            :label="$t('Change color')"
            v-model="enableColor"
            :disabled="readOnly"
        />
        <ui-color-selector
            v-if="!isCustomCategory || enableColor"
            :id="cardElementId + '-color-start'"
            key="color-selector-panel-color-start"
            class="ui-card-row"
            v-model:color="panelColorStart"
            default-color="color1"
            :palette="brandPalette"
            :enable-alpha="true"
            :enable-other-colors="brandEnableOtherColors"
            :label="$t('Color')"
            :disabled="readOnly"
        />

        <template #footer>
            <ui-dropdown
                v-if="isEmotionMode"
                :id="cardElementId + '-style'"
                class="centered style-dropdown"
                :menu="false"
                :caret="false"
                :scrollable="false"
                menu-placement="bottom-start"
                :toggle-style="toggleStylePanelStyle"
            >
                <template #dropdown-toggle>
                    <fa-icon class="icon" icon="fa-regular fa-sparkles" />
                    <span>{{ $t('Style') }}</span>
                </template>
                <ui-switch-button
                    :id="cardElementId + '-enable-animation'"
                    class="ui-content-row"
                    :label="$t('Animate')"
                    v-model="panelAnimationEnabled"
                    :disabled="readOnly"
                />
                <ui-switch-button
                    v-if="isSideCategory"
                    :id="cardElementId + '-enable-bg-move'"
                    class="ui-content-row"
                    :label="$t('Center background')"
                    v-model="panelBgMoveEnabled"
                    :disabled="readOnly"
                />
            </ui-dropdown>

            <ui-dropdown
                v-if="isEmotionMode"
                :id="cardElementId + '-position'"
                class="centered position-dropdown"
                :menu="false"
                :caret="false"
                :scrollable="false"
                menu-placement="bottom"
                menu-strategy="fixed"
            >
                <template #dropdown-toggle>
                    <fa-icon class="icon" icon="fa-regular fa-arrows-up-down-left-right" />
                    <span>{{ $t('Position') }}</span>
                </template>
                <div v-if="isSideCategory" class="panel-side-settings ui-content-row">
                    <label>{{ $t('Position') }}</label>
                    <div class="panel-side-inputs">
                        <input
                            v-for="(side, name) in panelSides"
                            type="radio"
                            :class="'panel-side-' + name"
                            :value="side"
                            v-model="panelSide"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
                <ui-number-input
                    :id="cardElementId + '-width'"
                    class="ui-content-row"
                    v-model="panelWidth"
                    :default-value="panelWidthDefault"
                    :min="1"
                    :decimals="1"
                    :label="isSideCategory ? $t('Size') : $t('Width')"
                    :disabled="readOnly"
                />
                <ui-number-input
                    v-if="!isSideCategory"
                    :id="cardElementId + '-height'"
                    class="ui-content-row"
                    v-model="panelHeight"
                    :default-value="panelHeightDefault"
                    :min="1"
                    :decimals="1"
                    :label="$t('Height')"
                    :disabled="readOnly"
                />
                <ui-switch-button
                    v-if="!isSideCategory"
                    :id="cardElementId + '-ratio-constraint'"
                    class="ui-content-row"
                    :label="$t('Keep original ratio')"
                    v-model="panelRatioConstraint"
                    :disabled="readOnly"
                />
                <div v-if="!isSideCategory" class="ui-content-field ui-content-row" :class="{ disabled: readOnly }">
                    <label>{{ $t('Horizontal position') }}</label>
                    <ui-dropdown
                        :id="cardElementId + '-hor-position'"
                        :select="true"
                        :editable="true"
                        :editable-reg-exp="/^\-?\d+(?:(?:\.|,)\d+)?$/"
                        v-model="panelHorizontalPosition"
                        :disabled="readOnly"
                    >
                        <ui-dropdown-item
                            v-for="position in panelHorizontalPositions"
                            :key="position"
                            :value="position"
                            :label="$t('studio.alignments.' + position)"
                        />
                    </ui-dropdown>
                </div>
                <div v-if="!isSideCategory" class="ui-content-field ui-content-row" :class="{ disabled: readOnly }">
                    <label>{{ $t('Vertical position') }}</label>
                    <ui-dropdown
                        :id="cardElementId + '-ver-position'"
                        :select="true"
                        :editable="true"
                        :editable-reg-exp="/^\-?\d+(?:(?:\.|,)\d+)?$/"
                        v-model="panelVerticalPosition"
                        :disabled="readOnly"
                    >
                        <ui-dropdown-item
                            v-for="position in panelVerticalPositions"
                            :key="position"
                            :value="position"
                            :label="$t('studio.alignments.' + position)"
                        />
                    </ui-dropdown>
                </div>
            </ui-dropdown>

            <ui-dropdown
                :id="cardElementId + '-timing'"
                class="centered timing-dropdown"
                :menu="false"
                :caret="false"
                :scrollable="false"
                menu-placement="bottom-end"
                :toggle-style="toggleTimingPanelStyle"
            >
                <template #dropdown-toggle>
                    <fa-icon class="icon" icon="fa-regular fa-stopwatch" />
                    <span>{{ $t('Timing') }}</span>
                </template>
                <ui-number-input
                    :id="cardElementId + '-start'"
                    class="ui-content-row"
                    v-model="panelStart"
                    :default-value="panelStartDefault"
                    :min="0"
                    :label="$t('Starting time')"
                    :disabled="readOnly"
                />
                <ui-number-input
                    :id="cardElementId + '-end'"
                    class="ui-content-row"
                    v-model="panelEnd"
                    :default-value="panelEndDefault"
                    :min="panelStart"
                    :label="$t('Ending time')"
                    :disabled="readOnly"
                />
            </ui-dropdown>
        </template>
    </ui-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Align, Color, Dimension, Duration, Panel, STAGE_LOADING_ENDED_EVENT } from 'cte-video-studio';
import { PANEL_ELEMENT_ID, PANEL_VERTICAL_POSITIONS, PANEL_HORIZONTAL_POSITIONS } from '../../../../../constants';
import { getPrefixedUuid } from '../../../../../utils';
import { SequenceCard } from '../../../../mixins';

export default {
    mixins: [SequenceCard],

    inject: ['$videoStudio'],

    components: {
        //
    },

    props: {
        id: {
            type: String,
            default: PANEL_ELEMENT_ID
        },

        editing: {
            type: Boolean,
            default: false
        },

        addedCardId: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            panelShapeCategories: Panel.CATEGORIES,
            panelShapeDefaultTypes: Panel.DEFAULTS,
            panelSides: {
                left: Align.LEFT,
                right: Align.RIGHT,
                top: Align.TOP,
                bottom: Align.BOTTOM
            },
            panelHeightDefault: Dimension.AUTO,
            panelHorizontalPositions: PANEL_HORIZONTAL_POSITIONS,
            panelVerticalPositions: PANEL_VERTICAL_POSITIONS,
            panelStartDefault: Duration.START_DEFAULT,
            panelEndDefault: Duration.END_DEFAULT
        };
    },

    computed: {
        ...mapState({
            //
        }),

        ...mapGetters({
            findLibraryItem: 'branding/libraries/findLibraryItem'
        }),

        isSideCategory() {
            return this.panelShapeCategory == Panel.SIDE_CATEGORY;
        },

        isCustomCategory() {
            return this.panelShapeCategory == Panel.CUSTOM_CATEGORY;
        },

        panelShapeCategory: {
            get() {
                return this.sequenceState.panel.shape.category;
            },
            set(category) {
                this.saveHistoryStep(() => {
                    let shapeSwapType = this.panelShapeType.replace(
                            new RegExp(
                                '^' + this.sequenceState.panel.shape.category.replace(/^./, (c) => c.toUpperCase())
                            ),
                            category.replace(/^./, (c) => c.toUpperCase())
                        ),
                        shapeSwapItem = this.findLibraryItem('panels', category, shapeSwapType) || null;
                    this._cachedPanelShapeTypes[this.sequenceState.panel.shape.category] = this.panelShapeType;

                    this.$store.commit(this.sequenceStoreModulePath + '/setPanelCategory', category);
                    this.$store.commit(
                        this.sequenceStoreModulePath + '/setPanel',
                        shapeSwapItem?.name ||
                            this._cachedPanelShapeTypes[category] ||
                            this.panelShapeDefaultTypes[category]
                    );
                });
            }
        },

        panelShapeCategoryPreviewPath() {
            return 'panels/';
        },

        panelShapeType: {
            get() {
                return this.sequenceState.panel.shape.type;
            },
            set(type) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.sequenceStoreModulePath + '/setPanel', type);
                });
            }
        },

        panelShapeImage: {
            get() {
                let ref = { src: '', id: '' };

                if (this.isCustomCategory) {
                    ref.src = this.sequenceState.panel.shape.src;
                    ref.id = this.sequenceState.panel.shape.src__id;
                }

                return ref;
            },
            set({ src, id }) {
                this.saveHistoryStep(() => {
                    if (!this.sequenceState.panel.shape.src__ref) {
                        this.$store.commit(
                            this.sequenceStoreModulePath + '/setPanelSourceReference',
                            getPrefixedUuid(this.prefixes.mediaReference)
                        );
                    }
                    this.$store.dispatch(this.sequenceStoreModulePath + '/updatePanelSource', { src, src__id: id });
                });
            }
        },

        panelColorStart: {
            get() {
                return {
                    ref: this.sequenceState.panel.color.start__ref,
                    value: this.sequenceState.panel.color.start,
                    alpha: this.sequenceState.panel.opacity
                };
            },
            set({ ref, value, alpha }) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.sequenceStoreModulePath + '/setPanelColor', {
                        start__ref: ref,
                        start: value
                    });
                    this.$store.commit(this.sequenceStoreModulePath + '/setPanelOpacity', alpha);
                    if (
                        this.isSideCategory &&
                        !this.panelCanHaveBgMove &&
                        this.sequenceState.panel.animation.enabledBgMove
                    ) {
                        this.$store.commit(this.sequenceStoreModulePath + '/enablePanelBgMove', false);
                    }
                });
            }
        },

        panelAnimationEnabled: {
            get() {
                return this.sequenceState.panel.animation.enabled;
            },
            set(value) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.sequenceStoreModulePath + '/enablePanelAnimation', value);
                });
            }
        },

        panelCanHaveBgMove() {
            return (
                this.sequenceState.panel.enabled &&
                this.isSideCategory &&
                this.sequenceState.panel.opacity == Color.OPACITY_FULL
            );
        },

        panelHasBgMove() {
            return this.$store.getters[this.sequenceStoreModulePath + '/panelHasBgMove'];
        },

        panelBgMoveEnabled: {
            get() {
                return this.panelHasBgMove;
            },
            set(value) {
                this.saveHistoryStep(() => {
                    if (this.isSideCategory && !this.panelCanHaveBgMove && value) {
                        this.$store.commit(this.sequenceStoreModulePath + '/setPanelOpacity', Color.OPACITY_FULL);
                    }
                    this.$store.commit(this.sequenceStoreModulePath + '/enablePanelBgMove', value);
                });
            }
        },

        panelSide: {
            get() {
                return this.sequenceState.panel.position.side;
            },
            set(side) {
                this.saveHistoryStep(() => {
                    this.$store.commit(
                        this.sequenceStoreModulePath + '/setPanelPosition',
                        Object.assign({}, this.sequenceState.panel.position, { side })
                    );
                });
            }
        },

        panelWidthDefault() {
            return this.isSideCategory ? Dimension.PANEL_DEFAULT : Dimension.AUTO;
        },

        panelWidth: {
            get() {
                return this.sequenceState.panel.size.width != Dimension.AUTO
                    ? parseFloat(this.sequenceState.panel.size.width)
                    : '';
            },
            set(value) {
                if (value != this.panelWidth) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(
                            this.sequenceStoreModulePath + '/setPanelWidth',
                            value != Dimension.AUTO ? value + Dimension.PERCENT_UNIT : value
                        );
                    });
                }
            }
        },

        panelHeight: {
            get() {
                return this.sequenceState.panel.size.height != Dimension.AUTO
                    ? parseFloat(this.sequenceState.panel.size.height)
                    : '';
            },
            set(value) {
                if (value != this.panelHeight) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(
                            this.sequenceStoreModulePath + '/setPanelHeight',
                            value != Dimension.AUTO ? value + Dimension.PERCENT_UNIT : value
                        );
                    });
                }
            }
        },

        panelRatioConstraint: {
            get() {
                return this.sequenceState.panel.size.ratioConstraint;
            },
            set(value) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.sequenceStoreModulePath + '/setPanelRatioConstraint', value);
                });
            }
        },

        panelHorizontalPosition: {
            get() {
                return this.sequenceState.panel.position.alignH != Align.CUSTOM
                    ? this.sequenceState.panel.position.alignH
                    : parseFloat(this.sequenceState.panel.position.alignX);
            },
            set(value) {
                let parsedValue = value.replace(',', '.'),
                    position = {
                        alignH: !isNaN(parsedValue) ? Align.CUSTOM : value,
                        alignX: !isNaN(parsedValue) ? parsedValue : Dimension.ZERO
                    };
                if (
                    position.alignH != this.sequenceState.panel.position.alignH ||
                    position.alignX != this.sequenceState.panel.position.alignX
                ) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(
                            this.sequenceStoreModulePath + '/setPanelPosition',
                            Object.assign({}, this.sequenceState.panel.position, position)
                        );
                    });
                }
            }
        },

        panelVerticalPosition: {
            get() {
                return this.sequenceState.panel.position.alignV != Align.CUSTOM
                    ? this.sequenceState.panel.position.alignV
                    : parseFloat(this.sequenceState.panel.position.alignY);
            },
            set(value) {
                let parsedValue = value.replace(',', '.'),
                    position = {
                        alignV: !isNaN(parsedValue) ? Align.CUSTOM : value,
                        alignY: !isNaN(parsedValue) ? parsedValue : Dimension.ZERO
                    };
                if (
                    position.alignV != this.sequenceState.panel.position.alignV ||
                    position.alignY != this.sequenceState.panel.position.alignY
                ) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(
                            this.sequenceStoreModulePath + '/setPanelPosition',
                            Object.assign({}, this.sequenceState.panel.position, position)
                        );
                    });
                }
            }
        },

        panelStart: {
            get() {
                return this.sequenceState.panel.animation.start;
            },
            set(value) {
                if (value != this.panelStart) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(this.sequenceStoreModulePath + '/setPanelStart', value);
                    });
                }
            }
        },

        panelEnd: {
            get() {
                return this.sequenceState.panel.animation.end != Duration.END_DEFAULT
                    ? this.sequenceState.panel.animation.end
                    : '';
            },
            set(value) {
                if (value != this.panelEnd) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(this.sequenceStoreModulePath + '/setPanelEnd', value);
                    });
                }
            }
        },

        enableColor: {
            get() {
                return this.sequenceState.panel.color.enabled;
            },
            set(value) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.sequenceStoreModulePath + '/enablePanelColor', value);
                });
            }
        },

        toggleStylePanelStyle() {
            if(this.panelAnimationEnabled === false || this.panelBgMoveEnabled == false) {
                return { color: 'var(--edit-mode-color)'};
            } else return {};
        },

        toggleTimingPanelStyle() {
            if(this.panelStart !== this.panelStartDefault || (this.panelEnd !==  '' && this.panelEnd !== 0)) {
                return { color: 'var(--edit-mode-color)' };
            } else return {};
        },
    },

    watch: {
        //
    },

    methods: {
        removeCard() {
            this.saveHistoryStep(() => {
                this.$store.dispatch(this.sequenceStoreModulePath + '/showPanelCard', false);
            });
        },

        previewPanelShapeType(type, cancel) {
            this.ignoreHistoryStep(() => {
                this.$store.commit(this.sequenceStoreModulePath + '/setPanel', type);
                if (!cancel)
                    this.$videoStudio.studio.$stage.$el.addEventListener(
                        STAGE_LOADING_ENDED_EVENT,
                        this.playPanelShapeTypePreview,
                        { once: true }
                    );
            });
        },

        playPanelShapeTypePreview() {
            this.playPreview(this.sequenceState.panel.animation.start || 0.001);
        },

        editingElement() {
            this.$videoStudio.studio.$stage.getSequenceElement(this.seqId).startEditingElement(null, this.cardElementId);
        }
    },

    mounted() {
        this._cachedPanelShapeTypes = { [this.panelShapeCategory]: this.panelShapeType };
    }
};
</script>
