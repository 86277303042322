<template>
    <component
        :is="sequenceContainerTagName"
        class="studio-sequence-bg studio-container"
        :class="classes"
        :id="containerID"
    >
        <component :is="sequenceInnerTagName" width="100%" height="100%" class="studio-container">
            <div
                class="studio-container"
                :class="containerClasses"
                :style="containerStyles"
                xmlns="http://www.w3.org/1999/xhtml"
            >
                <div ref="$bgAssets" class="studio-bg-assets studio-container" :style="blurStyles">
                    <AssetImage
                        v-if="isImage"
                        key="bg-image"
                        ref="$bgImage"
                        name="$bgImage"
                        :src="state.image.src"
                        width="100%"
                        height="100%"
                        class="studio-bg-image"
                        :cover="true"
                    />
                    <AssetImage
                        v-if="isCartoon"
                        key="bg-cartoon"
                        ref="$bgCartoon"
                        name="$bgCartoon"
                        :src="state.image.cartoonSrc"
                        width="100%"
                        height="100%"
                        class="studio-bg-cartoon"
                        :cover="true"
                    />
                    <AssetVideo
                        v-else-if="isVideo"
                        key="bg-video"
                        ref="$bgVideo"
                        name="$bgVideo"
                        :seqId="id"
                        :syncBy="previousBackgroundSyncBy"
                        :src="videoSrc"
                        width="100%"
                        height="100%"
                        :timerange="state.video.timerange"
                        :segments="state.video.timerangeSegments"
                        :playback-rate="state.video.playbackRate"
                        :basisVolume="videoVolume"
                        :volume="volume"
                        :captions="state.video.captions"
                        class="studio-bg-video"
                        :cover="true"
                        @update="update($event)"
                    />
                    <AssetVideo
                        v-else-if="isAnimated"
                        key="bg-animated"
                        ref="$bgAnimated"
                        name="$bgAnimated"
                        :seqId="id"
                        :syncBy="previousBackgroundSyncBy"
                        :src="state.video.animatedSrc"
                        width="100%"
                        height="100%"
                        :basisVolume="videoVolume"
                        :volume="volume"
                        class="studio-bg-animated"
                        :cover="true"
                        @update="update($event)"
                    />
                    <AssetVideo
                        v-else-if="isRecording"
                        key="bg-recording"
                        ref="$bgRecording"
                        name="$bgRecording"
                        :seqId="id"
                        :syncBy="previousBackgroundSyncBy"
                        :src="recordingSrc"
                        width="100%"
                        height="100%"
                        :timerange="state.recording.timerange"
                        :segments="state.recording.timerangeSegments"
                        :playback-rate="state.recording.playbackRate"
                        :basisVolume="state.recording.volume"
                        :volume="volume"
                        :captions="state.recording.captions"
                        class="studio-bg-recording"
                        :cover="true"
                        @update="update($event)"
                    />
                    <div
                        v-else-if="isMapZoom"
                        key="bg-map-zoom"
                        ref="$bgMapZoom"
                        class="studio-map-zoom"
                        :style="mapZoomStyles"
                    >
                        <div class="studio-map-zoom-level studio-map-zoom-level-1">
                            <AssetImage
                                name="$bgMapZoomImage1"
                                :src="getMapZoomURL(0)"
                                width="100%"
                                height="100%"
                                :cover="true"
                                class="studio-map-zoom-image"
                            />
                            <div class="studio-map-zoom-level studio-map-zoom-level-2">
                                <AssetImage
                                    name="$bgMapZoomImage2"
                                    :src="getMapZoomURL(1)"
                                    width="100%"
                                    height="100%"
                                    :cover="true"
                                    class="studio-map-zoom-image"
                                />
                                <div class="studio-map-zoom-level studio-map-zoom-level-3">
                                    <AssetImage
                                        name="$bgMapZoomImage3"
                                        :src="getMapZoomURL(2)"
                                        width="100%"
                                        height="100%"
                                        :cover="true"
                                        class="studio-map-zoom-image"
                                    />
                                    <div class="studio-map-zoom-level studio-map-zoom-level-4">
                                        <AssetImage
                                            name="$bgMapZoomImage4"
                                            :src="getMapZoomURL(3)"
                                            width="100%"
                                            height="100%"
                                            :cover="true"
                                            class="studio-map-zoom-image"
                                        />
                                        <div class="studio-map-zoom-level studio-map-zoom-level-5">
                                            <AssetImage
                                                name="$bgMapZoomImage5"
                                                :src="getMapZoomURL(4)"
                                                width="100%"
                                                height="100%"
                                                :cover="true"
                                                class="studio-map-zoom-image"
                                            />
                                            <AssetImage
                                                name="$bgMapZoomImageRoadmap"
                                                :src="getMapZoomURL(4, true)"
                                                width="100%"
                                                height="100%"
                                                :cover="true"
                                                class="studio-map-zoom-image studio-map-zoom-image-roadmap"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AssetImage
                            name="$bgMapZoomClouds1"
                            :src="cloudsAssetURL"
                            class="studio-map-zoom-clouds studio-map-zoom-clouds-1"
                        />
                        <AssetImage
                            name="$bgMapZoomClouds2"
                            :src="cloudsAssetURL"
                            class="studio-map-zoom-clouds studio-map-zoom-clouds-2"
                        />
                        <div v-show="state.mapZoom.marker.enabled" class="studio-map-zoom-marker">
                            <svg class="studio-map-zoom-marker-shadow" viewBox="0 0 50 10">
                                <ellipse cx="50%" cy="50%" rx="50%" ry="50%" />
                            </svg>
                            <div class="studio-map-zoom-marker-image studio-container">
                                <svg
                                    v-if="!state.mapZoom.marker.src"
                                    class="studio-map-zoom-marker-default"
                                    viewBox="0 0 64 83"
                                >
                                    <path
                                        d="M64,32A31.1,31.1,0,0,0,54.7,9.3,31.1,31.1,0,0,0,32,0,31.2,31.2,0,0,0,9.4,9.3,30.92,30.92,0,0,0,0,32Q0,43.4,9,57.6,22.8,79.3,32,83q5.4-2.3,12.6-10.9A124.4,124.4,0,0,0,54.9,57.6Q64,42.79,64,32Z"
                                        fill="#87a61b"
                                    />
                                    <path
                                        d="M43.36,20.72A15.47,15.47,0,0,0,32,16a15.2,15.2,0,0,0-11.28,4.72A15.2,15.2,0,0,0,16,32a15.2,15.2,0,0,0,4.72,11.28A15.2,15.2,0,0,0,32,48a15.47,15.47,0,0,0,11.36-4.72A15.36,15.36,0,0,0,48,32,15.36,15.36,0,0,0,43.36,20.72Z"
                                        fill="#fff"
                                    />
                                </svg>
                                <AssetImage
                                    v-else
                                    name="$bgMapZoomCustomMarker"
                                    :src="state.mapZoom.marker.src"
                                    width="100%"
                                    height="100%"
                                    class="studio-map-zoom-marker-custom"
                                />
                            </div>
                        </div>
                    </div>
                    <div v-if="isCollage" ref="$bgCollage" class="studio-container" :class="getCollageClass()">
                        <div
                            v-for="(collageImage, index) in collageImages"
                            :key="index"
                            class="studio-bg-collage-element"
                            :class="'studio-bg-collage-element-' + index"
                        >
                            <AssetImage :src="collageImage" :width="collageImagesWidth" height="100%" :cover="true" />
                        </div>
                    </div>

                    <svg
                        v-if="isLoadingProduction"
                        width="58"
                        height="58"
                        viewBox="0 0 58 58"
                        preserveAspectRatio="xMidYMid slice"
                        class="studio-bg-loading-production"
                        :style="loadingProductionStyles"
                    >
                        <defs>
                            <symbol id="loading-production-logo-icon" width="58" height="58" viewBox="0 0 58 58">
                                <linearGradient
                                    id="linear-gradient"
                                    x1="-0.054"
                                    y1="0.04"
                                    x2="0.917"
                                    y2="0.846"
                                    gradientUnits="objectBoundingBox"
                                >
                                    <stop offset="0" stop-color="#fff" stop-opacity="0.898" />
                                    <stop offset="0.24" stop-color="#fff" stop-opacity="0.89" />
                                    <stop offset="0.32" stop-color="#fff" stop-opacity="0.871" />
                                    <stop offset="0.38" stop-color="#fff" stop-opacity="0.82" />
                                    <stop offset="0.43" stop-color="#fff" stop-opacity="0.749" />
                                    <stop offset="0.47" stop-color="#fff" stop-opacity="0.671" />
                                    <stop offset="0.5" stop-color="#fff" stop-opacity="0.6" />
                                </linearGradient>
                                <g id="b" transform="translate(-84.217 170)">
                                    <g id="c" transform="translate(84.217 -170)">
                                        <rect
                                            id="Rectangle_1"
                                            data-name="Rectangle 1"
                                            width="7.142"
                                            height="24.723"
                                            rx="3.571"
                                            transform="translate(18.82 17.5)"
                                            fill="#fff"
                                        />
                                        <rect
                                            id="Rectangle_2"
                                            data-name="Rectangle 2"
                                            width="7.142"
                                            height="24.723"
                                            rx="3.571"
                                            transform="translate(21.071 43.523) rotate(-120)"
                                            fill="#fff"
                                        />
                                        <rect
                                            id="Rectangle_3"
                                            data-name="Rectangle 3"
                                            width="7.142"
                                            height="14.834"
                                            rx="3.571"
                                            transform="translate(26.082 27.335) rotate(-60)"
                                            opacity="0.9"
                                            fill="url(#linear-gradient)"
                                        />
                                    </g>
                                </g>
                            </symbol>
                            <pattern id="loading-production-logo-pattern" width="10%" height="10%" viewBox="0 0 58 58">
                                <use
                                    xlink:href="#loading-production-logo-icon"
                                    class="loading-production-logo-pattern-icon"
                                />
                            </pattern>
                        </defs>
                        <rect
                            class="loading-production-bg"
                            x="0"
                            y="0"
                            width="100%"
                            height="100%"
                            :fill="loadingProductionPattern"
                        />
                    </svg>

                    <div v-if="state.blur" class="studio-bg-blur studio-container"></div>
                    <overlay
                        v-if="!isColor && rawState.overlay.enabled"
                        ref="$bgOverlay"
                        :id="id + '-bg-overlay'"
                        :seqId="id"
                        :resolvedSeqId="resolvedID"
                    />
                </div>
            </div>
        </component>
    </component>
</template>

<script>
import { Background, Dimension, Duration, Timeline } from '../constants';
import SequenceMixin from '../mixins/Sequence';
import Backgrounds from '../animations/background';
import AssetImage from './assets/AssetImage.vue';
import AssetVideo from './assets/AssetVideo.vue';
import Overlay from './Overlay.vue';
import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';
import { mapState } from 'vuex';
import cloudAsset from '@resources/assets/background/clouds.png';

gsap.registerPlugin(CustomEase);

export default {
    components: {
        AssetImage,
        AssetVideo,
        Overlay
    },

    mixins: [SequenceMixin],
    inject: ['$stage'],

    props: {
        format: Object
    },

    data() {
        return {
            cloudsAssetURL: cloudAsset
        };
    },

    computed: {
        ...mapState({
            sequence(state) {
                return state.sequences[this.id];
            },
            rawState(state) {
                return state.sequences[this.id].background;
            },
            collage(state) {
                return state.sequences[this.id].background.collage;
            }
        }),

        isPrevious() {
            return this.$store.getters['sequences/' + this.id + '/hasPreviousBackground'];
        },

        previousWithBackground() {
            return this.$store.getters['sequences/' + this.id + '/previousWithBackground'];
        },

        previousBackgroundSyncBy() {
            if (!this.isPrevious || (!this.isVideo && !this.isAnimated)) return null;
            return this.$stage.getBackgroundElement(this.previous.id).$refs[this.isVideo ? '$bgVideo' : '$bgAnimated'];
        },
        resolvedID() {
            return !this.isPrevious || !this.previousWithBackground ? this.id : this.previousWithBackground.id;
        },
        state() {
            return !this.isPrevious || !this.previousWithBackground
                ? this.rawState
                : this.previousWithBackground.background;
        },

        isColor() {
            return (
                (this.isPrevious && !this.previousWithBackground) ||
                this.$store.getters['sequences/' + this.resolvedID + '/hasColorBackground']
            );
        },
        isImage() {
            return this.$store.getters['sequences/' + this.resolvedID + '/hasImageBackground'];
        },
        isVideo() {
            return this.$store.getters['sequences/' + this.resolvedID + '/hasVideoBackground'];
        },
        isCartoon() {
            return this.$store.getters['sequences/' + this.resolvedID + '/hasCartoonBackground'];
        },
        isCollage() {
            return this.$store.getters['sequences/' + this.resolvedID + '/hasCollageBackground'];
        },
        isAnimated() {
            return this.$store.getters['sequences/' + this.resolvedID + '/hasAnimatedBackground'];
        },
        isRecording() {
            return this.$store.getters['sequences/' + this.resolvedID + '/hasRecordingBackground'];
        },
        isMapZoom() {
            return this.$store.getters['sequences/' + this.resolvedID + '/hasMapZoomBackground'];
        },

        isLoadingProduction() {
            return this.$store.getters['sequences/' + this.resolvedID + '/hasLoadingProductionBackground'];
        },

        innerAnimationType() {
            return this.state.animation.type != Background.ANIMATION_NONE
                ? Backgrounds[this.state.animation.type]
                : null;
        },

        panelHasBgMove() {
            return this.$store.getters['sequences/' + this.id + '/panelHasBgMove'];
        },

        videoVolume() {
            return this.$store.getters['sequences/' + this.resolvedID + '/backgroundVolume'];
        },

        videoSrc() {
            return this.$store.getters['sequences/' + this.resolvedID + '/backgroundVideoSrc'];
        },

        recordingSrc() {
            return this.$store.getters['sequences/' + this.resolvedID + '/backgroundRecordingSrc'];
        },

        containerID() {
            return this.id + '-bg';
        },

        containerStyles() {
            return {
                backgroundColor: this.isColor ? this.state.color.start : null
            };
        },

        blurStyles() {
            return !this.state.blur
                ? {}
                : {
                      backdropFilter: 'blur(' + Background.BLUR_FILTER_AMOUNT + Dimension.PIXEL_UNIT + ')'
                  };
        },

        mapZoomStyles() {
            let bgSizeRect = {
                width: this.format.width,
                height: this.format.height
            };

            return {
                transform:
                    'translate(-50%, -50%) scale(' +
                    Math.max(
                        1,
                        Background.MAP_ZOOM_WIDTH / Background.MAP_ZOOM_HEIGHT / (bgSizeRect.width / bgSizeRect.height)
                    ) +
                    ')'
            };
        },

        loadingProductionPattern() {
            return (
                'url(' +
                (this.detection.browser.safari ? window.location.href : '') +
                '#loading-production-logo-pattern)'
            );
        },

        loadingProductionStyles() {
            return {
                transform: 'scale(' + 1 / this.$stage.scale + ')'
            };
        },

        collageImages() {
            let images_url = [];
            Object.keys(this.collage.images)
                .filter((image) => {
                    return !/(?:__ref|__id)$/.test(image);
                })
                .forEach((attr) => {
                    if (this.collage.images[attr] !== '') images_url.push(this.collage.images[attr]);
                });
            return images_url;
        },

        collageImagesWidth() {
            return this.state.animation.type === Background.COLLAGE_INCLINED_TYPE ? '130%' : '100%';
        }
    },

    watch: {
        format(newValue, oldValue) {
            if (newValue.width != oldValue.width || newValue.height != oldValue.height) this.updateTimeline();
        },

        'rawState.type': {
            handler() {
                this.updateTimeline();
            }
        },
        'state.type': {
            handler() {
                if (this.state != this.rawState) this.updateTimeline();
            }
        },

        'state.image.src': {
            handler() {
                this.updateTimeline();
            }
        },
        'state.image.cartoonSrc': {
            handler() {
                this.updateTimeline();
            }
        },
        'state.video.animatedSrc': {
            handler() {
                this.updateTimeline();
            }
        },
        'state.recording.src': {
            handler() {
                this.updateTimeline();
            }
        },

        'state.animation.type': {
            handler() {
                this.updateTimeline();
            }
        },
        'state.animation.zoomTarget': {
            handler() {
                this.updateTimeline();
            }
        },
        'state.animation.zoomTargetX': {
            handler() {
                this.updateTimeline();
            }
        },
        'state.animation.zoomTargetY': {
            handler() {
                this.updateTimeline();
            }
        },
        'state.collage.animated': {
            handler() {
                this.updateTimeline();
            }
        },

        'state.mapZoom.marker.enabled': {
            handler() {
                this.updateTimeline();
            }
        }
    },

    methods: {
        setEl() {
            let selector =
                '#' +
                this.containerID +
                ' > ' +
                this.sequenceInnerTagName +
                (!this.detection.browser.edge ? ' > div' : '');
            this.$store.commit('sequences/' + this.id + '/setBackgroundEl', document.querySelector(selector));
        },

        update(event) {
            this.updateTimeline();
        },

        updateTimeline() {
            this.$store.commit('sequences/' + this.id + '/setBackgroundTimeline', () => this.getTimeline());
        },

        getTimeline() {
            this._timeline.seek(0);
            this._timeline.clear();
            this._timeline.kill();

            // clear properties of inner background animation
            const animatedRef = this.getAnimatedRef();
            if (this.$refs[animatedRef])
                gsap.set(this.$refs[animatedRef].$el, { clearProps: 'transform,transformOrigin' });

            this._timeline = gsap.timeline({ paused: true, id: Timeline.BACKGROUND_TIMELINE_ID });

            if (this.$refs.$bgVideo) {
                this._timeline.add(this.$refs.$bgVideo.timeline().play(), 0);
                if (
                    this.$refs.$bgVideo.totalDuration !==
                    this.$store.getters['sequences/' + this.id + '/backgroundVideoTotalDuration']
                )
                    this.$store.commit(
                        'sequences/' + this.id + '/setBackgroundVideoTotalDuration',
                        this.$refs.$bgVideo.totalDuration
                    );
            } else if (this.$refs.$bgAnimated) {
                this._timeline.add(this.$refs.$bgAnimated.timeline().play(), 0);
            } else if (this.$refs.$bgRecording) {
                this._timeline.add(this.$refs.$bgRecording.timeline().play(), 0);
                this.$store.commit(
                    'sequences/' + this.id + '/recording/setTotalDuration',
                    this.$refs.$bgRecording.totalDuration
                );
            }

            if (
                this.isColor ||
                this.isImage ||
                (this.isVideo && this.state.video.src === '') ||
                (this.isAnimated && this.state.video.animatedSrc === '') ||
                (this.isRecording && this.state.recording.src === '')
            )
                this._timeline.to({}, { duration: Duration.READING_DEFAULT });

            if (
                (this.isImage || this.isVideo || this.isAnimated || this.isCartoon || this.isRecording) &&
                this.innerAnimationType
            ) {
                let ts = this._timeline.totalDuration() ? 1 / this._timeline.totalDuration() : 10000;
                this._timeline.add(this.getInnerAnimationTimeline().timeScale(ts), 0);
            } else this._innerAnimationTimeline.clear();

            if (this.isCollage && this.collage.animated) {
                let sequenceDuration = this.sequence.timeline().totalDuration();
                if (this.sequence.options.duration) sequenceDuration = this.sequence.options.duration;

                this.collageImages.forEach((collageElement, index) => {
                    let collageElementSelector = '#' + this.containerID + ' .studio-bg-collage-element-' + index,
                        animationDuration = sequenceDuration / this.collageImages.length,
                        animationStart = index * animationDuration;

                    this._timeline.fromTo(
                        collageElementSelector,
                        { zIndex: 0 },
                        { zIndex: 1, duration: 0.001 },
                        animationStart
                    );
                    this._timeline.fromTo(
                        collageElementSelector + ' .image-container',
                        { scale: 1 },
                        { scale: 1.3, transformOrigin: 'center', duration: animationDuration, ease: 'power1.in' },
                        animationStart
                    );
                    this._timeline.to(
                        collageElementSelector,
                        { zIndex: 0, duration: 0.001 },
                        animationStart + animationDuration
                    );
                });
            }

            if (this.isMapZoom) {
                this._timeline.set(
                    '#' + this.containerID + ' .studio-map-zoom-image:not(.studio-map-zoom-image-roadmap)',
                    { autoAlpha: 1 },
                    0
                );
                this._timeline.fromTo(
                    '#' + this.containerID + ' .studio-map-zoom-level-1',
                    { scale: 1 },
                    {
                        duration: 5,
                        scale: 1030,
                        force3D: this.detection.browser.safari ? false : 'auto',
                        ease: CustomEase.create('custom', 'M0,0,C0.512,0,0.642,0.131,0.75,0.352,0.87,0.598,0.902,1,1,1')
                    }
                );
                this._timeline.fromTo(
                    '#' + this.containerID + ' .studio-map-zoom-level-1',
                    { rotation: 0 },
                    {
                        duration: 4.2,
                        rotation: 12.5,
                        ease: CustomEase.create('custom', 'M0,0,C0.23,0,0.174,1,0.4,1,0.634,1,0.598,0,1,0')
                    },
                    0.8
                );
                this._timeline.fromTo(
                    '#' + this.containerID + ' .studio-map-zoom-image-roadmap',
                    { autoAlpha: 0 },
                    { duration: 0.4, autoAlpha: 1 }
                );

                this._timeline.add(
                    [
                        gsap.fromTo(
                            '#' + this.containerID + ' .studio-map-zoom-clouds-1',
                            { scale: 1.3, rotation: 0 },
                            { duration: 0.5, scale: 4.7, rotation: 5 }
                        ),
                        gsap.fromTo(
                            '#' + this.containerID + ' .studio-map-zoom-clouds-1',
                            { autoAlpha: 0 },
                            { duration: 0.3, autoAlpha: 1 }
                        ),
                        gsap.to('#' + this.containerID + ' .studio-map-zoom-clouds-1', {
                            delay: 0.4,
                            duration: 0.1,
                            autoAlpha: 0
                        })
                    ],
                    1.6
                );
                this._timeline.add(
                    [
                        gsap.fromTo(
                            '#' + this.containerID + ' .studio-map-zoom-clouds-2',
                            { scale: 0.75, rotation: 17 },
                            { duration: 0.6, scale: 3.8, rotation: 33 }
                        ),
                        gsap.fromTo(
                            '#' + this.containerID + ' .studio-map-zoom-clouds-2',
                            { autoAlpha: 0 },
                            { duration: 0.3, autoAlpha: 1 }
                        ),
                        gsap.to('#' + this.containerID + ' .studio-map-zoom-clouds-2', {
                            delay: 0.5,
                            duration: 0.1,
                            autoAlpha: 0
                        })
                    ],
                    1.8
                );
                this._timeline.add(
                    [
                        gsap.fromTo(
                            '#' + this.containerID + ' .studio-map-zoom-clouds-2',
                            { scale: 0.75, rotation: -30 },
                            { duration: 0.4, scale: 3.8, rotation: -40 }
                        ),
                        gsap.fromTo(
                            '#' + this.containerID + ' .studio-map-zoom-clouds-2',
                            { autoAlpha: 0 },
                            { duration: 0.2, autoAlpha: 1 }
                        ),
                        gsap.to('#' + this.containerID + ' .studio-map-zoom-clouds-2', {
                            delay: 0.3,
                            duration: 0.1,
                            autoAlpha: 0
                        })
                    ],
                    2.7
                );

                this._timeline.set(
                    '#' + this.containerID + ' .studio-map-zoom-level-1 > .studio-map-zoom-image',
                    { autoAlpha: 0 },
                    0.7
                );
                this._timeline.set(
                    '#' + this.containerID + ' .studio-map-zoom-level-2 > .studio-map-zoom-image',
                    { autoAlpha: 0 },
                    2
                );
                this._timeline.set(
                    '#' + this.containerID + ' .studio-map-zoom-level-3 > .studio-map-zoom-image',
                    { autoAlpha: 0 },
                    3.2
                );
                this._timeline.set(
                    '#' + this.containerID + ' .studio-map-zoom-level-4 > .studio-map-zoom-image',
                    { autoAlpha: 0 },
                    5
                );
                this._timeline.set(
                    '#' +
                        this.containerID +
                        ' .studio-map-zoom-level-4 > .studio-map-zoom-image:not(.studio-map-zoom-image-roadmap)',
                    { autoAlpha: 0 }
                );

                if (this.state.mapZoom.marker.enabled) {
                    let mr = Background.MAP_ZOOM_WIDTH / Background.MAP_ZOOM_HEIGHT,
                        fr = this.format.width / this.format.height,
                        mh = (fr >= mr ? this.format.height : this.format.height / (mr / fr)) + 10;
                    this._timeline.fromTo(
                        '#' + this.containerID + ' .studio-map-zoom-marker-shadow',
                        { scale: 3, xPercent: -50, yPercent: 50, autoAlpha: 0 },
                        { duration: 0.5, scale: 1, xPercent: -50, yPercent: 50, autoAlpha: 1, ease: 'bounce.out' },
                        '+=0.15'
                    );
                    this._timeline.fromTo(
                        '#' + this.containerID + ' .studio-map-zoom-marker-image',
                        { scale: 1, y: -mh / 2 },
                        { duration: 0.5, scale: 1, y: 0, ease: 'bounce.out' },
                        '<'
                    );
                }

                this._timeline.set({}, {}, '+=' + Duration.BG_MAP_ZOOM_READING_DEFAULT);
            }

            if (!this.isColor && this.rawState.overlay.enabled) {
                this.$refs.$bgOverlay.releaseTimeline();
                this._timeline.add(this.$refs.$bgOverlay.timeline(), 0);
            }

            if (this.panelHasBgMove) {
                this._timeline.add(this.getPanelBackgroundAnimationTimeline(), 0.0001);
            } else {
                this._panelBackgroundTimeline.clear();
            }

            this._timeline.addLabel('end');
            return this._timeline;
        },

        getInnerAnimationTimeline() {
            const animatedRef = this.getAnimatedRef();

            this._innerAnimationTimeline.seek(0);
            this._innerAnimationTimeline.clear();
            this._innerAnimationTimeline.kill();

            this._innerAnimationTimeline = gsap.timeline({ id: Timeline.INNER_BACKGROUND_TIMELINE_ID });
            this._innerAnimationTimeline.add(
                this.innerAnimationType.animate(this.$refs[animatedRef].$el, this.state.animation),
                0
            );
            return this._innerAnimationTimeline;
        },

        getPanelBackgroundAnimationTimeline() {
            let panelStart = this.sequence.panel.animation.start,
                panelEnd = this.sequence.panel.animation.end;

            this._panelBackgroundTimeline.seek(0);
            this._panelBackgroundTimeline.clear();
            this._panelBackgroundTimeline.kill();

            this._panelBackgroundTimeline.set(this.$refs.$bgAssets, { clearProps: 'transform' }, 0);
            this._panelBackgroundTimeline.add(
                this.$stage.getSequenceElement(this.id).getPanel().getBackgroundTimeline(this.$refs.$bgAssets),
                Math.max(0.0001, panelStart)
            );

            if (panelEnd != Duration.END_DEFAULT) {
                this._panelBackgroundTimeline.add(
                    this.$stage.getSequenceElement(this.id).getPanel().getBackgroundTimelineOut(this.$refs.$bgAssets),
                    Math.max(0.0001, panelEnd)
                );
            }

            return this._panelBackgroundTimeline;
        },

        getMapZoomURL(zoomLevel, roadmap) {
            let url = Background.MAP_ZOOM_URL;
            url = url.replace(Background.MAP_ZOOM_COORDS_PLACEHOLDER, this.state.mapZoom.coords);
            url = url.replace(Background.MAP_ZOOM_LEVEL_PLACEHOLDER, Background.MAP_ZOOM_LEVELS[zoomLevel]);
            url = url.replace(
                Background.MAP_ZOOM_TYPE_PLACEHOLDER,
                (roadmap && Background.MAP_ZOOM_ROADMAP_TYPE) || Background.MAP_ZOOM_SATELLITE_TYPE
            );
            url = url.replace(Background.MAP_ZOOM_API_KEY_PLACEHOLDER, window.STUDIO_MAP_API_KEY || '');
            return url;
        },

        getCollageClass() {
            let animationClassName = (
                !!this.state.animation.type && Background.COLLAGE_TYPES.includes(this.state.animation.type)
                    ? this.state.animation.type
                    : Background.COLLAGE_DEFAULT_TYPE
            ).toLowerCase();
            return 'studio-bg-' + animationClassName;
        },

        getAnimatedRef() {
            let animatedRef = '$bgImage';
            if (this.isVideo) animatedRef = '$bgVideo';
            else if (this.isAnimated) animatedRef = '$bgAnimated';
            else if (this.isCartoon) animatedRef = '$bgCartoon';
            else if (this.isRecording) animatedRef = '$bgRecording';

            return animatedRef;
        }
    },

    created() {
        this._timeline = gsap.timeline({ paused: true, id: Timeline.BACKGROUND_TIMELINE_ID });
        this._innerAnimationTimeline = gsap.timeline({ id: Timeline.INNER_BACKGROUND_TIMELINE_ID });
        this._panelBackgroundTimeline = gsap.timeline({ id: Timeline.PANEL_BG_TIMELINE_ID });
    },

    mounted() {
        this.updateTimeline();
    },

    beforeUnmount() {
        this._panelBackgroundTimeline.kill();
        this._innerAnimationTimeline.kill();
        this._timeline.kill();
    }
};
</script>
