<template>
    <ui-card
        :id="cardElementId"
        :class="[{ 'ui-card-editing': editing }, 'ui-card-' + id]"
        :title="$t('Visual {number}', { number })"
        :added-card-id="addedCardId"
        v-on:mousedown="editingElement"
    >
        <template #header-icon>
            <svg-icon icon="visual-icon" />
        </template>
        <template #menu-content>
            <ui-dropdown-item :disabled="!canCopyCard" @click.prevent="copyCard">
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-copy" />
                    {{ $t('Copy') }}
                </template>
            </ui-dropdown-item>

            <ui-dropdown-item :disabled="!canPasteCard" @click.prevent="pasteCard">
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-clipboard" />
                    {{ $t('Paste') }}
                </template>
            </ui-dropdown-item>

            <ui-dropdown-item
                v-if="isEmotionMode"
                classes="ui-duplicate-item"
                :disabled="!canDuplicateVisual"
                @click.prevent="duplicateCard()"
            >
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-clone" />
                    {{ $t('Duplicate') }}
                </template>
            </ui-dropdown-item>

            <ui-dropdown-item
                v-if="isEmotionMode"
                classes="ui-remove-item"
                :disabled="!canRemoveVisual"
                @click.prevent="removeCard"
            >
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-trash-can" />
                    {{ $t('Remove') }}
                </template>
            </ui-dropdown-item>
        </template>

        <div class="sequence-visual-category ui-card-row">
            <ui-media-selector
                v-if="isImageCategory"
                :id="cardElementId + '-image'"
                key="media-selector-visual-image"
                ref="$imageVisualSelector"
                v-model:media="visualImage"
                :type="mediaTypeImage"
                :label="$t('studio.visual_categories.image')"
                :showLabel="false"
                :disabled="readOnly"
                icon="fa-solid fa-image"
                :trigger-opening="triggerSelection && isImageCategory && !libraryId"
                @[mediaSelectorShowEvent]="openMediaLibrary"
            />
            <ui-media-selector
                v-else-if="isIconCategory"
                :id="cardElementId + '-icon'"
                key="media-selector-visual-icon"
                v-model:media="visualImage"
                :type="mediaTypeIcon"
                :label="$t('studio.visual_categories.icon')"
                :showLabel="false"
                :disabled="readOnly"
                icon="fa-solid fa-image"
                :trigger-opening="triggerSelection && isIconCategory && !libraryId"
                @[mediaSelectorShowEvent]="openMediaLibrary"
            />
            <ui-media-selector
                v-else-if="isVideoCategory"
                :id="cardElementId + '-video'"
                key="media-selector-visual-video"
                v-model:media="visualVideo"
                :type="mediaTypeVideo"
                :label="$t('studio.visual_categories.video')"
                :showLabel="false"
                :disabled="readOnly"
                icon="fa-solid fa-play"
                :trigger-opening="triggerSelection && isVideoCategory && !libraryId"
                @[mediaSelectorShowEvent]="openMediaLibrary"
                @[mediaSelectorChangeEvent]="resetVisualVideoCaptions"
            />
            <ui-library-selector
                v-else-if="isAnimatedCategory && visualCategories.includes(visualAnimatedCategory)"
                ref="$animatedVisualSelector"
                :id="cardElementId + '-animated'"
                v-model="visualType"
                :default-value="visualDefaultTypes[visualCategory]"
                library="visuals"
                :category="elementState.animation.category"
                :preview-path="visualCategoryPreviewPath"
                :label="visualTypeDefaultLabel[visualCategory]"
                :showLabel="false"
                :disabled="readOnly"
                icon="fa-solid fa-image"
                :trigger-opening="
                    triggerSelection &&
                    isAnimatedCategory &&
                    visualCategories.includes(visualAnimatedCategory) &&
                    !libraryId
                "
                @[librarySelectorShowEvent]="openLibrary"
                @[librarySelectorPreviewChangeEvent]="previewVisualType"
            />
            <ui-library-selector
                v-else-if="isCollageCategory"
                ref="$collageVisualSelector"
                :id="cardElementId + '-collage'"
                v-model="visualType"
                :default-value="visualDefaultTypes[visualCategory]"
                library="visuals"
                :category="elementState.animation.category"
                :preview-path="visualCategoryPreviewPath"
                :label="visualTypeDefaultLabel[visualCategory]"
                :showLabel="false"
                icon="fa-solid fa-sparkles"
                :disabled="readOnly"
                :trigger-opening="triggerSelection && isCollageCategory && !libraryId"
                @[librarySelectorShowEvent]="openLibrary"
                @[librarySelectorPreviewChangeEvent]="previewVisualType"
            />
            <ui-library-selector
                v-else-if="isLottieCategory && visualCategories.includes(visualLottieCategory)"
                ref="$lottieVisualSelector"
                :id="cardElementId + '-lottie'"
                v-model="visualType"
                :default-value="visualDefaultTypes[visualCategory]"
                library="visuals"
                :category="elementState.animation.category"
                :preview-path="visualCategoryPreviewPath"
                :label="visualTypeDefaultLabel[visualCategory]"
                :showLabel="false"
                :disabled="readOnly"
                icon="fa-solid fa-image"
                :trigger-opening="
                    triggerSelection &&
                    isLottieCategory &&
                    visualCategories.includes(visualLottieCategory) &&
                    !libraryId
                "
                @[librarySelectorShowEvent]="openLibrary"
                @[librarySelectorPreviewChangeEvent]="previewVisualType"
            />
            <ui-recording-selector
                v-else-if="isRecordingCategory"
                :id="cardElementId + '-recording'"
                v-model:recording="visualRecording"
                :seqId="seqId"
                :type="RECORDING_TYPE_SCREEN"
                :label="visualTypeDefaultLabel[visualCategory]"
                :showLabel="false"
                :disabled="readOnly"
                :trigger-opening="triggerSelection && isRecordingCategory && !libraryId"
                @[recordingSelectorChangeEvent]="resetVisualVideoCaptions"
            />

            <ui-dropdown
                :id="cardElementId + '-category'"
                :select="true"
                v-model="visualCategory"
                :disabled="readOnly"
                :key="visualTypesRender"
                menu-strategy="fixed"
                @[dropdownCategorySelectEvent]="triggerCategoryDropdown"
            >
                <template #dropdown-toggle="{ instance }">
                    <component
                        :is="getVisualIconComponent(instance.selectedValue.toLowerCase())"
                        class="icon"
                        v-if="visualCategories.includes(instance.selectedValue)"
                        :icon="getVisualIconName(instance.selectedValue.toLowerCase())"
                    />
                    <span>{{ instance.toggleLabel }}</span>
                </template>
                <ui-dropdown-item
                    v-for="category in visualCategories"
                    :key="category"
                    :value="category"
                    :label="$t('studio.visual_categories.' + category)"
                    :disabled="recordingDisabled(category)"
                    :show-icon-on-disabled="true"
                    v-tooltip="recordingDisabled(category) && $t('studio.features.recording-disabled')"
                >
                    <template #dropdown-menu-item-label="{ instance }">
                        <component
                            :is="getVisualIconComponent(instance.value.toLowerCase())"
                            class="icon"
                            :icon="getVisualIconName(instance.value.toLowerCase())"
                        />
                        {{ instance.label || instance.value }}
                    </template>
                </ui-dropdown-item>
            </ui-dropdown>

            <div v-if="isVideoCategory || isRecordingCategory" class="sequence-tools-selector-container">
                <UiAssetCaptionSelector
                    :seqId="seqId"
                    :elementId="elementId"
                    :cardElementId="cardElementId"
                    :readOnly="readOnly || isMediaConverting"
                    :iconOnly="true"
                />
                <UiAssetVideoTimeRangeSelector
                    :seqId="seqId"
                    :elementId="elementId"
                    :cardElementId="cardElementId"
                    :readOnly="readOnly || isMediaConverting"
                    :iconOnly="true"
                />
                <UiAssetVideoSpeedSelector
                    :seqId="seqId"
                    :elementId="elementId"
                    :cardElementId="cardElementId"
                    :readOnly="readOnly || isMediaConverting"
                    :iconOnly="true"
                />
                <UiAssetVolumeSelector
                    :seqId="seqId"
                    :elementId="elementId"
                    :cardElementId="cardElementId"
                    :readOnly="readOnly"
                    :iconOnly="true"
                />
            </div>
        </div>

        <div
            v-if="!isAnimatedCategory && !isCollageCategory && !isLottieCategory && isEmotionMode"
            class="sequence-visual-type ui-card-row"
        >
            <ui-library-selector
                ref="$visualSelector"
                :id="cardElementId + '-type'"
                v-model="visualType"
                :default-value="visualDefaultTypes[visualCategory]"
                library="visuals"
                :category="visualCategoryForLibrary"
                :preview-path="visualCategoryPreviewPath"
                :label="visualTypeDefaultLabel[visualCategory]"
                :disabled="readOnly"
                icon="fa-solid fa-sparkles"
                @[librarySelectorShowEvent]="openLibrary"
                @[librarySelectorPreviewChangeEvent]="previewVisualType"
            />
        </div>

        <div v-if="isCollageCategory" class="sequence-visual-collage-images ui-card-row">
            <ul>
                <li
                    v-for="(collageImage, index) in visualCollageImages"
                    :key="'collage-image' + index"
                    class="sequence-collage-image"
                >
                    <ui-media-selector
                        :id="cardElementId + '-image' + (index + 1)"
                        :key="'media-selector-collage-image' + (index + 1)"
                        :media="collageImage"
                        :type="mediaTypeImage"
                        :label="sprintf($t('Image %1$d'), index + 1)"
                        :showLabel="false"
                        :disabled="readOnly"
                        icon="fa-solid fa-image"
                        @[mediaSelectorChangeEvent]="setCollageImage(index, $event)"
                        @[mediaSelectorShowEvent]="openMediaLibrary"
                    />
                </li>
            </ul>
        </div>

        <div v-if="isColorizable" class="sequence-visual-colors ui-card-row">
            <ui-switch-button
                v-if="!isLottieCategory"
                :id="cardElementId + '-enable-color'"
                class="ui-card-row"
                :label="$t('Change color')"
                v-model="enableVisualColor"
                :disabled="readOnly"
            />
            <div class="sequence-visual-colors-list ui-card-row" v-if="enableVisualColor || isLottieCategory">
                <ui-color-selector
                    :id="cardElementId + '-color-start'"
                    key="color-selector-visual-color-start"
                    v-model:color="visualColorStart"
                    :default-color="''"
                    :palette="brandPalette"
                    :enable-other-colors="brandEnableOtherColors"
                    :label="$t('Choose a color')"
                    :show-label="!visualUses.includes('color.second')"
                    :disabled="readOnly"
                />
                <ui-color-selector
                    v-if="visualUses.includes('color.second')"
                    :id="cardElementId + '-color-second'"
                    key="color-selector-visual-color-second"
                    v-model:color="visualColorSecond"
                    :default-color="''"
                    :palette="brandPalette"
                    :enable-other-colors="brandEnableOtherColors"
                    :label="$t('Choose a color')"
                    :show-label="false"
                    :disabled="readOnly"
                />
                <ui-color-selector
                    v-if="visualUses.includes('color.third')"
                    :id="cardElementId + '-color-third'"
                    key="color-selector-visual-color-third"
                    v-model:color="visualColorThird"
                    :default-color="defaultThirdColor"
                    :palette="thirdColorPalette"
                    :enable-other-colors="false"
                    :label="$t('Choose a color')"
                    :show-label="false"
                    :add-black-and-white="!isLottieCategory"
                    :disabled="readOnly"
                    :is-brand-palette="false"
                />
            </div>
        </div>

        <div class="sequence-visual-opacity ui-card-row">
            <ui-range-input
                :id="cardElementId + '-opacity'"
                v-model="visualOpacity"
                :label="$t('Opacity')"
                :disabled="readOnly"
            />
        </div>

        <div v-if="visualUses.includes('animation.fit')" class="sequence-visual-fit ui-card-row">
            <ui-switch-button
                :id="cardElementId + '-fit'"
                :label="$t('Fit in')"
                v-model="visualFit"
                :disabled="readOnly"
            />
        </div>

        <template #footer>
            <ui-dropdown
                v-if="isEmotionMode"
                :id="cardElementId + '-position'"
                class="centered position-dropdown"
                :menu="false"
                :caret="false"
                :scrollable="false"
                menu-placement="bottom"
            >
                <template #dropdown-toggle>
                    <fa-icon class="icon" icon="fa-regular fa-arrows-up-down-left-right" />
                    <span>{{ $t('Position') }}</span>
                </template>
                <ui-number-input
                    :id="cardElementId + '-width'"
                    class="ui-content-row"
                    v-model="visualWidth"
                    :default-value="visualWidthDefault"
                    :min="1"
                    :decimals="1"
                    :label="$t('Width')"
                    :disabled="readOnly"
                />
                <ui-number-input
                    :id="cardElementId + '-height'"
                    class="ui-content-row"
                    v-model="visualHeight"
                    :default-value="visualHeightDefault"
                    :min="1"
                    :decimals="1"
                    :label="$t('Height')"
                    :disabled="readOnly"
                />
                <div class="ui-content-field ui-content-row" :class="{ disabled: readOnly }">
                    <label>{{ $t('Horizontal position') }}</label>
                    <ui-dropdown
                        :id="cardElementId + '-hor-position'"
                        :select="true"
                        :editable="true"
                        :editable-reg-exp="/^\-?\d+(?:(?:\.|,)\d+)?$/"
                        v-model="visualHorizontalPosition"
                        :disabled="readOnly"
                    >
                        <ui-dropdown-item
                            v-for="position in visualHorizontalPositions"
                            :key="position"
                            :value="position"
                            :label="$t('studio.alignments.' + position)"
                        />
                    </ui-dropdown>
                </div>
                <div class="ui-content-field ui-content-row" :class="{ disabled: readOnly }">
                    <label>{{ $t('Vertical position') }}</label>
                    <ui-dropdown
                        :id="cardElementId + '-ver-position'"
                        :select="true"
                        :editable="true"
                        :editable-reg-exp="/^\-?\d+(?:(?:\.|,)\d+)?$/"
                        v-model="visualVerticalPosition"
                        :disabled="readOnly"
                    >
                        <ui-dropdown-item
                            v-for="position in visualVerticalPositions"
                            :key="position"
                            :value="position"
                            :label="$t('studio.alignments.' + position)"
                        />
                    </ui-dropdown>
                </div>
            </ui-dropdown>

            <UiAssetDurationSelector
                :elementId="elementId"
                :cardElementId="cardElementId + '-duration'"
                :seqId="seqId"
                :readOnly="readOnly"
                :iconOnly="true"
            />
        </template>
    </ui-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { printf as sprintf } from 'fast-printf';
import { Align, Color, Dimension, Duration, Sound, STAGE_LOADING_ENDED_EVENT, Visual } from 'cte-video-studio';
import {
    HORIZONTAL_POSITIONS,
    MAX_NB_OF_VISUALS_PER_SEQUENCES,
    RECORDING_ELEMENT_ID,
    RECORDING_TYPE_SCREEN,
    VERTICAL_POSITIONS,
    VISUAL_ELEMENT_ID
} from '@/js/constants/index.js';
import { getPrefixedUuid } from '@/js/utils.js';
import { SequenceCard } from '../../../../mixins';
import { v4 as uuidv4 } from 'uuid';
import UiRecordingSelector, { UI_RECORDING_SELECTOR_CHANGE } from '../../../../../components/UiRecordingSelector.vue';
import UiAssetVideoTimeRangeSelector from '../selectors/time-range/UiAssetVideoTimeRangeSelector.vue';
import UiAssetVolumeSelector from '../selectors/volume/UiAssetVolumeSelector.vue';
import UiAssetVideoSpeedSelector from '../selectors/speed/UiAssetVideoSpeedSelector.vue';
import UiAssetDurationSelector from '../selectors/duration/UiAssetDurationSelector.vue';
import UiAssetCaptionSelector from '../selectors/caption/UiAssetCaptionSelector.vue';
import { UsesTooltip } from '@/js/mixins/index.js';

const VISUAL_BRAND_LOGO_CATEGORY = 'brand-logo';
export const UI_DROPDOWN_SELECT_CHANGE = 'update:modelValue';

export default {
    mixins: [SequenceCard, UsesTooltip],

    inject: ['$videoStudio'],

    components: {
        UiRecordingSelector,
        UiAssetVideoTimeRangeSelector,
        UiAssetVolumeSelector,
        UiAssetVideoSpeedSelector,
        UiAssetDurationSelector,
        UiAssetCaptionSelector
        //
    },

    props: {
        id: {
            type: String,
            default: VISUAL_ELEMENT_ID
        },

        elementId: {
            type: String,
            default: ''
        },

        editing: {
            type: Boolean,
            default: false
        },

        addedCardId: {
            type: String,
            default: ''
        },

        number: {
            type: Number,
            default: 1
        }
    },

    data() {
        return {
            volumeDefault: Sound.VOLUME_DEFAULT,
            playbackRateDefault: Duration.PLAY_BACK_RATE_DEFAULT,
            visualWidthDefault: Dimension.AUTO,
            visualHeightDefault: Dimension.AUTO,
            visualHorizontalPositions: HORIZONTAL_POSITIONS,
            visualVerticalPositions: VERTICAL_POSITIONS,
            visualEndDefault: Duration.END_DEFAULT,
            visualAnimatedCategory: Visual.ANIMATED_CATEGORY,
            visualLottieCategory: Visual.LOTTIE_CATEGORY,
            visualTypesRender: 0,
            recordingSelectorChangeEvent: UI_RECORDING_SELECTOR_CHANGE,
            dropdownCategorySelectEvent: UI_DROPDOWN_SELECT_CHANGE,
            RECORDING_TYPE_SCREEN,
            triggerSelection: false
        };
    },

    computed: {
        ...mapState({
            restrictions: (state) => state.ui.restrictions,
            libraryId: (state) => state.ui.currentLibrary.id
        }),

        ...mapGetters({
            findLibraryItem: 'branding/libraries/findLibraryItem',
            getMediaSrc: 'ui/quickcut/getMediaSrc'
        }),

        cardElementId() {
            return this.seqId + '-' + this.elementId;
        },

        elementState() {
            return this.sequenceState[this.elementId];
        },

        elementStoreModulePath() {
            return this.sequenceStoreModulePath + '/' + this.elementId;
        },

        isImageCategory() {
            return this.visualCategory == Visual.IMAGE_CATEGORY;
        },

        isIconCategory() {
            return this.visualCategory == Visual.ICON_CATEGORY;
        },

        isVideoCategory() {
            return this.visualCategory == Visual.VIDEO_CATEGORY;
        },

        isAnimatedCategory() {
            return this.visualCategory == Visual.ANIMATED_CATEGORY;
        },

        isRecordingCategory() {
            return this.visualCategory == Visual.RECORDING_CATEGORY;
        },

        isColorizable() {
            return (
                this.visualCategory == VISUAL_BRAND_LOGO_CATEGORY ||
                this.isImageCategory ||
                this.isAnimatedCategory ||
                this.isLottieCategory ||
                this.isVideoCategory ||
                this.isIconCategory ||
                this.isRecordingCategory
            );
        },

        isCollageCategory() {
            return this.visualCategory == Visual.COLLAGE_CATEGORY;
        },

        isLottieCategory() {
            return this.visualCategory == Visual.LOTTIE_CATEGORY;
        },

        visualCategories() {
            let categories = [...Visual.CATEGORIES];
            categories.splice(categories.indexOf(Visual.BLUR_CATEGORY), 1);
            categories.splice(0, 0, VISUAL_BRAND_LOGO_CATEGORY);
            categories = categories.filter(
                (category) =>
                    ![Visual.ANIMATED_CATEGORY, Visual.LOTTIE_CATEGORY].includes(category) ||
                    !this.isBrandLibraryEmpty('visuals', category)
            );
            if (!this.isEmotionMode) {
                let cat1 = [Visual.ANIMATED_CATEGORY, Visual.ICON_CATEGORY, VISUAL_BRAND_LOGO_CATEGORY],
                    cat2 = [
                        Visual.IMAGE_CATEGORY,
                        Visual.VIDEO_CATEGORY,
                        Visual.COLLAGE_CATEGORY,
                        Visual.RECORDING_CATEGORY
                    ],
                    cat3 = [Visual.LOTTIE_CATEGORY];
                categories = categories.filter(
                    (category) =>
                        (cat1.includes(category) && cat1.includes(this.visualCategory)) ||
                        (cat2.includes(category) && cat2.includes(this.visualCategory)) ||
                        (cat3.includes(category) && cat3.includes(this.visualCategory))
                );
            }
            return categories;
        },

        canDuplicateVisual() {
            let visualsLength = this.$store.getters['sequences/' + this.seqId + '/visuals'].length;
            return (
                this.canDuplicateCard &&
                visualsLength < MAX_NB_OF_VISUALS_PER_SEQUENCES &&
                (this.restrictions.maxSequenceVisualCards === null ||
                    visualsLength < this.restrictions.maxSequenceVisualCards)
            );
        },

        visualCategory: {
            get() {
                return this.elementState.animation.category != Visual.IMAGE_CATEGORY
                    ? this.elementState.animation.category
                    : (this.elementState.image.useBranding && VISUAL_BRAND_LOGO_CATEGORY) ||
                          this.elementState.animation.category;
            },
            set(category) {
                this.saveHistoryStep(() => {
                    this.$store.commit(
                        this.elementStoreModulePath + '/useBrandingImage',
                        category == VISUAL_BRAND_LOGO_CATEGORY
                    );
                    if (category == VISUAL_BRAND_LOGO_CATEGORY) category = Visual.IMAGE_CATEGORY;

                    let visualRef = {};
                    switch (this.elementState.animation.category) {
                        case Visual.IMAGE_CATEGORY:
                        case Visual.ICON_CATEGORY:
                            visualRef = { src: this.visualImage.src, src__id: this.visualImage.id };
                            break;
                        case Visual.VIDEO_CATEGORY:
                            visualRef = { src: this.visualVideo.src, src__id: this.visualVideo.id };
                            break;
                        case Visual.RECORDING_CATEGORY:
                            visualRef = { src: this.visualRecording.src, src__id: this.visualRecording.id };
                            break;
                    }

                    this._cachedVisualTypes[this.elementState.animation.category] = this.visualType;
                    this._cachedVisualReference[this.elementState.animation.category] = visualRef;

                    let visualSrcAction = '';
                    switch (category) {
                        case Visual.IMAGE_CATEGORY:
                        case Visual.ICON_CATEGORY:
                            visualSrcAction = 'updateImage';
                            break;
                        case Visual.VIDEO_CATEGORY:
                            visualSrcAction = 'updateVideo';
                            break;
                        case Visual.RECORDING_CATEGORY:
                            visualSrcAction = 'updateRecording';
                            break;
                    }

                    this.$store.commit(this.elementStoreModulePath + '/setAnimationCategory', category);
                    this.$store.commit(
                        this.elementStoreModulePath + '/setAnimation',
                        this._cachedVisualTypes[category] || this.visualDefaultTypes[category]
                    );
                    this.$store.dispatch(this.elementStoreModulePath + '/resetImageAndVideo');
                    if (!!visualSrcAction && this._cachedVisualReference[category]) {
                        this.$store.dispatch(
                            this.elementStoreModulePath + '/' + visualSrcAction,
                            this._cachedVisualReference[category]
                        );
                    }

                    if (category === Visual.LOTTIE_CATEGORY) {
                        this.$store.commit(this.elementStoreModulePath + '/setColor', {
                            start: this.brandPalette.color1,
                            second: this.brandPalette.color2
                        });
                    }
                });
            }
        },

        visualCategoryPreviewPath() {
            let categoryFolder =
                this.visualCategory == VISUAL_BRAND_LOGO_CATEGORY || this.visualCategory == Visual.ICON_CATEGORY
                    ? Visual.IMAGE_CATEGORY
                    : this.visualCategory == Visual.RECORDING_CATEGORY
                      ? Visual.VIDEO_CATEGORY
                      : this.visualCategory;
            return 'visuals/' + categoryFolder + '/';
        },

        visualCategoryForLibrary() {
            return this.visualCategory != Visual.RECORDING_CATEGORY
                ? this.elementState.animation.category
                : Visual.VIDEO_CATEGORY;
        },

        visualDefaultTypes() {
            return this.visualCategories.reduce((types, category) => {
                switch (category) {
                    case VISUAL_BRAND_LOGO_CATEGORY:
                    case Visual.IMAGE_CATEGORY:
                    case Visual.ICON_CATEGORY:
                    case Visual.VIDEO_CATEGORY:
                    case Visual.ANIMATED_CATEGORY:
                    case Visual.RECORDING_CATEGORY:
                        types[category] = Visual.DEFAULT;
                        break;
                    case Visual.BLUR_CATEGORY:
                        types[category] = Visual.BLUR_ANIMATION_NAME;
                        break;
                    case Visual.COLLAGE_CATEGORY:
                        types[category] = Visual.COLLAGE_DEFAULT;
                        break;
                    case Visual.LOTTIE_CATEGORY:
                        types[category] = Visual.LOTTIE_DEFAULT;
                        break;
                }
                return types;
            }, {});
        },

        visualTypeDefaultLabel() {
            return this.visualCategories.reduce((labels, category) => {
                switch (category) {
                    case VISUAL_BRAND_LOGO_CATEGORY:
                    case Visual.IMAGE_CATEGORY:
                    case Visual.ICON_CATEGORY:
                    case Visual.VIDEO_CATEGORY:
                    case Visual.RECORDING_CATEGORY:
                        labels[category] = this.$t('Choose an animation');
                        break;
                    case Visual.ANIMATED_CATEGORY:
                        labels[category] = this.$t('Choose a visual');
                        break;
                    default:
                        labels[category] = '';
                        break;
                }
                return labels;
            }, {});
        },

        visualType: {
            get() {
                return this.elementState.animation.type;
            },
            set(type) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.elementStoreModulePath + '/setAnimation', type);
                });
            }
        },

        visualMetadata() {
            return this.findLibraryItem('visuals', this.visualCategory, this.visualType) || null;
        },

        visualUses() {
            return (this.visualMetadata && this.visualMetadata.uses) || [];
        },

        visualImage: {
            get() {
                let ref = { src: '', id: '' };

                if (this.isImageCategory || this.isIconCategory) {
                    ref.src = this.elementState.image.src;
                    ref.id = this.elementState.image.src__id;
                }

                return ref;
            },
            set({ src, id }) {
                this.saveHistoryStep(() => {
                    if (!this.elementState.image.src__ref) {
                        this.$store.commit(
                            this.elementStoreModulePath + '/setImageReference',
                            getPrefixedUuid(this.prefixes.mediaReference)
                        );
                    }
                    this.$store.dispatch(this.elementStoreModulePath + '/updateImage', { src, src__id: id });
                });
            }
        },

        visualCollageImages: {
            get() {
                let index,
                    images = [];
                for (index = 0; index < Visual.COLLAGE_MAX_MEDIA; index++) {
                    images[index] = {
                        src: this.$store.getters[this.elementStoreModulePath + '/collageImage'](index + 1),
                        id: this.$store.getters[this.elementStoreModulePath + '/collageImageId'](index + 1)
                    };
                }
                return images;
            },
            set(images) {
                let imagesStore = images.reduce((store, image, index) => {
                    if (image.id != this.$store.getters[this.elementStoreModulePath + '/collageImageId'](index + 1)) {
                        store['image' + (index + 1)] = image.src;
                        store['image' + (index + 1) + '__id'] = image.id;
                        if (!this.$store.getters[this.elementStoreModulePath + '/collageImageRef'](index + 1)) {
                            store['image' + (index + 1) + '__ref'] = getPrefixedUuid(this.prefixes.mediaReference);
                        }
                    }
                    return store;
                }, {});

                if (!!Object.keys(imagesStore).length) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(this.elementStoreModulePath + '/setCollageImageReferences', imagesStore);
                        this.$store.dispatch(this.elementStoreModulePath + '/updateCollageImages', imagesStore);
                    });
                }
            }
        },

        visualVideo: {
            get() {
                let ref = { src: '', id: '' };

                if (this.isVideoCategory) {
                    ref.src = this.elementState.video.src;
                    ref.id = this.elementState.video.src__id;
                }

                return ref;
            },
            set({ src, id }) {
                // reset video timerange segments and timerange if the video source is changed for UiQuickCut
                if (this.getMediaSrc !== src) {
                    this.$store.commit(`${this.elementStoreModulePath}/setVideoTimerangeSegments`, []);
                    this.$store.commit(`${this.elementStoreModulePath}/setVideoTimerange`, { start: 0, end: 0 });
                }
                this.saveHistoryStep(() => {
                    if (!this.elementState.video.src__ref) {
                        this.$store.commit(
                            this.elementStoreModulePath + '/setVideoReference',
                            getPrefixedUuid(this.prefixes.mediaReference)
                        );
                    }
                    this.$store.dispatch(this.elementStoreModulePath + '/updateVideo', { src, src__id: id });
                });
            }
        },

        visualRecording: {
            get() {
                let ref = { src: '', id: '' };

                if (this.isRecordingCategory) {
                    ref.src = this.elementState.recording.src;
                    ref.id = this.elementState.recording.src__id;
                }

                return ref;
            },
            set({ src, id }) {
                this.saveHistoryStep(() => {
                    if (!this.elementState.recording.src__ref) {
                        this.$store.commit(
                            this.elementStoreModulePath + '/recording/setSourceRef',
                            getPrefixedUuid(this.prefixes.mediaReference)
                        );
                    }
                    this.$store.dispatch(this.elementStoreModulePath + '/updateRecording', { src, src__id: id });
                });
            }
        },

        enableVisualColor: {
            get() {
                return this.elementState.color.enabled;
            },
            set(value) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.elementStoreModulePath + '/enableColor', value);
                });
            }
        },

        volume: {
            get() {
                return Math.round(100 * this.elementState.video.volume);
            },
            set(value) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.elementStoreModulePath + '/setVideoVolume', value / 100);
                });
            }
        },

        playbackRate: {
            get() {
                return this.elementState.video.playbackRate;
            },
            set(value) {
                if (value != this.playbackRate) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(this.elementStoreModulePath + '/setVideoPlaybackRate', value);
                    });
                }
            }
        },

        visualColorStart: {
            get() {
                let color = { ref: Color.NONE, value: Color.NONE };

                if (this.isColorizable) {
                    color.ref = this.elementState.color.start__ref;
                    color.value = this.elementState.color.start;
                }

                return color;
            },
            set({ ref, value }) {
                this.saveHistoryStep(() => {
                    this.$store.commit(
                        this.elementStoreModulePath + '/setColor',
                        Object.assign({}, this.elementState.color, { start__ref: ref, start: value })
                    );
                });
            }
        },

        visualColorSecond: {
            get() {
                let color = { ref: Color.NONE, value: Color.NONE };

                if (this.isColorizable) {
                    color.ref = this.elementState.color.second__ref;
                    color.value = this.elementState.color.second;
                }

                return color;
            },
            set({ ref, value }) {
                this.saveHistoryStep(() => {
                    this.$store.commit(
                        this.elementStoreModulePath + '/setColor',
                        Object.assign({}, this.elementState.color, { second__ref: ref, second: value })
                    );
                });
            }
        },

        visualColorThird: {
            get() {
                let color = { ref: Color.NONE, value: Color.NONE };

                if (this.isColorizable) {
                    color.ref = this.elementState.color.third__ref;
                    color.value = this.elementState.color.third;
                }

                return color;
            },
            set({ ref, value }) {
                this.saveHistoryStep(() => {
                    this.$store.commit(
                        this.elementStoreModulePath + '/setColor',
                        Object.assign({}, this.elementState.color, { third__ref: ref, third: value })
                    );
                });
            }
        },

        visualOpacity: {
            get() {
                return Math.round(100 * this.$store.getters[this.elementStoreModulePath + '/opacity']);
            },
            set(value) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.elementStoreModulePath + '/setOpacity', {
                        value: (value / 100).toFixed(2),
                        custom: null
                    });
                });
            }
        },

        visualFit: {
            get() {
                return this.elementState.animation.fit;
            },
            set(value) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.elementStoreModulePath + '/setFit', value);
                });
            }
        },

        visualVideoCaptions: {
            get() {
                return (this.isVideoCategory && this.elementState.video.captions) || [];
            },
            set(value) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.elementStoreModulePath + '/setVideoCaptions', value);
                });
            }
        },

        visualVideoCaptioning: {
            get() {
                return this.isVideoCategory && this.elementState.video.captioning;
            },
            set(value) {
                if (!!value) {
                    this.$store.dispatch(this.elementStoreModulePath + '/startVideoCaptioning', value);
                } else {
                    this.$store.dispatch(this.elementStoreModulePath + '/cancelVideoCaptioning');
                }
                this.$store.commit(this.elementStoreModulePath + '/setVideoCaptioningStatus', null);
                this.saveVideo();
            }
        },

        visualVideoCaptioningStatus() {
            return this.isVideoCategory ? this.elementState.video.captioningStatus : null;
        },

        visualWidth: {
            get() {
                return this.$store.getters[this.elementStoreModulePath + '/size'].width != Dimension.AUTO
                    ? parseFloat(this.$store.getters[this.elementStoreModulePath + '/size'].width)
                    : '';
            },
            set(value) {
                if (value != this.visualWidth) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(this.elementStoreModulePath + '/setWidth', {
                            value: Dimension.CUSTOM,
                            custom: value != Dimension.AUTO ? value + Dimension.PERCENT_UNIT : value
                        });
                    });
                }
            }
        },

        visualHeight: {
            get() {
                return this.$store.getters[this.elementStoreModulePath + '/size'].height != Dimension.AUTO
                    ? parseFloat(this.$store.getters[this.elementStoreModulePath + '/size'].height)
                    : '';
            },
            set(value) {
                if (value != this.visualHeight) {
                    this.saveHistoryStep(() => {
                        if ((value != Dimension.AUTO) ^ this.elementState.size.height.enabled) {
                            this.$store.commit(
                                this.elementStoreModulePath + '/enableHeight',
                                !this.elementState.size.height.enabled
                            );
                        }
                        this.$store.commit(this.elementStoreModulePath + '/setHeight', {
                            value: Dimension.CUSTOM,
                            custom: value != Dimension.AUTO ? value + Dimension.PERCENT_UNIT : value
                        });
                    });
                }
            }
        },

        visualHorizontalPosition: {
            get() {
                return this.elementState.position.alignH != Align.CUSTOM
                    ? this.elementState.position.alignH
                    : parseFloat(this.elementState.position.alignX);
            },
            set(value) {
                let parsedValue = value.replace(',', '.'),
                    position = {
                        alignH: !isNaN(parsedValue) ? Align.CUSTOM : value,
                        alignX: !isNaN(parsedValue) ? parsedValue : Dimension.ZERO
                    };
                if (
                    position.alignH != this.elementState.position.alignH ||
                    position.alignX != this.elementState.position.alignX
                ) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(
                            this.elementStoreModulePath + '/setPosition',
                            Object.assign({}, this.elementState.position, position)
                        );
                    });
                }
            }
        },

        visualVerticalPosition: {
            get() {
                return this.elementState.position.alignV != Align.CUSTOM
                    ? this.elementState.position.alignV
                    : parseFloat(this.elementState.position.alignY);
            },
            set(value) {
                let parsedValue = value.replace(',', '.'),
                    position = {
                        alignV: !isNaN(parsedValue) ? Align.CUSTOM : value,
                        alignY: !isNaN(parsedValue) ? parsedValue : Dimension.ZERO
                    };
                if (
                    position.alignV != this.elementState.position.alignV ||
                    position.alignY != this.elementState.position.alignY
                ) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(
                            this.elementStoreModulePath + '/setPosition',
                            Object.assign({}, this.elementState.position, position)
                        );
                    });
                }
            }
        },

        // visualStart: {
        //     get() {
        //         return this.$store.getters[this.elementStoreModulePath + '/start'];
        //     },
        //     set(value) {
        //         if (value != this.visualStart) {
        //             this.saveHistoryStep(() => {
        //                 if (this.visualEnd != '' && value > this.visualEnd) {
        //                     this.$store.commit(this.elementStoreModulePath + '/setEnd', {
        //                         value: value + (this.visualEnd - this.visualStart),
        //                         custom: Duration.NONE
        //                     });
        //                 }
        //                 this.$store.commit(this.elementStoreModulePath + '/setStart', { value, custom: Duration.NONE });
        //             });
        //         }
        //     }
        // },

        // visualEnd: {
        //     get() {
        //         return this.$store.getters[this.elementStoreModulePath + '/end'] != Duration.END_DEFAULT
        //             ? this.$store.getters[this.elementStoreModulePath + '/end']
        //             : '';
        //     },
        //     set(value) {
        //         if (value != this.visualEnd) {
        //             this.saveHistoryStep(() => {
        //                 this.$store.commit(this.elementStoreModulePath + '/setEnd', { value, custom: Duration.NONE });
        //             });
        //         }
        //     }
        // },

        thirdColorPalette() {
            let palette = this.brandPalette;
            if (this.isLottieCategory) {
                palette = {};
                Color.SKINS.forEach((color, key) => {
                    palette['color' + (key + 1)] = color;
                });
            }
            return palette;
        },

        defaultThirdColor() {
            return this.isLottieCategory ? Color.CARTOON_THIRD_COLOR : '';
        },

        // videoRange: {
        //     get() {
        //         const range = { start: 0, end: -1 };

        //         if (this.isVideoCategory) {
        //             range.start = this.elementState.video.timerange.start;
        //             range.end = this.elementState.video.timerange.end;
        //         }

        //         return range;
        //     },
        //     set({ start, end }) {
        //         if (start !== this.videoRange.start || end !== this.videoRange.end) {
        //             this.saveHistoryStep(() => {
        //                 this.$store.commit(this.elementStoreModulePath + '/setVideoTimerange', { start, end });
        //             });
        //         }
        //     }
        // },

        // activeTimerange() {
        //     const videoTotalDurationRounded =
        //         Math.floor((this.elementState.video.totalDuration() + Number.EPSILON) * 100) / 100;
        //     return (
        //         this.isVideoCategory &&
        //         this.visualVideo.src &&
        //         (this.videoRange.start > 0 ||
        //             (this.videoRange.end < videoTotalDurationRounded && this.videoRange.end > -1))
        //     );
        // },

        isMediaConverting() {
            return this.isRecordingCategory && this.$store.getters['loading/isConverting'](this.visualRecording.src);
        }
    },

    watch: {
        visualCategories() {
            this.visualTypesRender++;
        }
    },

    methods: {
        sprintf,

        triggerCategoryDropdown() {
            this.triggerSelection = true; // need to be false at mounted
        },

        copyCard() {
            if (this.canCopyCard) {
                this.$store.dispatch(this.elementStoreModulePath + '/copyToClipboard');
            }
        },

        pasteCard() {
            if (this.canPasteCard) {
                this.$videoStudio.studio.$stage.pauseTimeline();
                this.saveHistoryStep(() => {
                    this.$store.dispatch(this.elementStoreModulePath + '/pasteCard');
                });
            }
        },

        duplicateCard() {
            if (this.canDuplicateVisual) {
                this.$videoStudio.studio.$stage.pauseTimeline();
                this.startHistoryStep();
                this.$store.dispatch('sequences/' + this.seqId + '/duplicateVisual', {
                    newId: Visual.PREFIX_ID + uuidv4(),
                    modelId: this.elementId
                });
                this.saveVideo();
            }
        },

        removeCard() {
            this.saveHistoryStep(() => {
                this.$store.dispatch(this.sequenceStoreModulePath + '/removeElement', this.elementId);
            });
        },

        previewVisualType(type, cancel) {
            this.ignoreHistoryStep(() => {
                this.$store.commit(this.elementStoreModulePath + '/setAnimation', type);
                if (!cancel)
                    this.$videoStudio.studio.$stage.$el.addEventListener(
                        STAGE_LOADING_ENDED_EVENT,
                        this.playVisualTypePreview,
                        {
                            once: true
                        }
                    );
            });
        },

        playVisualTypePreview() {
            this.playPreview(this.$store.getters[this.elementStoreModulePath + '/start'] || 0.001);
        },

        setCollageImage(index, media) {
            let images = [...this.visualCollageImages];
            images[index] = media;
            this.visualCollageImages = [...images];
        },

        editingElement() {
            this.$videoStudio.studio.$stage.getSequenceElement(this.seqId).startEditingElement(null, this.elementId);
        },

        getVisualIconComponent(category) {
            const { component, ...data } = this.getVisualIcon(category);
            return component;
        },

        getVisualIconName(category) {
            const { name, ...data } = this.getVisualIcon(category);
            return name;
        },

        getVisualIcon(category) {
            let component = 'fa-icon',
                name = '';

            switch (category) {
                case Visual.LOTTIE_CATEGORY:
                    name = 'fa-solid fa-person-running';
                    break;
                case Visual.COLLAGE_CATEGORY:
                    name = 'fa-regular fa-table-layout';
                    break;
                case Visual.ICON_CATEGORY:
                    name = 'fa-regular fa-shapes';
                    break;
                case Visual.ANIMATED_CATEGORY:
                    name = 'fa-regular fa-bolt';
                    break;
                case Visual.VIDEO_CATEGORY:
                    name = 'fa-regular fa-circle-play';
                    break;
                case Visual.IMAGE_CATEGORY:
                    name = 'fa-regular fa-image';
                    break;
                case VISUAL_BRAND_LOGO_CATEGORY:
                    name = 'fa-regular fa-hexagon-image';
                    break;
                case Visual.RECORDING_CATEGORY:
                    component = 'svg-icon';
                    name = 'recording-icon';
                    break;
            }

            return { component, name };
        },

        resetVisualVideoCaptions() {
            this.visualVideoCaptions = [];
        },

        recordingDisabled(visualCategory) {
            return visualCategory === RECORDING_ELEMENT_ID && !this.$store.state.auth.user.client.enableRecording;
        }
    },

    mounted() {
        this._cachedVisualTypes = { [this.visualCategory]: this.visualType };
        this._cachedVisualReference = {
            [this.visualCategory]:
                this.isImageCategory || this.isIconCategory
                    ? { src: this.visualImage.src, src__id: this.visualImage.id }
                    : this.isVideoCategory
                      ? { src: this.visualVideo.src, src__id: this.visualVideo.id }
                      : this.isRecordingCategory
                        ? { src: this.visualRecording.src, src__id: this.visualRecording.id }
                        : {}
        };
    }
};
</script>
