import ProfileApiService from '@/js/infrastructure/api/profile/ProfileApiService.js';
import StoreProfileDataService from '@/js/infrastructure/store/user/StoreProfileDataService.js';

export default class ProfileDataService {
    constructor(store) {
        this.profileApiService = new ProfileApiService(store);
        this.storeProfileDataService = new StoreProfileDataService(store);
    }

    async fetchProfileData() {
        let response = await this.profileApiService.fetchProfileData();
        if (response?.data) {
            this.storeProfileDataService.storeUserData(response.data.user);
        }
    }

    async fetchUserLatestVideosData() {
        let response = await this.profileApiService.fetchProfileLatestVideosData();
        this.storeProfileDataService.storeUserLatestVideosData(response.data);
    }

    async editGeneral(params) {
        let response = await this.profileApiService.postProfileGeneralData(params);
        if (response?.data) {
            this.storeProfileDataService.storeUserData(response.data.user);
        }
    }

    async editSecurity(params) {
        let response = await this.profileApiService.postProfileSecurityData(params);
        if (response?.data) {
            this.storeProfileDataService.storeUserData(response.data.user);
        }
    }

    async acceptWelcomeModal() {
        return await this.profileApiService.postWelcomeModalAccepted();
    }
}
