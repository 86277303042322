import { requestWithServiceTokenRefresh } from '@/js/utils.js';

export const setTemplateThumbnail = (axiosInstance, url, file) => {
    const data = new FormData();
    if (file) data.append('thumbnail', file);

    return new Promise((resolve) => {
        return requestWithServiceTokenRefresh(
            axiosInstance
                .post(url, data, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((response) => resolve(response))
        );
    });
};
