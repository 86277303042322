/**
 * Video Studio
 * Duration constants
 */

const Duration = {
    NONE: null,

    INFINITE_DURATION: 100000000,

    CONTROLS_HIDE_DELAY: 3000,

    START_DEFAULT: 0,
    END_DEFAULT: -1,
    READING_DEFAULT: 1,
    BG_MAP_ZOOM_READING_DEFAULT: 0.5,
    BG_OVERLAY_ANIMATION_TIME_DEFAULT: 0.5,
    PANEL_ANIMATION_TIME_DEFAULT: 0.5,
    FOOTER_START_DEFAULT: 2,
    FOOTER_READING_DEFAULT: 2.5,
    FOOTER_ANIMATION_TIME_DEFAULT: 0.2,
    PLAY_BACK_RATE_DEFAULT: 1,
    VIDEO_SYNC_THRESHOLD: 0.4,

    CUSTOM: 'CUSTOM'
};

export default Duration;
