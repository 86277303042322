<template>
    <section class="ui-body-panel" role="application" aria-describedby="ui-settings-title">
        <h2 id="ui-settings-title" class="visually-hidden">{{ $t('Video settings') }}</h2>

        <card-settings-format />
        <card-settings-template v-if="isTemplate" />
        <card-settings-ai v-if="isTemplate && sequenceMode && canEditAiSettings" />
        <card-settings-music v-if="!sequenceMode" />
        <card-settings-voice-over v-if="!sequenceMode" />
        <card-settings-branding v-if="!sequenceMode && isEmotionMode" />
    </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import {
    CardSettingsFormat,
    CardSettingsTemplate,
    CardSettingsAi,
    CardSettingsMusic,
    CardSettingsVoiceOver,
    CardSettingsBranding
} from './cards';

export default {
    components: {
        CardSettingsFormat,
        CardSettingsTemplate,
        CardSettingsAi,
        CardSettingsMusic,
        CardSettingsVoiceOver,
        CardSettingsBranding
    },

    props: {
        //
    },

    data() {
        return {
            //
        };
    },

    computed: {
        ...mapState({
            sequenceMode: (state) => state.ui.sequenceMode,
            canEditAiSettings: (state) => state.ui.permissions.editAiSettings,
            isTemplate: (state) => state.settings.isTemplate
        }),

        ...mapGetters({
            isEmotionMode: 'ui/isEmotionMode'
        })
    },

    watch: {
        //
    },

    methods: {
        //
    },

    mounted() {
        //
    }
};
</script>
