import axios from 'axios';
import { executeRequest } from '@/js/infrastructure/api/apiClient.js';

const BASE_URL = import.meta.env.VITE_LEGACY_APP_URL;

export default class StoreMediaApiService {
    constructor(store) {
        this.store = store;
    }

    getMediaUploadUrl(file) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.post(`${BASE_URL}/media-v2/get-upload-url`, {
                    extension: file.name.split('.').pop(),
                    mime_type: file.type
                });
            },
            onSuccess: async (response) => response.data,
            actionType: 'getMediaUploadUrl'
        });
    }

    uploadMedia(file) {
        return axios.put(file.uploadData.url, file, { headers: { 'Content-Type': file.type } });
    }

    storeMedia(params) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.post(`${BASE_URL}/media`, {
                    client: params.organizationId,
                    folders: Array.isArray(params.folderIds) ? params.folderIds.toString() : params.folderIds,
                    keywords: Array.isArray(params.tags) ? params.tags.toString() : params.tags,
                    files: params.files,
                    tags: 'recording'
                });
            },
            onSuccess: async (response) => response.data,
            actionType: 'storeMedia'
        });
    }
}
