export default class PaginatedResultDTO {
    constructor(data) {
        this.data = data.data;
        this.meta = {
            currentPage: data.meta?.current_page,
            lastPage: data.meta?.last_page,
            from: data.meta?.from,
            to: data.meta?.to,
            total: data.meta?.total
        };
        this.lastPage = data.last_page; // legacy
    }
}
