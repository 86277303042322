// constants to map key to icons name

const SVG = 'svg-icon';
const FA = 'fa-icon';

export const backgroundTypeIcons = {
    color: { component: FA, name: ['far', 'fill-drip'] },
    image: { component: FA, name: ['far', 'image'] },
    video: { component: FA, name: ['far', 'circle-play'] },
    cartoon: { component: FA, name: ['far', 'house-tree'] },
    animated: { component: FA, name: ['far', 'square-bolt'] },
    collage: { component: SVG, name: 'mosaic-icon' },
    mapzoom: { component: FA, name: ['far', 'map-location-dot'] },
    recording: { component: SVG, name: 'recording-icon' }
};
