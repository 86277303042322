import FetchCompanionApiService from '@/js/infrastructure/api/companion/FetchCompanionApiService.js';

export default class FetchCompanionDataService {
    constructor(store) {
        this.fetchCompanionApiService = new FetchCompanionApiService(store);
    }

    async fetchCompanionData() {
        return (await this.fetchCompanionApiService.fetchCompanionResources()).data;
    }
}
