<template>
    <ui-card :id="id" :class="'ui-card-' + id" :title="$t('Video voice-over')" :hide-footer="isNoneCategory">
        <template #header-icon>
            <svg-icon icon="voice-ai-icon" />
        </template>

        <div class="voice-over-card-content ui-card-row">
            <ui-media-selector
                v-if="isAudioCategory"
                key="media-selector-tts-audio"
                v-model:media="voiceOverMediaAudioModel"
                :type="mediaTypeAudio"
                :label="$t('studio.tts_categories.audio')"
                :showLabel="false"
                :disabled="readOnly"
                icon="fa-solid fa-volume"
                :trigger-opening="triggerSelection && isAudioCategory && !libraryId"
                @[mediaSelectorShowEvent]="openMediaLibrary"
                @[mediaSelectorChangeEvent]="resetVoiceOverCaptions"
            />

            <ui-voice-over-selector
                v-else-if="isVoiceCategory"
                :disabled="readOnly"
                :useInSequence="false"
                :trigger-opening="triggerSelection && isVoiceCategory && !libraryId"
            />

            <ui-recording-selector
                v-else-if="isRecordingCategory"
                v-model:recording="voiceOverRecordingModel"
                :type="RECORDING_TYPE_MICROPHONE"
                :label="$t('studio.tts_categories.recording')"
                :showLabel="false"
                :disabled="readOnly"
                :trigger-opening="triggerSelection && isRecordingCategory && !libraryId"
                @[recordingSelectorChangeEvent]="resetVoiceOverCaptions"
            />

            <ui-dropdown
                :select="true"
                v-model="voiceOverCategory"
                :disabled="readOnly"
                menu-strategy="fixed"
                :default-label="$t('Add voice-over')"
                @[dropdownCategorySelectEvent]="triggerCategoryDropdown"
            >
                <template #dropdown-toggle="{ instance }">
                    <svg-icon v-if="isVoiceCategory && isProcessing" icon="dots-loader" />
                    <span v-else>{{ instance.toggleLabel }}</span>
                </template>
                <ui-dropdown-item
                    v-for="category in ttsCategories"
                    :key="category"
                    :value="category"
                    :label="$t('studio.tts_categories.' + category)"
                    :disabled="recordingDisabled(category)"
                    :show-icon-on-disabled="true"
                    v-tooltip="recordingDisabled(category) && $t('studio.features.recording-disabled')"
                >
                    <template #dropdown-menu-item-label="{ instance }">
                        <slot :name="instance.value.toLowerCase() + '-voice-over-icon'"></slot>
                        {{ instance.label || instance.value }}
                    </template>
                </ui-dropdown-item>
            </ui-dropdown>

            <div class="sequence-tools-selector-container" v-if="!isNoneCategory">
                <UiVoiceOverCaptionSelector :useInSequence="false" :readOnly="readOnly" :iconOnly="true" />
                <UiVoiceOverTimeRangeSelector :useInSequence="false" :readOnly="readOnly" :iconOnly="true" />
                <UiVoiceOverSpeedSelector :useInSequence="false" :readOnly="readOnly" :iconOnly="true" />
                <UiVoiceOverVolumeSelector
                    :useInSequence="false"
                    :readOnly="readOnly"
                    :iconOnly="true"
                    menu-placement="bottom"
                />
            </div>
        </div>

        <template #footer>
            <UiVoiceOverDurationSelector :use-in-sequence="false" :readOnly="readOnly" :iconOnly="true" />
        </template>
    </ui-card>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import UiCard from '../../../../../components/UiCard.vue';
import UiIcon from '../../../../../components/UiIcon.vue';
import { useStore } from 'vuex';
import { useVideo } from '../../../../composables/useVideo';
import { useVoiceOver } from '../../../../composables/useVoiceOver';
import { TTS } from 'cte-video-studio';
import { UI_MEDIA_TYPE_AUDIO } from '@/js/components/UiMediaSelector.vue';
import UiVoiceOverVolumeSelector from '../selectors/volume/UiVoiceOverVolumeSelector.vue';
import UiVoiceOverSelector from '../../../../../components/UiVoiceOverSelector.vue';
import UiVoiceOverTimeRangeSelector from '../selectors/time-range/UiVoiceOverTimeRangeSelector.vue';
import UiVoiceOverCaptionSelector from '../selectors/caption/UiVoiceOverCaptionSelector.vue';
import UiVoiceOverSpeedSelector from '../selectors/speed/UiVoiceOverSpeedSelector.vue';
import { UiDropdown, UiDropdownItem, UiMediaSelector } from '../../../../../components';
import { UI_MEDIA_SELECTOR_SHOW, UI_MEDIA_SELECTOR_CHANGE } from '../../../../../components/UiMediaSelector.vue';
import UiRecordingSelector, { UI_RECORDING_SELECTOR_CHANGE } from '../../../../../components/UiRecordingSelector.vue';
import UiVoiceOverDurationSelector from '../selectors/duration/UiVoiceOverDurationSelector.vue';
import { UI_DROPDOWN_SELECT_CHANGE } from '@/js/components/UiDropdown.vue';
import { RECORDING_ELEMENT_ID, RECORDING_TYPE_MICROPHONE } from '@/js/constants';
import { UsesTooltip } from '@/js/mixins/index.js';

export default defineComponent({
    components: {
        UiVoiceOverDurationSelector,
        UiMediaSelector,
        UiRecordingSelector,
        UiDropdownItem,
        UiDropdown,
        UiVoiceOverSpeedSelector,
        UiVoiceOverCaptionSelector,
        UiVoiceOverTimeRangeSelector,
        UiVoiceOverSelector,
        UiVoiceOverVolumeSelector,
        UiCard,
        UiIcon
    },

    props: {
        id: {
            type: String,
            default: 'settings-voice-over'
        }
    },

    mixins: [UsesTooltip],

    setup() {
        const triggerSelection = ref(false);

        const store = useStore();

        const useInSequence = ref(false);

        const mediaTypeAudio = UI_MEDIA_TYPE_AUDIO;
        const ttsCategories = TTS.CATEGORIES;

        const readOnly = computed(() => store.state.ui.readOnly);
        const libraryId = computed(() => store.state.ui.currentLibrary.id);

        const { isEmotionMode } = useVideo();

        const {
            voiceOverCategory,
            isAudioCategory,
            isVoiceCategory,
            isRecordingCategory,
            voiceOverMediaAudioModel,
            voiceOverRecordingModel,
            isProcessing,
            voiceOverCaptions
        } = useVoiceOver(useInSequence, null);

        const isNoneCategory = computed(() => voiceOverCategory.value === TTS.NONE_CATEGORY);

        const openMediaLibrary = (selector) => {
            store.commit('ui/setCurrentMediaLibrary', {
                mediaType: selector.type,
                selectedMediaId: selector.media.id,
                selector
            });
        };

        const triggerCategoryDropdown = () => {
            triggerSelection.value = true;
        };

        const resetVoiceOverCaptions = () => {
            voiceOverCaptions.value = [];
        };

        const recordingDisabled = (category) =>
            category === RECORDING_ELEMENT_ID && !store.state.auth.user.client.enableRecording;

        return {
            triggerSelection,
            libraryId,
            isEmotionMode,
            mediaTypeAudio,
            readOnly,
            voiceOverCategory,
            voiceOverMediaAudioModel,
            voiceOverRecordingModel,
            ttsCategories,
            isAudioCategory,
            isVoiceCategory,
            isRecordingCategory,
            isNoneCategory,
            isProcessing,
            openMediaLibrary,
            triggerCategoryDropdown,
            resetVoiceOverCaptions,
            recordingDisabled,
            mediaSelectorShowEvent: UI_MEDIA_SELECTOR_SHOW,
            mediaSelectorChangeEvent: UI_MEDIA_SELECTOR_CHANGE,
            recordingSelectorChangeEvent: UI_RECORDING_SELECTOR_CHANGE,
            dropdownCategorySelectEvent: UI_DROPDOWN_SELECT_CHANGE,
            RECORDING_TYPE_MICROPHONE
        };
    }
});
</script>
