<template>
    <router-link
        :to="AIenabled ? { name: 'Companion' } : false"
        :aria-disabled="!AIenabled"
        :class="['home-view-new-video-button from-ai', { disabled: !AIenabled }]"
        v-tooltip="!AIenabled && $t('studio.features.recording-disabled')"
    >
        <fa-icon v-if="!AIenabled" icon="fa-regular fa-lock" class="icon" />
        <svg-icon v-else :icon="`sparkles-ai-${locale}-icon`" />
        <span>{{ $t('home.from-ai') }}</span>
    </router-link>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
const { locale, t } = useI18n();
import { useStore } from 'vuex';
import UsesTooltip from '../../../js/mixins/UsesTooltip.js';
const store = useStore();

const vTooltip = UsesTooltip.directives.tooltip;

const AIenabled = computed(() => {
    return store.state.auth.user.client.videoCompanionEnabled;
});
</script>
