<template>
    <div class="ui-video-image-preview">
        <img
            :src="getDefaultThumbnail(video)"
            :data-src="getStaticThumbnail(video)"
            class="static-preview"
            :class="{ 'default-preview': !video.thumbnails.staticSrc }"
            alt="Static video thumbnail"
            loading="lazy"
        />
        <img
            :src="getDefaultThumbnail(video)"
            :data-src="getPreviewThumbnail(video)"
            class="gif-preview"
            :class="{ 'default-preview': !video.thumbnails.previewSrc }"
            alt="Preview video thumbnail"
            loading="lazy"
        />
    </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue';
import VideoDTO from '@/js/dto/VideoDTO.js';
import TemplateDTO from '@/js/dto/TemplateDTO.js';
import format from '@/js/video-studio/constants/format.js';
import previewLandscape from '@/assets/images/preview-landscape.png';
import previewSquare from '@/assets/images/preview-square.png';
import previewVertical from '@/assets/images/preview-vertical.png';

export default defineComponent({
    name: 'UiVideoImagePreview',
    props: {
        video: {
            type: [VideoDTO, TemplateDTO],
            required: true
        }
    },
    setup() {
        const getStaticThumbnail = (video) => video.thumbnails.staticSrc ?? getDefaultThumbnail(video);
        const getPreviewThumbnail = (video) => video.thumbnails.previewSrc ?? getDefaultThumbnail(video);

        const getDefaultThumbnail = (video) => {
            const ratio = video.format.width / video.format.height;
            switch (ratio) {
                case format.RATIO_16X9:
                    return previewLandscape;
                case format.RATIO_1X1:
                    return previewSquare;
                case format.RATIO_9X16:
                    return previewVertical;
                default:
                    return previewSquare;
            }
        };

        const lazyLoadImages = () => {
            const images = document.querySelectorAll('img[data-src]');
            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const img = entry.target;
                        img.src = img.dataset.src;
                        observer.unobserve(img);
                    }
                });
            });

            images.forEach((img) => observer.observe(img));
        };

        onMounted(lazyLoadImages);

        return {
            getStaticThumbnail,
            getPreviewThumbnail,
            getDefaultThumbnail
        };
    }
});
</script>
