<template>
    <footer class="ui-caption-editor-footer">
        <button v-if="isVisible" class="ui-simple-button ui-caption-reset-button" @click.prevent="resetCaptions">
            <fa-icon class="icon" icon="fa-regular fa-trash-can" />
            <span>{{ $t('Reset captions') }}</span>
        </button>
    </footer>
</template>

<script>
export default {
    name: 'UiCaptionEditorFooter',

    props: {
        isVisible: {
            type: Boolean,
            default: true
        },
        currentCaptions: {
            type: Array
        },
        currentCaptionButton: {
            type: Object
        }
    },

    methods: {
        resetCaptions() {
            let newCaptions = [];
            this.$emit('update:currentCaptions', newCaptions);
            this.currentCaptionButton.change(newCaptions);
        }
    }
};
</script>

<style scoped></style>
