<template>
    <ui-dropdown
        ref="$translatingDropdown"
        id="caption-editor-translation"
        class="centered style-dropdown"
        toggleClass="ui-simple-button"
        :menu="false"
        :scrollable="false"
        menu-placement="bottom"
        :disabled="enable"
    >
        <template #dropdown-toggle>
            <slot name="caption-translate-icon"></slot>
            <span>{{ $t('Translate') }}</span>
        </template>
        <div class="language-selection ui-content-row">
            <label>{{ $t('Select the language to translate the captions to') }}</label>
            <ui-dropdown id="caption-editor-translation-language" :select="true" v-model="translationLanguage">
                <ui-dropdown-item
                    v-for="language in sortedTranslationLanguages"
                    :key="language.value"
                    :value="language.value"
                    :label="language.label"
                />
            </ui-dropdown>
        </div>
        <button class="ui-simple-button ui-simple-button-select" @click.prevent="startTranslation">
            {{ $t('Start') }}
        </button>
    </ui-dropdown>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import UiDropdown from '../../../../../../../../components/UiDropdown.vue';
import UiDropdownItem from '../../../../../../../../components/UiDropdownItem.vue';

export default {
    name: 'UiCaptionEditorActionTranslation',

    components: {
        UiDropdown,
        UiDropdownItem
    },

    props: {
        enable: {
            type: Boolean,
            default: true
        },
        currentCaptions: {
            type: Array
        },
        translatingStatus: {
            type: Object
        },
        shortLanguage: {
            type: String
        },
        currentCaptionButton: {
            type: Object
        }
    },

    data() {
        return {
            translationLanguage: 'en'
        };
    },

    computed: {
        ...mapState({
            translationLanguages: (state) => state.ui.translationConfig.languages
        }),

        sortedTranslationLanguages() {
            return this.translationLanguages
                .map((language) => ({
                    value: language,
                    label: this.$t('studio.translation_languages.' + language)
                }))
                .sort((entryA, entryB) => entryA.label.localeCompare(entryB.label, this.shortLanguage));
        }
    },

    methods: {
        ...mapActions({
            startCaptionsTranslating: 'ui/startCaptionsTranslating'
        }),

        async startTranslation() {
            this.$refs.$translatingDropdown.hide();

            this.startCaptionsTranslating({
                captions: this.currentCaptions,
                language: this.translationLanguage,
                success: (translation) => {
                    this.$emit('update:currentCaptions', translation);
                    this.currentCaptionButton.change(translation);
                },
                error: (error) => {
                    this.$emit('update:translatingStatus', error);
                }
            });
        }
    },

    created() {
        this.translationLanguage = this.shortLanguage;
    }
};
</script>

<style scoped></style>
