import { computed } from 'vue';
import { useStore } from 'vuex';

export function useModal() {
    const store = useStore();

    const modalData = computed(() => store.getters['modals/modalData'].data);
    const modalNavActiveTab = computed(() => store.getters['modals/modalNavActiveTab']);

    const closeModal = (callback = undefined) => {
        store.dispatch('modals/closeModal').then(callback);
    };

    const openModal = (name, data = {}) => {
        store.dispatch('modals/openModal', { name, data });
    };

    const setModalNavActiveTab = (tab) => {
        store.dispatch('modals/setModalNavActiveTab', tab);
    };

    return {
        modalData,
        modalNavActiveTab,
        closeModal,
        openModal,
        setModalNavActiveTab
    };
}
