<template>
  <div>
    <div class="gap" :style="initialGapStyle"></div>
    <div
      v-for="(segment, index) in segments"
      :key="`gap-${index}`"
      class="gap"
      :style="getGapStyle(index)"
    ></div>
    <div class="gap" :style="finalGapStyle"></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GapRenderer',
  props: {
    initialGapStyle: {
      type: Object,
      required: true
    },
    finalGapStyle: {
      type: Object,
      required: true
    },
    segments: {
      type: Array,
      required: true
    },
    getGapStyle: {
      type: Function,
      required: true
    }
  }
});
</script>
<style scoped>
.gap {
    position: absolute;
    top: 0;
    height: 58px;
    background-color: rgba(44, 52, 58, 0.76);
    z-index: 11;
    border-top: 2px solid #29303a;
    border-bottom: 2px solid #29303a;
    cursor: default;
}</style>
