import { executeRequest } from '@/js/infrastructure/api/apiClient.js';

const API_URL = import.meta.env.VITE_EDITION_API_URL;

export default class FetchTemplatesApiService {
    constructor(store) {
        this.store = store;
    }

    fetchTemplates(brandId, options = {}, signal) {
        const {
            page = 1,
            per_page = 25,
            search = '',
            category = '',
            format = '',
            sort = 'created_at',
            direction = 'desc'
        } = options;

        const queryParams = new URLSearchParams({
            page: page.toString(),
            per_page: per_page.toString(),
            sort,
            direction
        });

        if (search) queryParams.append('search', search);
        if (category) queryParams.append('category', category);
        if (format) queryParams.append('format', format);

        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${API_URL}/api/brands/${brandId}/templates?${queryParams.toString()}`, {
                    signal: signal
                });
            },
            onSuccess: async (response) => response.data,
            actionType: 'fetchTemplates',
            signal: signal
        });
    }
}
