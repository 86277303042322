<template>
    <ui-modal
        v-bind="modalProps"
        ref="$modal"
        class="record-device-modal"
        :class="statusClass"
        :show-title="false"
        :show-close="isSettingUp"
        :close-with-backdrop="isSettingUp"
    >
        <div
            class="video-preview video-device-preview"
            v-show="(isSettingUp || isRecording) && usesVideoDevice"
            :class="videoDevicePreviewClass"
        >
            <video ref="$videoDevicePreview" v-show="selectedVideoDeviceId && !videoDeviceError" />
            <div v-if="usesVideoDevice && usesAudioDevice" class="audio-device-preview">
                <audio-visualizer :stream="audioPreviewStream" :disabled="!selectedAudioDeviceId || audioDeviceError" />
            </div>
            <span v-if="!selectedVideoDeviceId">{{ $t('No video stream detected.') }}</span>
            <span v-else-if="videoDeviceError">{{ $t('An error occured with the selected video device.') }}</span>
        </div>

        <div v-if="shouldDisplayAudioVisualizer" class="audio-preview audio-device-preview">
            <audio-visualizer :stream="audioPreviewStream" :disabled="!selectedAudioDeviceId || audioDeviceError" />
            <span v-if="!selectedAudioDeviceId">{{ $t('No audio stream detected.') }}</span>
            <span v-else-if="audioDeviceError">{{ $t('An error occured with the selected audio device.') }}</span>
        </div>

        <template v-if="isSettingUp">
            <div v-if="shouldDisplayDeviceAlert" class="user-devices-alert">
                <fa-icon class="icon" icon="fa-regular fa-triangle-exclamation" />
                <div class="user-devices-alert-message">
                    <p>
                        <span v-if="shouldDisplayVideoDeviceAlert && shouldDisplayAudioDeviceAlert">
                            {{ $t('Oops! It looks like we can’t access your camera and microphone.') }}
                        </span>
                        <span v-else-if="shouldDisplayVideoDeviceAlert">
                            {{ $t('Oops! It looks like we can’t access your camera.') }}
                        </span>
                        <span v-else-if="shouldDisplayAudioDeviceAlert">
                            {{ $t('Oops! It looks like we can’t access your microphone.') }}
                        </span>
                        <a :href="recordingDeviceTutorialUrl" target="_blank">{{ $t('See how to fix this') }}</a>
                    </p>
                </div>
            </div>

            <div class="recording-type-selection">
                <ul class="recording-types">
                    <li>
                        <input
                            type="radio"
                            id="recording-type-screen"
                            v-model="recordingType"
                            :value="RECORDING_TYPE_SCREEN"
                            :disabled="!canRecordScreen"
                        />
                        <label
                            class="recording-button ui-simple-button"
                            :class="{ disabled: !canRecordScreen }"
                            for="recording-type-screen"
                        >
                            <fa-icon class="icon" icon="fa-regular fa-desktop" />
                            <span>{{ $t('Screen') }}</span>
                        </label>
                    </li>
                    <li>
                        <input
                            type="radio"
                            id="recording-type-camera"
                            v-model="recordingType"
                            :value="RECORDING_TYPE_CAMERA"
                            :disabled="!canRecordCamera"
                        />
                        <label
                            class="recording-button ui-simple-button"
                            :class="{ disabled: !canRecordCamera }"
                            for="recording-type-camera"
                        >
                            <fa-icon class="icon" icon="fa-regular fa-camera" />
                            <span>{{ $t('Camera') }}</span>
                        </label>
                    </li>
                    <li>
                        <input
                            type="radio"
                            id="recording-type-both"
                            v-model="recordingType"
                            :value="RECORDING_TYPE_BOTH"
                            :disabled="!canRecordBoth"
                        />
                        <label
                            class="recording-button ui-simple-button"
                            :class="{ disabled: !canRecordBoth }"
                            for="recording-type-both"
                        >
                            <fa-icon class="icon" icon="fa-regular fa-camera-viewfinder" />
                            <span>{{ $t('Both') }}</span>
                        </label>
                    </li>
                    <li>
                        <input
                            type="radio"
                            id="recording-type-microphone"
                            v-model="recordingType"
                            :value="RECORDING_TYPE_MICROPHONE"
                            :disabled="!canRecordMicrophone"
                        />
                        <label
                            class="recording-button ui-simple-button"
                            :class="{ disabled: !canRecordMicrophone }"
                            for="recording-type-microphone"
                        >
                            <fa-icon class="icon" icon="fa-regular fa-microphone" />
                            <span>{{ $t('Mic') }}</span>
                        </label>
                    </li>
                </ul>
            </div>
            <div class="user-devices">
                <ui-dropdown
                    v-show="usesAudioDevice"
                    id="audio-devices"
                    :select="true"
                    v-model="selectedAudioDeviceId"
                    menu-strategy="fixed"
                    :items="availableAudioDevices"
                >
                    <template #dropdown-toggle="{ instance }">
                        <fa-icon class="icon" icon="fa-regular fa-microphone" />
                        <span>{{ instance.toggleLabel }}</span>
                    </template>
                </ui-dropdown>

                <ui-dropdown
                    v-show="usesVideoDevice"
                    id="video-devices"
                    :select="true"
                    v-model="selectedVideoDeviceId"
                    menu-strategy="fixed"
                    :items="availableVideoDevices"
                >
                    <template #dropdown-toggle="{ instance }">
                        <fa-icon class="icon" icon="fa-regular fa-camera" />
                        <span>{{ instance.toggleLabel }}</span>
                    </template>
                </ui-dropdown>
            </div>
        </template>

        <template v-else-if="isRecording">
            <div class="recording-actions">
                <button
                    class="recording-button ui-simple-button ui-simple-button-cancel"
                    @click.prevent="resetRecording"
                >
                    <fa-icon class="icon" icon="fa-regular fa-arrow-rotate-right" />
                    <span>{{ $t('Restart') }}</span>
                </button>
                <button
                    class="recording-button ui-simple-button ui-simple-button-cancel"
                    @click.prevent="cancelRecording"
                >
                    <fa-icon class="icon" icon="fa-regular fa-trash-can" />
                    <span>{{ $t('Cancel') }}</span>
                </button>
                <button class="recording-button ui-simple-button ui-simple-button-cancel" @click.prevent="endRecording">
                    <svg-icon icon="recording-stop-icon" />
                    <span>{{ $t('Stop') }}</span>
                </button>
            </div>
        </template>

        <template v-else-if="isPreviewing || isCompleting">
            <div class="recording-previews" :class="recordingType">
                <div class="video-preview screen-recording-preview" v-show="usesScreen">
                    <video ref="$screenRecordingPreview" controls />
                </div>
                <div class="video-preview video-recording-preview" v-show="usesVideoDevice">
                    <video ref="$videoRecordingPreview" controls />
                </div>
                <div class="audio-preview mic-recording-preview" v-show="recordingType == RECORDING_TYPE_MICROPHONE">
                    <audio ref="$micRecordingPreview" controls />
                </div>
            </div>
        </template>

        <template #modal-footer>
            <template v-if="isSettingUp">
                <button
                    class="ui-simple-button ui-simple-button-run-action"
                    :disabled="!canRecord"
                    @click.prevent="startRecording"
                >
                    <span>{{ $t('Record') }}</span>
                </button>
            </template>
            <template v-else-if="isRecording">
                <p class="recording-status">{{ $t('Currently recording...') }}</p>
            </template>
            <template v-else-if="isPreviewing || isCompleting">
                <button
                    class="ui-simple-button ui-simple-button-cancel"
                    :disabled="isCompleting"
                    @click.prevent="cancelRecording"
                >
                    <span>{{ $t('Cancel') }}</span>
                </button>
                <button
                    class="ui-simple-button ui-simple-button-cancel"
                    :disabled="isCompleting"
                    @click.prevent="resetRecording"
                >
                    <span>{{ $t('Restart') }}</span>
                </button>
                <button
                    class="ui-simple-button ui-simple-button-run-action ui-loading-button"
                    :class="completeButtonClass"
                    :disabled="isCompleting"
                    @click.prevent="completeRecordingProcess"
                >
                    <span>{{ $t('Add') }}</span>
                    <svg-icon icon="dots-loader" class="loader" />
                </button>
            </template>
        </template>
    </ui-modal>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { mapGetters, useStore } from 'vuex';
import Plyr from 'plyr';
import WebRTCService from '@/js/infrastructure/web_rtc/WebRTCService.js';
import StoreRecordedMediaService from '@/js/videos/application/services/recording/StoreRecordedMediaService.js';
import SetRecordingElementService from '@/js/videos/application/services/recording/SetRecordingElementService.js';
import {
    RECORDING_INPUT_DEVICE_AUDIO,
    RECORDING_INPUT_DEVICE_VIDEO,
    RECORDING_TYPE_BOTH,
    RECORDING_TYPE_CAMERA,
    RECORDING_TYPE_MICROPHONE,
    RECORDING_TYPE_SCREEN
} from '@/js/constants';
import { Modal } from '@/js/mixins';
import { UiDropdown, UiDropdownItem } from '@/js/components';
import AudioVisualizer from '@/js/components/AudioVisualizer.vue';
import { useHistory } from '@/js/videos/composables/useHistory';
import { useVideo } from '@/js/videos/composables/useVideo';
import { useMediaConversions } from '@/js/videos/composables/useMediaConversions.js';

const RECORDER_STATUS_SETUP = 'setup';
const RECORDER_STATUS_RECORDING = 'recording';
const RECORDER_STATUS_PREVIEW = 'preview';
const RECORDER_STATUS_COMPLETING = 'completing';

export default defineComponent({
    mixins: [Modal],

    components: {
        UiDropdown,
        UiDropdownItem,
        AudioVisualizer
    },

    props: {
        sequenceId: String,

        selector: {
            type: Object,
            default: null
        },

        defaultRecordingType: {
            type: String,
            default: RECORDING_TYPE_SCREEN
        },

        excludedRecordingTypes: {
            type: Array,
            default: []
        }
    },

    setup(props, context) {
        const store = useStore();
        const { saveVideo } = useVideo(store);
        const { saveHistoryStep } = useHistory();
        const { addConversionFromId } = useMediaConversions();

        const webRTCService = new WebRTCService();
        const storeRecordedMediaService = new StoreRecordedMediaService(store);
        const setRecordingElementService = new SetRecordingElementService(store);

        const recorderMap = new Map();

        let isMounted = false;
        let videoPreviewStream = null;

        const recordingDeviceTutorialUrl = store.state.ui.urls.recordingDeviceTutorial || '#';

        const modalProps = computed(() => {
            const { sequenceId, selector, defaultRecordingType, excludedRecordingTypes, ...baseProps } = props;
            return { ...baseProps, ...context.attrs };
        });

        return {
            store,
            saveVideo,
            saveHistoryStep,
            addConversionFromId,
            webRTCService,
            storeRecordedMediaService,
            setRecordingElementService,
            recorderMap,
            isMounted,
            videoPreviewStream,
            recordingDeviceTutorialUrl,
            modalProps
        };
    },

    data() {
        return {
            recordingType: this.defaultRecordingType,
            status: RECORDER_STATUS_SETUP,

            videoDevices: [],
            audioDevices: [],
            selectedVideoDeviceId: '',
            selectedAudioDeviceId: '',

            audioPreviewStream: null,

            userDevicesDetected: false,
            videoDeviceReady: false,
            videoDeviceError: false,
            audioDeviceError: false
        };
    },

    computed: {
        ...mapGetters({
            recordingPreviewUrl: 'ui/mediaPreviewUrl'
        }),

        isSettingUp() {
            return this.status == RECORDER_STATUS_SETUP;
        },

        isRecording() {
            return this.status == RECORDER_STATUS_RECORDING;
        },

        isPreviewing() {
            return this.status == RECORDER_STATUS_PREVIEW;
        },

        isCompleting() {
            return this.status == RECORDER_STATUS_COMPLETING;
        },

        availableVideoDevices() {
            return this.videoDevices.concat(!this.videoDevices.length ? [{ label: this.$t('None'), value: '' }] : []);
        },

        availableAudioDevices() {
            return this.audioDevices.concat(
                !this.audioDevices.length || this.recordingType != RECORDING_TYPE_MICROPHONE
                    ? [{ label: this.$t('None'), value: '' }]
                    : []
            );
        },

        inputDeviceTypes() {
            switch (this.recordingType) {
                case RECORDING_TYPE_SCREEN:
                case RECORDING_TYPE_MICROPHONE:
                    return [RECORDING_INPUT_DEVICE_AUDIO];
                case RECORDING_TYPE_CAMERA:
                case RECORDING_TYPE_BOTH:
                    return [RECORDING_INPUT_DEVICE_VIDEO, RECORDING_INPUT_DEVICE_AUDIO];
                default:
                    return [];
            }
        },

        usesVideoDevice() {
            return this.inputDeviceTypes.includes(RECORDING_INPUT_DEVICE_VIDEO);
        },

        usesAudioDevice() {
            return this.inputDeviceTypes.includes(RECORDING_INPUT_DEVICE_AUDIO);
        },

        usesScreen() {
            return [RECORDING_TYPE_SCREEN, RECORDING_TYPE_BOTH].includes(this.recordingType);
        },

        canRecordScreen() {
            return !this.excludedRecordingTypes.includes(RECORDING_TYPE_SCREEN);
        },

        canRecordCamera() {
            return !this.excludedRecordingTypes.includes(RECORDING_TYPE_CAMERA) && this.videoDevices.length;
        },

        canRecordBoth() {
            return !this.excludedRecordingTypes.includes(RECORDING_TYPE_BOTH) && this.videoDevices.length;
        },

        canRecordMicrophone() {
            return !this.excludedRecordingTypes.includes(RECORDING_TYPE_MICROPHONE) && this.audioDevices.length;
        },

        canRecord() {
            return this.canRecordScreen || this.canRecordCamera || this.canRecordBoth || this.canRecordMicrophone;
        },

        shouldDisplayVideoDeviceAlert() {
            return (
                this.userDevicesDetected &&
                !this.videoDevices.length &&
                !(
                    this.excludedRecordingTypes.includes(RECORDING_TYPE_CAMERA) &&
                    this.excludedRecordingTypes.includes(RECORDING_TYPE_BOTH)
                )
            );
        },

        shouldDisplayAudioDeviceAlert() {
            return this.userDevicesDetected && !this.audioDevices.length;
        },

        shouldDisplayDeviceAlert() {
            return this.shouldDisplayVideoDeviceAlert || this.shouldDisplayAudioDeviceAlert;
        },

        shouldDisplayAudioVisualizer() {
            return (
                !this.usesVideoDevice &&
                this.usesAudioDevice &&
                (this.isRecording || (this.isSettingUp && !this.shouldDisplayAudioDeviceAlert))
            );
        },

        statusClass() {
            return 'status-' + this.status;
        },

        videoDevicePreviewClass() {
            return {
                'waiting-stream':
                    this.usesVideoDevice &&
                    this.selectedVideoDeviceId &&
                    !this.videoDeviceError &&
                    !this.videoDeviceReady,
                'empty-stream': !this.usesVideoDevice || !this.selectedVideoDeviceId,
                'has-error': this.usesVideoDevice && this.videoDeviceError
            };
        },

        completeButtonClass() {
            return {
                loading: this.isCompleting
            };
        }
    },

    watch: {
        recordingType(newValue) {
            if (
                newValue === RECORDING_TYPE_MICROPHONE &&
                !this.availableAudioDevices.find((device) => device.value === this.selectedAudioDeviceId)
            ) {
                this.selectedAudioDeviceId = this.availableAudioDevices[0].value;
            }
        },

        async usesVideoDevice() {
            this.refreshVideoPreview();
        },

        async usesAudioDevice(newValue) {
            this.refreshAudioPreview();
        },

        async selectedVideoDeviceId() {
            this.refreshVideoPreview();
        },

        async selectedAudioDeviceId() {
            this.refreshAudioPreview();
        },

        status() {
            if (!this.isRecording && !this.isCompleting) {
                this.stopRecorders();

                if (this.isSettingUp) {
                    this.refreshVideoPreview();
                    this.refreshAudioPreview();
                }
                if (this.isPreviewing) {
                    this.stopPreviewStreams();
                } else {
                    this.resetRecorders();
                }
            }
        }
    },

    methods: {
        setStatus(status) {
            this.status = status;
        },

        // TODO: Refactor WebRTC operations in a service
        async detectUserDevices(audioOnly = false) {
            this.resetUserDevices();

            const devices = await this.webRTCService.getUserInputDevices(audioOnly);

            this.videoDevices = devices[RECORDING_INPUT_DEVICE_VIDEO] || [];
            this.audioDevices = devices[RECORDING_INPUT_DEVICE_AUDIO] || [];
            this.userDevicesDetected = true;

            this.selectedVideoDeviceId =
                this.videoDevices.find((device) => device.value == 'default')?.value ||
                this.videoDevices[0]?.value ||
                '';
            this.selectedAudioDeviceId =
                this.audioDevices.find((device) => device.value == 'default')?.value ||
                this.audioDevices[0]?.value ||
                '';
        },

        resetUserDevices() {
            this.videoDevices = [];
            this.audioDevices = [];
            this.selectedVideoDeviceId = '';
            this.selectedAudioDeviceId = '';
            this.userDevicesDetected = false;
        },

        async setVideoPreviewStream() {
            this.videoDeviceError = false;

            this.webRTCService.stopStream(this.videoPreviewStream);

            this.videoPreviewStream =
                this.usesVideoDevice && this.selectedVideoDeviceId
                    ? this.stopStreamIfUnmounted(await this.webRTCService.getUserStream(this.selectedVideoDeviceId))
                    : null;

            if (!this.videoPreviewStream && this.usesVideoDevice && this.selectedVideoDeviceId)
                this.videoDeviceError = true;
        },

        async setAudioPreviewStream() {
            this.audioDeviceError = false;

            this.webRTCService.stopStream(this.audioPreviewStream);

            this.audioPreviewStream =
                this.usesAudioDevice && this.selectedAudioDeviceId
                    ? this.stopStreamIfUnmounted(await this.webRTCService.getUserStream('', this.selectedAudioDeviceId))
                    : null;

            if (!this.audioPreviewStream && this.usesAudioDevice && this.selectedAudioDeviceId)
                this.audioDeviceError = true;
        },

        stopPreviewStreams() {
            this.webRTCService.stopStream(this.videoPreviewStream);
            this.webRTCService.stopStream(this.audioPreviewStream);
        },

        stopStreamIfUnmounted(stream) {
            if (this.isMounted) return stream;

            // If the modal was unmounted before getting
            // the stream, we need to stop it here
            this.webRTCService.stopStream(stream);
            return null;
        },

        async refreshVideoPreview() {
            await this.setVideoPreviewStream();

            if (this.$refs.$videoDevicePreview) {
                if (this.$refs.$videoDevicePreview.srcObject?.id != this.videoPreviewStream?.id) {
                    this.videoDeviceReady = false;
                    this.$refs.$videoDevicePreview.onloadedmetadata = () => {
                        this.videoDeviceReady = true;
                    };
                    this.$refs.$videoDevicePreview.srcObject = this.videoPreviewStream;
                }

                if (this.videoPreviewStream) await this.$refs.$videoDevicePreview.play();
                else this.$refs.$videoDevicePreview.pause();
            }
        },

        async refreshAudioPreview() {
            await this.setAudioPreviewStream();
        },

        async startRecording() {
            if (this.usesScreen) {
                // If a video device is not used, and an audio device
                // is selected, we add the audio track to the screen stream
                const screenRecorder = await this.webRTCService.getDisplayRecorder(
                    !this.usesVideoDevice ? this.selectedAudioDeviceId : '',
                    this.handleRecordingStop
                );

                if (screenRecorder) this.storeRecorder(screenRecorder, '$screenRecordingPreview');
            }

            if (this.usesVideoDevice || (this.usesAudioDevice && !this.usesScreen)) {
                const deviceRecorder = await this.webRTCService.getUserRecorder(
                    this.usesVideoDevice ? this.selectedVideoDeviceId : '',
                    this.usesAudioDevice ? this.selectedAudioDeviceId : '',
                    this.handleRecordingStop
                );

                if (deviceRecorder)
                    this.storeRecorder(
                        deviceRecorder,
                        this.usesVideoDevice ? '$videoRecordingPreview' : '$micRecordingPreview'
                    );
            }

            // TODO: Display an error message if some recorders failed?
            if (this.recorderMap.size) {
                this.setStatus(RECORDER_STATUS_RECORDING);
                this.startRecorders();
            }
        },

        resetRecording() {
            this.setStatus(RECORDER_STATUS_SETUP);
        },

        endRecording() {
            this.setStatus(RECORDER_STATUS_PREVIEW);
        },

        cancelRecording() {
            this.close();
        },

        async storeRecording() {
            try {
                const recordings = [...this.recorderMap.values()];
                const recordingData = await this.storeRecordedMediaService.handle(
                    recordings.map(
                        (data) => new File([data.blob], data.recorder.stream?.id + '.webm', { type: 'video/webm' })
                    )
                );

                if (recordingData?.media) {
                    recordings.forEach((recording, index) => {
                        recording.mediaVariationId = recordingData.media[index].variation_id;
                        this.addConversionFromId(recordingData.media[index].variation_id);
                    });

                    return true;
                }
            } catch (error) {
                console.log(`${error.name}: ${error.message}`);
                // TODO: Display an error message
            }

            return false;
        },

        setRecordingElements() {
            const recordings = [...this.recorderMap.values()];

            if (!this.selector) {
                // Process was started from card selector
                switch (this.recordingType) {
                    case RECORDING_TYPE_SCREEN:
                        this.saveHistoryStep(() => {
                            this.setRecordingElementService.createScreenRecordingElement(
                                this.sequenceId,
                                this.recordingPreviewUrl(recordings[0].mediaVariationId),
                                recordings[0].mediaVariationId
                            );
                        });
                        break;

                    case RECORDING_TYPE_CAMERA:
                        this.saveHistoryStep(() => {
                            this.setRecordingElementService.createCameraRecordingElement(
                                this.sequenceId,
                                this.recordingPreviewUrl(recordings[0].mediaVariationId),
                                recordings[0].mediaVariationId
                            );
                        });
                        break;

                    case RECORDING_TYPE_BOTH:
                        this.saveHistoryStep(() => {
                            this.setRecordingElementService.createScreenRecordingElement(
                                this.sequenceId,
                                this.recordingPreviewUrl(recordings[0].mediaVariationId),
                                recordings[0].mediaVariationId
                            );
                            this.setRecordingElementService.createCameraRecordingElement(
                                this.sequenceId,
                                this.recordingPreviewUrl(recordings[1].mediaVariationId),
                                recordings[1].mediaVariationId
                            );
                        });
                        break;

                    case RECORDING_TYPE_MICROPHONE:
                        this.saveHistoryStep(() => {
                            this.setRecordingElementService.setMicrophoneRecordingAudio(
                                this.sequenceId,
                                this.recordingPreviewUrl(recordings[0].mediaVariationId),
                                recordings[0].mediaVariationId
                            );
                        });
                        break;
                }
            } else {
                // Process was started from a recording selector
                this.selector.select(recordings[0].mediaVariationId, recordings[0].objectUrl);

                // Process special case of 'both' type for background recording
                if (this.recordingType == RECORDING_TYPE_BOTH) {
                    this.setRecordingElementService.createCameraRecordingElement(
                        this.sequenceId,
                        recordings[1].objectUrl,
                        recordings[1].mediaVariationId
                    );
                    this.saveVideo();
                }
            }
        },

        async completeRecordingProcess() {
            this.setStatus(RECORDER_STATUS_COMPLETING);

            const complete = await this.storeRecording();

            if (complete) {
                this.setRecordingElements();
                this.close();
            } else {
                this.setStatus(RECORDER_STATUS_PREVIEW);
            }
        },

        async handleRecordingStop(blob, recorder) {
            // TODO: Check if all recorders have stopped

            switch (this.status) {
                case RECORDER_STATUS_RECORDING:
                    // Note: This case happens when clicking
                    // on browser's default stop capture dialog
                    this.setStatus(RECORDER_STATUS_PREVIEW);
                    await this.$nextTick();

                case RECORDER_STATUS_PREVIEW:
                    const recorderData = this.recorderMap.get(recorder);

                    if (recorderData) {
                        recorderData.blob = blob;
                        recorderData.objectUrl = URL.createObjectURL(blob);
                        if (this.$refs[recorderData.previewElement]) {
                            recorderData.player = this.getRecordingPreviewPlayer(
                                this.$refs[recorderData.previewElement]
                            );
                            this.$refs[recorderData.previewElement].src = recorderData.objectUrl;
                        }
                    }
                    break;
            }
        },

        storeRecorder(recorder, previewElement) {
            this.recorderMap.set(recorder, {
                recorder,
                previewElement,
                blob: null,
                objectUrl: null,
                player: null
            });
        },

        startRecorders() {
            Array.from(this.recorderMap.keys()).forEach((recorder) => recorder.start(100));
        },

        stopRecorders() {
            Array.from(this.recorderMap.keys()).forEach((recorder) => this.webRTCService.stopRecorder(recorder));
        },

        resetRecorders() {
            this.recorderMap.forEach((data) => {
                // TODO: Add a parameter to revoke object URLs
                // if (data.objectUrl) URL.revokeObjectURL(data.objectUrl);
                if (data.player) data.player.destroy();
            });
            this.recorderMap.clear();
        },

        getRecordingPreviewPlayer(element) {
            return new Plyr(element, {
                controls: ['play', 'progress', 'current-time', 'mute'],
                invertTime: false
            });
        }
    },

    created() {
        this.RECORDING_TYPE_SCREEN = RECORDING_TYPE_SCREEN;
        this.RECORDING_TYPE_CAMERA = RECORDING_TYPE_CAMERA;
        this.RECORDING_TYPE_BOTH = RECORDING_TYPE_BOTH;
        this.RECORDING_TYPE_MICROPHONE = RECORDING_TYPE_MICROPHONE;
        this.RECORDING_INPUT_DEVICE_VIDEO = RECORDING_INPUT_DEVICE_VIDEO;
        this.RECORDING_INPUT_DEVICE_AUDIO = RECORDING_INPUT_DEVICE_AUDIO;
    },

    mounted() {
        this.isMounted = true;
        this.detectUserDevices(
            // If 'camera' and 'both' types are excluded, we only detect audio devices
            this.excludedRecordingTypes.includes(RECORDING_TYPE_CAMERA) &&
                this.excludedRecordingTypes.includes(RECORDING_TYPE_BOTH)
        );
    },

    unmounted() {
        this.isMounted = false;
        this.stopPreviewStreams();
        this.resetUserDevices();
        this.stopRecorders();
        this.resetRecorders();
    }
});
</script>
