<template>
    <button
        :disabled="enable"
        id="caption-editor-auto-captions"
        class="ui-simple-button ui-simple-button-edit-mode"
        @click.prevent="startAutoCaptions"
    >
        <slot name="caption-auto-icon"></slot>
        <span>{{ $t('Automatic captions') }}</span>
    </button>
</template>

<script>
import { mapState } from 'vuex';
import UiDropdown from '../../../../../../../../components/UiDropdown.vue';
import UiDropdownItem from '../../../../../../../../components/UiDropdownItem.vue';

export default {
    name: 'UiCaptionEditorActionSubtitles',

    components: {
        UiDropdown,
        UiDropdownItem
    },

    props: {
        enable: {
            type: Boolean,
            default: true
        },
        captioning: {
            type: Boolean
        },
        currentCaptionButton: {
            type: Object
        },
        shortLanguage: {
            type: String
        }
    },

    computed: {
        ...mapState({
            language: (state) => state.ui.language,
            captionLanguages: (state) => state.ui.captionsConfig.languages
        }),
    },

    methods: {
        convertLanguageCode(code) {
            return code === 'zh-CN' ? 'cmn-Hans-CN' : code;
        },

        startAutoCaptions() {
            this.$emit('update:captioning', true);
            this.currentCaptionButton.changeCaptioning(this.captionLanguage);
        }
    },

    created() {
        this.captionLanguage = this.convertLanguageCode(this.language);
    }
};
</script>

<style scoped></style>
