<template>
    <div class="edit-client-config">
        <div class="edit-client-config-list">
            <ui-text-input v-if="isCTE" id="edit-client-label" :label="$t('Client label')" v-model="client.shortname" />
            <ui-text-input v-if="isCTE" id="edit-client-name" :label="$t('Client name')" v-model="client.name" />
            <ui-text-input
                v-if="isCTE"
                id="edit-client-license-start"
                :label="$t('License start date')"
                v-model="client.licenceStartsAt"
            />
            <ui-text-input
                v-if="isCTE"
                id="edit-client-license-end"
                :label="$t('License end date')"
                v-model="client.licenceExpiresAt"
            />
            <ui-number-input
                v-if="isCTE"
                id="edit-client-tacit-achieved"
                :label="$t('Tacit renewals achieved')"
                v-model="client.tacitRenewalAchieved"
            />
            <ui-number-input
                v-if="isCTE"
                id="edit-client-emotion-users-limit"
                :label="$t('emotionUsersLimit')"
                v-model="client.emotionUsersLimit"
            />
            <ui-number-input
                v-if="isCTE"
                id="edit-client-content-users-limit"
                :label="$t('contentUsersLimit')"
                v-model="client.contentUsersLimit"
            />
            <ui-number-input
                v-if="isCTE"
                id="edit-client-media-limit"
                :label="$t('mediaLimit')"
                v-model="client.mediaLimit"
            />
            <ui-number-input
                v-if="isCTE"
                id="edit-client-tts-total"
                :label="$t('ttsTotal')"
                v-model="client.ttsTotal"
            />
            <ui-number-input
                v-if="isCTE"
                id="edit-client-tts-limit"
                :label="$t('ttsLimit')"
                v-model="client.ttsLimit"
            />
            <ui-number-input
                v-if="isCTE"
                id="edit-client-stt-total"
                :label="$t('sttTotal')"
                v-model="client.sttTotal"
            />
            <ui-number-input
                v-if="isCTE"
                id="edit-client-stt-limit"
                :label="$t('sttLimit')"
                v-model="client.sttLimit"
            />
            <ui-number-input
                v-if="isCTE"
                id="edit-client-ai-image-credits"
                :label="$t('aiImagesCredits')"
                v-model="client.aiImagesCredits"
            />
            <ui-number-input
                v-if="isCTE"
                id="edit-client-max-message-cards"
                :label="$t('maxEditionMessageCards')"
                v-model="client.maxEditionMessageCards"
            />
            <ui-number-input
                v-if="isCTE"
                id="edit-client-max-visual-cards"
                :label="$t('maxEditionVisualCards')"
                v-model="client.maxEditionVisualCards"
            />
            <ui-switch-button
                v-if="isCTE"
                id="edit-client-test-license"
                :label="$t('TEST licence')"
                v-model="client.testLicence"
            />
            <ui-switch-button
                v-if="isCTE"
                id="edit-client-tacit-renewal"
                :label="$t('Tacit renewal')"
                v-model="client.tacitRenewal"
            />
            <ui-switch-button
                v-if="isCTE"
                id="edit-client-media-folder"
                :label="$t('mediaFolderAccessEnabled')"
                v-model="client.mediaFolderAccessEnabled"
            />
            <ui-switch-button
                v-if="isCTE"
                id="edit-client-verification"
                :label="$t('verificationEnabled')"
                v-model="client.verificationEnabled"
            />
            <ui-switch-button
                v-if="isCTE"
                id="edit-client-validation-access"
                :label="$t('validationAccessEnabled')"
                v-model="client.validationAccessEnabled"
            />
            <ui-switch-button
                v-if="isCTE"
                id="edit-client-media-usage"
                :label="$t('mediaUsageEnabled')"
                v-model="client.mediaUsageEnabled"
            />
            <ui-switch-button
                v-if="isCTE"
                id="edit-client-media-rights"
                :label="$t('mediaRightsEnabled')"
                v-model="client.mediaRightsEnabled"
            />
            <ui-switch-button
                v-if="isCTE"
                id="edit-client-activity-access"
                :label="$t('activityAccessEnabled')"
                v-model="client.activityAccessEnabled"
            />
            <ui-switch-button
                v-if="isCTE"
                id="edit-client-branding-access"
                :label="$t('brandingAccessEnabled')"
                v-model="client.brandingAccessEnabled"
            />
            <ui-switch-button
                v-if="isCTE"
                id="edit-client-custom-template-previews"
                :label="$t('customTemplatePreviewsEnabled')"
                v-model="client.customTemplatePreviewsEnabled"
            />
            <ui-switch-button
                v-if="isCTE"
                id="edit-client-template-auto-production"
                :label="$t('templateAutoProductionEnabled')"
                v-model="client.templateAutoProductionEnabled"
            />
            <ui-switch-button
                v-if="isCTE"
                id="edit-client-tags"
                :label="$t('enableTags')"
                v-model="client.enableTags"
            />
            <ui-switch-button
                v-if="isCTE"
                id="edit-client-locking-tags"
                :label="$t('enableLockingTags')"
                v-model="client.enableLockingTags"
            />
            <ui-switch-button
                v-if="isCTE"
                id="edit-client-scorm-export"
                :label="$t('enableScormExport')"
                v-model="client.enableScormExport"
            />
            <ui-switch-button
                v-if="isCTE"
                id="edit-client-recording"
                :label="$t('enableRecording')"
                v-model="client.enableRecording"
            />
            <ui-switch-button
                v-if="isCTE"
                id="edit-client-continuous-improvement"
                :label="$t('enableContinuousImprovement')"
                v-model="client.enableContinuousImprovement"
            />
            <ui-switch-button
                v-if="isCTE"
                id="edit-client-support-tool"
                :label="$t('enableSupportTool')"
                v-model="client.enableSupportTool"
            />
            <ui-switch-button
                v-if="isCTE"
                id="edit-client-notification-panel"
                :label="$t('enableNotificationPanel')"
                v-model="client.enableNotificationPanel"
            />
            <ui-switch-button
                v-if="isCTE"
                id="edit-client-external-links"
                :label="$t('enableExternalLinks')"
                v-model="client.enableExternalLinks"
            />
            <ui-switch-button
                v-if="isCTE"
                id="edit-client-quality-monitoring"
                :label="$t('enableQualityMonitoring')"
                v-model="client.enableQualityMonitoring"
            />
            <ui-switch-button
                v-if="isCTE"
                id="edit-client-right-alignment"
                :label="$t('disableEditionRightAlignment')"
                v-model="client.disableEditionRightAlignment"
            />

            <ui-switch-button
                v-if="isCTE || (isClientAdmin && client.validationAccessEnabled)"
                id="edit-client-validation-process"
                :label="$t('Enable validation process')"
                v-model="client.validationEnabled"
            />
            <ui-switch-button
                v-if="isCTE || (isClientAdmin && client.enableLockingTags)"
                id="edit-client-lock-tags"
                :label="$t('Lock Tags')"
                v-model="client.lockedTags"
            />
        </div>
    </div>
</template>

<script setup>
import UiSwitchButton from '@/js/components/UiSwitchButton.vue';
import { useAuth } from '@/js/composables/useAuth.js';
import UiTextInput from '@/js/components/UiTextInput.vue';
import UiNumberInput from '@/js/components/UiNumberInput.vue';

const { isCTE, isClientAdmin } = useAuth();

const client = defineModel();
</script>
