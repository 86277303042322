<!--
  -- Component name: ColorDisc
  -- Type: Transition
  -- Uses: color.start
  -- Tags: color
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
    >
        <defs>
            <mask :id="svgMaskID">
                <rect x="0" y="0" width="100%" height="100%" fill="#fff" :letter-spacing="svgMaskRefreshValue" />
                <circle cx="50%" cy="50%" r="0" />
            </mask>
        </defs>
        <rect
            class="transition-color-disc-rect"
            x="0"
            y="0"
            width="100%"
            height="100%"
            :fill="state.color.start"
            :mask="svgMask"
        />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {
        svgMaskID() {
            return this.containerID + '-color-disc-mask';
        },

        svgMaskRadius() {
            return Math.ceil(Math.sqrt(Math.pow(this.format.width, 2) + Math.pow(this.format.height, 2))) / 2;
        },

        svgMask() {
            return 'url(#' + this.svgMaskID + ')';
        },

        svgMaskRefreshValue() {
            return this.format && Math.random();
        }
    },

    watch: {
        svgMaskRadius: {
            handler() {
                this.updateTimeline();
            }
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let radius = this.svgMaskRadius + 25,
                t = gsap.timeline();
            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 }, 0.001);
            t.fromTo(
                '#' + this.svgMaskID + ' circle',
                { attr: { r: 0 } },
                { duration: 0.7, attr: { r: radius }, ease: 'power1.inOut' },
                0
            );
            return t;
        }
    }
};
</script>
