<template>
    <div class="ui-onboarding-step">
        <v-step
            v-if="step && (!disabled || this.stepNumber !== 2)"
            :step="step"
            :key="key"
            :previous-step="previousStep"
            :next-step="customNextStep"
            :stop="customStop"
            :skip="skip"
            :finish="finish"
            :is-first="isFirst"
            :is-last="isLast"
            :labels="labels"
            :enabled-buttons="enabledButtons"
            :highlight="highlight"
            :debug="debug"
        >
            <template #header>
                <div class="onboarding-header">
                    <fa-icon class="icon step-icon" :icon="step.icon" />
                    <h3 class="step-title">{{ step.header.title }}</h3>
                    <span class="step-number">{{ stepNumber + '/' + nbSteps }}</span>
                </div>
            </template>
            <template #content>
                <div class="onboarding-content">
                    <p class="step-content">{{ step.content }}</p>
                </div>
            </template>
            <template #actions> </template>
        </v-step>
    </div>
</template>

<script>
import UiIcon from './UiIcon.vue';
import VStep from 'vue3-tour/src/components/VStep.vue';
import { BRAND_CONTEXT } from '../constants/onboarding';
import OnboardingService from '@/js/infrastructure/api/onboarding/OnboardingService';
import LocalStorageManager from '@/js/infrastructure/local_storage/LocalStorageManager';
import { mapState } from 'vuex';

export default {
    name: 'UiOnboardingStep',

    components: {
        UiIcon,
        VStep
    },
    props: {
        stepNumber: {
            type: Number,
            required: true
        },
        step: {
            type: Object,
            required: true
        },
        key: {
            type: Number,
            required: true
        },
        previousStep: {
            type: Function,
            required: true
        },
        nextStep: {
            type: Function,
            required: true
        },
        stop: {
            type: Function,
            required: true
        },
        skip: {
            type: Function,
            default: function () {
                this.stop();
            }
        },
        finish: {
            type: Function,
            default: function () {
                this.stop();
            }
        },
        isFirst: {
            type: Boolean,
            required: true
        },
        isLast: {
            type: Boolean,
            required: true
        },
        labels: {
            type: Object,
            required: true
        },
        enabledButtons: {
            type: Object,
            required: true
        },
        highlight: {
            type: Boolean,
            required: true
        },
        debug: {
            type: Boolean,
            required: true
        },
        context: {
            type: String,
            required: true
        },
        nbSteps: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            disabled: false,
            onboardingService: new OnboardingService()
        };
    },

    computed: {
        ...mapState({
            userId: (state) => state.ui.userId
        })
    },

    methods: {
        customNextStep() {
            this.$emit('update-display', this.stepNumber);
            this.updateLastConsultedOnboardingStep();
            this.$nextTick(() => {
                // We need to wait for the DOM update to complete before moving on to the next step.
                this.nextStep();
            });
        },

        customStop() {
            this.updateOnboardingDisplayOption();
            this.stop();
        },

        updateOnboardingDisplayOption() {
            if (this.context === BRAND_CONTEXT) {
                this.onboardingService.updateOnboardingStep(this.$store.state.ui.userId, {
                    display_branding_onboarding: false
                });
                LocalStorageManager.save(this.userId + '_display_branding_onboarding', false);
            } else {
                this.onboardingService.updateOnboardingStep(this.$store.state.ui.userId, {
                    display_edition_onboarding: false
                });
                LocalStorageManager.save(this.userId + '_display_edition_onboarding', false);
            }
        },

        updateLastConsultedOnboardingStep() {
            if (this.context === BRAND_CONTEXT) {
                this.onboardingService.updateOnboardingStep(this.$store.state.ui.userId, {
                    branding_onboarding_last_step: this.stepNumber + 1
                });
                LocalStorageManager.save(this.userId + '_branding_onboarding_last_step', this.stepNumber + 1);
            } else {
                this.onboardingService.updateOnboardingStep(this.$store.state.ui.userId, {
                    edition_onboarding_last_step: this.stepNumber + 1
                });
                LocalStorageManager.save(this.userId + '_edition_onboarding_last_step', this.stepNumber + 1);
            }
        },

        onboardingAnimation() {
            const onboardingStepDiv = document.querySelector('.ui-onboarding-step');
            const vStepDiv = document.querySelector('.v-step');
            vStepDiv.classList.add('onboarding-animation');
            this.$nextTick(() => {
                // Apply the css properties of the v-step component to the overlay
                onboardingStepDiv.style.position = vStepDiv.style.position;
                onboardingStepDiv.style.transform = vStepDiv.style.transform;
                onboardingStepDiv.style.zIndex = '1000';
                onboardingStepDiv.style.inset = vStepDiv.style.inset;

                // Remove the css properties of the v-step component to make translation animation
                vStepDiv.style.transform = 'none';
                vStepDiv.style.position = 'relative';
                this.translationAnimation();
            });
        },

        translationAnimation() {
            const vStepDiv = document.querySelector('.v-step');
            switch (vStepDiv.getAttribute('data-popper-placement')) {
                case 'top':
                    vStepDiv.style.animation = 'translationBottom 1s';
                    break;
                case 'bottom':
                    vStepDiv.style.animation = 'translationTop 1s';
                    break;
                case 'right':
                    vStepDiv.style.animation = 'translationLeft 1s';
                    break;
                case 'left':
                    vStepDiv.style.animation = 'translationRight 1s';
                    break;
            }
        },

        initializeVisibilityOfSecondOnboardingBubble() {
            const container = document.querySelector(
                '.ui-sequence-list.ui-sequence-list-items > .ui-scrollable-container'
            );
            container.addEventListener('scroll', () => {
                this.disabled = container.scrollHeight - container.scrollTop - 5 > container.clientHeight;
            });
        }
    },

    mounted() {
        this.onboardingAnimation();
        if (this.stepNumber === 2) {
            this.initializeVisibilityOfSecondOnboardingBubble();

            const container = document.querySelector(
                '.ui-sequence-list.ui-sequence-list-items > .ui-scrollable-container'
            );
            container.scrollTop = container.scrollHeight;
        }
    }
};
</script>
