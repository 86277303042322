<!--
  -- Component name: ColorRTL2
  -- Type: Transition
  -- Uses: color.start
  -- Tags: color
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <path class="transition-color-rtl-2" :fill="state.color.start" d="M-206 0h1561.2L1479 720H-81.3z" />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            isAbove: true
        };
    },

    computed: {},

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            t.set([this.backgroundInElement, this.sequenceInElement], { autoAlpha: 0 }, 0.0002);
            t.fromTo(
                '#' + this.containerID + ' .transition-color-rtl-2',
                { xPercent: 100 },
                { duration: 0.5, xPercent: -100 }
            );
            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 }, 0.25);
            t.set([this.backgroundInElement, this.sequenceInElement], { autoAlpha: 1 }, 0.25);
            t.fromTo(
                '#' + this.containerID + ' .transition-color-rtl-2',
                { xPercent: -100 },
                { duration: 0.2, xPercent: 0, opacity: 0 }
            );
            return t;
        }
    }
};
</script>
