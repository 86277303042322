<template>
    <div class="nav-item" @click="showBeamer">
        <div class="nav-icon-container nav-icon-container-beamer" id="beamer-notif-icon">
            <fa-icon class="icon beamer-button" icon="fa-regular fa-newspaper" />
        </div>
        <div class="nav-item-route-name">
            <div class="nav-item-name-label">{{ $t("What's new ?") }}</div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue';
import { useAuth } from '@/js/composables/useAuth.js';

const BEAMER_SCRIPT_ID = 'beamer-script';
const BEAMER_SCRIPT_URL = 'https://app.getbeamer.com/js/beamer-embed.js';
const BEAMER_SCRIPT_TYPE = 'text/javascript';

const { user } = useAuth();

const showBeamer = () => {
    window.Beamer?.show();
};

const destroyBeamer = () => {
    window.Beamer?.destroy();
};

const updateBeamerConfig = () => {
    const lang = user.value.language.substring(0, 2);
    window.beamer_config.language = lang;
    console.log('Beamer language : ' + lang);
};

const importBeamer = () => {
    const beamerScript = document.createElement('script');
    beamerScript.id = BEAMER_SCRIPT_ID;
    beamerScript.src = BEAMER_SCRIPT_URL;
    beamerScript.type = BEAMER_SCRIPT_TYPE;
    document.body.appendChild(beamerScript);
};

onMounted(() => {
    updateBeamerConfig();

    if (!document.getElementById(BEAMER_SCRIPT_ID)) {
        importBeamer();
    }
});

onUnmounted(() => {
    destroyBeamer();
});
</script>
