export default class RoleDTO {
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.canAddClients = data.can_add_clients;
        this.canEditClients = data.can_edit_clients;
        this.canEditClient = data.can_edit_client;
        this.canAddUsers = data.can_add_users;
        this.canEditUsers = data.can_edit_users;
        this.canEditUser = data.can_edit_user;
        this.canAddProjects = data.can_add_projects;
        this.canEditProjects = data.can_edit_projects;
        this.canArchiveProjects = data.can_archive_projects;
        this.canHideProjects = data.can_hide_projects;
        this.canEditTemplateContainers = data.can_edit_template_containers;
        this.canViewTemplateContainers = data.can_view_template_containers;
        this.canTransferToTemplateContainers = data.can_transfer_to_template_containers;
        this.canSelectFromTemplateContainers = data.can_select_from_template_containers;
        this.canEditSchemas = data.can_edit_schemas;
        this.canViewSchemas = data.can_view_schemas;
        this.canAddDatas = data.can_add_datas;
        this.canEditDatas = data.can_edit_datas;
        this.canViewDatas = data.can_view_datas;
        this.canValidateDatas = data.can_validate_datas;
        this.canPublishDatas = data.can_publish_datas;
        this.canEditDisplayMode = data.can_edit_display_mode;
        this.canEditAnalytics = data.can_edit_analytics;
        this.canViewAnalytics = data.can_view_analytics;
        this.canEditPublishProfiles = data.can_edit_publish_profiles;
        this.canAddPartners = data.can_add_partners;
        this.canEditPartners = data.can_edit_partners;
        this.canEditPartner = data.can_edit_partner;
        this.canAddMedias = data.can_add_medias;
        this.canEditMedias = data.can_edit_medias;
        this.canViewMedias = data.can_view_medias;
        this.canViewActivity = data.can_view_activity;
    }
}
