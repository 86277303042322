<template>
    <div class="play-button-container">
        <button class="play-button" @click="togglePlay" :aria-label="buttonLabel">
            <svg-icon :icon="buttonIcon"></svg-icon>
        </button>
    </div>
</template>

<script setup>
import { computed, watch, inject } from 'vue';
import { usePreview } from '@video-composables/usePreview';
import { useDisplay } from '@video-composables/useDisplay';
import { useTimeline } from '@video-composables/useTimeline';

const videoStudio = inject('$videoStudio');

const props = defineProps({
    currentTime: {
        type: Number,
        required: true
    },
    videoDuration: {
        type: Number,
        required: true
    },
    startTime: {
        type: Number,
        default: 0.001
    }
});

watch(
    () => props.currentTime,
    () => {
        if (props.currentTime >= props.videoDuration) {
            videoStudio.value.studio.$stage.seekSequenceTimeline(currentTimelineId.value, props.startTime);
            setEndSequencePause(false);
            toggleTimeline();
        }
    }
);

const { currentTimelineId, isMainTimeline } = usePreview();
const { playing, labels } = useDisplay();
const { currentTimelineEnd, isEndSequencePause, setEndSequencePause, toggleTimeline } = useTimeline(
    currentTimelineId,
    labels
);

const buttonIcon = computed(() => (playing.value ? 'pause-icon' : 'play-icon'));

const buttonLabel = computed(() => (playing.value ? 'Pause' : 'Play'));

function togglePlay() {
    if (!playing.value && !isMainTimeline.value && isEndSequencePause()) {
        videoStudio.value.studio.$stage.seekSequenceTimeline(currentTimelineId.value, props.startTime);
    }
    setEndSequencePause(false);
    toggleTimeline();
}
</script>

<style scoped>
.play-button-container {
    padding-top: 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.play-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.icon {
    width: 24px;
    height: 24px;
    filter: brightness(0) invert(1);
}
</style>
