<template>
    <div class="ui-recording-selector" :class="containerClasses" @[stopAudioPreviewEvent]="stopAudioPreview">
        <button :id="selectorId" class="selector" :class="selectorClasses" :disabled="disabled" @click="handleOpen">
            <div class="selector-preview" :style="recordingPreviewStyles">
                <svg-icon v-if="!isEmpty && isAudio" icon="audio-icon" />
            </div>
            <span class="visually-hidden">{{ label || $t('Make a new recording') }}</span>
        </button>
        <div v-if="isEmpty" class="selector-empty">
            <svg class="border">
                <rect x="0" y="0" width="100%" height="100%" rx="10" fill="none" />
            </svg>
            <fa-icon class="icon" :icon="icon" />
        </div>
        <div v-else class="selector-actions">
            <button
                v-if="isAudio"
                class="action-preview"
                :class="previewActionClasses"
                :title="$t('Preview')"
                @click="toggleAudioPreview"
            >
                <span class="visually-hidden">{{ $t('Preview') }}</span>
                <svg-icon icon="play-icon" class="play" />
                <svg-icon icon="pause-icon" class="pause" />
            </button>
            <button class="action-change" :title="$t('Make a new recording')" @click="handleOpen">
                <span class="visually-hidden">{{ $t('Make a new recording') }}</span>
                <fa-icon class="icon" icon="fa-solid fa-rotate" />
            </button>
            <button class="action-remove" :title="$t('Remove recording')" @click="handleRemove">
                <span class="visually-hidden">{{ $t('Remove recording') }}</span>
                <svg-icon icon="close-icon" />
            </button>
        </div>
        <label :class="labelClasses" :for="selectorId" :aria-hidden="!showLabel || null">{{
            label || $t('Make a new recording')
        }}</label>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import {
    RECORDING_TYPE_SCREEN,
    RECORDING_TYPE_CAMERA,
    RECORDING_TYPE_BOTH,
    RECORDING_TYPE_MICROPHONE
} from '@/js/constants';
import loader from '@/js/video-studio/loader.js';
import { useRecording } from '@/js/videos/composables/useRecording';

export const UI_RECORDING_SELECTOR_CHANGE = 'update:recording';

export default defineComponent({
    emits: [UI_RECORDING_SELECTOR_CHANGE],

    inject: ['$videoStudio'],

    props: {
        id: {
            type: String
        },
        seqId: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: RECORDING_TYPE_SCREEN
        },
        excludedTypes: {
            type: Array,
            default: null
        },
        recording: {
            type: Object,
            default: () => ({
                src: '',
                id: ''
            })
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        triggerOpening: {
            type: Boolean,
            default: false
        }
    },

    setup() {
        const { openRecordDeviceModalForType } = useRecording();

        return {
            openRecordDeviceModalForType
        };
    },

    data() {
        return {
            currentValue: this.recording.id || '',
            temporarySrc: this.recording.src || '',
            audioPreviewIsPlaying: false,
            audioPreview: null
        };
    },

    computed: {
        ...mapGetters({
            recordingThumbnailUrl: 'ui/mediaThumbnailUrl',
            recordingPreviewUrl: 'ui/mediaPreviewUrl'
        }),

        isEmpty() {
            return this.currentValue === '';
        },

        isVideo() {
            return [RECORDING_TYPE_SCREEN, RECORDING_TYPE_CAMERA].includes(this.type);
        },
        isAudio() {
            return this.type === RECORDING_TYPE_MICROPHONE;
        },

        containerClasses() {
            return {
                audio: this.isAudio,
                disabled: this.disabled
            };
        },

        selectorId() {
            return this.id + '-selector';
        },

        selectorClasses() {
            return {
                empty: this.isEmpty
            };
        },

        icon() {
            switch (this.type) {
                case RECORDING_TYPE_SCREEN:
                    return 'fa-regular fa-desktop';
                case RECORDING_TYPE_CAMERA:
                    return 'fa-regular fa-camera';
                case RECORDING_TYPE_MICROPHONE:
                    return 'fa-regular fa-microphone';
                default:
                    return '';
            }
        },

        recordingPreviewSrc() {
            return !!this.currentValue ? this.temporarySrc || this.recordingPreviewUrl(this.currentValue) : '';
        },

        thumbnailSrc() {
            return !!this.currentValue ? this.recordingThumbnailUrl(this.currentValue) : '';
        },

        blobThumbnailSrc() {
            return !!this.thumbnailSrc ? this.$store.getters['loading/getBlob'](this.thumbnailSrc) || '' : '';
        },

        backgroundImage() {
            return this.$videoStudio ? this.blobThumbnailSrc : this.thumbnailSrc;
        },

        recordingPreviewStyles() {
            if (this.isEmpty) return {};

            if (!this.isAudio) {
                return {
                    backgroundImage: "url('" + this.backgroundImage + "')"
                };
            }
        },

        previewActionClasses() {
            return {
                playing: !this.isEmpty && this.isAudio && this.audioPreviewIsPlaying
            };
        },

        stopAudioPreviewEvent() {
            return !this.isEmpty && this.isAudio ? 'mouseleave' : null;
        },

        labelClasses() {
            return {
                'visually-hidden': !this.showLabel
            };
        }
    },

    watch: {
        recording(newValue) {
            this.currentValue = newValue?.id || '';
            this.temporarySrc =
                newValue?.src && newValue?.src != this.recordingPreviewUrl(this.currentValue) ? newValue?.src : '';
        },

        recordingPreviewSrc(newValue) {
            this.destroyAudioPreview();
            this.initAudioPreview();
        },

        thumbnailSrc(newValue, oldValue) {
            this.updateThumbnail(newValue, oldValue);
        }
    },

    methods: {
        handleOpen() {
            this.openRecordDeviceModalForType({
                type: this.type,
                sequenceId: this.seqId,
                selector: this,
                excludedTypes: this.excludedTypes
            });
        },

        handleRemove(event) {
            this.remove();
        },

        select(id, src = '') {
            this.currentValue = id;
            this.temporarySrc = src;
            this.emitChangeEvent();
        },

        remove() {
            this.currentValue = '';
            this.temporarySrc = '';
            this.emitChangeEvent();
        },

        initAudioPreview() {
            if (this.isAudio && !!this.recordingPreviewSrc) {
                this.audioPreview = new Audio(this.recordingPreviewSrc);
                this.audioPreview.addEventListener('ended', this.stopAudioPreview);
            }
        },

        destroyAudioPreview() {
            if (!!this.audioPreview) {
                this.audioPreview.removeEventListener('ended', this.stopAudioPreview);
                this.audioPreview.pause();
                this.audioPreview = null;
            }
        },

        toggleAudioPreview() {
            if (!this.isEmpty && this.isAudio) {
                this.audioPreviewIsPlaying = !this.audioPreviewIsPlaying;
                if (!!this.audioPreview) {
                    if (this.audioPreviewIsPlaying) this.audioPreview.play();
                    else this.audioPreview.pause();
                }
            }
        },

        stopAudioPreview(event) {
            this.audioPreviewIsPlaying = false;
            if (!!this.audioPreview) {
                this.audioPreview.pause();
                this.audioPreview.currentTime = 0;
            }
        },

        emitChangeEvent() {
            this.$emit(UI_RECORDING_SELECTOR_CHANGE, {
                id: this.currentValue,
                src: !!this.currentValue ? this.recordingPreviewUrl(this.currentValue) : ''
            });
        },

        updateThumbnail(newValue, oldValue) {
            if (this.$videoStudio && !this.isAudio) {
                if (newValue) loader.load(newValue, () => {});
                if (oldValue) loader.unload(oldValue, () => {});
            }
        }
    },

    mounted() {
        this.initAudioPreview();
        this.updateThumbnail(this.thumbnailSrc);
        if (this.triggerOpening) this.handleOpen();
    },

    beforeUnmount() {
        this.destroyAudioPreview();
    }
});
</script>
