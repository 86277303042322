/**
 * Video Studio
 * Edition constants
 */
import align from '../constants/align';

const ARROW_KEYS_STRENGTH = 0.005;
const Edition = {
    CORNERS: ['top-left', 'top-right', 'bottom-right', 'bottom-left'],
    BORDERS: ['top', 'right', 'bottom', 'left'],
    EDITABLE_CONTAINER_CLASS: 'studio-editable-container',
    EDITING_PREVIEW_CLASS: 'studio-editing-preview',
    MAXIMUM_REFRESH_TIME: 34, // ms

    MINIMAL_SIZE: 0.006, // need bigger than gridStrength
    HOMOTHETIC_MINIMAL_SIZE: 0.02,
    GRID_STRENGTH: 0.006,
    ARROW_KEYS_ACTIONS: {
        ArrowLeft: { x: -ARROW_KEYS_STRENGTH },
        ArrowUp: { y: -ARROW_KEYS_STRENGTH },
        ArrowRight: { x: ARROW_KEYS_STRENGTH },
        ArrowDown: { y: ARROW_KEYS_STRENGTH }
    },

    MOVING_ATTRIBUTES_ASSOCIATION: { A: 'B', B: 'A', C: 'D', D: 'C' },
    RESIZING_ATTRIBUTES_ASSOCIATION: { A: 'C', B: 'D', C: 'A', D: 'B' },

    GRIDS: [
        {
            observeAttribute: 'A',
            targetAttribute: 'A',
            targetValue: 0,
            field: 'v-left',
            alignment: 'vertical',
            resizing: true,
            finalPositionH: align.LEFT
        },
        {
            observeAttribute: 'A',
            targetAttribute: 'A',
            targetValue: 0.5,
            field: 'v-center',
            alignment: 'vertical',
            resizing: true
        },
        {
            observeAttribute: 'B',
            targetAttribute: 'B',
            targetValue: 0.5,
            field: 'v-center',
            alignment: 'vertical',
            resizing: true
        },
        {
            observeAttribute: 'B',
            targetAttribute: 'B',
            targetValue: 1,
            field: 'v-right',
            alignment: 'vertical',
            resizing: true,
            finalPositionH: align.RIGHT
        },
        {
            observeAttribute: 'verticalCenter',
            targetAttribute: 'A',
            targetValue: 0.5,
            field: 'v-center',
            alignment: 'vertical',
            resizing: false,
            finalPositionH: align.CENTER
        },
        {
            observeAttribute: 'C',
            targetAttribute: 'C',
            targetValue: 0,
            field: 'h-top',
            alignment: 'horizontal',
            resizing: true,
            finalPositionV: align.TOP
        },
        {
            observeAttribute: 'C',
            targetAttribute: 'C',
            targetValue: 0.5,
            field: 'h-center',
            alignment: 'horizontal',
            resizing: true
        },
        {
            observeAttribute: 'D',
            targetAttribute: 'D',
            targetValue: 0.5,
            field: 'h-center',
            alignment: 'horizontal',
            resizing: true
        },
        {
            observeAttribute: 'D',
            targetAttribute: 'D',
            targetValue: 1,
            field: 'h-bottom',
            alignment: 'horizontal',
            resizing: true,
            finalPositionV: align.BOTTOM
        },
        {
            observeAttribute: 'horizontalCenter',
            targetAttribute: 'C',
            targetValue: 0.5,
            field: 'h-center',
            alignment: 'horizontal',
            resizing: false,
            finalPositionV: align.CENTER
        }
    ],

    CROPPING_RATIOS: [
        { ratio: null, icon: ['far', 'expand'], tooltipKey: 'editing-frame.action.free-format' },
        { ratio: 16 / 9, icon: ['far', 'rectangle'], tooltipKey: 'editing-frame.action.landscape-format' }, // landscape
        { ratio: 9 / 16, icon: ['far', 'rectangle-vertical'], tooltipKey: 'editing-frame.action.portrait-format' }, // portrait
        { ratio: 1, icon: ['far', 'square'], tooltipKey: 'editing-frame.action.square-format' } // square
    ],
    CROPPING_ASSET_SIZE: 0.8, // 80%

    ROTATION_SNAP_THRESHOLD: 1, // 1 degree
    ROTATION_SNAP_ANGLES: [0, 90, 180, -90, -180],

    // available actions (enum style)
    ACTIONS: {
        CROPPING: 0,
        ROTATING: 1
    }
};

export default Edition;
