import { computed, ref } from 'vue';

export default function usePagination() {
    const page = ref(1);
    const perPage = ref(10);
    const totalPages = ref(0);
    const isLoading = ref(false);

    const setPage = (newPage) => {
        if (newPage > 0 && newPage <= totalPages.value) {
            page.value = newPage;
        }
    };

    const setPerPage = (newPerPage) => {
        if (newPerPage > 0) {
            perPage.value = newPerPage;
        }
    };

    const setTotalPages = (newTotalPages) => {
        if (newTotalPages >= 0) {
            totalPages.value = newTotalPages;
        }
    };

    const paginatedData = computed(() => {
        const start = (page.value - 1) * perPage.value;
        const end = start + perPage.value;
        return { start, end };
    });

    const paginationRequest = async (request) => {
        if (isLoading.value) return;

        isLoading.value = true;

        let paginatedResult = await request();

        if (paginatedResult.data.length > 0) {
            setTotalPages(paginatedResult.meta.lastPage ?? paginatedResult.lastPage);
        }

        isLoading.value = false;

        return paginatedResult;
    };

    const resetData = () => {
        page.value = 1;
        totalPages.value = 0;
    };

    return {
        page,
        perPage,
        totalPages,
        paginatedData,
        isLoading,
        paginationRequest,
        setPage,
        setPerPage,
        setTotalPages,
        resetData
    };
}
