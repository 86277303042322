<template>
    <div
        v-if="!error"
        class="spritesheet-container"
        ref="$spritesheetContainer"
        :class="containerClasses"
        :style="containerStyles"
    >
        <img :src="blobSrc || null" :ref="spritesheetReference" class="spritesheet" />
        <canvas
            :ref="spriteReference"
            :width="frame.width"
            :height="frame.height"
            class="sprite"
            :style="spriteComputedStyles"
        ></canvas>
    </div>
</template>

<script>
import { Dimension, Timeline } from '../../constants';
import { fastdom, conversions, randomID } from '../../utils';
import gsap from 'gsap';
import { mapState } from 'vuex';
import loader from '@/js/video-studio/loader.js';

export default {
    props: {
        src: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        classes: {
            type: String,
            default: ''
        },
        grid: {
            type: String,
            default: '1x1'
        },
        frames: {
            type: Number,
            default: 1
        },
        fps: {
            type: Number,
            default: 30
        },
        loop: {
            type: Number,
            default: 0
        },
        width: {
            type: String,
            default: Dimension.AUTO
        },
        height: {
            type: String,
            default: Dimension.AUTO
        },
        position: {
            type: Object,
            default: () => ({})
        },
        emSize: {
            type: Number,
            default: -1
        },
        cover: {
            type: Boolean,
            default: false
        },
        color: {
            type: Object,
            default: () => ({})
        },
        styles: {
            type: Object,
            default: () => ({})
        },
        spriteStyles: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        return {
            error: false,
            size: {
                width: 0,
                height: 0
            },
            currentFrame: -1,
            frame: {
                width: 0,
                height: 0
            }
        };
    },

    computed: {
        ...mapState({
            format: (state) => state.display.format,
            avoidTimelineReflow: (state) => state.display.avoidTimelineReflow
        }),

        containerClasses() {
            return [
                this.classes,
                {
                    'cover-mode': this.cover
                }
            ];
        },

        containerStyles() {
            return Object.assign(
                {},
                this.styles,
                {
                    width: this.size.width || this.width,
                    height: this.size.height || this.height
                },
                conversions.alignment(
                    this.position.alignH,
                    this.position.alignV,
                    this.position.alignX,
                    this.position.alignY
                )
            );
        },

        blobSrc() {
            return this.$store.getters['loading/getBlob'](this.src);
        },

        hasError() {
            return this.$store.getters['loading/hasError'](this.src);
        },

        spritesheetReference() {
            return this.name + '-spritesheet';
        },

        spritesheetColumns() {
            return parseInt(this.grid.split('x')[0]) || 1;
        },
        spritesheetRows() {
            return parseInt(this.grid.split('x')[1]) || 1;
        },

        spriteReference() {
            return this.name + '-sprite';
        },

        spriteComputedStyles() {
            return Object.assign({}, this.spriteStyles, {
                width: this.width == Dimension.AUTO ? Dimension.AUTO : null
            });
        }
    },

    watch: {
        format(newValue, oldValue) {
            if (!this.cover && (newValue.width != oldValue.width || newValue.height != oldValue.height))
                this.updateSize();
        },

        src(newValue, oldValue) {
            this.updateSrc(newValue, oldValue);
        },

        width() {
            if (!this.cover) this.updateSize();
        },

        height() {
            if (!this.cover) this.updateSize();
        },

        currentFrame(newValue, oldValue) {
            if (this.blobSrc && !this.error) this.drawFrame();
        }
    },

    methods: {
        start() {
            this._timeline.restart();
        },

        play() {
            this._timeline.play();
        },

        pause() {
            this._timeline.pause();
        },

        timeline() {
            return this._timeline;
        },

        drawFrame() {
            let x = this.currentFrame % this.spritesheetColumns,
                y = Math.floor(this.currentFrame / this.spritesheetColumns);

            this._spriteCanvas.clearRect(0, 0, this.frame.width, this.frame.height);

            if (this.color.start) {
                this._spriteCanvas.fillStyle = this.color.start;
                this._spriteCanvas.fillRect(0, 0, this.frame.width, this.frame.height);
            }

            this._spriteCanvas.drawImage(
                this.$refs[this.spritesheetReference],
                x * this.frame.width,
                y * this.frame.height,
                this.frame.width,
                this.frame.height,
                0,
                0,
                this.frame.width,
                this.frame.height
            );
        },

        updateSrc(newValue, oldValue) {
            this.size = Object.assign({}, this.size, { width: 0, height: 0 });
            this.frame = Object.assign({}, this.frame, { width: 0, height: 0 });
            this.currentFrame = -1;
            if (oldValue) loader.unload(oldValue, this.onSpritesheetError);
            if (newValue) {
                this.$store.commit('loading/prepare', this);
                if (!!this.$refs[this.spritesheetReference]) {
                    this.setSpritesheetListeners(this.onSpritesheetLoaded, this.onSpritesheetError);
                    loader.load(newValue, this.onSpritesheetError);
                } else {
                    this.$nextTick(() => {
                        this.setSpritesheetListeners(this.onSpritesheetLoaded, this.onSpritesheetError);
                        loader.load(newValue, this.onSpritesheetError);
                    });
                }
            } else {
                this.removeSpritesheetListeners();
                this.$store.commit('loading/prepared', this);
            }
            this.error = !newValue;
        },

        updateTimeline() {
            if (this.avoidTimelineReflow) return;

            this._timeline.seek(0);
            this._timeline.clear();
            this._timeline.kill();
            this._timeline = gsap.timeline({ id: Timeline.SPRITESHEET_TIMELINE_ID });
            if (this.blobSrc && !this.error) {
                this._timeline.fromTo(
                    this,
                    { currentFrame: 0 },
                    {
                        duration: this.frames / this.fps,
                        repeat: this.loop,
                        currentFrame: this.frames - 1,
                        ease: 'steps(' + (this.frames - 1) + ')'
                    }
                );
            }
        },

        updateSize(callback) {
            if (!!this.$refs[this.spritesheetReference]) {
                let spritesheet = this.$refs[this.spritesheetReference];
                this.size = Object.assign({}, this.size, { width: 0, height: 0 });

                this.$nextTick(async () => {
                    let s = { width: 0, height: 0 };

                    await fastdom.measure(() => {
                        // Note: The component may have been unmounted between
                        // registering this call, and executing this call
                        if (this._destroyed) return;

                        let r = this.frame.width / this.frame.height,
                            cw = this.$refs.$spritesheetContainer.clientWidth,
                            ch = this.$refs.$spritesheetContainer.clientHeight,
                            rc = cw / ch;

                        if (rc > r) {
                            s.width = Math.round(r * ch);
                            if (this.height == Dimension.AUTO) s.height = ch;
                        } else {
                            s.height = Math.round(cw / r);
                            if (this.width == Dimension.AUTO) s.width = cw;
                        }
                        if (this.emSize == -1) {
                            s.width = s.width ? s.width + Dimension.PIXEL_UNIT : s.width;
                            s.height = s.height ? s.height + Dimension.PIXEL_UNIT : s.height;
                        } else {
                            s.width = s.width ? s.width / this.emSize + Dimension.EM_UNIT : s.width;
                            s.height = s.height ? s.height / this.emSize + Dimension.EM_UNIT : s.height;
                        }
                    });

                    this.size = s;
                    if (callback) this.$nextTick(callback);
                });
            }
        },

        setSpritesheetListeners(onLoad, onError) {
            this.$refs[this.spritesheetReference].onload = onLoad;
            this.$refs[this.spritesheetReference].onerror = onError;
        },

        removeSpritesheetListeners() {
            if (!!this.$refs[this.spritesheetReference]) {
                this.setSpritesheetListeners(null, null);
            }
        },

        onSpritesheetLoaded() {
            this.removeSpritesheetListeners();
            this.frame = Object.assign({}, this.frame, {
                width: this.$refs[this.spritesheetReference].naturalWidth / this.spritesheetColumns,
                height: this.$refs[this.spritesheetReference].naturalHeight / this.spritesheetRows
            });

            let nextCall = this.cover ? this.$nextTick : this.updateSize;
            nextCall(() => {
                this._spriteCanvas.globalCompositeOperation = 'destination-atop';
                this.updateTimeline();
                this.$emit('load-success');
                if (!this.avoidTimelineReflow) this.$emit('update', { el: this.$refs.$spritesheetContainer });
                this.$store.commit('loading/prepared', this);
            });
        },

        onSpritesheetError() {
            if (this.blobSrc || this.hasError) {
                this.removeSpritesheetListeners();
                this.error = true;
                this.$store.commit('loading/prepared', this);
                this.$emit('load-error');
            }
        }
    },

    created() {
        this._timeline = gsap.timeline({ id: Timeline.SPRITESHEET_TIMELINE_ID });
    },

    mounted() {
        this._destroyed = false;
        this._spriteCanvas = this.$refs[this.spriteReference].getContext('2d');
        this._spriteCanvas.globalCompositeOperation = 'destination-atop';
        this.updateSrc(this.src);
    },

    beforeUnmount() {
        this.updateSrc('', this.src);
        this._spriteCanvas = null;
        this._timeline.kill();
        this._destroyed = true;
    }
};
</script>
