<template>
    <Teleport to=".preview-layout">
        <div
            v-if="Object.values(availableActions).some((action) => !!action)"
            class="studio-editing-frame-actions"
            :style="{ top: actionMenuTop }"
        >
            <div
                v-if="availableActions.crop && !cropping"
                class="studio-editing-frame-action"
                v-tooltip.light="$t('editing-frame.action.crop')"
                @click="$emit('init-crop')"
            >
                <fa-icon icon="fa-regular fa-crop-simple" />
            </div>
            <div
                v-if="availableActions.quickCut && !cropping"
                class="studio-editing-frame-action"
                v-tooltip.light="$t('editing-frame.action.quick-cut')"
                @click="$emit('open-quickcut')"
            >
                <fa-icon icon="fa-regular fa-scissors" />
            </div>
            <div
                v-if="availableActions.flip && !cropping"
                :class="['studio-editing-frame-action', { active: flipH }]"
                v-tooltip.light="$t('editing-frame.action.horizontal-flip')"
                @click="flipHorizontally"
            >
                <fa-icon icon="fa-regular fa-reflect-horizontal" />
            </div>
            <div
                v-if="availableActions.flip && !cropping"
                :class="['studio-editing-frame-action', { active: flipV }]"
                v-tooltip.light="$t('editing-frame.action.vertical-flip')"
                @click="flipVertically"
            >
                <fa-icon icon="fa-regular fa-reflect-vertical" />
            </div>

            <div
                v-if="cropping"
                v-for="aspectRatio in aspectRatios"
                :class="['studio-editing-frame-action', { active: selectedRatio === aspectRatio.ratio }]"
                v-tooltip.light="$t(aspectRatio.tooltipKey)"
                @click="changeAspectRatio(aspectRatio.ratio)"
            >
                <fa-icon :icon="aspectRatio.icon" />
            </div>

            <div v-if="cropping" class="studio-editing-frame-divider"></div>

            <div v-if="cropping" class="studio-editing-frame-action" v-tooltip.light="$t('Cancel')" @click="cancelCrop">
                <fa-icon icon="fa-xmark" />
            </div>
            <div
                v-if="cropping"
                class="studio-editing-frame-action"
                v-tooltip.light="$t('confirm')"
                @click="$emit('save-crop')"
            >
                <fa-icon icon="fa-check" />
            </div>
        </div>
    </Teleport>
</template>

<script>
import { computed, defineComponent, inject, ref } from 'vue';
import { Dimension, Edition } from '../constants';
import { useHistory } from '@/js/videos/composables/useHistory';
import UsesTooltip from '@/js/mixins/UsesTooltip.js';

export default defineComponent({
    name: 'EditingFrameActions',
    compilerOptions: {
        whitespace: 'preserve'
    },

    emits: ['init-crop', 'aspect-ratio', 'save-crop', 'cancel-crop', 'open-quickcut'],

    props: {
        cropping: Boolean,
        editingElement: Object
    },

    mixins: [UsesTooltip],

    data() {
        return {
            aspectRatios: Edition.CROPPING_RATIOS
        };
    },

    setup(props, { emit }) {
        const { saveHistoryStep } = useHistory();
        const videoStudio = inject('$videoStudio');
        const stage = inject('$stage');
        const actionMenuTop = ref('0px');

        // calculate position of actions menu relative to edit-preview container
        const stageResizeObserver = new ResizeObserver(([entry]) => {
            const wrapperRect = entry.target.getBoundingClientRect();
            const stageRect = stage.$el.getBoundingClientRect();

            // manually add 10px
            actionMenuTop.value = stageRect.top - wrapperRect.top + 10 + Dimension.PIXEL_UNIT;
        });
        stageResizeObserver.observe(videoStudio.value.$el.closest('.preview-layout'));

        // determine which actions are available
        const availableActions = computed(() => ({
            crop: !!props.editingElement.visualSelector && (props.editingElement.image || props.editingElement.video),
            quickCut: !!props.editingElement.visualSelector && props.editingElement.video,
            flip:
                (!!props.editingElement.visualSelector && (props.editingElement.image || props.editingElement.video)) ||
                !!props.editingElement.$refs.$lottieContainer
        }));

        // functions to flip asset on X and Y axis
        const flipHorizontally = () =>
            saveHistoryStep(() => props.editingElement.setHorizontalFlip(!props.editingElement.state.flip.horizontal));
        const flipVertically = () =>
            saveHistoryStep(() => props.editingElement.setVerticalFlip(!props.editingElement.state.flip.vertical));

        // force aspect ratio of EditingFrame
        const selectedRatio = ref(null);
        const changeAspectRatio = (ratio) => {
            if (ratio !== selectedRatio.value) {
                selectedRatio.value = ratio;
                emit('aspect-ratio', ratio);
            }
        };

        // cancel crop state - reset selected aspect ratio
        const cancelCrop = () => {
            emit('cancel-crop');
            selectedRatio.value = null;
        };

        return {
            availableActions,
            actionMenuTop,

            flipHorizontally,
            flipVertically,

            selectedRatio,
            changeAspectRatio,

            cancelCrop,

            flipH: computed(() => props.editingElement.state.flip.horizontal),
            flipV: computed(() => props.editingElement.state.flip.vertical)
        };
    }
});
</script>
