// Utilities

import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { CATEGORIES_INCLUDING_BRAND_NAME, STATUS_REFRESHING_TOKEN } from './constants';
import { UI_MODAL_LOGIN_REFRESHED_EVENT } from './components/modals/UiLoginRefreshModal.vue';
import { initializeEcho } from '@/js/websocket.js';
import { printf as sprintf } from 'fast-printf';

const axiosInstance = axios.create({
    withCredentials: true
});

const getPrefixedUuid = (prefix) => (prefix ?? '') + uuidv4();

const refreshServiceToken = async (context, { success: successCallback, error: errorCallback }) => {
    let { rootState, commit } = context;

    try {
        commit('ui/setSaveStatus', STATUS_REFRESHING_TOKEN, { root: true });
        await axiosInstance.get(rootState.ui.urls.refreshServiceToken, { withCredentials: true });
        // If service token is successfully refreshed, we can call success method
        if (successCallback) return await successCallback(context);
    } catch (refreshError) {
        if (errorCallback) errorCallback(context);
        throw refreshError;
    }
};

const requestWithServiceTokenRefresh = async (request, errorCallback, context) => {
    try {
        return await request();
    } catch (error) {
        if (error.name != 'AbortError') {
            try {
                if (error.response?.status == 401 || error.response?.status == 419) {
                    // Try refreshing service token
                    return await refreshServiceToken(context, {
                        success: async () => {
                            return await request();
                        }
                    });
                } else {
                    throw error;
                }
            } catch (refreshError) {
                if (errorCallback) errorCallback(refreshError);
            }
        }
    }
};

const maybeRefreshLogin = (context, token, refreshCallback = null) => {
    let { dispatch } = context,
        data = {
            token,
            ...(!!refreshCallback && typeof refreshCallback == 'function'
                ? { [parseEventName(UI_MODAL_LOGIN_REFRESHED_EVENT)]: refreshCallback }
                : {})
        };

    dispatch('ui/modals/openRefreshLoginModal', data, { root: true });
};

const resolveChannelName = (name, data = {}) =>
    Object.keys(data).reduce((resolved, key) => resolved.replace('{' + key + '}', data[key]), name);

const subscribeToChannel = (channelName, channelData = {}, channelType = null, listeners = {}) => {
    console.log('Subscribing to channel', channelName, channelData, channelType, listeners);
    if (!window.Echo) initializeEcho();

    let channel = window.Echo[channelType || 'channel'](resolveChannelName(channelName, channelData));

    Object.keys(listeners).forEach((event) => {
        channel.listen(event, listeners[event]);
    });
};

const resolveMediaApiUrl = (url, folderId, organizationId, mediaType, mediaId) => {
    return url
        .replace(/%%FOLDER_ID%%/, folderId || '')
        .replace(/%%ORGANIZATION_ID%%/, !folderId ? organizationId || '' : '')
        .replace(/%%MEDIA_TYPE%%/, mediaType || '')
        .replace(/%%MEDIA_ID%%/, mediaId || '');
};

const getShortcutString = (shortcutKey, key, shiftKey = false) => {
    let pattern = shortcutKey == 'metaKey' ? '[SHIFT]⌘[KEY]' : 'Ctrl+[SHIFT][KEY]',
        shiftString = shortcutKey == 'metaKey' ? '⇧' : 'Shift+';
    return pattern.replace(/\[SHIFT\]/g, shiftKey ? shiftString : '').replace(/\[KEY\]/g, key.toUpperCase());
};

const parseEventName = (name) => {
    return `on${name.charAt(0).toUpperCase()}${name.slice(1)}`;
};

const truncateString = (text, maxLength = 50) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
};

const getTemplateCategoryLabel = (t, type, categoryName, organizationName = null) => {
    const name = t(`brands.categories.${type}.${categoryName}`);

    if (CATEGORIES_INCLUDING_BRAND_NAME.includes(categoryName)) {
        return sprintf(name, organizationName);
    }

    return name;
};

export {
    getPrefixedUuid,
    refreshServiceToken,
    requestWithServiceTokenRefresh,
    maybeRefreshLogin,
    resolveChannelName,
    subscribeToChannel,
    resolveMediaApiUrl,
    getShortcutString,
    parseEventName,
    truncateString,
    getTemplateCategoryLabel
};
