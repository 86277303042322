import { computed } from 'vue';
import { useStore } from 'vuex';
import { Visual } from 'cte-video-studio';
import { useSequence } from './useSequence';
import { useHistory } from './useHistory';

export function useElement(seqId, elementId, store = null) {
    if (!store) {
        store = useStore();
    }
    const { saveHistoryStep } = useHistory(store);
    const { sequenceState, sequenceStoreModulePath } = useSequence(seqId, store);

    const elementState = computed(() => sequenceState.value[elementId]);
    const elementStoreModulePath = computed(() => `${sequenceStoreModulePath.value}/${elementId}`);
    const isRecordingCategory = computed(() => elementState.value.animation.category == Visual.RECORDING_CATEGORY);
    const videoState = computed(() =>
        isRecordingCategory.value ? elementState.value.recording : elementState.value.video
    );
    const maxDuration = computed(() => videoState.value.totalDuration());

    const startTime = computed(() => sequenceState.value.start);

    const playbackRate = computed({
        get: () => videoState.value.playbackRate || playbackRateDefault,
        set: (value) => {
            saveHistoryStep(() => {
                if (isRecordingCategory.value) {
                    store.commit(`${elementStoreModulePath.value}/recording/setPlaybackRate`, value);
                } else {
                    store.commit(`${elementStoreModulePath.value}/setVideoPlaybackRate`, value);
                }
            });
        }
    });

    const videoRange = computed({
        get: () => {
            const range = { start: 0, end: -1 };
            range.start = videoState.value.timerange.start;
            range.end = videoState.value.timerange.end;

            return range;
        },
        set: ({ start, end }) => {
            if (start !== videoRange.value.start || end !== videoRange.value.end) {
                saveHistoryStep(() => {
                    if (isRecordingCategory.value) {
                        store.commit(`${elementStoreModulePath.value}/recording/setTimerange`, { start, end });
                    } else {
                        store.commit(`${elementStoreModulePath.value}/setVideoTimerange`, { start, end });
                    }
                });
            }
        }
    });

    const videoTimerangeSegments = computed({
        get: () => elementState.value.video.timerangeSegments,
        set: (value) => {
            saveHistoryStep(() => {
                store.commit(`${elementStoreModulePath.value}/setVideoTimerangeSegments`, value);
            });
        }
    });

    const recordingVisualTimerangeSegments = computed({
        get: () => elementState.value.recording.timerangeSegments ?? [],
        set: (value) => {
            saveHistoryStep(() => {
                store.commit(`${elementStoreModulePath.value}/recording/setTimerangeSegments`, value);
            });
        }
    });

    const videoSrc = computed({
        get: () => elementState.value.video,
        set: (value) => {
            saveHistoryStep(() => {
                store.commit(`${elementStoreModulePath.value}/setVideo`, value);
            });
        }
    });

    const recordingVisualSrc = computed(() => elementState.value.recording);

    return {
        elementState,
        elementStoreModulePath,
        videoState,
        isRecordingCategory,
        maxDuration,
        playbackRate,
        videoTimerangeSegments,
        recordingVisualTimerangeSegments,
        recordingVisualSrc,
        videoRange,
        videoSrc
    };
}
