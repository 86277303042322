/**
 * Video Studio
 * Type utilities
 */

import {
    Align,
    Background,
    Color,
    Dimension,
    Font,
    Logo,
    Message,
    Overlay,
    Panel,
    Production,
    Transition,
    Visual
} from '../constants';
import Fonts from '../constants/font-list.json';

const FALSY_NAN_VALUES = [true, false, '', null];

const isPercentage = (value) => {
    // Plain number defaults to percentage
    let val = parseFloat(value);
    return (
        (!isNaN(value) && FALSY_NAN_VALUES.indexOf(value) === -1) ||
        (!isNaN(val) && val + Dimension.PERCENT_UNIT == value)
    );
};

const isPixels = (value) => {
    let val = parseFloat(value);
    return !isNaN(val) && val + Dimension.PIXEL_UNIT == value;
};

const isDimension = (value, positive) =>
    value == Dimension.AUTO || ((isPercentage(value) || isPixels(value)) && (!positive || isPositive(value)));

const isZero = (value) => parseFloat(value) === 0;

const isPositive = (value) => parseFloat(value) > 0;

const isZeroPositive = (value) => isZero(value) || isPositive(value);

const isNegative = (value) => parseFloat(value) < 0;

const isAlign = (value) => {
    let { TEXT_LEFT, TEXT_CENTER, TEXT_RIGHT, TEXT_JUSTIFY, ...alignValues } = Align;
    return Object.values(alignValues).indexOf(value) !== -1;
};

const isDistributedAlign = (value, excludeHorizontal = false, excludeVertical = false, includeDefault = false) => {
    let aligns = [Align.DISTRIBUTED_TOP, Align.DISTRIBUTED_RIGHT, Align.DISTRIBUTED_BOTTOM, Align.DISTRIBUTED_LEFT];
    if (includeDefault) aligns.push(Align.DISTRIBUTED_DEFAULT);
    if (excludeHorizontal)
        aligns = aligns.filter((dist) => [Align.DISTRIBUTED_TOP, Align.DISTRIBUTED_BOTTOM].indexOf(dist) !== -1);
    if (excludeVertical)
        aligns = aligns.filter((dist) => [Align.DISTRIBUTED_LEFT, Align.DISTRIBUTED_RIGHT].indexOf(dist) !== -1);
    return aligns.indexOf(value) !== -1;
};

const isHalfPosition = (value) => {
    return [Align.HALF_TOP, Align.HALF_RIGHT, Align.HALF_BOTTOM, Align.HALF_LEFT].indexOf(value) !== -1;
};

const isTextAlign = (value) => {
    let textAlignValues = [Align.TEXT_LEFT, Align.TEXT_CENTER, Align.TEXT_RIGHT, Align.TEXT_JUSTIFY];
    return textAlignValues.indexOf(value) !== -1;
};

const isColor = (value) => Color.CSS_REGEXP.test(value);

const isAngle = (value) => {
    let val = parseFloat(value);
    return (
        (!isNaN(value) && FALSY_NAN_VALUES.indexOf(value) === -1) ||
        (!isNaN(val) && val + Dimension.DEGREE_UNIT == value)
    );
};

const isFont = (value) => {
    return Object.keys(Fonts).indexOf(value) !== -1;
};

const isFontStyle = (value) => {
    let fontStyleValues = [Font.STYLE_NORMAL, Font.STYLE_BOLD, Font.STYLE_ITALIC, Font.STYLE_BOLD_ITALIC];
    return fontStyleValues.indexOf(value) !== -1;
};

const isLogoAnimation = (value) => {
    let { PREFIX_CLASS, ...logoProperties } = Logo;
    return Object.values(logoProperties).indexOf(Logo.PREFIX_CLASS + value) !== -1;
};

const isTransition = (value) => {
    let { PREFIX_CLASS, ...transitionProperties } = Transition;
    return Object.values(transitionProperties).indexOf(Transition.PREFIX_CLASS + value) !== -1;
};

const isBackground = (value) => Background.TYPES.indexOf(value) !== -1;

const isBackgroundAnimation = (value) => Object.values(Background.ANIMATION_TYPES).indexOf(value) !== -1;

const isBackgroundOverlay = (value) => {
    let { PREFIX_CLASS, ...overlayProperties } = Overlay;
    return Object.values(overlayProperties).indexOf(PREFIX_CLASS + value) !== -1;
};

const isPanelCategory = (value) => Panel.CATEGORIES.indexOf(value) !== -1;

const isPanel = (value) => {
    let { PREFIX_CLASS, ...panelProperties } = Panel;
    return Object.values(panelProperties).indexOf(PREFIX_CLASS + value) !== -1;
};

const isVisualCategory = (value) => Visual.CATEGORIES.indexOf(value) !== -1;

const isVisual = (value) => {
    let { PREFIX_CLASS, ...visualProperties } = Visual;
    return Object.values(visualProperties).indexOf(PREFIX_CLASS + value) !== -1;
};

const isMessage = (value) => {
    let { PREFIX_CLASS, ...messageProperties } = Message;
    return Object.values(messageProperties).indexOf(PREFIX_CLASS + value) !== -1;
};

const isCaptureMode = (value) => Production.CAPTURE_MODES.indexOf(value) !== -1;

export default {
    isPercentage,
    isPixels,
    isDimension,
    isZero,
    isPositive,
    isZeroPositive,
    isNegative,
    isAlign,
    isDistributedAlign,
    isHalfPosition,
    isTextAlign,
    isColor,
    isAngle,
    isFont,
    isFontStyle,
    isLogoAnimation,
    isTransition,
    isBackground,
    isBackgroundAnimation,
    isBackgroundOverlay,
    isPanelCategory,
    isPanel,
    isVisualCategory,
    isVisual,
    isMessage,
    isCaptureMode
};
