/**
 * Edit video Settings cover Vuex store extension
 */

import { types, Align, Dimension } from 'cte-video-studio';
import timeline from '../timeline.js';

const state = {
    ...timeline.state(),

    enabled: false,
    src: '',
    src__ref: '',
    src__id: '',
    position: {
        alignH: Align.RIGHT,
        alignV: Align.BOTTOM,
        alignX: Dimension.ZERO,
        alignY: Dimension.ZERO
    }
};

// Getters
const getters = {
    coverTimeline: (state) => state.timeline,

    coverTimelineOut: (state) => state.timelineOut,

    coverNeedsRefresh: (state) => state.needsRefresh,

    cover(state, getters, rootState) {
        if (!state.enabled) return false;
        return state.src || rootState.branding.cover.src || '';
    }
};

// Mutations
const mutations = {
    setCoverTimeline: timeline.mutations.setTimeline,

    setCoverTimelineOut: timeline.mutations.setTimelineOut,

    enabledCoverRefresh: timeline.mutations.enableRefresh,

    enableCover(state, enabled) {
        state.enabled = enabled;
    },

    setCover(state, src) {
        state.src = src;
    },

    setCoverPosition(state, position) {
        state.position.alignH = types.isAlign(position.alignH) ? position.alignH : Align.RIGHT;
        state.position.alignV = types.isAlign(position.alignV) ? position.alignV : Align.BOTTOM;
        state.position.alignX =
            state.position.alignH == Align.CUSTOM && types.isDimension(position.alignX)
                ? position.alignX
                : Dimension.ZERO;
        state.position.alignY =
            state.position.alignV == Align.CUSTOM && types.isDimension(position.alignY)
                ? position.alignY
                : Dimension.ZERO;
    },

    setCoverReference(state, ref) {
        state.src__ref = ref;
    },

    setCoverId(state, id) {
        state.src__id = id;
    }
};

// Actions
const actions = {
    initCover({ commit }, data) {
        commit('enableCover', data.enabled);
        commit('setCover', data.src);
        commit('setCoverPosition', data.position);
        commit('setCoverId', data.src__id);
        commit('setCoverReference', data.src__ref);
    },

    updateCover({ commit }, data) {
        commit('setCoverId', data.src__id);
        commit('setCover', data.src);
    }
};

export default {
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions
};
