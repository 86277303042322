<template>
    <div class="time-input-container">
        <label v-if="label" class="time-input-label">{{ label }}</label>
        <div class="time-input-wrapper" :class="{ 'is-focused': isFocused }">
            <div class="input-tooltip-wrapper">
                <input
                    ref="minutesInput"
                    type="number"
                    v-model="minutes"
                    @input="updateFromInput('minutes')"
                    @keydown.up.prevent="incrementField('minutes')"
                    @keydown.down.prevent="decrementField('minutes')"
                    @focus="handleFocus"
                    @blur="handleBlur"
                    min="0"
                    :max="maxMinutes"
                    class="time-input minutes"
                />
            </div>
            <span class="time-separator">:</span>
            <div class="input-tooltip-wrapper">
                <input
                    ref="secondsInput"
                    type="number"
                    v-model="displaySeconds"
                    @input="updateFromInput('seconds')"
                    @keydown.up.prevent="incrementField('seconds')"
                    @keydown.down.prevent="decrementField('seconds')"
                    @focus="handleFocus"
                    @blur="handleBlur"
                    min="0"
                    max="59"
                    class="time-input seconds"
                />
            </div>
            <span class="time-separator">.</span>
            <div class="input-tooltip-wrapper">
                <input
                    ref="framesInput"
                    type="text"
                    v-model="displayFrames"
                    @input="updateFromInput('frames')"
                    @keydown.up.prevent="incrementField('frames')"
                    @keydown.down.prevent="decrementField('frames')"
                    @focus="handleFocus"
                    @blur="handleBlur"
                    class="time-input frames"
                />
            </div>
            <div class="time-input-controls">
                <button @click="increment" class="time-input-button">
                    <fa-icon class="arrow" icon="fa-solid fa-chevron-up" />
                </button>
                <button @click="decrement" class="time-input-button">
                    <fa-icon class="arrow" icon="fa-solid fa-chevron-down" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, computed, watch } from 'vue';

export default defineComponent({
    name: 'TimeInput',
    props: {
        modelValue: {
            type: Number,
            required: true
        },
        label: {
            type: String,
            default: ''
        },
        step: {
            type: Number,
            default: 0.01
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: Infinity
        },
        rate: {
            type: Number,
            required: true
        }
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const isFocused = ref(false);
        const minutes = ref(0);
        const seconds = ref(0);
        const frames = ref(0);

        const minutesInput = ref(null);
        const secondsInput = ref(null);
        const framesInput = ref(null);

        const maxMinutes = computed(() => Math.floor(props.max / 60));

        const displayFrames = computed({
            get: () => frames.value.toString().padStart(2, '0'),
            set: (value) => {
                const numericValue = parseInt(value, 10);
                if (!isNaN(numericValue) && numericValue >= 0 && numericValue < props.rate) {
                    frames.value = numericValue;
                    updateModelValue();
                }
            }
        });

        const displaySeconds = computed({
            get: () => seconds.value.toString().padStart(2, '0'),
            set: (value) => {
                const numericValue = parseInt(value, 10);
                if (!isNaN(numericValue) && numericValue >= 0 && numericValue < 60) {
                    seconds.value = numericValue;
                    updateModelValue();
                }
            }
        });

        watch(() => props.modelValue, updateTimeComponents);

        function updateTimeComponents() {
            const totalSeconds = props.modelValue;
            minutes.value = Math.floor(totalSeconds / 60);
            seconds.value = Math.floor(totalSeconds % 60);
            frames.value = Math.round((totalSeconds % 1) * props.rate);
        }

        function updateModelValue() {
            const totalSeconds = minutes.value * 60 + seconds.value + frames.value / props.rate;
            emit('update:modelValue', Math.max(props.min, Math.min(props.max, totalSeconds)));
        }

        function updateFromInput(field) {
            if (field === 'minutes') {
                minutes.value = Math.max(0, Math.min(maxMinutes.value, parseInt(minutes.value) || 0));
            } else if (field === 'seconds') {
                const numericValue = parseInt(displaySeconds.value, 10);
                if (!isNaN(numericValue)) {
                    seconds.value = Math.max(0, Math.min(59, numericValue));
                }
            } else if (field === 'frames') {
                const numericValue = parseInt(displayFrames.value, 10);
                if (!isNaN(numericValue)) {
                    frames.value = Math.max(0, Math.min(props.rate - 1, numericValue));
                }
            }
            updateModelValue();
        }

        function incrementField(field) {
            if (field === 'minutes') {
                if (minutes.value < maxMinutes.value) {
                    minutes.value++;
                }
            } else if (field === 'seconds') {
                if (seconds.value < 59) {
                    seconds.value++;
                } else {
                    seconds.value = 0;
                    incrementField('minutes');
                }
            } else if (field === 'frames') {
                if (frames.value < props.rate - 1) {
                    frames.value++;
                } else {
                    frames.value = 0;
                    incrementField('seconds');
                }
            }
            updateModelValue();
        }

        function decrementField(field) {
            if (field === 'minutes') {
                if (minutes.value > 0) {
                    minutes.value--;
                }
            } else if (field === 'seconds') {
                if (seconds.value > 0) {
                    seconds.value--;
                } else if (minutes.value > 0) {
                    seconds.value = 59;
                    decrementField('minutes');
                }
            } else if (field === 'frames') {
                if (frames.value > 0) {
                    frames.value--;
                } else if (seconds.value > 0 || minutes.value > 0) {
                    frames.value = props.rate - 1;
                    decrementField('seconds');
                }
            }
            updateModelValue();
        }

        function handleFocus() {
            isFocused.value = true;
        }

        function handleBlur() {
            setTimeout(() => {
                if (
                    !minutesInput.value.contains(document.activeElement) &&
                    !secondsInput.value.contains(document.activeElement) &&
                    !framesInput.value.contains(document.activeElement)
                ) {
                    isFocused.value = false;
                }
            }, 0);
        }

        function increment() {
            incrementField('frames');
        }

        function decrement() {
            decrementField('frames');
        }

        updateTimeComponents();

        return {
            isFocused,
            minutes,
            seconds,
            frames,
            displayFrames,
            displaySeconds,
            updateFromInput,
            handleFocus,
            handleBlur,
            increment,
            decrement,
            incrementField,
            decrementField,
            minutesInput,
            secondsInput,
            framesInput,
            maxMinutes
        };
    }
});
</script>

<style scoped>

.arrow{
    width: 10px;
    height: 10px;
}

.icon {
    width: 16px;
    height: 16px;
}

.time-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.time-input-label {
    margin-right: 10px;
    margin-bottom: 2px;
    color: #ffffff;
    font-size: 14px;
}

.time-input-wrapper {
    display: flex;
    align-items: center;
    background-color: #29303A;
    border: 1px solid #3A4452;
    border-radius: 10px;
    padding: 2px 4px;
    transition: border-color 0.2s;
}


.time-input-wrapper.is-focused {
    border-color: white;
}

.input-tooltip-wrapper {
    position: relative;
    display: inline-block;
}

.time-input {
    width: 24px;
    padding: 2px 1px;
    font-size: 14px;
    background-color: transparent;
    color: #ffffff;
    border: none;
    text-align: center;
    -moz-appearance: textfield;
}

.time-input:focus {
    outline: none;
}

.time-input::-webkit-inner-spin-button,
.time-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.time-separator {
    color: #ffffff;
    padding: 0 1px;
    font-size: 14px;
}

.time-input-controls {
    display: flex;
    flex-direction: column;
    margin-left: 4px;
}

.time-input-button {
    background: none;
    border: none;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
    padding: 1%;
    line-height: 1;
}

.time-input-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.time-input-button:focus {
    outline: none;
}

@media (max-width: 1300px) {
    .time-input-label {
        display: none;
    }
}
</style>
