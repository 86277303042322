/**
 * Video Companion current prompt Vuex store
 */

import axios from 'axios';
import {
    PROMPT_STATUS_COMPLETED,
    PROMPT_STATUS_CREATING,
    PROMPT_STATUS_FAILED,
    PROMPT_STATUS_IGNORED,
    PROMPT_STATUS_PENDING,
    PROMPT_STATUS_PROCESSING
} from '../../../constants';
import { maybeRefreshLogin, requestWithServiceTokenRefresh } from '@/js/utils.js';

let _loadPromptAbortController;

const state = {
    id: null,
    name: '',

    input: '',
    context: {},
    brand: {
        id: null,
        name: ''
    },

    videoCreationsTotal: 0,
    videos: [],

    status: PROMPT_STATUS_PENDING,

    createdAt: null,
    startedAt: null,
    endedAt: null,

    resourceUrl: '',
    showUrl: '',
    deleteUrl: '',

    loaded: false
};

const getters = {
    currentPromptExists: (state) => !!state.resourceUrl && !!state.id,

    currentPromptNeedsLoading: (state, getters) => getters.currentPromptExists && !state.loaded,

    currentPromptIsPending: (state) => state.status === PROMPT_STATUS_PENDING,
    currentPromptIsProcessing: (state) => state.status === PROMPT_STATUS_PROCESSING,
    currentPromptIsCreating: (state) => state.status === PROMPT_STATUS_CREATING,
    currentPromptIsCompleted: (state) => state.status === PROMPT_STATUS_COMPLETED,
    currentPromptIsIgnored: (state) => state.status === PROMPT_STATUS_IGNORED,
    currentPromptIsFailed: (state) => state.status === PROMPT_STATUS_FAILED,
    currentPromptIsOngoing: (state) => [PROMPT_STATUS_PROCESSING, PROMPT_STATUS_CREATING].includes(state.status),
    currentPromptIsEnded: (state) =>
        [PROMPT_STATUS_COMPLETED, PROMPT_STATUS_IGNORED, PROMPT_STATUS_FAILED].includes(state.status)
};

const mutations = {
    setCurrentPromptId(state, id) {
        state.id = id;
    },

    setCurrentPromptName(state, name) {
        state.name = name;
    },

    setCurrentPromptInput(state, input) {
        state.input = input;
    },

    setCurrentPromptContext(state, context) {
        state.context = context;
    },

    setCurrentPromptBrand(state, { id, name }) {
        state.brand.id = id;
        state.brand.name = name;
    },

    setCurrentPromptVideoCreationsTotal(state, total) {
        state.videoCreationsTotal = total;
    },

    setCurrentPromptVideos(state, videos) {
        state.videos = videos;
    },

    setCurrentPromptStatus(state, status) {
        state.status = status;
    },

    setCurrentPromptTimestamps(state, { created_at, started_at, ended_at }) {
        state.createdAt = created_at ? created_at * 1000 : null;
        state.startedAt = started_at ? started_at * 1000 : null;
        state.endedAt = ended_at ? ended_at * 1000 : null;
    },

    setCurrentPromptResourceUrl(state, url) {
        state.resourceUrl = url;
    },

    setCurrentPromptShowUrl(state, url) {
        state.showUrl = url;
    },

    setCurrentPromptDeleteUrl(state, url) {
        state.deleteUrl = url;
    },

    setCurrentPromptLoaded(state, loaded) {
        state.loaded = loaded;
    }
};

const actions = {
    setPromptToLoad({ state, commit, dispatch }, { id, url }) {
        commit('setCurrentPromptId', id);
        commit('setCurrentPromptResourceUrl', url);
        commit('setCurrentPromptLoaded', false);

        commit('setCurrentPromptName', '');
        commit('setCurrentPromptInput', '');
        commit('setCurrentPromptContext', {});
        dispatch('setCurrentPromptBrand', { id: null, name: '' });
        commit('setCurrentPromptVideoCreationsTotal', 0);
        commit('setCurrentPromptVideos', []);
        commit('setCurrentPromptStatus', PROMPT_STATUS_PENDING);
        commit('setCurrentPromptTimestamps', {});
        commit('setCurrentPromptShowUrl', '');
        commit('setCurrentPromptDeleteUrl', '');
    },

    setCurrentPrompt({ commit, dispatch }, prompt) {
        commit('setCurrentPromptId', prompt.id);
        commit('setCurrentPromptName', prompt.name);
        commit('setCurrentPromptInput', prompt.input);
        commit('setCurrentPromptContext', prompt.context);
        dispatch('setCurrentPromptBrand', prompt.brand);
        commit('setCurrentPromptVideoCreationsTotal', prompt.video_creations_total);
        commit('setCurrentPromptVideos', prompt.videos || []);
        commit('setCurrentPromptStatus', prompt.status);
        commit('setCurrentPromptTimestamps', {
            created_at: prompt.created_at,
            started_at: prompt.started_at,
            ended_at: prompt.ended_at
        });
        commit('setCurrentPromptResourceUrl', prompt.resource_url);
        commit('setCurrentPromptShowUrl', prompt.show_url);
        commit('setCurrentPromptDeleteUrl', prompt.delete_url);
        commit('setCurrentPromptLoaded', true);
    },

    async loadPrompt(context, promptUrl) {
        let { dispatch, rootState } = context;

        if (_loadPromptAbortController) _loadPromptAbortController.abort();
        _loadPromptAbortController = new AbortController();

        await requestWithServiceTokenRefresh(
            async () => {
                let response = await axios.get(promptUrl, {
                    withCredentials: true,
                    signal: _loadPromptAbortController.signal
                });
                dispatch('setCurrentPrompt', response.data);
            },
            (refreshError) => {
                console.log('[Error] Loading prompt: ' + refreshError.message);
                if (!!refreshError.response?.data?.token) {
                    maybeRefreshLogin(context, refreshError.response.data.token, () =>
                        dispatch('loadPrompt', promptUrl)
                    );
                } else if (!!refreshError.response?.data?.redirect) {
                    // commit('setAuthError', refreshError.response.data);
                    window.location.assign(refreshError.response.data.redirect);
                } else {
                    // TODO: Display error message?
                    dispatch('showNewPrompt', true);
                }
            },
            context
        );
    },

    unloadPrompt({ commit, dispatch }) {
        commit('setCurrentPromptId', null);
        commit('setCurrentPromptName', '');
        commit('setCurrentPromptInput', '');
        commit('setCurrentPromptContext', {});
        dispatch('setCurrentPromptBrand', { id: null, name: '' });
        commit('setCurrentPromptVideoCreationsTotal', 0);
        commit('setCurrentPromptVideos', []);
        commit('setCurrentPromptStatus', PROMPT_STATUS_PENDING);
        commit('setCurrentPromptTimestamps', {});
        commit('setCurrentPromptResourceUrl', '');
        commit('setCurrentPromptShowUrl', '');
        commit('setCurrentPromptDeleteUrl', '');
        commit('setCurrentPromptLoaded', false);
    },

    showPrompt({ dispatch }, { prompt, replace }) {
        /*window.history[replace ? 'replaceState' : 'pushState'](
            { id: prompt.id, url: prompt.resource_url },
            '',
            prompt.show_url
        );*/
        dispatch('setPromptToLoad', { id: prompt.id, url: prompt.resource_url });
        dispatch('loadPrompt', prompt.resource_url);
    },

    showNewPrompt({ dispatch, rootState }, replace) {
        // window.history[replace ? 'replaceState' : 'pushState']({ new: true }, '', rootState.ui.urls.base);
        dispatch('unloadPrompt');
    },

    setCurrentPromptBrand({ commit, rootState }, brand) {
        if (brand.id) {
            commit('setCurrentPromptBrand', brand);
        } else {
            commit('setCurrentPromptBrand', {
                id: rootState.ui.defaultBrandId,
                name: rootState.ui.availableBrands[rootState.ui.defaultBrandId] || ''
            });
        }
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
