<template>
    <div class="ui-session">
        <ui-user-prompt :prompt="prompt" />
        <section v-if="prompt.isPending" class="ui-answer-loader">
            <svg-icon icon="dots-loader" />
        </section>
        <ui-companion-answer
            v-if="!prompt.isPending"
            :prompt="prompt"
            :folders="folders"
            @[companionVideoUseEvent]="useVideo"
        />
    </div>
</template>

<script>
import { printf as sprintf } from 'fast-printf';
import { UiIcon } from '../../../../components';
import UiUserPrompt from './UiUserPrompt.vue';
import UiCompanionAnswer from './UiCompanionAnswer.vue';
import { UI_COMPANION_VIDEO_USE } from './UiCompanionVideoCreated.vue';

export default {
    emits: [UI_COMPANION_VIDEO_USE],

    components: {
        UiIcon,
        UiUserPrompt,
        UiCompanionAnswer
    },

    props: {
        prompt: {
            type: Object,
            default: null
        },
        folders: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            companionVideoUseEvent: UI_COMPANION_VIDEO_USE
        };
    },

    computed: {
        //
    },

    methods: {
        sprintf,

        useVideo(videoData) {
            this.$emit(UI_COMPANION_VIDEO_USE, videoData);
        }
    },

    mounted() {
        //
    }
};
</script>
