import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export function useStoreManagement(store = null) {
    if (!store) {
        store = useStore();
    }

    const sequenceProps = reactive({
        backgroundVideo: { src: '' },
        hasVideoBackground: false,
        sequenceStoreModulePath: '',
        resolvedBackgroundState: {},
        startTime: 0,
        endTime: 0,
        playbackRate: 1,
    });

    const isOpenQuickCut = computed(() => store.getters['ui/quickcut/isOpenQuickCut']);

    const elementId = computed(() => store.getters['ui/quickcut/getElementId']);

    const sequenceId = computed(() => store.getters['ui/quickcut/getSeqId']);

    const mediaType = computed(() => store.getters['ui/quickcut/getMediaType']);

    const mediaSrc = computed(() => store.getters['ui/quickcut/getMediaSrc']);

    const elapsedTime = computed(() => store.state.display.timeline.sequenceElapsedTime);

    const getSegmentsFromStore = computed(() => {
        if (sequenceProps.sequenceStoreModulePath) {
            return store.getters[sequenceProps.sequenceStoreModulePath + '/getBackgroundVideoTimerangeSegments'];
        }
        return null;
    });

    const updateSegmentsInStore = (segments) => {
        if (sequenceProps.sequenceStoreModulePath) {
            store.commit(`${sequenceProps.sequenceStoreModulePath}/setBackgroundVideoTimerangeSegments`, segments);
        }
    }
    
    const closeQuickCut = () => {
        store.dispatch('ui/quickcut/closeQuickCut');
    };

    return {
        sequenceProps,
        elapsedTime,
        sequenceId,
        elementId,
        mediaType,
        mediaSrc,
        isOpenQuickCut,
        getSegmentsFromStore,
        updateSegmentsInStore,
        closeQuickCut
    };
}
