<!--
  -- Component name: AyvensLogo2
  -- Type: Transition
  -- Uses: color.start
  -- Tags: ayvens
  -->
<template>
    <div
        ref="transitionBackground"
        :style="`background-color: ${state.color.start};`"
        class="testcontainer studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
    >
        <div ref="maskBackground" class="testmask"></div>
    </div>
</template>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: false, // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            inElementsAutoAppear: false // The in elements are automatically added to the timeline and appear from the start,
            // keepActive: true
        };
    },

    computed: {
        color() {
            return this.state.color.enabled ? this.state.color?.start : '#000';
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            // white background for the mask
            const maskBackground = this.$refs.maskBackground;
            const transitionBackground = this.$refs.transitionBackground;

            const t = gsap.timeline();

            // hide masked image as it will appear with fade
            t.set([this.backgroundInElement], {
                autoAlpha: 0
            });

            // mask background style
            t.set(maskBackground, {
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                zIndex: 1000,
                background: 'white'
            });

            // set initial mask style
            // using mask image as data-uri because it does not work properly with inline HTML reference
            t.set([maskBackground, this.backgroundInElement], {
                maskImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 151.64 113.69'%3E%3Cpath d='M102.03 112.98h49.61V0h-31.61c0 39.37-6.33 77.51-18 112.98ZM85.64 0 0 113.69h1.47c61.58-.8 111.95-51.92 111.95-113.68H85.64Z' style='fill:%230f3549;stroke-width:0'/%3E%3C/svg%3E")`,
                maskPosition: 'center',
                maskRepeat: 'no-repeat',
                maskSize: '20%'
            });

            // mask image fade
            t.fromTo(
                this.backgroundInElement,
                {
                    autoAlpha: 0
                },
                {
                    autoAlpha: 1,
                    duration: 1,
                    ease: 'sine.in'
                },
                0.4
            );

            // scale up
            t.fromTo(
                [maskBackground, this.backgroundInElement, transitionBackground],
                {
                    maskSize: '20%'
                },
                {
                    maskSize: '700%',
                    duration: 1.15,
                    ease: 'expo.in'
                },
                0.4
            );

            return t;
        }
    }
};
</script>
