import StoreMediaApiService from '@/js/infrastructure/api/media/StoreMediaApiService.js';

export default class StoreRecordedMediaService {
    constructor(store) {
        this.store = store;
        this.storeMediaApiService = new StoreMediaApiService(store);
    }

    async handle(files) {
        const uploadedFiles = await Promise.all(
            files.map((file) => uploadRecordedMedia(file, this.storeMediaApiService))
        );

        return (
            await this.storeMediaApiService.storeMedia({
                organizationId: this.store.state.ui.organizationId,
                folderIds: [this.store.state.ui.folderId],
                tags: ['#mes-enregistrements'],
                files: uploadedFiles
            })
        ).data;
    }
}

const uploadRecordedMedia = async (file, storeMediaApiService) => {
    // Get upload dataand url for a file
    let uploadData = (await storeMediaApiService.getMediaUploadUrl(file))?.data;
    file.uploadData = uploadData?.data;

    // Upload the file
    await storeMediaApiService.uploadMedia(file);

    // Return data for storing the file
    return { ...file.uploadData, filename: file.name };
};
