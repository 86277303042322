<!--
  -- Component name: SG3
  -- Type: Transition
  -- Uses: 
  -- Tags: sg
  -->
<template>
    <svg
        ref="$sgLogo"
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        :viewBox="dataForView.viewBox"
        preserveAspectRatio="xMidYMid slice"
    >
        <g class="sg-logo">
            <rect class="sg-container" x="0" y="0" :width="dataForView.size" :height="dataForView.size" fill="#fff" />
            <rect
                class="sg-top"
                :x="dataForView.border"
                :y="dataForView.border"
                :width="dataForView.rectangleWidth"
                :height="dataForView.rectangleHeight"
                fill="#e9041e"
            />
            <rect
                class="sg-bottom"
                :x="dataForView.border"
                :y="dataForView.bottomRectangleY"
                :width="dataForView.rectangleWidth"
                :height="dataForView.rectangleHeight"
                fill="#000"
            />
        </g>
    </svg>
</template>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            isAbove: true,
            borderSize: 10,
            logoScaleDown: 0.9,
            BORDER_SIZE_RATIO: 0.03832,
            HEIGHT_RATIO: 0.43284,
            BOTTOM_RATIO: 0.54817
        };
    },

    computed: {
        isLandscapeFormat() {
            return this.format.width > this.format.height;
        },

        isPortraitFormat() {
            return this.format.width < this.format.height;
        },

        isSquareFormat() {
            return this.format.width === this.format.height;
        },

        dataForView() {
            if (this.isLandscapeFormat) {
                const size = 415;
                const border = (size * this.BORDER_SIZE_RATIO) / 2;

                return {
                    scaleUp: 5,
                    size,
                    viewBox: '0 0 1920 1080',
                    rectangleWidth: size - border * 2,
                    rectangleHeight: size * this.HEIGHT_RATIO,
                    bottomRectangleY: size * this.BOTTOM_RATIO,
                    border,
                    duration: 1
                };
            }

            if (this.isSquareFormat) {
                const size = 360;
                const border = (size * this.BORDER_SIZE_RATIO) / 2;

                return {
                    scaleUp: 3,
                    size,
                    viewBox: '0 0 1000 1000',
                    rectangleWidth: size - border * 2,
                    rectangleHeight: size * this.HEIGHT_RATIO,
                    bottomRectangleY: size * this.BOTTOM_RATIO,
                    border,
                    duration: 0.7
                };
            }

            if (this.isPortraitFormat) {
                const size = 370;
                const border = (size * this.BORDER_SIZE_RATIO) / 2;

                return {
                    scaleUp: 5.5,
                    size,
                    viewBox: '0 0 1080 1920',
                    rectangleWidth: size - border * 2,
                    rectangleHeight: size * this.HEIGHT_RATIO,
                    bottomRectangleY: size * this.BOTTOM_RATIO,
                    border,
                    duration: 0.85
                };
            }
        }
    },

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            const t = gsap.timeline();

            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 }, 0.001);
            t.set('#' + this.containerID + ' .sg-logo', {
                transform:
                    'translateX(50%) translateX(-' +
                    this.dataForView.size / 2 +
                    'px) translateY(50%) translateY(-' +
                    this.dataForView.size / 2 +
                    'px)'
            });
            t.set('#' + this.containerID + ' .sg-container', {
                width: this.dataForView.size,
                height: this.dataForView.size
            });

            //Scaling down logo
            t.to(
                '#' + this.containerID + ' .sg-logo',
                {
                    scale: this.logoScaleDown,
                    duration: 0.4,
                    ease: 'power2.out',
                    transformOrigin: '50% 50%'
                },
                0.5
            );

            // Cropping borders
            t.to(
                '#' + this.containerID + ' .sg-container',
                {
                    translateX: this.dataForView.border,
                    translateY: this.dataForView.border,
                    height: this.dataForView.size - this.dataForView.border * 2,
                    width: this.dataForView.size - this.dataForView.border * 2,
                    duration: 0.4,
                    ease: 'none'
                },
                0.5
            );

            // Scaling up logo
            t.to(
                '#' + this.containerID + ' .sg-logo',
                {
                    scale: this.dataForView.scaleUp,
                    duration: 0.8,
                    ease: 'power2.out',
                    transformOrigin: '50% 50%'
                },
                1
            );

            t.to(
                '#' + this.containerID + ' .sg-top',
                {
                    translateY: '-100%',
                    duration: 0.7,
                    ease: 'power4.in'
                },
                1.4
            );

            t.to(
                '#' + this.containerID + ' .sg-logo',
                {
                    translateY: -this.format.height * 4,
                    duration: 1.5,
                    ease: 'power1.in'
                },
                1.8
            );

            t.fromTo(
                '#' + this.containerID + ' .sg-bottom',
                { attr: { y: this.dataForView.bottomRectangleY }, height: this.dataForView.rectangleHeight },
                {
                    attr: { y: this.dataForView.size - 10 },
                    height: 10,
                    duration: this.dataForView.duration,
                    ease: 'power1.in'
                },
                1.8
            );

            return t;
        }
    }
};
</script>
