<template>
    <div class="home-view-latest-videos" v-if="hasVideos">
        <h2>{{ $t('My latest videos') }}</h2>
        <div class="latest-videos-list">
            <VideoItemList :videos="latestVideos" :loading="loading" />
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useVideos } from '@/js/composables/useVideos';
import { useStore } from 'vuex';
import VideoItemList from '@/views/HomeView/components/VideoItemList.vue';

const store = useStore();
const loading = ref(false);

const { fetchLatestVideos, latestVideos } = useVideos(store);

const hasVideos = computed(() => latestVideos.value.length > 0);

onMounted(async () => {
    loading.value = true;
    await fetchLatestVideos();
    loading.value = false;
});
</script>
