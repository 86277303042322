<template>
    <div ref="componentRef" class="dropdown" :class="classes">
        <div class="dropdown-selected-value-container" @click="toggle">
            <div class="dropdown-selected-value">
                <slot name="dropdown-selected-value" />
            </div>
            <fa-icon class="icon dropdown-chevron-icon" icon="fa-solid fa-chevron-right" />
        </div>
        <div class="dropdown-list">
            <div class="dropdown-list-container">
                <slot />
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, provide, ref, watch } from 'vue';

const model = defineModel();

provide('model', model);

const opened = ref(false);
const componentRef = ref(null);

const classes = computed(() => ({
    opened: opened.value
}));

const toggle = () => {
    opened.value = !opened.value;
};

const close = () => {
    opened.value = false;
};

const handleClickOutside = (event) => {
    if (opened.value && componentRef.value && !componentRef.value.contains(event.target)) {
        opened.value = false;
    }
};

watch(model, () => {
    close();
});

onMounted(() => {
    document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside);
});
</script>

<style scoped>
.dropdown {
    position: relative;

    .dropdown-list {
        position: absolute;
        z-index: 1000;
        top: 100%;
        left: 0;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
    }

    &.right {
        .dropdown-list {
            right: 0;
            left: unset;
        }
    }

    &.opened {
        .dropdown-list {
            visibility: visible;
            opacity: 1;
            pointer-events: auto;
        }
    }
}
</style>
