import { executeRequest } from '@/js/infrastructure/api/apiClient.js';

const LEGACY_URL = import.meta.env.VITE_LEGACY_APP_URL;
const EDITION_URL = import.meta.env.VITE_EDITION_API_URL;

export default class ClientApiService {
    constructor(store) {
        this.store = store;
    }

    getAll(options = {}) {
        const { page = 1, search = '', count = 10 } = options;

        const queryParams = new URLSearchParams({
            page: page.toString(),
            count: count.toString()
        });

        if (search) queryParams.append('search', search);

        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${LEGACY_URL}/front/clients?${queryParams.toString()}`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'ClientApiService.getAll'
        });
    }

    getByShortname(shortname) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${LEGACY_URL}/front/clients/${shortname}`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'ClientApiService.getByShortname'
        });
    }

    put(shortname, params) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.put(`${LEGACY_URL}/front/clients/${shortname}`, params);
            },
            onSuccess: async (response) => response.data,
            actionType: 'ClientApiService.put'
        });
    }

    fetchClientBrands(clientId, search, page) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(
                    `${EDITION_URL}/api/organizations/${clientId}/brands?count=10&page=${page}&search=${search}`
                );
            },
            onSuccess: async (response) => response.data,
            actionType: 'ClientApiService.fetchClientBrands'
        });
    }

    fetchClientUsers(clientId, options = {}) {
        const { page = 1, search = '', count = 10 } = options;

        const queryParams = new URLSearchParams({
            page: page.toString(),
            count: count.toString()
        });

        if (search) queryParams.append('search', search);

        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${LEGACY_URL}/front/clients/${clientId}/users?${queryParams.toString()}`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'ClientApiService.fetchClientUsers'
        });
    }

    fetchFoldersGrant(clientId, options = {}) {
        const { page = 1, search = '', count = 10 } = options;

        const queryParams = new URLSearchParams({
            page: page.toString(),
            count: count.toString()
        });
        if (search) queryParams.append('search', search);

        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(
                    `${LEGACY_URL}/front/clients/${clientId}/folders-grant?${queryParams.toString()}`
                );
            },
            onSuccess: async (response) => response.data,
            actionType: 'ClientApiService.fetchFoldersGrant'
        });
    }
}
