<template>
    <main class="ui-view" id="home-view">
        <h1 class="view-title">{{ $t('What video would you like to create?') }}</h1>
        <div class="home-view-header">
            <div class="home-view-new-video">
                <h2>{{ $t('home.new-video') }}</h2>
                <div class="home-view-new-video-actions">
                    <CreateVideoBanner @create-video="createVideo" />
                    <AIGeneratedVideoBanner />
                </div>
            </div>
            <LastestVideosSection />
        </div>
        <div class="home-view-templates-banner">
            <TemplateFilters
                :templateCategories="templateCategories"
                :formats="FORMATS"
                v-model:selectedBrandId="selectedBrandId"
                v-model:templateFilterText="templateFilterText"
                v-model:selectedTemplateCategory="selectedTemplateCategory"
                v-model:selectedTemplateFormat="selectedTemplateFormat"
            />
            <div class="home-view-templates-display">
                <UiInfiniteScrollPaginator v-model="page" :max-page="totalPages" :loading="templatesLoading">
                    <TemplatesList
                        :loading="templatesLoading"
                        :templates="templates"
                        :selectedTemplateFormat="selectedTemplateFormat"
                        @select-template="createVideoFromTemplate"
                    />
                    <template v-slot:loading>
                        <div class="template-loader-container">
                            <LoadingMarker v-if="templatesLoading" />
                        </div>
                        <NoTemplatesLabel
                            v-if="!templatesLoading && templates.length < 1"
                            :search="templateFilterText"
                        />
                    </template>
                </UiInfiniteScrollPaginator>
            </div>
        </div>
    </main>
</template>

<script setup>
import { computed, ref, watch, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { debounce } from 'lodash';
import { useModal } from '@/js/composables/useModal';
import { useTemplates } from './composables/useTemplates';
import CreateVideoBanner from './components/CreateVideoBanner.vue';
import AIGeneratedVideoBanner from './components/AIGeneratedVideoBanner.vue';
import LastestVideosSection from './components/LatestVideosSection.vue';
import NoTemplatesLabel from './components/NoTemplatesLabel.vue';
import TemplateFilters from './components/TemplateFilters.vue';
import TemplatesList from './components/TemplatesList.vue';
import UiInfiniteScrollPaginator from '@/js/components/UiInfiniteScrollPaginator.vue';
import LoadingMarker from '../../js/components/LoadingMarker.vue';

const FORMATS = ['landscape', 'portrait', 'square'];

const store = useStore();
const { openModal } = useModal();
const {
    fetchTemplateCategories,
    fetchTemplates,
    page,
    resetDataForTemplates,
    templateCategories,
    templates,
    templatesLoading,
    totalPages
} = useTemplates(store);

const templateFilterText = ref('');
const selectedTemplateCategory = ref('all');
const selectedTemplateFormat = ref('landscape');

const selectedBrandId = ref(null);

const options = computed(() => ({
    page: page.value,
    per_page: 25,
    search: templateFilterText.value,
    category: selectedTemplateCategory.value,
    format: selectedTemplateFormat.value,
    sort: 'created_at',
    direction: 'desc'
}));

watch(page, () => {
    fetchTemplates(selectedBrandId.value, options.value);
});

watch([selectedTemplateCategory, selectedTemplateFormat, selectedBrandId], () => {
    if (selectedBrandId.value !== null) {
        resetDataForTemplates();
        fetchTemplates(selectedBrandId.value, options.value);
    }
});

watchEffect(() => {
    if (selectedBrandId.value) fetchTemplateCategories(selectedBrandId.value);
});

const debouncedFetchTemplates = debounce((brandId, options) => {
    fetchTemplates(brandId, options);
}, 500);

watch(templateFilterText, () => {
    if (selectedBrandId.value !== null) {
        resetDataForTemplates();
        templatesLoading.value = true;
        debouncedFetchTemplates(selectedBrandId.value, options.value);
    }
});

const createVideo = () => {
    openModal('CreateVideoModal');
};

const createVideoFromTemplate = (template) => {
    openModal('UseTemplateModal', { template, selectedBrandId: selectedBrandId.value });
};
</script>

<style lang="scss">
@import '@/sass/views/home-view.scss';
.template-loader-container {
    display: flex;
    justify-content: center;
    align-items: end;
    width: 100%;
    height: 100%;
}
</style>
