/**
  -- Component name: TranslationBTT
  -- Type: Background animation
  -- Uses: 
  -- Categories: background
  -- Tags: translation
 */

import { Background } from '../../constants';
import gsap from 'gsap';

class TranslationBTT {
    static animate(background, data) {
        let t = gsap.timeline();
        t.set(background, { scale: Background.ANIMATION_ZOOM_SCALE_DEFAULT, transformOrigin: '50% 0' });
        t.fromTo(
            background,
            { yPercent: 0 },
            { duration: 1, yPercent: (1 - Background.ANIMATION_ZOOM_SCALE_DEFAULT) * 100 }
        );
        return t;
    }
}

export default TranslationBTT;
