/**
 * Video Studio
 * getLoadingProductionSequence function
 */

import { Background, Color, Dimension, Duration, Overlay, Panel, Production, Sound, Transition } from '../constants';

const getLoadingProductionSequence = () => ({
    id: Production.LOADING_SEQUENCE_ID,
    name: Production.LOADING_SEQUENCE_NAME,
    transition: {
        type: Transition.NONE,
        color: {
            start: '',
            second: '',
            end: '',
            angle: 0
        },
        scene: false
    },
    background: {
        type: Background.LOADING_PRODUCTION_TYPE,
        color: {
            start: '',
            end: '',
            angle: 0
        },
        image: {
            src: ''
        },
        video: {
            src: '',
            captions: false,
            volume: {
                value: null,
                custom: null
            },
            fade: {
                value: null,
                custom: null
            }
        },
        collage: {
            animated: false,
            images: {
                image1: '',
                image2: '',
                image3: '',
                image4: ''
            }
        },
        blur: false,
        animation: {
            type: '',
            zoomTarget: null,
            zoomTargetX: null,
            zoomTargetY: null
        },
        mapZoom: {
            coords: null,
            marker: {
                enabled: false,
                src: ''
            }
        },
        overlay: {
            enabled: false,
            type: Overlay.DEFAULT,
            color: {
                start: Color.NONE,
                end: Color.NONE,
                angle: Color.GRADIENT_ANGLE_DEFAULT
            },
            opacity: Color.BACKGROUND_OPACITY_DEFAULT,
            animation: {
                enabled: true,
                start: Duration.START_DEFAULT,
                end: Duration.END_DEFAULT
            }
        }
    },
    panel: {
        enabled: true,
        shape: {
            category: Panel.SIDE_CATEGORY,
            type: Panel.STANDARD,
            src: ''
        },
        color: {
            start: Color.WHITE,
            end: '',
            angle: 0
        },
        opacity: Color.OPACITY_FULL,
        size: {
            width: Dimension.FULL,
            height: Dimension.FULL,
            ratioConstraint: false
        },
        position: {
            side: null,
            alignH: null,
            alignV: null,
            alignX: null,
            alignY: null
        },
        animation: {
            enabled: false,
            start: Production.LOADING_SEQUENCE_DURATION,
            end: Duration.END_DEFAULT,
            enabledBgMove: false
        }
    },
    visuals: [],
    messages: [],
    audio: {
        volume: {
            value: null,
            custom: null
        },
        fade: {
            value: null,
            custom: null
        },
        track: {
            src: '',
            captions: false,
            volume: {
                value: null,
                custom: null
            },
            start: {
                value: null,
                custom: null
            }
        },
    },
    tts: {
        audio: {
            src: '',
            volume: {
                value: Sound.VOLUME_DEFAULT,
                custom: Sound.VOLUME_EMPTY
            },
            start: {
                value: Duration.START_DEFAULT,
                custom: Duration.NONE
            }
        },
        voice: {
            src: '',
            volume: {
                value: Sound.VOLUME_DEFAULT,
                custom: Sound.VOLUME_EMPTY
            },
            start: {
                value: Duration.START_DEFAULT,
                custom: Duration.NONE
            }
        }
    },
    options: {
        duration: Production.LOADING_SEQUENCE_DURATION,
        logo: {
            enabled: false
        },
        footer: {
            text: '',
            align: null,
            start: {
                value: null,
                custom: null
            }
        },
        hidden: false
    }
});

export default getLoadingProductionSequence;
