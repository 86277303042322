import { useStore } from 'vuex';
import {
    RECORDING_TYPE_SCREEN,
    RECORDING_TYPE_CAMERA,
    RECORDING_TYPE_BOTH,
    RECORDING_TYPE_MICROPHONE
} from '@/js/constants';

export function useRecording() {
    const store = useStore();

    const openRecordDeviceModal = ({
        sequenceId = null,
        selector = null,
        defaultType = null,
        excludedRecordingTypes = []
    }) => {
        store.commit('ui/modals/add', {
            id: 'device-recorder',
            type: 'modal-record-device',
            sequenceId,
            selector,
            defaultRecordingType: defaultType || RECORDING_TYPE_SCREEN,
            excludedRecordingTypes
        });
    };

    const openRecordDeviceModalForType = ({
        type = null,
        sequenceId = null,
        selector = null,
        excludedTypes = null
    }) => {
        openRecordDeviceModal({
            sequenceId,
            selector,
            defaultType: type || RECORDING_TYPE_SCREEN,
            excludedRecordingTypes:
                excludedTypes ??
                (type == RECORDING_TYPE_MICROPHONE
                    ? [RECORDING_TYPE_SCREEN, RECORDING_TYPE_CAMERA, RECORDING_TYPE_BOTH]
                    : [RECORDING_TYPE_BOTH, RECORDING_TYPE_MICROPHONE])
        });
    };

    return {
        openRecordDeviceModal,
        openRecordDeviceModalForType
    };
}
