import CGSApiService from '@/js/infrastructure/api/cgs/CGSApiService.js';
import CGSTextDTO from '@/js/dto/CGSTextDTO.js';

export default class CGSDataService {
    constructor(store) {
        this.fetchCGSApiService = new CGSApiService(store);
    }

    async fetchAll() {
        let data = (await this.fetchCGSApiService.fetchAll()).data;
        return data.map((cgs) => new CGSTextDTO(cgs));
    }

    async fetchById(id) {
        let data = (await this.fetchCGSApiService.fetchById(id)).data;
        return new CGSTextDTO(data);
    }

    async postReply(params) {
        return (await this.fetchCGSApiService.postReply(params)).data;
    }
}
