<!--
  -- Component name: CW1
  -- Type: Transition
  -- Uses: color.start,color.second
  -- Tags: cushman-wakefield
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        v-if="landscapeFormat"
        :class="classes"
        :id="this.containerID"
        viewBox="0 0 1920 1080"
        preserveAspectRatio="xMinYMin slice"
    >
        <polygon
            class="polygon3"
            :fill="state.color.start"
            points="492.28 0.34 3713.45 0.34 3216.14 1080.34 0.17 1080.34 492.28 0.34"
        />
        <polygon
            class="polygon2"
            :fill="state.color.second"
            fill-opacity="0.75"
            points="0 0.34 3014.84 0.34 4347.3 1080.34 1335.76 1080.34 0 0.34"
        />
        <polygon class="polygon1" fill="#fff" points="1920.45 0 0 481.35 0 2911.38 1920.45 2426.07 1920.45 0" />
    </svg>
    <svg
        class="studio-sequence-transition studio-container"
        v-else-if="squareFormat"
        :class="classes"
        :id="this.containerID"
        viewBox="0 0 1000 1000"
        preserveAspectRatio="xMinYMin slice"
    >
        <polygon
            class="polygon3"
            :fill="state.color.start"
            points="455.72 0.34 1599.65 0.78 1144.1 1000.55 0.17 1000.12 455.72 0.34"
        />
        <polygon
            class="polygon2"
            :fill="state.color.second"
            fill-opacity="0.75"
            points="0 0.34 1569.64 0.78 2806.18 1000.55 1236.54 1000.12 0 0.34"
        />
        <polygon class="polygon1" fill="#fff" points="1000.36 0 0 250.74 0 1516.54 1000.36 1263.74 1000.36 0" />
    </svg>
    <svg
        class="studio-sequence-transition studio-container"
        v-else-if="portraitFormat"
        :class="classes"
        :id="this.containerID"
        viewBox="0 0 1080 1920"
        preserveAspectRatio="xMinYMin slice"
    >
        <polygon
            class="polygon3"
            :fill="state.color.start"
            points="581.38 1.7 1918.24 1.7 1337.09 1924.7 0.23 1924.7 581.38 1.7"
        />
        <polygon
            class="polygon2"
            :fill="state.color.second"
            fill-opacity="0.75"
            points="0 3.7 1588.46 3.53 3333 1923.7 1744.38 1923.7 0 3.7"
        />
        <polygon class="polygon1" fill="#fff" points="1080.45 0 0.4 270.71 0.4 2699.01 1080.45 2426.07 1080.45 0" />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: true, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true, // The transition component is placed above the in and out elements
            // swapElements: true, // The in and out parent elements are swapped to have the out elements above the in elements
            inElementsAutoAppear: false // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {
        landscapeFormat() {
            return this.format.width > this.format.height;
        },

        portraitFormat() {
            return this.format.width < this.format.height;
        },

        squareFormat() {
            return this.format.width === this.format.height;
        }
    },

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            t.fromTo('#' + this.containerID + ' .polygon1', { yPercent: -100 }, { yPercent: 100, duration: 1 });
            t.fromTo('#' + this.containerID + ' .polygon2', { xPercent: -100 }, { xPercent: 100, duration: 1 }, 0.1);
            t.fromTo(
                '#' + this.containerID + ' .polygon3',
                { xPercent: 100 },
                { xPercent: -100, duration: 1, ease: 'power2.out' },
                0.45
            );
            t.set([this.backgroundInElement, this.sequenceInElement], { autoAlpha: 1 }, 0.5);
            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 }, 0.5);
            return t;
        }
    }
};
</script>
