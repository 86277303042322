/**
 * UI modals Vuex store
 */
import { v4 as uuidv4 } from 'uuid';

const state = {
    list: []
};

const getters = {
    indexOf: (state) => (id) => state.list.findIndex((modal) => modal.id == id),

    find: (state, getters) => (id) => {
        let index = getters.indexOf(id);
        return index != -1 ? state.list[index] : null;
    }
};

const mutations = {
    add(state, modal) {
        modal.id += `-${uuidv4()}`; // add random UUID to modal ID to avoid duplicate
        state.list.push(modal);
    },

    remove(state, id) {
        let index = state.list.findIndex((modal) => modal.id == id);
        if (index != -1) state.list.splice(index, 1);
    }
};

const actions = {
    openRefreshLoginModal({ rootState, commit }, data) {
        commit(
            'ui/modals/add',
            {
                id: 'login-refresh',
                type: 'ui-login-refresh-modal',
                url: rootState.ui.urls.refreshLogin,
                ...data
            },
            { root: true }
        );
    }
};

export default {
    namespaced: true,

    modules: {
        //
    },

    state,
    getters,
    mutations,
    actions
};
