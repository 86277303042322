<!--
  -- Component name: CenterToRight1
  -- Type: Transition
  -- Uses: color.start
  -- Tags: color
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <path
            class="center-to-right-transition-11"
            :fill="state.color.start"
            d="M-330-430.8L496.1-575l323.5 1815.9L-6.6 1389z"
        />
        <path
            class="center-to-right-transition-12"
            :fill="state.color.start"
            d="M491-535.4l826.1-144.1 323.5 1815.9-826.1 148.1z"
        />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            isAbove: true
        };
    },

    computed: {},

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 }, 0.001);
            t.to('#' + this.containerID + ' .center-to-right-transition-11', 0.2, { xPercent: -50 }, 0.2);
            t.to('#' + this.containerID + ' .center-to-right-transition-12', 0.2, { xPercent: 50 }, 0.2);
            return t;
        }
    }
};
</script>
