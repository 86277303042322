<!--
  -- Component name: SG1
  -- Type: Transition
  -- Uses: 
  -- Tags: sg
  -->
<template>
    <svg
        ref="$sgLogo"
        class="studio-sequence-transition studio-container"
        v-if="landscapeFormat"
        :class="classes"
        :id="this.containerID"
        viewBox="0 0 1920 1080"
        preserveAspectRatio="xMidYMid slice"
    >
        <g class="full-logo" transform="translate(0)">
            <g class="sg-logo">
                <rect x="0" y="0" :width="landscapeSize" :height="landscapeSize" fill="#fff" />
                <rect
                    :x="borderSize"
                    :y="borderSize"
                    :width="landscapeSize - borderSize * 2"
                    :height="landscapeSize / 2 - borderSize"
                    fill="#e9041e"
                />
                <rect
                    :x="borderSize"
                    :y="landscapeSize / 2"
                    :width="landscapeSize - borderSize * 2"
                    :height="landscapeSize / 2 - borderSize"
                    fill="#000"
                />
            </g>
            <rect
                :x="(landscapeSize - borderSize * 2) / 2 - logoLineWidth / 2 + borderSize"
                :y="landscapeSize / 2 - borderSize / 2"
                :width="logoLineWidth"
                :height="borderSize"
                fill="#fff"
                class="sg-logo-line"
            />
        </g>
    </svg>
    <svg
        ref="$sgLogo"
        class="studio-sequence-transition studio-container"
        v-else-if="portraitFormat"
        :class="classes"
        :id="this.containerID"
        viewBox="0 0 1080 1920"
        preserveAspectRatio="xMidYMid slice"
    >
        <g class="full-logo" transform="translate(0)">
            <g class="sg-logo">
                <rect x="0" y="0" :width="portraitSize" :height="portraitSize" fill="#fff" />
                <rect
                    :x="borderSize"
                    :y="borderSize"
                    :width="portraitSize - borderSize * 2"
                    :height="portraitSize / 2 - borderSize"
                    fill="#e9041e"
                />
                <rect
                    :x="borderSize"
                    :y="portraitSize / 2"
                    :width="portraitSize - borderSize * 2"
                    :height="portraitSize / 2 - borderSize"
                    fill="#000"
                />
            </g>
            <rect
                :x="(portraitSize - borderSize * 2) / 2 - logoLineWidth / 2 + borderSize"
                :y="portraitSize / 2 - borderSize / 2"
                :width="logoLineWidth"
                :height="borderSize"
                fill="#fff"
                class="sg-logo-line"
            />
        </g>
    </svg>
    <svg
        ref="$sgLogo"
        class="studio-sequence-transition studio-container"
        v-else-if="squareFormat"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 1000"
        preserveAspectRatio="xMidYMid slice"
    >
        <g class="full-logo" transform="translate(0)">
            <g class="sg-logo">
                <rect x="0" y="0" :width="squareSize" :height="squareSize" fill="#fff" />
                <rect
                    :x="borderSize"
                    :y="borderSize"
                    :width="squareSize - borderSize * 2"
                    :height="squareSize / 2 - borderSize"
                    fill="#e9041e"
                />
                <rect
                    :x="borderSize"
                    :y="squareSize / 2"
                    :width="squareSize - borderSize * 2"
                    :height="squareSize / 2 - borderSize"
                    fill="#000"
                />
            </g>
            <rect
                :x="(squareSize - borderSize * 2) / 2 - logoLineWidth / 2 + borderSize"
                :y="squareSize / 2 - borderSize / 2"
                :width="logoLineWidth"
                :height="borderSize"
                fill="#fff"
                class="sg-logo-line"
            />
        </g>
    </svg>
</template>

<style scoped>
.full-logo {
    will-change: transform;
}
</style>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            isAbove: true,
            borderSize: 10,

            landscapeSize: 415,
            portraitSize: 370,
            squareSize: 360
        };
    },

    computed: {
        landscapeFormat() {
            return this.format.width > this.format.height;
        },

        portraitFormat() {
            return this.format.width < this.format.height;
        },

        squareFormat() {
            return this.format.width === this.format.height;
        },

        size() {
            return this.landscapeFormat
                ? this.landscapeSize
                : this.portraitFormat
                  ? this.portraitSize
                  : this.squareSize;
        },

        logoLineWidth() {
            return 0.6 * (this.size - this.borderSize);
        }
    },

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            const t = gsap.timeline();

            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 }, 0.001);
            t.set(this.$refs.$sgLogo.querySelector('.full-logo'), {
                transform:
                    'translateX(50%) translateX(-' +
                    this.size / 2 +
                    'px) translateY(50%) translateY(-' +
                    this.size / 2 +
                    'px)'
            });

            t.to('#' + this.containerID + ' .sg-logo', { autoAlpha: 0, duration: 1 });
            t.to('#' + this.containerID + ' .sg-logo-line', { x: '300%', duration: 0.8, ease: 'power1.in' }, 0.2);

            return t;
        }
    }
};
</script>
