/**
 * Edit video Sequences Vuex store extension
 */

import _merge from 'lodash/merge';
import _mergeWith from 'lodash/mergeWith';
import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';
import { Visual } from 'cte-video-studio';
import { filterSequenceStateForCache } from '@/js/videos/utils';
import orderedList from './orderedList.js';
import sequence, { messageStoreConfig, visualStoreConfig } from './sequence/index.js';

// Initial state
const state = {
    ...orderedList.state(),
    id: 'sequences',
    path: ['sequences'],
    cached: {}
};

// Getters
const getters = {
    ...orderedList.getters,

    allVisible: (state, getters) => getters.all.filter((sequence) => !sequence.options.hidden)
};

// Mutations
const mutations = {
    ...orderedList.mutations,

    setCached(state, cached) {
        state.cached = _merge({}, cached);
    }
};

// Actions
const actions = {
    ...orderedList.actions,

    addEmptySequence({ dispatch }, data) {
        // data: { id, index }
        let seq = sequence;
        dispatch('addItem', {
            module: true,
            id: data.id,
            item: seq,
            index: data.index
        });
        dispatch(data.id + '/initDefaults');
    },

    addSequence({ dispatch }, data) {
        // data: { id, item, index }
        let id = data.id || data.item.id;
        dispatch('addEmptySequence', {
            id,
            index: data.index
        });
        dispatch(id + '/init', data.item);
    },

    removeSequence({ commit, getters }, index) {
        let seq = getters.childAt(index);
        if (seq) {
            commit('remove', {
                module: true,
                path: seq.path
            });
        }
    },

    moveSequence({ commit }, data) {
        // data: { id, index }
        commit('move', data);
    },

    clearState({ dispatch, state }) {
        Object.values(Object.assign({}, state.order)).forEach((seqId) => {
            dispatch(seqId + '/removeSelf');
        });
    },

    resetState({ commit, state, rootState }) {
        let currentState = _merge({}, state),
            originalState = _merge({}, rootState.ui.history.originalState().sequences);

        let addedSequences = currentState.order.filter((seqId) => !originalState.order.includes(seqId)),
            removedSequences = originalState.order.filter((seqId) => !currentState.order.includes(seqId)),
            presentSequences = currentState.order.filter((seqId) => originalState.order.includes(seqId));

        commit(
            'setCached',
            addedSequences.reduce((sequences, seqId) => {
                sequences[seqId] = filterSequenceStateForCache(currentState[seqId]);
                return sequences;
            }, {})
        );

        state.order = originalState.order;

        // Unregister added modules since original state
        addedSequences.forEach((seqId) => {
            if (this.hasModule(currentState[seqId].path)) {
                this.unregisterModule(currentState[seqId].path);
            }
        });

        // Register removed modules since original state
        removedSequences.forEach((seqId) => {
            if (!this.hasModule(originalState[seqId].path)) {
                this.registerModule(originalState[seqId].path, sequence.state);

                originalState[seqId].order.forEach((elementId) => {
                    if (!this.hasModule(originalState[seqId][elementId].path)) {
                        let elementStoreConfig = RegExp('^' + Visual.PREFIX_ID).test(elementId)
                            ? visualStoreConfig
                            : messageStoreConfig;
                        this.registerModule(originalState[seqId][elementId].path, elementStoreConfig);
                    }
                });

                state[seqId] = _merge({}, state[seqId], originalState[seqId]);
                state[seqId].order = originalState[seqId].order;
            }
        });

        presentSequences.forEach((seqId) => {
            let addedElements = currentState[seqId].order.filter(
                    (elementId) => !originalState[seqId].order.includes(elementId)
                ),
                removedElements = originalState[seqId].order.filter(
                    (elementId) => !currentState[seqId].order.includes(elementId)
                );

            addedElements.forEach((elementId) => {
                if (this.hasModule(currentState[seqId][elementId].path)) {
                    this.unregisterModule(currentState[seqId][elementId].path);
                }
            });

            removedElements.forEach((elementId) => {
                if (!this.hasModule(originalState[seqId][elementId].path)) {
                    let elementStoreConfig = RegExp('^' + Visual.PREFIX_ID).test(elementId)
                        ? visualStoreConfig
                        : messageStoreConfig;
                    this.registerModule(originalState[seqId][elementId].path, elementStoreConfig);
                }
            });

            state[seqId] = _mergeWith({}, state[seqId], originalState[seqId], (objValue, srcValue, key) => {
                // exemple useful for timerangeSegments array
                if (_isArray(objValue) && objValue.every((el) => _isObject(el))) return srcValue;

                //remove if captions is an array of objects
                if (key == 'captions') return srcValue;
            });
            state[seqId].order = originalState[seqId].order;
        });
    },

    endResetState({ commit, state }) {
        Object.keys(state.cached).forEach((seqId) => {
            if (!!state[seqId]) {
                state[seqId] = _merge({}, state[seqId], state.cached[seqId]);
            }
        });

        commit('setCached', {});
    }
};

export default {
    ...orderedList,

    state,
    getters,
    mutations,
    actions
};
