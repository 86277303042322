<template>
    <div class="ui-text-editor" :class="containerClasses">
        <label class="ui-text-label" :class="labelClasses" :for="editorId" :aria-hidden="!showLabel">
            <slot>{{ label }}</slot>
        </label>
        <div class="ui-text-editor-input" :class="editorClasses" :style="editorComputedStyles" :spellcheck="false">
            <editor-content :editor="editor" :id="editorId" class="ui-text-editor-element" />
            <div v-if="editor" class="ui-text-editor-toolbar">
                <ui-dropdown
                    v-if="enableFontSize"
                    :id="editorId + '-tool-font-size'"
                    class="font-size-dropdown"
                    :model-value="currentFontSize"
                    :select="true"
                    :caret="false"
                    :scrollable="false"
                    :editable="true"
                    :editable-reg-exp="/^\d+(?:(?:\.|,)\d+)?$/"
                    :default-label="$t('Auto')"
                    toggleClass="ui-simple-button"
                    :disabled="disabled"
                    :tooltip="$t('Font size')"
                    @[dropdownChangeEvent]="handleFontSizeChange"
                >
                    <template #dropdown-toggle="{ instance }">
                        {{ Math.round(instance.selectedValue) || $t('Auto') }}
                    </template>
                    <ui-dropdown-item key="size-auto" :label="$t('Auto')" />
                    <ui-dropdown-item
                        v-for="scale in fontScaleValues"
                        :key="'scale-' + scale"
                        :value="scale * fontSizeReference"
                    />
                </ui-dropdown>

                <ui-dropdown
                    :id="editorId + '-tool-align'"
                    class="align-dropdown"
                    :model-value="currentAlign"
                    :select="true"
                    :caret="false"
                    :scrollable="false"
                    :default-label="$t('Alignment')"
                    toggleClass="ui-simple-button"
                    menu-placement="bottom-start"
                    :disabled="disabled"
                    :tooltip="$t('Alignment')"
                    @[dropdownChangeEvent]="handleAlignChange"
                >
                    <template #dropdown-toggle="{ instance }">
                        <fa-icon class="icon" icon="fa-solid fa-align-left" />
                    </template>
                    <ui-dropdown-item
                        v-for="(alignLabel, alignValue) in availableAlignmentTypes"
                        :key="alignValue"
                        :value="alignValue"
                        :label="alignLabel"
                    >
                        <fa-icon class="icon" :icon="'fa-solid fa-align-' + alignValue" />
                    </ui-dropdown-item>
                </ui-dropdown>

                <div class="separator"></div>

                <button
                    class="ui-simple-button"
                    :class="{ selected: editor.isActive('bold') }"
                    :disabled="disabled"
                    :title="$t('Bold')"
                    v-tooltip="$t('Bold')"
                    @click="editor.chain().focus().toggleBold().run()"
                >
                    <span class="visually-hidden">{{ $t('Bold') }}</span>
                    <fa-icon class="icon" icon="fa-solid fa-bold" />
                </button>
                <button
                    class="ui-simple-button"
                    :class="{ selected: editor.isActive('italic') }"
                    :disabled="disabled"
                    :title="$t('Italic')"
                    v-tooltip="$t('Italic')"
                    @click="editor.chain().focus().toggleItalic().run()"
                >
                    <span class="visually-hidden">{{ $t('Italic') }}</span>
                    <fa-icon class="icon" icon="fa-solid fa-italic" />
                </button>

                <div class="text-color-tool">
                    <button
                        class="ui-simple-button"
                        :class="colorToolClasses"
                        :style="{ color: !disabled ? editorCurrentColor : null }"
                        :disabled="disabled"
                        :title="$t('Text color')"
                        v-tooltip="$t('Text color')"
                        @click="editor.chain().focus().setColor(editorCurrentColor).run()"
                    >
                        <span class="visually-hidden">{{ $t('Text color') }}</span>
                        <fa-icon class="icon" icon="fa-solid fa-font" />
                    </button>
                    <ui-color-selector
                        :id="editorId + '-text-color'"
                        :color="currentColor"
                        :palette="brandPalette"
                        :enable-other-colors="brandEnableOtherColors"
                        :label="$t('Choose a text color')"
                        :showLabel="false"
                        :disabled="disabled"
                        @[colorChangeEvent]="handleColorChange"
                    />
                </div>

                <button
                    class="ui-simple-button"
                    :disabled="disabled"
                    :title="$t('Clear formatting')"
                    v-tooltip="$t('Erase style')"
                    @click="editor.chain().focus().unsetAllMarks().run()"
                >
                    <span class="visually-hidden">{{ $t('Clear formatting') }}</span>
                    <fa-icon class="icon" icon="fa-solid fa-eraser" />
                </button>

                <!--<ui-dropdown :id="editorId+'-tool-emoji'" ref="$emojiDropdown" class="emoji-dropdown" :menu="false" :caret="false" :scrollable="false"
                             :default-label="$t('Emoji')" toggleClass="ui-simple-button" menu-placement="bottom-end"
                             :disabled="disabled">
                    <template #dropdown-toggle="{ instance }">
                        <svg-icon icon="editor-emoji-icon" />
                    </template>
                    <ui-emoji-picker :id="editorId+'-emoji-picker'" :disabled="disabled" @[emojiSelectEvent]="insertEmoji" />
                </ui-dropdown>-->

                <!-- TODO: temporary solution only -->
                <button
                    class="ui-simple-button"
                    :disabled="disabled"
                    :title="$t('Emoji')"
                    v-tooltip="$t('Emojis')"
                    @click="openAcademyEmojiList"
                >
                    <span class="visually-hidden">{{ $t('Emoji') }}</span>
                    <fa-icon class="icon" icon="fa-solid fa-face-grin-wide" />
                </button>

                <div v-if="editorHasMarks" class="separator"></div>

                <button
                    v-if="showHighlightTool"
                    class="ui-simple-button ui-special-action-button"
                    :class="highlightToolClasses"
                    :disabled="disabled"
                    :title="$t('Highlight')"
                    @click="toggleHighlight"
                >
                    <span class="visually-hidden">{{ $t('Highlight') }}</span>
                    <fa-icon class="icon" icon="fa-solid fa-highlighter" />
                </button>
                <button
                    v-if="showSeparatorTool"
                    class="ui-simple-button ui-special-action-button"
                    :disabled="disabled || !canInsertHighlightGroupSeparator"
                    :title="$t('Line separator')"
                    @click="insertSeparator"
                >
                    <span class="visually-hidden">{{ $t('Line separator') }}</span>
                    <svg-icon icon="line-separator-icon" />
                </button>
                <button
                    v-if="!!marks.highlightGroup"
                    class="ui-simple-button ui-special-action-button"
                    :disabled="disabled || canInsertHighlightGroupSeparator"
                    :title="$t('Insert an example')"
                    @click="insertHighlightGroupTemplate"
                >
                    <span class="visually-hidden">{{ $t('Insert an example') }}</span>
                    <fa-icon class="icon" icon="fa-regular fa-circle-question" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import tinycolor from 'tinycolor2';
import { Editor, EditorContent } from '@tiptap/vue-3';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import HardBreak from '@tiptap/extension-hard-break';
import Text from '@tiptap/extension-text';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import History from '@tiptap/extension-history';
import TextStyle from '@tiptap/extension-text-style';
import { Color as TextColor } from '@tiptap/extension-color';
import {
    TextColorContrast,
    EmojiReplacer,
    StudioMarkers,
    parseStudioMessage,
    toStudioMessage
} from '../extensions/tiptap';
import _debounce from 'lodash/debounce';
import _escapeRegExp from 'lodash/escapeRegExp';
import { Color, Message } from 'cte-video-studio';
import UiColorSelector, { UI_COLOR_SELECTOR_CHANGE } from './UiColorSelector.vue';
import UiDropdown, { UI_DROPDOWN_SELECT_CHANGE } from './UiDropdown.vue';
import UiDropdownItem from './UiDropdownItem.vue';
import UiEmojiPicker, { UI_EMOJI_PICKER_SELECT } from './UiEmojiPicker.vue';
import UiIcon from './UiIcon.vue';
import { UsesTooltip } from '../mixins';
import i18n from '@/libs/i18n.js';
import Align from '@/js/video-studio/constants/align.js';

export const UI_TEXT_EDITOR_CHANGE = 'update:modelValue';
export const UI_TEXT_EDITOR_FONT_SIZE_CHANGE = 'ui-text-editor-font-size-change';
export const UI_TEXT_EDITOR_ALIGN_CHANGE = 'ui-text-editor-align-change';
export const UI_TEXT_EDITOR_PREVIEW_CHANGE = 'ui-text-editor-preview-change';

const TEXT_EDITOR_DEFAULT_FONT_SCALES = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2];
const EMIT_EDITOR_CHANGE_DELAY = 1500;

export default {
    mixins: [UsesTooltip],

    components: {
        EditorContent,
        UiColorSelector,
        UiDropdown,
        UiDropdownItem,
        UiEmojiPicker,
        UiIcon
    },

    emits: [
        UI_TEXT_EDITOR_CHANGE,
        UI_TEXT_EDITOR_FONT_SIZE_CHANGE,
        UI_TEXT_EDITOR_ALIGN_CHANGE,
        UI_TEXT_EDITOR_PREVIEW_CHANGE
    ],

    props: {
        id: {
            type: String
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        defaultValue: {
            type: [String, Number],
            default: ''
        },
        messageType: {
            type: String,
            default: Message.DEFAULT
        },
        previewing: {
            type: Boolean,
            default: false
        },
        enableFontSize: {
            type: Boolean,
            default: true
        },
        fontSize: {
            type: [String, Number],
            default: ''
        },
        align: {
            type: String,
            default: 'left'
        },
        enableAlignJustify: {
            type: Boolean,
            default: true
        },
        palette: {
            type: Object,
            default: () => ({})
        },
        marks: {
            type: Object,
            default: () => ({})
        },
        horizontal: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        editorStyles: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        return {
            editor: null,
            hasFocus: false,
            dropdownChangeEvent: UI_DROPDOWN_SELECT_CHANGE,
            colorChangeEvent: UI_COLOR_SELECTOR_CHANGE,
            emojiSelectEvent: UI_EMOJI_PICKER_SELECT,
            fontScaleValues: TEXT_EDITOR_DEFAULT_FONT_SCALES,
            fontSizeReference: Message.FONT_SIZE_DEFAULT,
            alignmentTypes: Align.TEXT_ALIGNMENTS,
            currentFontSize: this.fontSize,
            currentAlign: this.align,
            currentColor: {
                ref: Color.BLACK,
                value: Color.BLACK
            },
            highlight: true
        };
    },

    computed: {
        ...mapState({
            prefixes: (state) => state.ui.prefixes,
            brandPalette: (state) => state.branding.palette,
            brandEnableOtherColors: (state) => state.branding.enableOtherColors,
            disableRightAlignment: (state) => state.ui.restrictions.disableRightAlignment
        }),

        containerClasses() {
            return {
                horizontal: this.horizontal,
                disabled: this.disabled
            };
        },

        labelClasses() {
            return {
                'visually-hidden': !this.showLabel
            };
        },

        editorId() {
            return this.id + '-text-editor';
        },

        editorClasses() {
            return [
                'align-' + this.currentAlign,
                {
                    focus: this.hasFocus
                }
            ];
        },

        editorComputedStyles() {
            let styles = { ...this.editorStyles };

            Object.keys(this.brandPalette).reduce((vars, color) => {
                if (!!this.brandPalette[color]) {
                    vars['--brand-palette-' + color] = this.brandPalette[color];
                }
                return vars;
            }, styles);
            Object.keys(this.palette).reduce((vars, color) => {
                if (!!this.palette[color]) {
                    vars['--palette-' + color] = this.palette[color];
                }
                return vars;
            }, styles);

            if (this.marks.highlightMark) {
                styles['--highlight-bg-color'] = this.palette.color1 || null;
                styles['--highlight-color'] = this.palette.color2 || null;
            }

            return styles;
        },

        editorCurrentColor() {
            return this.getResolvedColorProperty(this.currentColor);
        },

        colorToolClasses() {
            return {
                'non-readable-color': !tinycolor.isReadable(this.editorCurrentColor, '#fff')
            };
        },

        editorHasMarks() {
            return !!Object.keys(this.marks).length;
        },

        showHighlightTool() {
            return !!this.marks.highlightGroup || !!this.marks.highlightMark;
        },

        highlightToolClasses() {
            return {
                selected:
                    (!!this.marks.highlightGroup && this.editor.isActive('highlightGroup')) ||
                    (!!this.marks.highlightMark && this.editor.isActive('highlightMark'))
            };
        },

        showSeparatorTool() {
            return !!this.marks.highlightGroup || !!this.marks.lineSeparator;
        },

        canInsertHighlightGroupSeparator() {
            return !this.marks.highlightGroup || this.editor.can().insertHighlightGroupSeparator();
        },

        availableAlignmentTypes() {
            return Object.fromEntries(
                this.alignmentTypes
                    .filter(
                        (alignValue) =>
                            (this.enableAlignJustify || alignValue !== Align.TEXT_JUSTIFY) &&
                            (!this.disableRightAlignment || alignValue !== Align.TEXT_RIGHT)
                    )
                    .map((alignValue) => [alignValue, this.$t('studio.text_alignments.' + alignValue)])
            );
        }
    },

    watch: {
        modelValue() {
            let parsedContent = this.getParsedContent();
            if (parsedContent !== this.editor.getHTML() && !this.previewing) {
                let { $from, $to } = this.editor.state.selection;
                this.editor
                    .chain()
                    .setContent(parsedContent, false, { preserveWhitespace: true })
                    .setTextSelection({ from: $from.pos, to: $to.pos })
                    .run();
            }
        },

        previewing(newValue) {
            if (!newValue) {
                if (this.messageType == this._savedMessageType) {
                    this.emitPreviewChangeEvent(this._savedValue);
                } else {
                    this.editor.commands.setContent(this.getParsedContent(), false, { preserveWhitespace: true });
                }
            } else {
                this._savedValue = this.modelValue;
                this._savedMessageType = this.messageType;
            }
        },

        messageType() {
            if (!this.previewing) {
                if (this.messageType != this._savedMessageType) {
                    this.editor.commands.setContent(this.getParsedContent(), false, { preserveWhitespace: true });
                }
                this._savedValue = null;
                this._savedMessageType = null;
            } else {
                this.emitPreviewChangeEvent();
            }
        },

        fontSize(newValue) {
            this.currentFontSize = newValue;
        },

        align(newValue) {
            this.currentAlign = newValue;
        },

        disabled(newValue) {
            this.editor.setEditable(!newValue);
        }
    },

    methods: {
        getBrandPaletteReference(key) {
            return this.prefixes.settingsReference + 'palette.' + key;
        },

        getResolvedColorProperty({ ref, value }) {
            let refMatches = RegExp(
                '^' + _escapeRegExp(this.prefixes.settingsReference) + 'palette\\.(color\\d+)'
            ).exec(ref);

            return !!refMatches && refMatches[1] ? 'var(--brand-palette-' + refMatches[1] + ')' : value;
        },

        getParsedContent() {
            let parsedContent = /\S/.test(this.modelValue) ? this.modelValue : this.defaultValue;
            return parseStudioMessage(parsedContent, this.marks);
        },

        getRenderedContent() {
            let renderedContent = this.editor.getHTML();
            return toStudioMessage(renderedContent, this.marks);
        },

        handleFocus(event) {
            this.hasFocus = true;
        },

        handleBlur(event) {
            this.hasFocus = false;
        },

        emitChangeEvent() {
            this.$emit(UI_TEXT_EDITOR_CHANGE, this.getRenderedContent());
        },

        emitPreviewChangeEvent(value = null) {
            this.$emit(UI_TEXT_EDITOR_PREVIEW_CHANGE, value ?? this.getRenderedContent(), value != null);
        },

        handleFontSizeChange(fontSize) {
            this.currentFontSize = fontSize;
            this.$emit(UI_TEXT_EDITOR_FONT_SIZE_CHANGE, fontSize);
        },

        handleAlignChange(align) {
            this.currentAlign = align;
            console.log('align', align);
            this.$emit(UI_TEXT_EDITOR_ALIGN_CHANGE, align);
        },

        handleColorChange(color) {
            this.currentColor = color;

            let colorProperty = this.getResolvedColorProperty(color);
            this.editor.chain().focus().setColor(colorProperty).run();
        },

        insertEmoji(emoji) {
            this.editor.chain().focus().insertContent(emoji).run();
            this.$refs.$emojiDropdown.hide();
        },

        toggleHighlight() {
            if (this.showHighlightTool) {
                let highlightMethod = !!this.marks.highlightGroup ? 'toggleHighlightGroup' : 'toggleHighlight';
                this.editor.chain().focus()[highlightMethod]().run();
            }
        },

        insertSeparator() {
            if (this.showSeparatorTool && this.canInsertHighlightGroupSeparator) {
                let separatorMethod = !!this.marks.highlightGroup
                    ? 'insertHighlightGroupSeparator'
                    : 'insertLineSeparator';
                this.editor.chain().focus()[separatorMethod]().run();
            }
        },

        insertHighlightGroupTemplate() {
            if (!!this.marks.highlightGroup && !this.canInsertHighlightGroupSeparator) {
                this.editor
                    .chain()
                    .focus()
                    .insertHighlightGroupTemplate($t('First sentence'), this.$t('Second sentence'))
                    .run();
            }
        },

        openAcademyEmojiList() {
            window.open('https://academy.2emotion.com/emojis/', '_blank', 'noopener');
        }
    },

    mounted() {
        this._savedValue = null;
        this._savedMessageType = null;

        this.editor = new Editor({
            editable: !this.disabled,
            content: this.getParsedContent(),
            enableInputRules: false,
            enablePasteRules: false,
            parseOptions: {
                preserveWhitespace: true
            },
            extensions: [
                History,
                Document,
                Paragraph,
                StudioMarkers.configure(this.marks),
                HardBreak,
                TextStyle,
                TextColor,
                Bold,
                Italic,
                Text,
                TextColorContrast
                //EmojiReplacer,
            ],
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            onUpdate: _debounce(this.emitChangeEvent, EMIT_EDITOR_CHANGE_DELAY)
        });

        window.myEditor = this.editor;
    },

    beforeUnmount() {
        this.editor.destroy();
    }
};
</script>
