import { useStore } from 'vuex';
import { computed } from 'vue';
import { useHistory } from './useHistory';
import { useMedia } from './useMedia';
import { Duration, TTS } from 'cte-video-studio';
import { TTS_STATUS_PROCESSING } from '../../constants/index.js';

export function useVoiceOver(useInSequence, sequenceId, store = null) {
    if (!store) {
        store = useStore();
    }

    const { saveHistoryStep } = useHistory(store);

    const storeModulePath = computed(() => { 
        return useInSequence.value ? 'sequences/' + sequenceId.value + '/tts' : 'settings/tts';
    });

    const voiceOverState = computed(() => {
        return useInSequence.value ? store.state.sequences[sequenceId.value]?.tts : store.state.settings.tts;
    });

    const voiceOverMediaState = computed(() => store.getters[storeModulePath.value + '/media']);

    const voiceOverCategory = computed({
        get() {
            return voiceOverState.value.category;
        },
        set(value) {
            saveHistoryStep(() => {
                store.commit(storeModulePath.value + '/setCategory', value);
            });
        }
    });

    const isAudioCategory = computed(() => voiceOverCategory.value === TTS.AUDIO_CATEGORY);
    const isVoiceCategory = computed(() => voiceOverCategory.value === TTS.VOICE_CATEGORY);
    const isRecordingCategory = computed(() => voiceOverCategory.value === TTS.RECORDING_CATEGORY);
    const isNoneCategory = computed(() => voiceOverCategory.value === TTS.NONE_CATEGORY);

    const isProcessing = computed(() => {
        return voiceOverState.value.voice.status === TTS_STATUS_PROCESSING;
    });

    const { mediaModel: voiceOverMediaAudioModel } = useMedia(storeModulePath.value + '/audio', store);
    const { mediaModel: voiceOverRecordingModel } = useMedia(storeModulePath.value + '/recording', store);
    const { deleteSource: voiceOverDeleteVoiceSource } = useMedia(storeModulePath.value + '/voice',    store);

    const showTtsEditor = () => {
        store.dispatch('ui/showTTSEditor', {
            seqId: useInSequence.value ? sequenceId.value : null,
            useInSequence: useInSequence.value
        });
    };

    const timerange = computed({
        get: () => {
            const range = { start: 0, end: -1 };
            range.start = voiceOverMediaState.value?.timerange.start || 0;
            range.end = voiceOverMediaState.value?.timerange.end || -1;

            return range;
        },
        set: ({ start, end }) => {
            if (start !== timerange.value.start || end !== timerange.value.end) {
                saveHistoryStep(() => {
                    store.dispatch(storeModulePath.value + '/setTimerange', { start, end });
                });
            }
        }
    });

    const timerangeSegments = computed({
        get: () => {            
            return voiceOverMediaState.value.timerangeSegments || [];
        },
        set: (value) => {
            saveHistoryStep(() => {
                store.dispatch(storeModulePath.value + '/setTimerangeSegments', value);                
            });
        }
    });

    const playbackRate = computed({
        get: () => {
            return store.getters[storeModulePath.value + '/playbackRate'] || Duration.PLAY_BACK_RATE_DEFAULT;
        },
        set: (value) => {
            saveHistoryStep(() => {
                store.dispatch(storeModulePath.value + '/setPlaybackRate', value);
            });
        }
    });

    const voiceOverCaptions = computed({
        get: () => {
            return voiceOverMediaState.value.captions || [];
        },
        set: (value) => {
            saveHistoryStep(() => {
                store.dispatch(storeModulePath.value + '/setCaptions', value);
            });
        }
    });

    return {
        storeModulePath,
        voiceOverState,
        voiceOverMediaState,
        voiceOverCategory,
        isNoneCategory,
        isAudioCategory,
        isVoiceCategory,
        isRecordingCategory,
        isProcessing,
        voiceOverMediaAudioModel,
        voiceOverRecordingModel,
        voiceOverDeleteVoiceSource,
        showTtsEditor,
        timerange,
        timerangeSegments,
        playbackRate,
        voiceOverCaptions
    };
}
