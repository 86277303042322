/**
 * Edit video Card mixin Vuex store
 */

// Initial state
const state = () => ({
    cardShown: false
});

// Mutations
const mutations = {
    showCard(state, show) {
        state.cardShown = !!show;
    }
};

export default {
    state,
    mutations
};
