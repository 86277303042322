<template>
    <div class="ui-login-refresh-modal">
        <iframe ref="$modalFrame" class="ui-login-refresh-frame" :src="loginRefreshUrl" />
    </div>
</template>

<script>
import { BaseModal, UsesPostMessage } from '../../mixins';

export const UI_MODAL_LOGIN_REFRESHED_EVENT = 'ui-modal-login-refreshed';

export default {
    mixins: [BaseModal, UsesPostMessage],

    emits: [UI_MODAL_LOGIN_REFRESHED_EVENT],

    props: {
        url: {
            type: String,
            default: ''
        },
        token: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            //
        };
    },

    computed: {
        loginRefreshUrl() {
            return this.url.replace(/%%TOKEN%%/, this.token);
        }
    },

    watch: {
        loginRefreshUrl(newValue) {
            this.postMessageOrigin = this.parsePostMessageOrigin(newValue);
        }
    },

    methods: {
        close() {
            this.endPostMessageListening();
            this.endCapturingFocus();
            this.destroy();
        },

        destroy() {
            this.$store.commit('ui/modals/remove', this.id);
        },

        receiveMessageEvent(data, event) {
            if (data.type == 'event') {
                this.handleMessageEvent(data);
                return;
            }
        },

        handleMessageEvent(data) {
            console.log('UiLoginRefreshModal.handleMessageEvent', data);
            let { type, message, ...other } = data;

            switch (data.message) {
                case 'login-refresh-closed':
                    if (data.success) {
                        this.$emit(UI_MODAL_LOGIN_REFRESHED_EVENT);
                    }
                    this.close();
                    break;
            }
        }
    },

    mounted() {
        this.postMessageTarget = this.$refs.$modalFrame;
        this.postMessageOrigin = this.parsePostMessageOrigin(this.loginRefreshUrl);
        this.startPostMessageListening();
    }
};
</script>
