<template>
    <div :class="templateListClass" ref="listContainer">
        <template v-if="showLoader">
            <UiVideoListItem
                v-for="i in 1"
                :key="i"
                :loading="loading"
                :video="placeholderVideo"
                class="home-view-template-list-item"
                type="template"
                :show-description="true"
            />
        </template>
        <UiVideoListItem
            v-else
            v-for="template in templates"
            :key="template.id"
            :video="template"
            class="home-view-template-list-item"
            type="template"
            :show-description="true"
            @click="selectTemplate(template)"
        />
    </div>
</template>

<script setup>
import { computed, ref, watch, nextTick, onMounted } from 'vue';
import { gsap } from 'gsap';
import UiVideoListItem from '@/js/components/UiVideoListItem.vue';
import TemplateDTO from '@/js/dto/TemplateDTO.js';

const props = defineProps({
    selectedTemplateFormat: {
        type: String,
        required: true,
        default: 'landscape'
    },
    templates: {
        type: Array,
        required: true
    },
    loading: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['select-template']);

const listContainer = ref(null);

const showLoader = computed(() => props.loading && !props.templates.length);

const templateListClass = computed(() => `home-view-templates-list ${props.selectedTemplateFormat}`);

const placeHolderFormat = computed(() => {
    switch (props.selectedTemplateFormat) {
        case 'landscape':
            return { width: 16, height: 9 };
        case 'portrait':
            return { width: 9, height: 16 };
        case 'square':
            return { width: 1, height: 1 };
    }
});

const placeholderVideo = computed(() => {
    return new TemplateDTO({
        id: 'placeholder',
        name: '',
        description: '',
        organization_id: '',
        brand_id: '',
        is_made_by_cte: false,
        owner_id: '',
        format: placeHolderFormat.value,
        language: '',
        categories: [],
        compatible_formats: [],
        sequences_total: 0,
        needs_migration: false,
        thumbnails: {},
        produced_at: null,
        latest_produced_url: '',
        latest_produced_poster_url: '',
        latest_produced_is_outdated: false,
        created_at: '',
        updated_at: '',
        readable_created_at: '',
        readable_updated_at: '',
        permissions: {
            edit: false,
            copy: false,
            move: false,
            create_from: false
        }
    });
});

const animateItems = (items) => {
    nextTick(() => {
        if (!items.length) return;

        const itemElements = listContainer.value.querySelectorAll('.home-view-template-list-item:not(.skeleton)');
        const elementsToAnimate = Array.from(itemElements).slice(-items.length);

        gsap.set(elementsToAnimate, { opacity: 0 });
        elementsToAnimate.forEach((item, index) => {
            gsap.to(item, {
                opacity: 1,
                duration: 0.2,
                ease: 'power1.out',
                delay: index * 0.1
            });
        });
    });
};

let previousTemplates = [];
watch(
    () => props.templates,
    (newTemplates) => {
        if (previousTemplates.length === 0) {
            // Initial load
            animateItems(newTemplates);
        } else {
            const newItemsCount = newTemplates.length - previousTemplates.length;

            if (newItemsCount > 0) {
                // New items added, animate only the new items
                animateItems(newTemplates.slice(-newItemsCount));
            } else if (newItemsCount < 0) {
                // Items removed (e.g., category change), animate all new items
                animateItems(newTemplates);
            }
        }

        previousTemplates = [...newTemplates];
    },
    { deep: true }
);

const selectTemplate = (template) => {
    emit('select-template', template);
};

onMounted(() => {
    if (props.templates.length > 0) {
        nextTick(() => {
            animateItems(props.templates);
        });
    }
});
</script>

<style type="scss" scoped>
.home-view-templates-list {
    display: grid;
    gap: 20px;
}

.home-view-template-list-item {
    transform-origin: center center;
    width: 100%;
    height: 100%;
}
</style>
