<template>
    <button
        class="preview-action preview-action-play"
        id="video-onboarding-step-4"
        @click="play"
    >
            <span class="action-icon">
                <svg-icon icon="rotate-play-icon" class="play" />
            </span>
        <span class="action-label">{{ $t('Full preview') }}</span>
    </button>
</template>

<script>
export default {
    name: "FullPreviewButton",

    inject: ['$videoStudio'],

    methods: {
        play() {
            this.$store.commit('preview/setCurrentTimelineId', null);
            this.$videoStudio.studio.$stage.seekTimeline(0);
            this.$videoStudio.studio.$stage.playTimeline();
        },
    }
}
</script>

<style scoped>

</style>