import { onMounted, onBeforeUnmount, ref } from 'vue';

export function useEventHandlers({
    sliderWrapper,
    sliderContainer,
    handleCurrentTimeDrag,
    stopCurrentTimeDrag,
    onDrag,
    handleStopDrag,
    onSliderDrag,
    stopSliderDrag,
    handleResize,
    handleOutsideClick
}) {
    const uiScrollableBody = ref(null);

    const addEventListeners = () => {
        if (sliderContainer.value) {
            sliderContainer.value.addEventListener('mousemove', handleCurrentTimeDrag);
            sliderContainer.value.addEventListener('mouseup', stopCurrentTimeDrag);
            sliderContainer.value.addEventListener('mousemove', onDrag);
        }

        if (sliderWrapper.value) {
            sliderWrapper.value.addEventListener('mousemove', onSliderDrag);
        }

        //select element with class ui-scrollable ui-body
        uiScrollableBody.value = document.querySelector('.ui-scrollable.ui-body');

        // if (uiScrollableBody.value) {
        //     uiScrollableBody.value.addEventListener('mousedown', handleOutsideClick);
        // }
        window.addEventListener('resize', handleResize);
    };

    const removeEventListeners = () => {
        if (sliderContainer.value) {
            sliderContainer.value.removeEventListener('mousemove', handleCurrentTimeDrag);
            sliderContainer.value.removeEventListener('mouseup', stopCurrentTimeDrag);
            sliderContainer.value.removeEventListener('mousemove', onDrag);
            sliderContainer.value.removeEventListener('mouseup', handleStopDrag);
        }
        
        if (sliderWrapper.value) {
            sliderWrapper.value.removeEventListener('mousemove', onSliderDrag);
            sliderWrapper.value.removeEventListener('mouseup', stopSliderDrag);
        }
        
        if (uiScrollableBody.value) {
            uiScrollableBody.value.removeEventListener('mousedown', handleOutsideClick);
        }

        window.addEventListener('resize', handleResize);
    };

    onMounted(() => {
        addEventListeners();
    });

    onBeforeUnmount(() => {
        removeEventListeners();
    });

    return {
        addEventListeners,
        removeEventListeners,
    };
}
