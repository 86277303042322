/**
  -- Component name: ZoomIn
  -- Type: Background animation
  -- Uses: animation.zoomTarget,animation.zoomTargetX,animation.zoomTargetY
  -- Categories: background
  -- Tags: zoom
 */

import { Background } from '../../constants';
import { conversions } from '../../utils';
import gsap from 'gsap';

class ZoomIn {
    static animate(background, data) {
        let t = gsap.timeline(),
            transformOrigin = conversions.pointAlignment(data.zoomTarget) || data.zoomTargetX + ' ' + data.zoomTargetY;
        t.fromTo(
            background,
            { scale: 1 },
            { duration: 1, scale: Background.ANIMATION_ZOOM_SCALE_DEFAULT, transformOrigin }
        );
        return t;
    }
}

export default ZoomIn;
