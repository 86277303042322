<!-- TimeDisplay.vue -->
<template>
    <div class="time-container">
        <div class="time-container-content">
            <div class="time-layout">
                <span class="formattedTime">
                    {{ formattedTime }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, computed } from 'vue';

export default defineComponent({
    name: 'TimeDisplay',
    props: {
        elapsedTime: {
            type: Number,
            required: true
        },
    },
    setup(props) {
        const formatTime = (time) => {
            let minutes = Math.floor((time % 3600) / 60);
            let seconds = Math.floor(time % 60);
            if (seconds < 10) {
                seconds = `0${seconds}`;
            }
            let ms = Math.floor((time % 1) * 100);
            if (ms < 10) {
                ms = `0${ms}`;
            }
            return `${minutes}:${seconds}.${ms}`;
        };

        const formattedTime = computed(() => {
            return `${formatTime(props.elapsedTime)}`;
        });

        return {
            formattedTime,
        };
    }
});
</script>

<style scoped>
.time-layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.time-container-content {
    display: flex;
    justify-content: center;
    width: 64px;
}

.time-container {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.formattedTime {
    color: white;
    font-size: 14px;
    font-weight: bold;
}

.formattedEndTime {
    color: white;
    font-size: 14px;
}

</style>
