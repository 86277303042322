import { executeRequest } from '@/js/infrastructure/api/apiClient.js';

const API_URL = import.meta.env.VITE_EDITION_API_URL;

export default class FetchTemplateCategoriesService {
    constructor(store) {
        this.store = store;
    }

    async handle(brandId = null) {
        const params = {
            ...(brandId && { 'brand-id': brandId })
        };

        const response = await executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${API_URL}/api/templates/categories`, { params });
            },
            onSuccess: async (response) => response.data,
            actionType: 'fetchTemplateCategories'
        });

        return response.data;
    }
}
