import { executeRequest } from '@/js/infrastructure/api/apiClient.js';

const LEGACY_URL = import.meta.env.VITE_LEGACY_APP_URL;

export default class FollowFolderApiService {
    constructor(store) {
        this.store = store;
    }

    handle(params) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.post(`${LEGACY_URL}/front/folders/${params.publicId}/follow`, {
                    follow: params.follow
                });
            },
            onSuccess: async (response) => response.data,
            actionType: 'FollowFolderApiService'
        });
    }
}
