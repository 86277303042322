<!--
  -- Component name: None
  -- Type: Transition
  -- Uses: 
  -- Tags: 
  -->
<template></template>

<script>
import TransitionMixin from '../../mixins/Transition';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            // isAbove: false, // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {},

    methods: {
        // getTimeline() { // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
        // 	return gsap.timeline();
        // },
    }
};
</script>
