import { useStore } from 'vuex';
import { computed } from 'vue';

export function useVideo(store = null) {
    if (!store) {
        store = useStore();
    }

    const saveVideo = (video) => {
        store.dispatch('ui/saveVideo', video);
    };

    const isEmotionMode = computed(() => {
        return store.getters['ui/isEmotionMode'];
    });

    return { saveVideo, isEmotionMode };
}
