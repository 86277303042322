<template>
    <div :class="resultClasses" @click="useImage">
        <img :src="image.url" :alt="$t('image-generation.generated')" loading="lazy" />
        <svg-icon v-if="isImporting" class="loader" icon="dots-loader" />
    </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

export default defineComponent({
    name: 'UiImageGenerationResult',

    props: {
        image: Object,
        importingUuid: String
    },

    setup(props, { emit }) {
        const useImage = () => {
            if (!props.importingUuid) emit('use-image', props.image.uuid);
        };

        const isImporting = computed(() => props.importingUuid === props.image.uuid);
        const resultClasses = computed(() => [
            'gen-image-ai-result',
            {
                importing: isImporting.value
            }
        ]);

        return { isImporting, resultClasses, useImage };
    }
});
</script>
