<template>
    <div
        class="studio-container studio-editing-container"
        ref="$editingContainer"
        :class="editingContainerClass"
        :style="editingStyle"
    >
        <template v-for="field in ['v-left', 'v-center', 'v-right', 'h-top', 'h-center', 'h-bottom']" :key="field">
            <div v-if="activeGrids.includes(field)" class="studio-editing-grid-field" :class="field + '-grid'"></div>
        </template>
        <div ref="$editingFrame" :class="studioEditingFrameClasses" :style="editingFrameStyle">
            <editing-frame-actions
                :cropping="cropping"
                :editing-element="editingElement"
                @init-crop="initCropping"
                @aspect-ratio="changeAspectRatio"
                @save-crop="saveCrop"
                @cancel-crop="cancelCrop"
                @open-quickcut="openQuickCut"
            />
            <div class="frame-selection-outline"></div>
            <div
                class="frame-selectable-area"
                v-if="editingElement.editingData.movement"
                :class="{ active: mouseMoving }"
                @mousedown="enableMouseMovement"
            ></div>
            <div
                v-for="border in frameBorders"
                :key="border"
                class="frame-border"
                :class="{ [border + '-border']: true, active: resizeTarget === border }"
                @mousedown="enableResize($event, border)"
            ></div>
            <div
                v-for="corner in editingElement.editingData.corners"
                :key="corner"
                class="frame-corner"
                :class="{ [corner + '-corner']: true, active: resizeTarget === corner }"
                @mousedown="enableResize($event, corner)"
            ></div>

            <div v-if="cropping" class="frame-cropping-asset">
                <component
                    :is="editingElement.image ? 'img' : 'video'"
                    :src="editingElementAsset"
                    :style="frameAssetStyles"
                    ref="$croppingAsset"
                />
            </div>

            <div
                v-if="cropping"
                v-for="gridLine in ['v-first', 'v-second', 'h-first', 'h-second']"
                :key="gridLine"
                :class="['frame-grid-line', gridLine]"
            ></div>

            <div
                v-show="rotationAvailable && !cropping"
                ref="$rotateHandle"
                :class="rotateHandleClasses"
                @mousedown.left="enableRotate"
            >
                <fa-icon v-if="!rotating" icon="fa-rotate" />
                <span v-else class="frame-rotate-text">{{ rotateDegrees }}°</span>
            </div>
        </div>

        <div v-if="cropping" class="frame-cropping-asset-background">
            <component
                :is="editingElement.image ? 'img' : 'video'"
                ref="$croppingAssetBackground"
                :src="editingElementAsset"
                :style="croppingAssetStyles"
            />
        </div>
    </div>
</template>

<script>
import { Dimension, Edition, Visual } from '../constants';
import { conversions } from '../utils';
import throttle from 'lodash/throttle';
import gsap from 'gsap';
import { mapState } from 'vuex';
import EditingFrameActions from '@/js/video-studio/components/EditingFrameActions.vue';

export default {
    components: { EditingFrameActions },
    inject: ['$videoStudio', '$stage'],

    props: {
        canResize: Boolean
    },

    data() {
        return {
            A: 0,
            B: 0,
            C: 0,
            D: 0,
            mouseMoving: false,
            canEdit: false,
            math: {},
            resizeTarget: null,
            resizeAttributes: [],
            activeGrids: [],
            fixedValues: { A: null, B: null, C: null, D: null },

            currentAction: null,

            // element position
            _cachedElement: null,
            elementPosition: { left: 0, right: 0, top: 0, bottom: 0 },
            elementCenterX: 0,
            elementCenterY: 0,
            originalAsset: null,

            croppingRatio: null,

            rotateHandleObserver: null,
            rotateDegrees: 0,
            startRotateDegrees: 0,
            rotateHandleTop: false
        };
    },

    computed: {
        ...mapState({
            display: (state) => state.display,
            editingElement: (state) => state.edition.editingElement,
            movementEvent: (state) => state.edition.movementEvent
        }),

        isHomothetic() {
            return this.resizeAttributes.length > 1 && this.editingElement.editingData.homotheticOnCorners;
        },

        stageSize() {
            return {
                width: this.display.format.width,
                height: this.display.format.height
            };
        },

        stageScaledSize() {
            return {
                width: this.display.format.width * this.$stage.scale,
                height: this.display.format.height * this.$stage.scale
            };
        },

        width() {
            return (this.fixedValues.B ?? this.B) - (this.fixedValues.A ?? this.A);
        },

        height() {
            return (this.fixedValues.D ?? this.D) - (this.fixedValues.C ?? this.C);
        },

        position() {
            return {
                left: conversions.roundPercentage(this.fixedValues.A ?? this.A) + Dimension.PERCENT_UNIT,
                top: conversions.roundPercentage(this.fixedValues.C ?? this.C) + Dimension.PERCENT_UNIT
            };
        },

        size() {
            return {
                width: conversions.roundPercentage(this.width) + Dimension.PERCENT_UNIT,
                height: conversions.roundPercentage(this.height) + Dimension.PERCENT_UNIT
            };
        },

        editingContainerClass() {
            return {
                'editing-mode': this.canEdit,
                'editing-cursor-moving': this.mouseMoving,
                ...(this.resizeTarget && { ['editing-cursor-' + this.resizeTarget]: true })
            };
        },

        editingStyle() {
            return {
                fontSize: Math.round(Dimension.EDITING_FONT_SIZE_DEFAULT / this.$stage.scale) + Dimension.PIXEL_UNIT
            };
        },

        editingFrameStyle() {
            return {
                ...this.position,
                ...this.size
            };
        },

        verticalCenter() {
            return (this.fixedValues.A ?? this.A) + this.width / 2;
        },

        horizontalCenter() {
            return (this.fixedValues.C ?? this.C) + this.height / 2;
        },

        studioEditingFrameClasses() {
            return ['studio-editing-frame', { cropping: this.cropping }];
        },

        frameBorders() {
            // all borders enabled when cropping without forced aspect ratio
            return this.cropping && !this.croppingRatio ? Edition.BORDERS : this.editingElement.editingData.borders;
        },

        editingElementAsset() {
            return this.$store.getters['loading/getBlob'](this.editingElement.image || this.editingElement.video);
        },

        assetIntrinsicSize() {
            if (!this.originalAsset) return {};

            const [width, height] = this.editingElement.image
                ? [this.originalAsset.naturalWidth, this.originalAsset.naturalHeight]
                : [this.originalAsset.videoWidth, this.originalAsset.videoHeight];

            return {
                width,
                height,
                RW: width / height,
                RH: height / width
            };
        },

        croppingAssetStyles() {
            // must mimic scaling of original asset
            let scaleValue = null;
            if (this.editingElement.state.flip.horizontal && this.editingElement.state.flip.vertical)
                scaleValue = 'scale(-1, -1)';
            else if (this.editingElement.state.flip.horizontal) scaleValue = 'scale(-1, 1)';
            else if (this.editingElement.state.flip.vertical) scaleValue = 'scale(1, -1)';

            // determine which size property should be set to maximum
            const displayRatio = this.$store.getters['display/formatRatio'];
            let property = this.assetIntrinsicSize.RW > displayRatio ? 'width' : 'height';
            if (displayRatio < 1) property = this.assetIntrinsicSize.RW >= displayRatio ? 'width' : 'height';

            const size = conversions.roundPercentage(Edition.CROPPING_ASSET_SIZE) + Dimension.PERCENT_UNIT;
            return { transform: scaleValue, [property]: size };
        },

        frameAssetStyles() {
            // calculate duplicated asset values relative to element and frame
            const width = (this.elementPosition.right - this.elementPosition.left) / this.width;
            const height = (this.elementPosition.bottom - this.elementPosition.top) / this.height;
            const left = (this.elementPosition.left - this.A) / this.width;
            const top = (this.elementPosition.top - this.C) / this.height;

            // must mimic scaling of original asset
            let scaleValue = null;
            if (this.editingElement.state.flip.horizontal && this.editingElement.state.flip.vertical)
                scaleValue = 'scale(-1, -1)';
            else if (this.editingElement.state.flip.horizontal) scaleValue = 'scale(-1, 1)';
            else if (this.editingElement.state.flip.vertical) scaleValue = 'scale(1, -1)';

            return {
                width: conversions.roundPercentage(width) + Dimension.PERCENT_UNIT,
                height: conversions.roundPercentage(height) + Dimension.PERCENT_UNIT,

                left: conversions.roundPercentage(left) + Dimension.PERCENT_UNIT,
                top: conversions.roundPercentage(top) + Dimension.PERCENT_UNIT,

                transform: scaleValue
            };
        },

        cropping() {
            return this.currentAction === Edition.ACTIONS.CROPPING;
        },

        rotating() {
            return this.currentAction === Edition.ACTIONS.ROTATING;
        },

        rotateHandleClasses() {
            return ['frame-rotate-handle', { rotating: this.rotating, top: this.rotateHandleTop }];
        },

        rotationAvailable() {
            // visual and message for now
            return this.editingElement.visualSelector || this.editingElement.messageSelector;
        },

        isRecordingCategory() {
            return this.editingElement.state.animation.category === Visual.RECORDING_CATEGORY;
        }
    },

    watch: {
        editingElement(element) {
            if (element) this.initEdition();
        },

        'editingElement.needRefreshFrame': function (refresh) {
            if (refresh) {
                this.$nextTick(() => {
                    this.initEdition();
                    this.editingElement.needRefreshFrame = false;
                });
            }
        },

        '$stage.scale': function () {
            this.saveStageRect();
        }
    },

    methods: {
        getElement() {
            let element = this.editingElement.$el;
            if (!element.classList.contains(Edition.EDITABLE_CONTAINER_CLASS))
                element = element.querySelector('.' + Edition.EDITABLE_CONTAINER_CLASS);
            return element;
        },

        getInnerElement() {
            return this.getElement().querySelector('.studio-inner-container');
        },

        initEdition() {
            // needed to perform actions on element when clicking outside the frame
            this._cachedElement = this.editingElement;

            if (this.canResize) {
                let editElement = this.editingElement.$el;
                editElement.parentNode.insertBefore(this.$el, editElement.nextSibling);

                this.saveStageRect();
                this.initializeFrame();

                document.addEventListener('keydown', this.enableKeyboardMovement);

                if (this.movementEvent) {
                    this.enableMouseMovement(this.movementEvent);
                    this.$store.commit('edition/sendMovementEvent', null);
                }

                this.observeRotateHandleIntersection();
            }
        },

        initializeFrame() {
            this.editingElement.editingData.initFrameBeforeTransform
                ? this.initFrameBeforeTransform()
                : this.initFrameAfterTransform();
        },

        disableEdition() {
            document.removeEventListener('keydown', this.enableKeyboardMovement);
        },

        saveStageRect() {
            let stageRect = this.$stage.$el.getBoundingClientRect();
            this.stageX = stageRect.x;
            this.stageY = stageRect.y;
            this.stageW = stageRect.width;
            this.stageH = stageRect.height;
            this.stageRW = stageRect.width / stageRect.height;
            this.stageRH = stageRect.height / stageRect.width;
        },

        saveStartingPosition() {
            this.startA = this.fixedValues.A ?? this.A;
            this.startC = this.fixedValues.C ?? this.C;
            this.startWidth = this.width;
            this.startHeight = this.height;
            this.startRotateDegrees = this.editingElement.state.rotation;

            // when cropping, ratio must be calculated based on editing frame sizes to keep aspect ratio of it while resizing
            this.startRect = this.cropping
                ? this.$refs.$editingFrame.getBoundingClientRect()
                : this.getElement().getBoundingClientRect();
            this.startRW = this.startRect.width / this.startRect.height;
            this.startRH = this.startRect.height / this.startRect.width;

            if (this.cropping) {
                const bgElementRect = this.$refs.$croppingAssetBackground.getBoundingClientRect();

                // we have to get values of the inner element to calculate element real position
                this.elementPosition.left = (bgElementRect.left - this.stageX) / this.stageScaledSize.width;
                this.elementPosition.right = (bgElementRect.right - this.stageX) / this.stageScaledSize.width;
                this.elementPosition.top = (bgElementRect.top - this.stageY) / this.stageScaledSize.height;
                this.elementPosition.bottom = (bgElementRect.bottom - this.stageY) / this.stageScaledSize.height;
            }
        },

        initFrameAfterTransform() {
            let element = this.getElement(),
                elementRect = element.getBoundingClientRect();
            this.A = (elementRect.left - this.stageX) / this.stageScaledSize.width;
            this.B = (elementRect.right - this.stageX) / this.stageScaledSize.width;
            this.C = (elementRect.top - this.stageY) / this.stageScaledSize.height;
            this.D = (elementRect.bottom - this.stageY) / this.stageScaledSize.height;
        },

        initFrameBeforeTransform() {
            let element = this.getElement();
            this.A = element.offsetLeft / this.stageSize.width;
            this.B = (element.offsetLeft + element.clientWidth) / this.stageSize.width;
            this.C = element.offsetTop / this.stageSize.height;
            this.D = (element.offsetTop + element.clientHeight) / this.stageSize.height;
        },

        startEditing() {
            this.$store.commit('edition/setEditing', true);
        },

        stopEditing() {
            this.$store.commit('edition/setEditing', false);
        },

        enableKeyboardMovement(e) {
            // prevent movement when focus is on an input, textarea or tiptap editor
            if (['INPUT', 'TEXTAREA'].includes(e.target.tagName) || e.target.editor) return;

            if (Edition.ARROW_KEYS_ACTIONS[e.code] && this.editingElement.editingData.movement) {
                this.canEdit = true;
                this.saveStartingPosition();
                this.adaptPosition();
                this.move(e, false, false);
                if (!this.cropping) this.save(true, false);
                this.canEdit = false;
            }
        },

        enableMouseMovement(e) {
            if (e.which === 1 && this.editingElement.editingData.movement) {
                this.$stage.editing = true;
                this.canEdit = true;
                this.mouseMoving = true;
                this.saveStartingPosition();
                this.startClientX = e.clientX;
                this.startClientY = e.clientY;
                document.addEventListener('mousemove', this.adaptPosition, { once: true });
                document.addEventListener('mousemove', this.move);
                document.addEventListener('mouseup', this.disableMovement, { once: true });
                document.addEventListener('click', this.captureClickEvent, { once: true, capture: true });
            }
        },

        enableResize(e, target) {
            if (e.which === 1) {
                this.$stage.editing = true;
                this.canEdit = true;
                this.resizeTarget = target;
                this.saveStartingPosition();

                if (target === 'top-left' || target === 'bottom-right') {
                    this.math.a =
                        (this.startRect.bottom - this.startRect.top) / (this.startRect.right - this.startRect.left);
                    this.math.b = this.startRect.top - this.math.a * this.startRect.left;
                } else if (target === 'top-right' || target === 'bottom-left') {
                    this.math.a =
                        (this.startRect.top - this.startRect.bottom) / (this.startRect.right - this.startRect.left);
                    this.math.b = this.startRect.top - this.math.a * this.startRect.right;
                }

                this.resizeAttributes = [];
                target = target.split('-');
                if (target.includes('top')) this.resizeAttributes.push('C');
                if (target.includes('right')) this.resizeAttributes.push('B');
                if (target.includes('bottom')) this.resizeAttributes.push('D');
                if (target.includes('left')) this.resizeAttributes.push('A');

                document.addEventListener('mousemove', this.adaptPosition, { once: true });
                document.addEventListener('mousemove', this.resize);
                document.addEventListener('mouseup', this.disableResize, { once: true });
                document.addEventListener('click', this.captureClickEvent, { once: true, capture: true });
            }
        },

        enableRotate() {
            this.$stage.editing = true;
            this.canEdit = true;
            this.mouseMoving = true;
            this.saveStartingPosition();
            this.currentAction = Edition.ACTIONS.ROTATING;
            this.rotateDegrees = this.editingElement.state.rotation;
            this.elementCenterX = this.startRect.left + this.startRect.width / 2;
            this.elementCenterY = this.startRect.top + this.startRect.height / 2;

            document.addEventListener('mousemove', this.rotate);
            document.addEventListener('mouseup', this.disableRotate, { once: true });
        },

        disableMovement() {
            document.removeEventListener('mousemove', this.move);
            let positionUpdated = this.A !== this.startA || this.C !== this.startC;
            this.fixValues();
            if (positionUpdated && !this.cropping) {
                this.clearPreview();
                this.save(true, false);
            }
            this.editingElement.previewing = false;
            this.resetGrid();
            this.canEdit = false;
            this.mouseMoving = false;
            this.$stage.editing = false;
        },

        disableResize() {
            document.removeEventListener('mousemove', this.resize);
            let sizeUpdated = this.width !== this.startWidth || this.height !== this.startHeight;
            this.fixValues();
            if (sizeUpdated && !this.cropping) {
                this.clearPreview();
                this.save(this.editingElement.editingData.movement, true);
            }
            this.editingElement.previewing = false;
            this.resizeAttributes = [];
            this.resizeTarget = null;
            this.resetGrid();
            this.canEdit = false;
            this.$stage.editing = false;
        },

        disableRotate() {
            document.removeEventListener('mousemove', this.rotate);
            if (this.startRotateDegrees !== this.rotateDegrees) {
                this.clearPreview(true);
                this.saveRotation();
            }
            this.currentAction = null;
            this.editingElement.previewing = false;
            this.canEdit = false;
            this.mouseMoving = false;
            this.$stage.editing = false;
        },

        captureClickEvent(event) {
            event.stopPropagation();
        },

        move: throttle(function (e, grid = true, preview = true) {
            if (!this.canEdit) return;

            let vectorXPercent = 0;
            let vectorYPercent = 0;

            switch (e.type) {
                case 'mousemove':
                    vectorXPercent = (e.clientX - this.startClientX) / this.stageScaledSize.width;
                    vectorYPercent = (e.clientY - this.startClientY) / this.stageScaledSize.height;
                    this.startClientX = e.clientX;
                    this.startClientY = e.clientY;
                    break;
                case 'keydown':
                    let action = Edition.ARROW_KEYS_ACTIONS[e.code],
                        scaleW = this.stageW > this.stageH ? this.stageRH : 1,
                        scaleH = this.stageH > this.stageW ? this.stageRW : 1;
                    vectorXPercent = (action.x ? (e.shiftKey ? action.x * 10 : action.x) : 0) * scaleW;
                    vectorYPercent = (action.y ? (e.shiftKey ? action.y * 10 : action.y) : 0) * scaleH;
                    break;
            }

            // when cropping, we have to ensure the frame cannot go further than element borders
            if (this.cropping) {
                this.A = Math.max(
                    Math.min(this.A + vectorXPercent, this.elementPosition.right - this.startWidth),
                    this.elementPosition.left
                );
                this.B = this.A + this.startWidth;
                this.C = Math.max(
                    Math.min(this.C + vectorYPercent, this.elementPosition.bottom - this.startHeight),
                    this.elementPosition.top
                );
                this.D = this.C + this.startHeight;
            } else {
                this.A += vectorXPercent;
                this.B += vectorXPercent;
                this.C += vectorYPercent;
                this.D += vectorYPercent;
            }

            if (!this.cropping) {
                if (grid) this.updateGrids(false);
                if (preview) this.preview();
            }
        }, Edition.MAXIMUM_REFRESH_TIME),

        rotate: throttle(function (e) {
            if (!this.canEdit) return;

            // calculate rotation degrees based on mouse position with arc tangent
            const baseRotation = this.rotateHandleTop ? 90 : -90;
            const starting = this.editingElement.state.rotation + baseRotation;
            const radians = Math.atan2(e.clientY - this.elementCenterY, e.clientX - this.elementCenterX);
            let degrees = Math.trunc(radians * (180 / Math.PI) + starting);

            // degrees bounded between 180 and -180
            degrees = ((degrees % 360) + 360) % 360;
            if (degrees > 180) degrees -= 360;

            // check if the angle is close to a snap angle
            for (const angle of Edition.ROTATION_SNAP_ANGLES) {
                if (Math.abs(degrees - angle) <= Edition.ROTATION_SNAP_THRESHOLD) {
                    degrees = angle;
                }
            }

            this.rotateDegrees = degrees;
            gsap.set(this.getInnerElement(), { rotate: this.rotateDegrees + Dimension.DEGREE_UNIT });
        }, Edition.MAXIMUM_REFRESH_TIME),

        resize: throttle(function (e) {
            if (!this.canEdit) return;

            let x = 0,
                y = 0,
                minH = Edition.MINIMAL_SIZE,
                minV = Edition.MINIMAL_SIZE;

            if (this.isHomothetic) {
                minH = this.editingElement.editingData.homotheticMinimalSize
                    ? Edition.HOMOTHETIC_MINIMAL_SIZE
                    : Edition.MINIMAL_SIZE;
                minV = this.editingElement.editingData.homotheticMinimalSize
                    ? Edition.HOMOTHETIC_MINIMAL_SIZE
                    : Edition.MINIMAL_SIZE;
                if (this.startWidth > this.startHeight) minH = (minH / this.stageRW) * this.startRW;
                if (this.startHeight > this.startWidth) minV = (minV / this.stageRH) * this.startRH;

                let a = 1 / -this.math.a,
                    b = e.clientY - a * e.clientX;

                x = (b - this.math.b) / (this.math.a - a);
                y = a * x + b;
            } else {
                x = e.clientX;
                y = e.clientY;
            }

            this.resizeAttributes.forEach((attribute) => {
                if (attribute === 'A')
                    this[attribute] = Math.min((x - this.stageX) / this.stageScaledSize.width, this.B - minH);
                if (attribute === 'B')
                    this[attribute] = Math.max((x - this.stageX) / this.stageScaledSize.width, this.A + minH);
                if (attribute === 'C')
                    this[attribute] = Math.min((y - this.stageY) / this.stageScaledSize.height, this.D - minV);
                if (attribute === 'D')
                    this[attribute] = Math.max((y - this.stageY) / this.stageScaledSize.height, this.C + minV);
            });

            if (typeof this.editingElement.editingData.minA === 'number')
                this.A = Math.max(this.editingElement.editingData.minA, this.A);
            if (typeof this.editingElement.editingData.maxB === 'number')
                this.B = Math.min(this.editingElement.editingData.maxB, this.B);
            if (typeof this.editingElement.editingData.minC === 'number')
                this.C = Math.max(this.editingElement.editingData.minC, this.C);
            if (typeof this.editingElement.editingData.maxD === 'number')
                this.D = Math.min(this.editingElement.editingData.maxD, this.D);

            // when cropping, we have to ensure we cannot extend the frame outside the element
            if (this.cropping) {
                this.A = Math.max(this.elementPosition.left, this.A);
                this.B = Math.min(this.elementPosition.right, this.B);
                this.C = Math.max(this.elementPosition.top, this.C);
                this.D = Math.min(this.elementPosition.bottom, this.D);
            } else {
                this.updateGrids(true);
                this.preview();
            }
        }, Edition.MAXIMUM_REFRESH_TIME),

        fixValues() {
            this.A = this.fixedValues.A ?? this.A;
            this.B = this.fixedValues.B ?? this.B;
            this.C = this.fixedValues.C ?? this.C;
            this.D = this.fixedValues.D ?? this.D;
        },

        resetGrid() {
            this.fixedValues = { A: null, B: null, C: null, D: null };
            this.activeGrids = [];
            this.relativePositionH = null;
            this.relativePositionV = null;
        },

        updateGrids(resizing) {
            this.resetGrid();

            let currentGrids = [],
                positionVUpdated = false,
                positionHUpdated = false,
                scaleW = this.stageW > this.stageH ? this.stageRW : 1,
                scaleH = this.stageH > this.stageW ? this.stageRH : 1;

            Edition.GRIDS.forEach((grid) => {
                if (!resizing || (grid.resizing && this.resizeAttributes.includes(grid.targetAttribute))) {
                    let gap = grid.targetValue - this[grid.observeAttribute];
                    if (Math.abs(gap) < Edition.GRID_STRENGTH * (grid.alignment === 'vertical' ? scaleH : scaleW))
                        currentGrids.push({ ...grid, ...{ gap: gap } });
                }
            });

            currentGrids
                .sort((a, b) => {
                    return a.gap - b.gap;
                })
                .forEach((grid) => {
                    let displayGrid = false;

                    if (resizing) {
                        if (!this.isHomothetic) {
                            this.fixedValues[grid.targetAttribute] = grid.targetValue;
                            displayGrid = true;
                        }

                        if (this.isHomothetic && this.fixedValues[grid.targetAttribute] === null) {
                            this.fixedValues[grid.targetAttribute] = grid.targetValue;
                            displayGrid = true;
                            let associateAttribute = this.resizeAttributes.find((attr) => {
                                    return attr !== grid.targetAttribute;
                                }),
                                positiveLink =
                                    Edition.RESIZING_ATTRIBUTES_ASSOCIATION[grid.targetAttribute] === associateAttribute
                                        ? 1
                                        : -1,
                                vertical = grid.alignment === 'vertical';
                            this.fixedValues[associateAttribute] =
                                this[associateAttribute] +
                                (positiveLink *
                                    grid.gap *
                                    (vertical ? this.stageScaledSize.width : this.stageScaledSize.height) *
                                    (vertical ? this.startRH : this.startRW)) /
                                    (vertical ? this.stageScaledSize.height : this.stageScaledSize.width);
                        } else if (
                            this.isHomothetic &&
                            Math.abs(grid.targetValue - this.fixedValues[grid.targetAttribute]) < 0.001
                        )
                            displayGrid = true;
                    } else {
                        let fixedValue =
                            grid.targetValue -
                            (grid.observeAttribute !== grid.targetAttribute
                                ? grid.alignment === 'vertical'
                                    ? this.width / 2
                                    : this.height / 2
                                : 0);
                        if (
                            (!positionVUpdated && grid.alignment === 'vertical') ||
                            (!positionHUpdated && grid.alignment === 'horizontal')
                        ) {
                            this.fixedValues[grid.targetAttribute] = fixedValue;
                            let associateAttribute = Edition.MOVING_ATTRIBUTES_ASSOCIATION[grid.targetAttribute];
                            this.fixedValues[associateAttribute] = this[associateAttribute] + grid.gap;
                            if (grid.alignment === 'horizontal') positionHUpdated = true;
                            if (grid.alignment === 'vertical') positionVUpdated = true;
                            displayGrid = true;
                        } else if (Math.abs(fixedValue - this.fixedValues[grid.targetAttribute]) < 0.001)
                            displayGrid = true;
                    }

                    if (displayGrid) {
                        this.activeGrids.push(grid.field);
                        if (grid.finalPositionH) this.relativePositionH = grid.finalPositionH;
                        if (grid.finalPositionV) this.relativePositionV = grid.finalPositionV;
                    }
                });
        },

        preview() {
            let sizePreview = !(
                    this.editingElement.editingData.disableSizePreview && this.resizeAttributes.length === 1
                ),
                clearness = this.editingElement.editingData.clearnessPreview,
                clearBefore = this.editingElement.editingData.clearBeforePreview,
                previewAttribute = this.editingElement.editingData.previewAttribute;

            if (clearBefore) this.clearPreview();
            if (clearness) this.editingElement.previewing = true;
            if (sizePreview) {
                if (previewAttribute) {
                    gsap.set(this.getElement(), {
                        [previewAttribute]: this[previewAttribute] * 100 + Dimension.PERCENT_UNIT
                    });
                } else {
                    gsap.set(this.getElement(), {
                        x:
                            conversions.roundPercentage(
                                ((this.fixedValues.A ?? this.A) - this.startA) *
                                    (this.stageScaledSize.width / this.startRect.width)
                            ) + Dimension.PERCENT_UNIT,
                        y:
                            conversions.roundPercentage(
                                ((this.fixedValues.C ?? this.C) - this.startC) *
                                    (this.stageScaledSize.height / this.startRect.height)
                            ) + Dimension.PERCENT_UNIT,
                        scaleX: conversions.roundPercentage(this.width / this.startWidth) + Dimension.PERCENT_UNIT,
                        scaleY: conversions.roundPercentage(this.height / this.startHeight) + Dimension.PERCENT_UNIT,
                        transformOrigin: 'top left'
                    });
                }
            }
        },

        clearPreview(inner = false) {
            gsap.set(inner ? this.getInnerElement() : this.getElement(), { clearProps: 'transform,transform-origin' });
        },

        clearCropPreview() {
            this.originalAsset = null;

            const selector = `#${this._cachedElement.seqId}-content .studio-editable-container`;
            gsap.set(this.$stage.$el.querySelectorAll(selector), { clearProps: 'visibility' });
        },

        save(position, size) {
            if (position || size) {
                this.startEditing();
                if (this.$store.hasModule('ui')) this.$store.dispatch('ui/history/startStep');

                if (position)
                    this.editingElement.editPosition(this.position, this.relativePositionH, this.relativePositionV);
                if (size)
                    this.editingElement.editSize(
                        this.size,
                        this.resizeAttributes.length > 1 ? this.width / this.startWidth : null
                    );

                this.$nextTick(() => {
                    if (this.$store.hasModule('ui')) this.$store.dispatch('ui/saveVideo');
                    this.stopEditing();
                });
            }
        },

        adaptPosition() {
            if (this.editingElement.isHalfPosition) {
                this.editingElement.convertCustomPosition();
                this.clearPreview();
            }
            if (this.editingElement.isDistributedAlign && this.canEdit) {
                this.$store.commit('sequences/' + this.editingElement.seqId + '/disableDistributedAlign', true);
                this.$nextTick(() => {
                    this.$store.commit('sequences/' + this.editingElement.seqId + '/disableDistributedAlign', false);
                });
            }
        },

        initCropping() {
            this.currentAction = Edition.ACTIONS.CROPPING;
            this.originalAsset = this.editingElement.$el.querySelector('.asset-container .asset');

            this.assetWidth = this.width;
            this.assetHeight = this.height;

            // wait until $croppingAssetBackground is in the DOM tree
            this.$nextTick(() => {
                if (!this.editingElement.image) {
                    // set duplicated asset to same currentTime as original asset
                    this.$refs.$croppingAsset.currentTime = this.originalAsset.currentTime;
                    this.$refs.$croppingAssetBackground.currentTime = this.originalAsset.currentTime;

                    // video metadata must be loaded to get proper sizes
                    this.$refs.$croppingAssetBackground.addEventListener('loadedmetadata', this.initCroppingFrame);
                } else this.initCroppingFrame();
            });
        },

        // init cropping frame to match current asset crop size
        initCroppingFrame() {
            this.saveStartingPosition();

            // hide all elements from current sequence while cropping is enabled
            const selector = `#${this.editingElement.seqId}-content .studio-editable-container`;
            gsap.set(this.$stage.$el.querySelectorAll(selector), { visibility: 'hidden' });

            // calculate frame position based on element crop
            const ratioW = this.elementPosition.right - this.elementPosition.left;
            const ratioH = this.elementPosition.bottom - this.elementPosition.top;
            const cropPosition = this.editingElement.state.position.crop;

            this.A = this.elementPosition.left + Math.abs(cropPosition.left) * ratioW;
            this.B = this.elementPosition.left + (Math.abs(cropPosition.left) + cropPosition.width) * ratioW;
            this.C = this.elementPosition.top + Math.abs(cropPosition.top) * ratioH;
            this.D = this.elementPosition.top + (Math.abs(cropPosition.top) + cropPosition.height) * ratioH;
        },

        observeRotateHandleIntersection() {
            this.rotateHandleObserver = new IntersectionObserver(this.intersectionCallback, {
                root: this.$refs.$editingContainer,
                threshold: [0.5]
            });
            this.rotateHandleObserver.observe(this.$refs.$rotateHandle);
        },

        intersectionCallback([entry], observer) {
            const containerRect = observer.root.getBoundingClientRect();

            if (entry.boundingClientRect.top < containerRect.top) this.rotateHandleTop = false;
            else if (entry.boundingClientRect.bottom > containerRect.bottom) this.rotateHandleTop = true;
        },

        saveRotation() {
            if (this.$store.hasModule('ui')) this.$store.dispatch('ui/history/startStep');

            this.editingElement.editRotation(this.rotateDegrees);

            this.$nextTick(() => {
                if (this.$store.hasModule('ui')) this.$store.dispatch('ui/saveVideo');
                this.stopEditing();
            });
        },

        changeAspectRatio(ratio) {
            this.croppingRatio = ratio;

            const [RW, RH] = [this.assetIntrinsicSize.RW, this.assetIntrinsicSize.RH];
            const width = this.elementPosition.right - this.elementPosition.left;
            const height = this.elementPosition.bottom - this.elementPosition.top;

            // left and top are always the same when changing aspect ratio
            this.A = this.elementPosition.left;
            this.C = this.elementPosition.top;

            if (ratio === null) {
                // asset ratio
                [this.B, this.D] = [this.elementPosition.right, this.elementPosition.bottom];
            } else if (ratio === 1) {
                this.B = this.A + Math.min(width, width * RH);
                this.D = this.C + Math.min(height, height * RW);
            } else if (ratio === 16 / 9) {
                // landscape (16:9)
                this.B = this.A + Math.min(width, (16 / 9) * width * RH);
                this.D = this.C + Math.min(height, (9 / 16) * height * RW);
            } else {
                // portrait (9:16)
                this.B = this.A + Math.min(width, (9 / 16) * width * RH);
                this.D = this.C + Math.min(height, (16 / 9) * height * RW);
            }
        },

        saveCrop(reselect = true) {
            this.currentAction = null;
            this.clearCropPreview();

            if (this.$store.hasModule('ui')) this.$store.dispatch('ui/history/startStep');

            const deltaX = this.elementPosition.right - this.elementPosition.left;
            const deltaY = this.elementPosition.bottom - this.elementPosition.top;

            const width = this.width / deltaX;
            const height = this.height / deltaY;
            const left = (this.elementPosition.left - this.A) / deltaX;
            const top = (this.elementPosition.top - this.C) / deltaY;

            // if element cropping target is visual itself, we must edit its size accordingly
            if (!Visual.ANIMATIONS_CROPPING_ASSET.includes(this._cachedElement.state.animation.type)) {
                // round to 2 decimals
                const startRatio = Math.round((this.assetWidth / this.assetHeight + Number.EPSILON) * 100) / 100;
                const cropAreaRatio = Math.round((this.width / this.height + Number.EPSILON) * 100) / 100;

                // calculate new visual width and height based on cropped area size
                const newWidth = this.assetWidth * Math.sqrt(cropAreaRatio / startRatio);
                const newHeight = this.assetHeight * Math.sqrt(startRatio / cropAreaRatio);

                this._cachedElement.editSize({
                    width: conversions.roundPercentage(newWidth) + Dimension.PERCENT_UNIT,
                    height: conversions.roundPercentage(newHeight) + Dimension.PERCENT_UNIT
                });
            }

            // save crop position
            this._cachedElement.editCropPosition(width, height, left, top);

            this.$nextTick(() => {
                if (this.$store.hasModule('ui')) this.$store.dispatch('ui/saveVideo');

                // reset frame position by re-initialization
                if (reselect) this.initializeFrame();
            });
        },

        cancelCrop() {
            this.currentAction = null;
            this.clearCropPreview();

            // reset frame position by re-initialization
            this.initializeFrame();
        },

        openQuickCut() {
            if (this.isRecordingCategory) {
                this.$store.dispatch('ui/quickcut/openQuickCutForVisualRecording', {
                    seqId: this.editingElement.seqId,
                    elementId: this.editingElement.id
                });
            } else {
                this.$store.dispatch('ui/quickcut/openQuickCutForVisual', {
                    seqId: this.editingElement.seqId,
                    elementId: this.editingElement.id
                });
            }
        }
    },

    mounted() {
        this.$nextTick(this.initEdition);
    },

    beforeUnmount() {
        this.rotateHandleObserver?.disconnect();
        if (this.cropping) this.saveCrop(false);

        this.disableEdition();
    }
};
</script>
