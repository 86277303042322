import { setGlobalConfig, setGlobalVideoData } from './utilsInit';
import loader from '@/js/video-studio/loader.js';

export default class StudioEditInitializer {
    constructor(store, videoStudioService) {
        this.store = store;
        this.videoStudioService = videoStudioService;
    }

    async initVideoStudio(videoId, isSequenceTemplate = false, isTemplate = false, brandId = null) {
        let videoResource, config;

        try {
            if (isSequenceTemplate) {
                const videoResourceHttpRequest = await this.videoStudioService.getTemplateVideoResource(
                    brandId,
                    videoId,
                    'sequences'
                );
                const configHttpRequest = await this.videoStudioService.getTemplateConfig(
                    brandId,
                    videoId,
                    'sequences'
                );

                videoResource = videoResourceHttpRequest.data;
                config = configHttpRequest.data;
            } else if (isTemplate) {
                const videoResourceHttpRequest = await this.videoStudioService.getTemplateVideoResource(
                    brandId,
                    videoId
                );
                const configHttpRequest = await this.videoStudioService.getTemplateConfig(brandId, videoId);

                videoResource = videoResourceHttpRequest.data;
                config = configHttpRequest.data;
            } else {
                const videoResourceHttpRequest = await this.videoStudioService.getVideoStudioResource(videoId);
                const configHttpRequest = await this.videoStudioService.getEditConfig(videoId);

                videoResource = videoResourceHttpRequest.data.videoResource;
                config = configHttpRequest.data;
            }
        } catch (error) {
            return { errorCode: error.response?.status || 500 };
        }

        setGlobalConfig(config);
        setGlobalVideoData(videoResource);
        loader.init(this.store);

        this.initStore(config, videoResource);
    }

    initStore(config, resource) {
        this.store.dispatch('ui/init', config);

        this.store.dispatch('display/setReadOnly', config.readOnly);

        this.store.dispatch('display/setFormat', {
            width: resource.display.format.width,
            height: resource.display.format.height
        });

        this.store.dispatch('branding/init', resource.branding);
        this.store.dispatch('settings/init', resource.settings);

        for (let i = 0; i < resource.sequences.length; ++i) {
            this.store.dispatch('sequences/addSequence', {
                item: resource.sequences[i]
            });
        }

        if (config.sequenceMode) {
            this.store.commit('preview/setCurrentTimelineId', resource.sequences[0].id);
        }

        this.store.dispatch('ui/history/saveOriginalState');
        this.store.dispatch('ui/clipboard/init');
    }
}
