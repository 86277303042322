import VideoDTO from '@/js/dto/VideoDTO.js';

const state = () => ({
    currentVideo: null
});

const getters = {
    currentVideo: (state) => state.currentVideo
};

const mutations = {
    updateCurrentVideo(state, video) {
        state.currentVideo = video;
    },

    resetCurrentVideo(state) {
        state.currentVideo = null;
    }
};

const actions = {
    setCurrentVideo({ commit }, video) {
        commit('updateCurrentVideo', new VideoDTO(video));
    },

    clearCurrentVideo({ commit }) {
        commit('resetCurrentVideo');
    }
};

export default {
    namespaced: true,

    state,
    getters,
    mutations,
    actions
};
