import { executeRequest } from '@/js/infrastructure/api/apiClient.js';

const EDITION_URL = import.meta.env.VITE_EDITION_API_URL;

export default class CopyVideoApiService {
    constructor(store) {
        this.store = store;
    }

    handle(params) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.post(`${EDITION_URL}/api/videos/${params.videoId}/copy`, {
                    folder_ids: params.folderIds
                });
            },
            onSuccess: async (response) => response.data,
            actionType: 'CopyVideo'
        });
    }
}
