import { watch } from 'vue';
import { useStore } from 'vuex';
import { isEmpty } from 'lodash';

import { useAuth } from '@/js/composables/useAuth.js';
import { cgsData, loading } from '@/router/index.js';
import i18n from '@/libs/i18n.js';
import CGSDataService from '@/js/application/services/cgs/CGSDataService.js';
import ProfileDataService from '@/js/application/services/profile/ProfileDataService.js';
import { useModal } from '@/js/composables/useModal.js';

export const handleBeforeEachGuard = (to, from, next) => {
    const { user, isCTE, isClientAdmin } = useAuth();
    const store = useStore();
    const { openModal } = useModal();

    // if authentication is required and user object is empty we fetch the according data
    if (to.meta.requiresAuth && isEmpty(user.value)) {
        loading.value = true;
        new ProfileDataService(store).fetchProfileData();

        // it is only created once when user loads the page for the first time
        watch(user, async () => {
            const calls = [];

            if (isClientAdmin.value) {
                calls.push(new CGSDataService(store).fetchAll());
            }

            const [cgs] = await Promise.all(calls);

            if (!!cgs?.length) cgsData.value = cgs;

            const lang = user.value.language.substring(0, 2);
            i18n.global.locale.value = lang;
            localStorage.setItem('locale', lang);

            if (!user.value.welcomeModalAccepted) {
                openModal('WelcomeModal');
            }

            loading.value = false;
            next();
        });
    } else next();
};
