import { createRouter, createWebHistory } from 'vue-router';
import { ref } from 'vue';

import HomeView from '../views/HomeView/HomeView.vue';
import FoldersView from '@/views/FoldersView.vue';
import ShowFolderView from '@/views/ShowFolderView/ShowFolderView.vue';
import EditVideoView from '@/views/EditVideoView.vue';
import EditBrandView from '@/views/EditBrandView.vue';
import CompanionView from '@/views/CompanionView.vue';
import BrandsView from '@/views/BrandsView.vue';
import ElearningView from '@/views/ElearningView.vue';
import ProduceVideoView from '@/views/ProduceVideoView.vue';
import PreviewVideoView from '@/views/PreviewVideoView.vue';
import ShareVideoView from '@/views/ShareVideoView.vue';
import { showFolderGuard } from '@/js/guards/showFolderGuard.js';
import { handleBeforeEachGuard } from '@/router/guards/global.js';
import Error403View from '@/views/errors/Error403View.vue';
import Error404View from '@/views/errors/Error404View.vue';
import MediasView from '@/views/MediasView.vue';
import CompanionVideoView from '@/views/CompanionVideoView.vue';
import AcademyView from '@/views/AcademyView.vue';
import EditClientView from '@/views/EditClientView/EditClientView.vue';

export const loading = ref(false);
export const cgsData = ref([]);

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'Home',
            component: HomeView,
            meta: { requiresAuth: true, hideNavigation: false }
        },
        {
            path: '/folders',
            name: 'Folders',
            component: FoldersView,
            meta: { requiresAuth: true, hideNavigation: false }
        },
        {
            path: '/folders/:shortname',
            name: 'ShowFolder',
            component: ShowFolderView,
            meta: { requiresAuth: true, hideNavigation: false },
            beforeEnter: showFolderGuard
        },
        {
            path: '/brands',
            name: 'Brands',
            component: BrandsView,
            meta: { requiresAuth: true, hideNavigation: false }
        },
        {
            path: '/brands/:id',
            name: 'EditBrand',
            component: EditBrandView,
            meta: { requiresAuth: true, hideNavigation: false }
        },
        {
            path: '/videos/:id/edit',
            name: 'EditVideo',
            component: EditVideoView,
            meta: { hideNavigation: true, requiresAuth: true }
        },
        {
            path: '/videos/:id/preview',
            name: 'PreviewVideo',
            component: PreviewVideoView,
            meta: { hideNavigation: true }
        },
        {
            path: '/brands/:brandId/templates/:id/preview',
            name: 'PreviewTemplate',
            component: PreviewVideoView,
            meta: { hideNavigation: true, isTemplate: true }
        },
        {
            path: '/brands/:brandId/sequences/:id/preview',
            name: 'PreviewSequenceTemplate',
            component: PreviewVideoView,
            meta: { hideNavigation: true, isSequenceTemplate: true }
        },
        {
            path: '/videos/:id/share',
            name: 'ShareVideo',
            component: ShareVideoView,
            meta: { hideNavigation: true }
        },
        {
            path: '/brands/:brandId/templates/:id/share',
            name: 'ShareTemplate',
            component: ShareVideoView,
            meta: { hideNavigation: true, isTemplate: true }
        },
        {
            path: '/brands/:brandId/sequences/:id/share',
            name: 'ShareSequenceTemplate',
            component: ShareVideoView,
            meta: { hideNavigation: true, isSequenceTemplate: true }
        },
        {
            path: '/productions/:production',
            name: 'VideoProduction',
            component: ProduceVideoView,
            meta: { hideNavigation: true }
        },
        {
            path: '/brands/:brandId/sequences/:id/edit',
            name: 'EditSequenceTemplate',
            component: EditVideoView,
            meta: { hideNavigation: true, isSequenceTemplate: true, requiresAuth: true }
        },
        {
            path: '/brands/:brandId/templates/:id/edit',
            name: 'EditTemplate',
            component: EditVideoView,
            meta: { hideNavigation: true, isTemplate: true, requiresAuth: true }
        },
        {
            path: '/video-companion/:prompt?',
            name: 'Companion',
            component: CompanionView,
            meta: { requiresAuth: true, hideNavigation: false }
        },
        {
            path: '/companion/:uuid/preview',
            name: 'CompanionVideo',
            component: CompanionVideoView,
            meta: { hideNavigation: true }
        },
        {
            path: '/e-learning',
            name: 'Elearning',
            component: ElearningView,
            meta: { requiresAuth: true, hideNavigation: false }
        },
        {
            path: '/academy',
            name: 'Academy',
            component: AcademyView,
            meta: { requiresAuth: true, hideNavigation: false }
        },
        {
            path: '/client',
            name: 'EditClient',
            component: EditClientView,
            meta: { requiresAuth: true, hideNavigation: false }
        },
        {
            path: '/medias',
            name: 'Medias',
            component: MediasView,
            meta: { requiresAuth: true, hideNavigation: false }
        },
        {
            path: '/403',
            name: '403',
            component: Error403View,
            meta: {
                hideNavigation: true
            }
        },
        {
            path: '/404',
            name: '404',
            component: Error404View,
            meta: {
                hideNavigation: true
            }
        },
        {
            path: '/:pathMatch(.*)*',
            component: Error404View,
            meta: {
                hideNavigation: true
            }
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth'
            };
        }
    }
});

router.beforeEach(handleBeforeEachGuard);

export default router;
