import { executeRequest } from '@/js/infrastructure/api/apiClient.js';

const LEGACY_URL = import.meta.env.VITE_LEGACY_APP_URL;

export default class CGSApiService {
    constructor(store) {
        this.store = store;
    }

    fetchAll() {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${LEGACY_URL}/front/cgs`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'CGSApiService.fetchAll'
        });
    }

    fetchById(id) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${LEGACY_URL}/front/cgs/${id}`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'CGSApiService.fetchById'
        });
    }

    postReply(params) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.post(`${LEGACY_URL}/front/cgs/${params.id}/reply`, {
                    accept: params.accept
                });
            },
            onSuccess: async (response) => response.data,
            actionType: 'CGSApiService.postReply'
        });
    }
}
