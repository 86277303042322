import { computed } from 'vue';
import { useStore } from 'vuex';
import { Duration } from 'cte-video-studio';
import { useHistory } from './useHistory';

// reference: to the mixin SequenceCard.js
export function useSequence(sequenceId, store = null) {
    if (!store) {
        store = useStore();
    }
    const { saveHistoryStep } = useHistory(store);

    const sequenceStoreModulePath = computed(() => `sequences/${sequenceId}`);
    const sequenceState = computed(() => store.state.sequences[sequenceId]);
    const isLastVisibleSequence = computed(() => store.getters[`${sequenceStoreModulePath.value}/isLastVisible`]);
    const isNextVisibleSequence = computed(() => store.getters[`${sequenceStoreModulePath.value}/nextVisible`]);

    const hasColorBackground = computed(() => store.getters[`sequences/${resolvedId.value}/hasColorBackground`]);
    const hasImageBackground = computed(() => store.getters[`sequences/${resolvedId.value}/hasImageBackground`]);
    const hasVideoBackground = computed(() => store.getters[`sequences/${resolvedId.value}/hasVideoBackground`]);
    const hasAnimatedBackground = computed(() => store.getters[`sequences/${resolvedId.value}/hasAnimatedBackground`]);
    const hasCartoonBackground = computed(() => store.getters[`sequences/${resolvedId.value}/hasCartoonBackground`]);
    const hasCollageBackground = computed(() => store.getters[`sequences/${resolvedId.value}/hasCollageBackground`]);
    const hasMapZoomBackground = computed(() => store.getters[`sequences/${resolvedId.value}/hasMapZoomBackground`]);
    const hasRecordingBackground = computed(
        () => store.getters[`sequences/${resolvedId.value}/hasRecordingBackground`]
    );
    const hasPreviousBackground = computed(
        () => store.getters[`${sequenceStoreModulePath.value}/hasPreviousBackground`]
    );

    const maxDuration = computed(() => {
        if (hasRecordingBackground.value) return resolvedBackgroundState.value.recording.totalDuration();
        else return resolvedBackgroundState.value.video.totalDuration();
    });
    const resolvedId = computed(() => store.getters[`${sequenceStoreModulePath.value}/resolvedId`]);
    const resolvedBackgroundState = computed(() => store.state.sequences[sequenceId].background);
    const resolvedBackgroundVolume = computed(() => {
        if (hasRecordingBackground.value) return store.getters[`sequences/${resolvedId.value}/recording/volume`];
        else return store.getters[`sequences/${resolvedId.value}/backgroundVolume`];
    });
    const resolvedBackgroundPlaybackRate = computed(
        () => store.getters[`sequences/${resolvedId.value}/backgroundPlaybackRate`]
    );

    const playbackRate = computed({
        get: () => resolvedBackgroundPlaybackRate.value || Duration.PLAY_BACK_RATE_DEFAULT,
        set: (value) => {
            saveHistoryStep(() => {
                if (hasVideoBackground.value) {
                    store.commit(`${sequenceStoreModulePath.value}/setVideoPlaybackRate`, value);
                } else if (hasRecordingBackground.value) {
                    store.commit(`${sequenceStoreModulePath.value}/recording/setPlaybackRate`, value);
                }
            });
        }
    });
    const videoRange = computed(() => {
        const range = { start: 0, end: -1 };

        if (hasVideoBackground.value) {
            range.start = resolvedBackgroundState.value.video.timerange.start;
            range.end = resolvedBackgroundState.value.video.timerange.end;
        } else if (hasRecordingBackground.value) {
            range.start = resolvedBackgroundState.value.recording.timerange.start;
            range.end = resolvedBackgroundState.value.recording.timerange.end;
        }
        return range;
    });

    const videoTimerangeSegments = computed({
        get: () => resolvedBackgroundState.value.video.timerangeSegments ?? [],
        set: (value) => {
            saveHistoryStep(() => {
                store.commit(`${sequenceStoreModulePath.value}/setBackgroundVideoTimerangeSegments`, value);
            });
        }
    });

    const recordingTimerangeSegments = computed({
        get: () => resolvedBackgroundState.value.recording.timerangeSegments ?? [],
        set: (value) => {
            saveHistoryStep(() => {
                store.commit(`${sequenceStoreModulePath.value}/recording/setTimerangeSegments`, value);
            });
        }
    });

    const audioTimerangeSegments = computed({
        get: () => sequenceState.value.audio.track.timerangeSegments ?? [],
        set: (value) => {
            saveHistoryStep(() => {
                store.commit(`${sequenceStoreModulePath.value}/setTrackTimerangeSegments`, value);
            });
        }
    });

    const backgroundVideoCaptioningStatus = computed(() => {
        return !hasPreviousBackground.value && hasVideoBackground.value
            ? resolvedBackgroundState.value.video.captioningStatus
            : null;
    });

    const backgroundVideo = computed(() => {
        let ref = { src: '', id: '' };

        if (hasVideoBackground.value) {
            ref.src = resolvedBackgroundState.value.video.src;
            ref.id = resolvedBackgroundState.value.video.src__id;
        } else if (hasRecordingBackground.value) {
            ref.src = resolvedBackgroundState.value.recording.src;
            ref.id = resolvedBackgroundState.value.recording.src__id;
        }

        return ref;
    });

    const audioSrc = computed({
        get: () => sequenceState.value.audio.track,
        set: (value) => {
            store.commit(`${elementStoreModulePath.value}/setTrack`, value);
        }
    });

    const recordingSrc = computed(() => resolvedBackgroundState.value.recording);

    return {
        sequenceState,
        resolvedBackgroundState,
        sequenceStoreModulePath,
        hasVideoBackground,
        hasPreviousBackground,
        hasColorBackground,
        hasImageBackground,
        hasAnimatedBackground,
        hasCartoonBackground,
        hasCollageBackground,
        hasMapZoomBackground,
        hasRecordingBackground,
        isLastVisibleSequence,
        isNextVisibleSequence,
        maxDuration,
        resolvedId,
        resolvedBackgroundVolume,
        playbackRate,
        videoRange,
        videoTimerangeSegments,
        backgroundVideoCaptioningStatus,
        backgroundVideo,
        audioTimerangeSegments,
        audioSrc,
        recordingTimerangeSegments,
        recordingSrc
    };
}
