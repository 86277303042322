/**
  -- Component name: CollageTable
  -- Type: Background animation
  -- Uses: 
  -- Categories: background-collage
  -- Tags: background-collage
 */
import gsap from 'gsap';

class CollageTable {
    static animate(background, data) {
        let t = gsap.timeline();
        return t;
    }
}

export default CollageTable;
