/**
 * Video Studio
 * Panel constants
 */

const STANDARD = 'Standard';
const SHAPE_STANDARD = 'ShapeStandard';
const HOLLOW_STANDARD = 'HollowStandard';
const CUSTOM = 'Custom';

const SIDE_CATEGORY = 'side';
const SHAPE_CATEGORY = 'shape';
const HOLLOW_CATEGORY = 'hollow';
const CUSTOM_CATEGORY = 'custom';

const files = import.meta.glob('../components/panels/*.vue');
const panels = {};
Object.keys(files).forEach((key) => {
    key = key
        .split('/')
        .pop()
        .replace(/(\.\/|\.vue)/g, '');
    let parts = key.match(/([A-Z\d]+(?:[a-z]+|$))/g);
    panels[parts.join('_').toUpperCase()] = key;
});

const Panel = {
    PREFIX_CLASS: 'Panel',

    DEFAULT: STANDARD,
    DEFAULTS: {
        [SIDE_CATEGORY]: STANDARD,
        [SHAPE_CATEGORY]: SHAPE_STANDARD,
        [HOLLOW_CATEGORY]: HOLLOW_STANDARD,
        [CUSTOM_CATEGORY]: CUSTOM
    },

    STANDARD,
    SHAPE_STANDARD,
    HOLLOW_STANDARD,
    CUSTOM,

    SIDE_CATEGORY,
    SHAPE_CATEGORY,
    HOLLOW_CATEGORY,
    CUSTOM_CATEGORY,
    CATEGORIES: [SIDE_CATEGORY, SHAPE_CATEGORY, HOLLOW_CATEGORY, CUSTOM_CATEGORY],
    CATEGORY_DEFAULT: SIDE_CATEGORY,

    ANIMATION_ZOOM_SCALE_DEFAULT: 1.3,
    ANIMATION_ZOOM_IN_SCALE_DEFAULT: 0.7,

    ...panels
};

export default Panel;
