import { executeRequest } from '../apiClient.js';

const API_URL = import.meta.env.VITE_EDITION_API_URL;

class VideoStudioService {
    constructor(store) {
        this.store = store;
    }

    getVideoStudioResource(videoId) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${API_URL}/videos/${videoId}/read`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'getVideoStudioResource'
        });
    }

    getShowConfig(videoId) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${API_URL}/videos/${videoId}/config`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'getShowConfig'
        });
    }

    getEditConfig(videoId) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${API_URL}/api/videos/${videoId}/config`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'getConfig'
        });
    }

    getTemplateVideoResource(brandId, templateId, type = 'templates') {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${API_URL}/brands/${brandId}/${type}/${templateId}/video-resource`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'getTemplateVideoResource'
        });
    }

    getTemplateConfig(brandId, templateId, type = 'templates') {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${API_URL}/brands/${brandId}/${type}/${templateId}/config`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'getTemplateConfig'
        });
    }

    getProductionResource(productionUuid) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${API_URL}/productions/${productionUuid}`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'getProductionResource'
        });
    }

    getVideoShareResource(videoId) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${API_URL}/api/videos/${videoId}/share`);
            },
            onSuccess: (response) => response.data,
            actionType: 'getShareVideoResource'
        });
    }

    getTemplateShareResource(brandId, videoId) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${API_URL}/api/brands/${brandId}/templates/${videoId}/share`);
            },
            onSuccess: (response) => response.data,
            actionType: 'getTemplateVideoResource'
        });
    }

    getSequenceTemplateShareResource(brandId, videoId) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${API_URL}/api/brands/${brandId}/sequences/${videoId}/share`);
            },
            onSuccess: (response) => response.data,
            actionType: 'getSequenceTemplateVideoResource'
        });
    }
}

export default VideoStudioService;
