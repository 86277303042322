<!--
  -- Component name: CW2
  -- Type: Transition
  -- Uses: color.start,color.second
  -- Tags: cushman-wakefield
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        v-if="landscapeFormat"
        :class="classes"
        :id="this.containerID"
        viewBox="0 0 1920 1080"
        preserveAspectRatio="xMinYMin slice"
    >
        <polygon
            class="polygon3"
            :fill="state.color.start"
            points="533.62 0.5 2332.11 0.5 1787.04 1080.5 0.15 1080.5 533.62 0.5"
        />
        <polygon
            class="polygon2"
            :fill="state.color.second"
            fill-opacity="0.75"
            points="534.02 0.5 2332.52 0.5 1787.44 1080.5 0.55 1080.5 534.02 0.5"
        />
        <polygon class="polygon1" fill="#fff" points="0 475.49 1920 0 1920 1629.42 0 2107.81 0 475.49" />
    </svg>
    <svg
        class="studio-sequence-transition studio-container"
        v-else-if="squareFormat"
        :class="classes"
        :id="this.containerID"
        viewBox="0 0 1000 1000"
        preserveAspectRatio="xMinYMin slice"
    >
        <polygon
            class="polygon3"
            :fill="state.color.start"
            points="494.36 0 1494.41 0.08 1000.55 999.89 0.49 999.81 494.36 0"
        />
        <polygon
            class="polygon2"
            :fill="state.color.second"
            fill-opacity="0.75"
            points="493.87 0.04 1493.92 0.12 1000.05 999.93 0 999.85 493.87 0.04"
        />
        <polygon
            class="polygon1"
            fill="#fff"
            points="0.32 247.97 1000.32 0.32 1000.32 1053.92 0.32 1301.57 0.32 247.97"
        />
    </svg>
    <svg
        class="studio-sequence-transition studio-container"
        v-else-if="portraitFormat"
        :class="classes"
        :id="this.containerID"
        viewBox="0 0 1080 1920"
        preserveAspectRatio="xMinYMin slice"
    >
        <polygon
            class="polygon3"
            :fill="state.color.start"
            points="948.54 0.32 2028.09 0.32 1079.69 1920.32 0.14 1920.32 948.54 0.32"
        />
        <polygon
            class="polygon2"
            :fill="state.color.second"
            fill-opacity="0.75"
            points="948.98 0.32 2028.53 0.32 1080.13 1920.32 0.57 1920.32 948.98 0.32"
        />
        <polygon class="polygon1" fill="#fff" points="0 267.46 1080 0 1080 1973.38 0 2240.84 0 267.46" />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: true, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true, // The transition component is placed above the in and out elements
            // swapElements: true, // The in and out parent elements are swapped to have the out elements above the in elements
            inElementsAutoAppear: false // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {
        landscapeFormat() {
            return this.format.width > this.format.height;
        },

        portraitFormat() {
            return this.format.width < this.format.height;
        },

        squareFormat() {
            return this.format.width === this.format.height;
        }
    },

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            t.fromTo(
                '#' + this.containerID + ' .polygon3',
                { xPercent: -100 },
                { xPercent: 100, duration: 1, ease: 'power2.out' }
            );
            t.fromTo(
                '#' + this.containerID + ' .polygon2',
                { xPercent: -100 },
                { xPercent: 100, duration: 1, ease: 'power2.out' },
                0.1
            );
            t.fromTo(
                '#' + this.containerID + ' .polygon1',
                { yPercent: 100 },
                { yPercent: -100, duration: 1, ease: 'power2.out' },
                0.2
            );
            t.set([this.backgroundInElement, this.sequenceInElement], { autoAlpha: 1 }, 0.45);
            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 }, 0.45);
            return t;
        }
    }
};
</script>
