<template>
    <div v-if="isVisible" class="ui-caption-editor-processing">
        <div v-if="captioning" class="captioning">
            <div class="captioning-progress">
                <p>{{ $t('Automatic captioning is currently processing.') }}</p>
                <p>{{ $t('This operation can take up to several minutes.') }}</p>
                <svg-icon icon="circle-loader" />
                <button class="ui-simple-button ui-simple-button-cancel" @click.prevent="cancelAutoCaptions">
                    <span>{{ $t('Cancel') }}</span>
                </button>
            </div>
            <div class="captioning-notice">
                <p>{{ $t('You can continue to work on your video during the process.') }}</p>
                <p>{{ $t('You will be notified when it is complete.') }}</p>
            </div>
        </div>
        <div v-else-if="translating" class="translating">
            <p>{{ $t('Translation is currently processing.') }}</p>
            <p>{{ $t('This operation takes just a few seconds.') }}</p>
            <svg-icon icon="circle-loader" />
            <button class="ui-simple-button ui-simple-button-cancel" @click.prevent="cancelTranslation">
                <span>{{ $t('Cancel') }}</span>
            </button>
        </div>
        <div v-else-if="hasProcessingError" :class="hasCaptioningError ? 'captioning' : 'translating'">
            <p>{{ $t('errors.' + (hasCaptioningError ? 'stt' : 'translation') + '.prefix') }}</p>
            <p v-html="processingErrorDetails"></p>
            <button class="ui-simple-button ui-simple-button-cancel" @click.prevent="cancelProcessingMethod">
                <span>{{ $t('Cancel') }}</span>
            </button>
        </div>
    </div>
</template>

<script>
import { printf as sprintf } from 'fast-printf';
import i18n from '@/libs/i18n.js';

export default {
    name: 'UiCaptionEditorProcessing',

    props: {
        isVisible: {
            type: Boolean,
            default: true
        },
        captioning: {
            type: Boolean
        },
        translating: {
            type: Boolean
        },
        translatingStatus: {
            type: Object
        },
        hasProcessingError: {
            type: Boolean
        },
        hasCaptioningError: {
            type: Boolean
        },
        currentCaptionButtonCaptioningStatus: {
            type: Object
        },
        cancelTranslation: {
            type: Function
        },
        cancelAutoCaptions: {
            type: Function
        }
    },

    computed: {
        cancelProcessingMethod() {
            return this.hasCaptioningError ? this.cancelAutoCaptions : this.cancelTranslation;
        },

        processingErrorDetails() {
            if (!this.hasProcessingError) return '';

            let errorKey = '';

            if (this.hasCaptioningError) {
                errorKey = !!i18n.global.t('errors.stt')[this.currentCaptionButtonCaptioningStatus.error]
                    ? 'errors.stt.' + this.currentCaptionButtonCaptioningStatus.error
                    : 'errors.stt.default';
            } else {
                errorKey = !!i18n.global.t('errors.translation')[this.translatingStatus.error]
                    ? 'errors.translation.' + this.translatingStatus.error
                    : 'errors.translation.default';
            }

            return sprintf(i18n.global.t(errorKey), '<a href="mailto:' + this.supportEmail + '">', '</a>');
        }
    }
};
</script>

<style scoped></style>
