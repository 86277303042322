import { ref } from 'vue';

export function useWaveformDrawing() {
    const amplitudeScale = ref(0.5);

    const normalizeAudioData = (audioData) => {
        const sortedValues = audioData.map(Math.abs).sort((a, b) => b - a);
        const pourcentRemove = Math.floor(sortedValues.length * 0.02);

        const maxAmplitude = sortedValues[pourcentRemove] || sortedValues[sortedValues.length - 1];
        const scaleFactor = maxAmplitude > 0 ? 1 / maxAmplitude : 1;
        return audioData.map((value) => value * scaleFactor);
    };

    const drawBarWaveform = (ctx, width, height, color, audioData) => {
        const normalizedData = normalizeAudioData(audioData);
        const barWidth = 2;
        const barGap = 1;
        const barCount = Math.floor(width / (barWidth + barGap));
        const dataStep = normalizedData.length / barCount;

        ctx.fillStyle = color;

        for (let i = 0; i < barCount; i++) {
            const dataIndex = Math.floor(i * dataStep);
            const value = normalizedData[dataIndex] || 0;
            const barHeight = Math.max(0, value * height * amplitudeScale.value);
            const x = i * (barWidth + barGap);
            const y = height - barHeight;

            ctx.fillRect(x, y, barWidth, barHeight);
        }
    };

    const drawWaveWaveform = (ctx, width, height, color, audioData) => {
        const normalizedData = normalizeAudioData(audioData);
        ctx.clearRect(0, 0, width, height);
        ctx.fillStyle = color;
        ctx.beginPath();

        const dataStep = normalizedData.length / width;

        let previous = {
            x: 0,
            y: height - Math.max(0, normalizedData[0] * height * amplitudeScale.value)
        };
        ctx.moveTo(previous.x, previous.y);

        for (let i = 1; i < width; i++) {
            const dataIndex = Math.floor(i * dataStep);
            const value = normalizedData[dataIndex] || 0;
            const x = i;
            const y = height - Math.max(0, value * height * amplitudeScale.value);
            const cx = (previous.x + x) / 2;
            const cy = (previous.y + y) / 2;

            ctx.quadraticCurveTo(previous.x, previous.y, cx, cy);

            previous = { x, y };
        }

        ctx.lineTo(width, height);
        ctx.lineTo(0, height);
        ctx.closePath();
        ctx.fill();

        ctx.strokeStyle = color;
        ctx.lineWidth = 1;
        ctx.stroke();
    };

    const drawWaveWaveformMiddle = (ctx, width, height, color, audioData) => {
        const normalizedData = normalizeAudioData(audioData);
        amplitudeScale.value = 0.8;
        const centerY = height / 2;

        const gradient = ctx.createLinearGradient(0, 0, 0, height);
        gradient.addColorStop(0, '#ff007f');
        gradient.addColorStop(0.5, '#ff005f');
        gradient.addColorStop(1, '#ff0049');
        ctx.fillStyle = gradient;

        ctx.beginPath();

        const dataLength = normalizedData.length;
        const sliceWidth = width / dataLength;

        let previousX = 0;
        let previousY = centerY;

        for (let i = 0; i < dataLength; i++) {
            const x = i * sliceWidth;
            const value = normalizedData[i] || 0;
            const yOffset = (value * height * amplitudeScale.value) / 2;
            const y = centerY - yOffset;

            ctx.quadraticCurveTo(previousX, previousY, x, y);
            previousX = x;
            previousY = y;
        }

        for (let i = dataLength - 1; i >= 0; i--) {
            const x = i * sliceWidth;
            const value = normalizedData[i] || 0;
            const yOffset = (value * height * amplitudeScale.value) / 2;
            const y = centerY + yOffset;

            ctx.quadraticCurveTo(previousX, previousY, x, y);
            previousX = x;
            previousY = y;
        }

        ctx.closePath();
        ctx.fill();

        ctx.strokeStyle = color;
        ctx.lineWidth = 1;
        ctx.stroke();
    };

    const drawWaveform = (ctx, width, height, color, audioData, type) => {
        ctx.clearRect(0, 0, width, height);
        if (type === 'bar') {
            drawBarWaveform(ctx, width, height, color, audioData);
        }

        if (type === 'wave') {
            drawWaveWaveform(ctx, width, height, color, audioData);
        }

        if (type === 'wave-middle') {
            drawWaveWaveformMiddle(ctx, width, height, color, audioData);
        }
    };

    return {
        drawWaveform,
        amplitudeScale
    };
}
