import { useStore } from 'vuex';

export function useMediaConversions() {
    const store = useStore();

    const addConversionFromId = (id) => {
        store.commit('loading/addConvert', store.getters['ui/mediaPreviewUrl'](id));
    };

    const removeFromPath = (path) => {
        store.commit('loading/removeConvert', path);
    };

    return {
        addConversionFromId,
        removeFromPath
    };
}
