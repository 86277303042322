<template>
    <component v-if="is" :is="is" v-bind="$attrs" ref="$wrapperReference">
        <slot />
    </component>
    <slot v-else />
</template>

<script>
export default {
    inheritAttrs: false,

    props: {
        is: Object
    }
};
</script>
