<template>
    <splash-screen v-if="loading" />
    <div v-else-if="!errorCode" class="container-fluid h-100">
        <div class="row h-100">
            <main class="content col-12 p-0" :class="{ skeleton: !studioReady }">
                <div class="show-preview h-100">
                    <div id="studio-preview" class="preview-layout">
                        <div class="preview-studio-header" :style="{ '--preview-studio-width': `${formatWidth}px` }">
                            <h1 class="preview-name">{{ videoName }}</h1>
                            <h2 class="preview-signature">
                                {{ sprintf($t('Made with %1$s'), '') }}
                                <svg-id-randomized-icon icon="cte-logo-inline" />
                            </h2>
                        </div>

                        <div class="preview-studio-wrapper">
                            <video-studio
                                v-if="studioReady"
                                ref="videoStudioRef"
                                id="studio"
                                :external-preview="true"
                            />
                            <canvas :width="formatWidth" :height="formatHeight" class="preview-studio-shape"></canvas>
                        </div>
                        <studio-controls :external-preview="true" :skeleton="!studioReady" />
                    </div>
                </div>
            </main>
        </div>
    </div>
    <error-screen v-else :error-code="errorCode" />
</template>

<script setup>
import VideoStudio from '@/js/video-studio/VideoStudio.vue';
import StudioControls from '@/js/videos/components/controls/StudioControls.vue';
import { nextTick, onBeforeUnmount, provide, ref } from 'vue';
import { useStore } from 'vuex';
import VideoStudioService from '@/js/infrastructure/api/video/VideoStudioService.js';
import StudioShowInitializer from '@/js/videos/application/StudioShowInitializer.js';
import { useRoute } from 'vue-router';
import { printf as sprintf } from 'fast-printf';
import SvgIdRandomizedIcon from '@/js/components/SvgIdRandomizedIcon.vue';
import SplashScreen from '@/js/components/SplashScreen.vue';
import ErrorScreen from '@/js/components/ErrorScreen.vue';

const store = useStore();
const route = useRoute();

const videoStudioRef = ref(null);
const studioReady = ref(false);
const videoName = ref('');
const formatWidth = ref('');
const formatHeight = ref('');

// component state with API call
const loading = ref(true);
const errorCode = ref(null);

const videoStudioService = new VideoStudioService(store);
const studioShowInitializer = new StudioShowInitializer(store, videoStudioService);

provide('$videoStudio', videoStudioRef);
const ready = () => {
    studioReady.value = true;

    videoName.value = store.state.settings.name;
    formatWidth.value = store.state.display.format.width;
    formatHeight.value = store.state.display.format.height;
};
studioShowInitializer
    .initVideoStudio(
        route.params.id,
        route.meta.isSequenceTemplate,
        route.meta.isTemplate,
        route.params.brandId ?? null
    )
    .then((response) => {
        loading.value = false;

        if (response?.errorCode) errorCode.value = response.errorCode;
        else nextTick(ready);
    });

// add class to body
document.body.classList.add('show-video');
onBeforeUnmount(() => {
    document.body.classList.remove('show-video');
});
</script>
