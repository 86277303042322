/**
 * Edit video UI processes Vuex store
 */

import {
    CAPTIONING_PROCESS_TYPE,
    MEDIA_CAPTIONING_STATUS_POLLING_TIME,
    CAPTIONS_STATUS_COMPLETED,
    CAPTIONS_STATUS_ERROR
} from '@/js/constants';
import { requestWithServiceTokenRefresh, maybeRefreshLogin } from '@/js/utils';
import { startMediaCaptioning, getMediaCaptioningStatus } from '@/js/videos/utils';

const processMediaCaptioningResponse = async (context, mediaId, requestFunction) => {
    let { commit, dispatch, getters } = context;

    await requestWithServiceTokenRefresh(
        async () => {
            let response = await requestFunction();

            // TODO: Inform about possible language difference based on response.data.using_previous_operation?

            if (!response.data.stt) {
                dispatch('pollMediaCaptioningProcess', mediaId);
            } else {
                dispatch('completeMediaCaptioningProcess', { mediaId, captions: response.data.stt });
            }
        },
        (refreshError) => {
            console.log('[Error] Media captioning:', refreshError, refreshError.response);
            if (refreshError.response?.status == 401 && !!refreshError.response?.data?.token) {
                maybeRefreshLogin(context, refreshError.response.data.token);
            } else if (!!refreshError.response?.data?.redirect) {
                commit('ui/setAuthError', refreshError.response.data, { root: true });
                window.location.assign(refreshError.response.data.redirect);
            }

            dispatch('completeMediaCaptioningProcess', {
                mediaId,
                error: refreshError.response?.data || { error: 'stt-unknown-error', details: refreshError.message }
            });
        },
        context
    );
};

const state = {
    list: []
};

const getters = {
    getProcess: (state) => (type, id) =>
        state.list.find((processData) => processData.type == type && processData.id == id),

    hasProcess: (state, getters) => (type, id) => !!getters.getProcess(type, id)
};

const mutations = {
    addProcess(state, { type, id, ...data }) {
        state.list.push({
            type,
            id,
            pollId: null,
            // completed: false,
            // error: false,
            subscribers: [],
            ...data
        });
    },

    setProcessData(state, { type, id, ...data }) {
        let processIndex = state.list.findIndex((processData) => processData.type == type && processData.id == id);

        if (processIndex != -1) {
            state.list[processIndex] = Object.assign({}, state.list[processIndex], { ...data });
        }
    },

    removeProcess(state, { type, id }) {
        let processIndex = state.list.findIndex((processData) => processData.type == type && processData.id == id);

        if (processIndex != -1) {
            clearTimeout(state.list[processIndex].pollId);
            state.list.splice(processIndex, 1);
        }
    },

    addSubscriber(state, { type, id, subscriberId, callbacks }) {
        let processIndex = state.list.findIndex((processData) => processData.type == type && processData.id == id);

        if (processIndex != -1) {
            let processSubscribers = state.list[processIndex].subscribers,
                subscriberIndex = processSubscribers.findIndex((subscriber) => subscriber.id == subscriberId);

            if (subscriberIndex == -1) {
                state.list[processIndex] = Object.assign({}, state.list[processIndex], {
                    subscribers: [
                        ...processSubscribers,
                        {
                            id: subscriberId,
                            ...callbacks
                        }
                    ]
                });
            }
        }
    },

    removeSubscriber(state, { type, id, subscriberId }) {
        let processIndex = state.list.findIndex((processData) => processData.type == type && processData.id == id);

        if (processIndex != -1) {
            let processSubscribers = state.list[processIndex].subscribers,
                subscriberIndex = processSubscribers.findIndex((subscriber) => subscriber.id == subscriberId);

            if (subscriberIndex != -1) {
                processSubscribers.splice(subscriberIndex, 1);
                state.list[processIndex] = Object.assign({}, state.list[processIndex], {
                    subscribers: [...processSubscribers]
                });
            }
        }
    }
};

const actions = {
    startMediaCaptioningProcess(
        context,
        { mediaId, subscriberId, success: successCallback, error: errorCallback }
    ) {
        let { commit, getters } = context;

        if (getters.hasProcess(CAPTIONING_PROCESS_TYPE, mediaId)) {
            commit('addSubscriber', {
                type: CAPTIONING_PROCESS_TYPE,
                id: mediaId,
                subscriberId,
                callbacks: {
                    success: successCallback,
                    error: errorCallback
                }
            });
            return;
        }

        commit('addProcess', {
            type: CAPTIONING_PROCESS_TYPE,
            id: mediaId,
            subscribers: [
                {
                    id: subscriberId,
                    success: successCallback,
                    error: errorCallback
                }
            ],
        });

        processMediaCaptioningResponse(context, mediaId, () => {
            return startMediaCaptioning(context, mediaId);
        });
    },

    pollMediaCaptioningProcess(context, mediaId) {
        let { commit, getters } = context,
            processData = getters.getProcess(CAPTIONING_PROCESS_TYPE, mediaId);

        if (!!processData) {
            clearTimeout(processData.pollId);

            commit('setProcessData', {
                type: CAPTIONING_PROCESS_TYPE,
                id: mediaId,
                pollId: setTimeout(() => {
                    processMediaCaptioningResponse(context, mediaId, () => {
                        return getMediaCaptioningStatus(context, mediaId);
                    });
                }, MEDIA_CAPTIONING_STATUS_POLLING_TIME)
            });
        }
    },

    completeMediaCaptioningProcess({ commit, dispatch, getters }, { mediaId, captions, error }) {
        let processData = getters.getProcess(CAPTIONING_PROCESS_TYPE, mediaId);

        if (!!processData) {
            let subscribers = processData.subscribers,
                modifiers = subscribers.map((subscriber) => subscriber.id),
                status = '';

            if (!!captions && Array.isArray(captions)) {
                status = CAPTIONS_STATUS_COMPLETED;
                subscribers.forEach((subscriber) => {
                    if (subscriber.success) subscriber.success(captions);
                });
            } else if (!!error) {
                status = CAPTIONS_STATUS_ERROR;
                subscribers.forEach((subscriber) => {
                    if (subscriber.error) subscriber.error(error);
                });
            }

            // Notify complete or error process
            dispatch('ui/updateCaptionsStatus', { status, error, modifiers }, { root: true });
            commit('removeProcess', { type: CAPTIONING_PROCESS_TYPE, id: mediaId });
        }
    },

    cancelMediaCaptioningProcess({ commit, getters }, { mediaId, subscriberId }) {
        commit('removeSubscriber', { type: CAPTIONING_PROCESS_TYPE, id: mediaId, subscriberId });

        let processData = getters.getProcess(CAPTIONING_PROCESS_TYPE, mediaId);
        if (!processData.subscribers.length) {
            commit('removeProcess', { type: CAPTIONING_PROCESS_TYPE, id: mediaId });
        }
    }
};

export default {
    namespaced: true,

    modules: {
        //
    },

    state,
    getters,
    mutations,
    actions
};
